import {ref, onBeforeUnmount} from 'vue'
import {App} from '@capacitor/core'

export const createAppActive = () => {
  const _stateStore = ref<undefined | boolean>()
  
  return () => {
    const stateRef = ref<undefined | boolean>()

    if (typeof _stateStore.value === 'undefined') {
      App.getState().then((state) => {
        stateRef.value = state.isActive
        _stateStore.value = state.isActive
      })
    } else {
      stateRef.value = _stateStore.value
    }

    const handle = App.addListener('appStateChange', (state) => {
      stateRef.value = state.isActive
    })

    onBeforeUnmount(() => {
      handle.remove()
    })

    return stateRef
  }
}

/**
 * @return true = active, false = inactive, undefined = loading active state
 */
export const useAppActive = createAppActive()
