
import {defineComponent, computed, ref} from 'vue'
import {useStore} from 'vuex'
import router from '../../router'
import * as vector from '../../../public/assets/icon/Vector.png'
import * as thun from '../../../public/assets/icon/zap.png'
import BuyModal from './BuyModal.vue'
import {modalController} from '@ionic/vue'

export default defineComponent({
  components: {},
  name: 'DetailModal',
  props: {
    stockId: null,
  },
  setup(props) {
    const talkIcon = vector
    const thunder = thun

    const stockItemId = ref(props.stockId)

    const {getters, commit} = useStore()
    const deal = computed(() => getters['dealInfo/dealInfo'])

    const onClick = () => {
      router.push(`/buychat/${getters['dealInfo/dealId']}`)
      // commit('SET_DEAL', deal);
      modalController.dismiss()
    }

    const closeView = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: BuyModal,
          componentProps: {
            stockId: stockItemId,
          },
          cssClass: 'buy-modal',
          id: 'buyModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      return modal.present()
    }

    return {closeView, deal, onClick, talkIcon, thunder}
  },
})
