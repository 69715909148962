
import {
  IonPage, IonContent, modalController, toastController, alertController,
  popoverController, IonProgressBar,
  IonRefresher, IonRefresherContent,
  IonFooter, IonToolbar, useIonRouter,
} from '@ionic/vue'
import {arrowDownOutline} from 'ionicons/icons'
import {defineComponent, ref, computed, watch, onUpdated, onBeforeMount, getCurrentInstance} from 'vue'
import {useRoute, useRouter, onBeforeRouteUpdate} from 'vue-router'
import ClubDealHelp from '@/components/Popover/ClubDealHelp.vue'
import {useStore} from 'vuex'
import * as filter from '../../utils/filters'
import ClubDealInfo from '@/components/Modal/ClubDealInfo.vue'
import {Plugins} from '@capacitor/core'
import apiPartnership from '@/api/partnership'
import apiPoint from '@/api/user-point'
import CompanyValue from '@/components/Popover/CompanyValue.vue'
import Fee from '@/components/Popover/Fee.vue'
import useModal from '@/composables/useModal'

const {getPoint} = apiPoint()

export default defineComponent({
  components: {
    IonContent,
    IonFooter,
    IonPage,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
  },
  name: 'ClubDealProgress',
  setup() {
    const app = getCurrentInstance()
    const $global = app && app.appContext.config.globalProperties.$global
    const {dispatch, getters} = useStore()
    const {Browser} = Plugins
    const {useClubDealJoinModal, useCompanyModal, openFAQModal, viewKakaoKlipModal} = useModal()
    const {isvalidPartnership, delPartnershipRequests, postPartnershipLike} = apiPartnership()

    const route = useRoute()
    const router = useRouter()
    const showToggle = ref(false)
    const buttonToggle = ref(false)
    //
    // onUpdated(async ()=>{
    //   // console.log('ClubDealProgress Component is onUpdated!');
    //   if(route.params.clubdeal_id){
    //     await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', route.params.clubdeal_id);
    //   }
    // })

    const refresh = async () => {
      if (route.params.clubdeal_id) {
        await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', route.params.clubdeal_id)
      }
    }
    const doRefresh = async (event: any) => {
      await refresh()
      event.target.complete()
    }

    const partnershipInfo = computed(() => getters['partnership/getPartnershipInfo'])
    const boardSummary = computed(() => getters['partnership/getBoardSummary'])
    const anotherNotYet = computed(() => getters['partnership/getAnotherNotYet'])
    const recommendPartnership = computed(() => getters['partnership/getRecommendPartnership'])
    const submitButtons = computed(() => getters['partnership/getSubmitButtons'])
    const partnershipRequest = computed(() => getters['partnership/getPartnershipRequest'])
    const userAvailablePoint = ref(0)
    const user = computed(() => getters['user/getUser'])
    watch(submitButtons, (submitButtons) => {
      if (submitButtons.length > 0) {
        showToggle.value = true
      }
      if (submitButtons.length > 1) {
        buttonToggle.value = true
      }
    })

    const scrollTop = async () => {
      const content: any = document.querySelector('#ionContentClubDealProgress')
      content.scrollToPoint(0, 0)
    }
    const hasSession = () => {
      return getters['user/hasSession']
    }
    async function getUserPoint() {
      if (hasSession()) {
        const userPoint = await getPoint()
        userAvailablePoint.value = userPoint.totalPoint
      }
    }
    const fromCompany = ref(false)
    watch(
      () => route.params,
      async newParams => {
        if (newParams.clubdeal_id) {
          // console.log("route.params", newParams)
          fromCompany.value = (route.query.from === 'company')
          await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', newParams.clubdeal_id)
          await getUserPoint()
          scrollTop()
        }
      },
    )
    watch(
      () => route.path,
      async (newPath, oldPath) => {
        // console.log('newPath', 'oldPath');
        // console.log(newPath, oldPath);
      },
    )
    onBeforeMount(async () => {
      if (route.params.clubdeal_id) {
        fromCompany.value = (route.query.from === 'company')
        await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', route.params.clubdeal_id)
        await getUserPoint()
      }
    })

    let popover: any = null
    const openPopover = async (event_: Event) => {
      if (popover) {
return
}
      popover = await popoverController
        .create({
          component: CompanyValue,
          componentProps: {
            onClick: () => {
              popover.dismiss()
            },
          },
          cssClass: 'company-value',
          event: event_,
          translucent: false,
        })

      popover.onDidDismiss().then((result: any) => {
        popover = null
      })
      return popover.present()
    }

    let popoverFee: any = null
    const openPopoverFee = async (event_: Event) => {
      if (popoverFee) {
return
}
      popoverFee = await popoverController
        .create({
          component: Fee,
          componentProps: {
            onClick: () => {
              popoverFee.dismiss()
            },
          },
          cssClass: 'company-value',
          event: event_,
          translucent: false,
        })

      popoverFee.onDidDismiss().then((result: any) => {
        popoverFee = null
      })
      return popoverFee.present()
    }

    async function heartToggle(partnershipStatus: string, partnershipId: string) {
      const target = partnershipStatus === 'collecting' ? 'collectingLeadDeals' : 'upcomingLeadDeals'
      dispatch('partnership/ACT_LIKE_PARTNERSHIP_TARGET', {partnershipId, partnershipStatus, target})
        .then(() => {
          dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', partnershipId)
        })
    }

    const handleScroll = async (event: any) => {
      const main: any = document.querySelector('#progress__main_container')
      const button: any = document.querySelector('#progress__scroll_top_btn')
      const scroll = await event.getScrollElement()

      if (scroll.scrollTop > main.clientHeight) {
        button.classList.add('show')
      } else {
        button.classList.remove('show')
      }
    }

    const partnershipLike = (partnershipId: any) => {
      dispatch('partnership/ACT_LIKE_PARTNERSHIP_DETAIL', {partnershipId})
    }

    function openLink(link: string) {
      Browser.open({url: link})
    }

    async function buttonClick(type: string) {
      // console.log(type);
      if (type === 'anotherNotYet') {
        const modal = await modalController
          .create({
            backdropDismiss: true,
            component: ClubDealInfo,
            componentProps: {
              anotherNotYetProps: anotherNotYet.value,
              partnershipName: partnershipInfo.value.partnershipName,
            },
            cssClass: 'clubdeal-info',
            id: 'ClubDealInfo',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,

          })

        modal.onDidDismiss().then(async (data: any) => {
          if (data.data === 'cancelJoin') {
            await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', route.params.clubdeal_id)
          }
        })

        return modal.present()
      } else if (type === 'join') {
        await useClubDealJoinModal(Number(route.params.clubdeal_id))
        // await router.push({ name : 'ClubDealJoin', params: { clubdealId : route.params.clubdeal_id}});
      } else if (type === 'cancel') {
        const alert = await alertController
          .create({
            buttons: [
              {
                cssClass: 'btn-gray',
                handler: () => {
                  // console.log('Cancel clicked');
                },
                role: 'cancel',
                text: '아니오',
              },
              {
                handler: async () => {
                  // console.log("삭제하기 클릭");
                  await delPartnershipRequests(partnershipRequest.value.partnershipRequestId, partnershipRequest.value.partnershipId)
                  await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', route.params.clubdeal_id)
                  const toast = await toastController
                    .create({
                      duration: 1000,
                      message: '클럽딜 참여가 취소되었습니다.',
                      position: 'middle',
                    })
                  return toast.present()
                },
                text: '네, 취소할게요',
              },
            ],
            cssClass: 'btn-groups suggest-alert-class',
            message: `
            <div class="alert_css">
              <div class="header">
                이 클럽딜 참여를<br>
                취소하시겠어요?
              </div>
              <div class="message">
                입력하신 모든 정보가 삭제되며,<br/>
                다른 클럽딜에 참여하실 수 있게 됩니다.
              </div>
            </div>`,
          })
        return alert.present()
      }
    }
    const ionRouter = useIonRouter()
    function goPrevious() {
      router.go(-1)
    }
    return {
      arrowDownOutline,
      boardSummary,
      btnClick: buttonClick,
      btnToggle: buttonToggle,
      doRefresh,
      filter,
      fromCompany,
      goPrevious,
      handleScroll,
      hasSession,
      heartToggle,
      openLink,
      openPopover,
      openPopoverFee,
      partnershipInfo,
      partnershipLike,
      postPartnershipLike,
      recommendPartnership,
      refresh,
      route,
      router,
      scrollTop,
      showToggle,
      submitButtons,
      useCompanyModal,
      user,
      userAvailablePoint,
      viewKakaoKlipModal,
    }
  },
})
