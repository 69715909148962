import {ref, watch} from 'vue'
import {MayRef} from '@/types'

export const useLoading = (src?: MayRef<string | string[] | undefined>) => {
  const srcRef = ref(src)
  const loadingRef = ref(true)

  watch(srcRef, () => {
    loadingRef.value = true
  })

  const onload = () => {
    loadingRef.value = false
  }

  return {
    loading: loadingRef,
    onload,
    onloadstart,
  }
}
