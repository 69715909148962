import {_SystemProps, systemProps, SystemProps} from '@/plugins/emotion'
import {ExtractPropTypes, FunctionalComponent, h, PropType, renderSlot} from 'vue'
import {EmphasisText, StyledText} from './components'

export const agTextSProps = {
  emphasis: {default: false, type: [Object, Boolean] as PropType<boolean | SystemProps>},
  typography: {default: 'main16r', type: String},
  whiteSpace: {default: 'break-spaces', type: String},
}


export type AgTextSProps = Partial<ExtractPropTypes<typeof agTextSProps>> & {css?: _SystemProps}

export const AgTextS: FunctionalComponent<AgTextSProps> = (
  props,
  {slots},
) => {
  const {emphasis} = props
  if (emphasis) {
    const emphasisProp = typeof emphasis === 'boolean' ? {} : emphasis
    return (
      h(EmphasisText, {emphasis: emphasisProp}, slots)
    )
  }

  return h(StyledText, {}, () => renderSlot(slots, 'default'))
}

AgTextS.props = agTextSProps


export const agTextProps = {
  ...systemProps,
  ...agTextSProps,
}

export type AgTextProps = Partial<ExtractPropTypes<typeof agTextProps>>

export const AgText: FunctionalComponent<AgTextProps> = (
  props,
  {slots},
) => {
  const {emphasis, css, ...restProps} = props
  return h(AgTextS, {css: {...restProps as any, ...css}, emphasis}, slots)
}

AgText.props = agTextProps
