
import {defineComponent, reactive, computed, onBeforeMount, toRef} from 'vue'
import {IonRippleEffect} from '@ionic/vue'
import {AgBox, AgButton} from '@/components/ag'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    IonRippleEffect,
  },
  emits: ['update:modelValue', 'sellAll'],
  name: 'Keypad',
  props: {
    modelValue: {default: 0, type: Number},
    type: {type: Number},
  },
  setup(props, {emit}) {
    const modelValueRef = toRef(props, 'modelValue')
    const typeRef = toRef(props, 'type')

    function addNumberString(_value: any) {
      const value = _value.toString()
      let result = modelValueRef.value.toString()

      if (result.length >= 10) {
return result
}

      if ((value === '0' || value === '00') && (result === '0' || result === '')) {
return
} // 따로 작업을 해주지 않음!
      if (result === '' || result === '0') {
        result = value
      } else {
        result += value
      }

      result = result.slice(0, 10)
      emit('update:modelValue', result)
    }

    function addNumber(value: number) {
      let result: any = Number(modelValueRef.value)

      if (result >= 1000000000) {
return result
}

      if (value === 0 && result === 0) {
return
} // 따로 작업을 해주지 않음!
      result += value

      result = result.toString().slice(0, 10)
      emit('update:modelValue', result)
    }

    function clearNumber() {
      let result = modelValueRef.value.toString()

      if (result === '') {
return
}

      result = result.slice(0, -1)

      emit('update:modelValue', result)
    }

    const sellAll = () => emit('sellAll')

    return {addNumber, addNumberStr: addNumberString, clearNumber, sellAll, typeRef}
  },
})
