
import {IonPage, IonContent, modalController, IonRippleEffect, alertController, IonToggle} from '@ionic/vue'
import {AgText} from '@/components/ag'
import {defineComponent, onBeforeMount, ref, computed, inject} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import AddressInput from '@/components/Modal/AddressInput.vue'
import AccountModal from '@/components/Modal/AccountModal.vue'
import AccountKeypadModal from '@/components/Modal/AccountKeypadModal.vue'
import {chevronBack} from 'ionicons/icons'
import apiSettings from '@/api/settings'
import LoginModal from '@/components/Modal/LoginModal.vue'
import useModal from '@/composables/useModal'
import {openLink} from '@/utils/filters'
import {Plugins} from '@capacitor/core'
import router from '@/router'
import AgreeToUsePersonalInfo from '@/views/view/setting/AgreeToUsePersonalInfo.vue'
import {getFormattedDate} from '@/utils/filters'
import PushSetting from './components/PushSetting.vue'

const {agreeThirdparty, actAgreeMarketing} = apiSettings()

export default defineComponent({
  components: {
    AgText,
    AgreeToUsePersonalInfo,
    IonContent,
    IonPage,
    IonRippleEffect,
    IonToggle,
    PushSetting,
  },
  name: 'Settings',
  setup() {
    const router = useRouter()
    const {getters, dispatch, commit} = useStore()
    const {useRegistEmailModal, openFAQModal} = useModal()
    const settingInfo = computed(() => getters['user/getSettingInfo'] || [])
    const agreeMarketingValue = computed(() => getters['user/getAgreeMarketing'] || [])
    const agreeMarketingAt = computed(() => getters['user/getAgreeMarketingAt'] || [])
    const appPushAgreement = computed(() => settingInfo.value.agreeAppPushAt)
    const isAgree = computed(() => settingInfo.value ? settingInfo.value.agreeThirdparty : '')
    const email = computed(() => getters['user/getEmail'])
    const name = computed(() => getters['user/getName'])
    const linkAccountModal = ref<any>(false)

    const formattedDate = (updateDate: any) => {
      return getFormattedDate(updateDate, 'YY. MM. DD')
    }

    const currentStep = computed(() => getters['stakeDeal/currentStep'] || '')
    const negostockStatus = computed(() => statusMap[currentStep.value] || {})
    const negostockStatusText = computed(() => negostockStatus.value.text || '')
    const negostockStatusColor = computed(() => negostockStatus.value.color || '')
    const agreeModalOpen = ref<boolean>(false)

    type StatusType = {
      [index: string]: any
    }

    const statusMap: StatusType = {
      COMPLETE: {
        color: 'gray3',
        text: '계정 연결이 안전하게 완료되었습니다.',
      },
      NEED_AGREE: {
        color: 'gray3',
        text: '안전한 지분거래를 위해 네고스탁 회원계정을 연결해주세요',
      },
      NEED_LINKING: {
        color: 'primary',
        text: '실명정보 확인된 계정입니다. 바로 연결 해주세요.',
      },
      NEED_NAME_CHECK: {
        color: 'danger',
        text: '실명 정보 등록 필요한 계정입니다.',
      },
      NEED_REQUEST: {
        color: 'danger',
        text: '실명 정보 미일치. 다시 등록해주세요.',
      },
      NEED_SIGNIN: {
        color: 'gray3',
        text: '안전한 지분거래를 위해 네고스탁 회원계정을 연결해주세요',
      },
      NEED_WAITING: {
        color: 'danger',
        text: '등록하신 실명 정보를 확인 중입니다.',
      },
    }

    const {Browser} = Plugins

    const logout = async () => {
      await dispatch('user/ACT_LOGOUT')
      router.replace({path: '/tabs/home'})
    }

    const resetPassword = async () => {
      await dispatch('user/ACT_RESET_SIMPLE_PASSWORD')
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: LoginModal,
          componentProps: {
            psswordReset: true,
          },
          cssClass: 'login-modal',
          id: 'LoginModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      return modal.present()
    }

    async function askPassword() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              handler: () => {
                // $clickTrackEvent({'clickArea':'하단 버튼', 'clickContent':'취소', 'clickScreen': 'change-pwd'})
                // console.log('Cancel clicked')
              },
              role: 'cancel',
              text: '취소',
            },
            {
              handler: () => {
                resetPassword()
              },
              text: '변경 시작',
            },
          ],
          cssClass: 'btn-groups pw-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              간편 비밀번호 변경
            </div>
            <div class="message">
              변경 시작 버튼을 누르면<br>
              <span class="red">즉시 본인인증 정보가 삭제</span>됩니다.<br>
              본인 인증을 다시 진행한 후<br>
              비밀번호 변경이 가능합니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    const createModal = async (name: string, accountType?: string, bankInfo?: any) => {
      let modal: any
      if (name === 'address') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AddressInput,
            cssClass: 'address-modal',
            id: 'AddressInput',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(() => {
          dispatch('user/ACT_SET_SETTING_INFO')
        })
      } else if (name === 'account') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AccountModal,
            componentProps: {
              accountType,
            },
            cssClass: 'address-modal',
            id: 'AccountModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then((data: any) => {
          if (data.data) {
            createModal('accountKeypad', data.data.type, data.data.bankInfo)
          }
        })
      } else if (name === 'accountKeypad') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AccountKeypadModal,
            componentProps: {
              accountType,
              bankInfo,
            },
            cssClass: 'address-modal',
            id: 'AccountKeypadModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then((data: any) => {
          if (data.data === 'replace') {
            createModal('account', accountType)
          } else {
            dispatch('user/ACT_SET_SETTING_INFO')
          }
        })
      }

      return modal.present()
    }

    function agreeKakaoKilp() {
      agreeThirdparty()
        .then((data) => {
          if (data) {
          // console.log(data)
            dispatch('user/ACT_SET_SETTING_INFO')
          }
        })
    }

    onBeforeMount(() => {
      dispatch('stakeDeal/GET_LINK_STATUS')
      dispatch('user/ACT_SET_SETTING_INFO')
    })

    const refreshLinkData = () => dispatch('stakeDeal/GET_LINK_STATUS')

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }

    const simplePassword = async () => {
      router.replace({path: `/simple-login?${new Date().getMilliseconds()}`})
    }

    const agreeMarketing = async () => {
      const result = await actAgreeMarketing()
      if (result) {
        await commit('user/SET_AGREE_MARKETING', result.agreeMarketing)
        await commit('user/SET_AGREE_MARKETING_AT', result.agreeMarketingAt)
      }
    }

    const clickScreen = 'setting'

    return {
      agreeKakaoKilp,
      agreeMarketing,
      agreeMarketingAt,
      agreeMarketingValue,
      agreeModalOpen,
      askPassword,
      chevronBack,
      clickScreen,
      createModal,
      currentStep,
      email,
      formattedDate,
      isAgree,
      linkAccountModal,
      logout,
      name,
      negostockStatus,
      negostockStatusColor,
      negostockStatusText,
      openChannelTalk,
      openFAQModal,
      openLink,
      refreshLinkData,
      resetPassword,
      router,
      settingInfo,
      simplePassword,
      useRegistEmailModal,
      appPushAgreement,
    }
  },
})

