
import {useAppActive, useLiveUpdate} from '@/composables'
import {defineComponent, onMounted, ref, toRef, computed, watch} from 'vue'
import {AgAlert} from '@/components/ag'

export default defineComponent({
  components: {
    AgAlert,
  },
  emits: ['version', 'available', 'download', 'extract'],
  name: 'UpdateConfirm',
  props: {
    buttonText: {default: '업데이트 시작', type: String},
    message: {default: '더욱 편리하고 안전한 사용을 위해 업데이트합니다.', type: String},
    title: {default: '서비스 업데이트', type: String},
    updateChannel: {default: 'Production', type: String},
  },
  setup(props, {emit}) {
    const buttonText = toRef(props, 'buttonText')
    const {checkForUpdate, downloadUpdate, extractUpdate, reloadForUpdate, state} = useLiveUpdate()
    const isShowUpdateAlert = ref(false)
    const active = useAppActive

    const runUpdateProcess = async () => {
      const checkState = await checkForUpdate(props.updateChannel)
      emit('available', checkState)
      const downloadState = await downloadUpdate()
      emit('download', downloadState)
      const extractState = await extractUpdate()
      emit('extract', extractState)
      if (state.value === 'should-reload') {
        isShowUpdateAlert.value = true
      }
    }

    watch(active, (value) => {
      if (value) {
        runUpdateProcess()
      }
    })

    const onAppUpdate = () => {
      reloadForUpdate()
    }

    const yesUpdateButton = computed(() => ({
      handler: () => onAppUpdate(),
      text: buttonText.value,
    }))

    onMounted(() => {
      runUpdateProcess()
    })

    return {
      isShowUpdateAlert,
      onAppUpdate,
      yesUpdateButton,
    }
  },
})
