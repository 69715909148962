import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_search_stock_item = _resolveComponent("search-stock-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_subtitle, { color: "dark" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.text.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
            return (_openBlock(), _createBlock(_component_search_stock_item, {
              key: item.stockItemId,
              id: item.stockItemId,
              name: item.stockItemName,
              logo: item.companyLogo,
              like: item.likeFlag,
              onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', $event)))
            }, null, 8, ["id", "name", "logo", "like"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}