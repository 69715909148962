import {styled} from '@/plugins/emotion'
import {FunctionalComponent, h} from 'vue'

export const Handle = styled('div', {
  name: 'Handle',
})({
  backgroundColor: '#D9DEE8',
  borderRadius: '1000px',
  height: '5px',
  width: '42px',
})

export const HandleContainer = styled('div', {
  name: 'HandleContainer',
  stylePortal: 'css',
})({
  display: 'flex',
  flexDirection: 'row',
  height: '20px',
  justifyContent: 'center',
  paddingTop: '8px',
})

export const HeadHandle: FunctionalComponent = () => {
  return h(HandleContainer, () => (
    h(Handle)
  ))
}
