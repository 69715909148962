import {Module} from 'vuex'
import {RootState} from '..'
import apiAuth from '../../api/auth'
import apiUser from '../../api/user'
import jwtDecode from 'jwt-decode'
import apiSettings from '@/api/settings'
import {datadogRum} from '@datadog/browser-rum'

const {
  login, getSignupStep, saveAgreeTerms, saveAccount, simplePasswordRegist
  , simplePasswordLogin, simplePasswordReset, superLogin, userExceptionCheck,
} = apiAuth()
const {getUserSetting} = apiSettings()
const {saveNicknameAPI, info: getUserInfo} = apiUser()
// state
interface User {
  agreeMarketing: boolean
  agreeMarketingAt: string
  email: string
  money: number
  name: string
  point: number
  settingInfo: any
  simplePasswordFlag: boolean
  token: string
  tokenValidDuration: number
  user: any
  uuid: string
}

// action
const User: Module<User, RootState> = {
  actions: {
    async ACT_AGREE_TERMS({state}, {agrees, uuid}) {
      const {agreeTerms, agreeMarketing, agreeThirdparty} = agrees
      return saveAgreeTerms(uuid, agreeTerms, agreeMarketing, agreeThirdparty)
    },
    ACT_CALC_TOKEN_VALID_TIME({state, dispatch}) {
      if (state.token) {
        const jwtObject: any = jwtDecode(state.token)
        const now = Date.now().valueOf() / 1000

        const remainSeconds = Math.round(jwtObject.exp - now)

        if (remainSeconds > 0) {
          state.tokenValidDuration = remainSeconds

          // TODO 1분 남짓 남았을 경우 로그인 연장할지 물어봄
          // if (remainSeconds < 60) {
          //   dispatch('ACT_SET_GLOBAL_TOAST'
          //     , {
          //       open: true,
          //       duration: 50000,
          //       message: '60초 후에 로그아웃 됩니다.\n연장하시겠습니까?',
          //       buttons: [{
          //         icon: 'star',
          //         text: '연장',
          //         handler: async () => {
          //           // console.log('연장 clicked')
          //           await refreshToken()
          //         },
          //       }, {
          //         text: '취소',
          //         role: 'cancel',
          //         handler: () => {
          //           // console.log('취소 clicked')
          //         },
          //       }],
          //     }
          //     , {root: true})
          // }
        } else {
          // 토큰 만료 처리
          (async () => {
            await dispatch('ACT_LOGOUT')
          })()
        }
      } else {
        state.tokenValidDuration = 0
      }
    },
    async ACT_CHOOSE_ACCOUNT({state}, chooseAccount) {
      const uuid = state.uuid
      return saveAccount(uuid, chooseAccount)
    },
    async ACT_GET_SIGNUP_STEP({state}, uuid) {
      const signupStep = await getSignupStep(uuid)
      // commit('SET_UUID', signupStep.uuid)

      return signupStep
    },
    async ACT_LOGIN({commit}, {email, password}) {
      // console.log(email, password)
      const account = await login(email, password)

      if (!account.error) {
        localStorage.setItem('user', JSON.stringify(account.user))
        localStorage.setItem('token', account.token)
        localStorage.setItem('email', account.user.email)
        localStorage.setItem('name', account.user.name)
        commit('SET_USER', account.user)
        commit('SET_TOKEN', account.token)
        commit('SET_EMAIL', account.user.email)
        commit('SET_NAME', account.user.name)
      }
      return account
    },
    async ACT_LOGOUT({commit, dispatch}) {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('email')
      localStorage.removeItem('name')
      // localStorage.removeItem('uuid')simple_password_flag
      commit('SET_USER', null)
      commit('SET_TOKEN', '')
      commit('SET_EMAIL', '')
      commit('SET_NAME', '')
      // commit("SET_UUID", null)
      dispatch('ACT_SET_GLOBAL_TOAST', {open: false}, {root: true})

      dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS', null, {root: true})
      dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS', null, {root: true})

      dispatch('message/ACT_UNREAD_COUNT', null, {root: true})

      // await closeSendBirdConnection()

      return true
    },
    async ACT_RESET_SIMPLE_PASSWORD({commit, state, dispatch}) {
      const uuid = state.uuid
      const check = await simplePasswordReset(uuid)
      if (check) {
        localStorage.removeItem('simple_password_flag')
        localStorage.removeItem('uuid')
        commit('SET_UUID', null)
        await dispatch('ACT_LOGOUT')
      } else {
        localStorage.removeItem('simple_password_flag')
        localStorage.removeItem('uuid')
        commit('SET_UUID', null)
        await dispatch('ACT_LOGOUT')
      }
    },
    ACT_RESET_TESTER({commit, dispatch}, uuid: string) {
      const storedUuid = localStorage.getItem('uuid')
      if (storedUuid === uuid) {
        localStorage.removeItem('uuid')
        commit('SET_UUID', null)
        dispatch('ACT_LOGOUT')
      }
    },

    async ACT_RESGIST_SIMPLE_PASSWORD({commit, state}, password) {
      const uuid = state.uuid
      const check = await simplePasswordRegist(uuid, password)
      if (check) {
        localStorage.setItem('simple_password_flag', check.simple_password_flag)
        commit('SET_SIMPLE_PASSWORD_FLAG', check.simple_password_flag)
      } else {
        // 등록되어 있지 않은 uuid이기 때문에 localstorage에서 폐기
        localStorage.removeItem('uuid')
        localStorage.removeItem('simple_password_flag')
        commit('SET_SIMPLE_PASSWORD_FLAG', false)
        commit('SET_UUID', null)
      }
      return check
    },

    async ACT_SAVE_NICKNAME({commit}, nickname: string) {
      const result = await saveNicknameAPI(nickname)
      localStorage.setItem('user', JSON.stringify(result.userInfo))
      commit('SET_USER', result.userInfo)
      return result
    },
    ACT_SAVE_SIMPLE_PASSWORD_LOCALSTORAGE({commit}, flag: string) {
      localStorage.setItem('simple_password_flag', flag)
      commit('SET_SIMPLE_PASSWORD_FLAG', flag)
    },
    ACT_SAVE_UUID_LOCALSTORAGE({state}, uuid) {
      localStorage.setItem('uuid', uuid)
    },
    ACT_SET_EMAIL({commit}, email: string) {
      commit('SET_EMAIL', email)
    },

    async ACT_SET_SETTING_INFO({commit}) {
      const settings = await getUserSetting()

      commit('SET_SETTING_INFO', settings)
      commit('SET_AGREE_MARKETING', settings.agreeMarketing)
      commit('SET_AGREE_MARKETING_AT', settings.agreeMarketingAt)
    },
    ACT_SET_SIMPLE_PASSWORD_FLAG({commit}, simplePasswordFlag: string) {
      // localStorage.setItem('uuid', uuid)
      commit('SET_SIMPLE_PASSWORD_FLAG', simplePasswordFlag)
    },
    ACT_SET_TOKEN({commit}, token: string) {
      commit('SET_TOKEN', token)
    },
    ACT_SET_USER({commit}, user: any) {
      commit('SET_USER', user)
    },
    ACT_SET_UUID({commit}, uuid: string) {
      // localStorage.setItem('uuid', uuid)
      commit('SET_UUID', uuid)
    },
    async ACT_SIMPLE_PASSWORD_LOGIN({commit, state, dispatch}, password) {
      const uuid = state.uuid
      const account = await simplePasswordLogin(uuid, password)
      // console.log(account)
      if (!account.error) {
        localStorage.setItem('user', JSON.stringify(account.user))
        localStorage.setItem('token', account.token)
        localStorage.setItem('email', account.user.email)
        localStorage.setItem('name', account.user.name)
        commit('SET_USER', account.user)
        commit('SET_TOKEN', account.token)
        commit('SET_EMAIL', account.user.email)
        commit('SET_NAME', account.user.name)
        // await initSendBird()
        dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS', null, {root: true})
        dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS', null, {root: true})
        dispatch('message/ACT_UNREAD_COUNT', null, {root: true})
        datadogRum.setUser({
          id: account.user.userId,
          name: account.user.name,
        })
      }
      return account
    },
    async ACT_SUPER_LOGIN({commit, state, dispatch}, password) {
      const uuid = state.uuid
      const account = await superLogin(uuid, password)
      // console.log(account)
      if (!account.error) {
        localStorage.setItem('user', JSON.stringify(account.user))
        localStorage.setItem('token', account.token)
        localStorage.setItem('email', account.user.email)
        localStorage.setItem('name', account.user.name)
        commit('SET_USER', account.user)
        commit('SET_TOKEN', account.token)
        commit('SET_EMAIL', account.user.email)
        commit('SET_NAME', account.user.name)
        // await initSendBird()
        dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS', null, {root: true})
        dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS', null, {root: true})
        dispatch('message/ACT_UNREAD_COUNT', null, {root: true})
        datadogRum.setUser({
          id: account.user.userId,
          name: account.user.name,
        })
      }
      return account
    },
    async ACT_TESTER_LOGIN({commit, dispatch}, password) {
      const uuid = 'ebeba18d-42df-42c3-97bf-5ab6c9a0c7d8'
      const account = await simplePasswordLogin(uuid, password)
      if (!account.error) {
        localStorage.setItem('user', JSON.stringify(account.user))
        localStorage.setItem('token', account.token)
        localStorage.setItem('email', account.user.email)
        localStorage.setItem('name', account.user.name)
        commit('SET_USER', account.user)
        commit('SET_TOKEN', account.token)
        commit('SET_EMAIL', account.user.email)
        commit('SET_NAME', account.user.name)
        // await initSendBird()
        dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS', null, {root: true})
        dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS', null, {root: true})
        dispatch('message/ACT_CHANNEL_INIT', null, {root: true})
        datadogRum.setUser({
          id: account.user.userId,
          name: account.user.name,
        })
      }
      return account
    },
    async ACT_TOKEN_REFRESH({commit}, token) {
      localStorage.setItem('token', token)
      commit('SET_TOKEN', token)
    },
    async ACT_USER_EXCEPTION_CHECK({commit, state}, token) {
      const {uuid} = await userExceptionCheck(token)
      localStorage.setItem('uuid', uuid)
      commit('SET_UUID', uuid)
      return uuid
    },
    DECREASE_POINT({commit, state}) {
      commit('SET_POINT', state.point - 100)
    },
    INCREASE_POINT({commit, state}) {
      commit('SET_POINT', state.point + 100)
    },
    async updateUserInfo({commit, state}) {
      if (!state.token) {
        return
      }
      const response: any = await getUserInfo()
      if (!response) {
        return
      }
      
      localStorage.setItem('user', JSON.stringify(response))
      commit('SET_USER', response)
    },
  },

  getters: {
    getAgreeMarketing: state => state.agreeMarketing,
    getAgreeMarketingAt: state => state.agreeMarketingAt,
    getEmail: state => state.email,
    getMoney: state => state.money,
    getName: state => state.name,
    getSettingInfo: state => state.settingInfo,
    getSimplePasswordFlag: state => state.simplePasswordFlag,
    getToken: state => state.token,
    getTokenValidDuration: state => state.tokenValidDuration,
    getUser: state => state.user,
    getUuid: state => state.uuid,
    hasSession: state => Boolean(state.token),
  },

  // RootState와 똑같이 작성해도 된다.
  mutations: {
    SET_AGREE_MARKETING(state, bool: boolean) {
      state.agreeMarketing = bool
    },
    SET_AGREE_MARKETING_AT(state, date: string) {
      state.agreeMarketingAt = date
    },
    SET_EMAIL(state, email: string) {
      state.email = email
      localStorage.setItem('email', email)
    },
    SET_MONEY(state, money: number) {
      state.money = money
    },
    SET_NAME(state, name: string) {
      state.name = name
    },
    SET_POINT(state, point: number) {
      if (point > state.point || point < 0) {
        return
      }
      state.point = point
    },
    SET_SETTING_INFO(state, settingInfo: any) {
      state.settingInfo = settingInfo
    },
    SET_SIMPLE_PASSWORD_FLAG(state, simplePasswordFlag: boolean) {
      state.simplePasswordFlag = simplePasswordFlag
    },
    SET_TOKEN(state, token: string) {
      state.token = token
    },
    SET_USER(state, user: any) {
      // console.log(user)
      state.user = user
    },
    SET_UUID(state, uuid: string) {
      state.uuid = uuid
    },
  },
  namespaced: true,
  state: {
    agreeMarketing: false,
    agreeMarketingAt: '',
    email: localStorage.getItem('email') || '',
    money: 864805320,
    name: localStorage.getItem('name') || '',
    point: 5000,
    settingInfo: null,
    simplePasswordFlag: (localStorage.getItem('simple_password_flag') === 'true'),
    token: localStorage.getItem('token') || '',
    tokenValidDuration: 0,
    user: localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') || '{}')
      : null,
    uuid: localStorage.getItem('uuid') || '',
  },
}

export default User
