
import {AgButton, AgToggleIcon, AgBox, AgText, AgModal, AgContent, AgHeader, AgRefresher, AgBoxS, AgTextS, AgButtonS} from '@/components/ag'
import {modalController} from '@ionic/vue'
import {arrowDownOutline} from 'ionicons/icons'
import {
  defineComponent,
  ref,
  computed,
  toRef,
  reactive,
  watch,
} from 'vue'
import {useStore} from 'vuex'
import StakeItem from './StakeItem.vue'
import ViewOptionModal from '@/components/Modal/ViewOptionModal.vue'
import RegistSellStakeModal from '@/views/view/regist-sell-stake/RegistSellStakeModal.vue'
import ViewMyStake from '@/views/view/view-my-stake/ViewMyStake.vue'
import StakeInfoModal from '@/views/view/stake-deals/StakeInfoModal.vue'
import BuyStakeModal from '@/views/view/buy-stake/BuyStakeModal.vue'
import LinkAccountModal from '@/views/view/link-account/LinkAccountModal.vue'
import {getSellInfoDetail} from '@/api/stake-deal'
import useModal from '@/composables/useModal'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

export default defineComponent({
  components: {
    AgBoxS,
    AgButtonS,
    AgContent,
    AgHeader,
    AgModal,
    AgRefresher,
    AgTextS,
    AgToggleIcon,
    BuyStakeModal,
    LinkAccountModal,
    RegistSellStakeModal,
    StakeInfoModal,
    StakeItem,
    ViewMyStake,
  },
  emits: ['close', 'edit'],
  name: 'StakeListsModal',
  props: {
    isOpen: {require: true, type: Boolean},
  },
  setup(props, {emit}) {
    const gtm = useGtm()
    const {getters, dispatch, commit} = useStore()
    const isOpenRef = toRef(props, 'isOpen')
    const user = computed(() => getters['user/getUser'])
    const allStakeList = computed(() => getters['stakeDeal/availableStakeList'] || [])
    const setOpen = () => emit('close')
    const myAllStakeList = computed(() => getters['stakeDeal/myAllStakeList'] || [])
    const isAvaliable = computed(() => myAllStakeList.value && myAllStakeList.value.length)
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const viewType = ref<any>('등록 최신순')
    const {useCompanyModal} = useModal()
    const filterFlag: any = reactive({
      myItem: false,
      withinAMonth: false,
    })

    watch(user, () => {
      if (user.value) {
        dispatch('stakeDeal/GET_ALL_STAKELIST')
        dispatch('stakeDeal/GET_MY_ALL_STAKELIST')
      }
    })

    const clickScreen = 'partnership-share-sell-modal'
    watch(isOpenRef, () => {
      if (isOpenRef.value) {
        viewType.value='등록 최신순'
        dispatch('stakeDeal/GET_ALL_STAKELIST')
        if (user.value) {
          dispatch('stakeDeal/GET_MY_ALL_STAKELIST')
        }

        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: clickScreen,
          title: '조합지분 거래 모달',
        })
      }
    })

    const closeModal = () => emit('close')

    const createModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: ViewOptionModal,
          componentProps: {
            requestType: 'stakeDeal',
            viewOption: 'none',
            viewType: viewType.value,
          },
          cssClass: 'view-option-modal',
          id: 'ViewOptionModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      modal.onDidDismiss().then(async (data) => {
        if (data.data?.viewType) {
          viewType.value = await data.data.viewType
        }
      })

      return modal.present()
    }


    const sortedStake = computed(() => {
      const array = [...allStakeList.value]
      if (viewType.value === '등록 최신순') {
        return array.sort((a: any, b: any) => {
          const aDate: any = new Date(a.createAt)
          const bDate: any = new Date(b.createAt)
          return bDate - aDate
        })
      } else if (viewType.value === '주당 가격순') {
        return array.sort((a: any, b: any) => {
          if (Number(b.price) > Number(a.price)) {
            return -1
          } else if (Number(a.price) > Number(b.price)) {
            return 1
          }
          return 0
        })
      } else if (viewType.value === '판매 금액순') {
        return array.sort((a: any, b: any) => {
          if (Number(b.totalPrice) > Number(a.totalPrice)) {
            return -1
          } else if (Number(a.totalPrice) > Number(b.totalPrice)) {
            return 1
          }
          return 0
        })
      }
        return array
      
    })

    function checkWithinAMonth(currentDate: any) {
      const date: any = new Date((currentDate || '').replace(/-/g, '/').replace(/[TZ]/g, ' ').split('.')[0])
      const diff = ((Date.now() - date) / 1000)
      const dayDiff = Math.floor(diff / 86400)
      return dayDiff < 30
    }

    function viewToggle(item: any) {
      if (filterFlag.myItem && filterFlag.withinAMonth) {
        return item.mine && checkWithinAMonth(item.createAt)
      }

      if ((filterFlag.myItem && item.mine) || (!filterFlag.myItem && !filterFlag.withinAMonth)) {
        return true
      }

      if (filterFlag.withinAMonth) {
        return checkWithinAMonth(item.createAt)
      }

      return false
    }

    const displayedCount = computed(()=>{
      const filtered = sortedStake.value.filter((item: any)=>{
        return viewToggle(item)
      })
      return filtered.length
    })

    // 지분거래 관련 코드들

    const stakeSellOpen = ref<any>(false)
    const viewMyStakeOpen = ref<any>(false)
    const detailInfoOpen = ref<any>(false)
    const buyStakeModal = ref<any>(false)
    const linkAccountModal = ref<any>(false)
    const viewOpenType = ref<string>('')
    const SellOpenType = ref<string>('')
    const afterLinked = ref<string>('')

    const branchResult = () => {
      viewOpenType.value = 'result'

      stakeSellOpen.value = false
      viewMyStakeOpen.value = true
      dispatch('stakeDeal/GET_ALL_STAKELIST')
    }

    const dataRefresh = async () => {
      await dispatch('stakeDeal/GET_ALL_STAKELIST')
    }

    const printDetailModal = async (dealInfo: any) => {
      let deal = await getSellInfoDetail(dealInfo.partnershipShareSellId)
      if (deal.mine) {
        deal.type = 'view'
        await commit('stakeDeal/SET_REGISTER_RESULT', deal)
        viewOpenType.value = 'view'
        viewMyStakeOpen.value = true
      } else if (!deal.mine) {
        await dispatch('stakeDeal/SELECT_DEAL', deal)
        detailInfoOpen.value = true
      }
    }

    const editMyStake = async () => {
      SellOpenType.value = await 'edit'

      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
linkAccountModal.value = true
} else if (currentStep.value === 'COMPLETE') {
stakeSellOpen.value = await true
}

      viewMyStakeOpen.value = await false
    }

    const reloadSellData = async () => {
      await dataRefresh()
      viewMyStakeOpen.value = await false
    }

    const reloadBuyData = async () => {
      await dataRefresh()
      detailInfoOpen.value = await false
    }

    const moveToMyPage = async () => {
      await dispatch('stakeDeal/GET_ALL_STAKELIST')
      buyStakeModal.value = await false
      detailInfoOpen.value = await false
      await emit('close')
    }

    const buyNextProcess = (modal: any) => {
      afterLinked.value = 'buy'

      if (modal === 'linkAccount') {
        linkAccountModal.value = true
      } else if (modal === 'stakeBuy') {
        buyStakeModal.value = true
      }
    }

    const checkIsLinked = async () => {
      afterLinked.value = 'sell'
      SellOpenType.value = 'create'

      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
        linkAccountModal.value = true
      } else if (currentStep.value === 'COMPLETE') {
        stakeSellOpen.value = true
      }
    }

    const doRefresh = async (event: any) => {
      // console.log('Begin async operation');
      await dispatch('stakeDeal/GET_ALL_STAKELIST')
      if (user.value) {
        await dispatch('stakeDeal/GET_MY_ALL_STAKELIST')
      }
      event.target.complete()
    }

    const afterLinkAccountAct = () => {
      if (afterLinked.value === 'buy') {
        buyStakeModal.value = true
      } else {
        stakeSellOpen.value = true
      }
      linkAccountModal.value = false
    }

    const sellingMessage = computed(() => {
      if (!user.value) {
        return '로그인 후 조합원 자격 보유여부를 알 수 있어요'
      }
      if (myAllStakeList.value && myAllStakeList.value.length > 0) {
        return '참여하신 조합의 조합원 자격을 거래해보세요'
      }
      return '아직 판매 가능한 조합원 자격이 없습니다'
    })

    return {
      SellOpenType,
      afterLinkAccountAct,
      afterLinked,
      allStakeList,
      arrowDownOutline,
      branchResult,
      buyNextProcess,
      buyStakeModal,
      checkIsLinked,
      clickScreen,
      closeModal,
      createModal,
      dataRefresh,
      detailInfoOpen,
      displayedCount,
      doRefresh,
      editMyStake,
      filterFlag,
      isAvaliable,
      isOpenRef,
      linkAccountModal,
      moveToMyPage,
      printDetailModal,
      reloadBuyData,
      reloadSellData,
      sellingMessage,
      setOpen,
      sortedStake,
      stakeSellOpen,
      useCompanyModal,
      user,
      viewMyStakeOpen,
      viewOpenType,
      viewToggle,
      viewType,
    }
  },
})
