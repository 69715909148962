import {defineComponent, h, FunctionalComponent, ExtractPropTypes} from 'vue'
import {HeadHandle, StyledHeader} from './components'
import {systemProps} from '@/plugins/emotion'

export const agHeaderSProps = {
  handle: {default: false, type: Boolean},
  handleCss: {type: Object},
}

export type AgHeaderSProps = ExtractPropTypes<typeof agHeaderSProps>

export const AgHeaderS: FunctionalComponent<AgHeaderSProps> = (props, {slots}) => {
  const {handle, handleCss} = props
  return (
    h(StyledHeader, () => [
      handle ? h(HeadHandle, {css: handleCss}) : null,
      slots,
    ])
  )
}

export const agHeaderProps = {
  ...agHeaderSProps,
  ...systemProps,
}


export const AgHeader = defineComponent({
  name: 'AgHeader',
  props: {
    ...agHeaderProps,
  },
  setup(props, {slots}) {
    return () => {
      const {handle, handleCss, ...rest} = props

      return h(StyledHeader, {css: rest}, () => [
        handle ? h(HeadHandle, {css: handleCss}) : null,
        slots?.default?.(),
      ])
    }
  },
})
