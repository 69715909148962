import {getResponsiveProp} from './utils'
import {BlocksType} from '@/plugins/emotion'
import {system} from '@winter-love/style-system'
import css from '@styled-system/css'

export const blockSystems = system({
  block: Object.assign((value: any, scale: any, props: any) => {
    const style = scale?.[value]
    return style ? css(style)(props) : {}
  }, {
    scale: 'blocks',
  }),
})

export const blockProps = {
  block: getResponsiveProp<BlocksType>(),
}
