
import {defineComponent, computed, ref} from 'vue'
import {AgText, AgTextS, AgHeader, AgBox, AgButton, AgFooter, AgContent} from '@/components/ag'
import {modalController} from '@ionic/vue'
import {getFormattedDate} from '@/utils/filters'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgText,
    AgTextS,
  },
  name: 'DealInfoModal',
  props: {
    deal: {default: null, type: Object},
  },
  setup(props) {
    const deal = computed(() => props.deal)
    const transactionPrice = computed(() => {
      return deal.value.type === '판매' ? Number(deal.value.totalPrice) - Number(deal.value.fee) - Number(deal.value.carriedInterest) : Number(deal.value.totalPrice) + Number(deal.value.fee)
    })

    const dismiss = () => {
      modalController.dismiss()
    }

    const formattedDate = (updateDate: any) => {
      return getFormattedDate(updateDate, 'MM.DD HH:mm')
    }

    return {
      dismiss,
      formattedDate,
      transactionPrice,
    }
  },
})
