import {getMayObjectItem} from '@/utils'
import {getResponsiveProp} from './utils'
import {system, ResponsiveValue} from '@winter-love/style-system'
import {FillColorsType, fillColorSystem} from './fill-color'
import {OutlineColorsType, outlineColorSystem} from './outline-color'

export interface FillOutlineProps {
  fill?: ResponsiveValue<FillColorsType>
  outline?: ResponsiveValue<OutlineColorsType>
}

export const fillOutlineSystems = system({
  fill: (value: string, _, props, index) => {
    const outlineProp = props.outline
    const outline = getMayObjectItem(outlineProp, index, -1)
    if (outline || !value) {
      return {}
    }
    return fillColorSystem({...props, fillColor: value})
  },
  outline: (value: string, _, props) => {
    if (!value) {
      return {}
    }
    return outlineColorSystem({...props, outlineColor: value})
  },
})

export const fillOutlineProps = {
  fill: getResponsiveProp<FillColorsType>(),
  outline: getResponsiveProp<OutlineColorsType>(),
}
