const w9 = 900
const w8 = 800
const w7 = 700
const w6 = 600
const w5 = 500
const w4 = 400
const w3 = 300

const bold = w7
const extraBold = w8
const medium = w5
const normal = w4
const light = w3

export const fontWeights = {
  bold,
  extraBold,
  light,
  medium,
  normal,
  w3,
  w4,
  w5,
  w6,
  w7,
  w8,
  w9,
}

export type FontWeights = typeof fontWeights
export type FontWeightsType = keyof FontWeights | string | number
