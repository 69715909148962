import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_alert = _resolveComponent("ag-alert")!

  return (_openBlock(), _createBlock(_component_ag_alert, {
    "backdrop-dismiss": false,
    buttons: [_ctx.yesUpdateButton],
    header: _ctx.title,
    "model-value": _ctx.isShowUpdateAlert,
    message: _ctx.message,
    "css-class": "update-alert"
  }, null, 8, ["buttons", "header", "model-value", "message"]))
}