
import {AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader, AgBox} from '@/components/ag'
import {defineComponent, computed, defineAsyncComponent, toRef} from 'vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {cancelFromBuyer} from '@/api/stake-deal'
import {useStore} from 'vuex'
import {alertController} from '@ionic/vue'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    ModalHeader,
  },
  emits: ['closeDepositModal', 'delete:purchaseHistory'],
  name: 'ViewDeposit',
  props: {
    deposit: {require: true, type: Object},
    isOpen: {require: true, type: Boolean},
  },
  setup(props, {emit}) {
    const {dispatch} = useStore()
    const depositRef: any = toRef(props, 'deposit')

    const cancelPurchase = async () => {
      const result = await cancelFromBuyer(depositRef.value.partnershipShareSellId, depositRef.value.partnershipSharePurchaseId)
      if (result) {
        emit('delete:purchaseHistory')
        emit('closeDepositModal')
      }
    }

    async function cancelToBuy() {
      const alert = await alertController
          .create({
            buttons: [
              {
                cssClass: 'btn-gray',
                role: 'cancel',
                text: '아니오',
              },
              {
                handler: async () => {
                  cancelPurchase()
                },
                text: '네, 취소할게요',
              },
            ],
            cssClass: 'btn-groups adderss-alert-class',
            message: `
          <div class="alert_css">
            <div class="header">
              구매를 취소하시겠어요?
            </div>
            <div class="message">
              구매 요청 내역, 구매금 입금 계좌와<br>
              조합가입 확인서는 모두 삭제됩니다
            </div>
          </div>`,
          })

      return alert.present()
    }

    const remainingSeconds = computed(() => {
      if (depositRef.value.remainingSeconds >= 60) {
        return `${Math.floor(depositRef.value.remainingSeconds / 60)}분 전`
      } 
        return `${depositRef.value.remainingSeconds}초 전`
      
    })
    return {cancelToBuy, depositRef, remainingSeconds}
  },
})
