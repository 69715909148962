
import {computed, defineComponent, onBeforeMount, ref, watch} from 'vue'
import {modalController, IonContent} from '@ionic/vue'
import apiSettings from '@/api/settings'

export default defineComponent({
  components: {
    IonContent,
  },
  name: 'AccountModal',
  props: {
    accountType: null,
  },
  setup(props) {
    const dismiss = () => {
      modalController.dismiss()
    }

    const bankList = ref<any>(null)
    const type = computed(() => props.accountType)

    watch(bankList, () => {
      // console.log(bankList.value);
    })

    onBeforeMount(() => {
      if (type.value === 'refund') {
        apiSettings().getBankList()
          .then((list) => {
            bankList.value = list.banks
          })
      } else {
        apiSettings().getStockBankList()
          .then((list) => {
            bankList.value = list.stockBanks
          })
      }
    })

    function selectBank(bankInfo: any) {
      const data = {
        bankInfo: bankInfo,
        type: type.value,
      }
      modalController.dismiss(data)
    }
    const clickScreen = 'account-modal'
    return {bankList, clickScreen, dismiss, selectBank, type}
  },
})
