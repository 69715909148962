<template>
  <ion-content
    :translucent="true"
  >
    <div class="box">
      <div class="title">
        출금 가능 금액
      </div>
      <div class="info">
        왜 예수금과 출금 가능 금액이 다른가요?<br>
        <ul>
          <li>전자금융 사기 피해 방지를 위해 충전일로부터 24시간이 지난 후 출금이 가능합니다.</li>
          <li>사용 대기 예수금이 있을 경우 해당 금액을 제외한 금액을 출금할 수 있습니다.</li>
        </ul>
      </div>
      <div class="title">
        출금 수수료 1,000원
      </div>
      <div class="info">
        엔젤리그 지갑에서 예수금을 출금할 때 수수료 1,000원이 부과됩니다.<br>
        신청한 출금 희망 금액에서 자동으로 1,000원이 제외되고 입금됩니다.
      </div>
    </div>
  </ion-content>
</template>

<script>
import {IonContent} from '@ionic/vue'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {IonContent},
  name: 'Fee',
})
</script>

<style scoped>
ion-content {
--background: #222;
}

.text {
color: #C0C0C0;
}

.title {
color: #fff;
font-size: 16px;
  font-weight: bold;
}

.info {
color: #B6BBC6;
font-size: 14px;
margin-top: 8px;
margin-bottom: 8px;
}

.box {
padding: 20px;
}

ul{
  padding-left: 16px;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
}
</style>
