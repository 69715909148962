import {AgAlphabetKeypad, agAlphabetKeypadProps, AgAlphabetKeypadDefaultSlotProps} from '../ag-alphabet-keypad'
import {defineComponent, h} from 'vue'
import {AgButtonS} from '@/components/ag'

const KEY_PAD_COUNTS = 9

const keyList = Array(KEY_PAD_COUNTS).fill(null).map((_, index) => {
  return String(index + 1)
})
keyList.push('0')

export const AgNumberKeypad = defineComponent({
  inheritAttrs: false,
  name: 'AgNumberKeypad',
  props: {
    ...agAlphabetKeypadProps,
    keyList: {default: () => [...keyList], type: Array},
    shuffle: {default: false, type: Boolean},
  },
  setup(props, {attrs}) {
    return () => (
      h(AgAlphabetKeypad, {...attrs, ...props, css: {gridTemplateColumns: 'repeat(3, 1fr)'}}, {
        default: ({onClick, key, children, index}: AgAlphabetKeypadDefaultSlotProps) => [
          h(AgButtonS, {
            css: {
              bg: 'transparent',
              borderColor: 'transparent',
              color: 'white',
              corner: 'full',
              gridColumnStart: index === KEY_PAD_COUNTS ? 2 : undefined,
              height: 30,
              p: 0,
              typography: 'tit24b',
              width: 30,
            },
            key,
            onClick,
          }, () => children),
        ],
      })
    )
  },
})
