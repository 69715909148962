/* eslint-disable no-mixed-operators */
import {Plugins} from '@capacitor/core'
import {getTokenString} from '../api/auth-header'
import {isPlatform} from '@/composables'
import dayjs from 'dayjs'
const {Browser} = Plugins

function numberFormat(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const currency = (value: any) => {
  // // console.log(value);
  const num = Number.parseInt(value)
  if (!isNaN(num)) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
  }
    return value

}

const perCurrency = (value: any) => {
  const num = Number.parseFloat(value)
  if (!isNaN(num) && Number.isInteger(num)) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
  } else if (!isNaN(num) && !Number.isInteger(num)) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
  }
    return value

}

const toFixed = (number: number, digit: number) => {
  if (typeof number === 'number') {
    return number.toFixed(digit)
  }
    return number

}

const numberToKorean = (_number: number) => {
  let number = _number
  if (number < 10000) {
    return Math.floor(number)
  } else if (number > 10000000) {
    number = Math.floor(number * 0.0000001) * 10000000
  }

  const inputNumber = number < 0 ? 0 : number
  const unitWords = ['', '만', '억', '조', '경']
  const splitUnit = 10000
  const splitCount = unitWords.length
  const resultArray = []
  let resultString = ''

  for (let index = 0; index < splitCount; index++) {
    let unitResult = (inputNumber % splitUnit ** (index + 1)) / splitUnit ** index
    unitResult = Math.floor(unitResult)
    if (unitResult > 0) {
      resultArray[index] = unitResult
    }
  }

  for (const [index, element] of resultArray.entries()) {
    if (!element) {
continue
}
    resultString = String(numberFormat(element)) + unitWords[index] + ' ' + resultString
  }

  return resultString.trim()
}

export const only3 = (string?: string) => {
  if (!string) {
    return ''
  }
  return `${string.slice(0, 3)}***`
}

function getFormatDate(date: Date) {
  const year: any = date.getFullYear() // yyyy
  let month: any = (1 + date.getMonth()) // M
  month = month >= 10 ? month : '0' + month // month 두자리로 저장
  let day: any = date.getDate() // d
  day = day >= 10 ? day : '0' + day // day 두자리로 저장
  return year + '-' + month + '-' + day // '-' 추가하여 yyyy-mm-dd 형태 생성 가능
}

function clubDate(date: string) {
  const dateList = date.split('-')
  return dateList[1] + '.' + dateList[2]
}

function getDate(value: any) {
  const date = new Date(value)
  let month: any = (1 + date.getMonth()) // M
  month = month >= 10 ? month : '0' + month // month 두자리로 저장
  let day: any = date.getDate() // d
  day = day >= 10 ? day : '0' + day // day 두자리로 저장
  return month + '.' + day // '-' 추가하여 yyyy-mm-dd 형태 생성 가능
}

function printFileNameMaxlength(filename: string, _length: number) {
  let length = _length
  if (!filename) {
return
}
  const _fileLength = filename.length
  const _lastDot = filename.lastIndexOf('.')
  const _fileExtension = filename.substring(_lastDot, _fileLength).toLowerCase()
  const ellipsis = (length < _lastDot)
  length = ellipsis ? length : _lastDot
  const _fileName = filename.slice(0, Math.max(0, length))
  return `${_fileName}${ellipsis ? '...' : ''}${_fileExtension}`
}

function numToHan(_num: any) {
  let num = _num
  num = num.toString().split('.')[0]
  num = String(Number.parseInt((String(num)).replace(/\D/g, ''), 10)) // 숫자/문자/돈 을 숫자만 있는 문자열로 변환

  // console.log(num);

  if (num === '0') {
 return '영'
}

  if (isNaN(num)) {
 return ''
}

  const number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']
  const unit = ['', '만', '억', '조']
  const smallUnit = ['천', '백', '십', '']

  const result = [] // 변환된 값을 저장할 배열

  const unitCnt = Math.ceil(num.length / 4) // 단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
  num = num.padStart(unitCnt * 4, '0') // 4자리 값이 되도록 0을 채운다
  const regexp = /[\W\w]{4}/g // 4자리 단위로 숫자 분리
  const array = num.match(regexp)
  // 낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)

  function _makeHan(_text: any) {
    let text = _text
    let hanString = ''
    text = text.split('')

    for (const [index, num] of text.entries()) {
      if (num === '0') {
continue
}
      hanString += number[num] + smallUnit[index]
    }
    return hanString
  }

  for (let index = array.length - 1, unitCnt = 0; index >= 0; index--, unitCnt++) {
    const hanValue = _makeHan(array[index]) // 한글로 변환된 숫자
    // 값이 없을땐 해당 단위의 값이 모두 0이란 뜻.
    if (hanValue === '' || hanValue === undefined || hanValue == null) {
      continue
    }

    result.unshift(hanValue + unit[unitCnt]) // unshift는 항상 배열의 앞에 넣는다.
  }
  // 여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사

  return result.join('')
}

const isValidDate = (d:any) => {
  return d instanceof Date && !isNaN(Number(d))
}

const prettyDateToOnemonth = (time: any, type?: any) => {
  let _time = time
  if (!_time) {
    return
  }
  let date: any = ''

  if (type === 'channel') {
    const timeToDate = new Date(_time)
    date = new Date(getFormatDate(timeToDate).replace(/-/g, '/') + ' ' + timeToDate.toString().split(' ')[4])
    _time = getFormatDate(timeToDate)
  } else {
    date = new Date((_time || ''))//.replace(/-/g, '/').replace(/[TZ]/g, ' ').split('.')[0])
    if(!isValidDate(date)){
      date = new Date((_time.replace(/ /gi, 'T')))
    }

  }
  let diff = ((Date.now() - date.getTime()) / 1000)
  // diff = diff - 33000;

  if (diff < 0) {
    diff = 0
  }
  const dayDiff = Math.floor(diff / 86400)

  if (isNaN(dayDiff) || dayDiff < 0) {
    return
  }

  return dayDiff === 0 && (
    diff < 60 && '방금' ||
    diff < 120 && '1분 전' ||
    diff < 3600 && Math.floor(diff / 60) + '분 전' ||
    diff < 7200 && '1시간 전' ||
    diff < 86400 && Math.floor(diff / 3600) + '시간 전') ||
    dayDiff === 1 && '어제' ||
    dayDiff < 30 && dayDiff + '일 전' ||
    _time.slice(0, 7).replace(/-/gi, '.')
}
const urlify = (text: any, clickfnc: any) => {
  const urlRegex = /https?:\/\/(www\.)?[\w#%.:=@~-]{1,256}\.[\d()A-Za-z]{1,6}\b([\w#%&()./:=?@~-]*)?/g
  return text.replace(urlRegex, function(url: any) {
    if (clickfnc) {
      return '<a class="content-link" target="_blank" data-href="' + url + '">' + url + '</a>'
    }
      return '<a class="content-link" target="_blank" href="' + url + '">' + url + '</a>'

  })
}
const printContent = (contents: any, clickfnc: any) => {
  return urlify(contents.replace(/\n/gi, '<br/>'), clickfnc)
}

async function openLink(url: string) {
  return Browser.open({url: url})
}



function deviceParameter() {
  const isHybrid = isPlatform('hybrid')
  return `device=${isHybrid.value ? 'app' : 'web'}`
}

async function openLinkWithAccessToken(url: string) {
  const delim = ~url.indexOf('?') ? '&' : '?'
  return Browser.open({url: `${url}${delim}accessToken=${getTokenString()}`})
}

async function openLinkWithAccessTokenAndSocketId(url: string, socketId: string) {
  const delim = ~url.indexOf('?') ? '&' : '?'
  return Browser.open({url: `${url}${delim}accessToken=${getTokenString()}&socketId=${socketId}&${deviceParameter()}`})
}

async function openLinkWithSocketId(url: string, socketId: string, deviceCheck: boolean) {
  const delim = ~url.indexOf('?') ? '&' : '?'
  return Browser.open({url: `${url}${delim}&socketId=${socketId}&${deviceCheck ? deviceParameter() : ''}`})
}

const printDate = (createdAt: string) => {
  if (!createdAt) {
return ''
}
  return createdAt.slice(0, 10).replace(/-/gi, '. ')
}

export const uuid4 = () => {
  let d = Date.now()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = ((d + Math.random() * 16) % 16) | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const stockOptionPeriod = (period: any) => {
  if (period.includes('Invalid date')) {
return
}
  const [from, to] = [...period.split(' ~ ')]

  const date = new Date()
  let [fromYear, fromMonth] = [...from.split('.')]
  let [toYear, toMonth] = [...to.split('.')]

  fromYear = Number.parseInt(fromYear)
  fromMonth = Number.parseInt(fromMonth)
  toYear = Number.parseInt(toYear)
  toMonth = Number.parseInt(toMonth)

  const currentYear = date.getFullYear()
  const currentMonth = date.getMonth() + 1

  // // console.log(fromYear + fromMonth + " / " + toYear + toMonth + " / " + curYear + curMonth);

  if ((currentYear > fromYear || (currentYear === fromYear && currentMonth >= fromMonth)) && (currentYear < toYear || (currentYear === toYear && currentMonth <= toMonth))) {
    if ((toYear === currentYear && toMonth - currentMonth < 6) || (toYear - currentYear === 1 && (toMonth + 12) - currentMonth < 6)) {
      const left = toYear - currentYear === 1 && toMonth - currentMonth < 0 ? (toMonth + 12) - currentMonth : toMonth - currentMonth
      return `<span class="red">행사 가능 기간 ${left}개월 남았어요. 서두르세요!</span>`
    }
      return '<span class="deep-blue">현재 행사 가능한 기간이에요!</span>'

  } else if (currentYear > toYear || (currentYear === toYear && currentMonth > toMonth)) {
    return '<span class="gray">행사 가능 기간이 지났어요 😭</span>'
  } else if (currentYear < fromYear || (currentYear === fromYear && currentMonth < fromMonth)) {
    const leftYear = fromMonth - currentMonth < 0 ? fromYear - currentYear - 1 : fromYear - currentYear
    const leftMonth = fromMonth - currentMonth < 0 ? (fromMonth + 12) - currentMonth : fromMonth - currentMonth
    return `<span class="deep-blue">${leftYear}년 ${leftMonth}개월 후에 행사 가능해요!</span>`
  }
}

export const convertDate = (dateString: string) => {
  const yyyyMMdd = String(dateString).split('.')
  const year = yyyyMMdd[0]
  const month = yyyyMMdd[1]
  const day = yyyyMMdd[2]

  return new Date(Number(year), Number(month) - 1, Number(day))
}

export const getDateDiff = (today: Date, expireDate: Date) => {
  return (expireDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
}

export const splitDate = (dateString: string) => {
  const year = String(dateString).slice(0, 4)
  const month = String(dateString).slice(4, 6)
  const day = String(dateString).slice(6, 8)

  return year + '. ' + month + '. ' + day
}

export const getFormattedDate = (updateDate: any, dateFormat: string) => {
  if (updateDate) {
    return dayjs(updateDate.replace(/\./gi, '/')).format(dateFormat)
  }
}

export const getMonthDay = (date: Date) => {
  const stringArray = date.toString().split('-')
  stringArray[1] = stringArray[1].charAt(0) === '0' ? stringArray[1].charAt(1) : stringArray[1]
  return stringArray[1] + '. ' + stringArray[2]
}

export {printDate, perCurrency, currency, numberToKorean, getFormatDate, getDate, printFileNameMaxlength, numToHan, toFixed, prettyDateToOnemonth, urlify, printContent, openLink, openLinkWithAccessToken, openLinkWithAccessTokenAndSocketId, clubDate, openLinkWithSocketId, deviceParameter as deviceParam}
