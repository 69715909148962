
import {IonPage, IonContent, IonHeader, IonToolbar, IonBackButton, IonButtons, modalController} from '@ionic/vue'
import {defineComponent, onBeforeMount, computed, ref} from 'vue'
import {chevronBack} from 'ionicons/icons'
import {useRoute, useRouter} from 'vue-router'
import apiWithdrawal from '@/api/withdrawal'

export default defineComponent({
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
  },
  name: 'DeleteAccount',
  setup() {
    const router = useRouter()
    const {getSurveyItems} = apiWithdrawal()
    const surveyItems = ref<any>(null)
    const surveyTitle = ref<any>(null)

    const dismiss = (id: any) => {
      modalController.dismiss(id)
    }

    onBeforeMount(() => {
      getSurveyItems()
        .then((data) => {
          surveyTitle.value = data.title
          surveyItems.value = data.items.slice(1)
        })
    })

    return {
      chevronBack,
      dismiss,
      router,
      surveyItems,
      surveyTitle,
    }
  },
})
