import {getTokenString} from '@/api/auth-header'
import SendBird from 'sendbird'
import {SendBirdChatEvent} from '@/plugins/SendBirdChatEvent'
import {SendBirdConnection} from '@/plugins/SendBirdConnection'
import {store} from '@/vuex'

// const appId = 'E541C962-CE85-41B1-9651-69537658312D';
const appId = 'E541C962-CE85-41B1-9651-69537658312Dtest'

const createConnectionHandler = () => {
  const connectionManager = new SendBirdConnection()
  console.log(':::::::createConnectionHandler:::::::::::')
  connectionManager.onReconnectStarted = () => {
    console.log('[SendBird JS SDK] Reconnect : Started')
    // connectionManager.channel = chat.channel;
  }
  connectionManager.onReconnectSucceeded = () => {
    console.log('[SendBird JS SDK] Reconnect : Succeeded')
    // chat.refresh(connectionManager.channel);
  }
  connectionManager.onReconnectFailed = () => {
    console.log('[SendBird JS SDK] Reconnect : Failed')
    connectionManager.remove()
  }
}

let instance: any = null
class SendBirdAction {
  
  sb: any;
  userQuery: any;
  openChannelQuery: any;
  groupChannelQuery: any;
  previousMessageQuery: any;
  participantQuery: any;
  blockedQuery: any;
  connected: any;

  constructor() {
    if (instance) {
      return instance
    }
    this.connected = false
    this.sb = new SendBird({
      appId,
    })
    this.userQuery = null
    this.openChannelQuery = null
    this.groupChannelQuery = null
    this.previousMessageQuery = null
    this.participantQuery = null
    this.blockedQuery = null
    instance = this
  }

  connect(userId: any, nickname: any) {
    return new Promise((resolve, reject) => {
      const sb = SendBird.getInstance()
      sb.connect(
        userId,
        (user, error) => {
          if (error) {
            reject(error)
          } else {
            this.connected = true
            sb.updateCurrentUserInfo(decodeURIComponent(nickname), '', (user, error) => {
              if (error) {
                reject(error)
                return
              }

              resolve(user)
            })
          }
        },
      )
    })
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      this.sb.disconnect((response: any, error: any) => {
        if(error){
          reject(error)
        }else{
          instance = null
          this.connected = false
          resolve(null)
        }
      })
    })
  }

  isNull(value: any) {
    try {
      return value === null
    } catch {
      return false
    }
  }

  getGroupChannelList(isInit = false) {
    if (isInit || this.isNull(this.groupChannelQuery)) {
      this.groupChannelQuery = this.sb.GroupChannel.createMyGroupChannelListQuery()
      this.groupChannelQuery.limit = 100
      this.groupChannelQuery.includeEmpty = false
      this.groupChannelQuery.order = 'latest_last_message'
    }
    return new Promise((resolve, reject) => {
      if (this.groupChannelQuery.hasNext && !this.groupChannelQuery.isLoading) {
        this.groupChannelQuery.next((list: any, error: any) => {
          if (error) {
            reject(error)
            return
          }
          resolve(list)
        })
      } else {
        resolve([])
      }
    })
  }

  getCurrentUser() {
    return this.sb.currentUser
  }

  isCurrentUser(user: any) {
    return user.userId === this.sb.currentUser.userId
  }

  getUserList(isInit = false) {
    if (isInit || this.isNull(this.userQuery)) {
      this.userQuery = this.sb.createApplicationUserListQuery()
      this.userQuery.limit = 30
    }
    return new Promise((resolve, reject) => {
      if (this.userQuery.hasNext && !this.userQuery.isLoading) {
        this.userQuery.next((list: any, error: any) => {
          if (error) {
            reject(error)
            return
          }
          resolve(list)
        })
      } else {
        resolve([])
      }
    })
  }

  getBlockedList(isInit = false) {
    if (isInit || this.isNull(this.blockedQuery)) {
      this.blockedQuery = this.sb.createBlockedUserListQuery()
      this.blockedQuery.limit = 30
    }
    return new Promise((resolve, reject) => {
      if (this.blockedQuery.hasNext && !this.blockedQuery.isLoading) {
        this.blockedQuery.next((blockedList: any, error: any) => {
          if (error) {
            reject(error)
            return
          }
          resolve(blockedList)
        })
      } else {
        resolve([])
      }
    })
  }

  blockUser(user: any, isBlock = true) {
    return new Promise((resolve, reject) => {
      if (isBlock) {
        this.sb.blockUser(user, (response: any, error: any) => {
          if (error) {
            reject(error)
            return
          }
          resolve(null)
        })
      } else {
        this.sb.unblockUser(user, (response: any, error: any) => {
          if (error) {
            reject(error)
            return
          }
          resolve(null)
        })
      }
    })
  }

  getChannel(channelUrl: string, isOpenChannel = true) {
    return new Promise((resolve, reject) => {
      if (isOpenChannel) {
        this.sb.OpenChannel.getChannel(channelUrl, (openChannel: any, error: any) => {
          if (error) {
            reject(error)
            return
          }
          resolve(openChannel)
        })
      } else {
        this.sb.GroupChannel.getChannel(channelUrl, (groupChannel: any, error: any) => {
          if (error) {
            reject(error)
            return
          }
          resolve(groupChannel)
        })
      }
    })
  }

  leave(channelUrl: string) {
    return new Promise((resolve, reject) => {
      this.sb.GroupChannel.getChannel(channelUrl, (groupChannel: any, error: any) => {
        if (error) {
          reject(error)
        } else {
          groupChannel.leave((response: any, error: any) => {
            if (error) {
              reject(error)
              return
            }
            resolve(null)
          })
        }
      })
    })
  }

  hide(channelUrl: string) {
    return new Promise((resolve, reject) => {
      this.sb.GroupChannel.getChannel(channelUrl, (groupChannel: any, error: any) => {
        if (error) {
          reject(error)
        } else {
          groupChannel.hide((response: any, error: any) => {
            if (error) {
              reject(error)
              return
            }
            resolve(null)
          })
        }
      })
    })
  }

  markAsRead(channel: any) {
    channel.markAsRead()
  }

  getMessageList(channel: any, isInit: any = false) {
    if (isInit) {
      this.previousMessageQuery = channel.createPreviousMessageListQuery()
    }
    return new Promise((resolve, reject) => {
      if (this.previousMessageQuery.hasMore && !this.previousMessageQuery.isLoading) {
        this.previousMessageQuery.load(100, false, (messageList: any, error: any) => {
          if (error) {
            reject(error)
            return
          }
          resolve(messageList)
        })
      } else {
        resolve([])
      }
    })
  }

  getMessagesByMessageId(channel: any, MessageId: any, type: any) {
    const params = new this.sb.MessageListParams()
    params.isInclusive = !(type === 'receive' || type === 'send')
    params.prevResultSize = 0
    params.nextResultSize = type === 'update' ? 0 : 10
    return new Promise((resolve, reject) => {
      channel.getMessagesByMessageId(MessageId, params, function(messages: any, error: any) {
        if (error) {
          reject(error)
          return
        }
        resolve(messages)
      })
    })
  }

  getReadReceipt(channel: any, message: any) {
    if (this.isCurrentUser(message.sender)) {
      return channel.getReadReceipt(message)
    } 
      return 0
    
  }

  sendUserMessage({
    channel,
    message,
    status,
    handler,
  }: any) {
    const params = new this.sb.UserMessageParams()
    params.message = message
    params.data = status

    return channel.sendUserMessage(params, (message: any, error: any) => {
      if (handler) {
handler(message, error)
}
    })
  }

  sendFileMessage({
    channel,
    file,
    thumbnailSizes,
    handler,
  }: any) {
    const fileMessageParams = new this.sb.FileMessageParams()
    fileMessageParams.file = file
    fileMessageParams.thumbnailSizes = thumbnailSizes

    return channel.sendFileMessage(fileMessageParams, (message: any, error: any) => {
      if (handler) {
handler(message, error)
}
    })
  }

  deleteMessage({
    channel,
    message,
  }: any) {
    return new Promise((resolve, reject) => {
      if (!this.isCurrentUser(message.sender)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          message: 'You have not ownership in this message.',
        })
      }
      channel.deleteMessage(message, (response: any, error: any) => {
        if (error) {
          reject(error)
          return
        }
        resolve(response)
      })
    })
  }
  
  // initSendBird = () => {
  //   console.log(":::::::initSendBird:::::::::::")
  //   return new Promise((resolve, reject) => {
  //     const user = store.getters['user/getUser']
  //     if (user) {
  //       if (sb.connected) {
  //         return resolve(null)
  //       }
  //       createConnectionHandler()
  //       sb.connect(user.userId.toString(), user.nickname)
  //         .then(user => {
  //           console.log(user)
  //           // chatLeft.updateUserInfo(user);
  //
  //           const channelEvent = new SendBirdChatEvent()
  //           resolve(null)
  //           // createChannelEvent();
  //           // updateGroupChannelTime();
  //           // chatLeft.getGroupChannelList(true);
  //         })
  //         .catch((error: any) => {
  //           // console.log(e);
  //           // console.log('SendBird connection failed.');
  //         })
  //     }
  //   })
  // }
  
  static getInstance() {
    
    // return new Promise((resolve, reject) => {
    //   const sb = new SendBirdAction()
    //   if (sb.connected) {
    //     return resolve(sb)
    //   }
    //   const user = store.getters['user/getUser']
    //   if (user) {
    //     createConnectionHandler()
    //     sb.connect(user.userId.toString(), user.inviteCode||user.nickname)
    //       .then(user => {
    //         console.log(user)
    //         const channelEvent = new SendBirdChatEvent()
    //         return resolve(sb)
    //       })
    //       .catch((error) => {
    //         console.log(error)
    //         console.log('SendBird connection failed.')
    //       })
    //   }
    //
    // })
    
  }
}

export {
  SendBirdAction,
}
