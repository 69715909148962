
import {AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {defineComponent, ref, computed, defineAsyncComponent, h, toRef, watch} from 'vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import ConfirmBuyModal from '@/views/view/buy-stake/ConfirmBuyModal.vue'
import {useStore} from 'vuex'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

const modalPages = {
  BF_AGREE: defineAsyncComponent(() => import('./PartnershipJoinPage.vue')),
  PURCHASE_COMPLETE: defineAsyncComponent(() => import('./purchase-complete.vue')),
  PURCHASE_FAIL: defineAsyncComponent(() => import('./purchase-fail.vue')),
  PURCHASE_REQUEST: defineAsyncComponent(() => import('./purchase-request.vue')),
  SIGN_TO_JOIN: defineAsyncComponent(() => import('@/views/view/sign-joining/SignToJoinPartnership.vue')),
}
export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  components: {
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    ConfirmBuyModal,
    ModalHeader,
  },
  emits: ['close', 'buyDone'],
  name: 'BuyStakeModal',
  props: {
    isOpen: {require: true, type: Boolean},
  },
  setup(props, {emit}) {
    const gtm = useGtm()
    const {getters} = useStore()

    const isOpenRef = toRef(props, 'isOpen')
    const setOpen = () => emit('close')
    const modalPageName = ref<ModalPageName>('BF_AGREE')
    const modalPage = computed(() => modalPages[modalPageName.value])
    const confirmBuyRef = ref<boolean>(false)
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])

    watch(isOpenRef, ()=>{
      if(isOpenRef.value){
        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: `${clickScreen}/${dealInfo.value.partnershipShareSellId}`,
          title: `${dealInfo.value.partnershipName} 지분거래 구매 프로세스 모달`,
        })
      }
    })

    const movePage = (page: any, data?: any) => {
      modalPageName.value = page
    }

    const clickScreen = 'buy-partnership-share-sell-modal'

    return {clickScreen, confirmBuyRef, dealInfo, isOpenRef, modalPage, movePage, setOpen}
  },
})
