import {inject, InjectionKey, provide} from 'vue'

export type UseInjectionRecipe<T> = () => T

export const useInjection = <T> (key: InjectionKey<T>, recipe: UseInjectionRecipe<T>): T => {
  const data = inject(key)
  if (data) {
    return data
  }
  const newData = recipe()
  provide(key, newData)
  return newData
}
