<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/tabs/company"
            :icon="chevronBack"
          />
        </ion-buttons>
        <ion-title>
          <div class="title-css">
            판매자와의 협의
          </div>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content
      class="conf"
      :fullscreen="true"
    >
      <div class="chat-container">
        <!-- 여기는 buyChat에 들어오면 자동으로 출력되는 메시지 대신 date는 가져오는 걸로!-->
        <div class="getMessage">
          <div class="left-div">
            <div class="text">
              {{ deal.name }}<br>
              주당 <span class="bold">{{ $filters.currency(deal.price) }}</span> 원 / <span class="bold">{{ deal.percent }}</span> % 팝니다.<br>
              (총 {{ $filters.currency(deal.total) }} 원)<br>
              <!-- "statue"? -->
              협의 가능합니다.
            </div>
          </div>
          <img
            :src="leftTail"
            alt=""
          >
          <div class="time">
            {{ $filters.getTime(messageList[0].create_at) }}
          </div>
        </div>
        <div
          v-for="(message, index) in messageList"
          :key="index"
          class="message-content"
        >
          <transition name="fade">
            <buy-message
              :child-message="messageList[index]"
              :class="[message.type !== 'initial_price' ? activeClass : 'none', 'common']"
            />
          </transition>
          <div
            v-if="message.type === 'deny_suggest'"
            class="close-deal"
          >
            <hr class="relative">
            <span class="done">협의 종료 완료</span><br>
            <hr class="relative">
            <span class="close">거래 종료 {{ $filters.getDate(date) }}</span>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer translucent>
      <ion-toolbar
        v-if="status === 'accept_suggest'"
        class="btnFooter"
        @click="onWepview"
      >
        <div class="button-container">
          <button
            class="request"
            @click="onWepview"
          >
            결제
          </button>
        </div>
      </ion-toolbar>
      <ion-toolbar
        v-else
        class="btnFooter"
        @click="onRequest"
      >
        <div class="button-container">
          <button
            v-if="status === 'deny_suggest'"
            class="request"
          >
            협의 재요청
          </button>
          <button
            v-else
            class="request"
          >
            협의 요청
          </button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonBackButton, IonButtons, IonTitle, modalController} from '@ionic/vue'
import {defineComponent, computed} from 'vue'
import {useStore} from 'vuex'
import {Plugins} from '@capacitor/core'

// get icons & component
import * as lefttail from '../../public/assets/icon/left_tail.png'
import {chevronBack} from 'ionicons/icons'
import Keypad from '../components/Modal/Keypad.vue'
import BuyModal from '../components/Modal/BuyModal.vue'
import BuyMessage from '../components/Chat/BuyMessage.vue'

export default defineComponent({
  components: {
    BuyMessage,
    IonBackButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  name: 'BuyChat',
  setup() {
    const leftTail = lefttail
    const {getters} = useStore()

    const deal = computed(() => getters['dealInfo/dealInfo'])
    const messageList = computed(() => getters['dealInfo/message'])
    const status = computed(() => getters['dealInfo/messageStatus'])
    const activeClass = 'common'
    const date = computed(() => new Date())

    const onWepview = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: BuyModal,
          cssClass: 'buy-modal',
          id: 'buyModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      return modal.present()
      // Browser.open({ url: 'https://www.negostock.co.kr/intents/maker?stockInfoUid=350920&stockName=%EC%95%BC%EB%86%80%EC%9E%90&referSite=direct' });
    }

    const onRequest = async () => {
      // 2. modal 페이지 생성!
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: Keypad,
          componentProps: {
            title: 'New Title',
          },
          cssClass: 'key-class',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      return modal.present()
    }

    return {activeClass, chevronBack, date, deal, leftTail, messageList, onRequest, onWepview, status}
  },
})

</script>

<style>
  .title-css {
   padding-right: 65px;
   text-align: center;
   font-size: 18px;
   color: #222;
   font-weight: 600;
  }

  .conf::part(background) {
    background-color: #eef1f7;
  }

  .chat-container {
    margin: 20px 10px 20px 10px;
  }

  .getMessage {
    display: flex;
    flex-direction: row;
    position: relative;
    transition: all ease 1s;
  }

  .sendMessage {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
  }

  .getMessage > img{
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .sendMessage > img{
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .text {
    font-size: 16px;
    color: #222;
  }

  .left-right {
    font-size: 16px;
    color: white;
  }

  .left-div {
    display: inline;
    padding: 15px 25px 15px 15px;
    margin: 8px;
    border-radius: 15px;
    background-color: white;
  }

  .right-div {
    display: inline;
    padding: 15px 15px 15px 25px;
    margin: 8px;
    border-radius: 15px;
    color: white;
    background-color: #4A90E2;
  }

  .time {
    display: inline-flex;
    align-items: flex-end;
    margin-bottom: 8px;
    color: #D4D9DF;
  }

  /* BUTTON CHANGE */
  .cancel {
    color: white;
    background-color: #464646;
  }

  .button-container {
    background-color: #4A90E2;
    text-align: center;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .btnFooter {
    --background: #4A90E2;
  }

  .request {
    color: white;
    background-color: #4A90E2;
    font-size: 18px;
  }

  .key-class {
    --height: 500px;
    --border-radius: 20px 20px 0px 0px;
  }

  .key-class > .modal-wrapper {
    position: absolute;
    bottom: 0;
  }

  .none {
    display: none;
  }

  .common {
    display: unset;
  }

  .close-deal {
    text-align: center;
    position: relative;
    margin: 15px 0px 15px 0px;
  }

  .close-deal > span {
    font-size: 14px;
    color: #666;
    background: #eef1f7;
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
  }

  hr.relative {
    height: 1px;
    border-bottom: 1px solid #D9DEE8;
  }

  hr.relative:first-child {
    height: 9px;
    border-bottom: 1px solid #D9DEE8;
  }

  .close-deal > .done {
    left: 33%;
    top: 0px;
  }
  .close-deal > .close {
    left: 22.5%;
    top: 35px;
  }

  .button, .button:hover, .button:active{
    text-decoration: none;
  }

</style>
