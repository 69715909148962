import {IonRefresher} from '@ionic/vue'
import {styled, systemProps, systems} from '@/plugins/emotion'

export const refresherProps = {
  ...systemProps,
  closeDuration: {default: '500ms', type: String},
  disabled: {default: false, type: Boolean},
  pullFactor: {default: 1, type: Number},
  pullMax: {type: Number},
  pullMin: {default: 60, type: Number},
  snapbackDuration: {default: '500ms', type: String},
}

export const Refresher = styled(IonRefresher, {
  props: {
    ...refresherProps,
  },
})(
  systems,
)
