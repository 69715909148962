
import {IonPage, IonContent, modalController, IonRefresher, IonRefresherContent, IonRippleEffect} from '@ionic/vue'
import {computed, defineComponent, onBeforeMount, ref} from 'vue'
import {AgRefresher} from '@/components/ag'
import {SendBirdAction} from '@/plugins/SendBirdAction'
import {useStore} from 'vuex'
import ViewOptionModal from '@/components/Modal/ViewOptionModal.vue'
import {prettyDateToOnemonth} from '@/utils/filters'
import {useRouter} from 'vue-router'
import SellItemModal from '@/components/Modal/SellItemModal.vue'
import useModal from '@/composables/useModal'
import {arrowDownOutline} from 'ionicons/icons'
import LinkAccountModal from '@/views/view/link-account/LinkAccountModal.vue'
import RegistSellStakeModal from '@/views/view/regist-sell-stake/RegistSellStakeModal.vue'
import ViewMyStake from '@/views/view/view-my-stake/ViewMyStake.vue'

export default defineComponent({
  components: {
    AgRefresher,
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRippleEffect,
    LinkAccountModal,
    RegistSellStakeModal,
    ViewMyStake,
  },
  name: 'SendbirdTest',
  setup() {
    interface CustomChannel {
      channel: any
      channelUrl: any
      createdAt: any
      lastMessage: any
      leadAngelUserId: any
      members: any
      name: any
      sellerUserId: any
      status: any
      stockItemId: any
      unreadMessageCount: any
    }

    const {getters, dispatch} = useStore()

    const {useCollectPartnerships, useClubDealSellChatModal, useClubDealBuyChatModal, usePreApplicationModal} = useModal()

    const user = computed(() => getters['user/getUser'])
    const stockItemLogos = computed(() => getters['stockItem/getStockItemLogos'])
    const userId = computed(() => user.value.userId.toString())
    const testChannelList = computed(() => getters['message/getChannelList'])
    const router = useRouter()
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const myAllStakeList = computed(() => getters['stakeDeal/myAllStakeList'])
    const isAvaliable = computed(() => myAllStakeList.value && myAllStakeList.value.length)

    function channelType(leadAngelUserId: any) {
      return leadAngelUserId === userId.value ? '구매' : '판매'
    }

    // 1. view option
    const viewOption = ref<string>('전체')
    const viewType = ref<string>('최신순')

    function sortByCreated(array: any) {
      if (viewType.value === '최신순') {
        return array.sort(function(a: any, b: any) {
          return (a.createdAt < b.createdAt ? 1 : -1)
        })
      } else if (viewType.value === '과거순') {
        return array.sort(function(a: any, b: any) {
          return (a.createdAt < b.createdAt ? -1 : 1)
        })
      }
    }

    function getLogo(stockItemId: any) {
      if (stockItemLogos.value && stockItemId) {
        return stockItemLogos.value[stockItemId]
      }
      return 'https://static.angelleague.io/images/bk_v.png'
    }

    function getNickName(ch: any) {
      const mySellFlag = channelType(ch.leadAngelUserId) === '판매'

      return ch.members
        .filter((a: any) => a.userId !== 'angelleague' && a.userId !== userId.value)
        .map((a: any) => a.nickname === 'null' ? (((((((((((mySellFlag ? '리드엔젤' : '판매자'))))))))))) : a.nickname).toString()
    }

    function parseData(metaData: any) {
      const status = metaData.status

      if (status) {
        if (status === 'cancelToSeller' || status === 'cancelToLeadAngel') {
          return '협의종료'
        } else if (status === 'complete') {
          return '협의성공 🎉'
        }
      }

      return ''
    }

    const createModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: ViewOptionModal,
          componentProps: {
            requestType: 'negotiation',
            viewOption: viewOption.value,
            viewType: viewType.value,
          },
          cssClass: 'input-modal',
          id: 'ViewOptionModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      modal.onDidDismiss().then(async (data) => {
        viewOption.value = await data.data.viewOption
        viewType.value = await data.data.viewType
      })

      return modal.present()
    }

    const doRefresh = async (event: any) => {
      // console.log('Begin async operation')
      await Promise.all(
        [
          dispatch('message/ACT_UNREAD_COUNT'),
        ],
      )
      event.target.complete()
    }
    async function enterChannel(channel: any) {
      // commit('openChannel/SET_CHANNEL', channel.channel)
      if (channelType(channel.leadAngelUserId) === '판매') {
        await useClubDealSellChatModal(channel.channelUrl)
        // await router.push({ name : 'ClubDealSellChat', params: {channelUrl: channel.channelUrl}})
      } else {
        await useClubDealBuyChatModal(channel.channelUrl)
        // await router.push({ name : 'ClubDealBuyChat', params: {channelUrl: channel.channelUrl}})
      }
      // getChannelList()
    }

    async function leaveChannel(channel: any) {
      const sba: any = await SendBirdAction.getInstance()
      await sba.leave(channel.channel.url)
      // getChannelList()
    }

    function isRead(channel: any) {
      return channel.unreadMessageCount
    }

    const openSellModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: SellItemModal,
          componentProps: {},
          cssClass: 'sell-item-modal',
          id: 'SellItemModal',

          // mode: 'ios',
          keyboardClose: true,

          swipeToClose: true,
        })
      return modal.present()
    }

    // 지분 거래 관련 코드
    onBeforeMount(async () => {
      await Promise.all(
          [
            dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS'),
            dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS'),
            dispatch('stakeDeal/GET_MY_ALL_STAKELIST'),
          ],
      )
    })

    const stakeSellOpen = ref<any>(false)
    const linkAccountModal = ref<any>(false)
    const viewMyStakeOpen = ref<any>(false)

    const branchResult = () => {
      stakeSellOpen.value = false
      viewMyStakeOpen.value = true
    }

    const checkIsLinked = async () => {
      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
linkAccountModal.value = true
} else if (currentStep.value === 'COMPLETE') {
stakeSellOpen.value = true
}
    }
    const clickScreen = 'negotiation'
    return {
      arrowDownOutline,
      branchResult,
      channelType,
      checkIsLinked,
      clickScreen,
      createModal,
      doRefresh,
      enterChannel,
      getLogo,
      getNickName,
      isAvaliable,
      isRead,
      leaveChannel,
      linkAccountModal,
      openSellModal,
      parseData,
      prettyDateToOnemonth,
      router,
      sortByCreated,
      stakeSellOpen,
      stockItemLogos,
      testChannelList,
      useCollectPartnerships,
      usePreApplicationModal,
      user,
      viewMyStakeOpen,
      viewOption,
      viewType,
    }
  },
})
