import {MayArray, MayRef} from '@/types'
import {toArray} from '@/utils'
import {isPlatform as _isPlatform, Platforms} from '@ionic/vue'
import {computed} from 'vue'
import {wrapRef} from '@winter-love/use'

export type {Platforms}

export const isPlatform = (platform: MayRef<MayArray<Platforms | string>>) => {
  const platformRef = wrapRef(platform)

  return computed(() => {
    return toArray(platformRef.value).some(
      (item) => {
        if (item === 'all') {
          return true
        }
        return _isPlatform(item as any)
      },
    )
  })
}


