
import {
  IonPage, IonContent, IonProgressBar, loadingController,
  IonFooter, modalController, toastController, alertController,
  IonToolbar, IonIcon,
} from '@ionic/vue'
import {chevronForwardOutline} from 'ionicons/icons'
import {defineComponent, ref, watch, computed, onBeforeMount, onUpdated} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import contract from '@/components/Modal/Contract.vue'
import AddressInput from '@/components/Modal/AddressInput.vue'
import apiPartnership from '@/api/partnership'
import {SendBirdAction} from '@/plugins/SendBirdAction'
import channelApi from '@/api/open-channel'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    IonContent,
    IonFooter,
    IonIcon,
    IonPage,
    IonProgressBar,
    IonToolbar,
  },
  name: 'ClubDealJoin',
  props: {
    channelUrl: null,
    clubdealId: null,
    partnershipOpenChannelId: null,
  },
  setup(props) {
    const {sendNewMessage} = channelApi()
    const {useRegistEmailModal} = useModal()
    const {getters, dispatch} = useStore()
    const {getPartnershipsRequestOpenProcess, isValidAddress, isValidEmail, isCertifyEmail, checkValidAsset} = apiPartnership()

    const router = useRouter()
    const route = useRoute()

    const partnershipName = ref('')
    const depositInfo = computed(() => getters['partnership/getDepositInfo'])

    /* 매 프로세스에 공통으로 사용되는 변수 */
    const gauge = ref(0.25) // 상단 gauge
    const processStep = 6 // 협의 프로세스 단계 총 갯수
    const currentStep = ref(0) // 현재 단계
    const nextToggle = ref(false)
    const nextArray = ref(Array.from({length: 6}, () => false))

    /* checkbox 확인용 */
    const checkCount = ref(0)

    /* 약관동의 확인 용 */
    const confirmToggle = ref(false)
    const contractToggle = ref(false)
    const contractArray = ref(Array.from({length: 2}, () => false))

    /* 포인트 제어용 */
    const initPoint = computed(() => getters['pointInfo/getUserPoint'])
    const usePoint = ref(0)
    const currentPoint = computed(() => initPoint.value - usePoint.value)

    const clubdealId = computed(() => props.clubdealId)
    const channelUrl = computed(() => props.channelUrl)
    const partnershipOpenChannelId = computed(() => props.partnershipOpenChannelId)

    /* 출자 금액, 출자 금액 가능 범위, 출자 금액의 상태 */
    const investMoney = ref(0)
    const investMoneyStatus = ref('zero')
    const investRangeFrom = ref(0)
    const investRangeTo = ref(0)
    const stockPrice = ref(0)

    /* 주당 가격, 주식 수, 서비스 이용료 */
    const stockPerPrice = ref(0)
    const serviceFee = ref(0)
    const serviceFeeFlag = ref(false)
    const stockCount = computed(() => investMoney.value / stockPerPrice.value)

    /* 최종 결정된 비용 */
    const finalPrice = computed(() => {
 return investMoney.value + serviceFee.value - usePoint.value
})
    const account = ref('')

    onUpdated(async () => {
      if (route.params.clubdealId) {
        dispatch('pointInfo/loadData')
        const result = await getPartnershipsRequestOpenProcess(Number(route.params.clubdealId))
        partnershipName.value = result.partnershipName
        investRangeFrom.value = result.availableAssetFrom
        investRangeTo.value = result.availableAssetTo
        stockPrice.value = result.purchasePrice
        stockPerPrice.value = result.purchasePrice
        serviceFee.value = result.serviceFeeAmount
        serviceFeeFlag.value = result.serviceFeeFlag
        account.value = `${result.bank} ${result.bankno} ${result.bankholder}`
      }
    })

    watch(investMoney, () => {
      if (investRangeFrom.value <= investMoney.value && investRangeTo.value >= investMoney.value) {
        investMoneyStatus.value = 'stable'
        nextToggle.value = true
        return
      } else if (investRangeFrom.value > investMoney.value) {
        investMoneyStatus.value = 'lack'
      } else if (investRangeTo.value < investMoney.value) {
        investMoneyStatus.value = 'over'
      } else {
        investMoneyStatus.value = 'zero'
      }
      nextToggle.value = false
    })

    function checkForm(e: any) {
      checkCount.value = e.checked ? checkCount.value + 1 : checkCount.value - 1
    }

    watch(checkCount, async () => {
      nextToggle.value = checkCount.value === 3
    })

    async function createModal(name?: string) {
      let modal: any = ''
      if (name === 'address') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AddressInput,
            cssClass: 'address-modal',
            id: 'AddressInput',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        // modal.onDidDismiss().then(() => {
        //   addressToggle.value = true;
        // });
      } else {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: contract,
            id: 'ContractModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(() => {
          contractArray.value[1] = true
          contractToggle.value = true
        })
      }

      return modal.present()
    }

    async function openAlert() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '취소',
            },
            {
              handler: () => {
                createModal('address')
              },
              text: '주소 입력',
            },
          ],
          cssClass: 'btn-groups adderss-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
            주소를 입력해주세요!
            </div>
            <div class="message">
              클럽딜 참여 후<br>
              조합규약 작성과 조합 설립을 위해<br>
              참여자님의 주소가 필요합니다.
            </div>
          </div>
          `,
        })
      return alert.present()
    }

    async function agreeFunc() {
      contractArray.value[0] = !contractArray.value[0]
      confirmToggle.value = !confirmToggle.value
      const result = await isValidAddress()

      if (!result) {
        await openAlert()
        contractArray.value[0] = !contractArray.value[0]
        confirmToggle.value = !confirmToggle.value
      }
    }

    watch(contractArray.value, async () => {
      let count = 0
      await contractArray.value.forEach(e => e ? count++ : '')
      nextToggle.value = count === contractArray.value.length
    })

    async function sendOpenClubDealMessage() {
      await sendNewMessage(Number.parseInt(clubdealId.value), partnershipOpenChannelId.value, 'approveToSeller')
        .then(async (message) => {
          console.log(message)
          const sendbirdAction: any = await SendBirdAction.getInstance()
          sendbirdAction
            .getChannel(channelUrl.value, false)
            .then((channel: any) => {
              sendbirdAction.sendUserMessage({
                channel: channel,
                message: message.message,
                status: 'approveToSeller',
              })
            })
        })
    }

    async function nextProcess() {
      nextArray.value[currentStep.value] = nextToggle.value

      if (currentStep.value >= processStep) {
        return // maxStep보다 위에 있는 경우 (혹시 모를 에러 처리)
      }

      if (currentStep.value === 2) {
        const result = await isValidEmail()

        if (!result) {
          useRegistEmailModal()
          return
        }
      }

      if (currentStep.value === 5) {
        const loading = await loadingController
          .create({
            message: '출자 확정 중입니다.',
          })

        await loading.present()

        const result = await dispatch('partnership/ACT_GET_OPEN_DEPOSIT_BANKINFO',
          {
            investMoney: `${investMoney.value}`,
            partnershipId: `${route.params.clubdealId}`,
            usePoint: `${usePoint.value}`,
          })

        if (!depositInfo.value) {
          loading.dismiss()
          const toast = await toastController
            .create({
              duration: 1000,
              message: '출자 확정에 실패하였습니다.',
              position: 'middle',
            })
          return toast.present()
        } 
          loading.dismiss()
        

        console.log(':::::::::::::::::::::::::::::::::')
        console.log(partnershipOpenChannelId.value)
        console.log(':::::::::::::::::::::::::::::::::')

        // 협의창에서 와서 협의중인 channelUrl 정보가 있으면 클럽딜 열겠다고 전송
        if (partnershipOpenChannelId.value) {
          await sendOpenClubDealMessage()
        }
      }

      currentStep.value += 1

      if (currentStep.value > 1 && currentStep.value !== 3) {
        gauge.value += 0.25
      }

      nextToggle.value = nextArray.value[currentStep.value]

      /* 스크롤 위로 이동! */
      const content: any = document.querySelector('#clubDealOpenJoinIonContent')
      content.scrollToTop(0, 0)
    }

    function previousProcess() {
      if (currentStep.value === 0) {
        return
      }
      if (currentStep.value > 1 && currentStep.value !== 3) {
        gauge.value -= 0.25
      }
      currentStep.value -= 1
      nextToggle.value = nextArray.value[currentStep.value]
    }

    function addNumber(num: any) {
      const value: string = num.toString()
      let result: any = investMoney.value.toString()
      if ((value === '0' || value === '00') && result === '0') {
        return
        // 따로 작업을 해주지 않음!
      } else if (value === '0') {
        result += '0'
      } else if (value === '00') {
        result += '00'
      } else if (result === '0') {
        result = value
      } else {
        result += value
      }
      investMoney.value = Number(result)
    }

    function clearNumber(command: string) {
      let result: any = investMoney.value.toString()

      if (command === 'clear') {
        result = '0'
      } else if (command === 'del') {
        if (result === '') {
          return
        } 
          result = result.slice(0, - 1)
        
      }
      investMoney.value = Number(result)
    }

    async function onSuccess() {
      const toast = await toastController
        .create({
          duration: 1000,
          message: '계좌 번호가 복사되었습니다.',
          position: 'middle',
        })
      return toast.present()
    }

    async function onSuccessThen() {
      await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', route.params.clubdealId)
      await router.replace({
        name: 'ClubDealOpen',
        params: {clubdeal_id: route.params.clubdealId},
      })
    }

    async function onError() {
      console.log('error')
    }

    const useAllPointToggle = ref(false)

    async function adjustPoint(num: number, type?: string) {
      const total: any = finalPrice.value

      if (type === 'all' && !useAllPointToggle.value) {
        useAllPointToggle.value = true

        if (num < 10000) {
          const toast = await toastController
            .create({
              duration: 1000,
              message: '포인트는 10,000 단위로 사용 가능합니다.',
              position: 'middle',
            })
          return toast.present()
        } else if (num > serviceFee.value) {
          usePoint.value = serviceFee.value
          return
        } 
          usePoint.value = Math.floor(num * 0.0001) * 10000
          return
        
      } else if (type === 'all' && useAllPointToggle.value) {
        return
      } 
        useAllPointToggle.value = false
        if (total < 0) {
          return
        }
        if (num > initPoint.value || num < 0 || num > serviceFee.value) {
          return
        }
      

      usePoint.value = num
    }

    return {
      account,
      addNumber,
      adjustPoint,
      agreeFunc,
      checkCount,
      checkForm,
      chevronForwardOutline,
      clearNumber,
      confirm,
      confirmToggle,
      contractToggle,
      createModal,
      curPoint: currentPoint,
      curStep: currentStep,
      depositInfo,
      finalPrice,
      gauge,
      initPoint,
      investMoney,
      investMoneyStatus,
      investRangeFrom,
      investRangeTo,
      nextProcess,
      nextToggle,
      onError,
      onSuccess,
      onSuccessThen,
      partnershipName,
      previousProcess,
      process,
      route,
      router,
      serviceFee,
      stockCount,
      stockPerPrice,
      usePoint,
    }
  },
})
