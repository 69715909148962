import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'
import {SizesType} from '@/plugins/emotion'
import {Property} from 'csstype'
export type MaxHeightType = Property.MaxHeight | string | number
export type MaxWidthType = Property.MaxWidth | string | number
export type MinHeightType = Property.MinHeight | string | number
export type MinWidthType = Property.MinWidth | string | number
export type VerticalAlignType = Property.VerticalAlign
export type OverflowType = Property.Overflow
export type OverflowXType = Property.OverflowX
export type OverflowYType = Property.OverflowY

export const heightProp = getResponsiveProp<SizesType>()

export const widthProp = getResponsiveProp<SizesType>()

export const widthSystem = system({
  width: {
    property: 'width',
    scale: 'sizes',
  },
})

export const heightSystem = system({
  height: {
    property: 'height',
    scale: 'sizes',
  },
})

export const additionalSizeSystems = system({
  maxHeight: {
    property: 'maxHeight',
    scale: 'sizes',
  },
  maxWidth: {
    property: 'maxWidth',
    scale: 'sizes',
  },
  minHeight: {
    property: 'minHeight',
    scale: 'sizes',
  },
  minWidth: {
    property: 'minWidth',
    scale: 'sizes',
  },
  overflow: true,
  overflowX: true,
  overflowY: true,
  // size: {
  //   properties: ['width', 'height'],
  //   scale: 'sizes',
  // },
  verticalAlign: true,
})

export const additionalSizeProps = {
  maxHeight: getResponsiveProp<MaxHeightType>(),
  maxWidth: getResponsiveProp<MaxWidthType>(),
  minHeight: getResponsiveProp<MinHeightType>(),
  minWidth: getResponsiveProp<MinWidthType>(),
  overflow: getResponsiveProp<OverflowType>(),
  overflowX: getResponsiveProp<OverflowXType>(),
  overflowY: getResponsiveProp<OverflowYType>(),
  // size: getResponsiveProp<SizesType>(),
  verticalAlign: getResponsiveProp<VerticalAlignType>(),
}

export const sizeSystems = system({
  height: (value, _, props) => {
    const style = heightSystem({...props})
    if (value === '100vh') {
      return {
        ...style,
        '@supports (-webkit-touch-callout: none)': {
          height: '-webkit-fill-available',
        },
      }
    }
    return style
  },
  width: (value, _, props) => {
    const style = widthSystem({...props})
    if (value === '100vw') {
      return {
        ...style,
        '@supports (-webkit-touch-callout: none)': {
          width: '-webkit-fill-available',
        },
      }
    }
    return style
  },
})

export const sizeProps = {
  height: heightProp,
  width: widthProp,

}
