import {reactive} from 'vue'
import CallApi from '../utils/CallApi'
import {store} from '@/vuex'

// // console.log(config);

const auth = () => {
  const AUTH_PREFIX = '/auth'

  const login = async (email: string, password: string) => {
    const state = reactive<{error: any; token: string; user: any}>({
      error: null,
      token: '',
      user: null,
    })
    try {
      const res: any = await CallApi({
        data: {
          email: email,
          password: password,
        },
        method: 'post',
        url: `${AUTH_PREFIX}/login`,
      })

      if (res.token) {
        state.user = res.userInfo
        state.token = res.token
      }
    } catch (error) {
      state.error = error
    }
    return state
  }

  const simplePasswordLogin = async (uuid: string, password: string) => {
    const state = reactive<{error: any; token: string; user: any}>({
      error: null,
      token: '',
      user: null,
    })
    try {
      const result: any = await CallApi({
        data: {
          password: password,
          uuid: uuid,
        },
        method: 'post',
        url: `${AUTH_PREFIX}/simple-password-login`,
      })

      if (result.token) {
        state.user = result.userInfo
        state.token = result.token
      }
    } catch (error) {
      state.error = error
    }
    return state
  }
  
  const superLogin = async (uuid: string, password: string) => {
    const state = reactive<{error: any; token: string; user: any}>({
      error: null,
      token: '',
      user: null,
    })
    try {
      const result: any = await CallApi({
        data: {
          password: password,
          uuid: uuid,
        },
        method: 'post',
        url: `${AUTH_PREFIX}/super-login`,
      })
      
      if (result.token) {
        state.user = result.userInfo
        state.token = result.token
      }
    } catch (error) {
      state.error = error
    }
    return state
  }
  
  const refreshToken = async () => {
    await CallApi({
      method: 'get',
      url: `${AUTH_PREFIX}/refresh-token`,
    })
  }

  const saveAgreeTerms = async (uuid: string, agreeTerms: boolean, agreeMarketing: boolean, agreeThirdparty: boolean) => {
    let result: any = []
    try {
      result = await CallApi({
        data: {
          agreeMarketing: agreeMarketing,
          agreeTerms: agreeTerms,
          agreeThirdparty: agreeThirdparty,
          uuid: uuid,
        },
        method: 'post',
        url: `${AUTH_PREFIX}/identity-certification/agree`,
      })
    } catch {
      // console.log(error);
    }
    return result
  }

  const saveAccount = async (uuid: string, userId: number) => {
    let result: any = []
    try {
      result = await CallApi({
        data: {
          userId: userId,
          uuid: uuid,
        },
        method: 'post',
        url: `${AUTH_PREFIX}/identity-certification/account-choice`,
      })
    } catch {
      // console.log(error);
    }
    return result
  }

  const getSignupStep = async (uuid: string) => {
    let result: any = []
    try {
      result = await CallApi({
        method: 'get',
        url: `${AUTH_PREFIX}/identity-certification/signup-step/${uuid}`,
      })
    } catch {
      // console.log(error);
    }
    return result
  }

  const simplePasswordRegistCheck = async (uuid: string) => {
    let result: any = null
    result = await CallApi({
      data: {
        uuid: uuid,
      },
      method: 'post',
      url: `${AUTH_PREFIX}/simple-password-check`,
    })
    console.log('hereer')
    return result
  }
  
  const userExceptionCheck = async (token: string) => {
    let result: any = null
    result = await CallApi({
      data: {
        token: token,
      },
      method: 'post',
      url: `${AUTH_PREFIX}/user-exception-mapping`,
    })
    console.log('hereer')
    return result
  }

  const simplePasswordReset = async (uuid: string) => {
    let result: any = null
    try {
      result = await CallApi({
        data: {
          uuid: uuid,
        },
        method: 'post',
        url: `${AUTH_PREFIX}/simple-password-reset`,
      })
    } catch (error) {
      console.log(error)
    }
    return result
  }

  const simplePasswordRegist = async (uuid: string, password: string) => {
    let result: any = null
    try {
      result = await CallApi({
        data: {password, uuid},
        method: 'post',
        url: `${AUTH_PREFIX}/simple-password`,
      })
    } catch {
      // console.log(error);
    }
    return result
  }

  // RUN AT STARTUP
  // TODO: `토큰 유효성 체크 로직이 들어가야 할듯`
  // TODO local uuid 존재 여부, 간편 비밀번호 등록 여부 check
  const authCheck = async () => {
    const uuid = localStorage.getItem('uuid')

    if (uuid) {
      try {
        const check = await simplePasswordRegistCheck(uuid)
        localStorage.setItem('simple_password_flag', check.simple_password_flag)
        store.dispatch('user/ACT_SET_SIMPLE_PASSWORD_FLAG', check.simple_password_flag)
      } catch (error) {
        if (error && error.response && error.response.status === 406) {
          localStorage.removeItem('uuid')
          localStorage.removeItem('simple_password_flag')
          store.dispatch('user/ACT_LOGOUT')
        }

        // Error: Network Error
        // at createError (createError.js?2d83:16)
        // at XMLHttpRequest.handleError (xhr.js?b50d:84)

        // 토큰이 잘못된 경우
        // Error: Request failed with status code 400
        // at createError (createError.js?2d83:16)
        // at settle (settle.js?467f:17)
        // at XMLHttpRequest.handleLoad (xhr.js?b50d:62)
      }
    }
    return true

    // return new Promise(async (resolve, reject) => {
    //
    // console.log("authCheck");
    // const uuid = localStorage.getItem('uuid')
    // const simplePasswordFlag = localStorage.getItem('simple_password_flag') || false;
    // // console.log(uuid, simplePasswordFlag);
    //
    // if (uuid) {
    // const check = await simplePasswordRegistCheck(uuid);
    // // console.log(check);
    // //check simplePasswordFlag
    // }
    //
    //
    // resolve(true);
    // });
  }

  return {
    authCheck,
    getSignupStep,
    login,
    refreshToken,
    saveAccount,
    saveAgreeTerms,
    simplePasswordLogin,
    simplePasswordRegist,
    simplePasswordReset,
    superLogin,
    userExceptionCheck,
  }
}

export default auth
