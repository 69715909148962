
import {AgBox, AgImg, AgButton, AgText, AgContent, AgFooter} from '@/components/ag'
import {computed, defineComponent} from 'vue'
import {useStore} from 'vuex'
import {Browser} from '@capacitor/core'
import {useRouter} from 'vue-router'
import {alertController} from '@ionic/vue'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgImg,
    AgText,
  },
  emits: ['close'],
  props: {
    modelValue: {type: Boolean},
  },
  setup(props, {emit}) {
    const {getters} = useStore()
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }

    const router = useRouter()

    async function cancelToBuy() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                emit('close')
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups cancel-buy-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              구매를 취소하시겠어요?
            </div>
            <div class="message">
              판매자에게 정중한 거래 취소 메시지를<br>
              전송한 후, 거래를 종료합니다.<br>
              구매 요청 내역, 구매금 입금 계좌와 조합가입 확인서는 모두 삭제됩니다
            </div>
          </div>`,
        })

      return alert.present()
    }

    const onClose = () => {
      emit('close')
      router.push({
        name: 'MyPage',
      })
    }

    return {
      cancelToBuy,
      dealInfo,
      onClose,
      openChannelTalk,
    }
  },
})
