import {computed, defineComponent, h, PropType, toRef} from 'vue'
import {toMutRef} from '@/composables'
import {NativeInput, LabelWrapper, Container} from './components'
import {AgToggleIcon, agToggleProps} from '../ag-toggle-icon'

export type LabelPlace = 'start' | 'end'

export const agCheckProps = {
  ...agToggleProps,
  disableColor: {default: 'gray3', type: String},
  labelPlace: {default: 'end', type: String as PropType<LabelPlace>},
}

export const AgCheck = defineComponent({
  emits: ['update:modelValue'],
  name: 'AgCheck',
  props: {
    ...agCheckProps,
  },
  setup(props, {emit, slots}) {
    const valueRef = toMutRef(props, 'value')
    const colorRef = toRef(props, 'color')
    const disableColorRef = toRef(props, 'disableColor')

    const currentColorRef = computed(() => {
      if (valueRef.value) {
        return colorRef.value
      }
      return disableColorRef.value
    })

    const onClick = () => {
      valueRef.value = !valueRef.value
      emit('update:modelValue', valueRef.value)
    }

    return () => {
      const {labelPlace, block} = props
      return h(Container, {onclick: onClick}, () => [
        h(NativeInput),
        labelPlace === 'start' && h(LabelWrapper, {block, color: currentColorRef.value}, () => slots.default?.()),
        h(AgToggleIcon, {...props, color: currentColorRef.value, value: valueRef.value}),
        labelPlace === 'end' && h(LabelWrapper, {block, color: currentColorRef.value}, () => slots.default?.()),
      ])
    }
  },
})
