import {createApp} from 'vue'
import App from './app/App.vue'
import router from './router'
import {store} from '@/vuex'
import {VueClipboard} from '@soerenmartius/vue3-clipboard'
import {ionic} from '@/plugins/ionic'
import Emotion from '@/plugins/emotion'
import AngelLeagueComponents from '@/components/ag'
import airbridge from '@/plugins/airbridge'
import gtm from '@/plugins/gtm'
import gtmEvent from '@/plugins/gtm-event'
import meta from '@/plugins/meta'
import modal from '@/plugins/modal'
import filters from '@/plugins/filters'
import {createGtm} from '@gtm-support/vue-gtm'
import apollo from '@/plugins/apollo'
import analytics from '@/plugins/analytics'

const app = createApp(App)
  .use(apollo)
  .use(ionic, {
    mode: 'md',
    swipeBackEnabled: false,
    // animated : false,
    // 기본으로 제공되는 이벤트 제어 (router 이동, modal create)
  })
  .use(router)
  .use(Emotion)
  .use(store)
  .use(AngelLeagueComponents)
  .use(VueClipboard)
  .use(meta)
  .use(modal)
  .use(filters)
  .use(airbridge)
  .use(gtm)
  .use(createGtm({
    compatibility: false,
    debug: true,
    enabled: true,
    id: 'GTM-P4CBQJT',
    loadScript: true,
    trackOnNextTick: false,
  }))
  .use(gtmEvent)
  .use(analytics)

router.isReady().then(() => (app.mount('#app')))
