import CallApi from '../utils/CallApi'

export default function() {
  return {
    addStockOptions: async (formData: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          data: formData,
          headers: {'Content-Type': 'multipart/form-data'},
          method: 'post',
          url: '/stock-options',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    editStockOptions: async (stockOptionId: number, formData: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          data: formData,
          headers: {'Content-Type': 'multipart/form-data'},
          method: 'put',
          url: `/stock-options/${stockOptionId}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getCalcInfo: async (calcInfo: any) => {
      let calcResult: any = []

      console.log(calcInfo)

      try {
        calcResult = await CallApi({
          data: {
            annualIncome: Number.parseInt(calcInfo.annualIncome),
            companyName: calcInfo.companyName,
            exercisePrice: Number.parseInt(calcInfo.exercisePrice),
            exerciseQty: Number.parseInt(calcInfo.exerciseQty),
            handoverPrice: Number.parseInt(calcInfo.handoverPrice),
          },
          method: 'post',
          url: '/stock-options/calculate',
        })
      } catch {
        // console.log(error);
      }

      return calcResult
    },
  }
}
