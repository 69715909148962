
import {IonContent} from '@ionic/vue'
import {computed, defineComponent, ref} from 'vue'

export default defineComponent({
  components: {IonContent},
  name: 'ClubDealValue',
  props: {
    price: null,
  },
})
