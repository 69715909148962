const inXsm = '12px'
const inSm = '14px'
const inMd = '24px'
const inLg = '36px'
const xs = '30px'
const sm = '40px'
const md = '48px'
const lg = '60px'
const safeAreaTop = 'var(--ion-safe-area-top)'
const safeAreaBottom = 'var(--ion-safe-area-bottom)'
const safeAreaLeft = 'var(--ion-safe-area-left)'
const safeAreaRight = 'var(--ion-safe-area-right)'

export const sizes = {
  inLg,
  inMd,
  inSm,
  inXsm,
  lg,
  md,
  safeAreaBottom,
  safeAreaLeft,
  safeAreaRight,
  safeAreaTop,
  sm,
  xs,
}

export type Sizes = typeof sizes
export type SizesType = keyof Sizes | string | number
