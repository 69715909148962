
import component from '*.vue'
import {
  IonPage,
  IonContent,
  IonInput,
  IonList,
  IonToolbar,
  IonHeader,
  toastController,
  modalController,
  IonTitle,
} from '@ionic/vue'
import {computed, defineComponent, ref} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import email from '../../data/email.json'
import apiSettings from '@/api/settings'
const {registEmail} = apiSettings()
import {IonRippleEffect} from '@ionic/vue'

export default defineComponent({
  components: {
    IonContent,
    IonInput,
    IonList,
    IonPage,
    IonRippleEffect,
    IonTitle,
    IonToolbar,
  },
  name: 'RegistEmail',
  setup() {
    const {getters, dispatch} = useStore()

    const route = useRouter()
    const userEmail = ref<string>(getters['user/getEmail'] === 'null' ? '' : getters['user/getEmail'])
    const currentStatus = ref<string>(userEmail.value ? 'change' : 'progress')
    const emailInput = ref<boolean>(true)
    const emailList = ref<boolean>(false)
    const emailAddress = email.email

    function dismiss() {
      modalController.dismiss()
    }

    const handleInput = () => {
      if (userEmail.value === '' || userEmail.value.lastIndexOf('@') !== -1) {
        emailList.value = false
      } else {
        emailList.value = true
      }
      // console.log(userEmail.value);
    }

    const checkProcessStatus = (status: string) => {
      return currentStatus.value === status
    }

    const setProcessStatus = (status: string) => {
      currentStatus.value = status
    }

    async function registEmailFunc() {
      const result = await registEmail(userEmail.value)
      const message = result === true ? '이메일 등록에 성공하였습니다.' : '이메일 등록에 실패하였습니다. \n입력하신 이메일 형식을 다시 확인해주세요.'
      const duration = result === true ? 1000 : 2000

      const toast = await toastController
        .create({
          duration: duration,
          message: message,
          position: 'top',
        })

      if (result === true) {
setProcessStatus('done')
}

      return toast.present()
    }

    function emailRegistDone() {
      dispatch('user/ACT_SET_EMAIL', userEmail.value)
      dismiss()
    }

    const verifyEmail = computed(() => {
      if(!userEmail.value) {
return
}
      const regExp = /^([\w.-]+)@([\w-]+)(\.[\w-]+){1,2}$/

      if (userEmail.value.match(regExp) != null && emailInput.value) {
        return false
      }

      return true
    })

    const clickScreen = 'regist-email'

    return {
      checkProcessStatus, 
      clickScreen,
      dismiss,
      emailAddress,
      emailInput,
      emailList,
      emailRegistDone,
      handleInput,
      registEmailFunc,
      route,
      setProcessStatus,
      userEmail,
      verifyEmail,
    }
  },
})
