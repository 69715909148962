export const complexMarge = (...args: any[]): any => {
  const result = args.reduce((result, arg) => {
    if (typeof arg === 'undefined') {
      return result
    }
    if (typeof arg === 'object') {
      return Object.assign(result, arg)
    }
    return Object.assign(result, {0: arg})
  }, {})

  if (Object.keys(result).length < 2 && result[0]) {
    return result[0]
  }
  return result
}
