
import {alertController, IonButton, IonContent, modalController, popoverController} from '@ionic/vue'
import {defineComponent} from 'vue'
import PartnershipBoardWriteModal from '@/components/Modal/PartnershipBoard/PartnershipBoardWriteModal.vue'
import {useStore} from 'vuex'
export default defineComponent({
  components: {IonContent},
  name: 'Popover',
  props: {
    partnershipBoard: null,
    partnershipId: null,
  },
  setup(props) {
    const {getters, dispatch, commit} = useStore()
    const openModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: PartnershipBoardWriteModal,
          componentProps: {
            partnershipBoard: props.partnershipBoard,
            partnershipId: props.partnershipId,
          },
          cssClass: 'board-write-modal',
          id: 'BoardWriteModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      await popoverController.dismiss()
      return modal.present()
    }

    const dismiss = () => {
      return popoverController.dismiss()
    }

    const deleteBoard = async (partnershipId: number, partnershipBoardId: number) => {
      const ok = await dispatch('asset/ACT_DELETE_PARTNERSHIP_BOARD', {
        partnershipBoardId: partnershipBoardId,
        partnershipId: partnershipId,
      })
      if (ok) {
        // console.log('삭제됨')

        await dispatch('asset/ACT_SELECT_PARTNERSHIP_BOARD_LIST', props.partnershipId)
        dismiss()
      }
    }
    const sureDelete = async () => {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '취소',
            },
            {
              handler: async () => {
                await deleteBoard(props.partnershipId, props.partnershipBoard.partnershipBoardId)
              },
              text: '삭제하기',
            },
          ],
          cssClass: 'btn-groups lead-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              의견을 삭제합니다.
            </div>
            <div class="message">
              삭제후 복구 불가능합니다.
            </div>
          </div>`,
        })
      await popoverController.dismiss()
      return alert.present()
    }

    return {openModal, sureDelete}
  },

})
