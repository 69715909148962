import {MayRef} from '@/types'
import {shuffle} from 'lodash'
import {ref, computed, watch} from 'vue'
import {wrapRef} from '@winter-love/use'

/**
 * salt 값이 바뀌면 배열 순서를 랜덤으로 바꾸고 반환 합니다
 * @param list
 * @param salt
 * @param isShuffle
 */
export const useSaltShuffle = <List extends any[]> (list: MayRef<List>, salt: MayRef<string>, isShuffle: MayRef<boolean> = true) => {
  const listRef = ref(list)
  const isShuffleRef = ref(isShuffle)
  const saltRef = wrapRef(salt)
  const shuffleListRef = ref<List>(listRef.value)
  
  watch(saltRef, () => {
    if (isShuffleRef.value) {
      shuffleListRef.value = shuffle(listRef.value) as List
    }
  })

  return computed(() => {
    if (isShuffleRef.value) {
      return shuffleListRef.value
    }
    return listRef.value
  })
}
