import {defineComponent, h} from 'vue'
import {systemProps} from '@/plugins/emotion'
import {StyledFooter} from './components'

// eslint-disable-next-line vue/one-component-per-file
export const AgFooterS = defineComponent({
  name: 'AgFooterS',
  setup(_, {slots}) {
    return () => (
      h(StyledFooter, () => slots?.default?.())
    )
  },
})

// eslint-disable-next-line vue/one-component-per-file
export const AgFooter = defineComponent({
  name: 'AgFooter',
  props: {
    ...systemProps,
  },
  setup(props, {slots}) {
    return () => (
      h(StyledFooter, {css: props}, () => slots?.default?.())
    )
  },
})
