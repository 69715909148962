/* eslint-disable vue/one-component-per-file */
import {useDebounce} from '@/composables/debounce'
import {IonRippleEffect} from '@ionic/vue'
import {
  defineComponent,
  ExtractPropTypes,
  FunctionalComponent,
  h,
  toRef,
} from 'vue'
import {containerProps, Content} from './components'
import {AgBoxS} from '@/components/ag/ag-box'

// export type ButtonType = 'button' | 'submit' | 'reset'
// export type RippleType = boolean | 'unbounded' | 'bounded'

export const agButtonSProps = {
  containerAs: null,
  debounce: {default: false, type: [Number, Boolean]},
  debounceOptions: {type: Object},
  disabled: {default: false, type: Boolean},
  ripple: {default: true, type: [Boolean, String]},
  type: {default: 'button', type: String},
}

export const AgButtonS = defineComponent({
  emits: ['click'],
  name: 'AgButtonS',
  props: {
    ...agButtonSProps,
    css: {default: () => ({}), type: Object},
  },
  setup(props, {slots, emit}) {
    const debounceRef = toRef(props, 'debounce')
    const debounceOptionsRef = toRef(props, 'debounceOptions')

    const onClickRef = useDebounce(
      (event) => emit('click', event),
      debounceRef,
      debounceOptionsRef,
    )

    return () => {
      const {ripple, disabled, containerAs = 'button', css} = props
      return (
        h(AgBoxS, {
          class: 'ion-activatable ripple-parent',
          containerAs,
          css: {
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            ...css as any,
          },
          disabled,
          onClick: onClickRef.value,
        }, {
          ...slots,
          append: () => [
            ...slots.append?.() ?? [],
            ripple ? h(IonRippleEffect, {type: typeof ripple === 'string' ? ripple : 'bounded'}) : null,
          ],
          default: () => h(Content, () => slots.default?.()),
          prepend: () => [
            ...slots.prepend?.() ?? [],
          ],
        })
      )
    }
  },
})

export const agButtonProps = {
  ...containerProps,
  ...agButtonSProps,
  block: {default: 'md', type: String},
  fill: {default: 'primary', type: String},
}

export type AgButtonProps = Partial<ExtractPropTypes<typeof agButtonProps>>


export const AgButton: FunctionalComponent<AgButtonProps> = (
  props, {slots},
) => {

  const {css, ripple, debounce, debounceOptions, disabled, type, ...rest} = props

  return (
    h(AgButtonS, {
      css: {...rest as any, ...css},
      debounce,
      debounceOptions,
      disabled,
      ripple,
      type,
    }, slots)
  )
}

AgButton.props = agButtonProps
