import {Property} from 'csstype'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'

export type FlexAlignSelfType = Property.AlignSelf
export type FlexJustifySelfType = Property.JustifySelf
export type FlexPlaceSelfType = Property.PlaceSelf
export type FlexAlignContentType = Property.AlignContent
export type FlexAlignItemsType = Property.AlignItems
export type FlexPlaceItemsType = Property.PlaceItems
export type FlexJustifyItemsType = Property.JustifyContent
export type FlexJustifyContentType = Property.JustifyContent
export type FlexPlaceContentType = Property.PlaceContent

export const placeSystems = system({
  alignContent: true,
  alignItems: true,
  justifyContent: true,
  justifyItems: true,
  placeContent: true,
  placeItems: true,
})

export const placeProps = {
  alignContent: getResponsiveProp<FlexAlignContentType>(),
  alignItems: getResponsiveProp<FlexAlignItemsType>(),
  justifyContent: getResponsiveProp<FlexJustifyContentType>(),
  justifyItems: getResponsiveProp<FlexJustifyItemsType>(),
  placeContent: getResponsiveProp<FlexPlaceContentType>(),
  placeItems: getResponsiveProp<FlexPlaceItemsType>(),
}

export const placeItemSystems = system({
  alignSelf: true,
  justifySelf: true,
  placeSelf: true,
})

export const placeItemProps = {
  alignSelf: getResponsiveProp<FlexAlignSelfType>(),
  justifySelf: getResponsiveProp<FlexJustifySelfType>(),
  placeSelf: getResponsiveProp<FlexPlaceSelfType>(),
}
