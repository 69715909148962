
import apiWithdrawal from '@/api/withdrawal'
import DeleteInfo from '@/components/Modal/DeleteInfo.vue'
import LeaveReason from '@/components/Modal/LeaveReason.vue'
import {AgBox} from '@/components/ag'
import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, modalController} from '@ionic/vue'
import {chevronBack} from 'ionicons/icons'
import {defineComponent, onBeforeMount, ref} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
  },
  name: 'DeleteAccount',
  setup() {
    const router = useRouter()
    // const curStatus = ref<string>('init')
    const {checkWithdrawal, getWithdrawalInfo, postWithdrawal} = apiWithdrawal()

    const isAvailable = ref<any>(null)
    const partnershipList = ref<any>(null)
    const withdrawalInfoList = ref<any>(null)
    const userWithdrawalSurveyItemId = ref<any>(null)
    const {dispatch} = useStore()

    const createModal = async (type: string) => {
      let modal: any = null

      if (type === 'survey') {
        modal = await modalController
          .create({

            backdropDismiss: true,

            component: LeaveReason,

            id: 'LeaveReasonModal',
            // mode: 'ios',
            keyboardClose: true,
            swipeToClose: true,
          })

        modal.onDidDismiss().then((id: any) => {
          if (id.data === 'close') {
            return null
          } 
            userWithdrawalSurveyItemId.value = id.data
            createModal('withdrawalInfo')
          
        })
      } else if (type === 'withdrawalInfo') {
        modal = await modalController
          .create({

            backdropDismiss: true,

            component: DeleteInfo,

            componentProps: {
              type,
            },

            cssClass: 'withdrawal-modal',

            id: 'DeleteInfo',
            // mode: 'ios',
            keyboardClose: true,
            swipeToClose: true,
          })

        modal.onDidDismiss().then((command: any) => {
          if (command.data === 'cancel') {
            return
          }
          if (command.data === 'confirm') {
            postWithdrawal(userWithdrawalSurveyItemId.value)
              .then((result) => {
                if (result) {
                  createModal('withdrawalDone')
                }
              })
          }
        })
      } else if (type === 'withdrawalDone') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: DeleteInfo,
            componentProps: {
              type,
            },
            cssClass: 'withdrawal-done-modal',
            id: 'DeleteInfo',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(() => {
          dispatch('user/ACT_LOGOUT').then(() => localStorage.clear())
          document.location.href = '/'
        })
      }

      return modal?.present()
    }

    onBeforeMount(() => {
      checkWithdrawal()
        .then((data) => {
          if (data.length === 0) {
            isAvailable.value = true
          } else {
            isAvailable.value = false
            partnershipList.value = data
          }
        })

      getWithdrawalInfo()
        .then((data) => {
          withdrawalInfoList.value = data.info
        })
    })

    return {
      chevronBack,
      createModal,
      isAvailable,
      partnershipList,
      router,
      withdrawalInfoList,
    }
  },
})
