
import {defineComponent, computed, getCurrentInstance, onMounted} from 'vue'
import {useStore} from 'vuex'
import {modalController, toastController, alertController, IonRippleEffect, IonContent} from '@ionic/vue'
import stockitemApi from '../../api/stock-item'
import {printFileNameMaxlength} from '../../utils/filters'
import SellItemModal from '@/components/Modal/SellItemModal.vue'

import {openMyFile} from '../../utils/OpenFiles'
const {deleteStockItemSell} = stockitemApi()

export default defineComponent({
  components: {IonContent, IonRippleEffect},
  name: 'SellItemViewModal',
  props: {
    sellInfoRef: null,
    updateCompleteRef: null,
  },
  setup(props) {
    const app = getCurrentInstance()
    const $modalOpenEvent = app && app.appContext.config.globalProperties.$modalOpenEvent
    const clickScreen = 'sell-item-complete-modal'
    onMounted(()=>{
      $modalOpenEvent({page: clickScreen, title: '팔기 조건 입력 완료 모달'})
    })

    const {dispatch} = useStore()

    const sellInfo = computed(() => props.sellInfoRef)
    const updateComplete = computed(() => props.updateCompleteRef)

    const dismiss = async () => {
      await modalController.dismiss()
      // sell업데이트
      await dispatch('stockItem/ACT_GET_STOCK_ITEM', {stockItemId: sellInfo.value.stockItemId})
    }

    const checkConfirm = async () => {
      await dismiss()
      const alert = await alertController
        .create({
          buttons: ['확인'],
          cssClass: 'lead-alert-class',
          message: `
        <div class="alert_css">
          <div class="header">
          등록 완료
          </div>
          <div class="message">
            주식 팔기 등록 되었습니다.<br/>
            증명서류 정보 검토가 완료되면,<br/>
            거래 가능한 매물로 전환됩니다.
          </div>
        </div>
        `,
        })
      return alert.present()
    }

    async function cancelStockItemSell() {
      const result = await deleteStockItemSell(sellInfo.value.stockItemWantSellId)
      if (result) {
        await dispatch('stockItem/ACT_GET_STOCK_ITEM', {stockItemId: sellInfo.value.stockItemId})
        await dismiss()
        const toast = await toastController
          .create({
            duration: 1000,
            message: '보유주식 팔기 요청이 삭제되었습니다.',
            position: 'middle',
          })
        return toast.present()
      }
    }

    const openAlert = async (type: string) => {
      if (type === 'cancel') {
        const alert = await alertController
          .create({
            buttons: [
              {
                cssClass: 'btn-gray',
                handler: () => {
                  // console.log('Cancel clicked');
                },
                role: 'cancel',
                text: '팔기 유지',
              },
              {
                handler: async () => {
                  await cancelStockItemSell()
                },
                text: '팔기 취소',
              },
            ],
            cssClass: 'btn-groups lead-alert-class',
            message: `
          <div class="alert_css">
            <div class="header">
            보유주식 팔기 취소
            </div>
            <div class="message">
              입력된 팔기 조건은 모두 삭제되며<br/>
              매물로 등록된 건은<br/>
              목록에서 보이지 않게됩니다.
            </div>
          </div>
          `,
          })
        return alert.present()
      }
    }

    const openSellModal = async () => {
      await dismiss()
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: SellItemModal,
          componentProps: {
            stockItemId: sellInfo.value.stockItemId,
            stockItemWantSellId: sellInfo.value.stockItemWantSellId,
          },
          cssClass: 'sell-item-modal',
          id: 'SellItemModal',
          keyboardClose: true,
          swipeToClose: true,
        })

      return modal.present()
    }

    return {
      checkConfirm,
      clickScreen,
      dismiss,
      openAlert,
      openMyFile,
      openSellModal,
      printFileNameMaxlength,
      sellInfo,
      updateComplete,
    }
  },
})
