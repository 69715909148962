import CallApi from '../utils/CallApi'

export default function() {
  return {
    actAgreeMarketing: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'put',
          url: '/user/agree-marketing',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    agreeThirdparty: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'put',
          url: '/user/agree-thirdparty',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    // registBankAccount : async (bankId: number, bankAccount: string) => {
    //   let result: any = [];
    //   try{
    //     result = await CallApi({
    //       url:`/user/bank-account`,
    //       method:'put',
    //       data: { bankId, bankAccount }
    //     });
    //   }catch(error){
    //     // console.log(error);
    //   }
    //   return result;
    // },
    // registStockBankAccount : async (stockBankId: number, stockBankAccount: string) => {
    //   let result: any = [];
    //   try{
    //     result = await CallApi({
    //       url:`/user/stock-bank-account`,
    //       method:'put',
    //       data: { stockBankId, stockBankAccount }
    //     });
    //   }catch(error){
    //     // console.log(error);
    //   }
    //   return result;
    // },
    getBankList: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/bank',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getStockBankList: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/stock-bank',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getUserSetting: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/user/setting',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    putAgreeMarketing: async (simplePassword: string) => {
      let result: any = []
      try {
        result = await CallApi({
          data: {simplePassword},
          method: 'put',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    // /user/bank-account
    putBankAccount: async (bankId: number, bankAccount: string) => {
      let result: any = []
      try {
        result = await CallApi({
          data: {
            bankAccount,
            bankId,
          },
          method: 'put',
          url: '/user/bank-account',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    // /user/stock-bank-account
    putStockBankAccount: async (stockBankId: number, stockBankAccount: string) => {
      let result: any = []
      try {
        result = await CallApi({
          data: {
            stockBankAccount,
            stockBankId,
          },
          method: 'put',
          url: '/user/stock-bank-account',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    registAddress: async (address1: string, address2: string, zipcode: string) => {
      let result: any = []
      try {
        result = await CallApi({
          data: {address1, address2, zipcode},
          method: 'put',
          url: '/user/address',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    registEmail: async (email: string) => {
      let result: any = []
      try {
        result = await CallApi({
          data: {email},
          method: 'put',
          url: '/user/email',
        })
      } catch {
        // console.log(error);
      }
      return result
    },
  }
}
