
import {defineComponent, computed, toRef, ref, reactive, onBeforeMount} from 'vue'
import {AgBox, AgBoxS, AgButton, AgContent, AgText, AgTextS, AgImg, AgFooter} from '@/components/ag'
import {useStore} from 'vuex'
import Keypad from './Keypad.vue'
import {alertController, toastController} from '@ionic/vue'
import useAlert from '@/composables/useAlert'
import apiWallet from '@/api/wallet'

export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgImg,
    AgText,
    AgTextS,
    Keypad,
  },
  emits: ['closeWithResult', 'close'],
  name: 'DepositRequestInfo',
  props: {
    openType: {type: String},
    parent: String,
    requestInfo: Object,
  },
  setup(props, {emit}) {
    const {getters} = useStore()
    const openTypeRef = toRef(props, 'openType')
    const requestInfoRef = toRef(props, 'requestInfo')
    const componentNumber = ref<number>(1)
    const sellStakeCount = ref<any>(null)
    const resultModal = ref<boolean>(false)
    const viewMyStakeOpen = ref<boolean>(false)
    const requestId = ref<any>(null)
    const parentRef = toRef(props, 'parent')
    const clickScreen = parentRef.value

    const depositInfo = ref<any>({})
    const depositMaxLimit = ref<number>(0)
    const currentBalance = ref<number>(0)
    const availableDeposit = ref<number>(0)

    const depositAmount = ref<any>(0)

    onBeforeMount(() => {
      apiWallet().getDepositInfo()
        .then((info: any) => {
          currentBalance.value = info.currentBalance
          depositMaxLimit.value = info.depositMaxLimit
          availableDeposit.value = info.depositMaxLimit - info.currentBalance
        })
    })





    const cancel = () => {
      emit('close')
    }

    const requestDeposit = async ()=> {
      const body = {
        amount: depositAmount.value,
      }
      const result: any = await apiWallet().createDeposit(body)
      const {amount, bank, bankholder, bankno, depositCode, depositId} = result
      depositInfo.value.amount = amount
      depositInfo.value.bank = bank
      depositInfo.value.bankholder = bankholder
      depositInfo.value.bankno = bankno
      depositInfo.value.depositCode = depositCode
      depositInfo.value.depositId = depositId
      componentNumber.value++
    }

    const cancelDeposit = async ()=> {
      await apiWallet().deleteDeposit()
      closeModal()
    }

    const {cancelDepositAlert} = useAlert()

    const cancelDepositConfirm = ()=>{
      cancelDepositAlert(cancelDeposit)
    }


    const checkDisable = computed(() => {
      if (!depositAmount.value || depositAmount.value < 10000 ||
          depositAmount.value > availableDeposit.value
      ) {
        return true
      }
      return false
    })

    const inputFunction = (value: any) => {
      depositAmount.value = Number(value)
    }

    const closeModal = () => {
      emit('closeWithResult')
    }

    const onSuccess = async () => {
      const toast = await toastController
          .create({
            duration: 1000,
            message: '입금 계좌가 클립보드에 복사되었습니다.',
            position: 'middle',
          })
      return toast.present()
    }
    const onSuccessCode = async () => {
      const toast = await toastController
          .create({
            duration: 1000,
            message: '입금 코드가 클립보드에 복사되었습니다.',
            position: 'middle',
          })
      return toast.present()
    }



    const onError = () =>{
      console.log('error')
    }

    return {
      availableDeposit,
      cancel,
      cancelDeposit,
      cancelDepositConfirm,
      checkDisable,
      clickScreen,
      closeModal,
      componentNumber,
      currentBalance,
      depositAmount,
      depositInfo,
      depositMaxLimit,
      inputFunc: inputFunction,
      onError,
      onSuccess,
      onSuccessCode,
      openTypeRef,
      requestDeposit,
      requestId,
      resultModal,
      sellStakeCount,
      viewMyStakeOpen,
    }
  },
})
