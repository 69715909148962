
import {defineComponent, computed, toRef, ref, reactive, onBeforeMount} from 'vue'
import {AgBox, AgBoxS, AgButton, AgContent, AgText, AgTextS, AgImg, AgFooter} from '@/components/ag'
import {useStore} from 'vuex'
import Keypad from './Keypad.vue'
import {alertController, popoverController, IonIcon, modalController} from '@ionic/vue'
import {helpCircleOutline} from 'ionicons/icons'
import Withdraw from '@/components/Popover/Withdraw.vue'
import AddressInput from '@/components/Modal/AddressInput.vue'
import AccountModal from '@/components/Modal/AccountModal.vue'
import AccountKeypadModal from '@/components/Modal/AccountKeypadModal.vue'
import useAlert from '@/composables/useAlert'
import apiWallet from '@/api/wallet'

export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgImg,
    AgText,
    AgTextS,
    IonIcon,
    Keypad,
  },
  emits: ['closeWithResult', 'close'],
  name: 'DepositRequestInfo',
  props: {
    openType: {type: String},
    parent: String,
    requestInfo: Object,
  },
  setup(props, {emit}) {



    const {getters, dispatch} = useStore()
    const openTypeRef = toRef(props, 'openType')
    const requestInfoRef = toRef(props, 'requestInfo')
    const componentNumber = ref<number>(1)
    const requestId = ref<any>(null)
    const parentRef = toRef(props, 'parent')
    const clickScreen = parentRef.value

    const withdrawAmount = ref<any>(0)
    const withdrawAvailableAmount = ref<number>(0)
    const dueDate = ref<string>('')

    onBeforeMount(async () => {
      await dispatch('user/ACT_SET_SETTING_INFO')
      apiWallet().getWithdrawInfo()
          .then((info: any) => {
            withdrawAvailableAmount.value = info.withdrawAvailableAmount
          })
    })

    const settingInfo = computed(() => getters['user/getSettingInfo'] || [])
    const bankInfo = computed(()=> {
      return {bankAccount:settingInfo.value?.bankAccount, bankName:settingInfo?.value?.bankName?.bankName}
    })
    const cancel = () => {
      emit('close')
    }

    const requestWithdraw = async ()=> {
      const body = {
        amount: withdrawAmount.value,
      }
      const result: any = await apiWallet().createWithdraw(body)
      dueDate.value = result.dueDate
      next()
    }

    const previous = ()=> {
      componentNumber.value--
    }

    const cancelWithdraw = async ()=> {
      await apiWallet().deleteWithdraw()
      closeModal()
    }

    const withdrawAll = () => {
      withdrawAmount.value = withdrawAvailableAmount.value
    }



    const {cancelWithdrawAlert} = useAlert()

    const cancelWithdrawConfirm = ()=>{
      cancelWithdrawAlert(cancelWithdraw)
    }

    const checkDisable = computed(() => {
      if (!withdrawAmount.value || withdrawAmount.value > withdrawAvailableAmount.value) {
        return true
      }
      return false
    })

    const inputFunction = (value: any) => {
      withdrawAmount.value = Number(value)
    }

    const closeModal = () => {
      emit('closeWithResult')
    }

    let popover: any = null
    const openPopover = async (event_: Event) => {
      if (popover) {
        return
      }
      popover = await popoverController
          .create({
            component: Withdraw,
            componentProps: {},
            cssClass: 'witdraw-info',
            event: event_,
            translucent: false,
          })

      popover.onDidDismiss().then((result: any) => {
        popover = null
      })
      return popover.present()
    }

    const createModal = async (name: string, accountType?: string, bankInfo?: any) => {
      let modal: any
      if (name === 'account') {
        modal = await modalController
            .create({
              backdropDismiss: true,
              component: AccountModal,
              componentProps: {
                accountType,
              },
              cssClass: 'address-modal',
              id: 'AccountModal',
              keyboardClose: true,
              mode: 'ios',
              swipeToClose: true,
            })

        modal.onDidDismiss().then((data: any) => {
          if (data.data) {
            createModal('accountKeypad', data.data.type, data.data.bankInfo)
          }
        })
      } else if (name === 'accountKeypad') {
        modal = await modalController
            .create({
              backdropDismiss: true,
              component: AccountKeypadModal,
              componentProps: {
                accountType,
                bankInfo,
              },
              cssClass: 'address-modal',
              id: 'AccountKeypadModal',
              keyboardClose: true,
              mode: 'ios',
              swipeToClose: true,
            })

        modal.onDidDismiss().then(async (data: any) => {
          if (data.data === 'replace') {
            createModal('account', accountType)
          } else {
            await dispatch('user/ACT_SET_SETTING_INFO')
            checkBankAccountAndGoNext()
          }
        })
      }

      return modal.present()
    }

    const checkBankAccountAndGoNext = () => {
      if(!bankInfo.value.bankAccount){
        createModal('account', 'refund')
      }else if(componentNumber.value===1){
        next()
      }
    }

    const next = () => {
      componentNumber.value++
    }

    return {
      bankInfo,
      cancel,
      cancelWithdraw,
      cancelWithdrawConfirm,
      checkBankAccountAndGoNext,
      checkDisable,
      clickScreen,
      closeModal,
      componentNumber,
      createModal,
      dueDate,
      helpCircleOutline,
      inputFunc: inputFunction,
      next,
      openPopover,
      openTypeRef,
      previous,
      requestId,
      requestWithdraw,
      withdrawAll,
      withdrawAmount,
      withdrawAvailableAmount,
    }
  },
})
