
import {computed, defineComponent, toRef} from 'vue'
import {AgBox, AgText, AgImg} from '@/components/ag'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    AgImg,
    AgText,
  },
  name: 'LeadAngelCarriedInterest',
  props: {
    carriedInterest: {type: String || Number},
    finalSellInfo: {default: null, type: Object},
    incomeFee: {type: String || Number},
    revenue: {type: String || Number},
  },
  setup(props) {
    const {getters} = useStore()
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const finalSellInfoRef = toRef(props, 'finalSellInfo')
    const incomeFeeRef = toRef(props, 'incomeFee')
    const carriedInterestRef = toRef(props, 'carriedInterest')
    const revenueRef = toRef(props, 'revenue')
    const expectedPriceRef = computed(() => finalSellInfoRef.value.expectedPrice || '')
    const expectedTotalPriceRef = computed(() => finalSellInfoRef.value.expectedTotalPrice || '')
    const expectedQtyRef = computed(() => finalSellInfoRef.value.expectedQty || '')
    const expectedAssetRef = computed(() => finalSellInfoRef.value.expectedAsset || '')

    return {
      carriedInterestRef,
      dealInfo,
      expectedAssetRef,
      expectedPriceRef,
      expectedQtyRef,
      expectedTotalPriceRef,
      finalSellInfoRef,
      incomeFeeRef,
      revenueRef,
    }
  },
})
