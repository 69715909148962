import {useDeepLinkReplace} from '@/composables/deep-link-replace'
import {getPlatforms} from '@ionic/vue'
import {defineComponent, h, onMounted, toRef} from 'vue'
import {useRouter} from 'vue-router'
import {AgButtonS, AgBoxS, AgTextS} from '@/components/ag'
import {appUniversalInstallUrl} from '@/environment'
import introPng from './assets/intro.png'
import introPng2 from './assets/intro@2x.png'
import introPng3 from './assets/intro@3x.png'
import {Plugins} from '@capacitor/core'

const {Browser} = Plugins

const _useragentCache = navigator.userAgent.toLowerCase()

const isInFacebookWebView = (lowerUserAgent: string) => {
  if (lowerUserAgent.indexOf('fban') > -1) {
    return true
  }
  return lowerUserAgent.indexOf('fbav') > -1
}

const isInKakaotalkWebView = (lowerUserAgent: string) => {
  return lowerUserAgent.indexOf('kakaotalk') > -1
}

const DeepLinkRedirectPage = defineComponent({
  props: {
    link: {
      default: '/',
      type: String,
    },
  },
  setup: (props) => {
    const linkRef = toRef(props, 'link')
    const schemeLink = `angelleague://${linkRef.value}`
    const router = useRouter()
    const iframe = document.createElement('iframe')
    const runByLink = useDeepLinkReplace()
    iframe.src = schemeLink
    const platforms = getPlatforms()
    const isIos = platforms.includes('ios')
    const isAndroid = platforms.includes('android')
    const isHybrid = platforms.includes('hybrid')
    const isFacebookWebView = isInFacebookWebView(_useragentCache)
    const isKakaotalkWebView = isInKakaotalkWebView(_useragentCache)
    const storeLink = appUniversalInstallUrl()

    const onDeepLink = () => {
      const link = linkRef.value

      if (isHybrid) {
        return router.replace('/')
      }

      if (isIos) {
        if (isKakaotalkWebView) {
          location.href = schemeLink
          return
        } else if (isFacebookWebView) {
          window.location.replace(schemeLink)
          return
        }
      } else if (isAndroid && (isKakaotalkWebView || isFacebookWebView)) {
        document.body.appendChild(iframe)
        return
      }

      runByLink(link)
    }
    
    const onOpenAppInstall = () => {
      Browser.open({
        url: storeLink,
      })
    }

    onMounted(onDeepLink)

    return () => (
      h(AgBoxS, {
        css: {
          alignItems: 'center',
          direction: 'column',
          dp: 'flex',
          height: '100%',
          px: 30,
        },
      }, () => [
        h(AgBoxS, {
          containerAs: 'img',
          css: {
            mt: 100,
            typography: 'tit24b',
          },
          sizeSet: `${introPng2} 2x, ${introPng3} 3x`,
          src: introPng,
        }),
        h(AgTextS, {
          css: {
            mb: 8,
            typography: 'tit20r',
          },
        }, () => '엔젤리그'),
        h(AgTextS, {}, () => '손쉬운 비상장주식 공동투자'),
        h(AgBoxS, {css: {grow: 1}}),
        h(AgButtonS, {css: {block: 'sm', mb: 16, outline: 'primary', width: '100%'}, onClick: onOpenAppInstall}, () => '앱이 없다면? 설치하러 가기!'),
        h(AgButtonS, {css: {block: 'sm', mb: 40, width: '100%'}, onClick: onDeepLink}, () => '지금 바로 경험하기'),
      ])
    )
  },
})

export default DeepLinkRedirectPage
