
import {defineComponent, onBeforeMount, computed, inject, defineAsyncComponent, ref} from 'vue'
import {useStore} from 'vuex'

const modalPages = {
  login: defineAsyncComponent(process.env.VUE_APP_UI_MODE === 'ios-pass' ? () => import('./Settings2.vue') : () => import('./Settings.vue')),
  logout: defineAsyncComponent(process.env.VUE_APP_UI_MODE === 'ios-pass' ? () => import('./SettingsNon2.vue') : () => import('./SettingsNon.vue')),
}

export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  name: 'SettingsParent',
  setup() {
    const {getters} = useStore()
    const user = computed(() => getters['user/getUser'])
    const currentView = computed(() => user.value ? 'login' : 'logout')

    return {curView: currentView, modalPages, user}
  },
})

