import {Plugin} from 'vue'

export const filter: Plugin = (app) => {
  app.config.globalProperties.$filters = {
    currency(value: any) {
      // // console.log(value)
      if (value === undefined) {
return ''
}
      const num = Number.parseInt(value)
      if (!isNaN(num)) {
        if (Number.parseFloat(value) % 1 !== 0) {
          return Number.parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        } 
          return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        
      } 
        return value
      
    },
    getDate(value: any) {
      const date = value
      let time = ' ' + date.getFullYear().toString().slice(2, 4) + '. ' + date.getMonth() + '. ' + date.getDate()
      time += ' ' + date.getHours() + ':'
      if (date.getMinutes() < 10) {
        time += '0' + date.getMinutes()
      } else {
        time += date.getMinutes()
      }
      return time
    },
    getMonthDay(date: Date) {
      const stringArray = date.toString().split('-')
      stringArray[1] = stringArray[1].charAt(0) === '0' ? stringArray[1].charAt(1) : stringArray[1]
      return stringArray[1] + '. ' + stringArray[2]
    },
    getTime(value: string) {
      const date = new Date(value)
      let time = date.getHours() - 12 < 0 ? '오전 ' : '오후 '

      if (date.getHours() === 0) {
        time += 12 + ':'
      } else {
        time += date.getHours() - 12 < 0 ? date.getHours() + ':' : (date.getHours() - 12).toString() + ':'
      }

      if(date.getMinutes() < 10) {
        time += '0' + date.getMinutes()
      } else {
        time += date.getMinutes()
      }
      return time
    },
    newLineToBr(value: any) {
      return value ? value.replace(/\n/gi, '<br/>') : ''
    },
    transDate(value: any) {
      if (!value) {
return
}
      return value.replace(/-/gi, '. ')
    },
  }
}

export default filter
