import {systems, styled, colorSystem} from '@/plugins/emotion'
import {IonIcon} from '@ionic/vue'

export const ionicIconColorSystem = (props: any) => {
  const result = colorSystem(props)
  return {
    ...result,
    '--ion-color-base': result.color,
  }
}

export const iconProps = {
  ariaLabel: {type: String},
  color: {type: String},
  flipRtl: {type: String},
  icon: null,
  ios: {type: String},
  lazy: {type: String},
  md: {type: String},
  mode: {type: String},
  name: {type: String},
  size: {type: String},
  src: {type: String},
}

export const StyledIcon = styled(IonIcon, {
  stylePortal: 'css',
})(
  systems,
  ionicIconColorSystem,
)
