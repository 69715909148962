
import {IonPage, IonContent, IonRippleEffect, IonRefresher, IonRefresherContent} from '@ionic/vue'
import {defineComponent, onMounted, computed, inject, ref, onUnmounted} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {openLinkWithAccessTokenAndSocketId, toFixed} from '@/utils/filters'
import {Browser} from '@capacitor/core'
import useModal from '@/composables/useModal'
import {useSocketIO} from '@/composables/socket-io'
import {arrowDownOutline} from 'ionicons/icons'
import CollectClubDeals from '@/components/Modal/Home/CollectClubDeals.vue'
export default defineComponent({
  components: {
    CollectClubDeals,
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRippleEffect,
  },
  name: 'ContractStatus',
  setup() {
    const {getters, dispatch} = useStore()
    const {viewCollectClubdealsModal} = useModal()
    const router = useRouter()
    const {socket} = useSocketIO()

    onMounted(async () => {
      await dispatch('asset/loadData')
    })

    onUnmounted(() => {
      console.log('onMounted')
      socket?.disconnect?.()
    })

    const docuign = computed(() => getters['asset/getDocusign'])

    const SOCKET_ID = ref('')

    socket.on('connect', function() {
      // console.log('SOCKET Connected');
      // console.log(socket.id);
      SOCKET_ID.value = socket.id
    })
    //
    socket.on('disconnect', function() {
      // console.log('SOCKET Disconnected');
    })
    socket.on('SEND_OK', async function(data: any) {
      // console.log('SEND_OK');
      console.log(data)
    })
    socket.on('DOCUSIGN_COMPLETE', async function() {
      console.log('DOCUSIGN_COMPLETE')
      await dispatch('asset/loadData')
      await Browser.close()
    })

    const doRefresh = async (event: any) => {
      await dispatch('asset/loadData')
      event.target.complete()
    }

    const clickScreen = 'contract'
    const clubDealAllOpen = ref<boolean>(false)
    return {
      SOCKET_ID,
      arrowDownOutline,
      clickScreen,
      clubDealAllOpen,
      doRefresh,
      docuign,
      openLinkWithAccessTokenAndSocketId,
      router,
      toFixed,
      viewCollectClubdealsModal,
    }
  },
})
