import {MayRef} from '@/types'
import {wrapRef, animationRepeater} from '@winter-love/use'

export const VERY_SLOW = 0.05
export const DEFAULT_STUCK = 90
export const FULL_PROGRESS = 100
export const START_PROGRESS = 0
export const LAST_TIP = 0.1

export interface UseFakeProgressOptions {
  criticalPoint?: number
}

export const useFakeProgress = (progress?: MayRef<number | undefined>, toggle?: MayRef<boolean | undefined>, options: UseFakeProgressOptions = {}) => {
  const {criticalPoint = DEFAULT_STUCK} = options
  const progressRef = wrapRef(progress, {initState: START_PROGRESS})

  const tick = () => {
    const progress = progressRef.value
    if (progress >= FULL_PROGRESS - LAST_TIP) {
      progressRef.value = FULL_PROGRESS
      return
    }
    if (progress > criticalPoint) {
      progressRef.value += VERY_SLOW - (VERY_SLOW * (progress - criticalPoint) / (FULL_PROGRESS - criticalPoint))
      return
    }
    progressRef.value += (1 - (progress / criticalPoint)) + VERY_SLOW
  }

  const toggleRef = animationRepeater(tick, toggle)

  return [progressRef, toggleRef]
}
