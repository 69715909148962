import {defineComponent, h, toRef} from 'vue'
import {AgButtonS} from '@/components/ag'
import {DeleteIcon} from './DeleteIcon'

export interface ButtonSlotProps {
  children: any
  index: number
  key: string
  onClick?: (key: string) => any
}

export const Button = defineComponent({
  emits: ['click'],
  props: {
    css: {type: Object},
    index: {required: true, type: Number},
    keyName: {required: true, type: String},
  },
  setup(props, {slots, emit}) {
    const keyName = toRef(props, 'keyName')
    const index = toRef(props, 'index')
    const css = toRef(props, 'css')
    const onClick = () => emit('click', keyName.value)
    return () => {
      const defaultSlots: ((props: ButtonSlotProps) => any) | undefined = slots.default
      let children: any
      if (keyName.value === 'delete') {
        children = h(DeleteIcon)
      } else {
        children = keyName.value
      }



      if (defaultSlots) {
        return defaultSlots({
          children, index: index.value, key: keyName.value, onClick,
        })
      }

      return (
        h(AgButtonS, {css: {
            bg: 'transparent',
            block: null,
            borderColor: 'transparent',
            color: 'white',
            corner: 'full',
            height: 30,
            p: 0,
            typography: 'tit24b',
            width: 30,
            ...css.value,
          }, onClick}, () => children)
      )
    }
  },
})
