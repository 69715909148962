<template>
  <ion-card>
    <ion-card-content>
      <ion-grid>
        <ion-col>
          <ion-row>
            <ion-text>
              <span>
                {{ text.earlyAccess.message }}
              </span>
            </ion-text>
          </ion-row>
          <ion-row>
            <ion-button @click="router.push('/tabs')">
              {{ text.earlyAccess.button }}
            </ion-button>
          </ion-row>
        </ion-col>
        <ion-col>
          <ion-row>
            <ion-text>
              <span>
                {{ text.sellStock.message }}
              </span>
            </ion-text>
          </ion-row>
          <ion-row>
            <ion-button @click="router.push('/tabs')">
              {{ text.sellStock.button }}
            </ion-button>
          </ion-row>
        </ion-col>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {defineComponent} from 'vue'
import {IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonRow, IonText} from '@ionic/vue'
import {useRouter} from 'vue-router'

export const text = {
  earlyAccess: {
    button: '조합지분 거래 사전 신청',
    message: '참여하신 조합의 지분 팔기가 곹 가능해져요',
  },
  sellStock: {
    button: '보유 주식 팔기',
    message: '보유주식 팔면 수수료 0원, 100,000P 적립',
  },
}

export default defineComponent({
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  name: 'SearchHotLinks',
  props: {
    // todo 모달 로직을 바꿀 수가 없어서 임시 대용
    user: null,
  },
  setup() {
    const router = useRouter()

    return {
      router,
      text,
    }
  },
})
</script>

<style scoped>
  ion-card {
    box-shadow: none
  }

</style>
