
import {AgBox, AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {IonSpinner} from '@ionic/vue'
import {defineComponent, ref, computed, defineAsyncComponent, toRef, watch} from 'vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {useStore} from 'vuex'
import {getPurchaseCheck} from '@/api/stake-deal'
import {Browser} from '@capacitor/core'
import {openLinkWithAccessToken} from '@/utils/filters'
import {API_URL} from '@/config'
import {retryAsync} from '@/utils'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

const modalPages = {
  FAIL: {
    component: defineAsyncComponent(() => import('./confirm-buy/FailToConfirm.vue')),
  },
  WAIT: {
    component: defineAsyncComponent(() => import('./confirm-buy/WaitUntilDone.vue')),
  },
}
export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    IonSpinner,
    ModalHeader,
  },
  emits: ['close', 'fail', 'success'],
  name: 'ConfirmBuyModal',
  props: {
    isOpen: {require: true, type: Boolean},
  },
  setup(props, {emit}) {
    const gtm = useGtm()
    const isOpenRef = toRef(props, 'isOpen')
    const setOpen = () => emit('close')
    const modalPageName = ref<ModalPageName>('WAIT')
    const modalPage = computed(() => modalPages[modalPageName.value].component)

    const {getters, commit} = useStore()
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const partnershipShareSellId = computed(() => dealInfo.value.partnershipShareSellId)
    const negostockUrl = computed(() => getters['stakeDeal/negostockUrl'])
    const isWaitingConfirm = ref(false)
    const retryCount = ref(0)
    const retryStatus = ref('undefined')
    const retryError = ref('')

    const goToNext = async () => {
      try {
        if (modalPageName.value === 'FAIL') {
          setOpen()
        } else {
          isWaitingConfirm.value = true
          const result = (await retryAsync(() => getPurchaseCheck(partnershipShareSellId.value), {
            count: 10,
            duration: 1000,
            isExit: (data) => (data.result?.status === null),
            isOk: (data) => (data.result?.status === 'SHARE_IN'),
            tick: (data) => {
              retryCount.value += 1
              retryStatus.value = JSON.stringify(data)
            },
          })).result
          isWaitingConfirm.value = false

          // null ACCEPT SHARE_IN

          if (result.status === 'SHARE_IN') {
            await commit('stakeDeal/SET_BUY_RESULT', result)
            await emit('success')
            await setOpen()
            return
          }
          await emit('fail')

        }
        modalPageName.value = 'FAIL'
      } catch (error) {
        retryError.value = JSON.stringify(error)
      }
    }

    const reRequest = async () => {
      await openLinkWithAccessToken(API_URL + negostockUrl.value)
      modalPageName.value = await 'WAIT'
    }

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }

    const buttonText = computed(() => {
      if (modalPageName.value === 'WAIT') {
        return [{func: goToNext, title: '네, 확인했어요'}]
      }
      return [{func: openChannelTalk, title: '1:1 문의'}, {func: reRequest, title: '다시 확인하기'}]

    })

    const printIsClicked = () => {
      console.log('clicked!!')
    }

    return {
      buttonText,
      goToNext,
      isOpenRef,
      isWaitingConfirm,
      modalPage,
      modalPageName,
      openChannelTalk,
      printIsClicked,
      reRequest,
      retryCount,
      retryError,
      retryStatus,
      setOpen,
    }
  },
})
