import {Module} from 'vuex'
import {RootState} from '..'
import apiHome from '@/api/main-home'

// state
interface Home {
  activeBoardTop: Record<any, any>
  fivePopularShareTransaction: Record<any, any>
  investorRecommend: Record<any, any>
  leadTop: Record<any, any>
  likeStocks: Record<any, any>
  partnershipsHome: Record<any, any>
  popularItemTop3: Record<any, any>
  popularItemTop50: Record<any, any>
  popularShareTransaction: Record<any, any>
  stockItemTop3: Record<any, any>
  stockItemTop50: Record<any, any>
}

// action
const Point: Module<Home, RootState> = {

  actions: {
    async ACT_ACTIVE_BOARD_TOP({commit}, orderByCount) {
      commit('SET_ACTIVE_BOARD_TOP', orderByCount)
    },
    async ACT_GET_FIVE_POPULAR_SHARE_TRANSACTION({commit}, command: any) {
      const list = await apiHome().getFivePopularShareTransaction()
      commit('SET_FIVE_POPULAR_SHARE_TRANSACTION', list)
    },
    async ACT_GET_POPULAR_SHARE_TRANSACTION({commit}, command: any) {
      const list = await apiHome().getPopularShareTransaction()
      commit('SET_POPULAR_SHARE_TRANSACTION', list)
    },
    async ACT_INVESTOR_RECOMMEND({commit}) {
      const value = await apiHome().getInvestorRecommend()
      commit('SET_INVESTOR_RECOMMEND', value.investorRecommend)
    },
    async ACT_LEAD_TOP({commit}, orderByLeadLike) {
      commit('SET_LEAD_TOP', orderByLeadLike)
    },
    async ACT_POPULAR_ITEM_TOP3({commit}, orderBySearch) {
      commit('SET_POPULAR_ITEM_TOP3', orderBySearch)
    },

    async ACT_STOCK_ITEM_TOP3({commit}, orderByLike) {
      commit('SET_STOCK_ITEM_TOP3', orderByLike)
    },
    async loadData({commit}) {
      // let stockItems = await getStockItems()
      // stockItems = stockItems.stockItems
      //
      // const likeCount = stockItems.sort((a: any, b: any) => b.likeCount - a.likeCount).map((a: any) => a.stockItemId)
      // const searchCount = stockItems.sort((a: any, b: any) => b.searchCount - a.searchCount).map((a: any) => a.stockItemId)
      // const leadLikeCount = stockItems.sort((a: any, b: any) => b.leadLikeCount - a.leadLikeCount).map((a: any) => a.stockItemId)
      // const count = stockItems.sort((a: any, b: any) => b.count - a.count).map((a: any) => a.stockItemId)
      //
      // commit('SET_STOCK_ITEM_TOP50', likeCount.map((id: string)=>stockItems.find((stock: any)=>stock.stockItemId.toString()===id.toString())))
      // commit('SET_POPULAR_ITEM_TOP50', searchCount.map((id: string)=>stockItems.find((stock: any)=>stock.stockItemId.toString()===id.toString())))
      // commit('SET_LEAD_TOP', leadLikeCount.map((id: string)=>stockItems.find((stock: any)=>stock.stockItemId.toString()===id.toString())))
      // commit('SET_ACTIVE_BOARD_TOP', count.map((id: string)=>stockItems.find((stock: any)=>stock.stockItemId.toString()===id.toString())))

      // apiHome().getStockItemTop3().then((value) => {
      //   commit('SET_STOCK_ITEM_TOP3',value)
      // })
      // apiHome().getPopularItemTop3().then((value) => {
      //   commit('SET_POPULAR_ITEM_TOP3',value)
      // })
      // apiHome().getLeadTop().then((value) => {
      //   commit('SET_LEAD_TOP',value)
      // })
      apiHome().getPartnershipsHome().then((value) => {
        commit('SET_PARTNERSHIPS_HOME', value)
      })
      apiHome().getFivePopularShareTransaction().then((value) => {
        commit('SET_FIVE_POPULAR_SHARE_TRANSACTION', value)
      })
      // apiHome().getActiveBoardTop().then((value) => {
      //   commit('SET_ACTIVE_BOARD_TOP',value)
      // })
    },
  },

  getters: {
    getActiveBoardTop: state => state.activeBoardTop,
    getFivePopularShareTransaction: state => state.fivePopularShareTransaction,
    getInvestorRecommend: state => state.investorRecommend,
    getLeadTop: state => state.leadTop,
    getLikeStock: state => state.likeStocks,
    getPartnershipsHome: state => state.partnershipsHome,
    getPopularItemTop3: state => state.popularItemTop3,
    getPopularItemTop50: state => state.popularItemTop50,
    getPopularShareTransaction: state => state.popularShareTransaction,
    getStockItemTop3: state => state.stockItemTop3,
    getStockItemTop50: state => state.stockItemTop50,
  },

  // RootState와 똑같이 작성해도 된다.
  mutations: {
    SET_ACTIVE_BOARD_TOP(state, object: any) {
      state.activeBoardTop = object
    },
    SET_FIVE_POPULAR_SHARE_TRANSACTION(state, list: any) {
      state.fivePopularShareTransaction = list
    },
    SET_INVESTOR_RECOMMEND(state, object: any) {
      state.investorRecommend = object
    },
    SET_LEAD_TOP(state, object: any) {
      state.leadTop = object
    },
    SET_LIKE_STOCKS(state, object: Record<any, any>) {
      state.likeStocks = object
    },
    SET_PARTNERSHIPS_HOME(state, object: Record<any, any>) {
      state.partnershipsHome = object
    },
    SET_POPULAR_ITEM_TOP3(state, object: any) {
      state.popularItemTop3 = object
    },
    SET_POPULAR_SHARE_TRANSACTION(state, list: any) {
      state.popularShareTransaction = list
    },
    SET_STOCK_ITEM_TOP3(state, object: any) {
      state.stockItemTop3 = object
    },
  },
  namespaced: true,
  state: {
    activeBoardTop: [],
    fivePopularShareTransaction: {},
    investorRecommend: [],
    leadTop: {},
    likeStocks: {},
    partnershipsHome: {},
    popularItemTop3: {},
    popularItemTop50: {},
    popularShareTransaction: {},
    stockItemTop3: {},
    stockItemTop50: {},
  },
}

export default Point
