import {createStore, Store} from 'vuex'
// 기존 import Vuex from 'vuex'
import user from './store/user'
import dealInfo from './store/dealinfo'
import message from './store/message'
import partnership from './store/partnership'
import stockItem from './store/stock-item'
import pointInfo from './store/point'
import asset from './store/asset'
import common from './store/common'
import stockOption from './store/stock-option'
import openChannel from './store/open-channel'
import home from './store/home'
import preApplication from './store/pre-application'
import stakeDeal from './store/stake-deal'
import wallet from './store/wallet'
import {watch} from 'vue'
export interface RootState {
  apiAvailable: boolean
  data: string
  isGlobalLoading: any
  isGlobalToast: any
}
// 개발을 위해 스토어 구조 확인용
const stateLog = (store: Store<any>) => {
  if (process.env.NODE_ENV === 'development') {
    store.subscribe((_, state) => {
      sessionStorage.setItem('vuex', JSON.stringify(state))
    })
  }
}

export const store = createStore<RootState>({
  actions: {


    ACT_SET_API_AVAILABLE({commit}, flag: any) {
      commit('setApiAvailable', flag)
    },


ACT_SET_GLOBAL_LOADING({commit, state}, data: any) {
      if (!data.open) {
        commit('setGlobalLoading', data)
      } else if (!state.isGlobalToast.open) {
        commit('setGlobalLoading', data)
      }
    },


ACT_SET_GLOBAL_TOAST({commit, state}, data: any) {
      console.log(data, state.isGlobalToast.open)
      if (!data.open) {
        commit('setGlobalToast', data)
      } else if (!state.isGlobalToast.open) {
        commit('setGlobalToast', data)
      }
    },
    // ActionContext 타입이 RootState이므로 위에 있으니 따로 지정안해줘도 됨.
// 기본적으로 RootState에서 관리하기 때문에 각 모듈별로 네임스페이스를 지정해줘야 해당 모듈만 데이터 변화가능.
setRootData({commit}, data: string) {
      commit('setData', data)
    },

  },
  getters: {
    apiAvailable: state => state.apiAvailable,
    data: state => state.data,
    isGlobalLoading: state => state.isGlobalLoading,
    isGlobalToast: state => state.isGlobalToast,
  },
  modules: {
    asset,
    common,
    dealInfo,
    home,
    message,
    openChannel,
    partnership,
    pointInfo,
    preApplication,
    stakeDeal,
    stockItem,
    stockOption,
    user,
    wallet,
  },
  mutations: {
    setApiAvailable(state, flag: false) {
      state.apiAvailable = flag
    },
    setData(state, data: string) {
      state.data = data
    },
    setGlobalLoading(state, data: any) {
      state.isGlobalLoading = {
        ...state.isGlobalLoading,
        ...data,
      }
    },
    setGlobalLoadingMessage(state, message: any) {
      state.isGlobalLoading.message = message
    },
    setGlobalToast(state, data: any) {
      state.isGlobalToast = {
        ...state.isGlobalToast,
        ...data,
      }
    },
    setGlobalToastMessage(state, message: any) {
      state.isGlobalToast.message = message
    },
  },
  plugins: [stateLog],
  state: {
    apiAvailable: true,
    data: 'root',
    isGlobalLoading: {
      message: '서버와 연결을 시도 중입니다.',
    },
    isGlobalToast: {
      buttons: [],
      duration: 0,
      message: '',
      open: false,
    },
  },
})

export default store

// watch(store.getters['user/getUser'], (user) => {
//   console.log("----vuex/index.ts----")
//   console.log(user)
// })
