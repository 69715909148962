import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_toggle_icon = _resolveComponent("ag-toggle-icon")!
  const _component_ag_text = _resolveComponent("ag-text")!
  const _component_ag_box = _resolveComponent("ag-box")!

  return (_openBlock(), _createBlock(_component_ag_box, {
    dp: "flex",
    direction: "column",
    "padding-bottom": "30px",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('radioClick', _ctx.indexRef)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_box, { dp: "flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_toggle_icon, {
            style: {"flex-shrink":"0","padding-top":"5px"},
            value: _ctx.checkedRef,
            name: "check"
          }, null, 8, ["value"]),
          _createVNode(_component_ag_text, {
            p: "0px 0px 16px 16px",
            typography: "tit18b"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.agreeRef.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ag_text, {
        dp: "block",
        "padding-left": "36px",
        typography: "main14r"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.agreeRef.content), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}