import {styled, systems, systemProps} from '@/plugins/emotion'
import {CSSObject} from '@emotion/css'
import {IonContent} from '@ionic/vue'
import {ExtractPropTypes, PropType} from 'vue'

export const styledContentProps = {
  ...systemProps,
  scrollPart: {type: Object as PropType<ExtractPropTypes<typeof systemProps>>},
}

export const StyledContent = styled(IonContent, {
  name: 'StyledContent',
  props: {
    ...styledContentProps,
  },
  stylePortal: 'css',
})(
  {
    '&::part(background)': {
      display: 'none',
    },
    '&::part(scroll)': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
      width: '100%',
    },
    // it will cover refresher icon so remove this for now
    // background: 'white',
    contain: 'unset',
    display: 'block',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    minHeight: 0,
    position: 'relative',
    // contain: 'size style',
    visibility: 'inherit',
  } as CSSObject,
  systems,
  ({scrollPart}) => {
    if (!scrollPart) {
      return {}
    }
    return {
      '&::part(scroll)': systems(scrollPart),
    }
  },
)
