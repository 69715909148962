import {currency} from '@/utils/filters'
import {alertController} from '@ionic/vue'
export default function useAlert() {
  async function showBank(partnershipRequest: any) {
    const alert = await alertController
      .create({
        buttons: ['확인'],
        cssClass: 'bank-alert-class',
        message: `
          <div class="alert_css">
            <div class="header">
              참여금 입금정보
            </div>
            <div class="message">
              <b>${partnershipRequest.bank}
              ${partnershipRequest.bankno}<br/>
              ${partnershipRequest.bankholder}</b><br/><br/>
              ${currency(partnershipRequest.asset)} 원<br/><br/>
              <span class="red">(입금 가능기한 ${partnershipRequest.dueDate})</span>
            </div>
          </div>`,
      })
    return alert.present()
  }
  
  const watingWithdrawAlert = async () => {
    const alert = await alertController
      .create({
        buttons: [
          {
            text: '확인',
          },
        ],
        cssClass: 'wait-alert-class',
        message: `
          <div class="alert_css">
            <div class="header">
            출금 완료 후<br/>
            충전 신청이 가능해요!
            </div>
            <div class="message">
              출금 대기중인 예수금이 있습니다.<br/>
              출금 신청을 취소하거나,<br/>
              출금이 완료된 후 충전 신청 해주세요!
            </div>
          </div>
          `,
      })
    return alert.present()
  }
  
  const cancelWithdrawAlert = async (callback: any) => {
    const alert = await alertController
      .create({
        buttons: [
          {
            cssClass: 'btn-gray',
            role: 'cancel',
            text: '아니오',
          },
          {
            handler: () => {
              callback()
            },
            text: '네, 취소할게요',
          },
        ],
        cssClass: 'btn-groups adderss-alert-class',
        message: `
          <div class="alert_css">
            <div class="header">
            출금을 취소하시겠어요?
            </div>
            <div class="message">
              예수금 출금 신청 내용을<br>
              삭제합니다.
            </div>
          </div>
          `,
      })
    return alert.present()
  }
  const cancelDepositAlert = async (callback:any) => {
    const alert = await alertController
      .create({
        buttons: [
          {
            cssClass: 'btn-gray',
            role: 'cancel',
            text: '아니오',
          },
          {
            handler: () => {
              callback()
            },
            text: '네, 취소할게요',
          },
        ],
        cssClass: 'btn-groups adderss-alert-class',
        message: `
          <div class="alert_css">
            <div class="header">
            충전을 취소하시겠어요?
            </div>
            <div class="message">
              예수금 충전 신청 내용을<br>
              삭제합니다.
            </div>
          </div>
          `,
      })
    return alert.present()
  }
  const wating24HoursAlert = async (dateTime: string, type: string, amount: string) => {
    
    
    
    const alert = await alertController
      .create({
        buttons: [
          {
            text: '확인',
          },
        ],
        cssClass: 'pay-alert-class',
        message: `
          <div class="alert_css">
            <div class="header">
            출금 신청을<br/>
            조금만 기다려주세요!
            </div>
            <div class="message">
              24시간 내 충전된 내역이 있습니다<br/>
              <div class="blue" >
                <div>${dateTime}</div>
                <div>${type}</div>
                <div>${currency(amount)} 원</div>
              </div>
              
              전자금융 사기 피해 방지를 위해<br/>
              충전일로부터 24시간이 지난 후<br/>
              출금이 가능합니다 🙏
            </div>
          </div>
          `,
      })
    return alert.present()
  }
  
  const notEnoughAmountAlert = async () => {
    const alert = await alertController
      .create({
        buttons: [
          {
            text: '확인',
          },
        ],
        cssClass: 'cancel-alert-class',
        message: `
          <div class="alert_css">
            <div class="header">
            출금 가능 금액이 부족해요 😭
            </div>
            <div class="message">
              출금 수수료(1,000원)보다 큰 예수금이<br/>
              있어야 출금이 가능합니다.
            </div>
          </div>
          `,
      })
    return alert.present()
  }
  
  const limitDepositAlert = async () => {
    const alert = await alertController
      .create({
        buttons: [
          {
            text: '확인',
          },
        ],
        cssClass: 'cancel-alert-class',
        message: `
          <div class="alert_css">
            <div class="header">
            예수금 한도 초과 😮
            </div>
            <div class="message">
              보유 예수금이 1,000만원 이상이면<br/>
              더 이상 충전할 수 없습니다.
            </div>
          </div>
          `,
      })
    return alert.present()
  }
  
  const chargeDepositAlert = async (amount: string, callback: any)=>{
    const alert = await alertController
      .create({
        buttons: [
          {
            cssClass: 'btn-gray',
            role: 'cancel',
            text: '취소',
          },
          {
            handler: () => {
              callback()
            },
            text: '충전하기',
          },
        ],
        cssClass: 'btn-groups time-alert-class',
        message: `
          <div class="alert_css">
            <div class="header">
            예수금이 부족해요
            </div>
            <div class="message">
              이 매물을 구매하시려면<br>
              예수금을 ${currency(amount)}원 이상 <br>
              충전해주세요!
            </div>
          </div>
          `,
      })
    return alert.present()
  }

  return {
    cancelDepositAlert, cancelWithdrawAlert,
    chargeDepositAlert, limitDepositAlert,
    notEnoughAmountAlert,
    showBank,
    wating24HoursAlert,
    watingWithdrawAlert,
  }
}
