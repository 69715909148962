
import {IonIcon} from '@ionic/vue'
import {AgImg, AgIntersectBox, AgBoxS, AgTextS} from '@/components/ag'
import {defineComponent, toRef} from 'vue'
import {prettyDateToOnemonth, currency} from '@/utils/filters'
import {personCircleOutline} from 'ionicons/icons'
export default defineComponent({
  components: {
    AgBoxS,
    AgImg,
    AgIntersectBox,
    AgTextS,
    IonIcon,
  },
  props: {
    stakeInfo: {type: Object},
  },
  setup(props, {emit}) {
    const stakeInfoRef = toRef(props, 'stakeInfo')

    return {
      currency,
      personCircleOutline,
      prettyDateToOnemonth,
      stakeInfoRef,
    }
  },
})
