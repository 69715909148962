import CallApi from '../utils/CallApi'

export default function() {
  return {

    getActiveBoardTop: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: 'stock-items/likes/active-board-top?perPage=10',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    getFivePopularShareTransaction: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/share-transaction/popularity?page=1&perPage=5',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    getInvestorRecommend: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/common/templates/investor-recommend',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    // getLikeStock : async () => {
    //   let result: any = [];
    //   try{
    //     result = await CallApi({
    //       url:`stock-items/likes`,
    //       method:'get',
    //     });
    //   }catch(error){
    //     // console.log(error);
    //   }
    //   return result;
    // },
    getLeadTop: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: 'stock-items/likes/lead-top?perPage=10',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    getPartnershipsHome: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: 'partnerships/home?perPage=4',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPopularItemTop3: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: 'stock-items/search/popularity?perPage=3&page=1',
        })
      } catch {
        // console.log(error);
      }

      return result.popularStockItems
    },

    getPopularItemTop50: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: 'stock-items/search/popularity?perPage=50&page=1',
        })
      } catch {
        // console.log(error);
      }

      return result.popularStockItems
    },

    getPopularShareTransaction: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/share-transaction/popularity?page=1&perPage=50',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    getStockItemTop3: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: 'stock-items/likes/top?perPage=3',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    getStockItemTop50: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: 'stock-items/likes/top?perPage=50',
        })
      } catch {
      // console.log(error);
      }

      return result
    },
  }
}
