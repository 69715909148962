<template>
  <div class="wrapper">
    <div class="indicator-wrap">
      <div class="indicator_rec" />
    </div>
    <div
      v-if="!reValue.stepToggle"
      class="items-container"
    >
      <div class="pad-title">
        제안 가격
      </div>
      <div class="pad-price">
        <span class="strong">{{ $filters.currency(reValue.price) }}</span> 원
      </div>
    </div>
    <div
      v-else
      class="items-container"
    >
      <div class="pad-title">
        제안 규모
      </div>
      <div class="pad-price">
        <span class="strong">{{ $filters.currency(reValue.scale) }}</span> 원
      </div>
    </div>
    <div :class="[reValue.stepToggle ? activeClass : 'white', 'pad-total']">
      <span
        v-if="!reValue.stepToggle"
        class="gray_text"
      >판매자 조건 7,000 원</span>
      <span
        v-else
        class="gray_text"
      >3,254 주 / 판매자 요청 조건 350,000,000 원 (6,821 주)</span>
    </div>
    <div class="deal-center">
      <div class="pad-container">
        <div
          v-for="row in [0,1,2]"
          :key="row"
          class="row"
        >
          <div
            v-for="col in 3"
            :key="col"
            class="letter"
            @click="addNumber(row * 3 + col)"
          >
            <span> {{ row * 3 + col }} </span>
          </div>
        </div>
        <div class="row">
          <div
            class="letter"
            @click="addNumber('00')"
          >
            <span>00</span>
          </div>
          <div
            class="letter"
            @click="addNumber(0)"
          >
            <span>0</span>
          </div>
          <div
            class="letter"
            @click="clearNumber('del')"
          >
            <img
              src="/assets/icon/Delete@3x.png"
              class="Delete"
              alt="backspace"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="space-between">
      <button
        v-if="!reValue.stepToggle"
        class="left_btn btn_groups"
        @click="dismiss"
      >
        취소
      </button>
      <button
        v-else
        class="left_btn btn_groups"
        @click="preStep"
      >
        이전
      </button>
      <button
        v-if="!reValue.stepToggle"
        class="right_btn btn_groups"
        @click="nextStep"
      >
        다음
      </button>
      <button
        v-else
        class="right_btn btn_groups"
        @click="nextStep"
      >
        제안 전송
      </button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from 'vue'
import {useStore} from 'vuex'
// import router from "../../router";
import {modalController} from '@ionic/vue'

export default defineComponent({
  components: {},
  name: 'BottomModal',
  setup() {
    const {getters, dispatch} = useStore()
    const reValue = reactive({
      deal: {
        comment: 3,
        date: '1일 전',
        minPrice: 6000,
        name: '컬리14호 조합지분',
        percent: 3.62,
        price: 605000,
        total: 345677000,
        user: 'j*****',
      },
      percent: '0',
      price: '0',
      stepToggle: false,
    })
    let payload
    const sendRequest = () => dispatch('dealInfo/SEND_REQUEST', payload)

    // value
    const activeClass = 'pad-total'

    const dismiss = () => {
      modalController.dismiss()
    }

    const nextStep = () => {
      if (reValue.stepToggle === true) {
        payload = {percent: reValue.percent, price: reValue.price, total: reValue.deal.total * reValue.percent}
        sendRequest()
        modalController.dismiss()
        return
      }
      reValue.stepToggle = true
    }

    const preStep = () => {
      reValue.stepToggle = false
    }

    function addNumber(_value) {
      let value = _value
      value = value.toString()
      let result = ''
      if (reValue.stepToggle) {
        result = reValue.percent
      } else {
        result = reValue.price
      }

      if (value === '0' && result === '0') {
        return
        // 따로 작업을 해주지 않음!
      } else if (value === '0') {
        result += '0'
      } else if (result === '0') {
        result = value
      } else {
        result += value
      }

      if (reValue.stepToggle) {
        const dot = result.slice(Math.max(0, result.indexOf('.') + 1))
        if (Number(result) > 100 || dot.length > 2) {
return
}
      }

      if (reValue.stepToggle) {
        reValue.percent = result
      } else {
        reValue.price = result
      }
    }

    function clearNumber(value) {
      let result = ''
      if (reValue.stepToggle) {
        result = reValue.percent
      } else {
        result = reValue.price
      }

      if (value === 'clear') {
        result = ''
      } else if (value === 'del') {
        if (result === '') {
          return
        } 
          result = result.slice(0, - 1)
        
      }

      if (reValue.stepToggle) {
        reValue.percent = result
      } else {
        reValue.price = result
      }
    }

    function addDot() {
      if (!reValue.percent.includes('.')) {
        reValue.percent += '.'
      }
      // console.log(reValue.percent);
    }

    return {activeClass, addDot, addNumber, clearNumber, dismiss, nextStep, payload, preStep, reValue}
  },
})
</script>

<style scoped>
.pad-container {
margin-bottom: 35px;
margin-top: 35px;
padding: 0px 19px 0px 19px;
}

.wrapper {
margin: 0px 25px 0px 25px;
}

.items-container {
text-align: center;
margin-top: 36px;
}

.pad-title {
font-size: 16px;
margin-bottom: 16px;
}

.pad-price {
font-size: 36px;
margin-bottom: 8px;
}

.deal-center {
margin-top : 15px;
}

.deals-price {
margin-bottom: 5px;
}

.deal-title, .deal-details, .deals-price, .deals-percent {
display:flex;
flex-direction: row;
justify-content: space-between;
}

.items-container {
display:flex;
flex-direction: column;
width: 100%;
}

.deals-percent {
text-align: right;
}

.small {
font-size: 13px;
color: #999;
}

.deal-details > * {
margin-top: 3px;
}

.name {
color: #999;
}

hr.divide {
height: 0px;
border-bottom: 1px solid #EEF1F7;
}

.deal-total {
text-align: right;
margin-top : 37px;
font-size: 24px;
}

.deal-btn {
margin-top: 10px;
display: flex;
  flex-wrap: wrap;
}

.indicator-wrap {
text-align: center;
}

.letter {
display: inline-block;
width: 46px;
font-size: 24px;
font-weight: 700;
}

.row {
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 24px;
text-align: center;
}

.clear {
font-size: 13.5px;
}

.pad-total {
text-align: center;
}

.white {
color: white;
}

.left_btn {
color: #4360EC;
width: 100%;
border: 1px solid #4360EC;
background-color:#131937;
}

.right_btn {
color: white;
width: 100%;
background: #4360EC;
margin-left: 5px;
}

.btn_groups {
font-size: 16px;
font-weight: 500;
padding: 20px 0px 20px 0px;
border-radius: 10px;
}

.indicator {
width: 42px;
height: 5px;
background: #D9DEE8;
opacity: 0.2;
border-radius: 100px;
display: inline-block;
}

.gray_text {
color: rgba(255, 255, 255, 0.5);
}

  /* .pad-container {
    margin-top: 35px;
  }

  .wrapper {
    margin: 0px 25px 0px 25px;
  }

  .items-container {
    text-align: center;
    margin-top: 26px;
  }

  .pad-title {
    font-size: 16px;
  }

  .pad-price {
    font-size: 36px;
  }

  .deal-center {
    margin-top : 15px;
  }

  .deals-price {
    margin-bottom: 5px;
  }

  .deal-title, .deal-details, .deals-price, .deals-percent {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .items-container {
    display:flex;
    flex-direction: column;
    width: 100%;
  }

  .deals-percent {
    text-align: right;
  }

  .small {
    font-size: 13px;
    color: #999;
  }

  .deal-details > * {
    margin-top: 3px;
  }

  .name {
    color: #999;
  }

  hr.divide {
    height: 0px;
    border-bottom: 1px solid #EEF1F7;
  }

  .deal-total {
    text-align: right;
    margin-top : 37px;
    font-size: 24px;
  }

  .leftBtn {
    color: #4A90E2;
    border: 2px solid #4A90E2;
    border-radius: 5px;
    background-color: white;
    font-size: 16.5px;
  }

  .deal-btn {
    margin-top: 10px;
    display: flex;
    justify-content: wrap;
  }

  .rightBtn {
    color: white;
    border-radius: 5px;
    background-color: #4A90E2;
    font-size: 16.5px;
  }

  .indicator-wrap {
    text-align: center;
    margin-bottom: 10px;
  }

  .letter {
    display: block;
    width: 100%;
    text-align: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
  }

  .letter {
    font-size: 20px;
  }

  .letter:not(:last-child) {
    margin-right: 15px;
  }

  .clear {
    font-size: 13.5px;
  }

  .pad-total {
    text-align: center;
  }

  .white {
    color: white;
  } */
</style>
