
import {computed, defineComponent, onBeforeMount, ref} from 'vue'
import {IonPage} from '@ionic/vue'
import {AgHeader, AgContent, AgButton, AgText} from '@/components/ag'
import apiTest from '@/api/test'
export default defineComponent({
  components: {
    AgButton,
    AgContent,
    AgHeader,
    AgText,
    IonPage,
  },
  name: 'Test4',
  setup() {


    const codeString = ref<string>('')
    const messageString = ref<string>('')

    onBeforeMount(async ()=>{
      console.log('???')
      try{
        const result = await apiTest().notAcceptableTest()
      }catch(error){
        console.log(error.response)
        console.log(error.response.data)
        if(error.response.status === 406){
          const {code, msg} = error.response.data
          codeString.value = code
          messageString.value = msg
        }


      }

    })

    return {
      codeString, msgString: messageString,
    }
  },
})
