
import {AgBox, AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {computed, defineComponent, defineAsyncComponent, ref, toRef} from 'vue'
import {IonProgressBar, IonRippleEffect} from '@ionic/vue'
import {useStore} from 'vuex'

const modalPages = {
  DONE: {},
  FIRST: {
    component: defineAsyncComponent(() => import('./partnership-join/FirstStep.vue')),
    height: '100%',
    leftButton: '취소',
    progress: 0.25,
    rightButton: '동의합니다',
    title: '조합 참여 위험안내',
  },
  // FOURTH: {
  //   component: defineAsyncComponent(() => import('./partnership-join/FourthStep.vue')),
  //   height: '100%',
  //   leftButton: '이전',
  //   previousPage: 'THIRD',
  //   progress: 0.5,
  //   rightButton: '서명하기',
  //   title: '조합설립 확인서 서명',
  // },
  SECOND: {
    component: defineAsyncComponent(() => import('./partnership-join/SecondStep.vue')),
    height: '100%',
    leftButton: '이전',
    previousPage: 'FIRST',
    progress: 0.25,
    rightButton: '확인완료',
    title: '조합 참여 위험안내',
  },
  // THIRD: {
  //   component: defineAsyncComponent(() => import('./partnership-join/ThirdStep.vue')),
  //   height: '100%',
  //   leftButton: '이전',
  //   previousPage: 'SECOND',
  //   progress: 0.5,
  //   rightButton: '다음',
  //   title: '조합설립 계약서 동의',
  // },
}
export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    IonProgressBar,
    IonRippleEffect,
  },
  emits: ['close', 'goToNext', 'update:modelValue'],
  props: {
    // dealInfo: {type: Object},
    modelValue: {type: Boolean},
    parent: {type: String},
  },
  setup(props, {emit}) {
    const {getters} = useStore()
    const modalPageName = ref<ModalPageName>('FIRST')
    const modalPage: any = computed(() => modalPages[modalPageName.value])
    const buttonDisabled = ref<boolean>(true)
    const nextPage = ref<ModalPageName>('FIRST')
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const parentRef = toRef(props, 'parent')
    const readyToGo = (pageName: any) => {
      nextPage.value = pageName
      buttonDisabled.value = false
    }

    const goToPrevious = () => {
      if (modalPageName.value === 'FIRST') {
emit('close')
} else {
        modalPageName.value = modalPage.value.previousPage
      }
      scrollToTop()
    }

    function scrollToTop() {
      const content: any = document.querySelector('#pt__ionContent')
      content.scrollToPoint(0, 0)
    }

    const goToNext = () => {
      if (nextPage.value === 'DONE') {
        emit('goToNext', 'SIGN_TO_JOIN')
      } else {
        modalPageName.value = nextPage.value
        buttonDisabled.value = true
      }
      scrollToTop()
    }

    const clickScreen = parentRef.value

    return {
      buttonDisabled,
      clickScreen,
      dealInfo,
      goToNext,
      goToPrevious,
      modalPage,
      modalPageName,
      nextPage,
      readyToGo,
      scrollToTop,
    }
  },
})
