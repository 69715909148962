
import {defineComponent, toRef} from 'vue'
import {AgText, AgHeader, AgBox} from '@/components/ag'
import {} from '@ionic/vue'

export default defineComponent({
  components: {
    AgBox,
    AgHeader,
    AgText,
  },
  emits: ['close'],
  name: 'ModalHeader',
  props: {
    title: {type: String},
  },
  setup(props, {emit}) {
    const titleRef = toRef(props, 'title')
    const modalClose = () => {
      emit('close')
    }

    return {
      modalClose,
      titleRef,
    }
  },
})
