
import {AgBox, AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader, AgImg} from '@/components/ag'
import {IonContent, IonPage, modalController} from '@ionic/vue'
import {defineComponent, h, toRef} from 'vue'
import {useStore} from 'vuex'
import AddressInput from '@/components/Modal/AddressInput.vue'
import AccountModal from '@/components/Modal/AccountModal.vue'
import AccountKeypadModal from '@/components/Modal/AccountKeypadModal.vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgImg,
    AgModal,
    AgText,
    ModalHeader,
  },
  emits: ['update:modelValue', 'openSellModal'],
  name: 'PersonalInfo',
  props: {
    infoType: {default: '주소', require: true, type: String},
    infoValue: {default: null, type: String},
  },
  setup(props, {emit}) {
    const {getters, dispatch} = useStore()
    const infoTypeRef = toRef(props, 'infoType')
    const infoValueRef = toRef(props, 'infoValue')

    const setOpen = (value: boolean) => {
      emit('update:modelValue', value)
    }

    const checkFunction = () => {
      emit('openSellModal')
    }

    const infoMap = {
      은행계좌: {
        default: '본인명의 은행계좌를 입력해주세요',
        func: () => useSettingModal('account', 'refund'),
      },
      주소: {
        default: '조합 참여를 위한 주소 정보가\n필요합니다.',
        func: () => useSettingModal('address'),
      },
      증권계좌: {
        default: '본인명의 증권계좌를 입력해주세요',
        func: () => useSettingModal('account', 'stock'),
      },
    }

    async function useSettingModal(name: string, accountType?: string, bankInfo?: any) {
      let modal: any
      if (name === 'address') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AddressInput,
            cssClass: 'address-modal',
            id: 'AddressInput',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(() => {
          console.log('꺼짐')
          dispatch('user/ACT_SET_SETTING_INFO')
        })
      } else if (name === 'account') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AccountModal,
            componentProps: {
              accountType,
            },
            cssClass: 'address-modal',
            id: 'AccountModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then((data: any) => {
          if (data.data) {
            useSettingModal('accountKeypad', data.data.type, data.data.bankInfo)
          }
        })
      } else if (name === 'accountKeypad') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AccountKeypadModal,
            componentProps: {
              accountType,
              bankInfo,
            },
            cssClass: 'address-modal',
            id: 'AccountKeypadModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then((data: any) => {
          if (data.data === 'replace') {
            useSettingModal('account', accountType)
          } else {
            dispatch('user/ACT_SET_SETTING_INFO')
          }
        })
      }

      return modal.present()
    }

    return {checkFunction, infoMap, infoTypeRef, infoValueRef, setOpen}
  },
})
