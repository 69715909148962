import {isVNode, Slots, VNode} from 'vue'


export const parseNode = (node: VNode): string => {
  const {children} = node

  if (typeof children === 'string') {
    return children
  }
  if (Array.isArray(children)) {
    return children.map((item: any) => parseNode(item)).join('')
  }
  return ''
}

/**
 * @param slots
 * @param key
 */
export function getStringSlot(slots: Slots, key: string): string {
  const slot = slots[key]?.()

  if (!slot) {
    return ''
  }

  if (Array.isArray(slot)) {
    return slot.map((slot) => {
      return parseNode(slot)
    }).join('')
  }

  if (isVNode(slot)) {
    return parseNode(slot)
  }

  return ''
}
