import {includes} from 'lodash'
import {useStore} from 'vuex'
import {Stock} from './types'
import {computed, ref, Ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export const useSelectStock = (stocks: Ref<Stock[]>) => {
  const router = useRouter()

  const selectedItemId = ref<number | null>(null)

  const selectedItem = computed(() => {
    if (selectedItemId.value === null) {
      return null
    }
    return stocks.value.find((item) => {
      return item.stockItemId === selectedItemId.value
    })
  })

  const onSelect = (id: number) => {
    selectedItemId.value = id
    return router.replace(`/tabs/search/${id}`)
  }

  return {
    onSelect,
    selectedItem,
    selectedItemId,
  }
}

export const useSearchStock = (stocks: Ref<Stock[]>) => {
  const searchValue = ref('')

  const searchedItems = computed(() => {
    if (!searchValue.value) {
      return []
    }

    return stocks.value.filter((item) => {
      const {stockItemName = ''} = item

      return stockItemName.includes(searchValue.value)
    })
  })

  return {
    searchValue,
    searchedItems,
  }
}

export const useStorkId = (paramsKey: string = 'storkId') => {
  const route = useRoute()

  return computed(() => route.params[paramsKey])
}

export const useStocks = () => {
  const store = useStore()

  const stocks = computed<Stock[]>(() => (store.getters['stockItem/getStockItems']))

  const popularStockIds = computed<number[]>(() => store.getters['stockItem/getPopularStockItem'])

  const popularStocks = computed(() => {
    return stocks.value.filter((item) => {
      return includes(popularStockIds.value, item.stockItemId)
    })
  })

  return {
    popularStocks,
    stocks,
  }
}

export const useLoadDeal = () => {
  const store = useStore()

  return async () => {
    await store.dispatch('stockItem/ACT_GET_POPULARITY')
    store.commit('stockItem/SET_INIT_RECENT_VIEW_STOCK_ITEM')
  }
}

export const useRefresh = (before: () => Promise<any>) => {
  const store = useStore()

  return async () => {
    await before()
    return Promise.all([
      store.dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS'),
      store.dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS'),
      store.dispatch('stockItem/ACT_GET_POPULARITY'),
    ])
  }
}
