
import {
  IonPage,
  IonContent,
  alertController,
  IonRippleEffect,
  toastController,
  modalController,
} from '@ionic/vue'
import {Notification} from './components/Notification'
import InputModal from '@/components/Modal/InputModal.vue'
import {arrowDownOutline} from 'ionicons/icons'
import {defineComponent, computed, onBeforeMount, ref, watch, getCurrentInstance} from 'vue'
import {useStore} from 'vuex'
import {throttle} from 'lodash'
import {useRoute, useRouter} from 'vue-router'
import apiPartnership from '@/api/partnership'
import {SendBirdAction} from '@/plugins/SendBirdAction'
import useModal from '@/composables/useModal'
import {AgButton, AgButtonS, AgRefresher, AgTextS, AgBoxS} from '@/components/ag'
import {cancelFromBuyer, cancelFromSeller, postConfirmSell} from '@/api/stake-deal'
import {openLink} from '@/utils/filters'
import {Browser} from '@capacitor/core'
import {useShare, useAppConfig} from '@/composables'
import WalletRequestModal from '@/views/MyPage/wallet/modal/wallet-request-modal.vue'
import useAlert from '@/composables/useAlert'
import apiWallet from '@/api/wallet'
import dayjs from 'dayjs'
import {NftMintBanner} from '@/components/NftMintBanner'

export default defineComponent({
  components: {
    AgBoxS,
    AgButton,
    AgButtonS,
    AgRefresher,
    AgTextS,
    IonContent,
    IonPage,
    IonRippleEffect,
    NftMintBanner,
    Notification,
    WalletRequestModal,
  },
  name: 'MyPage',
  setup() {
    const app = getCurrentInstance()
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const {getters, dispatch, commit, state} = useStore()
    const {delPartnershipRequests} = apiPartnership()
    const user = computed(() => getters['user/getUser'])
    const isLead = computed(() => user.value.leadAngelId)
    const printTime = (second: any) => {
      if (second >= 60) {
        return `${Math.floor(second / 60)}분 전`
      }
        return `${second}초 전`

    }
    const share = useShare()
    const isCreator = computed(() => user.value.creatorId)
    const appConfig = useAppConfig()
    const showNotificationButton = computed(() => {
      return appConfig.notificationFeature
    })

    const router = useRouter()
    const route = useRoute()

    const {useClubDealProgressModal, openFAQModal, viewKakaoKlipModal} = useModal()

    const getTokenValidDuration = computed(() => getters['user/getTokenValidDuration'])
    const point = computed(() => getters['pointInfo/getUserPoint'])
    const partnershipComplete = computed(() => getters['asset/getPsComplete'])
    const shareTransactions = computed(() => getters['asset/getShareTransactions'])
    const partnershipCollecting = computed(() => getters['asset/getPsCollecting'])
    // const earlyBird = computed(() => getters[`asset/getEarlyBird`]);
    const exit = computed(() => getters['asset/getExit'])
    const stockOption = computed(() => getters['asset/getStockOption'])
    const depositInfo = computed(() => getters['asset/getDepositInfo'])
    const shareTransactionsDepositInfo = computed(() => getters['asset/getShareTransactionsDepositInfo'])
    const stBuyerDepositInfo = computed(() => {
      const array = [...shareTransactionsDepositInfo.value]
      return array.filter((a) => a.buyerUserId === user.value.userId)
    })
    const stSellerDepositInfo = computed(() => {
      const array = [...shareTransactionsDepositInfo.value]
      return array.filter((a) => a.sellerUserId === user.value.userId)
    })
    const totalAsset = computed(() => getters['asset/getTotalAsset'])
    const docusign = computed(() => getters['asset/getDocusign'])

    const recommendList = computed(() => getters['partnership/getEntireRecommendPartnership'])
    const groupChannels = ref<any>(0)
    const mypageNameHeader = ref<any>(null)


    /**
     * 지갑 관련 변수들
     */
    const depositRequested = ref<boolean>(false)
    const withdrawRequested = ref<boolean>(false)
    const walletInfo = computed(() => getters['wallet/getWalletInfo'])
    const depositRequestInfo = ref<any>({})
    const withdrawRequestInfo = ref<any>({})
    const withdrawRequestInfoExpirationAt = ref<string>('')
    const withdrawProhibit = ref<any>(null)
    const openType = ref<string>('')
    const depositRequestOpen = ref<boolean>(false)


    const formattedDate = (updateDate: any) => {
      return dayjs(updateDate).format('MM.DD HH:mm')
    }

    const getDepositRequested = async () => {
      const result: any = await apiWallet().getDepositInfo()
      depositRequestInfo.value = result.depositRequest
      depositRequested.value = Boolean(depositRequestInfo.value)
    }

    const getWithdrawRequested = async () => {
      const result: any = await apiWallet().getWithdrawInfo()
      withdrawRequestInfo.value = result.withdrawRequest
      withdrawRequestInfoExpirationAt.value = result.expirationAt
      withdrawRequested.value = Boolean(withdrawRequestInfo.value)
    }

    const getWithdrawProhibit = async () => {
      const result: any = await apiWallet().withdrawRequestCheck()
      if(result.amount){
        withdrawProhibit.value = result
      }else{
        withdrawProhibit.value = null
      }
    }

    const branchResult = async () => {
      await getDepositRequested()
      await getWithdrawRequested()
      depositRequestOpen.value = false
    }

    const cancelDeposit = async ()=> {
      await apiWallet().deleteDeposit()
      await getDepositRequested()
    }
    const cancelWithdraw = async ()=> {
      await apiWallet().deleteWithdraw()
      await getWithdrawRequested()
    }


    const {watingWithdrawAlert, limitDepositAlert, cancelWithdrawAlert, cancelDepositAlert, wating24HoursAlert, notEnoughAmountAlert} = useAlert()

    const callWalletFunction = async (target: string)=>{
      if(target==='deposit'){
        if(depositRequested.value){
          return cancelDepositAlert(cancelDeposit)
        }else if(withdrawRequested.value){
          return watingWithdrawAlert()
        }else if(walletInfo.value.currentBalance>=10000000){
          return limitDepositAlert()
        }
      }else if (target==='withdraw'){
        await getWithdrawProhibit()
        if(withdrawRequested.value){
          return cancelWithdrawAlert(cancelWithdraw)
        }else if(withdrawProhibit.value){
          const {amount, dateTime, type} = withdrawProhibit.value
          return wating24HoursAlert(dateTime, type, amount)
        }else if(walletInfo.value.currentBalance<=1000){ //출금 가능 금액 부족
          return notEnoughAmountAlert()
        }
      }

      openType.value = target
      depositRequestOpen.value = true
    }


    async function initThisPage() {
      dispatch('pointInfo/getPointLink')
      await dispatch('pointInfo/loadData')
      await dispatch('user/ACT_SET_SETTING_INFO')
      await dispatch('asset/loadData')

      await dispatch('asset/ACT_GET_ASSET_PARTNERSHIPS')
      await dispatch('partnership/ACT_GET_ENTIRE_RECOMMEND_PARTNERSHIPS')
      await dispatch('message/ACT_UNREAD_COUNT')
      const channelList = computed(() => getters['message/getChannelList'])
      groupChannels.value = channelList.value.length

      await dispatch('wallet/ACT_GET_WALLET_INFO')
      await getDepositRequested()
      await getWithdrawRequested()
      return dispatch('user/updateUserInfo')
    }
    onBeforeMount(async () => {
      await initThisPage()
    })
    watch(
      () => route.path,
      async newPath => {
        if (newPath === '/tabs/mypage') {
          await initThisPage()
        }
      },
    )
    const registCode = computed(() => getters['pointInfo/getRegistInviteCode'])

    const createModal = async () => {
      const modal = await modalController
      .create({
        backdropDismiss: true,
        component: InputModal,
        componentProps: {
          registCode: registCode.value,
        },
        cssClass: 'input-modal',
        id: 'InputModal',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })

      return modal.present()
    }

    const doRefresh = async (event: any) => {
      await initThisPage()
      event.target.complete()
    }

    // onUpdated(()=>{
    //   // console.log('MyPageAuth Component is onUpdated!');
    //   dispatch(`pointInfo/loadData`);
    //   dispatch(`asset/loadData`);
    //   SendBirdAction.getInstance().getGroupChannelList(false)
    //       .then((data: any) => {
    //         groupChannels.value = data.length;
    //       })
    // })

    const pointBox = ref<any>(null)
    const walletBox = ref<any>(null)

    const handleScroll = throttle(async (event: any) => {
      const scroll = await event.getScrollElement()

      if (scroll.scrollTop > 20) {
        // pointBox.style.cssText = `opacity : (50-${scroll.scrollTop})/100`;
        const opacity = await (1 - (scroll.scrollTop * 0.01))
        pointBox.value.style.cssText = `opacity : ${opacity}`
      } else {
        pointBox.value.style.cssText = 'opacity : 1'
      }
      if (scroll.scrollTop > 120) {
        // pointBox.style.cssText = `opacity : (50-${scroll.scrollTop})/100`;
        const opacity = await (1 - ((scroll.scrollTop-120) * 0.01))
        walletBox.value.style.cssText = `opacity : ${opacity}`
      } else {
        walletBox.value.style.cssText = 'opacity : 1'
      }

      // if (scroll.scrollTop > 205) {
      //   mypageNameHeader.value.classList.add('shadow')
      // } else {
      //   mypageNameHeader.value.classList.remove('shadow')
      // }
    }, 33)

    async function sendCancelClubDealMessage(message: string, channelUrl: string) {
      if (!message) {
        return
      }
      const sendbirdAction: any = await SendBirdAction.getInstance()
      sendbirdAction
        .getChannel(channelUrl, false)
        .then((channel: any) => {
          sendbirdAction.sendUserMessage({
            channel: channel,
            message: message,
            status: 'cancelToSeller',
          })
        })
    }

    const cancelBuyAction = async (purchaseInfo: any, index: any) => {
      const result = await cancelFromBuyer(purchaseInfo.partnershipShareSellId, purchaseInfo.partnershipSharePurchaseId)
      if (result) {
        commit('asset/DEL_SHARE_TRANSACTION_DEPOSIT_INFO', index)
      }
    }

    async function cancelToBuy(purchaseInfo: any, index: any) {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              handler: async () => {
                $clickTrackEvent({'clickArea':'구매를 취소하시겠어요?', 'clickContent':'아니오', clickScreen})
              },
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                $clickTrackEvent({'clickArea':'구매를 취소하시겠어요?', 'clickContent':'네, 취소할게요', clickScreen})
                cancelBuyAction(purchaseInfo, index)
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups adderss-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              구매를 취소하시겠어요?
            </div>
            <div class="message">
              구매 요청 내역, 구매금 입금 계좌와<br>
              조합가입 확인서는 모두 삭제됩니다
            </div>
          </div>`,
        })

      return alert.present()
    }

    const cancelSellAction = async (sellInfo: any, index: any) => {
      console.log(sellInfo)
      const result = await cancelFromSeller(sellInfo.partnershipShareSellId, sellInfo.partnershipSharePurchaseId)
      if (result) {
        commit('asset/DEL_SHARE_TRANSACTION_DEPOSIT_INFO', index)
      }
    }

    async function cancelToSell(sellInfo: any, index: any) {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                cancelSellAction(sellInfo, index)
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups seller-cancel-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              거래를 취소하시겠어요?
            </div>
            <div class="message">
              구매자에게 정중한 거래 취소 메시지를<br>
              전송한 후, 이번 거래를 종료합니다.<br>
              거래 종료 후<br>
               해당 매물은 판매, 판매취소, 조건 수정이
              가능한 상태가 됩니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    const confirmSell = async (sellInfo: any, index: any) => {
      const result = await postConfirmSell(sellInfo.partnershipShareSellId, sellInfo.partnershipSharePurchaseId)
      if (result) {
        commit('asset/DEL_SHARE_TRANSACTION_DEPOSIT_INFO', index)
        initThisPage()

        const toast = await toastController
          .create({
            cssClass: 'toast-custom-blue',
            duration: 2000,
            message: '거래 확정이 성공적으로 완료되었습니다.',
            position: 'middle',
          })
        return toast.present()
      }
    }

    async function confirmAlert(sellInfo: any, index: any) {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '취소',
            },
            {
              handler: async () => {
                confirmSell(sellInfo, index)
              },
              text: '확인',
            },
          ],
          cssClass: 'btn-groups buy-confirm-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              거래를 확정합니다.
            </div>
            <div class="message">
              거래를 확정하시면<br>
              즉시 조합원 자격 이전 후<br>
              판매금이 예수금으로 충전됩니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    async function openAlert(type: string, command?: string, partnership?: any) {
      let alert: any = ''
      const typeString = type === 'clubdeal' ? '클럽딜' : '얼리버드'
      if (command === 'cancel') {
        const id = partnership.partnershipId
        const requestId = partnership.partnershipRequestId

        alert = await alertController
          .create({
            buttons: [
              {
                cssClass: 'btn-gray',
                role: 'cancel',
                text: '취소',
              },
              {
                handler: async () => {
                  if (type === 'clubdeal') {
                    const message = await delPartnershipRequests(requestId, id)
                    if (partnership.partnershipOpenChannelId) {
                      await sendCancelClubDealMessage(message.channelMessage, partnership.channelUrl)
                    }
                  }

                  await openAlert(type)
                },
                text: '확인',
              },
            ],
            cssClass: 'btn-groups cancel-alert-class',
            message: `
          <div class="alert_css">
            <div class="header">
            참여 취소하기
            </div>
            <div class="message">
              ${typeString} 참여 취소 하시겠어요?
            </div>
          </div>
          `,
          })
      } else {
        alert = await alertController
          .create({
            buttons: [
              {
                handler: async () => {
                  await dispatch('asset/loadData')
                  await dispatch('pointInfo/loadData')
                },
                text: '확인',
              },
            ],
            cssClass: 'cancel-alert-class',
            message: `
          <div class="alert_css">
            <div class="header">
            참여 취소 완료
            </div>
            <div class="message">
              ${typeString} 참여가 취소되었습니다.
            </div>
          </div>
          `,
          })
      }

      return alert.present()
    }

    const textDiv = ref<any>(null)

    const onSuccess = async (message?: string) => {
      await initThisPage()

      const toast = await toastController
        .create({
          duration: 1000,
          message: `${message?message:'입금 계좌가 클립보드에 복사되었습니다.'}`,
          position: 'middle',
        })
      return toast.present()
    }

    async function copyText(bankAccountInfo: string, message?: string) {
      const createInput = document.createElement('input')
      createInput.setAttribute('type', 'text')
      textDiv.value.append(createInput)
      createInput.value = bankAccountInfo
      createInput.select()

      const result = document.execCommand('copy')
      createInput.remove()

      if (result) {
        onSuccess(message)
      }
    }

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }
    const code = computed(() => getters['pointInfo/getInviteCode'])
    const link = computed(() => state.pointInfo.sharePointsLink)

    const onShare = () => {
      if (!link.value) {
        return
      }
      share({
        text: `엔젤리그 10,000P 적립 초대코드 ${code.value}`,
        title: '',
        url: link.value,
      })
    }

    const transactionPrice = (stake: any) => {
      return stake.type === '판매' ? Number(stake.totalPrice) - Number(stake.fee) - Number(stake.carriedInterest) : Number(stake.totalPrice) + Number(stake.fee)
    }
    const clickScreen = 'my'

    return {
      arrowDownOutline,
      branchResult,
      callWalletFunction,
      cancelToBuy,
      cancelToSell,
      clickScreen,
      confirmAlert,
      copyText,
      createModal,
      depositInfo,
      depositRequestInfo,
      depositRequestOpen,
      depositRequested,
      doRefresh,
      docusign,
      exit,
      formattedDate,
      getTokenValidDuration,
      groupChannels,
      handleScroll,
      isCreator,
      isLead,
      link,
      mypageNameHeader,
      onShare,
      openAlert,
      openChannelTalk,
      openFAQModal,
      openLink,
      openType,
      partnershipCollecting,
      partnershipComplete,
      point,
      pointBox,
      printTime,
      recommendList,
      router,
      shareTransactions,
      shareTransactionsDepositInfo,
      showNotificationButton,
      stBuyerDepositInfo,
      stSellerDepositInfo,
      stockOption,
      textDiv,
      totalAsset,
      transactionPrice,
      useClubDealProgressModal,
      user,
      viewKakaoKlipModal,
      walletBox,
      walletInfo,
      withdrawRequestInfo,
      withdrawRequestInfoExpirationAt,
      withdrawRequested,
    }
  },
})
