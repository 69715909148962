
import {defineComponent} from 'vue'

const image = {
  selectedStar: '/assets/icon/star_b.png',
  unselectedStar: '/assets/icon/star_g.png',
}

export default defineComponent({
  props: {
    value: Boolean,
  },
  setup() {
    return {
      image,
    }
  },
})
