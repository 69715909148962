import {RouteRecordRaw} from 'vue-router'

export const myPage: RouteRecordRaw[] = [
  {
    component: () => import('@/views/MyPage.vue'),
    name: 'MyPage',
    path: 'mypage',
  },
  {
    component: () => import('@/views/MyPage/MyPoint.vue'),
    name: 'MyPoint',
    path: 'mypage/mypoint',
  },
  {
    component: () => import('@/views/MyPage/MyAsset.vue'),
    name: 'MyAsset',
    path: 'mypage-myasset',
    props: (to) => {
      const {query = {}} = to
      const {from = '/'} = query
      return {
        fromUrl: from,
      }
    },
  },
  {
    component: () => import('@/views/MyPage/PartnershipDetail.vue'),
    name: 'PartnershipDetail',
    path: 'mypage/partnership-detail/:partnershipId',
    props: true,
  },
  {
    component: () => import('@/views/MyPage/StockOption.vue'),
    name: 'StockOption',
    path: 'mypage/stockoption',
    props: true,
  },
  {
    component: () => import('@/views/MyPage/SellStatus.vue'),
    name: 'SellStatus',
    path: 'mypage/sell-status',
  },
  {
    component: () => import('@/views/MyPage/ContractStatus.vue'),
    name: 'ContractStatus',
    path: 'mypage/contract-status',
  },
  {
    component: () => import('@/views/MyPage/DealHistory.vue'),
    name: 'DealHistory',
    path: 'mypage/deal-history',
  },
  {
    component: () => import('@/views/MyPage/wallet/index.vue'),
    name: 'Wallet',
    path: 'mypage/wallet',
    props: true,
  },
]
