import CallApi from '../utils/CallApi'

export default function() {
  return {
    getFAQ: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/common/templates/faq',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getIntro: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/common/templates/angelleague-introduction',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getProcess: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/common/templates/partnership-process',
        })
      } catch {
        // console.log(error);
      }
      return result
    },
  }
}
