import {styled, systems} from '@/plugins/emotion'

export const ModalWrapper = styled('div', {
  props: {
    css: null,
  },
  stylePortal: 'css',
  target: 'block-content',
})(
  {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  systems,
)
