const sm = '6px'
const md = '10px'
const full = '10000px'

export const radii = {
  full, md, sm,
}

export type Radii = typeof radii
export type RadiiType = keyof Radii | string | number
