
import {computed, defineComponent} from 'vue'
import {AgImg, AgBox, AgText, AgContent} from '@/components/ag'

export default defineComponent({
  components: {
    AgBox,
    AgContent,
    AgImg,
    AgText,
  },
  name: 'FailToConfirm',
})
