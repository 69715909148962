
import {defineComponent, reactive, computed} from 'vue'
import {useStore} from 'vuex'

import {IonRippleEffect, modalController} from '@ionic/vue'
import channelApi from '@/api/open-channel'
import {SendBirdAction} from '@/plugins/SendBirdAction'

export default defineComponent({
  components: {
    IonRippleEffect,
  },
  name: 'SellKeypad',
  props: {
    channel: null,
    channelMetaData: null,
    channelUrl: null,
    suggestType: null,
  },
  setup(props) {
    const {sendNewMessage} = channelApi()

    const reValue = reactive({
      channelUrl: computed(() => props.channelUrl),
      partnershipId: computed(() => props.channelMetaData.partnershipId),
      partnershipOpenChannelId: computed(() => props.channelMetaData.partnershipOpenChannelId),
      price: 0,
      suggestPrice: computed(() => props.channelMetaData.price),
      suggestType: computed(() => props.suggestType),
    })
    const currentChannel = computed(() => props.channel)

    const dismiss = async (data?: string) => {
      await modalController.dismiss(data)
    }

    async function sendRequest() {
      await sendNewMessage(reValue.partnershipId, reValue.partnershipOpenChannelId, reValue.suggestType, reValue.price)
        .then(async (message) => {
          const sba: any = await SendBirdAction.getInstance()
          const temporaryMessage = sba.sendUserMessage({
            channel: currentChannel.value,
            handler: (message: any, error: any) => {
              dismiss(message)
            },
            message: message.message,
            status: reValue.suggestType,
          })
        })
    }

    function addNumber(_value: any) {
      let value = _value
      value = value.toString()
      let result = reValue.price.toString()

      if (result.length >= 10) {
return result
}

      if (value === '0' && result === '0') {
return
}
      result += value

      result = result.slice(0, 10)
      reValue.price = Number(result)
    }

    function clearNumber() {
      let result = reValue.price.toString()

      if (result === '') {
return
}
      result = result.slice(0, - 1)

      reValue.price = Number(result)
    }

    return {addNumber, clearNumber, dismiss, reValue, sendRequest}
  },
})
