import {FunctionalComponent, h, ExtractPropTypes} from 'vue'
import {StyledSkeleton} from './StyledSkeleton'
import {SkeletonWrapper} from './SkeletonWrapper'

export const skeletonProps = {
  // empty
}

export type SkeletonProps = ExtractPropTypes<typeof skeletonProps>

export const Skeleton: FunctionalComponent<SkeletonProps> = (props, {slots}) => {
  return slots.default?.() ?? h(SkeletonWrapper, () => [
    h(StyledSkeleton),
  ])
}

Skeleton.props = skeletonProps
