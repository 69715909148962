
import {defineComponent, inject, onMounted, ref} from 'vue'
import {modalController, IonInput, toastController} from '@ionic/vue'
import apiSetting from '@/api/settings'

export default defineComponent({
  components: {
    IonInput,
  },
  name: 'AddressInput',
  setup() {
    const userAddress = ref<string>('')
    const jibunAddress = ref<string>('')
    const roadAddress = ref<string>('')
    const extraAddress = ref<string>('')
    const zipcode = ref<string>('')

    const dismiss = async () => {
      await modalController.dismiss()
    }

    const success = async () => {
      const toast = await toastController
        .create({
          duration: 1000,
          message: '',
          position: 'middle',
        })

      apiSetting().registAddress(userAddress.value, extraAddress.value, zipcode.value)
        .then((data) => {
          toast.message = data ? '주소가 성공적으로 변경되었습니다.' : '주소 변경에 실패하였습니다.'
          if (data) {
            toast.present()
            modalController.dismiss()
          } else {
            toast.present()
          }
        })
    }

    const daum: any = window.daum

    const execDaumPostcode = () => {
      const elementWrap: any = document.querySelector('#popup-wrap')
      if (elementWrap) {
        elementWrap.innerHTML = ''
      }
      const addressModal: any = document.querySelector('#modal-address')

      const themeObject = {
        bgColor: '#FFFFFF',
        // 우편번호 글자색
        emphTextColor: '#222222',

        // 강조 글자색
        outlineColor: '#FFFFFF',
        // 바탕 배경색
        postcodeTextColor: '#222222', // 테두리
      }

      // 현재 scroll 위치를 저장해놓는다. => 뭔가 수상
      const currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
      new daum.Postcode({
        height: '100%',

        oncomplete: function(data: any) {
          let address = '' // 도로명 주소 변수
          let jibunAddr = ''
          let roadAddr = ''
          let zonecode = '' // 우편 번호

          address = data.address
          zonecode = data.zonecode
          roadAddr = data.roadAddress === '' ? data.autoRoadAddress : data.roadAddress
          jibunAddr = data.jibunAddress === '' ? data.autoJibunAddress : data.jibunAddress

          userAddress.value = address
          zipcode.value = zonecode
          jibunAddress.value = jibunAddr
          roadAddress.value = roadAddr

          elementWrap.style.display = 'none'
          addressModal.style.display = 'none'

          document.body.scrollTop = currentScroll
        },

        theme: themeObject,
        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        // onresize : function(size: any) {
        //     elementWrap.style.height = size.height+'px';
        // }, => 이거 하면 스크롤 안생김
        width: '98%',
      }).embed(elementWrap)

      // iframe을 넣은 element를 보이게 한다.
      elementWrap.style.display = 'block'
      addressModal.style.display = 'block'
    }

    onMounted(() => {
      execDaumPostcode()
    })

    const clickScreen = 'input-address'

    return {
      clickScreen, 
      dismiss,
      execDaumPostcode,
      extraAddress,
      jibunAddress,
      roadAddress,
      success,
      userAddress,
    }
  },

})
