import CallApi from '../utils/CallApi'

export default function() {
  return {
    createStockItemBoards: async (stockItemId: any, boardData: any) => {
      let result: any = ''
      if (boardData.stockItemBoardId) {
        try {
          result = await CallApi({
            data: boardData,
            method: 'put',
            url: `/stock-items/boards/${stockItemId}/${boardData.stockItemBoardId}`,
          })
          result.type = 'update'
        } catch {
          // console.log(error);
        }
      } else {
        try {
          result = await CallApi({
            data: boardData,
            method: 'post',
            url: `/stock-items/boards/${stockItemId}`,
          })
          result.type = 'create'
        } catch {
          // console.log(error);
        }
      }
      console.log(result)

      return result
    },

    createStockItemSell: async (formData: any) => {
      let result: any = ''

      try {
        result = await CallApi({
          data: formData,
          headers: {'Content-Type': 'multipart/form-data'},
          method: 'post',
          url: '/stock-items/sell',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    deleteStockItemBoard: async (stockItemId: any, stockItemBoardId: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'delete',
          url: `/stock-items/boards/${stockItemId}/${stockItemBoardId}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    deleteStockItemSell: async (stockItemWantSellId: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'delete',
          url: `/stock-items/sell/${stockItemWantSellId}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPartnershipHistoriesByStockItemId: async (page: number, stockItemId: number) => {
      let result: any = []
      try {
        // // console.log({ headers: authHeader() });
        result = await CallApi({
          method: 'get',
          url: `/partnerships/histories-all?stockItemId=${stockItemId}&page=${page}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getStockItem: async (stockItemId: any) => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: `/stock-items/${stockItemId}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },
    getStockItemSell: async (stockItemId: any, stockItemWantSellId: any) => {
      let result: any = ''
      const url = `/stock-items/sell/${stockItemId}${stockItemWantSellId ? '/' + stockItemWantSellId : ''}`
      try {
        result = await CallApi({
          method: 'get',
          url: url,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    
    getStockItemSellFile: async (stockItemId: any, stockItemWantSellId: any) => {
      let result: any = ''
      const url = `/stock-items/sell/${stockItemId}/${stockItemWantSellId}/download`
      try {
        result = await CallApi({
          method: 'get',
          responseType: 'arraybuffer',
          url: url,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    
getStockItems: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/stock-items',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    
// 회사 찜
stockItemBoardLike: async (stockItemId: any, stockItemBoadId: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'post',
          url: `/stock-items/boards/${stockItemId}/like/${stockItemBoadId}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    

stockItemBoards: async (stockItemId: any, page: any) => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: `/stock-items/boards/${stockItemId}/page?page=${page}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    
stockItemBoardsPage: async (page: any) => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: `/stock-items/boards/page?page=${page}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    // 회사 찜
stockItemLike: async (stockItemId: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'post',
          url: `/stock-items/${stockItemId}/like`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    
    // 회사 검색시 로그
stockItemSearchLog: async (stockItemId: number) => {
      let result: any = ''
      try {
        result = await CallApi({
          data: {stockItemId: Number(stockItemId)},
          method: 'post',
          url: '/stock-items/search',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    
    // 인기 검색 회사 조회
stockItemsPopularity: async () => {
      let result: any = ''
      console.log('stockItemsPopularity')
      try {
        result = await CallApi({
          method: 'get',
          url: '/stock-items/search/popularity',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    
    updateStockItemSell: async (stockItemWantSellId: string, formData: any) => {
      let result: any = ''

      try {
        result = await CallApi({
          data: formData,
          headers: {'Content-Type': 'multipart/form-data'},
          method: 'put',
          url: `/stock-items/sell/${stockItemWantSellId}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

  }
}
