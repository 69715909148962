
import {AgBox, AgToggleIcon, AgImg, AgButton, AgText, AgContent, AgHeader} from '@/components/ag'
import {computed, defineComponent, onBeforeMount, ref} from 'vue'
import {requestSignToJoin} from '@/api/stake-deal'
import {IonRippleEffect, IonInput, modalController, IonIcon} from '@ionic/vue'
import {AgSignaturePad} from '@/components/ag/ag-signature-pad'
import {useStore} from 'vuex'
import {currency, openLinkWithAccessToken, splitDate} from '@/utils/filters'
import {API_URL} from '@/config'
import contract from '@/components/Modal/Contract.vue'
import {chevronForwardOutline} from 'ionicons/icons'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import {useGtm} from '@gtm-support/vue-gtm'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgHeader,
    AgImg,
    AgSignaturePad,
    AgText,
    AgToggleIcon,
    IonIcon,
    IonInput,
    IonRippleEffect,
  },
  emits: ['close', 'goToNext', 'update:modelValue'],
  setup(props, {emit}) {
    const gtm = useGtm()
    const {getters, commit, dispatch} = useStore()
    const BuyerName = ref<string>('')
    const BuyerNameValid = ref<boolean>(false)
    const isEmpty = ref<boolean>(true)
    const padToggle = ref<boolean>(true)
    const padClick = ref<boolean>(false)
    const readContract = ref<boolean>(false)
    const canvasRef = ref<any>(null)
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const purchaseInfo = computed(() => getters['stakeDeal/purchaseInfo'])

    const buyerSignId = computed(() => purchaseInfo.value.buyerSignId)
    const partnershipShareSellId = computed(() => dealInfo.value.partnershipShareSellId)
    const typoColor = computed(() => {
      if (BuyerName.value === purchaseInfo.value.buyerName) {
        return 'primary'
      } else if (BuyerName.value !== purchaseInfo.value.buyerName && BuyerName.value) {
        return 'red2'
      }
      return 'white'
    })

    const typoStatus = computed(() => {
      if (BuyerName.value === purchaseInfo.value.buyerName) {
        return '입력하신 성함이 일치합니다.'
      } else if (BuyerName.value !== purchaseInfo.value.buyerName && BuyerName.value) {
        return '성함을 정확히 입력해주세요.'
      }
      return ''
    })

    onBeforeMount(() => {
      dispatch('stakeDeal/GET_PURCHASE_INFO', dealInfo.value.partnershipShareSellId)

      gtm?.trackEvent({
        event: MODAL_OPEN_EVENT,
        page: `${clickScreen}`,
        title: '조합 가입확인서 서명',
      })
    })

    const clearSign = () => {
      isEmpty.value = true
      canvasRef.value = null
    }

    const signToJoin = async () => {
      const result = await requestSignToJoin(partnershipShareSellId.value, canvasRef.value, buyerSignId.value)
      console.log(result)
      if (result) {
        // todo 구매 결과를 받아서 바로 구매 완료 step으로
        await commit('stakeDeal/SET_EXPECTED_PURCHASE_INFO', result)
        await emit('goToNext', 'PURCHASE_REQUEST')
      }
    }

    const viewContract = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: contract,
          componentProps: {
            parent: clickScreen,
          },
          id: 'ContractModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      modal.onDidDismiss().then(() => {
        readContract.value = true
      })

      return modal.present()
    }

    const onChangeCanvas = (data: string) => {
      canvasRef.value = data
      padClick.value = true
    }

    const clickScreen = 'sign-to-join-partnership'

    return {
      BuyerName,
      BuyerNameValid,
      canvasRef,
      chevronForwardOutline,
      clearSign,
      clickScreen,
      currency,
      dealInfo,
      isEmpty,
      onChangeCanvas,
      padClick,
      padToggle,
      purchaseInfo,
      readContract,
      signToJoin,
      splitDate,
      typoColor,
      typoStatus,
      viewContract,
    }
  },
})
