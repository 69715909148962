import {useTheme, ColorsType} from '@/plugins/emotion'
import {CSSObject} from '@emotion/css'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'
import style from '@styled-system/css'

const defaultStyle: CSSObject = {
  borderStyle: 'solid',
  borderWidth: '1px',
}

export type FillColorsType = ColorsType | string

export const fillColorSystem = system({
  fillColor: (value: string, _, props) => {
    if (!value) {
      return {}
    }
    const invertColors: Record<string, any> = useTheme()?.invertColors ?? {}
    const color = invertColors?.[value] ?? 'unset'
    return style({
      backgroundColor: value,
      borderColor: value,
      color,
      ...defaultStyle,
    })(props)
  },
})

export const fillColorProp = getResponsiveProp<FillColorsType>()
