import {styled} from '@/plugins/emotion'

export const ProgressNumber = styled('div', {
  name: 'ProgressNumber',
})(
  {
    left: 0,
    position: 'absolute',
    top: 0,
  },
)
