import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-538daf05"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "star-rating" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratings, (rating) => {
      return (_openBlock(), _createElementBlock("label", {
        key: rating,
        class: _normalizeClass(["star-rating__star", {'is-selected': (_ctx.value != null && (_ctx.value >= rating)),
               'is-selected-half': (_ctx.value != null && (_ctx.value < rating && Math.ceil(_ctx.value) >= rating)),
               'is-disabled': _ctx.disabled}])
      }, null, 2))
    }), 128))
  ]))
}