import SendBird from 'sendbird'
import {uuid4} from '../utils/filters'

class SendBirdConnection {
  instance: any;
  sb: any;
  key: any;
  channel: any;
  onReconnectStarted: any;
  onReconnectSucceeded: any;
  onReconnectFailed: any;

  constructor() {
    if (this.instance) {
      return this.instance
    }

    this.sb = SendBird.getInstance()
    this.key = uuid4()
    this.channel = null
    this._createConnectionHandler(this.key)

    this.onReconnectStarted = null
    this.onReconnectSucceeded = null
    this.onReconnectFailed = null

    this.instance = this
  }

  _createConnectionHandler(key: any) {
    const handler = new this.sb.ConnectionHandler()
    handler.onReconnectStarted = () => {
      if (this.onReconnectStarted) {
        this.onReconnectStarted()
      }
    }
    handler.onReconnectSucceeded = () => {
      if (this.onReconnectSucceeded) {
        this.onReconnectSucceeded()
      }
    }
    handler.onReconnectFailed = () => {
      if (this.onReconnectFailed) {
        this.onReconnectFailed()
      }
    }
    this.sb.addConnectionHandler(key, handler)
  }

  remove() {
    this.sb.removeConnectionHandler(this.key)
  }

  static getInstance() {
    return new SendBirdConnection()
  }
}

export {SendBirdConnection}
