
import {IonPage, IonContent, modalController, IonRippleEffect, toastController} from '@ionic/vue'
import {defineComponent, computed, ref, onBeforeMount} from 'vue'
import {useStore} from 'vuex'
import AddressInput from '@/components/Modal/AddressInput.vue'
import AccountModal from '@/components/Modal/AccountModal.vue'
import AccountKeypadModal from '@/components/Modal/AccountKeypadModal.vue'
import PreAppInfoBox from '@/components/Modal/Home/PreAppInfoBox.vue'
import apiPreApplication from '@/api/pre-application'
const {postPreApplication} = apiPreApplication()

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonRippleEffect,
    PreAppInfoBox,
  },
  setup() {
    const {getters, dispatch} = useStore()

    const user = computed(() => getters['user/getUser'])
    const currentStatus = ref<string>('init')
    const statusKey: any = {
      apply: 'done',
      init: 'apply',
    }
    const inviteCode = ref<string>('')

    async function loadUserData() {
      const result = await dispatch('preApplication/ACT_SET_DATA')
      return result
    }

    const name = computed(() => getters['user/getName'])
    const userBankAccount = computed(() => getters['preApplication/getUserBankAccount'] || {})
    const TotalFreeDay = computed(() => getters['preApplication/getTotalFreeDay'] || null)
    const userStockBankAccount = computed(() => getters['preApplication/getUserStockBankAccount'] || {})
    const userInfo = computed(() => getters['preApplication/getUserData'] || {})
    const code = computed(() => userInfo.value.inviteCode || '')

    // onBeforeMount(() => {
    // })

    const scrollTop = async () => {
      const content: any = document.querySelector('#preApp__ion_content')
      content.scrollToPoint(0, 0)
    }

    function changeStatus() {
      if (TotalFreeDay.value) {
        currentStatus.value = 'done'
      } else {
        currentStatus.value = statusKey[currentStatus.value]
      }
      scrollTop()
    }

    function checkValidUser() {
      loadUserData()
        .then((data) => {
          if (data) {
            changeStatus()
          }
        })
    }

    function nextProcess() {
      if (currentStatus.value === 'init') {
        checkValidUser()
        return
      }
      changeStatus()
    }

    async function applyPreApplication() {
      const result = await postPreApplication(inviteCode.value)
      console.log('click!!!')
      if (result === 'done') {
        await dispatch('preApplication/ACT_SET_DATA')
        nextProcess()
      }
    }

    async function useSettingModal(name: string, accountType?: string, bankInfo?: any) {
      let modal: any
      if (name === 'address') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AddressInput,
            cssClass: 'address-modal',
            id: 'AddressInput',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(() => {
          dispatch('preApplication/ACT_SET_DATA')
          dispatch('user/ACT_SET_SETTING_INFO')
        })
      } else if (name === 'account') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AccountModal,
            componentProps: {
              accountType,
            },
            cssClass: 'address-modal',
            id: 'AccountModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then((data: any) => {
          if (data.data) {
            useSettingModal('accountKeypad', data.data.type, data.data.bankInfo)
          }
        })
      } else if (name === 'accountKeypad') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: AccountKeypadModal,
            componentProps: {
              accountType,
              bankInfo,
            },
            cssClass: 'address-modal',
            id: 'AccountKeypadModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then((data: any) => {
          if (data.data === 'replace') {
            useSettingModal('account', accountType)
          } else {
            dispatch('preApplication/ACT_SET_DATA')
            dispatch('user/ACT_SET_SETTING_INFO')
          }
        })
      }

      return modal.present()
    }

    function dismiss() {
      return modalController.dismiss()
    }

    async function presentToast(toastMessage: string) {
      const toast = await toastController
        .create({
          cssClass: 'toast-custom-alert',
          duration: 2000,
          message: toastMessage,
          position: 'middle',
        })
      return toast.present()
    }

    const onLinkSuccess = async () => {
      const toastMessage = '지금 엔젤리그 조합지분 거래 사전 신청하면 수수료 무료 혜택을 드려요 🎉\n\nhttps://angelleague.io/'
      presentToast(toastMessage)
    }

    const onCodeSuccess = async () => {
      const toastMessage = '초대코드 복사 완료.\n친구에게 공유하세요! 🙂'
      presentToast(toastMessage)
    }

    const onError = () => {
      console.log('error')
    }

    const textDiv = ref<any>(null)

    async function copyText(string: string, type: string) {
      const createInput = document.createElement('input')
      createInput.setAttribute('type', 'text')

      textDiv.value.append(createInput)
      createInput.value = string
      createInput.select()

      document.execCommand('copy')
      createInput.remove()

      if (type === 'link') {
onLinkSuccess()
} else if (type === 'code') {
onCodeSuccess()
}
    }

    return {TotalFreeDay, applyPreApplication, changeStatus, code, copyText, curStatus: currentStatus, dismiss, inviteCode, name, nextProcess, onCodeSuccess, onError, onLinkSuccess, scrollTop, textDiv, useSettingModal, user, userBankAccount, userInfo, userStockBankAccount}
  },
})
