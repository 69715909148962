import {defineComponent, h, onMounted, toRef, PropType, ref} from 'vue'
import {isPlatform} from '@/composables'
import {useRouter} from 'vue-router'

export const AppBlock = defineComponent({
  name: 'AppBlock',
  props: {
    message: {type: String},
    redirect: {type: String},
    targetPlatforms: {default: () => ([]), type: Array as PropType<string[]>},
  },
  setup(props) {
    const redirectRef = toRef(props, 'redirect')
    const targetPlatforms = toRef(props, 'targetPlatforms')
    const {replace} = useRouter()
    const resultRef = isPlatform(targetPlatforms)
    const isRedirect = ref(false)

    onMounted(() => {
      if (resultRef.value && redirectRef.value) {
        return replace(redirectRef.value)
      }
    })

    return () => {
      if (props.message && isRedirect.value) {
        return h('div', props.message)
      }
    }
  },
})
