import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'
import {Property} from 'csstype'

export const etcSystems = system({
  alignTracks: true,
  alignmentBaseline: true,
  all: true,
  appearance: true,
  aspectRatio: true,
  azimuth: true,
  backdropFilter: true,
  backfaceVisibility: true,
  blockOverflow: true,
  blockSize: true,
  boxAlign: true,
  boxShadow: true,
  boxSizing: true,
  breakAfter: true,
  colorAdjust: true,
  filter: true,
  gap: true,
  textOverflow: true,
})

export const etcProps = {
  alignTracks: getResponsiveProp<Property.AlignTracks>(),
  alignmentBaseline: getResponsiveProp<Property.AlignmentBaseline>(),
  all: getResponsiveProp<Property.All>(),
  appearance: getResponsiveProp<Property.Appearance>(),
  aspectRatio: getResponsiveProp<Property.AspectRatio>(),
  azimuth: getResponsiveProp<Property.Azimuth>(),
  backdropFilter: getResponsiveProp<Property.BackdropFilter>(),
  backfaceVisibility: getResponsiveProp<Property.BackfaceVisibility>(),
  blockOverflow: getResponsiveProp<Property.BlockOverflow>(),
  blockSize: getResponsiveProp<Property.BlockSize>(),
  boxAlign: getResponsiveProp<Property.BoxAlign>(),
  boxShadow: getResponsiveProp<Property.BoxShadow>(),
  boxSizing: getResponsiveProp<Property.BoxSizing>(),
  breakAfter: getResponsiveProp<Property.BreakAfter>(),
  colorAdjust: getResponsiveProp<Property.ColorAdjust>(),
  filter: getResponsiveProp<Property.Filter>(),
  gap: getResponsiveProp<Property.Gap>(),
  textOverflow: getResponsiveProp<Property.TextOverflow>(),
}
