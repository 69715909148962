import {defineComponent, h, Fragment, toRef, getCurrentInstance, onMounted} from 'vue'
import {AgButtonS, AgContentS, AgImg, AgFooterS, AgBoxS} from '@/components/ag'
import {useRouter} from 'vue-router'
import {isHttpLink} from '@/utils/is-http-link'
import {openLink} from '@/utils/filters'
export const Banner = defineComponent({
  emits: ['prohibitClose', 'close'],
  name: 'Banner',
  props: {
    img: {type: String},
    imgLink: {type: String},
  },
  setup(props, {emit}) {
    const app = getCurrentInstance()
    const $modalOpenEvent = app && app.appContext.config.globalProperties.$modalOpenEvent
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const imgLinkRef = toRef(props, 'imgLink')
    const router = useRouter()

    const goLink = () => {
      const link = imgLinkRef.value
      if (!link) {
        return
      }
      $clickTrackEvent({'clickArea':'본문', 'clickContent':link, clickScreen})
      if(isHttpLink(link)){
        openLink(link)
      }else{
        router.push(link)
      }
      emit('close')
    }
  
    onMounted(()=>{
      $modalOpenEvent({page: 'banner', title: '팝업배너'})
    })

    const prohibitClose = () => {
      emit('prohibitClose')
      $clickTrackEvent({'clickArea':'버튼', 'clickContent':'3일 보지 않기', clickScreen})
    }

    const close = () => {
      emit('close')
      $clickTrackEvent({'clickArea':'버튼', 'clickContent':'닫기', clickScreen})
    }

    const clickScreen = 'banner'
    
    return () => {
      const {img} = props
      return (
        h(Fragment, [
          h(AgContentS, () => [
            h(AgImg, {onClick: goLink, src: img, width: '100%'}),
          ]),
          h(AgFooterS, {}, () => [
            h(AgBoxS, {css: {dp: 'flex', gap: '6px', mb: 'safeAreaBottom', px: 20, py: 16}}, () => [
              h(AgButtonS, {css: {block: 'sm', color: 'gray3', flexBasis: '50%', grow: 1, outline: 'bg2'}, onClick: prohibitClose}, () => '3일간 보지 않기'),
              h(AgButtonS, {css: {block: 'sm', color: 'gray3', flexBasis: '50%', grow: 1, outline: 'bg2'}, onClick: close}, () => '닫기'),
            ]),
          ]),
        ])
      )
    }
  },
})
