import CallApi from '../utils/CallApi'

export default function() {
  const AUTH_PREFIX = '/user'
  return {
    checkNickNameDup: async (nickname: string) => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: `${AUTH_PREFIX}/dup-check-nickname?nickname=${nickname}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    info: () => {
      try {
        return CallApi({
          method: 'get',
          url: '/user',
        })
      } catch {
        // empty
      }
    },

    saveNicknameAPI: async (nickname: string) => {
      let result: any = []
      try {
        result = await CallApi({
          data: {
            nickname,
          },
          method: 'post',
          url: `${AUTH_PREFIX}/nickname`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },
  }
}
