import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "model-value": _ctx.modelValue,
    height: "auto",
    "safe-area": true,
    "swipe-to-close": true,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalPage.component), {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', false))),
        onNextStep: _ctx.goToNext
      }, null, 8, ["onNextStep"]))
    ]),
    _: 1
  }, 8, ["model-value"]))
}