
import bannerApi from '@/api/banner'
import {AgBoxS} from '@/components/ag'
import {isNative, notificationError, notificationToken} from '@/composables'
import {IonApp, IonContent, IonHeader, IonRouterOutlet, IonToast} from '@ionic/vue'
import {useMutation} from '@vue/apollo-composable'
import {gql} from 'graphql-tag'
import {computed, defineComponent, onBeforeMount, onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {
  Banner,
  BannerModal,
  DeepLink,
  Notification,
  NotificationAllowMessage,
  PcBanner,
  Splash,
  UpdateConfirm,
  useNotificationAllowMessage,
} from './components'

const {getBanner} = bannerApi()

const TOKEN_CHECK_INTERVAL = 60000

export interface BannerInfo {
  bannerId?: number
  imageUrl?: string
  link?: string
}

export default defineComponent({
  components: {
    AgBoxS,
    Banner,
    BannerModal,
    DeepLink,
    IonApp,
    IonContent,
    IonHeader,
    IonRouterOutlet,
    IonToast,
    Notification,
    NotificationAllowMessage,
    PcBanner,
    Splash,
    UpdateConfirm,
  },
  name: 'App',
  setup() {
    const {
      dispatch,
      getters,
    } = useStore()

    const route = useRoute()
    const token = computed(() => {
      return getters['user/getToken']
    })
    const notificationAllowMessage = useNotificationAllowMessage()

    const isLogin = computed(() => {
      return Boolean(token.value)
    })

    const {mutate} = useMutation(gql`
        mutation upsertToken($token: String!) {
            upsertUserAppPushToken(token: $token) {
                id
            }
        }
    `)

    // Valid token 감시
    setInterval(() => {
      dispatch('user/ACT_CALC_TOKEN_VALID_TIME')
    }, TOKEN_CHECK_INTERVAL)

    const isGlobalToast = computed(() => getters.isGlobalToast)
    const isIosPass = computed(() => process.env.VUE_APP_UI_MODE === 'ios-pass')
    const showBanner = computed(() => !/^\/link/u.test(route.path) && !isIosPass.value && notificationAllowMessage.endToShow)

    const setDefault = () => {
      dispatch('ACT_SET_GLOBAL_TOAST', {open: false})
    }

    const bannerRef = ref<undefined | BannerInfo | null>()
    const bannerOpenRef = computed(() => {
      return Boolean(bannerRef.value)
    })
    const closeBanner = () => {
      bannerRef.value = undefined
    }

    const channel = process.env.VUE_APP_LIVE_UPDATE_CHANNEL

    const native = isNative()

    const updateToken = (token: string) => {
      return mutate({token})
    }

    onMounted(async () => {
      bannerRef.value = await getBanner()
    })

    onBeforeMount(() => {
      const classList = document.querySelector('#app')?.classList
      if (!native && classList) {
        classList.add('pc')
      }
    })

    watch([isLogin, notificationToken], ([isLogin, token]) => {
      if (isLogin && token) {
        return updateToken(token)
      }
    }, {immediate: true})

    return {
      bannerOpen: bannerOpenRef,
      bannerRef,
      channel,
      closeBanner,
      iosPass: process.env.VUE_APP_UI_MODE === 'ios-pass',
      isGlobalToast,
      native,
      notificationError,
      notificationToken,
      setDefault,
      showBanner,
    }
  },
})
