import CallApi from '../utils/CallApi'

export default function() {
	return {
		getBanner: async () => {
			let result: any = []
			result = await CallApi({
				method: 'get',
				url: '/banners',
			})
			if (typeof result === 'object') {
				return result
			}
			return null
		},
	}
}
