import {defineComponent, h, getCurrentInstance, ref, toRefs, onMounted, computed, withDirectives} from 'vue'
import {AgBoxS, AgButtonS} from '@/components/ag'
import {nextAppUrl, allowDomain} from '@/environment'
import {Plugins} from '@capacitor/core'
import {cookieSoftStorage} from '@/utils/storage'
import {useStore} from 'vuex'
import {useShare, getPlatform, useInAppBrowser, trackDirective} from '@/composables'

export const NftMintBanner = defineComponent({
  name: 'NftMintBanner',
  props: {
    clickArea: {default: 'NFT', type: String},
    clickContent: {default: '내 작품 NFT 민팅 신청하기', type: String},
    clickScreen: {default: 'home', type: String},
    linkUrl: {default: '/pages/nft/register-files'},
  },
  render() {
    const {onOpenNft, $slots} = this

    if ($slots.default) {
      return h(AgBoxS, {onClick: onOpenNft}, () => $slots.default?.())
    }

    return (
      h(AgBoxS, () => [
        withDirectives(h(AgButtonS, {
          css: {bg: 'primary', block: 'md', color: 'white', corner: 'full', mb:30, width: '100%'},
          onClick: onOpenNft,
        }, () => [
          '내 작품 NFT 민팅 신청하기',
        ]), [[trackDirective, {clickArea: '민팅신청하기', clickContent: '민팅신청하기 버튼'}]]),
      ])
    )
  },
  setup(props) {
    const {clickContent, clickArea, clickScreen, linkUrl} = toRefs(props)
    const store = useStore()
    const token = computed(() => store.state.user.token)
    const userInfo = computed(() => {
      return store.getters['user/getUser']
    })
    const isCreator = computed(() => {
      return Boolean(userInfo.value?.creatorId)
    })

    const defaultUrl = computed(() => {
      return isCreator.value ? linkUrl.value : '/pages/nft/register-creator'
    })

    const nextUrl = ref()

    const app = getCurrentInstance()
    const $global = app && app.appContext.config.globalProperties.$global

    const share = useShare()

    const {Browser} = Plugins

    const getToken = () => {
      return localStorage.getItem('token')
    }

    const getUrl = (_nextUrl?: string) => {
      const nextUrl = _nextUrl ? _nextUrl : defaultUrl.value

      const platform = getPlatform()
      const _token = encodeURIComponent(token.value)
      return `${nextAppUrl()}${nextUrl}?platform=${platform}${_token ? `&token=${_token}`: ''}`
    }

    const inAppBrowser = useInAppBrowser(
      getUrl(),
      '_black',
      {
        presentationStyle: 'fullscreen',
        useWkWebView: true,
      },
    )

    const gaTrack = () => {
      if (!$global) {
        return
      }

      const {$clickTrackEvent} = $global

      // eslint-disable-next-line no-unused-expressions
      $clickTrackEvent?.({
        clickArea: clickArea.value, clickContent: clickContent.value, clickScreen: clickScreen.value,
      })
    }

    const setCookieToken = () => {
      const token = getToken()
      cookieSoftStorage.setItem('user', {token}, {domain: allowDomain(), sameSite: 'strict'})
    }

    const openNft = () => {
      const platform = getPlatform()
      const _nextUrl = nextUrl.value
      setCookieToken()
      nextUrl.value = undefined
      const url = getUrl(_nextUrl)
      if (platform === 'web') {
        location.href = url
        return
      }
      return inAppBrowser.open(url)
    }


    const updateUser = () => {
      try {
        return store.dispatch('user/updateUserInfo')
      } catch {
        // empty
      }
    }

    onMounted(() => {
      return updateUser()
    })

    const onOpenNft = async () => {
      gaTrack()
      if (!token.value) {
        $global.openLoginModal(() => {
          openNft()
        })
        return
      }

      return openNft()
    }

    const login = async () => {
      inAppBrowser.close()
      $global.openLoginModal(() => {
        openNft()
      })
    }

    inAppBrowser.onMessage((message) => {
      const {close, openLink, share: _share, login: _login, reopenTo} = message

      nextUrl.value = reopenTo

      if (close) {
        updateUser()
        inAppBrowser.close()
        return
      }
      if (openLink) {
        Browser.open({url: openLink})
        return
      }
      if (_share) {
        share(_share)
      }

      if (_login) {
        login()
      }
    })

    return {
      isCreator,
      onOpenNft,
      userInfo,
    }
  },
})
