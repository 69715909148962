const f12 = '12px'
const f13 = '13px'
const f14 = '14px'
const f15 = '15px'
const f16 = '16px'
const f17 = '17px'
const f18 = '18px'
const f20 = '20px'
const f22 = '22px'
const f24 = '24px'
const f26 = '26px'
const f30 = '30px'

export const fontSizes = {
  f12, f13, f14, f15, f16, f17, f18, f20, f22, f24, f26, f30,
}

export type FontSizes = typeof fontSizes

export type FontSizesType = keyof FontSizes | string | number
