
import {defineComponent} from 'vue'
import {LikeStar} from '.'
import {IonItem, IonLabel, IonAvatar} from '@ionic/vue'

const image = {
  defaultLogo: '/assets/icon/company_default_img.png',
  selectedStar: '/assets/icon/star_g.png',
  unselectedStar: '/assets/icon/star_b.png',
}

export default defineComponent({
  components: {
    IonAvatar,
    IonItem,
    IonLabel,
    LikeStar,
  },
  emits: ['select'],
  name: 'SearchStockItem',
  props: {
    id: Number,
    like: Boolean,
    logo: {default: image.defaultLogo, type: String},
    name: String,
  },
  setup() {
    return {
      image,
    }
  },
})
