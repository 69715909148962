import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'
import {Property} from 'csstype'

export const opacitySystems = system({
  opacity: {
    property: 'opacity',
    scale: 'opacities',
  },
})

export const opacityProps = {
  opacity: getResponsiveProp<Property.Opacity>(),
}
