import {ColorsType} from '@/plugins/emotion'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'
import {Property} from 'csstype'

export const backgroundSystem = system({
  background: true,
  backgroundAttachment: true,
  backgroundBlendMode: true,
  backgroundClip: true,
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
  },
  backgroundImage: true,
  backgroundOrigin: true,
  backgroundPosition: true,
  backgroundPositionX: true,
  backgroundPositionY: true,
  backgroundRepeat: true,
  backgroundSize: true,
  bg: {
    property: 'backgroundColor',
    scale: 'colors',
  },
})

export const backgroundColorProps = {
  background: getResponsiveProp<Property.Background>(),
  backgroundAttachment: getResponsiveProp<Property.BackgroundAttachment>(),
  backgroundBlendMode: getResponsiveProp<Property.BackgroundBlendMode>(),
  backgroundClip: getResponsiveProp<Property.BackgroundClip>(),
  backgroundColor: getResponsiveProp<ColorsType>(),
  backgroundImage: getResponsiveProp<Property.BackgroundImage>(),
  backgroundOrigin: getResponsiveProp<Property.BackgroundOrigin>(),
  backgroundPosition: getResponsiveProp<Property.BackgroundPosition>(),
  backgroundPositionX: getResponsiveProp<Property.BackgroundPositionX>(),
  backgroundPositionY: getResponsiveProp<Property.BackgroundPositionY>(),
  backgroundRepeat: getResponsiveProp<Property.BackgroundRepeat>(),
  backgroundSize: getResponsiveProp<Property.BackgroundSize>(),
  bg: getResponsiveProp<ColorsType>(),
}
