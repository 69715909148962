import {createEmotion, useTheme, CSSObject as CSSObject2} from '@winter-love/emotion'
import {theme} from './theme'
export * from './theme'
export * from './systems'

const emotion = createEmotion({
  key: 'css',
  theme,
})

export type CSSObject = CSSObject2

const {
  css,
  cx,
  styled,
  cache,
  flush,
  getRegisteredStyles,
  hydrate,
  injectGlobal,
  keyframes,
  merge,
  sheet,
} = emotion

export {
  useTheme,
  css,
  cx,
  styled,
  cache,
  flush,
  getRegisteredStyles,
  hydrate,
  injectGlobal,
  keyframes,
  merge,
  sheet,
}

export default emotion
