<template>
  <div class="gray_bg">
    <div class="gray_bg_title">
      꼭 읽어보세요
    </div>
    <div class="gray_bg_info_title">
      사전신청 이벤트 안내
    </div>
    <ul class="dash_list">
      <li>이벤트 기간 : 서비스 오픈 전까지 </li>
      <li>안전한 지분거래를 위해 필요한 <span class="bk_bold">본인 명의의 은행/증권계좌, 주소지 정보를 입력한 후 사전신청이 완료</span>됩니다. </li>
      <li>사전신청한 사용자에게는 엔젤리그웹 또는 카카오 알림톡으로 조합지분 거래 기능 오픈 안내 메세지가 발송되며, 기능 오픈 시점부터 30일간 수수료 무료 혜택을 받을 수 있습니다.</li>
    </ul>
    <div class="gray_bg_info_title">
      기타 유의사항
    </div>
    <ul class="dash_list">
      <li>서비스 오픈 전까지 사전신청이 승인되어야 수수료 혜택을 받으실 수 있어요.</li>
      <li>조합지분 거래는 설립 1년이 넘은 조합부터 순차적으로 가능하게 됩니다.</li>
      <li>본 이벤트는 국내거주 중인 만 19세 이상 내/외국인을 대상으로 하며, 만 19세 미만일 경우 엔젤리그 서비스를 사용할 수 없습니다. </li>
      <!--        <li>엔젤리그에서 지원하지 않는 OS 버전의 경우 추천을 받더라도 엔젤리그 서비스를 사용할 수 없습니다.-->
      <!--&lt;!&ndash;          (안드로이드 : 0.0 미만 버전, iOS : 0.0 미만 버전)&ndash;&gt;-->
      <!--        </li>-->
      <li>사전신청 취소는 마이페이지 > 설정 > 1:1문의로 신청하실 수 있습니다.</li>
      <li>본 이벤트에 명시된 날짜 및 시간은 한국시간 기준입니다.</li>
      <li>본 이벤트는 당사의 사정에 따라 사전 공지 없이 서비스 정책 및 내용이 변경되거나 종료될 수 있습니다.</li>
      <li>회원탈퇴 후 재가입 하는 경우, 무료 수수료 혜택은 소멸됩니다.</li>
      <li>이벤트 중 사용자가 핸드폰 번호를 변경한 경우, 기존 핸드폰 번호 기준으로 적용된 혜택이 새로운 핸드폰 번호에 적용되지 않습니다.</li>
      <li>사용자가 커뮤니티, SNS 등에 초대코드를 공유해 발생한 일에 대해 회사는 책임을 지지 않습니다.</li>
    </ul>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.gray_bg {
padding: 32px 20px 40px 20px;
background: #EEF1F7;
}

.gray_bg_title {
font: var(--title_22B);
margin-bottom: 16px;
}

.gray_bg_info_title {
font: var(--title_16B);
margin-bottom: 16px;
}

ul.dash_list {
list-style: none;
font-size: 16px;
line-height: 28px;
margin-bottom: 24px;
padding-left: 10px;
color: #5E6068;
}

ul.dash_list > li::marker {
content:"- ";
}

ul.dash_list > li {
margin-bottom: 14px;
}

.bk_bold {
color: #222;
font-weight: 700;
}
</style>
