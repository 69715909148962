/* eslint-disable prefer-named-capture-group */
import useModal from '@/composables/useModal'
import {Plugins} from '@capacitor/core'
import {toastController} from '@ionic/vue'
import {computed, watch} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {isNative} from '@/composables'

const sharePointsMatch = /^link\/share-points\//u
const companyMatch = /^link\/company\//u
const clubDealMatch = /^link\/club-deal\//u
const openClubDealMatch = /^link\/open-club-deal\//u
const clubDealsMatch = /^link\/club-deals($|\/)/u
const clubDealsToOpenMatch = /^link\/club-deals-to-open($|\/)/u
const communityMatch = /^link\/community($|\/)/u
const clubDealAllMatch = /^link\/club-deal-all/u
const shareSellToOpenMatch = /^link\/share-sell-to-open/u
const linkMatch = /^link/u
const requestMatch = /^link\/request/u

const {Browser} = Plugins

export const useDeepLinkReplace = () => {
  const {
    commit,
    state,
    dispatch,
  } = useStore()
  const router = useRouter()
  const userToken = computed(() => state.user.token)
  const userWaitingPoints = computed(() => state.pointInfo.waitingPoint)

  const updateCodePoint = async (value: string) => {
    if (value && userWaitingPoints.value) {
      const {code} = userWaitingPoints.value
      commit('pointInfo/setWaitingPoint', undefined)
      const {
        error,
        addedPoints,
      } = await dispatch('pointInfo/registerInviteCode', code)
      if (error) {
        const toast = await toastController.create({
          duration: 4000,
          message: `초대코드 ${code} ${error}`,
        })
        return toast.present()
      }
      const toast = await toastController.create({
        duration: 4000,
        message: `초대코드 ${code} ${addedPoints} 포인트 지급`,
      })
      return toast.present()
    }
  }

  const goHome = () => {
    return router.replace('/')
  }

  watch(userToken, updateCodePoint)

  const runSharePoints = async (code: string) => {
    commit('pointInfo/setWaitingPoint', {
      code,
    })

    updateCodePoint(userToken.value)
    return goHome()
  }

  const runCompany = async (id: string, tab?: string) => {

    const numberId = Number(id)

    if (!Number.isNaN(numberId)) {
      useCompanyModal(numberId, undefined, tab)
    }
    return goHome()
  }

  const runClubDeal = async (id: string) => {
    const numberId = Number(id)

    if (!Number.isNaN(numberId)) {
      return useClubDealProgressModal(numberId, 'company')
    }
    return goHome()
  }

  const runOpenClubDeal = async (id: string) => {
    const numberId = Number(id)

    if (!Number.isNaN(numberId)) {
      return useClubDealOpenModal(numberId, 'company')
    }
    return goHome()
  }

  const runClubDeals = async () => {
    await viewCollectClubdealsModal()
    return goHome()
  }

  const runClubDealsToOpen = async () => {
    await useCollectPartnerships()
    return goHome()
  }

  const runCommunity = async () => {
    return router.push('/tabs/community')
    
  }
  
  const runRequest = () => {
    if (isNative()) {
      Browser.open({url: 'https://angelleague.channel.io/'})
    } else {
      location.href = 'https://angelleague.channel.io/'
    }
    
    return goHome()
  }
  
  const runClubDealAll = () => {
    return router.replace({
      path: '/tabs/home',
      query: {
        isOpenClubDealAll: 'true',
      },
    })
  }
  
  const runShareSellToOpen = () => {
    return router.replace({
      path: '/tabs/home',
      query: {
        isOpenShareTransaction: 'true',
      },
    })
  }

  const tryRoute = async (link: string) => {
    return router.replace(`/${link}`)
  }

  const runByLink = (link: string) => {
    if (sharePointsMatch.test(link)) {
      return runSharePoints(link.split('link/share-points/')[1])
    }
    if (companyMatch.test(link)) {
      const [id, tab] = link.split('link/company/')[1].split('/')
      return runCompany(id, tab)
    }
    if (clubDealMatch.test(link)) {
      return runClubDeal(link.split('link/club-deal/')[1])
    }
    if (openClubDealMatch.test(link)) {
      return runOpenClubDeal(link.split('link/open-club-deal/')[1])
    }
    if (clubDealsMatch.test(link)) {
      return runClubDeals()
    }
    if (clubDealsToOpenMatch.test(link)) {
      return runClubDealsToOpen()
    }
    if (communityMatch.test(link)) {
      return runCommunity()
    }
    
    if (shareSellToOpenMatch.test(link)) {
      return runShareSellToOpen()
    }
    
    if (clubDealAllMatch.test(link)) {
      return runClubDealAll()
    }
    
    if(requestMatch.test(link)) {
      return runRequest()
    }
    
    if (linkMatch.test(link)) {
      return tryRoute(link.replace(/^link\//u, ''))
    }
    
    return tryRoute(link)
  }

  const {
    useCollectPartnerships,
    useCompanyModal,
    useClubDealProgressModal,
    useClubDealOpenModal,
    viewCollectClubdealsModal,
  } = useModal()

  return runByLink
}
