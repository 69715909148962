
import {defineComponent, reactive, computed} from 'vue'
import {modalController} from '@ionic/vue'
import channelApi from '@/api/open-channel'
import {SendBirdChatEvent} from '@/plugins/SendBirdChatEvent'
import {SendBirdAction} from '@/plugins/SendBirdAction'

export default defineComponent({
  components: {},
  name: 'SellAgreeInfo',
  props: {
    channel: null,
    
    
    channelMetaData: null,
    // partnershipID: null,
// channelID: null,
channelUrl: null,
    userId: null,
  },
  setup(props) {
    const {sendNewMessage} = channelApi()

    const reValue = reactive({
      channelUrl: computed(() => props.channelUrl),
      partnershipId: computed(() => props.channelMetaData.partnershipId),
      partnershipOpenChannelId: computed(() => props.channelMetaData.partnershipOpenChannelId),
      userId: computed(() => props.userId),
    })
    const currentChannel = computed(() => props.channel)

    async function sendRequest() {
      await sendNewMessage(reValue.partnershipId, reValue.partnershipOpenChannelId, 'approveToLeadAngel')
        .then(async (message) => {
          console.log(message)
          const sba: any = await SendBirdAction.getInstance()
          const temporaryMessage = sba.sendUserMessage({
            channel: currentChannel.value,
            handler: async (message: any, error: any) => {
              await modalController.dismiss(message)
            },
            message: message.message,
            status: 'approveToLeadAngel',
          })
        })
    }

    return {sendRequest}
  },
})
