import {useQuery} from '@vue/apollo-composable'
import {gql} from 'graphql-tag'
import {computed, defineComponent} from 'vue'
import {AgIcon} from '@/components/ag'
import {useRouter} from 'vue-router'
import {NotificationMark} from './NotificationMark'

export const Notification = defineComponent({
  components: {
    AgIcon,
    NotificationMark,
  },
  name: 'Notification',
  setup() {
    const router = useRouter()
    const onOpen = () => {
      return router.push({
        name: 'notification-list',
      })
    }
    const {result: notification} = useQuery(gql`
        query {
            userAppPushMessages {
                id
                readAt,
            }
        }
    `)

    const list = computed(() => {
      const list = notification.value?.userAppPushMessages ?? []
      return list.filter((value: any) => {
        return !value?.readAt
      })
    })

    const hasNotifications = computed(() => {
      return list.value.length > 0
    })

    return {
      hasNotifications,
      onOpen,
    }
  },
  template: `
    <notification-mark v-model="hasNotifications">
      <ag-icon
          name="notifications"
          :css="{width: 26, height: 26}"
          @click="onOpen"
      >
      </ag-icon>
    </notification-mark>
  `,
})


