import {ExtractPropTypes, FunctionalComponent, h} from 'vue'
import {StyledRowSection} from './StyledRowSection'

export const rowSectionProps = {
  rowAs: null,
}

export const RowSection: FunctionalComponent<ExtractPropTypes<typeof rowSectionProps>> =
  (props, {slots}) => {
  const {rowAs} = props
  const leftSlot = slots.left?.()
  const rightSlot = slots.right?.()
  const hasRow = Boolean(leftSlot || rightSlot)
  if (hasRow) {
    return (
      h(StyledRowSection, {as: rowAs}, () => [
        leftSlot,
        slots.default?.(),
        rightSlot,
      ])
    )
  }
  return slots.default?.()
}

RowSection.props = rowSectionProps
