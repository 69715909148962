
import {computed, defineComponent, reactive, ref, watch} from 'vue'
import {modalController, IonContent, alertController, IonFooter} from '@ionic/vue'
import {useStore} from 'vuex'
export default defineComponent({
  components: {
    IonContent,
    IonFooter,
  },
  name: 'BoardWriteModal',
  props: {
    partnershipBoard: null,
    partnershipId: null,
  },
  setup(props) {
    const {state, dispatch, commit, getters} = useStore()

    const focusToggle = ref(false)
    const buttonToggle = ref(false)
    const selectedPartnershipId = computed(() => props.partnershipId)

    const boardData = reactive({
      contents: props.partnershipBoard.contents || '',
      partnershipBoardId: props.partnershipBoard.partnershipBoardId || null,
      partnershipId: `${selectedPartnershipId.value}`,
    })

    if (boardData.contents) {
      focusToggle.value = true
    }

    const dismiss = () => {
      modalController.dismiss()
    }

    const onFocus = async () => {
      focusToggle.value = await true
      const tag: any = await document.querySelector('#input_content')
      await tag.focus()
    }

    const onBlur = () => {
      if (boardData.contents == null || boardData.contents === '') {
        focusToggle.value = false
      } else {
        focusToggle.value = true
      }
    }

    const handleContent = (e: any) => {
      if (e.target.value.length >= 400) {
        e.target.value = e.target.value.slice(0, 400)
        boardData.contents = e.target.value
      }
    }

    const contentLengthCheck = () => {
      if (boardData.contents.length >= 20) {
        return false
      } 
        return true
      
    }

    const OPINION_REGIST_TITLE = '의견 등록하기'

    const openAlert = async (title: string, message: string) => {
      const alert = await alertController
        .create({
          buttons: ['확인'],
          cssClass: 'lead-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              ${title}
            </div>
            <div class="message">
              ${message}
            </div>
          </div>
          `,
        })
      return alert.present()
    }

    const saveBoard = async () => {
      console.log(selectedPartnershipId.value)
      console.log(boardData)

      boardData.partnershipId = selectedPartnershipId.value
      if (contentLengthCheck()) {
        await openAlert(OPINION_REGIST_TITLE, '20자 이상 작성해주세요!')
        return
      }
      const ok = await dispatch('asset/ACT_SAVE_PARTNERSHIP_BOARDS', {
        boardData: boardData,
      })
      if (ok) {
        await dispatch('asset/ACT_SELECT_PARTNERSHIP_BOARD_LIST', selectedPartnershipId.value)
        await openAlert(OPINION_REGIST_TITLE, '저장되었습니다.')
        dismiss()
      }
    }

    return {
      boardData,
      btnToggle: buttonToggle,
      dismiss,
      focusToggle,
      handleContent,
      onBlur,
      onFocus,
      saveBoard,
    }
  },
})
