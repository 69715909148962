
import {defineComponent, computed, toRef, watch, onBeforeMount, ref} from 'vue'
import {AgModal, AgContent, AgHeader, AgBoxS, AgTextS, AgIcon, AgIntersectBox} from '@/components/ag'
import {useStore} from 'vuex'
import {prettyDateToOnemonth, perCurrency, getDate} from '@/utils/filters'
import {getFormattedDate} from '@/utils/filters'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import {numberToKorean} from '@/utils/filters'
import {useShare} from '@/composables'
export default defineComponent({
  components: {
    AgBoxS,
    AgContent,
    AgHeader,
    AgIcon,
    AgIntersectBox,
    AgModal,
    AgTextS,
  },
  emits: ['update:modelValue', 'companyClick', 'btnClick'],
  name: 'PopularShareTransactionModal',
  props: {
    modelValue: {type: Boolean},
  },
  setup(props, {emit}) {
    const gtm = useGtm()
    const {getters, dispatch} = useStore()
    const modelValueRef = toRef(props, 'modelValue')
    const setOpen = (value: boolean) => emit('update:modelValue', value)
    const user = computed(() => getters['user/getUser'])
    const popularShareTransaction = computed(() => getters['home/getPopularShareTransaction'])
    const stakeList = computed(() => popularShareTransaction.value.popularShareTransactions.concat( popularShareTransaction.value.popularShareTransactions))
    const updateDate = computed(() => popularShareTransaction.value.reflectedAt)
    const share = useShare()
    const formattedDate = (updateDate: any) => {
      return getFormattedDate(updateDate, 'MM.DD HH:mm')
    }

    const stakeListOpen = ref<boolean>(false)

    onBeforeMount(() => {
      dispatch('home/ACT_GET_POPULAR_SHARE_TRANSACTION')
    })

    const clickScreen = 'popular-share-transaction-modal'

    watch(modelValueRef, () => {
      if (modelValueRef.value) {
        dispatch('home/ACT_GET_POPULAR_SHARE_TRANSACTION')
        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: clickScreen,
          title: '인기 조합지분 거래 모달',
        })
      }
    })

    const onShare = () => {
      // bichi co
      const url = 'https://angelleague.io/link/share-sell-to-open/'
      const title = '조합원 자격 거래 열기'
      const text = '회사를 선택해서 조합원 자격 매물들을 확인하세요!'
      share({
        text,
        title,
        url,
      })
    }

    return {
      clickScreen,
      formattedDate,
      getDate,
      modelValueRef,
      numberToKorean,
      onShare,
      perCurrency,
      prettyDateToOnemonth,
      setOpen,
      stakeList,
      stakeListOpen,
      updateDate,
      user,
    }
  },
})
