import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_text = _resolveComponent("ag-text")!
  const _component_ag_img = _resolveComponent("ag-img")!
  const _component_ag_button = _resolveComponent("ag-button")!
  const _component_ag_content = _resolveComponent("ag-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_content, { "scroll-part": {justifyContent: 'center', alignItems: 'center', px: 20} }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_text, {
            typography: "tit20b",
            mb: "msd"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.text.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ag_text, {
            typography: "main16r",
            mb: "mld"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.text.description), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ag_img, {
            width: "151px",
            height: "260px",
            src: [
          $setup.image.troublePng,
          $setup.image.trouble2Png,
          $setup.image.trouble3Png,
        ]
          }, null, 8, ["src"]),
          _createVNode(_component_ag_button, {
            block: "md",
            corner: "full",
            outline: "primary",
            width: "100%",
            onClick: $setup.onRefresh
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.text.refresh), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}