import {AgImg, AgText} from '@/components/ag'
import {PropType, FunctionalComponent, h} from 'vue'
import {IonContent, IonPage, IonGrid, IonRow} from '@ionic/vue'
import contentPng from './content.png'
import content2Png from './content@2x.png'
import content3Png from './content@3x.png'
import {css} from '@/plugins/emotion'

export type AfterAction = 'refresh' | 'close'

const $container = css({
  backgroundColor: 'white',
  label: 'container',
})

const $row = css({
  display: 'flex',
  justifyContent: 'center',
})

const text = {
  message: '서버와의 통신이 원활하지않아\n테이터를 불어올 수 없습니다.',
  title: '네트워크 상태를 확인해주세요😢',
}

export interface OfflineProps {
  afterAction?: AfterAction
  image?: string | string[]
  message?: string
  title?: string
}

export const Offline: FunctionalComponent<OfflineProps> = (props) => {
  return (
    h(IonPage, () => [
      h(IonContent, {class: [$container]}, () => [
        h(IonGrid, () => [
          h(IonRow, () => [
            h(AgText, {mb: 'md', textAlign: 'center', typography: 'tit20b', width: '100%'}, () => props.title),
          ]),
          h(IonRow, () => [
            h(AgText, {mb: 'md', textAlign: 'center', typography: 'main16r', width: '100%'}, () => props.message),
          ]),
          h(IonRow, {class: [$row]}, () => [
            h(AgImg, {src: props.image}),
          ]),
        ]),
      ]),
    ])
  )
}

Offline.props = {
  afterAction: {default: 'close', type: String as PropType<AfterAction>},
  image: {default: [contentPng, content2Png, content3Png], type: [String, Array] as PropType<string | string[]>},
  message: {default: text.message, type: String},
  title: {default: text.title, type: String},
}
