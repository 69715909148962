export interface AppConfig {
  notificationFeature: boolean
}

const config: AppConfig = {
  notificationFeature: true,
}

export const useAppConfig = () => {
  return Object.freeze(config)
}
