import CallApi from '../utils/CallApi'

export default function() {
  const checkWithdrawal = async () => {
    let result: any = 0
    try {
      result = await CallApi({
        method: 'get',
        url: '/user/withdrawal/check',
      })
    } catch {
      // console.log(error);
    }

    return result
  }

  const getWithdrawalInfo = async () => {
    let list: any = ''
    try {
      list = await CallApi({
        method: 'get',
        url: '/user/withdrawal',
      })
    } catch {
      // console.log(error);
    }

    return list
  }

  const getSurveyItems = async () => {
    let list: any = []
    try {
      list = await CallApi({
        method: 'get',
        url: '/user/withdrawal/survey-items',
      })
    } catch {
      // console.log(error);
    }

    return list
  }
  const postWithdrawal = async (userWithdrawalSurveyItemId: any) => {
    let result: any = ''
    try {
      result = await CallApi({
        data: {
          userWithdrawalSurveyItemId,
        },
        method: 'post',
        url: '/user/withdrawal',
      })
    } catch {
      // console.log(error);
    }

    return result
  }

  return {
    checkWithdrawal,
    getSurveyItems,
    getWithdrawalInfo,
    postWithdrawal,
  }
}
