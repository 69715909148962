import {registerWebPlugin, WebPlugin} from '@capacitor/core'
import {injectScript} from '@/utils'

export interface BootOptions {
  /**
   * hide channel io button after boot
   * web only
   * @default false
   */
  hideChannelButtonOnBoot?: boolean
  hidePopup?: string
  memberHash?: string
  memberId?: string
  pluginKey: string
  trackDefaultEvent?: string
}

export interface BootResult {
  name: string
}

interface ChannelIoFunc {
  (kind: 'boot', options: BootOptions, callback: (error: any, user: BootResult) => any): void
  (kind: 'showChannelButton'): void
  (kind: 'hideChannelButton'): void
  (kind: 'openChat', chatId?: string, message?: string): void
}

const getWebChannelIo = (): ChannelIoFunc => {
  const ChannelIO = window.ChannelIO
  if (ChannelIO) {
    return ChannelIO
  }
  throw new Error('no channelIo')
}

export interface OpenChetOptions {
  chatId?: string
  message?: string
}

class WebChannelIo extends WebPlugin {
  constructor() {
    super({
      name: 'ChannelIoPlugin',
      platforms: ['web'],
    })
  }

  async boot(options: BootOptions): Promise<BootResult> {
    const {pluginKey, ...rest} = options
    injectScript(`
        (function() {
      var w = window;
      if (w.ChannelIO) {
        return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
      }
      var ch = function() {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function(args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        s.charset = 'UTF-8';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (document.readyState === 'complete') {
        l();
      } else if (window.attachEvent) {
        window.attachEvent('onload', l);
      } else {
        window.addEventListener('DOMContentLoaded', l, false);
        window.addEventListener('load', l, false);
      }
    })();
    `, {
      async: true,
    })
    const channelIo = getWebChannelIo()
    return new Promise((resolve, reject) => {
      channelIo('boot', {
        ...rest,
        hideChannelButtonOnBoot: true,
        pluginKey,
      }, (error: any, user: BootResult) => {
        if (error) {
          reject(new Error('boot error'))
          return
        }
        resolve(user)
      })
    })
  }

  hide() {
    const channelIo = getWebChannelIo()
    channelIo('showChannelButton')
    return Promise.resolve(true)
  }

  show() {
    const channelIo = getWebChannelIo()
    channelIo('hideChannelButton')
    return Promise.resolve(true)
  }
  
  openChat(options: OpenChetOptions = {}) {
    const {chatId, message} = options
    const channelIo = getWebChannelIo()
    channelIo('openChat', chatId, message)
  }
}

export const addWebChannelIo = () => {
  registerWebPlugin(new WebChannelIo())
}
