import {MayRef} from '@/types'
import {computed, ref, watch} from 'vue'

export type Src = string | string[]

export const useSrcset = (src?: MayRef<Src | undefined>, failBackSrc?: MayRef<string | undefined>) => {
  const srcRef = ref(src)
  const failBackSrcRef = ref(failBackSrc)
  const isErrorRef = ref(false)

  watch(srcRef, () => {
    isErrorRef.value = false
  })

  const originalSrcRef = computed(() => {
    if (Array.isArray(srcRef.value)) {
      return srcRef.value[0]
    }
    return srcRef.value
  })

  const srcsetRef = computed(() => {
    if (!Array.isArray(srcRef.value)) {
      return ''
    }
    const sizeList = [...srcRef.value]
    sizeList.shift()

    return sizeList.map((src, index) => {
      return `${src} ${index + 2}x`
    }).join(',')
  })

  const currentSrcRef = computed(() => {
    if (isErrorRef.value) {
      return failBackSrcRef.value
    }
    return originalSrcRef.value
  })

  return {
    isError: isErrorRef,
    src: currentSrcRef,
    srcset: srcsetRef,
  }
}
