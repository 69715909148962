import {defineComponent, PropType, toRefs} from 'vue'
import {AgIcon, AgBoxS} from '@/components/ag'
import {} from '@ionic/vue'
import {useRouter} from 'vue-router'

export interface LinkMeta {
  path: string
  text: string
}

export const NotificationItem = defineComponent({
  components: {
    AgBoxS,
    AgIcon,
  },
  emits: ['read'],
  name: 'NotificationItem',
  props: {
    id: {default: 0, type: Number},
    links: {default: () => ([]), type: Array as PropType<LinkMeta[]>},
    message: {default: '', type: String},
    read: {default: false, type: Boolean},
    title: {default: '', type: String},
  },
  setup(props, {emit}) {
    const {id} = toRefs(props)
    const router = useRouter()
    const onRead = () => {
      emit('read', id.value, true)
    }

    const onGoLink = (path: string) => {
      onRead()
      if (path.startsWith('http')) {
        location.href = path
        return
      }
      return router.push(path)
    }

    return {
      onGoLink,
      onRead,
    }
  },
  template: `
    <ag-box-s 
        @click="onRead"
      :css="{
        dp: 'flex',
        width: '100%',
        direction: 'column',
        px: 20,
        bg: read ? 'white' : 'bg2',
        py: 24,
        cursor: 'pointer',
      }"
    >
      <ag-box-s :css="{dp: 'flex', direction: 'row'}">
        <ag-box-s :css="{typography: 'h10', color: 'gray3', mr: 8}">
          {{title}}
        </ag-box-s>
        <ag-box-s v-if="!read" :css="{typography: 'h11', color: 'red1', opacity: 0.7}">
          new
        </ag-box-s>
      </ag-box-s>

      <ag-box-s :css="{typography: 'h7', color: 'font', whiteSpace: 'break-spaces'}">
        {{message}}
      </ag-box-s>
      <ag-box-s v-for="item in links">
        <ag-box-s @click="onGoLink(item.path)" :css="{typography: 'h9', color: 'blue1'}">{{item.text}}</ag-box-s>
      </ag-box-s>
    </ag-box-s>
  `,
})

