import io from 'socket.io-client'

export interface IO {
  (uri: string, options?: SocketIOClient.ConnectOpts): SocketIOClient.Socket
  (options?: SocketIOClient.ConnectOpts): SocketIOClient.Socket

  Manager: SocketIOClient.ManagerStatic

  Socket: SocketIOClient.Socket

  connect(options?: SocketIOClient.ConnectOpts): SocketIOClient.Socket

  connect(uri: string, options?: SocketIOClient.ConnectOpts): SocketIOClient.Socket

  managers: { [key: string]: SocketIOClient.Manager }

  protocol: number
}

export interface UseSocketReturn {
  io: IO
  socket: SocketIOClient.Socket
}

/**
 * socket-io 사용 임시
 */
export const useSocketIO = () => {
  const socket = io(process.env.VUE_APP_SOCKET_URL ?? 'https://auth.angelleague.io')

  return {io, socket}
}
