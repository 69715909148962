
import {AgModal} from '@/components/ag'
import {useBlur} from '@/composables'
import {IonContent, IonHeader, IonPage} from '@ionic/vue'
import {defineComponent, nextTick} from 'vue'
import {useRouter} from 'vue-router'
import {useLoadDeal, useRefresh, useSearchStock, useSelectStock, useStocks, useStorkId} from './composables'
import {
  StockDetail,
  SearchResults,
  SearchPopularList,
  SearchHotLinks,
  SearchInput,
  Refresher,
  SearchLatestList,
} from './components'

export default defineComponent({
  components: {
    AgModal,
    IonContent,
    IonHeader,
    IonPage,
    Refresher,
    SearchHotLinks,
    SearchInput,
    SearchLatestList,
    SearchPopularList,
    SearchResults,
    StockDetail,
  },
  name: 'Search',
  setup() {
    const router = useRouter()

    const storkId = useStorkId()
    const {stocks, popularStocks} = useStocks()
    const {selectedItem, onSelect} = useSelectStock(stocks)
    const {searchValue, searchedItems} = useSearchStock(stocks)

    const onClose = () => {
      router.replace('/tabs/search')
    }

    const onLoadDeal = useLoadDeal()

    const referch = useRefresh(() => onLoadDeal())

    const onRefresh = (event: any) => {
      referch().then(() => {
        event.target.complete()
      })
    }

    const onBlur = useBlur()

    const onSubmit = () => {
      onBlur()
    }

    // reload deal
    nextTick(() => onLoadDeal())

    return {
      onBlur,
      onClose,
      onRefresh,
      onSelect,
      onSubmit,
      popularStocks,
      searchValue,
      searchedItems,
      selectedItem,
      storkId,
    }
  },
})
