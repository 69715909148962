
import {AgBox, AgContent} from '@/components/ag'
import {computed, defineComponent, getCurrentInstance, onBeforeMount, ref, toRef} from 'vue'
import {IonSearchbar, IonInput} from '@ionic/vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    AgContent,
    IonInput,
    IonSearchbar,
    ModalHeader,
  },
  emits: ['goTo'],
  name: 'SearchStake',
  props:{
    parent: String,
  },
  setup(props, {emit}) {
    const app = getCurrentInstance()
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const {getters, dispatch, commit} = useStore()
    const stockName = ref<string>('')
    const myAllStakeList = computed(() => getters['stakeDeal/myAllStakeList'])
    const searchStakeList = ref<any>([])
    const companyInput = ref<string>('')
    const parentRef = toRef(props, 'parent')
    const clickScreen = parentRef.value
    onBeforeMount(async () => {
      await dispatch('stakeDeal/GET_MY_ALL_STAKELIST')
      searchStakeList.value = myAllStakeList.value
    })

    async function searchKeyword(event: any) {
      const query = event.target.value
      if (!query) {
        searchStakeList.value = myAllStakeList.value
      } else {
        companyInput.value = query
        $clickTrackEvent({'clickArea':'검색영역', 'clickContent':query, clickScreen})
        searchStakeList.value = myAllStakeList.value.filter((item: any) => ~item.stockItemName.indexOf(companyInput.value) || (item.codeNumber && ~item.codeNumber.indexOf(query)))
      }
    }

    const setCompany = async (stock: any) => {
      await dispatch('stakeDeal/GET_MY_STAKELIST', stock.stockItemId)
      await emit('goTo', 'SELECTED')
    }

    return {
      clickScreen,
      companyInput,
      myAllStakeList,
      searchKeyword,
      searchStakeList,
      setCompany,
      stockName,
    }
  },
})
