import {defineComponent, h, FunctionalComponent, ExtractPropTypes} from 'vue'
import {StyledIcon, iconProps} from './components'
import * as ionicIcons from 'ionicons/icons'
import homeTab from './assets/home-tab.pure.svg'
import searchTab from './assets/search-tab.pure.svg'
import growTab from './assets/grow-tab.pure.svg'
import talkTab from './assets/talk-tab.pure.svg'
import myPageTab from './assets/my-page-tab.pure.svg'
import angelLeaf from './assets/angel-leaf.pure.svg'
import agArtLogo from './assets/ag-art-logo.pure.svg'
import agNftLogo from './assets/ag-nft-logo.pure.svg'
import agDefaultLogo from './assets/ag-default-logo.pure.svg'
import {omit} from 'lodash'

const icons = {
  ...ionicIcons,
  agArtLogo,
  agDefaultLogo,
  agNftLogo,
  angelLeaf,
  growTab,
  homeTab,
  myPageTab,
  searchTab,
  talkTab,
}

export type IconNames = keyof typeof icons

export const agIconProps = {
  ...omit(iconProps, 'color'),
  name: {default: 'ellipse', type: String},
}

export type AgIconSProps = ExtractPropTypes<typeof agIconProps>

export const AgIconS: FunctionalComponent<AgIconSProps> = (props) => {
  const {name, ...rest} = props
  const icon = name && icons[name as IconNames]
  return (
    h(StyledIcon, {...rest, icon})
  )
  }

AgIconS.props = agIconProps
/**
 *
 */
export const AgIcon = defineComponent({
  name: 'AgIcon',
  props: {
    ...omit(iconProps, 'icon'),
  },
  setup(props) {
    return () => {
      const {
        name,
        ariaLabel,
        flipRtl,
        ios,
        lazy,
        md,
        mode,
        size,
        src,
        ...rest
      } = props
      return (
        h(AgIconS, {
          ariaLabel,
          css: rest,
          flipRtl,
          ios,
          lazy,
          md,
          mode,
          name: name as any,
          size,
          src,
        })
      )
    }
  },
})
