import CallApi from '../utils/CallApi'

export default function() {
  return {
    checkValidAsset: async (partnershipId: number, usePoint: number, realAsset: number) => {
      let result: any = ''

      try {
        result = await CallApi({
          data: {
            realAsset,
            usePoint,
          },
          method: 'post',
          url: `/partnerships/${partnershipId}/requests/valid-asset`,
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    delEalyBirdRequests: async (id: number) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'delete',
          url: `/earlybirds/requests/${id}`,
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    delPartnershipRequests: async (partnershipRequestId: number, partnershipId: number) => {
      let result: any = ''
      result = await CallApi({
        method: 'delete',
        url: `/partnerships/${partnershipId}/requests/${partnershipRequestId}`,
      })

      return result
    },

    getContract: async () => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'get',
          url: '/partnerships/contract',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    getExceptPartnerships: async (partnershipId: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'get',
          url: `/partnerships/recommend/${partnershipId}`,
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    getHistories: async (page: number) => {
      let result: any = []
      try {
        // // console.log({ headers: authHeader() });
        result = await CallApi({
          method: 'get',
          url: `/partnerships/histories?page=${page}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPartnerships: async () => {
      let result: any = []
      try {
        // // console.log({ headers: authHeader() });
        result = await CallApi({
          method: 'get',
          url: '/partnerships',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPartnershipsHomePrepares: async () => {
      let result: any = []
      try {
        // // console.log({ headers: authHeader() });
        result = await CallApi({
          method: 'get',
          url: '/partnerships/home-prepares',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPartnershipsOpen: async (partnershipId: number) => {
      let result: any = []
      result = await CallApi({
        method: 'get',
        url: `/partnerships/${partnershipId}/open`,
      })
      return result
    },

    getPartnershipsRequest: async (partnershipId: number) => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: `/partnerships/${partnershipId}/requests`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPartnershipsRequestBankInfo: async (partnershipId: number) => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: `/partnerships/${partnershipId}/requests/bank-info`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPartnershipsRequestOpenProcess: async (partnershipId: number) => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: `/partnerships/${partnershipId}/requests/open-process`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPartnershipsRequestProcess: async (partnershipId: number) => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: `/partnerships/${partnershipId}/requests/process`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    getPreparePartnerships: async () => {
      let result: any = []
      try {
        // // console.log({ headers: authHeader() });
        result = await CallApi({
          method: 'get',
          url: 'partnerships/prepares',
        })
      } catch {
        // console.log(error);
      }
      console.log(result)

      return result
    },

    getRecommendPartnerships: async () => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'get',
          url: '/partnerships/recommend',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    isCertifyEmail: async () => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'get',
          url: '/user/valid-email-certification',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    isValidAddress: async () => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'get',
          url: '/user/valid-address',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    isValidEmail: async () => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'get',
          url: '/user/valid-email',
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    isvalidPartnership: async (partnershipId: number) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'get',
          url: `/partnerships/${partnershipId}/requests/valid`,
        })
      } catch {
        // console.log(error);
      }

      return result
    },
    partnershipLike: async (partnershipId: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: 'post',
          url: `/partnerships/${partnershipId}/like`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    postPartnershipLike: async (api: any) => {
      let result: any = ''
      try {
        result = await CallApi({
          method: api.method,
          url: `/${api.path}`,
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    requestPartnership: async (_partnershipId: any, _usePoint: any, _realAsset: any) => {
      let partnershipId = _partnershipId
      let usePoint = _usePoint
      let realAsset = _realAsset
      let result: any = ''

      partnershipId = await Number(partnershipId)
      usePoint = await Number(usePoint)
      realAsset = await Number(realAsset)

      try {
        result = await CallApi({
          data: {
            realAsset,
            usePoint,
          },
          method: 'post',
          url: `/partnerships/${partnershipId}/requests`,
        })
      } catch {
        // console.log(error);
      }

      return result
    },

    requestPartnershipOpen: async (_partnershipId: any, _usePoint: any, _realAsset: any) => {
      let partnershipId = _partnershipId
      let usePoint = _usePoint
      let realAsset = _realAsset
      let result: any = ''

      partnershipId = await Number(partnershipId)
      usePoint = await Number(usePoint)
      realAsset = await Number(realAsset)

      try {
        result = await CallApi({
          data: {
            realAsset,
            usePoint,
          },
          method: 'post',
          url: `/partnerships/${partnershipId}/requests/open`,
        })
      } catch {
        // console.log(error);
      }

      return result
    },
  }
}
