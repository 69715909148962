
import {
  alertController,
  IonPage,
  IonContent,
  IonFooter,
  modalController,
  IonRippleEffect,
} from '@ionic/vue'
import {defineComponent, computed, ref, onBeforeMount, getCurrentInstance, onMounted} from 'vue'
import {useStore} from 'vuex'
// import SendBird from '@/plugins/SendBird.ts'
import {SendBirdChatEvent} from '@/plugins/SendBirdChatEvent'
import {SendBirdAction} from '@/plugins/SendBirdAction'
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router'

// get icons & component
import {chevronBack} from 'ionicons/icons'
import BuyKeypad from '@/components/Modal/BuyKeypad.vue'
import BuyMessage from '@/components/Chat/BuyMessage.vue'
import channelApi from '@/api/open-channel'
import partnershipApi from '@/api/partnership'
import {getFormatDate} from '@/utils/filters'
import useModal from '@/composables/useModal'
import useAlert from '@/composables/useAlert'

export default defineComponent({
  components: {
    BuyMessage,
    IonContent,
    IonFooter,
    IonPage,
    IonRippleEffect,
  },
  name: 'BuyChatModal',
  props: {
    channelUrl: null,
  },
  setup(props) {
    const app = getCurrentInstance()
    const $modalOpenEvent = app && app.appContext.config.globalProperties.$modalOpenEvent
    const {getters, dispatch} = useStore()
    const {sendNewMessage, markAsRead} = channelApi()
    const {getPartnershipsRequestBankInfo} = partnershipApi()

    const {useClubDealOpenJoinModal} = useModal()
    const {showBank} = useAlert()
    const router = useRouter()

    const user = computed(() => getters['user/getUser'])
    const userId = computed(() => user.value.userId.toString())
    const nickname = computed(() => user.value.nickname)

    const channelMetaData: any = ref(null)
    const messageMap = ref<any>(new Map())
    const unreadList = ref<any>([])
    const channelUrl = ref(props.channelUrl)

    const currentChannel = ref<any>(null)
    const lastMessageId = ref<any>(null)
    const element: any = computed(() => document.querySelector('#ionContent')) // null?

    const partnershipOpenChannelId = computed(() => channelMetaData.value ? channelMetaData.value.partnershipOpenChannelId : '')
    const channelStatus = computed(() => channelMetaData.value ? channelMetaData.value.status : '')
    const partnershipID = computed(() => channelMetaData.value ? channelMetaData.value.partnershipId : '')

    const LeadNickname = computed(() => {
      if (currentChannel.value) {
        return currentChannel.value.members
          .filter((a: any) => a.userId !== 'angelleague' && a.userId !== userId.value)
          .map((a: any) => a.nickname === 'null' ? '판매자' : a.nickname).toString()
      }
      return ''
    })

    // scroll setting
    async function moveToBottom() {
      // console.log('moveToBottom')
      if (channelUrl.value) {
        // curChannel.value.markAsRead();
        await markAsRead(channelUrl.value, userId.value)
        await dispatch('message/ACT_UNREAD_COUNT')
        setTimeout(() => {
          element.value.scrollToBottom()
        }, 200)
      }
    }

    const bankInfo = ref({})
    async function getBankInfo(partnershipId: any) {
      const info = await getPartnershipsRequestBankInfo(partnershipId)
      bankInfo.value = info
    }

    const getMessageList = async (groupChannel: any) => {
      const sba: any = await SendBirdAction.getInstance()
      const messages: any = await sba.getMessageList(groupChannel, true)

      messages.forEach((item: any) => {
        const key = getFormatDate(new Date(item.createdAt))

        item.unreadStatus = groupChannel.getUnreadMembers(item).length > 1
        if (item.unreadStatus === true) {
          unreadList.value.push(item.messageId)
        }

        if (messageMap.value.has(key)) {
          // messageid가 포함되어 있는지 확인 하고 push
          const messageId = item.messageId
          if (messageMap.value.get(key).find((m: any) => m.messageId !== messageId)) {
            messageMap.value.get(key).push(item)
          }
        } else {
          messageMap.value.set(key, [item])
        }
      })
      lastMessageId.value = groupChannel.lastMessage.messageId
      moveToBottom()
      groupChannel.getAllMetaData()
        .then((data: any) => {
          channelMetaData.value = data
          if (channelMetaData.value.status === 'approveToSeller') {
            getBankInfo(channelMetaData.value.partnershipId)
          }
        })
    }

    const getNextMessage = async (groupChannel: any, MessageId: number, type?: string) => {
//       const sba: any = await SendBirdAction.getInstance()
//       const messages: any = await sba.getMessagesByMessageId(groupChannel, MessageId, type)
//       let index = 0
//       messages.forEach((message: any) => {
//         const key = getFormatDate(new Date(message.createdAt))
//         message.unreadStatus = groupChannel.getUnreadMembers(message).length > 1
//         if (message.unreadStatus === true) {
//           unreadList.value.push(message.messageId)
//         }
//         if (type === 'update') {
//           const array = messageMap.value.get(key)
//           array[array.length - 1] = message
//         } else if (type === 'reset' && !message.unreadStatus) { // type이 reset이고 unreadStatus가 false이면?
//           const arrayIndex = messageMap.value.get(key).map((a: any) => a.messageId).lastIndexOf(unreadList.value[index])
//           messageMap.value.get(key)[arrayIndex] = message
//           index++
//         } else {
//           if (messageMap.value.has(key)) {
//             // messageid가 포함되어 있는지 확인 하고 push
//             const messageId = message.messageId
//             // console.log(messageId)
//             if (messageMap.value.get(key).find((m: any) => m.messageId !== messageId)) {
//               messageMap.value.get(key).push(message)
//             }
//           } else {
//             messageMap.value.set(key, [message])
//           }
//         }
//       })
//
//       if (type === 'reset') {
// unreadList.value = []
// }
//       lastMessageId.value = groupChannel.lastMessage.messageId
//       moveToBottom()

      // groupChannel.getAllMetaData()
      //   .then((data: any) => {
      //     channelMetaData.value = data
      //     if (channelMetaData.value.status === 'approveToSeller') {
      //       getBankInfo(channelMetaData.value.partnershipId)
      //     }
      //   })
    }

//     const sendBirdChatEvent = SendBirdChatEvent.getInstance()
//     sendBirdChatEvent.onMessageReceived = async (channel: any, message: any) => {
//       if (channel.url === channelUrl.value) {
//         await getNextMessage(channel, lastMessageId.value, 'receive')
//       }
//     }
//
//     sendBirdChatEvent.onReadReceiptUpdated = async (channel: any) => {
//       if (channel.url === channelUrl.value && unreadList.value[0]) {
//  await getNextMessage(channel, unreadList.value[0], 'reset')
// }
//     }

    async function init() {
      if (channelUrl.value) {
        const sendbirdAction: any = await SendBirdAction.getInstance()
        sendbirdAction
          .getChannel(channelUrl.value, false)
          .then((channel: any) => {
            getMessageList(channel)
            currentChannel.value = channel
          })
          .catch((error: any) => {
            console.error(error)
          })
      }
    }

    onBeforeMount(async () => {
      await init()
      moveToBottom()
    })

    const clickScreen = 'negotiation-buy'
    onMounted(()=>{
      $modalOpenEvent({page: clickScreen, title: '클럽딜 열기 구매자 협의창'})
    })

    function resetRefs() {
      channelMetaData.value = null
      messageMap.value = new Map()
      unreadList.value = []
      channelUrl.value = ''
    }

    async function cancelToSeller() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              handler: () => {
              // console.log('Cancel clicked');
              },
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                await sendNewMessage(Number.parseInt(partnershipID.value), partnershipOpenChannelId.value, 'cancelToSeller')
                  .then(async (message) => {
                    const sba: any = await SendBirdAction.getInstance()
                    const temporaryMessage = sba.sendUserMessage({
                      channel: currentChannel.value,
                      handler: (message: any, error: any) => {
                        getNextMessage(currentChannel.value, lastMessageId.value, 'send')
                      },
                      message: message.message,
                      status: 'cancelToSeller',
                    })
                  })
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups adderss-alert-class',
          message: `
      <div class="alert_css">
        <div class="header">
          판매자와의 협의를<br/>
          취소하시겠어요?
        </div>
        <div class="message">
          판매자에게 정중한 협의 취소 메시지를<br/>
          전송한 후, 협의를 종료합니다.
        </div>
      </div>`,
        })

      return alert.present()
    }

    const suggestToSeller = async (type: string) => {
      let modal: any = null
      if (type === 'reRequest') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: BuyKeypad,
            componentProps: {
              channel: currentChannel.value,
              channelMetaData: channelMetaData.value,
              channelUrl: channelUrl.value,
              messageID: lastMessageId.value,
              suggestType: type,
            },
            cssClass: 'buy-keypad-modal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(async (data: any) => {
          if (data.data) {
            getNextMessage(currentChannel.value, lastMessageId.value, 'update')
          }
        })
      } else {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: BuyKeypad,
            componentProps: {
              channel: currentChannel.value,

              channelMetaData: channelMetaData.value,
              // sellPrice : partnershipInfo.value.price,
              // partnershipID,
              // partnershipOpenChannelId: partnershipOpenChannelId.value,
              channelUrl: channelUrl.value,
              suggestType: type,
            },
            cssClass: 'buy-keypad-modal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(async (data: any) => {
          if (data) {
            getNextMessage(currentChannel.value, lastMessageId.value, 'send')
          }
        })
      }

      return modal.present()
    }

    // todo 클럽딜 열고 나서 상태 변경 하도록 수정
    async function openClubDeal() {
      await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', channelMetaData.value.partnershipId)
      const modal = await useClubDealOpenJoinModal(Number(channelMetaData.value.partnershipId), channelUrl.value, partnershipOpenChannelId.value)
      modal.onDidDismiss().then(async (data: any) => {
        getNextMessage(currentChannel.value, lastMessageId.value, 'update')
      })
    }

    function printDate(createdAt: string) {
      const date = new Date(createdAt)
      const days = ['일', '월', '화', '수', '목', '금', '토']

      return getFormatDate(date).slice(2).replace(/-/gi, '. ') + ' ' + days[date.getDay()] + '요일'
    }

    async function closeModal() {
      await modalController.dismiss()
      resetRefs()
    }
    return {
      LeadNickname,
      bankInfo,
      cancelToSeller,
      channelStatus,
      chevronBack,
      clickScreen,
      closeModal,
      curChannel: currentChannel,
      messageMap,
      openClubDeal,
      printDate,
      router,
      showBank,
      status,
      suggestToSeller,
      userId,
    }
  },
})

