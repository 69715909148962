
import {defineComponent, watch, reactive, computed} from 'vue'
import {modalController, alertController} from '@ionic/vue'
import SendBird from '@/plugins/SendBird'
import channelApi from '@/api/open-channel'

export default defineComponent({
  name: 'SuggestModal',
  props: {
    channelMetaData: null,
    // partnershipID: null,
    // channelID: null,
    channelUrl: null,
    userId: null,
  },
  setup(props) {
    const {sendNewMessage} = channelApi()

    const dismiss = async (data?: string) => {
      await modalController.dismiss(data)
    }

    const reValue = reactive({
      channelId: computed(() => props.channelMetaData.channelId),
      channelUrl: computed(() => props.channelUrl),
      partnershipId: computed(() => props.channelMetaData.partnershipId),
      userId: computed(() => props.userId),
    })

    async function cancelToLeadAngel() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              handler: () => {
                // console.log('Cancel clicked');
              },
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                await sendNewMessage(Number.parseInt(reValue.partnershipId), reValue.channelId, 'cancelToLeadAngel')
                  .then((message) => {
                    const result = SendBird.groupChannelSendMessage(
                      reValue.channelUrl, message.message, 'cancelToLeadAngel',
                    )
                    return result
                  }).then((data) => {
                    if (data) {
 dismiss('cancelToLeadAngel')
}
                  })
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups adderss-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              리드엔젤과 협의를<br/>
              취소하시겠어요?
            </div>
            <div class="message">
              리드엔젤에게 정중한 협의 취소 메시지를<br/>
              전송한 후, 협의를 종료합니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    return {cancelToLeadAngel, dismiss}
  },
})
