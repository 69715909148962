
import {defineComponent, computed, onBeforeMount, ref, watch} from 'vue'
import {IonRippleEffect, popoverController, modalController} from '@ionic/vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'
import {prettyDateToOnemonth, printContent, openLink} from '@/utils/filters'
import PrintOgData from '@/components/Board/PrintOgData.vue'

import PartnershipPopover from './PartnershipPopover.vue'

export default defineComponent({
  components: {IonRippleEffect, PrintOgData},
  name: 'PartnershipBoardList',
  props: {
    partnershipId: null,
  },
  setup(props) {
    const selectedStockItemId = computed(() => props.partnershipId)
    const {getters, dispatch, commit} = useStore()
    const user = computed(() => getters['user/getUser'])
    const page = ref(0)
    const loadBoard = () => {
      if (selectedStockItemId.value) {
        page.value = 0
        dispatch('asset/ACT_SELECT_PARTNERSHIP_BOARD_LIST', selectedStockItemId.value)
      // page:++page.value, type:'initAndSet'}
      }
    }
    const partnershipBoardList = computed(() => getters['asset/getPartnershipBoardList'])

    const loadBoardMore = () => {
      dispatch('asset/ACT_SELECT_PARTNERSHIP', {partnershipId: selectedStockItemId.value})
      // dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS', { stockItemId : props.stockItemId, page:++page.value})
    }

    onBeforeMount(async () => {
      if (props.partnershipId) {
        await loadBoard()
      }
    })

    const partnershipBoards = computed(() => getters['asset/getPartnershipBoardList'])
    // const partnershipBoardsCount = computed(() => getters[`stockItem/getpartnershipBoardsCount`])

    const hasMore = computed(() => getters['stockItem/getStockItemBoardsHasmore'])

    watch(selectedStockItemId, () => {
      loadBoard()
    })

    function boardLike(board: any) {
      dispatch('asset/ACT_LIKE_PARTNERSHIP_BOARD', {
        partnershipBoardId: board.partnershipBoardId, partnershipId: selectedStockItemId.value,
      })
    }

    function boardClick(data: any, event: any) {
      if (event.target.tagName === 'A') {
        openLink(event.target.dataset.href)
      }
    }

    const openPopover = async (partnershipBoard: any = null, event_: Event) => {
      const popover = await popoverController
        .create({
          component: PartnershipPopover,
          componentProps: {
            partnershipBoard,
            partnershipId: selectedStockItemId.value.toString(),
          },
          
          cssClass: 'more-vertical',
          // cssClass: 'my-custom-class',
event: event_,
          translucent: true,
        })
      return popover.present()
    }

    return {
      
boardClick,
      
// partnershipBoardsCount,
boardLike,
      
hasMore,
      
      loadBoard,
      loadBoardMore,
      openLink,
      openPopover,
      partnershipBoards,
      prettyDateToOnemonth,
      printContent,
      selectedStockItemId,
      user,
    }
  },
})
