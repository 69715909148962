import {AgBoxS, AgContent, AgIconS} from '@/components/ag'
import {IonPage} from '@ionic/vue'
import {useQuery, useMutation} from '@vue/apollo-composable'
import {gql} from 'graphql-tag'
import {computed, defineComponent, ref, watch} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {NotificationItem} from './NotificationItem'

const safeDataParser = (data: string) => {
  try {
    return JSON.parse(data)
  } catch {
    return {}
  }
}

export const NotificationList = defineComponent({
  components: {
    AgBoxS,
    AgContent,
    AgIconS,
    IonPage,
    NotificationItem,
  },
  name: 'NotificationList',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const thisRouteName = route.name
    const message = 'message\nmessage2'
    const links = ref([
      {
        path: '/tabs/home',
        text: 'home',
      },
    ])
    const {result: notification, refetch} = useQuery(gql`
      query {
        userAppPushMessages {
          id
          readAt
          title
          data
        }
      }
    `, undefined, {
      fetchPolicy: 'network-only',
    })

    watch(route, (value) => {
      if (value.name === thisRouteName) {
        refetch()
      }
    })

    const {mutate: readNotification} = useMutation(gql`
        mutation ($id: Float!) {
            readUserAppPushMessage(id: $id) {
                id
            }
        }
    `)

    const list = computed(() => {
      const rawList = notification.value?.userAppPushMessages ?? []
      return rawList.map(({title, data, readAt, id}: any) => {
        const {links, ...restData} = safeDataParser(data)
        return {
          ...restData,
          id,
          links: safeDataParser(links),
          read: Boolean(readAt),
          title,
        }
      })
    })

    const hasList = computed(() => {
      return list.value.length > 0
    })

    const listTest = ref([
      {
        id: 0,
        links: [
          {
            path: '/tabs/home',
            text: 'home',
          },
        ],
        message: 'message1',
        read: true,
        title: 'title',
      },
      {
        id: 1,
        links: [
          {
            path: '/tabs/home',
            text: 'home',
          },
        ],
        message: 'message2',
        read: false,
        title: 'title',
      },
    ])

    const onRead = async (id: string, value: boolean) => {
      if (!value) {
        return
      }
      await readNotification({
        id,
      })
      return refetch()
    }

    const onBack = () => {
      return router.push('/tabs/mypage/')
    }

    return {
      hasList,
      links,
      list,
      listTest,
      message,
      onBack,
      onRead,
    }
  },
  template: `
    <ion-page>
    <ag-box-s
      @click="onBack"
      :css="{height: 67, width: '100%', pt: 'safeAreaTop', dp: 'flex', alignItems: 'center', px: 20, bg: 'white'}"
    >
      <ag-icon-s
        name="chevronBack"
        :css="{width: 24, height: 24, mr: 16}"
      />
      <ag-box-s :css="{typography: 'h5'}">
        알림
      </ag-box-s>
    </ag-box-s>

    <ag-content :css="{bg: 'white'}">
      <ag-box-s v-if="!hasList" :css="{typography: 'h5', color: 'font', width: '100%', textAlign: 'center'}">
        알림이 비었습니다
      </ag-box-s>
      <notification-item
        v-for="item in list"
        v-bind="item"
        @read="onRead"
      />
    </ag-content>
    </ion-page>
  `,
})

export default NotificationList
