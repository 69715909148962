
import {IonPage, IonContent} from '@ionic/vue'
import {defineComponent} from 'vue'
import stock from '../../data/stockDetail.json'
import {useRouter} from 'vue-router'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
  },
  name: 'SellStatus',
  setup() {
    const router = useRouter()
    const dealList = stock.stock[0].deal

    return {dealList, router}
  },
})
