export interface ScriptOptions {
  async?: boolean
}

export const createScriptElement = (options: ScriptOptions = {}): HTMLScriptElement => {
  const {async = false} = options
  const scriptElement = document.createElement('script')
  scriptElement.async = async
  scriptElement.type = 'text/javascript'

  return scriptElement
}

export const appendChild = (target: HTMLElement, node: Node) => {
  const appendScript = () => {
    target.append(node)
    window.removeEventListener('load', appendScript)
  }
  if (document.readyState === 'complete') {
    appendScript()
  } else {
    window.addEventListener('load', appendScript, false)
  }
}

export const injectScriptSrc = (src: string, options: ScriptOptions = {}) => {
  const scriptElement = createScriptElement(options)
  scriptElement.src = src
  document.body.append(scriptElement)
}

export const injectScript = (script: string, options: ScriptOptions = {}) => {
  const scriptElement = createScriptElement(options)
  scriptElement.innerHTML = script
  appendChild(document.body, scriptElement)
}
