
import {AgBox, AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {computed, defineAsyncComponent, defineComponent, ref, toRef, PropType, watch} from 'vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

type OpenType = 'edit' | 'selected' | 'stakeSelected' | 'create'

const modalPages = {
  INIT: defineAsyncComponent(() => import('./component/search-my-stake/SearchStake.vue')),
  INPUT_SELL_INFO: defineAsyncComponent(() => import('./component/input-sell-info/InputSellInfoPage.vue')),
  SELECTED: defineAsyncComponent(() => import('./component/partnership-stake-list/MyPartnershipStakeList.vue')),
}
export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    ModalHeader,
  },
  emits: ['close', 'closeWithResult', 'update:openType'],
  name: 'RegistSellStakeModal',
  props: {
    isOpen: {type: Boolean},
    openType: {default: 'create', type: String as PropType<OpenType>},
  },
  setup(props, {emit}) {
    const gtm = useGtm()
    const openTypeRef = toRef(props, 'openType')
    const isOpenRef = toRef(props, 'isOpen')
    const stepRef = ref<ModalPageName | undefined>()

    const clickScreen = 'regist-partnership-share-sell-modal'
    watch(isOpenRef, ()=>{
      if (isOpenRef.value){
        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: clickScreen,
          title: '조합지분 팔기 모달',
        })
      }
    })


    const initModalPage = computed(() => {
      switch (openTypeRef.value) {
        case 'create':
          return modalPages.INIT
        case 'edit':
          return modalPages.INPUT_SELL_INFO
        case 'selected':
          return modalPages.SELECTED
        case 'stakeSelected':
          return modalPages.INPUT_SELL_INFO
        default:
          return modalPages.INIT
      }
    })

    const modalPage = computed(() => {
      if (stepRef.value) {
        return modalPages[stepRef.value]
      }
      return initModalPage.value
    })

    const setOpen = (value: boolean) => {
      if (!value) {
        stepRef.value = undefined
      }
      emit('close', value)
    }

    const goToModal = (step?: ModalPageName) => {
      console.log(step)
      stepRef.value = step
    }

    return {
      clickScreen, goToModal, modalPage, setOpen, 
    }
  },
})
