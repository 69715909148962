import {MayRef} from '@/types'
import {Plugins} from '@capacitor/core'
import {toastController, useBackButton, useIonRouter} from '@ionic/vue'
import {onBeforeUnmount, ref} from 'vue'
import {useRouter} from 'vue-router'
import {wrapRef} from '@winter-love/use'

const {App} = Plugins

const MAXIMUM_PRIORITY = 99999

export const useAndroidBackButtonExit = (message: MayRef<string> = '"뒤로" 한번 더하시면 종료됩니다') => {
  const readyToExitRef = ref(false)
  const messageRef = wrapRef(message)
  const {canGoBack} = useIonRouter()
  const isBackRef = ref(false)
  const {beforeEach} = useRouter()
  beforeEach(() => {
    isBackRef.value = false
  })

  const onToast = async () => {
    const toast = await toastController.create({
      duration: 1000,
      message: messageRef.value,
      position: 'top',
    })
    toast.onDidDismiss().then(() => {
      readyToExitRef.value = false
    })

    await toast.present()

    readyToExitRef.value = true
  }

  const {unregister} = useBackButton(MAXIMUM_PRIORITY, (processNextHandler) => {
    if (readyToExitRef.value) {
      App.exitApp()
      return
    }
    if (canGoBack()) {
      return processNextHandler()
    }
    isBackRef.value = true
    processNextHandler()
    setTimeout(() => {
      if (isBackRef.value) {
        onToast()
      }
      isBackRef.value = false
    }, 150)
  })

  onBeforeUnmount(unregister)

  return {
    isBack: isBackRef,
    readyToExit: readyToExitRef,
  }
}
