
import {defineComponent} from 'vue'
import {IonRefresher, IonRefresherContent} from '@ionic/vue'
import {arrowDownOutline} from 'ionicons/icons'
export default defineComponent({
  components: {
    IonRefresher,
    IonRefresherContent,
  },
  emits: ['refresh'],
  setup() {
    return {
      arrowDownOutline,
    }
  },
})
