
// get Components & Module
import {defineComponent, reactive, onBeforeMount, computed, watch, ref} from 'vue'
import {
  IonPage,
  modalController,
  IonContent,
  IonRefresher, IonRefresherContent, popoverController, IonRippleEffect,
} from '@ionic/vue'
import {useStore} from 'vuex'
import {useRoute, onBeforeRouteLeave, useRouter} from 'vue-router'
import DetailModal from '@/components/Modal/DetailModal.vue'
import BoardWriteModal from '@/components/Modal/BoardWriteModal.vue'
import {set} from '@/composables/useDealStorage'
import * as _ from 'lodash'
import {AgRefresher} from '@/components/ag'
// get Icons
import {searchOutline, arrowDownOutline} from 'ionicons/icons'
import * as leaf from '../../public/assets/icon/leaf.png'
import * as vector from '../../public/assets/icon/Vector.png'
import * as help from '../../public/assets/icon/help-circle.png'
import * as thun from '../../public/assets/icon/zap.png'
import SellItemModal from '@/components/Modal/SellItemModal.vue'
import SellItemViewModal from '@/components/Modal/SellItemViewModal.vue'
import StarRatingInline from '@/components/Board/StarRatingInline.vue'

import BoardList from '@/components/Board/BoardList.vue'
import * as filter from '../utils/filters'
import CompanyValue from '@/components/Popover/CompanyValue.vue'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    AgRefresher,
    BoardList,
    IonContent,
    IonPage,
    IonRippleEffect,
    StarRatingInline,
  },
  name: 'Company',
  props: {
    move: null,
  },
  setup(props) {
    // const { Browser } = Plugins;
    const {getters, dispatch, commit} = useStore()
    const fromCommunity = computed(() => props.move)
    const reValue = reactive<{ mode: boolean; starIcon: string}>({
      mode: false,
      starIcon: '',
    })

    const route = useRoute()
    const router = useRouter()

    const {useClubDealProgressModal} = useModal()

    const stockItemName = ref('')
    const user = computed(() => getters['user/getUser'])
    const loadCompany = (id: string) => {
      dispatch('stockItem/ACT_GET_STOCK_ITEM', {stockItemId: id})
      // 인기 검색 Log 호출
      dispatch('stockItem/ACT_LOG_STOCK_ITEM_SEARCH', {stockItemId: id})
      dispatch('stockItem/ACT_GET_COLLECTED_PARTNERSHIP', {page: 1, stockItemId: id})
    }

    // const paramsId = ref<any>(route.params.stockItemId);
    const paramsId = computed(() => route.params.stockItemId)

    const selectedStockItem = computed(() => getters['stockItem/getSelectedStockItem']) // 현재 슬라이드가 선택된 회사
    const popularStockItem = computed(() => getters['stockItem/getPopularStockItem']) // idx만 넘어옴
    const stockItems = computed(() => getters['stockItem/getStockItems'])
    const initStockItem = computed(() => getters['stockItem/getInitStockItem'])
    const stockItemPartnershipHistories = computed(() => getters['stockItem/getStockItemPartnershipHistories'])

    // 회사 정보
    const stockItemInfo = computed(() => selectedStockItem.value.stockItemInfo || {})
    const initStockItemInfo = computed(() => initStockItem.value.stockItemInfo || {})

    // 회사의 진행중 클럽딜
    const collectingPartnerships = computed(() => selectedStockItem.value.collectingPartnerships || [])
    // 회사의 진행예정 클럽딜
    const comingSoonPartnerships = computed(() => selectedStockItem.value.comingSoonPartnerships || [])
    // 회사의 클럽딜 열기
    const preparePartnerships = computed(() => selectedStockItem.value.preparePartnerships || [])
    // 다른회사 클럽딜
    const anotherCompanyPartnerships = computed(() => selectedStockItem.value.anotherCompanyPartnerships || [])
    // 등록된 주식 매물
    const diplayStockItemSell = computed(() => selectedStockItem.value.diplayStockItemSell || [])
    const stockItemBoards = computed(() => getters['stockItem/getStockItemBoards'])

    const swiper = ref<any>(null)
    const skipIndex = ref(-1)

    const hasSession = () => {
      return getters['user/hasSession']
    }

    function resetRefs() {
      stockItemName.value = ''
    }

    onBeforeRouteLeave((to: any, from: any) => {
      // console.log("onBeforeRouteLeave");
      resetRefs()
    })

    async function initPage() {
      await loadCompany(route.params.stockItemId.toString())
      await dispatch('stockItem/ACT_GET_INIT_STOCK_ITEM', {stockItemId: route.params.stockItemId.toString()})
      await set('deal_storage', route.params.stockItemId)
      stockItemName.value = getters['stockItem/getStandardStockItem'](route.params.stockItemId).stockItemName
    }

    async function initPageMeta() {
      await dispatch('stockItem/ACT_GET_POPULARITY')
      await dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS', {page: 1, stockItemId: route.params.stockItemId.toString()})
    }

    const activeTab = ref('buy')
    const changeTab = (value: string) => {
      activeTab.value = value
    }

    const clickTab = (tab: string) => {
      const topContainer: any = document.querySelector('#company__topContainer')
      const buyBox: any = document.querySelector('#company__buy_box')
      const sellBox: any = document.querySelector('#company__sell_box')
      const boardBox: any = document.querySelector('#company__board_box')

      const containerHeight = topContainer.clientHeight
      const buyHeight = containerHeight + buyBox.clientHeight
      const sellHeight = buyHeight + sellBox.clientHeight
      const boardHeight = sellHeight + boardBox.clientHeight

      const content: any = document.querySelector('#company__ionContent')
      if (tab === 'buy') {
        content.scrollToPoint(0, containerHeight)
      }
      if (tab === 'sell') {
        content.scrollToPoint(0, buyHeight)
      }
      if (tab === 'board') {
        content.scrollToPoint(0, sellHeight)
      }
      if (tab === 'info') {
        content.scrollToPoint(0, boardHeight)
      }
    }

    watch(selectedStockItem, async () => {
      // console.log("watch selectedStockItem", selectedStockItem.value);
    })

    onBeforeMount(async () => {
      // console.log(":::Company onBeforeMount:::")
      if (route.params.stockItemId) {
        await initPage()
        await initPageMeta()
      }
      if (route.params.type === 'community') {
        changeTab('board')
        clickTab('board')
      }
    })

    watch(
      () => route.params,
      async (newParams, oldParameter) => {
        if (newParams.stockItemId) {
          await initPage()
        }
      },
    )

    const doRefresh = async (event: any) => {
      // console.log('Begin async operation');
      if (route.params.stockItemId) {
        await initPage()
      }
      event.target.complete()
    }

    const slideOptions = {
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: -30,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: -38,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: -48,
        },
      },
      centeredSlides: true,
      initialSlide: 0,
      observeParents: true,
      observer: true,
      speed: 400,
    }

    // get icons
    const searchIcon = searchOutline
    const talkIcon = vector
    const budIcon = leaf
    const helpCircle = help
    const thunder = thun
    // const user = getters["user/getUser"];

    const itemLike = (stockItemId: any) => {
      dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
    }

    const partnershipLike = async (partnershipStatus: string, target: string, partnershipId: string) => {
      console.log(partnershipStatus)
      console.log(target)
      console.log(partnershipId)

      const result = await dispatch('partnership/ACT_LIKE_PARTNERSHIP_TARGET', {partnershipId, partnershipStatus, target})

      const likeInfo = result.likeInfo
      const filtered = partnershipStatus === 'coming_soon'
        ? comingSoonPartnerships.value.filter((b: any) => b.partnershipId === likeInfo.partnershipId)
        : preparePartnerships.value.filter((b: any) => b.partnershipId === likeInfo.partnershipId)

      if (filtered.length > 0) {
        filtered[0].partnershipLikeId = likeInfo.partnershipLikeId
        filtered[0].likeCount = likeInfo.likeCount
      }
    }

    const openModal = async (stockItemBoard: any = null) => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: BoardWriteModal,
          componentProps: {
            stockItemBoard,
            stockItemId: paramsId.value,
          },
          cssClass: 'board-write-modal',
          id: 'BoardWriteModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      return modal.present()
    }

    /* functions */
    const clickDeal = async (deal: any) => {
      // 1. getDeal 실행! => vuex에 dealInfo 저장

      const getDeal = () => dispatch('dealInfo/LOADDEAL', {dealInfo: deal})
      await getDeal()
      // 2. modal 페이지 생성!
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: DetailModal,
          componentProps: {
            stockId: deal.stockItemId,
          },
          cssClass: 'my-custom-class',
          // ios 모드에서만 지원 가능? -> 이따 빌드해서 확인해보기
id: 'detailModal',

// backdropDismiss: true,
keyboardClose: true,



mode: 'ios',


swipeToClose: true,
        })

      // console.log(getters.dealInfo);
      return modal.present()
    }

    const handleScroll = _.throttle(async (event: any) => {
      const tab: any = document.querySelector('#company__mainTab')
      const topContainer: any = document.querySelector('#company__topContainer')
      const buyBox: any = document.querySelector('#company__buy_box')
      const sellBox: any = document.querySelector('#company__sell_box')
      const boardBox: any = document.querySelector('#company__board_box')

      const scroll = await event.getScrollElement()

      const containerHeight = topContainer.clientHeight
      const buyHeight = containerHeight + buyBox.clientHeight
      const sellHeight = buyHeight + sellBox.clientHeight
      const boardHeight = sellHeight + boardBox.clientHeight

      if (scroll.scrollTop > containerHeight) {
        tab.classList.add('company__main_tap_fix')
      } else {
        tab.classList.remove('company__main_tap_fix')
      }

      if (scroll.scrollTop > containerHeight && scroll.scrollTop < buyHeight) {
        changeTab('buy')
      }
      if (scroll.scrollTop > buyHeight && scroll.scrollTop < sellHeight) {
        changeTab('sell')
      }
      if (scroll.scrollTop > sellHeight && scroll.scrollTop < boardHeight) {
        changeTab('board')
      }
      if (scroll.scrollTop > boardHeight) {
        changeTab('info')
      }
    }, 33)

    const openSellModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: SellItemModal,
          componentProps: {
            stockItemId: route.params.stockItemId,
          },
          cssClass: 'sell-item-modal',
          id: 'SellItemModal',

          // mode: 'ios',
keyboardClose: true,

swipeToClose: true,
        })

      return modal.present()
    }

    async function showMySellItem(sellInfo: any) {
      if (!sellInfo.isMine) {
return
}
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: SellItemViewModal,
          // ios 모드에서만 지원 가능? -> 이따 빌드해서 확인해보기
componentProps: {
            sellInfoRef: sellInfo,
            updateCompleteRef: false,
          },


cssClass: 'sell-item-modal complete',


// backdropDismiss: true,
keyboardClose: true,


mode: 'ios',

          swipeToClose: true,
        })

      return modal.present()
    }

    function preRegist() {
      console.log('개발해야됨')
    }
    function moreClubdeals() {
      console.log('개발해야됨')
    }

    let popover: any = null
    const openPopover = async (event_: Event) => {
      if (popover) {
return
}
      popover = await popoverController
        .create({
          component: CompanyValue,
          componentProps: {
            onClick: () => {
              popover.dismiss()
            },
          },
          cssClass: 'company-value',
          event: event_,
          translucent: false,
        })

      popover.onDidDismiss().then((result: any) => {
        popover = null
      })
      return popover.present()
    }

    return {
      activeTab,
      anotherCompanyPartnerships,
      arrowDownOutline,
      budIcon,
      changeTab,
      clickDeal,
      clickTab,
      collectingPartnerships,
      comingSoonPartnerships,
      diplayStockItemSell,
      doRefresh,
      filter,
      handleScroll,
      hasSession,
      helpCircle,
      initStockItem,
      initStockItemInfo,
      itemLike,
      moreClubdeals,
      openModal,
      openPopover,
      openSellModal,
      paramsId,
      partnershipLike,
      preRegist,
      preparePartnerships,
      reValue,
      router,
      searchIcon,
      selectedStockItem,
      showMySellItem,
      slideOptions,
      stockItemBoards,
      stockItemInfo,
      stockItemName,
      stockItemPartnershipHistories,
      swiper,
      talkIcon,
      thunder,
      useClubDealProgressModal,
      user,
    }
  },
})

