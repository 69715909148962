import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4ab9b2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "board_container" }
const _hoisted_2 = { class: "board_top" }
const _hoisted_3 = { class: "black" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "board_info space-between" }
const _hoisted_6 = { class: "board_left" }
const _hoisted_7 = { class: "gray" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "star",
  src: "/assets/icon/like_btn.png",
  srcset: "/assets/icon/like_btn@2x.png 2x,/assets/icon/like_btn@3x.png 3x"
}
const _hoisted_10 = {
  key: 1,
  src: "/assets/icon/like_btn_e.png",
  srcset: "/assets/icon/like_btn_e@2x.png 2x,/assets/icon/like_btn_e@3x.png 3x"
}
const _hoisted_11 = { class: "board_content" }
const _hoisted_12 = ["onClick", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_print_og_data = _resolveComponent("print-og-data")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnershipBoards, (board) => {
      return (_openBlock(), _createElementBlock("div", {
        key: board.partnershipBoardId,
        class: "board_list"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(board.nickname), 1),
          (board.writerFlag)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "more-vertical-btn",
                onClick: ($event: any) => (_ctx.openPopover(board, $event))
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3], (v) => {
                  return _createElementVNode("div", {
                    key: v,
                    class: "gray_circle"
                  })
                }), 64))
              ], 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.prettyDateToOnemonth(board.createAt)), 1)
          ]),
          _createElementVNode("div", {
            class: "ion-activatable ripple-parent board_right",
            onClick: ($event: any) => (_ctx.user === null ? _ctx.$global.openLoginModal() :_ctx.boardLike(board))
          }, [
            (board.likeFlag)
              ? (_openBlock(), _createElementBlock("img", _hoisted_9))
              : (_openBlock(), _createElementBlock("img", _hoisted_10)),
            _createElementVNode("span", {
              class: _normalizeClass({'deep-blue': board.likeFlag, 'grey': !board.likeFlag})
            }, _toDisplayString(board.likeCount), 3),
            _createVNode(_component_ion_ripple_effect, { type: "unbounded" })
          ], 8, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", {
            onClick: ($event: any) => (_ctx.boardClick(board,$event)),
            innerHTML: _ctx.printContent(board.contents, true)
          }, null, 8, _hoisted_12),
          (board.ogData)
            ? (_openBlock(), _createBlock(_component_print_og_data, {
                key: 0,
                og: board.ogData,
                onClick: ($event: any) => (_ctx.openLink(board.ogData.url))
              }, null, 8, ["og", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}