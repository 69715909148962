
import {AgBox, AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {computed, defineComponent, defineAsyncComponent, ref, toRef, watch} from 'vue'
import {useStore} from 'vuex'

const modalPages = {
  NEED_AGREE: {
    component: defineAsyncComponent(() => import('./component/terms-of-service/TermsOfService.vue')),
    height: '619px',
  },
  NEED_PERSONAL_INFO: {
    component: defineAsyncComponent(() => import('./component/personal-info/CheckPersonalInfo.vue')),
    height: '619px',
  },
}
export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
  },
  emits: ['complete', 'update:modelValue', 'cancel'],
  name: 'LinkAccountModal',
  props: {
    modelValue: {type: Boolean},
  },
  setup(props, {emit}) {
    const modelValueRef = toRef(props, 'modelValue')
    const {getters, dispatch} = useStore()
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const modalPageName = ref<ModalPageName>(currentStep.value||'ERROR')
    const modalPage = computed(() => modalPages[modalPageName.value])
    const height = computed(() => modalPages[modalPageName.value].height)
    const user = computed(() => getters['user/getUser'])

    watch(modelValueRef, async (value)=>{
      if(value && user.value) {
          await dispatch('stakeDeal/GET_LINK_STATUS')
          modalPageName.value = currentStep.value
        }
    })

    const goToNext = async ()=>{
      await dispatch('stakeDeal/GET_LINK_STATUS')
      if (currentStep.value === 'COMPLETE') {
        emit('complete')
      } else {
       modalPageName.value = await currentStep.value
      }
    }
    return {
      goToNext, height, modalPage,
      modalPageName,
    }
  },
})
