import {defineComponent, h, watch} from 'vue'
import {useFakeProgress, toMutRef} from '@/composables'
import {ProgressBar, ProgressNumber, Container, containerProps, progressBarProps} from './components'
import {debounce} from 'lodash'

export const agProgressProps = {
  ...containerProps,
  ...progressBarProps,
  modelValue: {default: 0, type: Number},
  toggle: {default: true, type: Boolean},
}

const DEBOUNCE_EMIT = 100
const MAX_DEBOUNCE_EMIT = 750

export const AgProgress = defineComponent({
  emits: ['update:toggle', 'update:modelValue'],
  inheritAttrs: false,
  name: 'AgProgress',
  props: {
    ...agProgressProps,
  },
  setup: (props, {slots, emit}) => {
    const updateProgress = debounce((data) => emit('update:modelValue', data), DEBOUNCE_EMIT, {maxWait: MAX_DEBOUNCE_EMIT})
    const toggleRef = toMutRef(props, 'toggle')
    const progressRef = toMutRef(props, 'modelValue')
    
    
    watch(toggleRef, (value) => emit('update:toggle', value))
    watch(progressRef, (value) => updateProgress(value))

    useFakeProgress(progressRef, toggleRef)

    return () => {
      const {bg: _bg, backgroundColor: _backgroundColor, height: _height = '100%', width: _width = '100%', css, ...restProps} = props
      const {bg = _bg, backgroundColor = _backgroundColor, height = _height, width = _width, ...restCss} = css ?? {}

      const _restProps: any = restProps

      _restProps.modelValue = undefined
      _restProps.toggle = undefined

      return (
        h(Container, {..._restProps, css: restCss, height, width}, () => [
          h(ProgressBar, {backgroundColor, bg, style: {width: `${progressRef.value}%`}}),
          h(ProgressNumber, () => [
            slots.default?.({
              progress: progressRef.value,
              toggle: toggleRef.value,
            }),
          ]),
        ])
      )
    }
  },
})
