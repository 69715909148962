import {getResponsiveProp} from './utils'
import {Property} from 'csstype'
import {system} from '@winter-love/style-system'

export const borderSystems = system({
  border: {
    property: 'border',
    scale: 'borders',
  },
  borderBlock: true,
  borderBlockColor: true,
  borderBottom: {
    property: 'borderBottom',
    scale: 'borders',
  },
  borderBottomLeftRadius: {
    scale: 'radii',
  },
  borderBottomRightRadius: {
    scale: 'radii',
  },
  borderColor: {
    property: 'borderColor',
    scale: 'colors',
  },
  borderLeft: {
    property: 'borderLeft',
    scale: 'borders',
  },
  borderRadius: {
    property: 'borderRadius',
    scale: 'radii',
  },
  borderRight: {
    property: 'borderRight',
    scale: 'borders',
  },
  borderStyle: {
    property: 'borderStyle',
    scale: 'borders',
  },
  borderTop: {
    property: 'borderTop',
    scale: 'borders',
  },
  borderTopLeftRadius: {
    scale: 'radii',
  },
  borderTopRightRadius: {
    scale: 'radii',
  },
  borderWidth: {
    property: 'borderWidth',
    scale: 'borders',
  },
  borderX: {
    properties: ['borderLeft', 'borderRight'],
    scale: 'borders',
  },
  borderY: {
    properties: ['borderTop', 'borderBottom'],
    scale: 'borders',
  },
})
export const borderProps = {
  border: getResponsiveProp<Property.Border>(),
  borderBlock: getResponsiveProp<Property.BorderBlock>(),
  borderBlockColor: getResponsiveProp<Property.BorderBlockColor>(),
  borderBottom: getResponsiveProp<Property.BorderBottom>(),
  borderColor: getResponsiveProp<Property.BorderColor>(),
  borderLeft: getResponsiveProp<Property.BorderLeft>(),
  borderRadius: getResponsiveProp<Property.BorderRadius>(),
  borderRight: getResponsiveProp<Property.BorderRight>(),
  borderStyle: getResponsiveProp<Property.BorderStyle>(),
  borderTop: getResponsiveProp<Property.BorderTop>(),
  borderWidth: getResponsiveProp<Property.Width>(),
  borderX: getResponsiveProp<Property.Border>(),
  borderY: getResponsiveProp<Property.Border>(),
}
