import css from '@styled-system/css'
import {styled, keyframes} from '@/plugins/emotion'

const shineLines = keyframes({
  from: {
    backgroundPosition: 'top left',
  },
  to: {
    backgroundPosition: 'top right',
  },
})

export const StyledSkeleton = styled('div', {
  stylePortal: 'css',
})(
  {
    animationDuration: '1s',
    animationFillMode: 'forward',
    animationIterationCount: 'infinite',
    animationName: shineLines,
    animationTimingFunction: 'linear',
    backgroundImage: 'linear-gradient(to right, #f0f0f0, #d8d8d8, #f0f0f0)',
    backgroundSize: '50% 104px',
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    width: '2000px',
  },
  css({
    backgroundColor: 'gray5',
  }),
)
