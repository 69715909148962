import {Theme} from '@winter-love/emotion'
import {DefaultTheme} from '@winter-love/style-system'
import {blocks, Blocks} from './blocks'
import {Colors, colors, invertColors, InvertColors} from './colors'
import {fontSizes, FontSizes} from './font-sizes'
import {fontWeights, FontWeights} from './font-weights'
import {fonts, Fonts} from './fonts'
import {letterSpacings, LetterSpacings} from './letter-spacings'
import {lineHeights, LineHeights} from './line-heights'
import {Radii, radii} from './radii'
import {Sizes, sizes} from './sizes'
import {space, Space} from './space'
import {typography, Typography} from './typography'
import {Borders, borders} from './borders'
import {breakpoints} from './breakpoints'

export * from './blocks'
export * from './colors'
export * from './font-sizes'
export * from './font-weights'
export * from './fonts'
export * from './letter-spacings'
export * from './line-heights'
export * from './radii'
export * from './sizes'
export * from './space'
export * from './typography'
export * from './inner-blocks'

declare module '@winter-love/emotion' {
  interface Theme extends DefaultTheme {
    blocks?: Blocks
    borders?: Borders
    colors?: Colors
    fontSizes?: FontSizes
    fontWeights?: FontWeights
    fonts?: Fonts
    invertColors?: InvertColors
    letterSpacings?: LetterSpacings
    lineHeights?: LineHeights
    radii?: Radii
    sizes?: Sizes
    space?: Space
    typography?: Typography
  }
}

export const theme: Theme = {
  blocks,
  borders,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  invertColors,
  letterSpacings,
  lineHeights,
  radii,
  sizes,
  space,
  typography,
}
