
import {defineComponent, PropType} from 'vue'
import {Stock} from '../types'
import {IonCard, IonCardHeader, IonCardContent, IonCardSubtitle} from '@ionic/vue'
import SearchStockItem from './SearchStockItem.vue'

const text = {
  title: '인기 검색 회사',
}

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    SearchStockItem,
  },
  emits: ['select'],
  name: 'SearchPopularList',
  props: {
    list: Array as PropType<Array<Stock>>,
  },
  setup() {
    return {
      text,
    }
  },
})

