import apiPoint from '@/api/user-point'
import {Module} from 'vuex'
import {RootState} from '..'

const {
  getPoint,
  getInviteCode,
  getTotalPoint,
  getSaveHistory,
  getUseHistory,
  sendInvite,
  getSharePointsLink,
} = apiPoint()

export interface WaitingPoint {
  author?: string
  code?: string
}

// state
interface Point {
  inviteCode: string
  registInviteCode: string
  saveCount: number
  saveHistory: Array<Record<any, any>>
  sharePointsLink?: string
  totalPoint: Record<any, any>
  useCount: number
  useHistory: Array<Record<any, any>>
  userPoint: number
  /**
   * 링크 초대를 통해 추가 예정 포인트정보
   */
  waitingPoint?: WaitingPoint
}

// action
const Point: Module<Point, RootState> = {

  actions:{
    async getPointLink({commit}) {
      const link = await getSharePointsLink()
      commit('setSharePointsLink', link)
      return link
    },
    loadData({commit}) {
      getPoint().then((value) => {
        commit('SET_USERPOINT', value.totalPoint)
      })
      getInviteCode().then((value) => {
        commit('SET_INVITECODE', value.inviteCode)
        commit('SET_REGIST_INVITECODE', value.registInviteCode)
      })
      getTotalPoint().then((value) => {
        commit('SET_TOTALPOINT', value)
      })
      getSaveHistory().then((value) => {
        commit('SET_SAVE_HISTORY', value.savePointList)
        commit('SET_SAVE_COUNT', value.savePointListTotalCount)
      })
      getUseHistory().then((value) => {
        commit('SET_USE_HISTORY', value.exceptSavePointList)
        commit('SET_USE_COUNT', value.exceptSavePointListTotalCount)
      })
    },
    async registerInviteCode({commit}, code) {
      const response = await sendInvite(code)

      const addedPoints = response.points ?? 0

      const {totalPoint} = await getPoint()

      commit('SET_USERPOINT', totalPoint)

      if (response.result) {
        return {
          addedPoints,
          points: totalPoint,
        }
      }

      return {
        addedPoints,
        error: response.msg,
        points: totalPoint,
      }
    },
  },


  getters: {
    getInviteCode: state => state.inviteCode,
    getRegistInviteCode: state => state.registInviteCode,
    getSaveHistory: state => state.saveHistory,
    getTotalPoint: state => state.totalPoint,
    getUseHistory: state => state.useHistory,
    getUserPoint: state => state.userPoint,
    saveCount: state => state.saveCount,
    useCount: state => state.useCount,
  },

  // RootState와 똑같이 작성해도 된다.
  mutations: {
    ADD_SAVE_HISTORY(state, json: Array<Record<any, any>>) {
      state.saveHistory.push(...json)
    },
    ADD_USE_HISTORY(state, json: Array<Record<any, any>>) {
      state.useHistory.push(...json)
    },
    SET_INVITECODE(state, code: string) {
      state.inviteCode = code
    },
    SET_REGIST_INVITECODE(state, code: string) {
      state.registInviteCode = code
    },
    SET_SAVE_COUNT(state, count: number) {
      state.saveCount = count
    },
    SET_SAVE_HISTORY(state, array: Array<Record<any, any>>) {
      state.saveHistory = array
    },
    SET_TOTALPOINT(state, point: Record<any, any>) {
      state.totalPoint = point
    },
    SET_USERPOINT(state, point: number) {
      state.userPoint = point
    },
    SET_USE_COUNT(state, count: number) {
      state.useCount = count
    },
    SET_USE_HISTORY(state, array: Array<Record<any, any>>) {
      state.useHistory = array
    },
    setSharePointsLink(state, payload?: string) {
      state.sharePointsLink = payload
    },
    setWaitingPoint(state, payload?: WaitingPoint) {
      state.waitingPoint = payload
    },
  },
  namespaced: true,
  state: {
    inviteCode: '',
    registInviteCode: '',
    saveCount: 0,
    saveHistory: [],
    totalPoint: [],
    useCount: 0,
    useHistory: [],
    userPoint: 0,
  },
}

export default Point
