
import {
  AgAlert,
  AgBox,
  AgBoxS,
  AgButton,
  AgButtonS,
  AgContent,
  AgFooter,
  AgHeader,
  AgIntersectBox,
  AgModal,
  AgModalS,
  AgText,
  AgTextS,
} from '@/components/ag'
import {
  notificationPermissionRef,
  onNotificationReceived,
  useLegacyClipboard,
  useInAppBrowser,
  useChannelIo,
  useShare,
} from '@/composables'
import {alertController, IonModal, IonPage} from '@ionic/vue'
import {computed, defineComponent, ref, onMounted} from 'vue'

const selfUrl = `${location.protocol}://${location.host}/`

export default defineComponent({
  components: {
    AgBoxS,
    AgButtonS,
    AgContent,
    AgFooter,
    AgHeader,
    AgTextS,
    IonPage,
  },
  name: 'TestPage',
  setup() {
    const {isOpen, onMessage} = useInAppBrowser('http://192.168.35.31:8100/')
    const messageRef = ref({})
    
    onMessage((message) => {
      messageRef.value = message
    })
    
    const {show, boot, openChat, isShow} = useChannelIo()
    
    const share = useShare()

    onMounted(() => {
      boot().then(() => {
        show()
      })
    })

    
    const onShare = () => {
      share({
        dialogTitle: 'dialogTitle',
        text: 'text',
        title: 'title',
        url: 'url',
      })
    }
    
    const onOpen = () => {
      isOpen.value = true
    }
    
    const onOpenChat = () => {
      return openChat()
    }
    
    return {
      isOpen,
      isShow,
      message: messageRef,
      onOpen,
      onOpenChat,
      onShare,
      selfUrl,
    }
  },
})
