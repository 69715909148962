
import {defineComponent, toRef, computed} from 'vue'
import {AgToggleIcon, AgBox, AgText} from '@/components/ag'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    AgText,
    AgToggleIcon,
  },
  emits: ['select'],
  name: 'PartnershipStake',
  props: {
    idx: {type: Number},
    selectedIndex: {type: Number},
    stake: {default: null, type: Object},
  },
  setup(props) {
    const {getters} = useStore()
    const stakeRef = toRef(props, 'stake')
    const indexRef = toRef(props, 'idx')
    const selectedIndexRef = toRef(props, 'selectedIndex')

    const user = computed(() => getters['user/getUser'])
    const userId = computed(() => user.value ? user.value.userId.toString() : '')
    const checkIsLead = computed(() => stakeRef.value.leadId.toString() === userId.value)

    return {
      checkIsLead,
      idxRef: indexRef,
      selectedIndexRef,
      stakeRef,
    }
  },
})
