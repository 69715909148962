import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0a89960"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "main-content",
  class: "ion-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_pc_banner = _resolveComponent("pc-banner")!
  const _component_ag_box_s = _resolveComponent("ag-box-s")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_toast = _resolveComponent("ion-toast")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_splash = _resolveComponent("splash")!
  const _component_notification = _resolveComponent("notification")!
  const _component_notification_allow_message = _resolveComponent("notification-allow-message")!
  const _component_banner = _resolveComponent("banner")!
  const _component_banner_modal = _resolveComponent("banner-modal")!
  const _component_update_confirm = _resolveComponent("update-confirm")!
  const _component_deep_link = _resolveComponent("deep-link")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo),
    (!_ctx.native)
      ? (_openBlock(), _createBlock(_component_ag_box_s, {
          key: 0,
          css: {color: 'font'}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_pc_banner)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_app, {
      class: _normalizeClass({pc: !_ctx.native})
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_header, { class: "safe-header" }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toast, {
                buttons: _ctx.isGlobalToast.buttons,
                duration: _ctx.isGlobalToast.duration||2000,
                "is-open": _ctx.isGlobalToast.open,
                message: _ctx.isGlobalToast.message,
                "css-class": "toast-custom-red",
                position: "top",
                onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDefault()))
              }, null, 8, ["buttons", "duration", "is-open", "message"]),
              _createVNode(_component_ion_router_outlet)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_splash),
        _createVNode(_component_notification),
        _createVNode(_component_notification_allow_message, { "model-value": true }),
        (_ctx.showBanner)
          ? (_openBlock(), _createBlock(_component_banner_modal, {
              key: 0,
              id: _ctx.bannerRef?.bannerId,
              open: _ctx.bannerOpen,
              "onUpdate:open": _ctx.closeBanner
            }, {
              default: _withCtx((bannerProps) => [
                _createVNode(_component_banner, {
                  img: _ctx.bannerRef?.imageUrl,
                  "img-link": _ctx.bannerRef?.link,
                  onClose: bannerProps.close,
                  onProhibitClose: bannerProps.prohibitClose
                }, null, 8, ["img", "img-link", "onClose", "onProhibitClose"])
              ]),
              _: 1
            }, 8, ["id", "open", "onUpdate:open"]))
          : _createCommentVNode("", true),
        _createVNode(_component_update_confirm, { "update-channel": _ctx.channel }, null, 8, ["update-channel"]),
        _createVNode(_component_deep_link)
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}