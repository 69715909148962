import {RouteRecordRaw} from 'vue-router'

export const test: RouteRecordRaw[] = [
  {
    component: () => import('@/views/test/Test.vue'),
    name: 'test',
    path: 'test',
  },
  {
    component: () => import('@/views/test/Test2.vue'),
    name: 'test2',
    path: 'test2',
  },
  {
    component: () => import('@/views/test/Test4.vue'),
    name: 'test4',
    path: 'test4',
  },
  {
    component: () => import('@/views/test/Test5.vue'),
    name: 'test5',
    path: 'test5',
  },
  {
    component: () => import('@/views/test/Test6.vue'),
    name: 'test6',
    path: 'test6',
  },
  {
    component: () => import('@/views/test/InApp.vue'),
    name: 'in-app',
    path: 'in-app',
  },
  {
    component: () => import('@/views/view/buy-stake/FailToConfirmPage.vue'),
    name: 'test-fail',
    path: 'test-fail',
  },
  {
    component: () => import('@/views/view/link-account/component/terms-of-service/TermsCheckBox.vue'),
    name: 'test-check',
    path: 'test-check',
  },
]
