import {RouteRecordRaw} from 'vue-router'
import Tabs from '@/views/Tabs.vue'
import {home} from './home'
import {search} from './search'
import {myPage} from './my-page'
import {community} from './community'
import {test} from './test'

export const tabs: RouteRecordRaw = {
  children: [
    ...home,
    ...search,
    ...myPage,
    ...community,
    ...test,
  ],
  component: Tabs,
  path: '/tabs',
}
