import {MayRef} from '@/types'
import {debounce, DebounceSettings} from 'lodash'
import {computed} from 'vue'
import {wrapRef} from '@/composables'

const DEFAULT_WAIT = 250

export const useDebounce = <Args extends any[] = any[]>(
  handle?: (...args: Args) => void,
  wait?: MayRef<number | boolean | undefined>,
  options?: MayRef<DebounceSettings | undefined>,
) => {
  const waitRef = wrapRef(wait)
  const optionsRef = wrapRef(options)

  const _handle = (...args: Args) => {
    handle?.(...args)
  }

  return computed(() => {
    const wait = waitRef.value
    if (wait) {
      const _wait = typeof wait === 'number' ? wait : DEFAULT_WAIT
      return debounce(_handle, _wait, optionsRef.value)
    }
    return _handle
  })
}
