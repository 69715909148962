import {Plugin} from 'vue'
import {injectGlobalStyle} from './global-style'

export * from './components'

export const angelLeagueComponents: Plugin = () => {
  injectGlobalStyle()
}

export default angelLeagueComponents
