
import {defineComponent, computed, getCurrentInstance, onMounted, toRef} from 'vue'
import {modalController, IonContent, toastController} from '@ionic/vue'
import {useStore} from 'vuex'
import apiPartnership from '@/api/partnership'
import {useRouter} from 'vue-router'
import {SendBirdAction} from '@/plugins/SendBirdAction'

export default defineComponent({
  components: {
    IonContent,
  },
  name: 'ClubDealInfo',
  props: {
    anotherNotYetProps: null,
    parent: String,
    partnershipName: null,
  },
  setup(props) {
    const app = getCurrentInstance()
    const $modalOpenEvent = app && app.appContext.config.globalProperties.$modalOpenEvent
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const {getters} = useStore()
    // const anotherNotYet = computed(() => getters[`partnership/getAnotherNotYet`]);
    const anotherNotYet = computed(() => props.anotherNotYetProps)
    // console.log("anotherNotYet", anotherNotYet.value);
    // console.log("partnershipName", props.partnershipName);
    const {delPartnershipRequests} = apiPartnership()
    const router = useRouter()
    const parentRef = toRef(props, 'parent')
    const clickScreen = parentRef.value

    onMounted(()=>{
      $modalOpenEvent({page: 'another-clubdeal-info', title: anotherNotYet.value.partnershipName})
    })

    const dismiss = () => {
      console.log('?')
      $clickTrackEvent({'clickArea':'클럽딜 참여 정보', 'clickContent':'닫기', clickScreen})
      modalController.dismiss()
    }

    async function sendCancelClubDealMessage(message: string, channelUrl: string) {
      if (!message) {
return
}
      const sendbirdAction: any = await SendBirdAction.getInstance()
      sendbirdAction
        .getChannel(channelUrl, false)
        .then((channel: any) => {
          sendbirdAction.sendUserMessage({
            channel: channel,
            handler: (message: any, error: any) => {
              // console.log(message, error);
            },
            message: message,
            status: 'cancelToSeller',
          })
        })
    }

    const cancel = async () => {
      try{
        const message = await delPartnershipRequests(anotherNotYet.value.partnershipRequestId, anotherNotYet.value.partnershipId)
        console.log(message)
        await modalController.dismiss('cancelJoin')

        // await router.push({ name : 'ClubDealProgress', params: { clubdealId : anotherNotYet.value.partnershipId}});

        const toast = await toastController
            .create({
              duration: 1000,
              message: '클럽딜 참여가 취소되었습니다.',
              position: 'middle',
            })
        if (anotherNotYet.value.partnershipOpenChannelId) {
          await sendCancelClubDealMessage(message.channelMessage, anotherNotYet.value.channelUrl)
        }
        return toast.present()
      }catch {
        // empty
      }

    }

    return {
      anotherNotYet,
      cancel,
      clickScreen,
      dismiss,
    }
  },
})
