
import {AgButton, AgContent, AgHeader} from '@/components/ag'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {
    AgButton,
    AgContent,
    AgHeader,
  },
  emits: ['go'],
  name: 'Bar.vue',
  setup(_, {emit}) {
    const goFoo = () => {
      emit('go', 'foo')
    }

    return {
      goFoo,
    }
  },
})
