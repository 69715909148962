
import {AgBox, AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {computed, defineAsyncComponent, defineComponent, ref, toRef, PropType, watch} from 'vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

type OpenType = 'deposit' | 'withdraw'

const modalPages = {
  DEPOSIT_REQUEST_INFO: defineAsyncComponent(() => import('../component/deposit-request-info.vue')),
  WITHDRAW_REQUEST_INFO: defineAsyncComponent(() => import('../component/withdraw-request-info.vue')),
}
export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    ModalHeader,
  },
  emits: ['close', 'closeWithResult', 'update:openType'],
  name: 'RegistSellStakeModal',
  props: {
    isOpen: {type: Boolean},
    openType: {type: String as PropType<OpenType>},
  },
  setup(props, {emit}) {
    const gtm = useGtm()
    const openTypeRef = toRef(props, 'openType')
    const isOpenRef = toRef(props, 'isOpen')
    const stepRef = ref<ModalPageName | undefined>()

    const clickScreen = 'deposit-request-modal'
    watch(isOpenRef, ()=>{
      if (isOpenRef.value){
        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: clickScreen,
          title: '충전신청 모달',
        })
      }
    })


    const initModalPage = computed(() => {
      switch (openTypeRef.value) {
        case 'deposit':
          return modalPages.DEPOSIT_REQUEST_INFO
        case 'withdraw':
          return modalPages.WITHDRAW_REQUEST_INFO
        default:
          return modalPages.DEPOSIT_REQUEST_INFO
      }
    })

    const modalPage = computed(() => {
      if (stepRef.value) {
        return modalPages[stepRef.value]
      }
      return initModalPage.value
    })

    const setOpen = (value: boolean) => {
      if (!value) {
        stepRef.value = undefined
      }
      emit('close', value)
    }

    const goToModal = (step?: ModalPageName) => {
      console.log(step)
      stepRef.value = step
    }

    return {
      clickScreen, goToModal, modalPage, openTypeRef, setOpen,
    }
  },
})
