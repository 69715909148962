
import {defineComponent, ref} from 'vue'
export default defineComponent({
  name: 'StarRatingInline',
  props: {
    disabled: Boolean,
    id: String,
    name: String,
    required: Boolean,
    value: null,
  },
  setup() {
    const ratings = ref([1, 2, 3, 4, 5])
    return {ratings}
  },
})
