
import {AgBox, AgButton, AgContent, AgHeader, AgFooter} from '@/components/ag'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
  },
  emits: ['go'],
  name: 'Foo.vue',
  setup(_, {emit}) {
    const goBar = () => {
      emit('go', 'bar')
    }

    const list = (Array(50)).fill('scroll')

    return {
      goBar,
      list,
    }
  },
})
