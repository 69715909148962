import CallApi from '../utils/CallApi'

export const getLinkStatus = async () => {
  // 지분거래 가능 상태 조회 api
  let linkStatus: any = null
  try {
    linkStatus = await CallApi({
      method: 'get',
      url: '/share-transaction/agree',
    })
  } catch {
    console.log(Error)
  }

  return linkStatus
}

export const actionLinkStatusApi = async (endPoint: any, apiMethod: any) => {
  let result: any = null

  try {
    result = await CallApi({
      method: apiMethod,
      url: `${endPoint}`,
    })
  } catch (error) {
    console.log(error)
  }

  return result
}

export const getCompanyStakeList = async (stockItemId: any, page: any, perPage:number=100, includeStatus: string = '') => {
  let list: any = null
  try {
    list = await CallApi({
      method: 'get',
      url: `/share-transaction/sells?stockItemId=${stockItemId}&perPage=${perPage}&page=${page}&includeStatus=${includeStatus}`,
    })
  } catch {
    console.log(Error)
  }

  return list
}

export const getStakeDepositInfo = async (partnershipSharePurchaseId: any) => {
  let list: any = null
  try {
    list = await CallApi({
      method: 'get',
      url: `/share-transaction/purchases/${partnershipSharePurchaseId}/deposit-info`,
    })
  } catch {
    console.log(Error)
  }

  return list
}

export const getAllStakeList = async (page: number) => {
  let list: any = null
  const perPage = 500
  try {
    list = await CallApi({
      method: 'get',
      url: `/share-transaction/sells?perPage=${perPage}&page=${page}`,
    })
  } catch {
    console.log(Error)
  }

  return list
}


// export const searchMyStakeList = async (stockItemName: any) => {
//   let list: any = null
//   try {
//     list = await CallApi({
//       method: 'get',
//       url: `/share-transaction/partnership-shares?search=${stockItemName}`,
//     })
//   } catch {
//     console.log(Error)
//   }
//
//   return list
// }

export const getMyStakeList = async (stockItemId: any) => {
  let list: any = null
  try {
    list = await CallApi({
      method: 'get',
      url: `/share-transaction/sells/partnership-shares?stockItemId=${stockItemId}`,
    })
  } catch {
    console.log(Error)
  }

  return list
}

export const getSelectedStakeInfo = async (partnershipId: any, stockItemId: any) => {
  let list: any = null
  try {
    list = await CallApi({
      method: 'get',
      url: `/share-transaction/sells/partnership-shares?stockItemId=${stockItemId}&partnershipId=${partnershipId}`,
    })
  } catch {
    console.log(Error)
  }

  return list
}

export const getMyAllStakeList = async () => {
  let list: any = null
  try {
    list = await CallApi({
      method: 'get',
      url: '/share-transaction/sells/stock-items',
    })
  } catch {
    console.log(Error)
  }

  return list
}

export const getCalcResult = async (partnershipId: any, price: any, totalPrice: any, maxTotalPrice: any, remainingAsset: any) => {
  let result: any = null
  try {
    result = await CallApi({
      data: {maxTotalPrice: Number(maxTotalPrice), partnershipId: Number(partnershipId), price: Number(price), remainingAsset: Number(remainingAsset), totalPrice: Number(totalPrice)},
      method: 'get',
      url: `/share-transaction/sells/calculate-expected-price?partnershipId=${partnershipId}&price=${price}&totalPrice=${totalPrice}&maxTotalPrice=${maxTotalPrice}&remainingAsset=${remainingAsset}`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const getCalcCarriedInterest = async (partnershipId: any, expectedQty: any, expectedAsset: any, assetPrice: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'get',
      url: `/share-transaction/sells/calculate-carried-interest?partnershipId=${partnershipId}&expectedQty=${expectedQty}&expectedAsset=${expectedAsset}&assetPrice=${assetPrice}`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const editCalcCarriedInterest = async (partnershipId: any, expectedQty: any, expectedAsset: any, assetPrice: any, partnershipShareSellId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'get',
      url: `/share-transaction/sells/calculate-carried-interest?partnershipId=${partnershipId}&expectedQty=${expectedQty}&expectedAsset=${expectedAsset}&assetPrice=${assetPrice}&partnershipShareSellId=${partnershipShareSellId}`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const getTotalPriceCalcResult = async (partnershipId: any, price: any, mode: any, partnershipShareSellId?: any) => {
  let result: any = null
  try {
    result = await CallApi({
      data: {partnershipId: Number(partnershipId), price: Number(price)},
      method: 'get',
      url: mode === 'edit'
        ? `/share-transaction/sells/calculate-max-total-price-remaining-asset?partnershipId=${partnershipId}&price=${price}&partnershipShareSellId=${partnershipShareSellId}`
        : `/share-transaction/sells/calculate-max-total-price-remaining-asset?partnershipId=${partnershipId}&price=${price}`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const getRedirectPage = async (partnershipId: any, price: any, totalPrice: any, maxTotalPrice: any, remainingAsset: any) => {
  let url: any = null
  try {
    url = await CallApi({
      data: {maxTotalPrice: Number(maxTotalPrice), partnershipId: Number(partnershipId), price: Number(price), remainingAsset: Number(remainingAsset), totalPrice: Number(totalPrice)},
      method: 'post',
      url: '/share-transaction/sells',
    })
  } catch {
    console.log(Error)
  }

  return url
}

export const getEditRedirectPage = async (partnershipId: any, partnershipShareSellId: any, price: any, totalPrice: any, maxTotalPrice: any, remainingAsset: any) => {
  let url: any = null
  try {
    url = await CallApi({
      data: {maxTotalPrice: Number(maxTotalPrice), partnershipId: Number(partnershipId), price: Number(price), remainingAsset: Number(remainingAsset), totalPrice: Number(totalPrice)},
      method: 'put',
      url: `/share-transaction/sells/${partnershipShareSellId}`,
    })
  } catch {
    console.log(Error)
  }

  return url
}

export const getResultOfRegisterStake = async (requestId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'get',
      url: `/external/negostock-page-requests/${requestId}/check-response`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const requestSignToJoin = async (partnershipShareSellId: any, buyerSign: any, buyerSignId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      data: {
        buyerSign: buyerSign,
        buyerSignId,
      },
      method: 'post',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/check`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const requestPurchase = async (partnershipShareSellId: any, buyerSignId: any) => {
  let result: any = null
  result = await CallApi({
    data: {
      buyerSignId,
    },
    method: 'post',
    url: `/share-transaction/sells/${partnershipShareSellId}/purchases`,
  })
  return result
}

export const getPurchaseInfo = async (partnershipShareSellId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'get',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/purchase-info`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const getPurchaseCheck = async (partnershipShareSellId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'get',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/check`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const deletePurchaseInfo = async (partnershipShareSellId: any, partnershipSharePurchaseId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'delete',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/${partnershipSharePurchaseId}`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const deleteSellInfo = async (partnershipShareSellId: any) => {
  const result = await CallApi({
    method: 'delete',
    url: `/share-transaction/sells/${partnershipShareSellId}`,
  })

  return result
}

export const getSellInfoDetail = async (partnershipShareSellId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'get',
      url: `/share-transaction/sells/${partnershipShareSellId}`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const cancelFromBuyer = async (partnershipShareSellId: any, partnershipSharePurchaseId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'delete',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/${partnershipSharePurchaseId}/buyer`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const cancelFromSeller = async (partnershipShareSellId: any, partnershipSharePurchaseId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'delete',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/${partnershipSharePurchaseId}/seller`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const postConfirmSell = async (partnershipShareSellId: any, partnershipSharePurchaseIdByBuyer: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'post',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/${partnershipSharePurchaseIdByBuyer}/confirm`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const deleteDealInfo = async (partnershipShareSellId: any, partnershipSharePurchaseId: any) => {
  let result: any = null
  try {
    result = await CallApi({
      method: 'delete',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/${partnershipSharePurchaseId}`,
    })
  } catch {
    console.log(Error)
  }

  return result
}

export const getValidatePurchase = async (partnershipShareSellId: any) => {
  const r = {data:null, msg: '', result: false, status: null}
  try {
    const result = await CallApi({
      method: 'get',
      url: `/share-transaction/sells/${partnershipShareSellId}/purchases/validate`,
    })

    r.result = true
  } catch (error) {
    r.result = false
    r.status = error.response.status
    r.msg = error.response.data.message
    r.data = error.response.data.data
  }

  return r
}
