import {Plugins, AppUrlOpen} from '@capacitor/core'
import {onBeforeUnmount} from 'vue'
// import {getBaseUrl} from '@/utils'

const {App} = Plugins

export type UseDeepLinksHandle = (url: string) => unknown

export const onDeepLinks = (handle: UseDeepLinksHandle) => {
  const appHandle = App.addListener('appUrlOpen', (data: AppUrlOpen) => {
    handle(data.url)
  })

  onBeforeUnmount(() => {
    appHandle.remove()
  })
}
