
import {defineComponent, computed, onBeforeMount} from 'vue'
import {IonRippleEffect} from '@ionic/vue'
import {AgBox, AgText, AgModal, AgContent, AgHeader} from '@/components/ag'
import {useStore} from 'vuex'
import {prettyDateToOnemonth, perCurrency, getFormattedDate} from '@/utils/filters'
import apiSettings from '@/api/settings'
const {agreeThirdparty} = apiSettings()

export default defineComponent({
  components: {
    AgBox,
    AgContent,
    AgHeader,
    AgModal,
    AgText,
    IonRippleEffect,
  },
  emits: ['update:modelValue'],
  name: 'AgreeToUsePersonalInfo',
  props: {
    modelValue: {type: Boolean},
  },
  setup(props, {emit}) {
    const {getters, dispatch} = useStore()
    const setOpen = (value: boolean) => emit('update:modelValue', value)
    const user = computed(() => getters['user/getUser'])
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const settingInfo = computed(() => getters['user/getSettingInfo'] || [])
    const negostockAgreeThirdparty = computed(() => settingInfo.value ? settingInfo.value.negostockAgreeThirdparty : '')
    const negostockAgreeThirdpartyAt = computed(() => settingInfo.value ? settingInfo.value.negostockAgreeThirdpartyAt : '')
    const isAgreeThirdparty = computed(() => settingInfo.value ? settingInfo.value.agreeThirdparty : '')
    const agreeThirdpartyAt = computed(() => settingInfo.value ? settingInfo.value.agreeThirdpartyAt : '')

    onBeforeMount(async () => {
      if (user.value) {
await dispatch('user/ACT_SET_SETTING_INFO')
}
    })

    const agreeKakaoKilp = () => {
      agreeThirdparty()
        .then((data) => {
          if (data) {
            dispatch('user/ACT_SET_SETTING_INFO')
          }
        })
    }

    const formattedDate = (updateDate: any) => {
      return getFormattedDate(updateDate, 'YY. MM. DD')
    }

    const clickScreen = 'agree-personal-info'

    return {
      agreeKakaoKilp, 
      agreeThirdpartyAt,
      clickScreen,
      dealInfo,
      formattedDate,
      isAgreeThirdparty,
      negostockAgreeThirdparty,
      negostockAgreeThirdpartyAt,
      perCurrency,
      prettyDateToOnemonth,
      setOpen,
      settingInfo,
      user,
    }
  },
})
