import {StyledContent, styledContentProps} from './components'
import {FunctionalComponent, h, ExtractPropTypes} from 'vue'

export const agContentProps = {
  ...styledContentProps,
}

export const AgContentS = StyledContent

export type AgContentProps = ExtractPropTypes<typeof agContentProps>

export const AgContent: FunctionalComponent<AgContentProps> = (props, {slots}) => {
  return h(AgContentS, {css: props}, slots)
}

AgContent.props = agContentProps
