
import {defineComponent, toRef} from 'vue'
import {AgImg, AgIntersectBox, AgBoxS, AgTextS, AgIcon} from '@/components/ag'
import {numberToKorean, prettyDateToOnemonth, perCurrency} from '@/utils/filters'
import {personCircleOutline} from 'ionicons/icons'
import {IonIcon} from '@ionic/vue'
export default defineComponent({
  components: {
    AgBoxS,
    AgIcon,
    AgImg,
    AgIntersectBox,
    AgTextS,
    IonIcon,
  },
  name: 'StakeDealList',
  props: {
    dealInfo: {type: Object},
  },
  setup(props) {
    const dealInfoRef = toRef(props, 'dealInfo')

    return {
      dealInfoRef,
      numberToKorean,
      perCurrency,
      personCircleOutline,
      prettyDateToOnemonth,
    }
  },
})
