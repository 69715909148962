import {cornerProps, systemProps, _SystemProps} from '@/plugins/emotion'
import {defineComponent, h, FunctionalComponent, ExtractPropTypes, PropType} from 'vue'
import {ionModelProps, styleModalProps, ModalWrapper, StyledModal} from './components'


export const agModalOriginalProps = {
  ...ionModelProps,
  lazy: {default: false, type: Boolean},
  modalCss: {default: () => ({}), type: Object as PropType<ExtractPropTypes<typeof styleModalProps>>},
  modelValue: {default: false, type: Boolean},
}

export type AgModalSProps = ExtractPropTypes<typeof agModalOriginalProps> & {
  /**
   * ModalWrapper 에 전달할 css
   */
  css?: _SystemProps
}

export const agModalEmits = ['update:modelValue']

export type AgModalEmits = typeof agModalEmits

export const AgModalS: FunctionalComponent<AgModalSProps, AgModalEmits> = (
  props,
  {emit, slots, attrs},
) => {
  const {modelValue, modalCss, ...restProps} = props
  const onClose = () => {
    emit('update:modelValue', false)
  }

  return h(StyledModal, {
    ...restProps,
    css: modalCss,
    isOpen: modelValue,
    onDidDismiss: onClose,
  }, () => [
    h(ModalWrapper, {...attrs}, () => slots.default?.()),
  ])
}

/**
 * attrs 상속을 제어 하기 위해 사용한다
 * 기본적으로 root 에 attrs 를 넘겨 주는데
 * 여기서는 root 의 자식인 ModalWrapper 에 넘겨 준다
 */
AgModalS.inheritAttrs = false
AgModalS.props = agModalOriginalProps
AgModalS.emits = agModalEmits

export const AgModal = defineComponent({
  emits: ['update:modelValue'],
  inheritAttrs: false,
  name: 'AgModal',
  props: {
    ...systemProps,
    ...ionModelProps,
    ...cornerProps,
    ...styleModalProps,
    modelValue: {default: false, type: Boolean},
  },
  setup(props, {emit, slots, attrs}) {
    const onClose = () => {
      emit('update:modelValue', false)
    }

    return () => {
      const {
        modelValue,
        animated,
        backdropDismiss,
        enterAnimation,
        keyboardClose,
        leaveAnimation,
        mode,
        presentingElement,
        showBackdrop,
        swipeToClose,
        safeArea,
        corner,
        cornerBottomLeft,
        cornerBottomRight,
        cornerTopLeft,
        cornerTopRight,
        modalPosition,
        height,
        css,
        ...rest
      } = props
      return (
        h(StyledModal, {
          animated,
          backdropDismiss,
          css: {
            corner,
            cornerBottomLeft,
            cornerBottomRight,
            cornerTopLeft,
            cornerTopRight,
            height,
            modalPosition,
            safeArea,
          },
          enterAnimation,
          isOpen: modelValue,
          keyboardClose,
          leaveAnimation,
          mode,
          onDidDismiss: onClose,
          presentingElement,
          showBackdrop,
          swipeToClose,
        }, () => h(ModalWrapper, {...attrs, css: {...rest, ...css}}, () => slots?.default?.()))
      )
    }
  },
})

