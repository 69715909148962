
import {defineComponent, toRef, computed} from 'vue'
import {AgBox, AgText} from '@/components/ag'
import {IonLabel} from '@ionic/vue'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    AgText,
    IonLabel,
  },
  emits: ['select'],
  name: 'SelectedPartnershipStake',
  props: {
    carriedInterest: {type: Number || String},
    componentNumber: {type: Number || String},
    finalSellInfo: {type: Object},
    sellAmount: {type: Number || String},
    sellPrice: {type: Number || String},
  },
  setup(props) {
    const {getters} = useStore()
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const sellPriceRef = toRef(props, 'sellPrice')
    const finalSellInfoRef = toRef(props, 'finalSellInfo')
    const sellAmountRef = toRef(props, 'sellAmount')
    const componentNumberRef = toRef(props, 'componentNumber')
    const expectedQtyRef = computed(() => finalSellInfoRef.value ? finalSellInfoRef.value.expectedQty : '')
    const expectedTotalPriceRef = computed(() => finalSellInfoRef.value ? finalSellInfoRef.value.expectedTotalPrice : '')
    const expectedAssetRef = computed(() => finalSellInfoRef.value ? finalSellInfoRef.value.expectedAsset : '')
    const expectedPriceRef = computed(() => finalSellInfoRef.value ? finalSellInfoRef.value.expectedPrice : '')
    const carriedInterestRef = toRef(props, 'carriedInterest')

    const componentNumberName = computed(() => {
      return (componentNumberRef?.value ?? 0) < 4 ? '희망' : '예정'
    })

    return {
      carriedInterestRef,
      componentNumberName,
      componentNumberRef,
      dealInfo,
      expectedAssetRef,
      expectedPriceRef,
      expectedQtyRef,
      expectedTotalPriceRef,
      finalSellInfoRef,
      sellAmountRef,
      sellPriceRef,
    }
  },
})
