import {useElementEvent, useSplashscreen} from '@/composables'
import {defineComponent} from 'vue'

export const Splash = defineComponent({
  props: {
    limitWait: {default: 4000, type: Number},
  },
  setup(props) {
    const splash = useSplashscreen()

    const timeOut = setTimeout(() => {
      splash.hide()
    }, props.limitWait)

    const handle = useElementEvent(window, 'load', () => {
      splash.hide()
      clearTimeout(timeOut)
      handle.value = false
    }, false)

    handle.value = true
    return () => null
  },
})
