import {createRouter, createWebHistory} from '@ionic/vue-router'
import {RouteRecordRaw} from 'vue-router'
import {tabs} from './tabs'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home',
  },
  tabs,
  {
    component: () => import('@/views/Login.vue'),
    name: 'login',
    path: '/login',
  },
  {
    component: () => import('@/views/notification-list/NotificationList'),
    name: 'notification-list',
    path: '/notification-list',
  },
  {
    component: () => import('@/views/notification-setting/NotificationSetting.vue'),
    name: 'notification-setting',
    path: '/notification-setting',
  },
  {
    component: () => import('@/views/view/negostock/PartnershipDetailInfo.vue'),
    name: 'NegostockDetailPage',
    path: '/partnership/:partnershipId',
  },
  {
    component: () => import('@/views/BuyChat.vue'),
    path: '/buychat/:chat_id',
  },
  {
    component: () => import('@/views/ClubDealBuyChat.vue'),
    name: 'ClubDealBuyChat',
    path: '/clubdeal-buychat/:channelUrl',
  },
  {
    component: () => import('@/views/ClubDealSellChat.vue'),
    name: 'ClubDealSellChat',
    path: '/clubdeal-sellchat/:channelUrl',
  },
  {
    component: () => import('../components/PayResult.vue'),
    path: '/payresult/:status',
  },
  {
    component: () => import('@/views/Settings/SettingsParent.vue'),
    name: 'Settings',
    path: '/settings',
  },
  {
    component: () => import('@/views/Settings/DeleteAccount.vue'),
    name: 'DeleteAccount',
    path: '/delete-account',
  },
  {
    component: () => import('@/views/Deal/ClubDealProgress.vue'),
    name: 'ClubDealProgress',
    path: '/clubdeal-progress/:clubdeal_id',
  },
  {
    component: () => import('@/views/Deal/ClubDealOpen.vue'),
    name: 'ClubDealOpen',
    path: '/clubdeal-open/:clubdeal_id',
  },
  {
    component: () => import('../components/ClubDeal/ClubDealJoin.vue'),
    name: 'ClubDealJoin',
    path: '/join/:clubdealId',
  },
  {
    component: () => import('../components/ClubDeal/ClubDealOpenJoin.vue'),
    name: 'ClubDealOpenJoin',
    path: '/join-open/:clubdealId',
    props: route => ({...route.params, ...route.query}),
  },
  {
    component: () => import('../views/test/Test2.vue'),
    name: 'TestPage',
    path: '/test',
  },
  {
    component: () => import('@/views/Agrees/Use.vue'),
    name: 'AgreeUse',
    path: '/agree-use',
  },
  {
    component: () => import('@/views/under-construction/Index.vue'),
    name: 'under-construction',
    path: '/under-construction',
  },
  {
    component: () => import('@/views/final-error/Index.vue'),
    name: 'final-error',
    path: '/error',
  },
  {
    component: () => import('@/views/DeepLinkRedirect'),
    path: '/link/:etc*',
    props: (context) => {
      const {path} = context
      return {
        link: path.replace(/^\//u, ''),
      }
    },
  },
  {
    path: '/:catchAll(.*)*',
    redirect: '/tabs/home',
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})




// router.beforeEach(async (to: any, from: any, next) => {
// const user = store.getters['user/getUser']
// if (user) {
//   await initSendBird()
// }
// await SendBirdAction.getInstance()
// next()
// })

// router.beforeEach((to, from, next) => {
//   modalController.dismiss()
//   next()
// })

export default router


