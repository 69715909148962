import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_like_star = _resolveComponent("like-star")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: "",
    lines: "none",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.id)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_avatar, { slot: "start" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.logo,
            alt: "logo"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createVNode(_component_like_star, {
        slot: "end",
        value: _ctx.like
      }, null, 8, ["value"])
    ]),
    _: 1
  }))
}