<template>
  <ion-content
    :translucent="true"
  >
    <div class="box">
      <div class="title">
        서비스 이용료
      </div>
      <div class="info">
        엔젤리그가 각 조합의 감사로서 조합을 실시간 관리, 검증하며 각종 진행 업무를 지원하는데 사용됩니다.
      </div>
    </div>
  </ion-content>
</template>

<script>
import {IonContent} from '@ionic/vue'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {IonContent},
  name: 'Fee',
})
</script>

<style scoped>
ion-content {
--background: #222;
}

.text {
color: #C0C0C0;
}

.title {
color: #fff;
font-size: 16px;
}

.info {
color: #fff;
font-size: 12px;
margin-top: 8px;
margin-bottom: 8px;
}

.box {
padding: 20px;
}
</style>
