import CallApi from '../utils/CallApi'

export default function() {
  const getAsset = async () => {
    let assets: any = null
    try {
      assets = await CallApi({
        method: 'get',
        url: '/user/asset',
      })
    } catch {
      // console.log(error);
    }

    return assets
  }

  const getAssetPartnerships = async () => {
    let assets: any = null
    try {
      assets = await CallApi({
        method: 'get',
        url: '/user/asset/partnerships',
      })
    } catch {
      // console.log(error);
    }

    return assets
  }

  const getShareTransactions = async (page: any) => {
    const perPage = 1000
    let assets: any = null
    try {
      assets = await CallApi({
        method: 'get',
        url: `/user/asset/share-transactions?perPage=${perPage}&page=${page}`,
      })
    } catch {
      // console.log(error);
    }
    console.log(assets)

    return assets
  }

  const getSelectedPartnership = async (partnershipId: any) => {
    let assets: any = null

    try {
      assets = await CallApi({
        method: 'get',
        url: `/user/asset/partnerships/${partnershipId}`,
      })
    } catch {
      // console.log(error);
    }

    return assets
  }

  const getPartnershipBoard = async (partnershipId: any) => {
    let list: any = null

    try {
      list = await CallApi({
        method: 'get',
        url: `/partnerships/${partnershipId}/boards`,
      })
    } catch {
      // console.log(error);
    }

    return list?.partnershipBoards
  }

  const createPartnershipBoards = async (boardData: any) => {
    let result: any = ''

    // console.log(boardData)

    if (boardData.partnershipBoardId) {
      try {
        result = await CallApi({
          data: {contents: boardData.contents},
          method: 'put',
          url: `/partnerships/${boardData.partnershipId}/boards/${boardData.partnershipBoardId}`,
        })
        result.type = 'update'
      } catch {
        // console.log(error)
      }
    } else {
      try {
        result = await CallApi({
          data: {contents: boardData.contents},
          method: 'post',
          url: `/partnerships/${boardData.partnershipId}/boards`,
        })
        result.type = 'create'
      } catch {
        // console.log(error)
      }
    }

    return result
  }

  const deletePartnershipBoard = async (partnershipId: any, partnershipBoardId: any) => {
    // console.log(partnershipId)
    // console.log(partnershipBoardId)

    let result: any = null

    try {
      result = await CallApi({
        method: 'delete',
        url: `/partnerships/${partnershipId}/boards/${partnershipBoardId}`,
      })
    } catch {
      // console.log(error);
    }
    // console.log(result)

    return result
  }

  const likePartnershipBoard = async (partnershipId: any, partnershipBoardId: any) => {
    let result: any = null

    try {
      result = await CallApi({
        method: 'post',
        url: `/partnerships/${partnershipId}/boards/${partnershipBoardId}/like`,
      })
    } catch {
      // console.log(error);
    }

    return result
  }

  return {
    createPartnershipBoards,
    deletePartnershipBoard,
    getAsset,
    getAssetPartnerships,
    getPartnershipBoard,
    getSelectedPartnership,
    getShareTransactions,
    likePartnershipBoard,
  }
}
