import {styled, typography, colorSystem, colorProp, variant} from '@/plugins/emotion'
import css from '@styled-system/css'

const labelFontSizeVariant = variant({
  prop: 'block',
  variants: {
    md: {
      ...typography.main12r,
    },
    sm: {
      ...typography.main12r,
    },
  },
})

export const LabelWrapper = styled('div', {
  props: {
    block: null,
    color: colorProp,
  },
})(
  css({
    fontFamily: 'block',
    px: '7px',
  }) as any,
  colorSystem,
  labelFontSizeVariant,
)
