
import {IonPage, IonContent, IonRippleEffect, IonIcon, IonInput} from '@ionic/vue'
import {defineComponent, computed, onBeforeMount, getCurrentInstance, ref} from 'vue'
import {useRouter} from 'vue-router'
import useModal from '@/composables/useModal'
import {useStore} from 'vuex'
import {openLink} from '@/utils/filters'
import {Browser} from '@capacitor/core'
import {modalController} from '@/plugins/ionic'
import TesterLoginModal from '@/components/Modal/TesterLoginModal.vue'
import {AgBox, AgButton, AgText, AgFooter, AgContent, AgHeader, AgModal} from '@/components/ag'
import {globeOutline} from 'ionicons/icons'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    IonContent,
    IonIcon,
    IonInput,
    IonPage,
    IonRippleEffect,
  },
  name: 'MyPageNon',
  setup() {
    const app = getCurrentInstance()
    const $global = app && app.appContext.config.globalProperties.$global
    const router = useRouter()
    const {getters, dispatch} = useStore()
    const {useClubDealProgressModal, openFAQModal} = useModal()
    const {viewKakaoKlipModal} = useModal()
    const exceptionToken = ref<string>('')
    const isOpenCodeLogin = ref(false)
    const recommendList = computed(() => getters['partnership/getEntireRecommendPartnership'])

    async function initThisPage() {
      await dispatch('partnership/ACT_GET_ENTIRE_RECOMMEND_PARTNERSHIPS')
    }

    onBeforeMount(() => initThisPage())

    async function openTesterLoginModal() {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: TesterLoginModal,
          componentProps: {},
          cssClass: 'simple-login-modal',
          id: 'SimpleLoginModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      return modal.present()
    }

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }

    async function getUuidByExceptionToken() {
      await dispatch('user/ACT_USER_EXCEPTION_CHECK', exceptionToken.value)
      return $global.openLoginModal()
    }

    const setOpenCodeLogin = (value: boolean) => {
      isOpenCodeLogin.value = value
    }

    const setExceptionToken = (event: any) => {
      exceptionToken.value = event?.target?.value ?? ''
    }

    const closeCodeLoginAndOpenChannelTalk = () => {
      setOpenCodeLogin(false)
      openChannelTalk()
    }

    const clickScreen = 'my'

    return {
      clickScreen, 
      closeCodeLoginAndOpenChannelTalk,
      getUuidByExceptionToken,
      globeOutline,
      isOpenCodeLogin,
      openChannelTalk,
      openFAQModal,
      openLink,
      openTesterLoginModal,
      recommendList,
      router,
      setExceptionToken,
      setOpenCodeLogin,
      useClubDealProgressModal,
      viewKakaoKlipModal,
    }
  },
})
