import {getTokenString} from '@/api/auth-header'
import SendBird from 'sendbird'

const sb = new SendBird({
  appId: 'E541C962-CE85-41B1-9651-69537658312D',
})

const ChannelHandler = new sb.ChannelHandler()
// console.log("New ChannelHandler");
// 최초 한번 사용? -> 계속 재사용~~
// 생성되어있으면 계속 그거 쓰고~~ 아니면 새로 생성~~

// sb.addChannelHandler('', ChannelHandler);

const token = '398ca6e96d7354c8c365d7c411dd800ef87567fa'
/*
  - channel create 과정은 서버에서 진행
  - 클라이언트
      1. sendbird와 connect
      2. 반환된 채널 url, userId 값을 받아서 open된 채널과 연결!
      - 접속과 채널 연결은 한번만 연결 -> getGroupChannel을 통해 받은 채널로 메시지 계속 전송
      3. sb.userMessageParams 를 사용하여 전송
*/

let sendBirdUser: any = null

export default {
  ChannelHandler() {
    return ChannelHandler
  },

  // async addChannelHandler(url = 'channelID'): Promise<void> {
//   sb.addChannelHandler(url, ChannelHandler);
// },
// 1. SendBird랑 연결 (이 때, userId값이 안넘어오면 새 사용자로 등록이 됨 (기존 ID는 직접 로그인 가능))
async connect(userId: any): Promise<void> {
    return new Promise((resolve) => {
      if (sendBirdUser) {
        resolve(sendBirdUser)
      } else {
        sb.connect(userId, (user: any, connectError: any) => {
          if (connectError) {
            // console.log('connect error', connectError);
            throw new Error('connect error')
          }
          // console.log("기존 sendbird connection X");
          sendBirdUser = user
          resolve(user)
        })
      }
    })
  },






  // 2. openChannel과 연결 (url을 통해서)
async getGroupChannel(channelUrl: string): Promise<any> {
    // // console.log('groupchannel-----------------------');
    // // console.log(channelUrl);

    return new Promise((resolve) => {
      sb.GroupChannel.getChannel(channelUrl, (openChannel, error) => {
        if (error) {
          // console.log('getChannel error', error);
          throw new Error('getChannel error')
        }
        // // console.log(openChannel);

        resolve(openChannel)
      })
    })
  },



// 5. 전송 과정 전체
async groupChannelSendMessage(channelUrl: string, message: string, status: string): Promise<any> {
    const params = new sb.UserMessageParams()
    params.message = message
    params.data = status

    const groupChannel = await this.getGroupChannel(channelUrl)
    return this.groupChannelSendUserMessage(groupChannel, params)
  },



// 3. 연결된 채널에 message 보내기
async groupChannelSendUserMessage(groupChannel: any, params: any): Promise<void> {
    // console.log(params);

    return new Promise((resolve) => {
      groupChannel.sendUserMessage(params, (userMessage: any, error: any) => {
        if (error) {
          // console.log('groupSendUserMessage error', error);
          throw new Error('groupSendUserMessage error')
        }

        // console.log(groupChannel.data);
        resolve(userMessage)
      })
    })
  },



onMessageReceived(channel: string, callback: any) {
    // // console.log(channel);
    ChannelHandler.onMessageReceived = (channel: any, message: any) => {
      callback(channel, message)
    }

    sb.addChannelHandler(channel, ChannelHandler)
  },




sb() {
    return sb
  },


  // 4. 닉네임 변경
async updateCurrentUserInfo(nickname: string): Promise<void> {
    return new Promise((resolve) => {
      sb.updateCurrentUserInfo(nickname.trim(), '', (response: any, error: any) => {
        if (error) {
          // console.log('updateCurrentUserInfo error', error);
          throw new Error('updateCurrentUserInfo error')
        }
        resolve()
      })
    })
  },

  async updateUserMessage(
    channelUrl: string, messageID: string, message: string,
    // data: string,
  ): Promise<any> {
    const params = new sb.UserMessageParams()

    params.message = message
    // params.data = data;

    const groupChannel = await this.getGroupChannel(channelUrl)

    return new Promise((resolve) => {
      groupChannel.updateUserMessage(messageID, params, function(message: any, error: any) {
        if (error) {
          // console.log('groupSendUserMessage error', error);
          throw new Error('groupSendUserMessage error')
        }

        // console.log(groupChannel.data);
        resolve(message)
      })
    })
  },

}

// channelHandler.onMessageReceived = (channel: any, message: any) => {
//   // console.log('메시지 수신');
//   // console.log(message);
//   getMessageList(openChannel);
// }
// channelHandler.onMessageUpdated = (channel: any, message: any) => {
//   // console.log('메시지 업데이트');
//   // console.log(message);
//   getMessageList(openChannel);
// }
// // 위에서 조회한 channelUrl
// sb.addChannelHandler(channelUrl, channelHandler);
