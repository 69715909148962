
import {defineComponent, onMounted, toRef, h, ref, watch, onBeforeUnmount, onBeforeMount} from 'vue'
import {AgToggleIcon, AgImg, AgText, AgBox} from '@/components/ag'
import {openLink} from '@/utils/filters'
import {} from '@ionic/vue'

export default defineComponent({
  components: {
    AgBox,
    AgImg,
    AgText,
    AgToggleIcon,

  },
  emits: ['agree'],
  name: 'TermsOfService',
  setup(props, {emit}) {
    const agreeAll = ref<any>(false)
    const agreeList = ref<any>([{
      link: 'https://www.notion.so/angelleague/484d0cf572b44e49974ebebf36b9783b',
      title: '엔젤리그 조합원 자격 거래 약관 (필수)',
      value: false,
    },
    {
      link: 'https://www.notion.so/angelleague/3541287d75ca43d8a8bea469ddda2a3d',
      title: '조합원 자격 거래 위험사항 고지 (필수)',
      value: false,
    },
    ])
    const actAgreeAll = () => {
      agreeAll.value = !agreeAll.value
      agreeList.value.map((a: any) => {
 a.value = agreeAll.value; return a.value
})
    }

    onBeforeMount(() => {
      emit('agree', false)
    })

    watch(agreeList, () => {
      if (agreeList.value.every((agree: any) => agree.value)) {
        agreeAll.value = true
        emit('agree', true)
      } else {
        agreeAll.value = false
        emit('agree', false)
      }
    }, {deep: true})

    return {
      actAgreeAll,
      agreeAll,
      agreeList,
      openLink,
    }
  },
})
