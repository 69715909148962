
import {IonPage, IonContent, modalController} from '@ionic/vue'
import stockInfo from '@/data/stock.json'
import homeMain from '@/data/homeMain.json'
import {computed, defineComponent, onBeforeMount, ref, onBeforeUpdate, getCurrentInstance} from 'vue'
import {useStore} from 'vuex'
import {onBeforeRouteLeave, useRouter} from 'vue-router'
import {clubDate, openLink} from '@/utils/filters'
import useModal from '@/composables/useModal'
import {AgImg, AgRefresher, AgBoxS, AgTextS} from '@/components/ag'
import NewBoardList from '@/components/Board/NewBoardList.vue'
import InvestStatus from '@/components/Home/InvestStatus.vue'
import LikeCompany from '@/components/Home/LikeCompany.vue'
import PopularShareTransactions from '@/components/Home/PopularShareTransactions.vue'
import StockItemTop from '@/views/home/components/StockItemTop.vue'
import StockItemEntire from '@/views/home/components/StockItemEntire.vue'
import {isPlatform} from '@/composables'
import {store} from '@/vuex'
import sellRegistModal from '@/components/Home/SellRegistModal.vue'
import StakeListsModal from '@/views/view/stake-lists/StakeListsModal.vue'
import PopularShareTransactionModal from '@/views/view/home/popular-share-transaction/PopularShareTransactionModal.vue'
import {Plugins} from '@capacitor/core'
import CollectClubDeals from '@/components/Modal/Home/CollectClubDeals.vue'
import {getPlatform} from '@/composables/platform'
const {Browser} = Plugins
const {openSellModal} = useModal()
export default defineComponent({
  components: {
    AgBoxS,
    AgImg,
    AgRefresher,
    AgTextS,
    CollectClubDeals,
    InvestStatus,
    IonContent,
    IonPage,
    NewBoardList,
    PopularShareTransactionModal,
    PopularShareTransactions,
    StakeListsModal,
    sellRegistModal,
  },
  data() {
    return {
      companyLogo: stockInfo.cLogo,
      homeMain: homeMain.menu,
      stocks: stockInfo.stock,
    }
  },
  name: 'Home',
  setup() {
    const platform = getPlatform()
    const app = getCurrentInstance()
    const $global = app && app.appContext.config.globalProperties.$global
    const {dispatch, getters} = useStore()
    const router = useRouter()
    const {useCollectPartnerships, viewKakaoKlipModal, openFAQModal, viewCollectClubdealsModal, useCompanyModal, useClubDealProgressModal} = useModal()
    const user = computed(() => getters['user/getUser'])
    const showBanner = computed(() => !isPlatform('hybrid').value)

    const token = computed(() => getters['user/getToken'])
    const homeClubDeals = computed(() => getters['home/getPartnershipsHome'] || [])

    const stockItems = computed(() => getters['stockItem/getStockItems'] || [])
    const currentPath = computed(() => window.location.pathname)

    // const stockItemTop3 = computed(() => getters['home/getStockItemTop3'] || [])
    // const popularItemTop3 = computed(() => getters['home/getPopularItemTop3'] || [])

    const likeStocks = computed(() => getters['home/getLikeStock'] || [])
    // const leadTop = computed(() => getters['home/getLeadTop'] || [])
    // const activeBoardTop = computed(() => getters['home/getActiveBoardTop'] || [])

    const likeStockItems = computed(() => getters['stockItem/getLikeStockItems'] || [])

    const stockItems10 = computed(() => stockItems.value.slice(0, 10) || [])

    const stockItemBoards = computed(() => getters['stockItem/getStockItemBoardsHome'])

    const toggleValue = ref<any>(false)

    // async function openSellModal() {
    //   const sellModal = await modalController
    //     .create({
    //       backdropDismiss: true,
    //       component: SellItemModal,
    //       cssClass: 'full-screen-modal',
    //       id: 'SellItemModal',
    //       // mode: 'ios',
    //       keyboardClose: true,
    //
    //       swipeToClose: true,
    //     })
    //
    //   return sellModal.present()
    // }

    const onClick = () => {
      router.push('/tabs/search/company')
    }

    const opinionIndex = ref<any>(0)
    const slideToggle = ref<any>(false)

    const loadBoard = () => {
      dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS_FOR_HOME', {page: 1})
    }

    onBeforeMount(() => {
      // console.log('home onBeforeMount!?')
      dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS')
      dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS')
      dispatch('home/loadData')
      loadBoard()
      slideToggle.value = false
    })

    onBeforeUpdate(() => {
      if (currentPath.value === '/tabs/home') {
        slideToggle.value = false
      }
    })

    onBeforeRouteLeave(() => {
      slideToggle.value = true
    })

    // watch(leadTop, () => {
    //   // console.log(leadTop.value);
    // })

    const itemLike = async (stockItemId: any) => {
      await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
    }

    const itemLikeCompany = async (stockItemId: any) => {
      await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
    }

    const doRefresh = async (event: any) => {
      // console.log('Begin async operation');
      await Promise.all(
        [
          store.dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS'),
          store.dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS'),
          store.dispatch('home/loadData'),
          store.dispatch('asset/loadData'),
          loadBoard(),
        ],
      )
      event.target.complete()
    }

    const openItemTopModal = async (type: string) => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: StockItemTop,
          componentProps: {
            type,
          },
          cssClass: 'home-modal',
          id: 'ItemTop',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      return modal.present()
    }

    const openItemEntireModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: StockItemEntire,
          cssClass: 'home-modal',
          id: 'ItemTop',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      return modal.present()
    }

    const sellModalOpen = ref<boolean>(false)
    const stakeListOpen = ref<boolean>(false)
    const viewShareTransactionOpen = ref<boolean>(false)
    const clubDealAllOpen = ref<boolean>(false)

    function clickHomeBanner(index: number) {
      switch(index) {
        case 0:
          useCollectPartnerships()
          break
        case 1:
          // sellModalOpen.value = true
          user.value === null ? $global.openLoginModal() : openSellModal()
          break
        case 2:
          // sellModalOpen.value = true
          Browser.open({url: 'https://angelleague.notion.site/5dfa09f455914adeb7324397e1d08103'})
          break
      }
    }

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }

    //gtm event screen name
    const clickScreen = 'home'

    return {
      clickHomeBanner,
      clickScreen,
      clubDate,
      clubDealAllOpen,
      doRefresh,
      homeClubDeals,
      itemLike,
      
      itemLikeCompany,
      // leadTop,
likeStockItems,
      likeStocks,
      onClick,
      openChannelTalk,
      openFAQModal,
      openItemEntireModal,
      openItemTopModal,
      openLink,
      openSellModal,
      opinionIdx: opinionIndex,
      platform,
      router,
      sellModalOpen,


      showBanner,
      stakeListOpen,
      


      stockItemBoards,
      // activeBoardTop,
// popularItemTop3,
// stockItemTop3,
stockItems,
      stockItems10,
      toggleValue,
      token,
      useClubDealProgressModal,
      useCompanyModal,
      user,
      viewCollectClubdealsModal,
      viewKakaoKlipModal,
      viewShareTransactionOpen,
    }
  },

})
