import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_alert = _resolveComponent("ag-alert")!

  return (_openBlock(), _createBlock(_component_ag_alert, _mergeProps({ "model-value": _ctx.showNotification }, _ctx.notificationRef, {
    buttons: [_ctx.noButton, _ctx.yesButton],
    "backdrop-dismiss": false,
    "css-class": "update-alert",
    "onUpdate:modelValue": _ctx.closeNotification
  }), null, 16, ["model-value", "buttons", "onUpdate:modelValue"]))
}