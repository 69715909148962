import {Plugin} from 'vue'
import {getPlatform} from '@/composables/platform'
import {storageRef} from '@/composables'

export interface UserInfo {
  userId?: number
}

const platform = getPlatform()

export const gtm: Plugin = (app) => {
  window.dataLayer = window.dataLayer || []
  const userRef = storageRef<undefined | UserInfo>('user')
  const uid = userRef.value?.userId
  
  window.dataLayer.push({appName: platform}, {uid})
  app.config.globalProperties.$dataLayer = window.dataLayer

}

export default gtm
