
import {AgBox, AgBoxS, AgImg, AgButton, AgText, AgTextS, AgContent, AgFooter} from '@/components/ag'
import {computed, defineComponent, watch, defineAsyncComponent, ref, toRef} from 'vue'
import {alertController, modalController, toastController} from '@ionic/vue'
import {useStore} from 'vuex'
import {requestPurchase} from '@/api/stake-deal'
import {useRouter} from 'vue-router'
import {useLegacyClipboard} from '@/composables'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgImg,
    AgText,
    AgTextS,
  },
  emits: ['close', 'goToNext', 'buyDone'],
  props: {
    // dealInfo: {type: Object},
    modelValue: {type: Boolean},
  },
  setup(props, {emit}) {

    const {getters, dispatch} = useStore()
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])

    async function cancelToBuy() {
      await dispatch('stakeDeal/GET_ALL_STAKELIST')
      emit('close')
    }

    return {
      cancelToBuy,
      dealInfo,
    }
  },
})
