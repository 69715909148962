
import {AgBox, AgButton, AgImg, AgText, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {defineComponent, toRef} from 'vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {getResultOfRegisterStake} from '@/api/stake-deal'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgImg,
    AgModal,
    AgText,
    ModalHeader,
  },
  emits: ['close', 'fail'],
  name: 'SellRegistResult',
  props: {
    isOpen: {require: true, type: Boolean},
    requestId: {type: Number},
  },
  setup(props, {emit}) {
    const {commit} = useStore()
    const isOpenRef = toRef(props, 'isOpen')
    const requestIdRef = toRef(props, 'requestId')

    const setOpen = async () => {
      const result = await getResultOfRegisterStake(requestIdRef.value)
      if (result) {
        result.type = 'register'
        await commit('stakeDeal/SET_REGISTER_RESULT', result)
        if (result.status) {
emit('close')
} else {
emit('fail')
}
      }
    }

    return {isOpenRef, requestIdRef, setOpen}
  },
})
