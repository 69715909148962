
import {computed, defineComponent, ref, defineAsyncComponent} from 'vue'
import {IonPage} from '@ionic/vue'
import {AgHeader, AgText, AgContent, AgButton, AgModal, AgBox, AgToggleIcon} from '@/components/ag'
import {AgImg} from '@/components/ag/ag-img'
import {AgSignaturePad} from '@/components/ag/ag-signature-pad'
import {InAppBrowser} from '@ionic-native/in-app-browser'

const modalPages = {
  bar: {
    component: defineAsyncComponent(() => import('./modal-pages/Bar.vue')),
    height: '200px',
  },
  foo: {
    component: defineAsyncComponent(() => import('./modal-pages/Foo.vue')),
    height: '500px',
  },
}

export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgHeader,
    AgImg,
    AgModal,
    AgSignaturePad,
    AgText,
    AgToggleIcon,
    IonPage,
  },
  name: 'Test2Page',
  setup: () => {
    const modalPageName = ref<ModalPageName>('foo')
    const isOpen = ref(false)
    const modalPage = computed(() => {
      return modalPages[modalPageName.value]
    })
    const height = computed(() => {
      return modalPage.value.height
    })
    const padHeight = ref(200)
    const padToggle = ref(true)
    const padToggleWidth = ref('100%')
    const lineMaxWidth = ref(2)
    const lineColor = ref('white')
    const isEmpty = ref(true)
    const isWebKit = ref<any>(false)
    const messageRef = ref('message')

    const toggleWidth = () => {
      if (padToggleWidth.value === '100%') {
        padToggleWidth.value = '200px'
        return
      }
      padToggleWidth.value = '100%'
    }

    const onMessage = () => {
      if (window.webkit) {
        isWebKit.value = webkit?.messageHandlers?.cordova_iab
        webkit.messageHandlers.cordova_iab.postMessage('hi!!!!!')
      }
    }

    const toggleLineColor = () => {
      if (lineColor.value === 'white') {
        lineColor.value = 'black'
        return
      }
      lineColor.value = 'white'
    }

    const openBrowser = () => {
      // console.log(cordova)
      const ref = InAppBrowser.create('http://google.com', '_blank', 'location=yes')
      // ref.on('message').subscribe({
      //   complete: () => {
      //     messageRef.value = JSON.stringify('complete')
      //   },
      //   error: (error) => {
      //     messageRef.value = JSON.stringify(error)
      //   },
      //   next(data) {
      //     messageRef.value = JSON.stringify(data)
      //   },
      // })
      // ref.on('customscheme').subscribe((data) => {
      //   console.log(data)
      //   messageRef.value = JSON.stringify(data)
      // })
      // ref.on('loadstop').subscribe((data) => {
      //   console.log(data)
      //   messageRef.value = JSON.stringify(data)
      // })
      ref.show()

      setTimeout(() => {
        console.log('closeeeeeeeee')
        ref.close()
      }, 10000)
    }

    const go = (url: ModalPageName) => {
      modalPageName.value = url
    }

    return {
      go,
      height,
      isEmpty,
      isOpen,
      isWebKit,
      lineColor,
      lineMaxWidth,
      messageRef,
      modalPage,
      modalPageName,
      modalPages,
      onMessage,
      openBrowser,
      padHeight,
      padToggle,
      padToggleWidth,
      toggleLineColor,
      toggleWidth,
    }
  },
})
