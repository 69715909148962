import {Property} from 'csstype'
import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'

export const textSystems = system({
  letterSpacing: true,
  textAlign: true,
  textDirection: {
    property: 'direction',
  },
  whiteSpace: true,
  wordBreak: true,
  writingMode: true,
})

export const textProps = {
  letterSpacing: getResponsiveProp<Property.LetterSpacing>(),
  textAlign: getResponsiveProp<Property.TextAlign>(),
  textDirection: getResponsiveProp<Property.Direction>(),
  whiteSpace: getResponsiveProp<Property.WhiteSpace>(),
  wordBreak: getResponsiveProp<Property.WordBreak>(),
  writingMode: getResponsiveProp<Property.WritingMode>(),
}
