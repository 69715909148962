
import {IonPage, IonContent, modalController, IonRippleEffect, alertController} from '@ionic/vue'
import {defineComponent, onBeforeMount, computed, inject} from 'vue'
import {useRouter} from 'vue-router'
import {chevronBack} from 'ionicons/icons'
import useModal from '@/composables/useModal'
import {Plugins} from '@capacitor/core'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonRippleEffect,
    // IonTitle,
    // IonToolbar, IonHeader, IonBackButton, IonButtons,
  },
  name: 'Settings',
  setup() {
    const router = useRouter()
    const {openFAQModal} = useModal()
    const {Browser} = Plugins

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }
    
    const clickScreen = 'setting'
    return {chevronBack, clickScreen, openChannelTalk, openFAQModal, router}
  },
})

