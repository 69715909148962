import CallApi from '../utils/CallApi'
import {toastController} from '@ionic/vue'

export default function() {
  const getPoint = async () => {
    let point: any = 0
    try {
      point = await CallApi({
        method: 'get',
        url: '/user/point',
      })
    } catch {
      // console.log(error);
    }

    return point
  }

  const getInviteCode = async () => {
    let code: any = ''
    try {
      code = await CallApi({
        method: 'get',
        url: '/user/invite-info',
      })
    } catch {
      // console.log(error);
    }

    return code
  }

  const getTotalPoint = async () => {
    let point: any = []
    try {
      point = await CallApi({
        method: 'get',
        url: '/user/point/total',
      })
    } catch {
      // console.log(error);
    }

    return point
  }

  const getSaveHistory = async () => {
    let saveHistory: any = []
    try {
      saveHistory = await CallApi({
        method: 'get',
        url: '/user/point/save',
      })
    } catch {
      // console.log(error);
    }

    return saveHistory
  }

  const getUseHistory = async () => {
    let useHistory: any = []
    try {
      useHistory = await CallApi({
        method: 'get',
        url: '/user/point/except-save',
      })
    } catch {
      // console.log(error);
    }

    return useHistory
  }

  const sendInvite = async (inviteCode: string) => {
    const r = {msg: '', points: 0, result: false}
    try {
      const res: any = await CallApi({
        data: {
          inviteCode: inviteCode,
        },
        method: 'post',
        url: '/user/invite-code',
      })
      r.result = true
      r.msg = '등록 되었습니다.'
      r.points = res.point
    } catch (error) {
      console.log('error----', error)
      r.result = false
      r.msg = error.response.data.message
    }
    return r
  }

  const sendPromotion = async (promotionCode: string) => {
    const r = {msg: '', result: false}
    try {
      const res: any = await CallApi({
        data: {
          promotionCode: promotionCode,
        },
        method: 'post',
        url: '/user/promotion-code',
      })
      r.result = true
      r.msg = '등록 되었습니다.'
    } catch (error) {
      r.result = false
      r.msg = error.response.data.message
    }
    return r
  }

  const loadSaveHistory = async (pageNum: number) => {
    let savePointList: any = []
    try {
      savePointList = await CallApi({
        method: 'get',
        params: {
          page: pageNum,
        },
        url: '/user/point/save',
      })
    } catch {
      // console.log(error);
    }
    return savePointList.savePointList
  }

  const loadUseHistory = async (pageNum: number) => {
    let exceptSavePointList: any = []
    try {
      exceptSavePointList = await CallApi({
        method: 'get',
        params: {
          page: pageNum,
        },
        url: '/user/point/except-save',
      })
    } catch {
      // console.log(error);
    }
    return exceptSavePointList.exceptSavePointList
  }
  
  const getSharePointsLink = async () => {
    const sharePointsLink: any = await CallApi({
      method: 'get',
      url: '/user/share-points-link',
    })
    
    console.log(sharePointsLink)
    
    return `https://abr.ge/${sharePointsLink?.abrgeInviteLink}`
  }

  return {
    getInviteCode,
    getPoint,
    getSaveHistory,
    getSharePointsLink,
    getTotalPoint,
    getUseHistory,
    loadSaveHistory,
    loadUseHistory,
    sendInvite,
    sendPromotion,
  }
}
