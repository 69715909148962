import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { translucent: true }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "vetical-btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal()))
      }, " 편집하기 "),
      _createElementVNode("div", {
        class: "vetical-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.sureDelete();})
      }, " 삭제하기 ")
    ]),
    _: 1
  }))
}