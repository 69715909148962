import {RouteRecordRaw} from 'vue-router'

export const home: RouteRecordRaw[] = [
  {
    name: 'home',
    path: '',
    redirect: '/tabs/home',
  },
  {
    component: process.env.VUE_APP_UI_MODE === 'ios-pass' ? () => import('@/views/home/Index2.vue') : () => import('@/views/home/Index.vue'),
    // component: () => import('@/views/test/Test.vue'),
    path: 'home',
    props: (to) => {
      const {query} = to
      return {
        isOpenClubDealAll: query.isOpenClubDealAll === 'true',
        isOpenShareTransaction: query.isOpenShareTransaction === 'true',
      }
    },
  },
]
