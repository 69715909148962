
import {defineComponent, getCurrentInstance, onMounted} from 'vue'
import {AgBox, AgButton, AgText, AgContent, AgFooter} from '@/components/ag'
import {} from '@ionic/vue'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgText,
  },
  emits: ['close'],
  name: 'FailToRegist',
  setup(props, {emit}) {
    const app = getCurrentInstance()
    const $modalOpenEvent = app && app.appContext.config.globalProperties.$modalOpenEvent
    const clickScreen = 'my-partnership-share-sell-fail-modal'
    onMounted(()=>{
      $modalOpenEvent({page: clickScreen, title: '지분거래 등록 확인 실패'})
    })
    const setOpen = () => emit('close')

    return {clickScreen, setOpen}
  },
})
