import { resolveComponent as _resolveComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!

  return (_openBlock(), _createBlock(_component_ion_searchbar, {
    placeholder: _ctx.text.placeholder,
    value: _ctx.modelValue,
    debounce: _ctx.debounce,
    enterkeyhint: _ctx.enterkeyhint,
    onIonChange: _ctx.onChange,
    onIonClear: _ctx.onClear,
    onKeydown: _withKeys(_ctx.onSubmit, ["enter"])
  }, null, 8, ["placeholder", "value", "debounce", "enterkeyhint", "onIonChange", "onIonClear", "onKeydown"]))
}