import {Module} from 'vuex'
import {RootState} from '..'
import apiStockOption from '@/api/stock-option'
const {getCalcInfo} = apiStockOption()

// state
interface StockItem {
  stockCalc: any[]
}

// action
const StockItem: Module<StockItem, RootState> = {
  
actions: {
    async GET_STOCK_CALCINFO({commit}, calcInfo) {
      const result = await getCalcInfo(calcInfo)
      commit('SET_STOCK_CALCINFO', result.stockOptionCalc)
    },
  },
  

getters: {
    getCalcInfo: (state) => state.stockCalc,
  },
  
  // RootState와 똑같이 작성해도 된다.
mutations: {
    SET_STOCK_CALCINFO(state, stockCalc: any) {
      state.stockCalc = stockCalc
    },
  },
  namespaced: true,
  state: {
    stockCalc: [],
  },
}

export default StockItem
