import {RadiiType} from '@/plugins/emotion'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'

export const cornerSystems = system({
  corner: {
    property: 'borderRadius',
    scale: 'radii',
  },
  cornerBottomLeft: {
    property: 'borderBottomLeftRadius',
    scale: 'radii',
  },
  cornerBottomRight: {
    property: 'borderBottomRightRadius',
    scale: 'radii',
  },
  cornerTopLeft: {
    property: 'borderTopLeftRadius',
    scale: 'radii',
  },
  cornerTopRight: {
    property: 'borderTopRightRadius',
    scale: 'radii',
  },
})

export const cornerProps = {
  corner: getResponsiveProp<RadiiType>(),
  cornerBottomLeft: getResponsiveProp<RadiiType>(),
  cornerBottomRight: getResponsiveProp<RadiiType>(),
  cornerTopLeft: getResponsiveProp<RadiiType>(),
  cornerTopRight: getResponsiveProp<RadiiType>(),
}
