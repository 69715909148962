
import {defineComponent, ref} from 'vue'
import {IonLabel, IonSegment, IonSegmentButton, modalController} from '@ionic/vue'
import {toMutRef} from '@/composables'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

export default defineComponent({
  components: {
    IonLabel, IonSegment, IonSegmentButton,
  },
  name: 'ViewOptionModal',
  props: {
    requestType: {required: true, type: String},
    viewOption: String,
    viewType: String,
  },
  setup(props) {
    const viewOption = toMutRef(props, 'viewOption')
    const viewType = toMutRef(props, 'viewType')
    const typeRequest = toMutRef(props, 'requestType')

    const modalTitle: {[key: string]: string} = {
      'wallet': '보기옵션',
    }
    const clickScreen = `view-option-${typeRequest.value}`
    const gtm = useGtm()
    gtm?.trackEvent({
      event: MODAL_OPEN_EVENT,
      page: 'view-option-modal',
      title: `${modalTitle[typeRequest.value]} 보기 옵션 모달`,
    })



    const dismiss = () => {
      const data = {
        viewOption: viewOption.value,
        viewType: viewType.value,
      }
      return modalController.dismiss(data)
    }

    const segmentChanged = (event_: any, type: string) => {
      if (type === 'option') {
        viewOption.value = event_.detail.value
      } else {
        viewType.value = event_.detail.value
      }
    }

    return {clickScreen, dismiss, segmentChanged, typeRequest}
  },
})
