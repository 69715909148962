import CallApi from '../utils/CallApi'

export default function() {
	return {
		
		notAcceptableTest: async () => {
			let result: any = []
			result = await CallApi({
				method: 'get',
				url: '/test',
			})
			return result
		},
	}
}
