import {InAppBrowser, InAppBrowserEvent, InAppBrowserObject} from '@ionic-native/in-app-browser'
import {MayRef, unwrapRef, wrapRef} from '@winter-love/use'
import {v1 as uuid} from 'uuid'
import {computed, onUnmounted, ref, Ref, watch} from 'vue'
import {getPlatform} from '../platform'

const _isNative = () => {
  return getPlatform() !== 'web'
}

export type InAppBrowserTarget = '_black' | '_system'
export type OnMessageHandel = (data: Record<string, any>) => any
export type LoadStopHandel = (data: InAppBrowserEvent) => any
export type LoadStartHandel = (data: InAppBrowserEvent) => any

export const useInAppBrowserPostMessage = () => {
  const nativePostMessage = (message: Record<string, any>) => {
    window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({...message}))
  }

  const browserPostMessage = (message: Record<string, any>) => {
    window.postMessage({
      ...message,
      __fingerprint__: true,
    }, '*')
  }

  const postMessage = (message: Record<string, any>) => {
    if (window.webkit) {
      nativePostMessage(message)
      return
    }

    browserPostMessage(message)
  }

  return {
    postMessage,
  }
}

const booleanYes = (value: boolean | undefined): 'yes' | 'no' | undefined => {
  if (typeof value === 'undefined') {
    return value
  }
  return value ? 'yes' : 'no'
}

export interface UseInAppBrowserOpenOptions {
  /**
   * clear cookie cache before the new window is opened
   * native android, ios only
   */
  clearCache?: boolean
  /**
   * clear entire local storage (cookies, HTML5 local storage, IndexedDB, etc.) before the new window is opened
   * native ios only
   */
  cleardata?: boolean
  /**
   * native android
   * @default true
   */
  fullscreen?: boolean
  /**
   * @default true
   * native android
   */
  hardwareBack?: boolean
  /**
   * @default true
   */
  hideNavigationButtons?: boolean
  /**
   * native android only
   * @default true
   */
  hideUrlBar?: boolean
  location?: boolean

  presentationStyle?: 'pagesheet' | 'formsheet' | 'fullscreen'
  toolbar?: boolean
  toolbarPosition?: 'top' | 'bottom'
  transitionStyle?: 'fliphorizontal' | 'crossdissolve' | 'coververtical'
  /**
   * @default true
   */
  useWkWebView?: boolean
  /**
   * show Android browser's zoom controls
   * native android
   * @default false
   */
  zoom?: boolean
}

export interface InjectScript {
  code?: string
  file?: string
}

const stringRefTrim = (value?: Ref<string> | string) => {
  return unwrapRef(value)?.trim()
}

// eslint-disable-next-line max-lines-per-function
export const useInAppBrowser = (
  url?: MayRef<string>,
  target?: MayRef<InAppBrowserTarget | undefined>,
  options: UseInAppBrowserOpenOptions = {},
) => {
  const {
    hideUrlBar = true,
    hideNavigationButtons = true,
    clearCache,
    useWkWebView = true,
    hardwareBack = true,
    zoom = false,
    fullscreen = false,
    transitionStyle,
    toolbar = false,
    location = false,
    toolbarPosition = 'top',
    presentationStyle = 'pagesheet',
  } = options
  const urlRef = wrapRef(url)
  const targetRef = wrapRef(target)
  const inAppBrowserRef = ref<undefined | InAppBrowserObject>()
  const windowRef = ref<undefined | Window>()
  const messageHandelRef = ref<undefined | OnMessageHandel>()
  const loadStopHandelRef = ref<undefined | LoadStopHandel>()
  const loadStartHandelRef = ref<undefined | LoadStartHandel>()
  const isOpenRef = ref(false)
  const blockIsOpenRef = ref(false)
  const windowCloseIntervalFlag = ref<any | undefined>()

  const isNative = computed(() => {
    return _isNative()
  })

  const messageHandel = (data: Record<string, any>) => {
    const messageHandel = messageHandelRef.value
    if (messageHandel) {
      messageHandel(data)
    }
  }

  const nativeOpen = (url?: string, injectScript?: InjectScript) => {

    const _url = url ?? urlRef.value

    if (!_url) {
      return false
    }

    const ref = InAppBrowser.create(_url?.trim(), targetRef.value, {
      clearcache: booleanYes(clearCache),
      fullscreen: booleanYes(fullscreen),
      hardwareback: booleanYes(hardwareBack),
      hidenavigationbuttons: booleanYes(hideNavigationButtons),
      hideurlbar: booleanYes(hideUrlBar),
      location: booleanYes(location),
      presentationstyle: presentationStyle,
      toolbar: booleanYes(toolbar),
      toolbarposition: toolbarPosition,
      transitionstyle: transitionStyle,
      // 이건 뭐더라?
      usewkwebview: booleanYes(useWkWebView),
      zoom: booleanYes(zoom),
    })

    ref.on('message').subscribe((event: any) => {
      messageHandel(event.data)
    })

    let executed = false

    ref.on('loadstop').subscribe((data: any) => {
      const loadStopHandel = loadStopHandelRef.value
      if (loadStopHandel) {
        loadStopHandel(data)
      }
      if (!executed && injectScript) {
        ref.executeScript(injectScript)
        executed = true
      }

    })

    ref.on('loadstart').subscribe((data: any) => {
      const loadStartHandel = loadStartHandelRef.value
      if (loadStartHandel) {
        loadStartHandel(data)
      }
    })

    ref.on('exit').subscribe(() => close())

    ref.show()

    inAppBrowserRef.value = ref

    return true
  }

  const onBrowserMessage = (event: MessageEvent<any>) => {
    const {
      __fingerprint__,
      ...restData
    } = event.data ?? {}
    if (__fingerprint__) {
      messageHandel(restData)
    }
  }

  const onBrowserLoad = () => {
    windowRef.value?.addEventListener('unload', close)
  }

  const browserOpen = (url?: string) => {

    const _url = url ?? urlRef.value

    if (!_url) {
      return false
    }

    const ref = window.open(_url, uuid())

    if (!ref) {
      return false
    }

    ref.location.assign(_url)

    // window close 이벤트 안와서 만들없습니다.
    windowCloseIntervalFlag.value = setInterval(() => {
      if (ref.closed) {
        close()
      }
    }, 1000)

    ref.addEventListener('message', onBrowserMessage)

    ref.addEventListener('load', onBrowserLoad)

    ref.addEventListener('close', close)

    windowRef.value = ref

    return true
  }

  const open = async (url?: string | undefined, blockWatch: boolean = true, injectCode?: any) => {
    if (blockWatch) {
      blockIsOpenRef.value = true
    }
    if (windowRef.value || inAppBrowserRef.value || isOpenRef.value) {
      close()
    }

    if (_isNative()) {
      isOpenRef.value = nativeOpen(url, injectCode)
    } else {
      isOpenRef.value = browserOpen(url)
    }

  }

  const close = () => {
    if (!windowRef.value && !inAppBrowserRef.value) {
      return
    }

    const inAppBrowser = inAppBrowserRef.value
    const _window = windowRef.value
    if (inAppBrowser) {
      inAppBrowser.close?.()
    } else if (_window) {
      // _window.removeEventListener?.('message', onBrowserMessage)
      // _window.removeEventListener?.('load', onBrowserLoad)
      // _window.removeEventListener?.('close', close)
      // _window.removeEventListener?.('unload', close)
      try {
        // _window.close?.()
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          // dev mode only
          // eslint-disable-next-line no-console
          console.warn(error)
        }
      }
    }
    inAppBrowserRef.value = undefined
    windowRef.value = undefined
    isOpenRef.value = false
    clearInterval(windowCloseIntervalFlag.value)
    windowCloseIntervalFlag.value = undefined
  }

  onUnmounted(() => {
    close()
  })

  watch(isOpenRef, (value: boolean) => {
    if (blockIsOpenRef.value) {
      blockIsOpenRef.value = false
      return
    }
    if (value) {
      open()
    } else {
      close()
    }
  })

  const onMessage = (hook: OnMessageHandel) => {
    messageHandelRef.value = hook
  }

  const hide = () => {
    inAppBrowserRef.value?.hide?.()
  }

  return {
    close,
    hide,
    isNative,
    isOpen: isOpenRef,
    onMessage,
    open,
  }
}
