
import {
  IonPage,
  IonContent,
    IonIcon,
  modalController, toastController,
} from '@ionic/vue'
import {computed, defineComponent, ref, onBeforeMount} from 'vue'
import {AgBoxS, AgTextS, AgImg, AgButtonS, AgRefresher} from '@/components/ag'
import {useStore} from 'vuex'
import apiPoint from '../../../api/user-point'
const {loadSaveHistory, loadUseHistory} = apiPoint()
import {chevronBack} from 'ionicons/icons'
import {useRouter} from 'vue-router'
import {getFormattedDate} from '@/utils/filters'
import WalletViewOptionModal from '@/views/MyPage/wallet/modal/wallet-view-option-modal.vue'
import WalletRequestModal from '@/views/MyPage/wallet/modal/wallet-request-modal.vue'
import useAlert from '@/composables/useAlert'
import apiWallet from '@/api/wallet'
export default defineComponent({
  components: {
    AgBoxS,
    AgButtonS,
    AgImg,
    AgRefresher,
    AgTextS,
    IonContent,
    IonIcon,
    IonPage, WalletRequestModal, WalletViewOptionModal,
  },
  setup() {
    const {getters, commit, dispatch, state} = useStore()
    const router = useRouter()
    const depositRequested = ref<boolean>(false)
    const withdrawRequested = ref<boolean>(false)
    const viewOption = ref<string>('전체')
    const viewType = ref<any>('최신순')
    const transactionHistoryData = computed(() => getters['wallet/getTransactionHistory']||[])
    const walletInfo = computed(() => getters['wallet/getWalletInfo']|| {})
    const depositRequestInfo = ref<any>({})
    const withdrawProhibit = ref<any>(null)
    const withdrawRequestInfo = ref<any>({})
    const getDepositRequested = async () => {
      const result: any = await apiWallet().getDepositInfo()
      depositRequestInfo.value = result.depositRequest
      depositRequested.value = Boolean(depositRequestInfo.value)
    }

    const getWithdrawProhibit = async () => {
      const result: any = await apiWallet().withdrawRequestCheck()
      if(result.amount){
        withdrawProhibit.value = result
      }else{
        withdrawProhibit.value = null
      }
    }
    const getWithdrawRequested = async () => {
      const result: any = await apiWallet().getWithdrawInfo()
      withdrawRequestInfo.value = result.withdrawRequest
      withdrawRequested.value = Boolean(withdrawRequestInfo.value)
    }

    const initThisPage = async () => {
      await dispatch('wallet/ACT_GET_WALLET_INFO')
      await getDepositRequested()
      await getWithdrawRequested()
    }
    onBeforeMount(async ()=>{
      await initThisPage()
    })


    const doRefresh = async (event: any) => {
      await initThisPage()
      event.target.complete()
    }

    const transactionHistory = computed(()=>{

      let array = [...transactionHistoryData.value]

      if(viewOption.value === '사용') {
        array = array.filter((a:any)=>{
          return a.typeDetail === 'use'
        })
      }else if(viewOption.value === '충전') {
        array = array.filter((a:any)=>{
          return a.transactionType === 'in'
        })
      }else if(viewOption.value === '출금') {
        array = array.filter((a:any)=>{
          return a.typeDetail === 'withdraw'
        })
      }
      if (viewType.value === '과거순') {
        array = array.sort(function(a: any, b: any) {
          return (a.createdAt < b.createdAt ? 1 : -1)
        })
      }
      array = array.sort(function(a: any, b: any) {
        return (a.createdAt < b.createdAt ? -1 : 1)
      })
      const result = array.reduce((r: any, a: any) => {
        const {transactionAt} = a
        let monthyear = transactionAt.substring(0, 4)
        if(!r[monthyear]) {
          r[monthyear] = [a]
        } else {
          r[monthyear].push(a)
        }
        return r
      }, {})

      return result
    })

    const depositRequestOpen = ref<boolean>(false)
    const branchResult = async () => {
      await dispatch('wallet/ACT_GET_WALLET_INFO')
      await getDepositRequested()
      await getWithdrawRequested()
      depositRequestOpen.value = false
    }

    const createModal = async () => {
      const modal = await modalController
          .create({
            backdropDismiss: true,
            component: WalletViewOptionModal,
            componentProps: {
              requestType: 'wallet',
              viewOption: viewOption.value,
              viewType: viewType.value,
            },
            cssClass: 'input-modal',
            id: 'ViewOptionModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

      modal.onDidDismiss().then(async (data) => {
        viewOption.value = await data.data.viewOption
        viewType.value = await data.data.viewType
      })

      return modal.present()
    }

    const openType = ref<string>('')
    const cancelDeposit = async ()=> {
      await apiWallet().deleteDeposit()
      await getDepositRequested()
      await dispatch('wallet/ACT_GET_WALLET_INFO')
    }
    const cancelWithdraw = async ()=> {
      await apiWallet().deleteWithdraw()
      await getWithdrawRequested()
      await dispatch('wallet/ACT_GET_WALLET_INFO')
    }
    const {watingWithdrawAlert, cancelDepositAlert, wating24HoursAlert, notEnoughAmountAlert, cancelWithdrawAlert, limitDepositAlert} = useAlert()
    const callWalletFunction = async (target:string) =>{
      if(target==='deposit'){
        if(depositRequested.value){
          return cancelDepositAlert(cancelDeposit)
        }else if(withdrawRequested.value){
          return watingWithdrawAlert()
        }else if(walletInfo.value.currentBalance>=10000000){
          return limitDepositAlert()
        }
      }else if (target==='withdraw'){
        await getWithdrawProhibit()
        if(withdrawRequested.value){
          return cancelWithdrawAlert(cancelWithdraw)
        }else if(withdrawProhibit.value){
          const {amount, dateTime, type} = withdrawProhibit.value
          return wating24HoursAlert(dateTime, type, amount)
        }else if(walletInfo.value.currentBalance<=1000){ //출금 가능 금액 부족
          return notEnoughAmountAlert()
        }
      }

      openType.value = target
      depositRequestOpen.value = true

    }

    const clickScreen = 'wallet'

    return {
      branchResult,
      callWalletFunction,
      chevronBack,
      clickScreen,
      createModal,
      depositRequestOpen,
      depositRequested,
      doRefresh,
      getFormattedDate,
      openType,
      router,
      transactionHistory,
      transactionHistoryData,
      viewOption,
      viewType,
      walletInfo,
      withdrawRequested,
    }
  },
})
