import {Deploy} from 'cordova-plugin-ionic'
import {ISnapshotInfo} from 'cordova-plugin-ionic/www/IonicCordova'
import {ref} from 'vue'

export type State = 'idle' | 'should-update' | 'should-extract' | 'should-reload' | 'checking-update'
  | 'downloading' | 'sync'

/**
 * 라이브 업데이트 확인용
 */
export const useLiveUpdate = () => {
  const stateRef = ref<State>('idle')
  const downloadProgressRef = ref<number | undefined>()
  const extractProgressRef = ref<number | undefined>()

  const config = (channel = 'Production') => {
    return Deploy.configure({channel})
  }

  const downloadUpdate = async () => {
    if (stateRef.value !== 'should-update') {
      return
    }
    stateRef.value = 'downloading'
    const result = await Deploy.downloadUpdate((progress) => {
      downloadProgressRef.value = progress
    })

    if (result) {
      stateRef.value = 'should-extract'
    } else {
      stateRef.value = 'should-update'
    }

    return result
  }

  const extractUpdate = async () => {
    if (stateRef.value !== 'should-extract') {
      return
    }

    const result = await Deploy.extractUpdate((progress) => {
      extractProgressRef.value = progress
    })

    if (result) {
      stateRef.value = 'should-reload'
    } else {
      stateRef.value = 'should-extract'
    }

    return result
  }

  const reloadForUpdate = async () => {
    if (stateRef.value !== 'should-reload') {
      return
    }

    await Deploy.reloadApp()
    stateRef.value = 'idle'
  }

  const checkForUpdate = async (channel?: string) => {
    if (stateRef.value !== 'idle') {
      return
    }
    stateRef.value = 'checking-update'
    await config(channel)
    const state = await Deploy.checkForUpdate()
    if (state.available) {
      stateRef.value = 'should-update'
    } else {
      stateRef.value = 'idle'
    }
    return state
  }

  const sync = (): Promise<ISnapshotInfo | undefined> => {
    if (stateRef.value !== 'idle') {
      return Promise.resolve(undefined)
    }
    stateRef.value = 'sync'
    return Deploy.sync({updateMethod: 'auto'})
  }

  return {
    checkForUpdate,
    downloadProgress: downloadProgressRef,
    downloadUpdate,
    extractProgress: extractProgressRef,
    extractUpdate,
    reloadForUpdate,
    state: stateRef,
    sync,
  }
}
