
import {AgBox, AgBoxS, AgImg, AgButton, AgText, AgTextS, AgContent, AgFooter} from '@/components/ag'
import {computed, defineComponent, watch, defineAsyncComponent, ref, toRef} from 'vue'
import {alertController, modalController, toastController} from '@ionic/vue'
import {useStore} from 'vuex'
import {requestPurchase} from '@/api/stake-deal'
import {useRouter} from 'vue-router'
import {useLegacyClipboard} from '@/composables'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgImg,
    AgText,
    AgTextS,
  },
  emits: ['close', 'goToNext', 'buyDone'],
  props: {
    // dealInfo: {type: Object},
    modelValue: {type: Boolean},
  },
  setup(props, {emit}) {

    const gtm = useGtm()
    const {getters, dispatch} = useStore()
    const completePurchaseInfo = computed(() => getters['stakeDeal/completePurchaseInfo'])
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const router = useRouter()

    watch(completePurchaseInfo, ()=>{
      gtm?.trackEvent({
        event: MODAL_OPEN_EVENT,
        page: `purchase-complete/${dealInfo.value.partnershipShareSellId}`,
        title: `${dealInfo.value.partnershipName} 지분거래 완료 후 구매금 입금 대기 모달`,
      })
    })

    const onComplete = async () => {
      await emit('buyDone')
      await dispatch('stakeDeal/GET_ALL_STAKELIST')
      await router.push('/tabs/mypage')
    }

    return {
      completePurchaseInfo,
      dealInfo,
      onComplete,
    }
  },
})
