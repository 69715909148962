
import {AgImg, AgIntersectBox, AgBoxS, AgTextS} from '@/components/ag'
import {defineComponent, computed, ref, toRef} from 'vue'
import groupBuy from '../../data/groupBuy.json'
import groupHistory from '../../data/groupHistory.json'
import stockInfo from '../../data/stock.json'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {clubDate, numberToKorean} from '@/utils/filters'
import {IonRippleEffect} from '@ionic/vue'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    AgBoxS,
    AgImg,
    AgIntersectBox,
    AgTextS,
  },
  name: 'GroupBuy',
  props:{
    parent: String,
  },
  setup(props) {
    const {getters, dispatch} = useStore()
    const router = useRouter()
    const {useClubDealProgressModal, useClubDealOpenModal} = useModal()

    const user = computed(() => getters['user/getUser'])
    // const collectingNoLeadDeals = computed(() => getters[`partnership/getCollectingNoLeadDeals`]);
    const upcomingNoleadDeals = computed(() => getters['partnership/getUpcomingNoLeadDeals'])
    const collectingLeadDeals = computed(() => getters['partnership/getCollectingLeadDeals'])
    const upcomingLeadDeals = computed(() => getters['partnership/getUpcomingLeadDeals'])
    const prepareDeals = computed(() => getters['partnership/getPrepareDeals'])
    // const recentCollectedDeals = computed(() => getters['partnership/getRecentCollectedDeals'])
    // const exitDeals = computed(() => getters['partnership/getExitDeals'])
    // const partnershipHistories = computed(() => getters['partnership/getClubDealHistories'])

    const companyLogo = stockInfo.cLogo

    const buyList = groupBuy.groupBuy
    const history = groupHistory

    const clubdealCount = computed(() => {
      return collectingLeadDeals.value.length + Object.values((upcomingLeadDeals.value as Record<any, any>)).reduce((a, b) => (a as any).concat(b), []).length
    })

    const hasSession = () => {
      return getters['user/hasSession']
    }

    const partnershipLike = (partnershipStatus: string, target: string, partnershipId: string) => {
      dispatch('partnership/ACT_LIKE_PARTNERSHIP_TARGET', {partnershipId, partnershipStatus, target})
    }

    const page = ref(1)
    const loadMore = () => {
      dispatch('partnership/ACT_GET_HISTORIES', ++page.value)
    }

    const parentRef = toRef(props, 'parent')

    return {
      buyList,
      clickScreen: parentRef.value,
      clubDate,
      clubdealCount,
      collectingLeadDeals,
      companyLogo,
      // exitDeals,
      // recentCollectedDeals,
      // partnershipHistories,
      hasSession,
      history,
      loadMore,
      numberToKorean,
      partnershipLike,
      prepareDeals,
      router,
      upcomingLeadDeals,
      upcomingNoleadDeals,
      useClubDealOpenModal,
      useClubDealProgressModal,
      user,
    }
  },
})

