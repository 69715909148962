
import {defineComponent, computed, toRef, ref, reactive} from 'vue'
import {AgBox, AgButton, AgContent, AgText, AgTextS, AgFooter} from '@/components/ag'
import {
  getCalcCarriedInterest,
  getCalcResult,
  getEditRedirectPage,
  getRedirectPage,
  getTotalPriceCalcResult,
  editCalcCarriedInterest,
} from '@/api/stake-deal'
import {useStore} from 'vuex'
import SelectedPartnershipStake from './SelectedPartnershipStake.vue'
import Keypad from './input-component/Keypad.vue'
import CalcExpectedPrice from './input-component/CalcExpectedPrice.vue'
import LeadAngelCarriedInterest from './input-component/LeadAngelCarriedInterest.vue'

import SellRegistResult from './SellRegistResult.vue'
import {openLinkWithAccessToken} from '@/utils/filters'
import {API_URL} from '@/config'
import ViewMyStake from '@/views/view/view-my-stake/ViewMyStake.vue'
import {alertController} from '@ionic/vue'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgText,
    AgTextS,
    CalcExpectedPrice,
    Keypad,
    LeadAngelCarriedInterest,
    SelectedPartnershipStake,
    SellRegistResult,
    ViewMyStake,
  },
  emits: ['closeWithResult', 'close', 'goTo'],
  name: 'InputSellInfoPage',
  props: {
    openType: {type: String},
    parent: String,
  },
  setup(props, {emit}) {
    const {getters, commit} = useStore()
    const openTypeRef = toRef(props, 'openType')
    const componentNumber = ref<number>(1)
    const sellStakeCount = ref<any>(null)
    const resultModal = ref<boolean>(false)
    const viewMyStakeOpen = ref<boolean>(false)
    const requestId = ref<any>(null)
    const finalSellInfo = ref<any>({})
    const LeadFinalSellInfo = reactive({
      carriedInterest: 0,
      incomeFee: 0,
      revenue: 0,
    })
    const parentRef = toRef(props, 'parent')
    const clickScreen = parentRef.value
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const sellPrice = ref<any>(openTypeRef.value === 'edit' && dealInfo.value.price ? Number(dealInfo.value.price) : 0)
    const sellAmount = ref<any>(openTypeRef.value === 'edit' && dealInfo.value.totalPrice ? Number(dealInfo.value.totalPrice) : 0)
    const totalPrice = ref<any>()
    const remainAsset = ref<any>()
    const finalProfit = computed(() => finalSellInfo.value.expectedTotalPrice - (Number(LeadFinalSellInfo.carriedInterest)))

    const goToPrevious = () => {
      if (componentNumber.value === 1 ) {
        emit('goTo', 'SELECTED')
      } else if (componentNumber.value === 5 && LeadFinalSellInfo.carriedInterest === 0) {
        componentNumber.value = 3
      } else {
        componentNumber.value -= 1
      }
    }

    const showMaxPriceAlert = async () => {
      const alert = await alertController
          .create({
            buttons: [
              {
                text: '확인',
              },
            ],
            cssClass: 'seller-cancel-alert-class',
            message: `
          <div class="alert_css">
            <div class="header">
            판매 금액이 모자라요
            </div>
            <div class="message">
              최소 판매금액(1만원)이<br>
              충족되지 않았습니다.<br>
              판매 희망 주당 가격을 올려<br>
              총 판매 금액을<br>
              1만원 이상으로 만들어주세요!
            </div>
          </div>
          `,
          })
      return alert.present()
    }

    const goToNext = async () => {
      if (componentNumber.value === 1) {

        const result = openTypeRef.value === 'edit'
            ? await getTotalPriceCalcResult(dealInfo.value.partnershipId, sellPrice.value, openTypeRef.value, dealInfo.value.partnershipShareSellId)
            : await getTotalPriceCalcResult(dealInfo.value.partnershipId, sellPrice.value, openTypeRef.value)
        console.log(result)
        if(result){
          const {maxTotalPrice, remainingAsset} = result
          console.log(maxTotalPrice, remainingAsset)
          totalPrice.value = maxTotalPrice
          remainAsset.value = remainingAsset

          console.log(totalPrice.value, remainAsset.value)

          if(totalPrice.value < 10000){
            console.log('불가!')
            await showMaxPriceAlert()
          }

        }



      }else if (componentNumber.value === 2) {
        finalSellInfo.value = await getCalcResult(dealInfo.value.partnershipId, sellPrice.value, sellAmount.value, totalPrice.value, remainAsset.value)
      }else if (componentNumber.value === 3) {
        const carriedResult = openTypeRef.value === 'edit'
          ? await editCalcCarriedInterest(dealInfo.value.partnershipId, finalSellInfo.value.expectedQty, finalSellInfo.value.expectedAsset, finalSellInfo.value.assetPrice, dealInfo.value.partnershipShareSellId)
          : await getCalcCarriedInterest(dealInfo.value.partnershipId, finalSellInfo.value.expectedQty, finalSellInfo.value.expectedAsset, finalSellInfo.value.assetPrice)

        LeadFinalSellInfo.carriedInterest = carriedResult.carriedInterest
        LeadFinalSellInfo.incomeFee = carriedResult.incomeFee
        LeadFinalSellInfo.revenue = carriedResult.revenue

        if (LeadFinalSellInfo.carriedInterest === 0) {
          componentNumber.value += 2
          return
        }
      }else if (componentNumber.value === 5) {
        const result = openTypeRef.value === 'edit'
          ? await getEditRedirectPage(dealInfo.value.partnershipId, Number(dealInfo.value.partnershipShareSellId), sellPrice.value, sellAmount.value, totalPrice.value, remainAsset.value)
          : await getRedirectPage(dealInfo.value.partnershipId, sellPrice.value, sellAmount.value, totalPrice.value, remainAsset.value)

        if(result){
          result.type = 'register'
          await commit('stakeDeal/SET_REGISTER_RESULT', result)
          emit('closeWithResult')
        }

        // if (result) {
        //   requestId.value = result.negostockPageRequestId
        //   await openLinkWithAccessToken(API_URL + result.url)
        //   resultModal.value = true
        // }

        return
      }

      componentNumber.value += 1
    }

    const checkDisable = computed(() => {
      if (componentNumber.value === 1 && !sellPrice.value) {
return true
}
      if ((componentNumber.value === 2 && !sellAmount.value) || (componentNumber.value === 2 && sellAmount.value > totalPrice.value) || (componentNumber.value === 2 && sellAmount.value < 10000)) {
return true
}
      if (componentNumber.value === 3 && finalSellInfo.value.expectedTotalPrice < 10000) {
return true
}
      return false
    })

    const sellAllStake = () => {
 sellAmount.value = totalPrice.value
}

    const inputFunction = (value: any) => {
      if (componentNumber.value === 1) {
        sellPrice.value = Number(value)
      } else if (componentNumber.value === 2) {
        sellAmount.value = Number(value)
      }
    }

    const closeModal = () => {
      emit('closeWithResult')
    }

    const openRegistStatus = () => {
      resultModal.value = false
      viewMyStakeOpen.value = true
    }

    return {
      LeadFinalSellInfo,
      checkDisable,
      clickScreen,
      closeModal,
      componentNumber,
      dealInfo,
      finalProfit,
      finalSellInfo,
      goToNext,
      goToPrevious,
      inputFunc: inputFunction,
      openRegistStatus,
      openTypeRef,
      requestId,
      resultModal,
      sellAllStake,
      sellAmount,
      sellPrice,
      sellStakeCount,
      totalPrice,
      viewMyStakeOpen,
    }
  },
})
