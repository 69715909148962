import {
  cornerProps,
  cornerSystems,
  getResponsiveProp,
  keyframes,
  styled,
  heightSystem,
  systemProps,
  variant,
} from '@/plugins/emotion'
import {IonModal} from '@ionic/vue'
import {FunctionalComponent, h, PropType} from 'vue'

const IonModalWrapper: FunctionalComponent = (props, {attrs, slots}) => {
  return h(IonModal, {...props, cssClass: attrs.class}, () => slots.default?.())
}

const shadowAnimation = keyframes({
  '50%': {
    opacity: 0,
  },
  from: {
    opacity: 0,
  },
  to: {
    opacity: 0.4,
  },
})

export const ionModelProps = {
  animated: {default: true, type: Boolean},
  backdropDismiss: {default: true, type: Boolean},
  // cssClass: [String, Array],
  enterAnimation: Function,
  // isOpen: {type: Boolean},
  keyboardClose: {default: true, type: Boolean},
  leaveAnimation: Function,
  mode: {default: 'ios', type: String},
  presentingElement: Object as PropType<HTMLElement>,
  showBackdrop: {default: true, type: Boolean},
  swipeToClose: {default: false, type: Boolean},
}

IonModalWrapper.props = {
  ...ionModelProps,
}

export const modalSafeAreaProp = {type: Boolean}

const modalCornerSystem = (props: any) => {
  return {
    'div[role=dialog]': cornerSystems(props),
  }
}

const modalPositionSafeAreaSystem = variant({
  prop: 'modalPosition',
  variants: {
    bottom: {
      paddingTop: 'var(--ion-safe-area-top)',
    },
    top: {
      paddingBottom: 'var(--ion-safe-area-bottom)',
    },
  },
})

const modalSafeAreaSystem = (props: any) => {
  const {safeArea} = props
  if (safeArea) {
    return modalPositionSafeAreaSystem(props)
  }
  return {}
}

const modalHeightSystem = (props: any) => {
  return {
    'div[role=dialog]': {
      ...heightSystem(props),
    },
  }
}

export type ModalPositionType = 'center' | 'top' | 'bottom'

export const modalPositionProp = getResponsiveProp<ModalPositionType>()

const modalPositionSystem = variant({
  prop: 'modalPosition',
  variants: {
    bottom: {
      'div[role=dialog]': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      justifyContent: 'flex-end',
    },
    center: {
      justifyContent: 'center',
    },
    top: {
      'div[role=dialog]': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      justifyContent: 'flex-start',
    },
  },
})

export const styleModalProps = {
  ...cornerProps,
  corner: {...cornerProps.corner, default: '20px'},
  height: systemProps.height,
  modalPosition: {...modalPositionProp, default: 'bottom'},
  safeArea: modalSafeAreaProp,
}

export const StyledModal: any = styled(IonModalWrapper, {
  name: 'StyledModal',
  stylePortal: 'css',
})(
  {
    '.modal-shadow': {
      // animationDuration: '0.7s',
      // animationName: shadowAnimation,
      // backgroundColor: '#000000',
      // opacity: '0.4 !important',
      zIndex: 'unset',
    },
    contain: 'strict',
    'div[role=dialog]': {
      // '--border-radius': '20px',
      '.ion-page': {
        contain: 'unset',
        height: '100%',
        maxHeight: '100%',
        position: 'relative',
      },
      contain: 'layout',
      height: 'auto',
      maxWidth: '100%',
      transition: 'height 0.7s, width 0.7s',
      zIndex: 'unset',
    },
    flexDirection: 'column',
    'ion-backdrop': {
      '--backdrop-opacity': '0.4',
      animationDuration: '0.7s',
      animationName: shadowAnimation,
      // animationName: 'unset',
      // opacity: '1 !important',
      // backgroundColor: 'rgba(0, 0, 0, 0)',
      zIndex: 'unset',
      // visibility: 'hidden',
    },
    justifyContent: 'flex-end',
    /**
     * ion-modal 이랑 css 충돌 있어서 ion-modal 모든 곳에서 사용 안할때까지 아래 z-index 를 주석 처리
     */
    // zIndex: 'unset !important' as any,
  },
  modalSafeAreaSystem,
  modalCornerSystem,
  modalPositionSystem,
  modalHeightSystem,
)
