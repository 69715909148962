import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'
import {Property} from 'csstype'

export const displaySystems = system({
  display: true,
  dp: {
    property: 'display',
  },
})

export type DisplayType = Property.Display

export const displayProps = {
  display: getResponsiveProp<DisplayType>(),
  dp: getResponsiveProp<DisplayType>(),
}
