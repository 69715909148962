import {PropType} from 'vue'
import {system} from '@winter-love/style-system'

export type SafeAreaType = 'top' | 'bottom' | 'left' | 'right'

export interface SafeAreaPadProps {
  safeAreaPad?: SafeAreaType
}

export const safeAreaPadSystem = system({
  safeAreaPad: (safeAreaPad: any) => {
    if (safeAreaPad) {
      switch (safeAreaPad) {
        case 'bottom':
          return {
            paddingBottom: 'var(--ion-safe-area-bottom)',
          }
        case 'top':
          return {
            paddingTop: 'var(--ion-safe-area-top)',
          }
        case 'left':
          return {
            paddingLeft: 'var(--ion-safe-area-left)',
          }
        case 'right':
          return {
            paddingRight: 'var(--ion-safe-area-right)',
          }
      }
    }
    return {}
  },
})

export const safeAreaPadProp = {type: [String] as PropType<SafeAreaType>}
