import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_modal = _resolveComponent("ag-modal")!
  const _component_confirm_buy_modal = _resolveComponent("confirm-buy-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_modal, {
      corner: "0",
      height: "100%",
      "model-value": _ctx.isOpenRef,
      "safe-area": true,
      "swipe-to-close": true,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.movePage('BF_AGREE')))
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalPage), {
          parent: _ctx.clickScreen,
          onBuyDone: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('buyDone'))),
          onGoToNext: _cache[1] || (_cache[1] = ($event: any) => (_ctx.movePage($event))),
          onClose: _ctx.setOpen
        }, null, 8, ["parent", "onClose"]))
      ]),
      _: 1
    }, 8, ["model-value"]),
    _createVNode(_component_confirm_buy_modal, {
      "is-open": _ctx.confirmBuyRef,
      onSuccess: _cache[3] || (_cache[3] = ($event: any) => (_ctx.movePage('AT_AGREE'))),
      onFail: _cache[4] || (_cache[4] = ($event: any) => (_ctx.movePage('FAIL_TO_CONFIRM'))),
      onClose: _cache[5] || (_cache[5] = () => _ctx.confirmBuyRef = false)
    }, null, 8, ["is-open"])
  ], 64))
}