
import {computed, defineComponent, onBeforeMount, ref, watch, reactive, onMounted} from 'vue'
import {modalController, IonContent, IonRippleEffect} from '@ionic/vue'
import {useStore} from 'vuex'
import {prettyDateToOnemonth, numberToKorean} from '@/utils/filters'
import ViewOptionModal from '@/components/Modal/ViewOptionModal.vue'
import useModal from '@/composables/useModal'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import {useShare} from '@/composables'
import {AgBoxS, AgTextS, AgIcon} from '@/components/ag'
import PrepareDeals from '@/components/Home/prepare-deals.vue'

export default defineComponent({
  components: {
    AgBoxS,
    AgIcon, AgTextS,
    IonContent,
    IonRippleEffect,
    PrepareDeals,
  },
  name: 'CollectPartnership',
  setup() {
    const gtm = useGtm()
    const {dispatch, getters} = useStore()
    const viewType = ref<any>('등록 최신순')
    const {openSellModal} = useModal()
    const user = computed(() => getters['user/getUser'])
    const share = useShare()

    const preparesPartnerships = computed(() => getters['partnership/getPreparesPartnerships'])
    const filterFlag: any = reactive({
      myItem: false,
      withinAMonth: false,
    })

    const dismiss = () => {
      modalController.dismiss()
    }

    function checkForm(e: any) {
      const id = e.id
      filterFlag[id] = e.checked
    }

    const partnershipLike = async (partnershipStatus: string, target: string, partnershipId: string) => {
      const result = await dispatch('partnership/ACT_LIKE_PARTNERSHIP_TARGET', {partnershipId, partnershipStatus, target})

      const likeInfo = result.likeInfo
      const filtered = preparesPartnerships.value.filter((b: any) => b.partnershipId === likeInfo.partnershipId)

      console.log(likeInfo)

      if (filtered.length > 0) {
        filtered[0].likeFlag = likeInfo.partnershipLikeId
        filtered[0].likeCount = likeInfo.likeCount
      }
    }

    const createModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: false,
          component: ViewOptionModal,
          componentProps: {
            requestType: 'partnership',
            viewOption: 'none',
            viewType: viewType.value,
          },
          cssClass: 'view-option-modal',
          id: 'ViewOptionModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      modal.onDidDismiss().then(async (data) => {
        if (data.data) {
          viewType.value = await data.data.viewType
        }
      })

      return modal.present()
    }

    function dealSort() {
      if (viewType.value === '등록 최신순') {
        preparesPartnerships.value.sort((a: any, b: any) => {
          const aDate: any = new Date(a.createAt)
          const bDate: any = new Date(b.createAt)
          return bDate - aDate
        })
      } else if (viewType.value === '회사 이름순') {
        preparesPartnerships.value.sort((a: any, b: any) => {
          if (b.stockItemName > a.stockItemName) {
return -1
} else if (a.stockItemName > b.stockItemName) {
return 1
}
          return 0
        })
      }
    }

    onBeforeMount(() => {
      dispatch('partnership/ACT_GET_PREPARE_PARTNERSHIPS')
    })

    const clickScreen = 'clubdeal-open-modal'
    onMounted(()=>{
      gtm?.trackEvent({
        event: MODAL_OPEN_EVENT,
        page: clickScreen,
        title: '주식매물 보기 모달',
      })
    })

    watch(viewType, async () => {
      await dealSort()
    })

    watch(preparesPartnerships, async () => {
      await dealSort()
    })

    function checkWithinAMonth(currentDate: any) {
      const date: any = new Date((currentDate || '').replace(/-/g, '/').replace(/[TZ]/g, ' ').split('.')[0])
      const diff = ((Date.now() - date) / 1000)
      const dayDiff = Math.floor(diff / 86400)

      return dayDiff < 30
    }


    const {useClubDealOpenModal} = useModal()
    // async function moveToPage (item: any) {
    //   router.push({name: 'ClubDealOpen', params: {clubdeal_id: item.partnershipId}})
    //   dismiss()
    // }
    
    const onShare = () => {
      // bichi co
      const url = 'https://angelleague.io/link/club-deals-to-open/'
      const title = '클럽딜 열기'
      const text = '판매 중인 스타트업 주식 확인, 리드엔젤이라면 가격 협의 가능 🤝 합니다'
      share({
        text,
        title,
        url,
      })
    }

    return {
      checkForm,
      clickScreen,
      createModal,
      dismiss,
      filterFlag,
      numberToKorean,
      onShare,
      openSellModal,
      partnershipLike,
      preparesPartnerships,
      prettyDateToOnemonth,
      useClubDealOpenModal,
      user,
      viewType,
    }
  },
})
