<template>
  <ion-page>
    <ag-header bg="white">
      <div class="main_top">
        <div class="main_title">
          조합규약(계약서) 전문
        </div>
      </div>
    </ag-header>
    <ion-content :fullscreen="true">
      <div
        id="mainContainer"
        class="main_container"
      >
        <div class="modal_content">
          <div v-html="contract.leadContract" />
        </div>
      </div>
    </ion-content>
    <ion-footer
      class="ion-no-border"
      no-padding
    >
      <div class="footer_container space-between">
        <button
          class="blue_btn footer_btn"
          @click="$clickTrackEvent({clickScreen,'clickArea':'조합규약(계약서) 동의', 'clickContent':`계약서 전문 보기(필수)`});dismiss()"
        >
          확인완료
        </button>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import {defineComponent, onBeforeMount, computed, toRef} from 'vue'
import {modalController, IonContent, IonPage, IonFooter} from '@ionic/vue'
import {AgHeader} from '@/components/ag'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgHeader,
    IonContent,
    IonFooter,
    IonPage,
  },
  name: 'Contract',
  props: {
    parent: String,
  },
  setup(props) {
    const {getters, dispatch} = useStore()
    const parentRef = toRef(props, 'parent')
    const contract = computed(() => getters['partnership/getContract'])

    onBeforeMount(() => {
      dispatch('partnership/ACT_GET_CONTRACT')
    })
    const dismiss = () => {
      modalController.dismiss()
    }

    return {
      clickScreen: parentRef.value,
      contract, dismiss,
    }
  },
})
</script>

<style scoped>
.main_top {
padding: 20px 20px 23px 20px;
background: #fff;
}

.main_title {
text-align: center;
position: relative;
font-size: 18px;
color: #222;
font-weight: 400;
}

.top_container {
padding: 32px 20px 32px 20px;
background: #fff;
}

.modal_content {
padding: 24px 20px 24px 20px;
color: #222;
background: #F6F8FB;
}

ion-content {
--background: #F6F8FB;
}

ion-toolbar {
/* --min-height: 95px; */
--padding-bottom: 0px;
--padding-end: 0px;
--padding-start: 0px;
--padding-top: 0px;
}

ion-footer {
--padding-bottom: 0px;
--padding-end: 0px;
--padding-start: 0px;
--padding-top: 0px;
}

.footer_container {
height: 100%;
}

.footer_btn {
color: #fff;
font-size: 16px;
font-weight: 500;
padding-top: 16px;
  padding-bottom: calc(16px + var(--ion-safe-area-bottom));
text-align: center;
width: 100%;
display: flex;
align-items: flex-start;
justify-content: center;
}

.footer_btn > span {
margin-top: 7px;
}

.blue_btn {
background: #4360EC;
}
</style>
