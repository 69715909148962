import {Module} from 'vuex'
import {RootState} from '..'

interface Channel {
  channel: null
  channelID: null
  channelUsers: []
  channels: null
  locale: string
  messages: []
  user: null
}

// action
const Channel: Module<Channel, RootState> = {
  
actions: {
    addChannelUser: ({commit, state}, user) => {
      commit('SET_CHANNEL_USERS', state.channelUsers.concat(user))
    },
    addMessage: ({commit, state}, message) => {
      commit('SET_MESSAGES', state.messages.concat(message))
    },
    addMessages: ({commit, state}, messages) => {
      commit('SET_MESSAGES', messages.concat(state.messages))
    },
    // removeChannelUser: ({ commit, state }, user) => {
    //   commit('SET_CHANNEL_USERS', state.channelUsers.filter(it => it.userId !== user.userId))
    // }
  },
  

getters: {
    getChannel(state) {
 return state.channel
},
    getChannelID(state) {
 return state.channelID
},
    getChannels(state) {
 return state.channels
},
    getMessages(state) {
 return state.messages
},
  },
  
  // RootState와 똑같이 작성해도 된다.
mutations: {
    SET_CHANNEL: (state, channel) => {
      state.channel = channel.url
    },
    SET_CHANNELS: (state, channels) => {
      state.channels = channels
    },
    SET_CHANNEL_USERS: (state, channelUsers) => {
      state.channelUsers = channelUsers
    },
    SET_MESSAGES: (state, messages) => {
      state.messages = messages
    },
    SET_USER: (state, user) => {
      state.user = user
    },
  },
  namespaced: true,
  state: {
    channel: null,
    channelID: null,
    channelUsers: [],
    channels: null,
    locale: 'fr',
    messages: [],
    user: null,
  },
}

export default Channel
