
import {defineComponent, computed, ref, toRef} from 'vue'
import {AgBox, AgButton, AgImg, AgContent, AgText, AgFooter} from '@/components/ag'
import PartnershipStake from './PartnershipStake.vue'
import {storeKey, useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgImg,
    AgText,
    PartnershipStake,
  },
  emits: ['close', 'goTo', 'closeWithResult'],
  name: 'MyPartnershipStakeList',
  props: {
    openType: {type: String},
    parent: {type: String},
  },
  setup(props, {emit}) {
    const {getters, dispatch} = useStore()

    const parentRef = toRef(props, 'parent')
    const clickScreen = parentRef.value
    const setOpen = (value: boolean) => emit('close', value)
    const openTypeRef = toRef(props, 'openType')
    const previousProcess = () => emit('goTo', 'INIT')
    const nextProcess = () => checkStakeList.value ? emit('goTo', 'INPUT_SELL_INFO') : setOpen(false)

    const user = computed(() => getters['user/getUser'])
    const userId = computed(() => user.value ? user.value.userId.toString() : '')

    const selectedIndex = ref<number>(-1)
    const stakeList = computed(() => getters['stakeDeal/myStakeList'])
    const selectCompany = computed(() => getters['stakeDeal/selectCompany'])
    const checkStakeList = computed(() => stakeList.value.length && stakeList.value)
    const nextButton = computed(() => checkStakeList.value ? '다음' : '취소')
    const previousButton = computed(() => checkStakeList.value ? '취소' : '이전')

    const selectAction = async (index: number, stake: any) => {
      await dispatch('stakeDeal/SELECT_DEAL', stake)
      selectedIndex.value = index
    }

    const checkMinRetentionAsset = (stake: any) => {
      if (stake.asset <= '0') {
        return true
      }
      return false
    }

    const checkValidateDate = (stake: any) => {
      // Math.abs((new Date().getTime() - new Date('2020.05.19').getTime()) / (24 * 60 * 60 * 1000))
      const today: any = new Date()
      const foundDate = new Date(stake.foundAt)

      const calDate = today.getTime() - foundDate.getTime()
      const calDateDays = Math.abs(calDate / (24 * 60 * 60 * 1000))

      if (calDateDays <= 365) {
        return true
      }
      return false
    }

    return {
      checkMinRetentionAsset,
      checkStakeList,
      checkValidateDate,
      clickScreen,
      nextButton,
      nextProcess,
      openTypeRef,
      previousButton,
      previousProcess,
      selectAction,
      selectCompany,
      selectedIndex,
      setOpen,
      stakeList,
    }
  },
})
