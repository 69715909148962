
import {defineComponent} from 'vue'
import {modalController, IonContent} from '@ionic/vue'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

export default defineComponent({
  components: {
    IonContent,
  },
  name: 'KakaoKlipModal',
  props: {
    anotherNotYetProps: null,
    partnershipName: null,
  },
  setup() {
    const gtm = useGtm()
    gtm?.trackEvent({
      event: MODAL_OPEN_EVENT,
      page: 'KakaoKlipModal',
      title: '카카오클립 안내 모달',
    })

    const dismiss = () => {
      modalController.dismiss()
    }

    return {
      dismiss,
    }
  },
})
