import {_SystemProps} from '@/plugins/emotion'
import {StyledSpinner, styledSpinnerProps, ionicSpinnerProps} from './componnets'
import {h, FunctionalComponent, ExtractPropTypes} from 'vue'

export type AgSpinnerSProps = Partial<ExtractPropTypes<typeof ionicSpinnerProps>> & {css?: _SystemProps}

export const AgSpinnerS: FunctionalComponent<AgSpinnerSProps> = (
  props, {slots},
) => {
  const {duration, name, paused} = props
  return h(StyledSpinner, {duration, name, paused}, slots.default?.())
}

AgSpinnerS.props = ionicSpinnerProps

export type AgSpinnerProps = ExtractPropTypes<typeof styledSpinnerProps> & {css?: _SystemProps}

export const agSpinnerProps = {
  ...styledSpinnerProps,
}

export const AgSpinner: FunctionalComponent<AgSpinnerProps> = (
  props, {slots},
) => {
  return h(StyledSpinner, {css: {...props}}, () => slots.default?.())
}

AgSpinner.props = agSpinnerProps
