
import {IonPage, IonContent, IonRippleEffect} from '@ionic/vue'
import {defineComponent, computed, ref, onBeforeMount, onMounted, toRef} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {chevronBack} from 'ionicons/icons'
import {useStore} from 'vuex'
import {numberToKorean} from '@/utils/filters'
import useModal from '@/composables/useModal'
import RegistSellStakeModal from '@/views/view/regist-sell-stake/RegistSellStakeModal.vue'
import ViewMyStake from '@/views/view/view-my-stake/ViewMyStake.vue'
import LinkAccountModal from '@/views/view/link-account/LinkAccountModal.vue'
import CollectClubDeals from '@/components/Modal/Home/CollectClubDeals.vue'
import {AgBoxS, AgTextS, AgIcon} from '@/components/ag'
export default defineComponent({
  components: {
    AgBoxS,
    AgIcon,
    AgTextS,
    CollectClubDeals,
    IonContent,
    IonPage,
    IonRippleEffect,
    LinkAccountModal, RegistSellStakeModal, ViewMyStake,
  },
  name: 'MyAsset',
  props: {
    fromUrl: {default: '/', type: String},
    scroll: String,
  },
  setup() {
    const router = useRouter()
    const activeTab = ref('own')
    const {getters, dispatch} = useStore()
    const {viewCollectClubdealsModal} = useModal()

    const stakeSellOpen = ref<boolean>(false)
    const sellOpenType = ref<string>('')
    const viewMyStakeOpen = ref<any>(false)
    const viewOpenType = ref<string>('')
    const linkAccountModal = ref<boolean>(false)

    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const partnershipComplete = computed(() => getters['asset/getPsComplete'] || null)
    const partnershipCollecting = computed(() => getters['asset/getPsCollecting'])
    const exit = computed(() => getters['asset/getExit'])
    const totalAsset = computed(() => getters['asset/getTotalAsset'])

    onBeforeMount(() => {
      dispatch('asset/loadData')
      dispatch('asset/ACT_GET_ASSET_PARTNERSHIPS')
    })

    onMounted(() => {
      const result = getters['asset/getPsComplete']
    })

    function viewDetail(partnershipId: any) {
      router.push({name: 'PartnershipDetail', params: {partnershipId}})
    }

    const branchResult = () => {
      viewOpenType.value = 'result'

      stakeSellOpen.value = false
      viewMyStakeOpen.value = true
      dispatch('stakeDeal/GET_ALL_STAKELIST')
    }

    const openSellStakeModal = () => {
      sellOpenType.value = 'stakeSelected'
      stakeSellOpen.value = true
    }

    const selectStakeAction = async (item: any) => {
      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
        linkAccountModal.value = true
      } else if (currentStep.value === 'COMPLETE') {
        await dispatch('stakeDeal/GET_SELECTED_STAKE_INFO', item)
        await openSellStakeModal()
      }
    }

    const clickScreen = 'myasset'
    const clubDealAllOpen = ref<boolean>(false)
    return {
      activeTab,
      branchResult,
      chevronBack,
      clickScreen,
      clubDealAllOpen,
      exit,
      linkAccountModal,
      numberToKorean,
      partnershipCollecting,
      partnershipComplete,
      router,
      selectStakeAction,
      sellOpenType,
      stakeSellOpen,
      totalAsset,
      viewCollectClubdealsModal,
      viewDetail,
      viewMyStakeOpen,
      viewOpenType,
    }
  },
})
