import {styled, systemProps, systems, CSSObject} from '@/plugins/emotion'
import {IonHeader} from '@ionic/vue'

export const StyledHeader = styled(IonHeader, {
  name: 'StyledHeader',
  props: {
    ...systemProps,
  },
  stylePortal: 'css',
})(
  {
    flexGrow: 0,
    flexShrink: 0,
  } as CSSObject,
  systems,
)
