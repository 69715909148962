import {setTimeoutPromise} from '@/utils'


export interface RetryData<Result> {
  count: number
  result?: Result
}

export interface RetryAsyncOptions<Data = any> {
  count?: number
  duration?: number
  initResult?: Data
  isExit?: (data: RetryData<Data>) => boolean
  isOk?: (data: RetryData<Data>) => boolean
  tick?: (data: RetryData<Data>) => unknown
}

export const DEFAULT_COUNT = 5
export const DEFAULT_DURATION = 3000

export interface RetryResult<Result> extends RetryData<Result> {
  isExit?: boolean
  isOk?: boolean
}

export const retryAsync = async <Data extends any>(recipe: () => Promise<Data>, options: RetryAsyncOptions = {}): Promise<RetryResult<Data>> => {
  const {isOk, count = DEFAULT_COUNT, duration = DEFAULT_DURATION, isExit, tick} = options
  const _count = count - 1

  const nextResult: RetryResult<Data> = {
    count,
  }

  nextResult.result = await recipe()

  if (tick) {
    tick(nextResult)
  }

  if (_count === 0) {
    return nextResult
  }

  if (isExit && isExit(nextResult)) {
    return {
      ...nextResult,
      isExit: true,
    }
  }

  if (!isOk) {
    return {
      ...nextResult,
      isExit: true,
    }
  }

  if (isOk(nextResult)) {
    return {
      ...nextResult,
      isOk: true,
    }
  }

  await setTimeoutPromise(duration)

  return retryAsync(recipe, {
    count: _count,
    duration,
    isExit,
    isOk,
    tick,
  })
}
