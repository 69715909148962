
import {defineComponent, computed} from 'vue'
import {useStore} from 'vuex'
import ChannelList from '@/views/OpenChannel/ChannelList.vue'
import ChannelListNon from '@/views/OpenChannel/ChannelListNon.vue'

export default defineComponent({
  components: {
    ChannelList,
    ChannelListNon,
  },
  name: 'OpenChannel',
  setup() {
    const {getters} = useStore()
    const user = computed(() => getters['user/getUser'])
    return {
      user,
    }
  },
})
