import {styled, systems, systemProps} from '@/plugins/emotion'
import {IonFooter} from '@ionic/vue'

export const StyledFooter = styled(IonFooter, {
  props: {
    ...systemProps,
  },
  stylePortal: 'css',
})(
  {
    '&::before': {
      display: 'none',
    },
    flexGrow: 0,
    flexShrink: 0,
    label: 'ag-footer',
  },
  systems,
)
