import {styled, systemProps} from '@/plugins/emotion'

export const columnProps = {
  ...systemProps,
}

export const StyledColumnSection = styled('div', {
  name: 'ColumnSection',
  stylePortal: 'css',
})(
  {
    display: 'flex',
    flexDirection: 'column',
  },
)
