
import {AgBox, AgButton, AgText, AgToggleIcon, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {computed, defineComponent, toRef} from 'vue'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    AgToggleIcon,
  },
  emits: ['radioClick'],
  props: {
    agree: {type: Object},
    checked: {type: Boolean},
    index: {type: Number},
  },
  setup(props, {emit}) {
    const agreeRef = toRef(props, 'agree')
    const checkedRef = toRef(props, 'checked')
    const indexRef = toRef(props, 'index')

    return {
      agreeRef,
      checkedRef,
      indexRef,
    }
  },
})
