import {Property} from 'csstype'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'

export const imgSystems = system({
  objectFit: true,
})

export const imgProps = {
  objectFit: getResponsiveProp<Property.ObjectFit>(),
}
