<template>
  <div class="logo">
    <img
      v-if="setColor('white')"
      src="/assets/image/LOGO_ANGELLEAGUE_WHITE.png"
      srcset="/assets/image/LOGO_ANGELLEAGUE_WHITE@2x.png 2x,/assets/image/LOGO_ANGELLEAGUE_WHITE@3x.png 3x"
    >
    <img
      v-else
      src="/assets/image/LOGO_ANGELLEAGUE.png"
      srcset="/assets/image/LOGO_ANGELLEAGUE@2x.png 2x,/assets/image/LOGO_ANGELLEAGUE@3x.png 3x"
    >
  </div>
</template>

<script>
import {defineComponent, reactive} from 'vue'
export default defineComponent({
  name: 'LeftTopLogo',
  props: {
    color: String,
  },
  setup(props) {
    const setColor = (c) => {
      return props.color === c
    }
    return {setColor}
  },
})
</script>

<style scoped>
  .logo{
    text-align: left;
  }
</style>
