
import {IonPage, IonContent, modalController} from '@ionic/vue'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
  },
  name: 'Use',
  setup() {
    async function close() {
      await modalController.dismiss()
    }
    return {close}
  },
})
