import {trimEnd, trimStart} from 'lodash'

export const joinUrl = (first: string, second: string) => {
  const _first = trimEnd(first, '/')
  const _second = trimStart(second, '/')

  return _first + '/' + _second
}

export const joinUrlMany = (first: string, ...args: string[]) => {
  return args.reduce((result, item) => {
    return joinUrl(result, item)
  }, first)
}
