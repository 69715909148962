
import {IonPage, IonContent} from '@ionic/vue'
import {useStore} from 'vuex'
import {defineComponent, reactive, ref, computed} from 'vue'
import router from '../router'

export default defineComponent({
  components: {IonContent, IonPage},
  name: 'PayResult',
  setup() {
    const {getters} = useStore()

    const stocks = computed(() => getters['dealInfo/company'])
    const deal = computed(() => getters['dealInfo/dealInfo'])

    let able = Math.random()
    if (able < 0.5) {
 able = 0
} else {
 able = 1
}

    const next = ref(false)

    const goBack = () => {
      router.go(-1)
    }

    return {able, deal, goBack, next, stocks}
  },
})
