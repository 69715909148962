import {Property} from 'csstype'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'

export type CursorType = Property.Cursor | string

export const cursorSystems = system({
  cursor: {
    property: 'cursor',
    scale: 'cursors',
  },
})

export const cursorProps = getResponsiveProp<CursorType>()
