
import {AgButton, AgContent, AgImg, AgText} from '@/components/ag'
import {IonPage} from '@ionic/vue'
import troublePng from './assets/trouble.png'
import trouble2Png from './assets/trouble@2x.png'
import trouble3Png from './assets/trouble@3x.png'

const text = {
  description: '서버와의 통신이 원활하지 않아\n데이터를 불러올 수 없습니다.',
  refresh: '새로고침',
  title: '네트워크 상태를 확인해주세요 😢',
}

const image = {
  trouble2Png,
  trouble3Png,
  troublePng,
}

export default {
  components: {
    AgButton,
    AgContent,
    AgImg,
    AgText,
    IonPage,
  },
  name: 'FinalError',
  setup() {
    const onRefresh = () => {
      location.replace('/')
    }
    return {
      image,
      onRefresh,
      text,
    }
  },
}
