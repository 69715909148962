export interface ParseInfo {
  type: 'inner' | 'outer'
  value: string
}

const createInfo = (value: string, isOpen: boolean): ParseInfo => {
  return {
    type: isOpen ? 'inner' : 'outer',
    value,
  }
}

export const parseString = (value: string): ParseInfo[] => {
  let isOpen = false
  let stringStack = ''
  let index = 0
  const stringList: ParseInfo[] = []
  const length = value.length
  while (index < length) {
    const oldIsOpen = isOpen
    const nextChar = value[index + 1]
    const char = value[index]
    let isOperator = false
    if (!isOpen && (char + nextChar) === '{{') {
      isOpen = true
      isOperator = true
    } else if (isOpen && (char + nextChar) === '}}') {
      isOpen = false
      isOperator = true
    }

    if (isOperator) {
      stringList.push(createInfo(stringStack, oldIsOpen))
      stringStack = ''
    } else {
      stringStack += char
    }

    index += isOperator ? 2 : 1
  }

  if (stringStack) {
    stringList.push(createInfo(stringStack, isOpen))
  }

  return stringList
}
