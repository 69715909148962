import {ParseInfo, parseString} from '@/components/ag/ag-text/composables'
import {SystemProps} from '@/plugins/emotion'
import {getStringSlot} from '@/utils'
import {computed, defineComponent, h, PropType, toRef} from 'vue'
import {StyledText} from './StyledText'

export const EmphasisText = defineComponent({
  name: 'EmphasisText',
  props: {
    emphasis: {default: () => ({}), type: Object as PropType<SystemProps>},
  },
  setup(props, {slots}) {
    const emphasisRef = toRef(props, 'emphasis')
    const textRef = computed<string>(() => {
      return getStringSlot(slots, 'default')
    })

    const slot = computed(() => {
      const emphasis = emphasisRef.value

      return parseString(textRef.value).map((info: ParseInfo) => {
        const {value, type} = info
        if (type === 'inner') {
          return h(StyledText, {as: 'strong', css: emphasis}, () => value)
        }
        return h('span', value)
      })
    })

    return () => (
      h(StyledText, {}, () => slot.value)
    )
  },
})
