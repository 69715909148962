
import {IonPage, IonContent} from '@ionic/vue'
import {AgRefresher, AgIcon, AgBoxS} from '@/components/ag'
import {cloneDeep} from 'lodash'
import {computed, defineComponent} from 'vue'
import {useStore} from 'vuex'
import BoardListAll from '@/components/Board/BoardListAll.vue'
import {arrowDownOutline} from 'ionicons/icons'
import {useShare} from '@/composables'

export default defineComponent({
  components: {
    AgBoxS,
    AgIcon,
    AgRefresher,
    BoardListAll,
    IonContent,
    IonPage,
  },
  name: 'Community',
  setup() {
    const {dispatch, getters} = useStore()

    // 오브젝트 참조 내부 데이터 변경으로 문제 생기는 부분 임시 대응 (다른 페이지 리프레시에 영향을 끼침)
    const stockItemBoards = computed(() => cloneDeep(getters['stockItem/getStockItemBoardsAll']))

    const doRefresh = async (event: any) => {
      await dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS_ALL_FOR_REFRESH', {page: 1})
      event.target.complete()
    }
    
    const share = useShare()
    
    const onShare = () => {
      const url = 'https://angelleague.io/link/community/'
      share({
        text: '스타트업 주식과 관련한 최신 토론글을 확인해 보세요',
        title: '엔젤리그 토론 NOW',
        url,
      })
    }

    // todo pull to refresh 적용
    return {
      arrowDownOutline,
      doRefresh,
      onShare,
      stockItemBoards,
    }
  },
})

