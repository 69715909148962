
import {computed, defineComponent, PropType, toRef} from 'vue'
import {IonSearchbar} from '@ionic/vue'

const text = {
  placeholder: '회사명, 종목(주식)코드 검색',
}

export default defineComponent({
  components: {
    IonSearchbar,
  },
  emits: ['update:modelValue', 'submit', 'input'],
  name: 'SearchInput',
  props: {
    debounce: {default: 500, type: Number},
    enterkeyhint: {default: 'done', type: String as PropType<any>},
    modelValue: {default: '', type: String},
  },
  setup(props, {emit}) {
    const value = toRef(props, 'modelValue')
    const isShowCancel = computed(() => Boolean(value.value))

    const onChange = (event: any) => {
      emit('update:modelValue', event.target.value)
    }

    const onClear = () => {
      if (value.value === '') {
        onSubmit()
      }
      emit('update:modelValue', '')
    }

    const onSubmit = () => {
      emit('submit')
    }

    return {
      isShowCancel,
      onChange,
      onClear,
      onSubmit,
      text,
    }
  },
})
