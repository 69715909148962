
import {
  IonPage,
  IonContent,
  modalController,
  alertController,
  popoverController,
  IonFooter,
  IonRefresher, IonRefresherContent,
  toastController, IonRippleEffect,
} from '@ionic/vue'
import {arrowDownOutline} from 'ionicons/icons'
import {defineComponent, computed, onBeforeMount, onUpdated, watch, ref, onMounted, getCurrentInstance} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import CompanyValue from '@/components/Popover/CompanyValue.vue'
import Fee from '@/components/Popover/Fee.vue'
import {useStore} from 'vuex'
import {numberToKorean, openLink, only3} from '@/utils/filters'
import ClubDealValue from '@/components/Popover/ClubDealValue.vue'
import ClubDealInfo from '@/components/Modal/ClubDealInfo.vue'
import apiPartnership from '@/api/partnership'
import {SendBirdAction} from '@/plugins/SendBirdAction'
import channelApi from '@/api/open-channel'
import useModal from '@/composables/useModal'
import useAlert from '@/composables/useAlert'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import frame783Png from './assets/Frame783.png'
import frame783x2Png from './assets/Frame783@2x.png'
import frame783x3Png from './assets/Frame783@3x.png'
const {delPartnershipRequests} = apiPartnership()
import {AgBoxS, AgTextS, AgIcon} from '@/components/ag'
import {useShare} from '@/composables'
export default defineComponent({
  components: {
    AgBoxS,
    AgIcon,
    AgTextS,
    IonContent,
    IonFooter,
    IonPage,
    IonRefresher, IonRefresherContent, IonRippleEffect,
  },
  name: 'ClubDealOpenModal',
  props: {
    from: null,
    partnershipId: null,
  },
  setup(props) {
    const gtm = useGtm()
    const route = useRoute()
    const router = useRouter()
    const {dispatch, getters} = useStore()
    const {postOpenChannel} = channelApi()
    const {showBank} = useAlert()
    const {
      useClubDealOpenJoinModal, useCompanyModal,
      useClubDealProgressModal, useClubDealBuyChatModal, openFAQModal,
    } = useModal()
    const partnershipId = ref(props.partnershipId)
    const self: any = getCurrentInstance()
    // Partnership status = prepare
    // 리드엔젤 아니면 참여 불가능 뜨게

    const share = useShare()

    async function initPage() {
      await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', partnershipId.value)
      trackEvent()
    }
    const fromCompany = ref(false)
    onBeforeMount(async () => {
      if (props.partnershipId) {
        fromCompany.value = (props.from === 'company')
        await initPage()
      }
    })
    const scrollTop = async () => {
      const content: any = document.querySelector('#open__ion_content')
      content.scrollToPoint(0, 0)
    }

    const refresh = async () => {
      if (partnershipId.value) {
        await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', partnershipId.value)
      }
    }
    const doRefresh = async (event: any) => {
      // console.log('Begin async operation');
      await refresh()
      event.target.complete()
    }

    const setPartnershipId = async (id: number) => {
      partnershipId.value = id
      await refresh()
      scrollTop()
    }

    const hasSession = () => {
      return getters['user/hasSession']
    }

    const isLogin = computed(() => getters['user/hasSession'])
    const partnershipInfo = computed(() => getters['partnership/getPartnershipOpen'])
    const boardTotalInfo = computed(() => partnershipInfo.value.boardTotalInfo || {})
    const partnershipRequest = computed(() => partnershipInfo.value.joinPartnershipRequest || {})
    const recommendPartnership = computed(() => partnershipInfo.value.recommendPartnerships || {})



    const currentValuation = computed(() => numberToKorean(partnershipInfo.value.currentValuation))
    const leadAvailableAssetFrom = computed(() => numberToKorean(partnershipInfo.value.leadAvailableAssetFrom))
    const leadAvailableAssetTo = computed(() => numberToKorean(partnershipInfo.value.leadAvailableAssetTo))

    const availableAssetFrom = computed(() => numberToKorean(partnershipInfo.value.availableAssetFrom))
    const availableAssetTo = computed(() => numberToKorean(partnershipInfo.value.availableAssetTo))



    const trackEvent = () => {
      const stockItemName = partnershipInfo.value?.stockItemName
      gtm?.trackEvent({
        event: MODAL_OPEN_EVENT,
        page: `clubdeal-open/${partnershipId.value}`,
        title: `${stockItemName} 클럽딜 열기`,
      })
    }

    let popover: any = null
    const openPopover = async (price: any) => {
      if (popover) {
        return
      }
      popover = await popoverController
        .create({
          component: CompanyValue,
          componentProps: {
            onClick: () => {
              popover.dismiss()
            },
            price,
          },
          cssClass: 'company-value',
          translucent: false,
        })

      popover.onDidDismiss().then((result: any) => {
        popover = null
      })
      return popover.present()
    }

    let popoverFee: any = null
    const openPopoverFee = async (event_: Event) => {
      if (popoverFee) {
return
}
      popoverFee = await popoverController
        .create({
          component: Fee,
          componentProps: {
            onClick: () => {
              popoverFee.dismiss()
            },
          },
          cssClass: 'company-value',
          event: event_,
          translucent: false,
        })

      popoverFee.onDidDismiss().then((result: any) => {
        popoverFee = null
      })
      return popoverFee.present()
    }

    const handleScroll = async (event: any) => {
      const main: any = document.querySelector('#open__main_container')
      const button: any = document.querySelector('#open__scroll_top_btn')
      const scroll = await event.getScrollElement()

      if (scroll.scrollTop > main.clientHeight) {
        button.classList.add('show')
      } else {
        button.classList.remove('show')
      }
    }

    async function openClubDeal() {
      await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', Number(partnershipId.value))
      await useClubDealOpenJoinModal(Number(partnershipId.value), partnershipInfo.value.partnershipOpenChannelUrl, partnershipInfo.value.partnershipOpenChannelId)
    }

    const notLeadAngelAlert = async () => {
      const alert = await alertController
        .create({
          buttons: ['확인'],
          cssClass: 'leadinfo-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
            리드엔젤만<br>
            클럽딜을 열 수 있습니다
            </div>
            <div class="message">
            아직 리드엔젤이 되지 못하셨어요 😢<br>
            클럽딜 1회 이상 참여, 회원가입 1개월 이후<br>
            리드엔젤 자격이 생성되며,<br>
            리드엔젤이 되시면 알림을 드립니다!
            </div>
          </div>
          `,
        })
      return alert.present()
    }

    const openAlert = async () => {
      if (partnershipInfo.value.validLeadAngel) {
        await openClubDeal()
      } else {
        await notLeadAngelAlert()
      }
    }

    async function priceNegoFunc() {
      if (!partnershipInfo.value.validLeadAngel) {
        return notLeadAngelAlert()
      }

      if (partnershipInfo.value.partnershipOpenChannelUrl) {
        await useClubDealBuyChatModal(partnershipInfo.value.partnershipOpenChannelUrl)
        // await router.push({ name : 'ClubDealBuyChat', params: {channelUrl: partnershipInfo.value.partnershipOpenChannelUrl}});
      } else {
        try {
          const channel = await postOpenChannel(Number.parseInt(partnershipId.value))
          if (channel) {
            await useClubDealBuyChatModal(channel.channelUrl)
            await refresh()
            // await router.push({ name : 'ClubDealBuyChat', params: { channelUrl : channel.channelUrl}});
          } else {
            // console.log('이미 생성된 채널');

          }
        } catch {
          // console.log(e);
        }
      }
    }

    async function showNotYet() {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: ClubDealInfo,
          componentProps: {
            anotherNotYetProps: partnershipInfo.value.anotherNotYet,
            parent: clickScreen,
            partnershipName: partnershipInfo.value.partnershipName,
          },
          cssClass: 'clubdeal-info',
          id: 'ClubDealInfo',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      modal.onDidDismiss().then(async (data: any) => {
        if (data.data === 'cancelJoin') {
          await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', partnershipId.value)
        }
      })

      return modal.present()
    }

    async function sendCancelClubDealMessage(message: string) {
      if (!message) {
        return
      }
      const sendbirdAction: any = await SendBirdAction.getInstance()
      sendbirdAction
        .getChannel(partnershipInfo.value.partnershipOpenChannelUrl, false)
        .then((channel: any) => {
          sendbirdAction.sendUserMessage({
            channel: channel,
            handler: (message: any, error: any) => {
              // console.log(message, error);
            },
            message: message,
            status: 'cancelToSeller',
          })
        })
    }

    async function cancelRequest() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              handler: () => {
                // console.log('Cancel clicked');
              },
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                // console.log("삭제하기 클릭");
                const message = await delPartnershipRequests(partnershipInfo.value.joinPartnershipRequest.partnershipRequestId, Number(partnershipId.value))

                await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', partnershipId.value)
                const toast = await toastController
                  .create({
                    duration: 1000,
                    message: '클럽딜 참여가 취소되었습니다.',
                    position: 'middle',
                  })
                if (partnershipInfo.value.partnershipOpenChannelId) {
                  await sendCancelClubDealMessage(message.channelMessage)
                }
                return toast.present()
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups suggest-alert-class',
          message: `
            <div class="alert_css">
              <div class="header">
                이 클럽딜 참여를<br>
                취소하시겠어요?
              </div>
              <div class="message">
                입력하신 모든 정보가 삭제되며,<br/>
                다른 클럽딜에 참여하실 수 있게 됩니다.
              </div>
            </div>`,
        })
      return alert.present()
    }

    async function closeModal() {
      await modalController.dismiss()
    }

    const openPopover2 = async (price: any) => {
      popover = await popoverController
      .create({
        component: ClubDealValue,
        componentProps: {
          onClick: () => {
            popover.dismiss()
          },
          price,
        },
        cssClass: 'company-value',
        translucent: false,
      })

      return popover.present()
    }

    const clickScreen = 'clubdeal-open'

    console.log(partnershipInfo.value, '00000', partnershipId.value)

    const onShare = () => {
      console.log(self)
      if (!self) {
        return
      }
      // bichi share club deal open
      const url = `https://angelleague.io/link/open-club-deal/${partnershipId.value}`
      const title = `${partnershipInfo.value.stockItemName} 주식 클럽딜(공동구매) 열기`
      const text = `주당 가격 ${self.appContext.config.globalProperties.$filters.currency(partnershipInfo.value.price)}원 ${partnershipInfo.value.stockItemName} 매물이 공동구매 오픈을 기다리고 있어요. 수수료 무료 혜택에 10만 포인트 적립까지!`
      return share({
        text,
        title,
        url,
      })
    }

    const aaaaa = computed(() => {
      return hasSession() ? numberToKorean(partnershipInfo.value.price*partnershipInfo.value.totalStockAmount) : '?'
    })

    return {
      aaaaa,
      arrowDownOutline,
      availableAssetFrom,
      availableAssetTo,
      boardTotalInfo,
      cancelRequest,
      clickScreen,
      closeModal,
      currentValuation,
      doRefresh,
      frame783Png,
      frame783x2Png,
      frame783x3Png,
      fromCompany,
      handleScroll,
      hasSession,
      isLogin,
      leadAvailableAssetFrom,
      leadAvailableAssetTo,
      numberToKorean,
      onShare,
      only3,
      openAlert,
      openFAQModal,
      openLink,
      openPopover,
      openPopover2,
      openPopoverFee,
      partnershipInfo,
      partnershipRequest,
      priceNegoFunc,
      recommendPartnership,
      refresh,
      route,
      router,
      scrollTop,
      setPartnershipId,
      showBank,
      showNotYet,
      useClubDealProgressModal,
      useCompanyModal,

    }
  },
})
