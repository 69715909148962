
import {defineComponent, reactive, computed, onBeforeMount} from 'vue'
import {useStore} from 'vuex'

import {IonRippleEffect, modalController} from '@ionic/vue'
import channelApi from '@/api/open-channel'
import {SendBirdAction} from '@/plugins/SendBirdAction'

export default defineComponent({
  components: {
    IonRippleEffect,
  },
  name: 'BuyKeypad',
  props: {
    channel: null,

    channelMetaData: null,
    // sellPrice: null,
    // partnershipID: null,
    // channelID: null,
    channelUrl: null,
    messageID: null,
    suggestType: null,
  },
  setup(properties) {
    const {sendNewMessage} = channelApi()

    const dismiss = async (data?: string) => {
      await modalController.dismiss(data)
    }

    const reValue = reactive({
      channelUrl: computed(() => properties.channelUrl),
      messageID: computed(() => properties.messageID),
      partnershipId: computed(() => properties.channelMetaData.partnershipId),
      partnershipOpenChannelId: computed(() => properties.channelMetaData.partnershipOpenChannelId),
      price: 0,
      sellPrice: computed(() => properties.channelMetaData.price),
      suggestType: computed(() => properties.suggestType),
    })

    const currentChannel = computed(() => properties.channel)

    async function sendRequest() {
      // console.log(reValue);
      await sendNewMessage(reValue.partnershipId, reValue.partnershipOpenChannelId, reValue.suggestType, reValue.price)
        .then(async (message) => {
          const sba: any = await SendBirdAction.getInstance()
          const temporaryMessage = sba.sendUserMessage({
            channel: currentChannel.value,
            handler: (message: any, error: any) => {
            // console.log(message);
              dismiss(message)
            },
            message: message.message,
            status: reValue.suggestType,
          })
        })
    }

    function addNumber(_value: any) {
      let value = _value
      value = value.toString()
      let result = reValue.price.toString()

      if (result.length >= 10) {
return result
}

      if (value === '0' && result === '0') {
return
} // 따로 작업을 해주지 않음!
      result += value

      result = result.slice(0, 10)
      reValue.price = Number(result)
    }

    function clearNumber(value: any) {
      let result = reValue.price.toString()

      if (result === '') {
return
}

      result = result.slice(0, -1)

      reValue.price = Number(result)
    }

    return {addNumber, clearNumber, dismiss, reValue, sendRequest}
  },
})
