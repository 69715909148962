import SignaturePad from 'signature_pad'
import {h, onBeforeUnmount, ref, defineComponent, onUpdated, watch, toRefs} from 'vue'
import {styled, systems, systemProps} from '@/plugins/emotion'
import {useElementEvent, onDomMounted} from '@/composables'
import {useDebounce} from '@/composables/debounce'

const canvasWrapperProps = {
  /**
   * 그리기가 끝났을 경우 바로 data url 를 emit 을 할지 결정합니다
   * 만약 auto 가니면 이 값이 바뀔 경우 data url 을 emit 합니다.
   */
  changeEmitFlag: {default: 'auto', type: String},
  /**
   * to-data-url function type
   */
  dataUrlType: {default: 'image/svg+xml', type: String},
  debounce: {default: 500, type: [Number]},
  empty: {default: true, type: Boolean},
  /**
   * 팬 선 색깔
   * props 변경 반응 안합니다. (시간남으면 지원 하겠습니다.)
   */
  lineColor: {default: 'black', type: String},
  lineMaxWidth: {default: 2.5, type: Number},
  lineMinWidth: {default: 0.5, type: Number},
  redrawOnResize: {default: true, type: Boolean},
}

export const canvasProps = {
  ...systemProps,
  ...canvasWrapperProps,
}

const CanvasWrapper = defineComponent({
  emits: ['update:empty', 'change'],
  props: {
    ...canvasWrapperProps,
  },
  setup: (props, {attrs, emit}) => {
    const {dataUrlType, empty, redrawOnResize, changeEmitFlag, debounce} = toRefs(props)
    const canvasRef = ref<undefined | HTMLCanvasElement>()
    const signaturePadRef = ref()

    const assignOptions = (target: SignaturePad, options: typeof props) => {
      target.minWidth = options.lineMinWidth
      target.maxWidth = options.lineMaxWidth
      target.penColor = options.lineColor
    }

    const toDataUrl = () => {
      const signaturePad: SignaturePad = signaturePadRef.value
      if (!signaturePad) {
        return
      }
      return signaturePad.toDataURL(dataUrlType.value)
    }

    const onChange = () => {
      const data = toDataUrl()
      if (data) {
        emit('change', data)
      }
    }

    watch(empty, (empty) => {
      if (!empty) {
        return
      }
      const signaturePad: SignaturePad = signaturePadRef.value
      if (!signaturePad) {
        return
      }
      signaturePad.clear()
      onChange()
    })

    watch(props, (props) => {
      const signaturePad: SignaturePad = signaturePadRef.value
      if (!signaturePad) {
        return
      }

      assignOptions(signaturePad, props)
    })

    const onDrawEnd = () => {
      if (empty.value) {
        emit('update:empty', false)
      }
      if (changeEmitFlag.value === 'auto') {
        onChange()
      }
    }

    watch(changeEmitFlag, (value: string) => {
      if (value !== 'auto') {
        onChange()
      }
    })

    const onUpdateCanvas = () => {
      const canvas = canvasRef.value
      const signaturePad = signaturePadRef.value
      if (!canvas || !signaturePad) {
        return
      }
      let imgData
      if (redrawOnResize.value) {
        imgData = signaturePad.toData()
      }
      const {width, height} = canvas.getBoundingClientRect()
      canvas.width = width
      canvas.height = height
      if (redrawOnResize.value && imgData) {
        signaturePad.fromData(imgData)
      }
    }

    const onDebounceUpdateCanvas = useDebounce(onUpdateCanvas, debounce)

    useElementEvent(window, 'resize', () => {
      onDebounceUpdateCanvas.value()
    })

    onUpdated(() => {
      onUpdateCanvas()
    })

    /**
     * update canvas after dom rendered & this component mounted
     */
    onDomMounted(() => {
      const canvas = canvasRef.value
      if (!canvas) {
        return
      }
      const signaturePad = new SignaturePad(canvas)
      signaturePadRef.value = signaturePad
      assignOptions(signaturePad, props)
      signaturePad.onEnd = () => onDrawEnd()
      onUpdateCanvas()
    })

    onBeforeUnmount(() => {
      signaturePadRef.value?.off()
    })

    return () => {
      return (
        h('canvas', {...attrs, ...props, ref: canvasRef})
      )
    }
  },
})

export const Canvas = styled(CanvasWrapper, {
  props: {
    ...canvasProps,
  },
})(
  {
    height: '200px',
    width: '300px',
  },
  systems,
)
