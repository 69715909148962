import {BootOptions, BootResult, addWebChannelIo, OpenChetOptions} from './web'
import {Plugins} from '@capacitor/core'
import {computed, ref, watch, provide, inject, InjectionKey, Ref, reactive} from 'vue'
import {isNative} from '../is-native'
if (!isNative()) {
  addWebChannelIo()
}

const {ChannelIoPlugin} = Plugins

const bootResult = ref<BootResult | undefined>()
const _isShow = ref(false)

const _bootOptions: BootOptions = {
  pluginKey: 'e485ff9c-15a3-4d19-a04e-63f43a0325e4',
}



export const useChannelIo = (options: BootOptions = _bootOptions) => {
  const isBoot = computed(() => Boolean(bootResult.value))
  const isShow = computed(() => Boolean(_isShow.value))
  const user = computed(() => bootResult.value)


  const show = (): Promise<true> => {
    _isShow.value = true
    return ChannelIoPlugin.show()
  }

  const hide = (): Promise<true> => {
    _isShow.value = false
    return ChannelIoPlugin.hide()
  }
  
  const boot = async (): Promise<BootResult> => {
    if (bootResult.value) {
      return bootResult.value
    }
    const result = await ChannelIoPlugin.boot(options)

    bootResult.value = result

    return result
  }
  
  const openChat = async (options: OpenChetOptions = {}) => {
    return ChannelIoPlugin.openChat(options)
  }

  return {
    boot,
    hide,
    isBoot,
    isShow,
    openChat,
    show,
    user,
  }
}
