
import {defineComponent, computed} from 'vue'
import {useStore} from 'vuex'
import MyPageNon from '../../src/components/MyPage/MyPageNon.vue'
import MyPageNon2 from '../../src/components/MyPage/MyPageNon2.vue'
import MyPageAuth from '../../src/components/MyPage/MyPageAuth.vue'
import MyPageAuth2 from '../../src/components/MyPage/MyPageAuth2.vue'

export default defineComponent({
  components: {
    MyPageAuth: process.env.VUE_APP_UI_MODE === 'ios-pass' ? MyPageAuth2 : MyPageAuth,
    MyPageNon: process.env.VUE_APP_UI_MODE === 'ios-pass' ? MyPageNon2 : MyPageNon,
  },
  name: 'MyPage',
  setup() {
    const {getters, dispatch} = useStore()

    const user = computed(() => getters['user/getUser'])
    const logout = () => {
      dispatch('user/ACT_LOGOUT')
    }

    return {
      logout,
      user,
    }
  },
})
