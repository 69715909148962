
import {
  AgBox,
  AgBoxS,
  AgButton,
  AgContent,
  AgFooter,
  AgImg,
  AgRefresher,
  AgText,
  AgTextS,
  AgIcon} from '@/components/ag'
import {
  alertController,
  IonAvatar,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonRippleEffect,
  IonSkeletonText,
  modalController,
  popoverController,
  toastController,
  useIonRouter,
} from '@ionic/vue'
import {arrowDownOutline} from 'ionicons/icons'
import {computed, defineComponent, getCurrentInstance, onBeforeMount, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {getDate, numberToKorean, only3} from '@/utils/filters'
import ClubDealInfo from '@/components/Modal/ClubDealInfo.vue'
import {Plugins} from '@capacitor/core'
import apiPartnership from '@/api/partnership'
import apiPoint from '@/api/user-point'
import CompanyValue from '@/components/Popover/CompanyValue.vue'
import ClubDealValue from '@/components/Popover/ClubDealValue.vue'
import Fee from '@/components/Popover/Fee.vue'
import useModal from '@/composables/useModal'
import useAlert from '@/composables/useAlert'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import {useShare} from '@/composables'
import frame783Png from './assets/Frame783.png'
import frame783x2Png from './assets/Frame783@2x.png'
import frame783x3Png from './assets/Frame783@3x.png'
const {getPoint} = apiPoint()

export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgIcon,
    AgImg,
    AgRefresher,
    AgText,
    AgTextS,
    IonAvatar,
    IonContent,
    IonFooter,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonProgressBar, IonRefresher,
    IonRefresherContent, IonRippleEffect, IonSkeletonText,
  },
  name: 'ClubDealProgressModal',
  props: {
    from: null,
    partnershipId: null,
  },
  setup(props) {
    const app = getCurrentInstance()
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const {dispatch, getters, commit} = useStore()
    const gtm = useGtm()
    const {Browser} = Plugins
    const {useClubDealJoinModal, useCompanyModal, viewKakaoKlipModal, openFAQModal} = useModal()
    const {showBank} = useAlert()
    const {delPartnershipRequests, postPartnershipLike} = apiPartnership()
    const share = useShare()

    const partnershipId = ref(props.partnershipId)

    const route = useRoute()
    const router = useRouter()
    const showToggle = ref(false)
    const buttonToggle = ref(false)

    const scrollTop = async () => {
      const content: any = document.querySelector('#ionContentClubDealProgress')
      content.scrollToPoint(0, 0)
    }

    const refresh = async () => {
      if (partnershipId.value) {
        showToggle.value = false
        commit('partnership/SET_PARTNERSHIP_INFO', {
          anotherNotYet: {},
          boardSummary:[],
          partnershipInfo:{},
          partnershipRequest:{},
          recommendPartnership:[],
          submitButtons:[],
        })//초기화
        await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', partnershipId.value)
      }
    }
    const doRefresh = async (event: any) => {
      await refresh()
      event.target.complete()
    }

    const setPartnershipId = async (id: number) => {
      partnershipId.value = id
      await refresh()
      scrollTop()
    }

    const partnershipInfo = computed(() => getters['partnership/getPartnershipInfo'])
    const boardSummary = computed(() => getters['partnership/getBoardSummary'])
    const anotherNotYet = computed(() => getters['partnership/getAnotherNotYet'])
    const recommendPartnership = computed(() => getters['partnership/getRecommendPartnership'])
    const submitButtons = computed(() => getters['partnership/getSubmitButtons'])
    const partnershipRequest = computed(() => getters['partnership/getPartnershipRequest'])
    const userAvailablePoint = ref(0)
    const user = computed(() => getters['user/getUser'])

    watch(partnershipInfo, () => {
      const partnershipName = partnershipInfo.value?.partnershipName
      const partnershipId = partnershipInfo.value?.partnershipId
      if(partnershipId){
        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: `clubdeal/${partnershipId}`,
          title: partnershipName,
        })
      }
    })

    watch(submitButtons, (submitButtons) => {
      if (submitButtons.length > 0) {
        showToggle.value = true
      }
      if (submitButtons.length > 1) {
        buttonToggle.value = true
      }
    })

    const hasSession = () => {
      return getters['user/hasSession']
    }
    const isLogin = computed(() => getters['user/hasSession'])
    async function getUserPoint() {
      if (hasSession()) {
        const userPoint = await getPoint()
        userAvailablePoint.value = userPoint.totalPoint
      }
    }
    const fromCompany = ref(false)
    // watch(
    //     () => props,
    //     async newParams => {
    //       if(newParams.partnershipId){
    //         // console.log("props", newParams)
    //         fromCompany.value = (props.from==='company');
    //         await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', newParams.partnershipId);
    //         await getUserPoint();
    //         scrollTop();
    //       }
    //     }
    // )

    onBeforeMount(async () => {
      if (partnershipId.value) {
        fromCompany.value = (props.from === 'company')
        await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', partnershipId.value)
        await getUserPoint()

      }
    })

    let popover: any = null

    const openPopover2 = async (price: any) => {
      popover = await popoverController
      .create({
        component: ClubDealValue,
        componentProps: {
          onClick: () => {
            popover.dismiss()
          },
          price,
        },
        cssClass: 'company-value',
        translucent: false,
      })

      return popover.present()
    }

    const openPopover = async (price: any) => {
      if (popover) {
return
}
      popover = await popoverController
        .create({
          component: CompanyValue,
          componentProps: {
            onClick: () => {
              popover.dismiss()
            },
            price,
          },
          cssClass: 'company-value',
          translucent: false,
        })

      popover.onDidDismiss().then((result: any) => {
        popover = null
      })
      return popover.present()
    }

    let popoverFee: any = null
    const openPopoverFee = async (event_: Event) => {
      if (popoverFee) {
return
}
      popoverFee = await popoverController
        .create({
          component: Fee,
          componentProps: {
            onClick: () => {
              popoverFee.dismiss()
            },
          },
          cssClass: 'company-value',
          event: event_,
          translucent: false,
        })

      popoverFee.onDidDismiss().then((result: any) => {
        popoverFee = null
      })
      return popoverFee.present()
    }

    async function heartToggle(partnershipStatus: string, partnershipId: string) {
      const target = partnershipStatus === 'collecting' ? 'collectingLeadDeals' : 'upcomingLeadDeals'
      dispatch('partnership/ACT_LIKE_PARTNERSHIP_TARGET', {partnershipId, partnershipStatus, target})
        .then(() => {
          dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', partnershipId)
        })
    }

    const handleScroll = async (event: any) => {
      const main: any = document.querySelector('#progress__main_container')
      const button: any = document.querySelector('#progress__scroll_top_btn')
      const scroll = await event.getScrollElement()

      if (scroll.scrollTop > main.clientHeight) {
        button.classList.add('show')
      } else {
        button.classList.remove('show')
      }
    }

    const partnershipLike = (partnershipId: any) => {
      dispatch('partnership/ACT_LIKE_PARTNERSHIP_DETAIL', {partnershipId})
    }

    function openLink(link: string) {
      Browser.open({url: link})
    }

    async function buttonClick(type: string) {
      // console.log(type);
      if (type === 'anotherNotYet') {
        const modal = await modalController
          .create({
            backdropDismiss: true,
            component: ClubDealInfo,
            componentProps: {
              anotherNotYetProps: anotherNotYet.value,
              parent: clickScreen,
              partnershipName: partnershipInfo.value.partnershipName,
            },
            cssClass: 'clubdeal-info',
            id: 'ClubDealInfo',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,

          })

        modal.onDidDismiss().then(async (data: any) => {
          if (data.data === 'cancelJoin') {
            await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', partnershipId.value)
          }
        })

        return modal.present()
      } else if (type === 'join') {

        await useClubDealJoinModal(Number(partnershipId.value))
        // await router.push({ name : 'ClubDealJoin', params: { clubdealId : props.partnershipId}});
      } else if (type === 'cancel') {
        const alert = await alertController
          .create({
            buttons: [
              {
                cssClass: 'btn-gray',
                handler: () => {
                  // console.log('Cancel clicked');
                  $clickTrackEvent({'clickArea':'참여취소하기 alert', 'clickContent':'아니오', clickScreen})
                },
                role: 'cancel',
                text: '아니오',
              },
              {
                handler: async () => {
                  // console.log("삭제하기 클릭");
                  $clickTrackEvent({'clickArea':'참여취소하기 alert', 'clickContent':'네, 취소할게요', clickScreen})
                  try{
                    await delPartnershipRequests(partnershipRequest.value.partnershipRequestId, partnershipRequest.value.partnershipId)
                    const toast = await toastController
                        .create({
                          duration: 1000,
                          message: '클럽딜 참여가 취소되었습니다.',
                          position: 'middle',
                        })
                    await toast.present()
                  }catch(error){
                    console.log(error)
                  }
                  await dispatch('partnership/ACT_GET_PARTNERSHIPS_REQUEST', partnershipId.value)

                },
                text: '네, 취소할게요',
              },
            ],
            cssClass: 'btn-groups suggest-alert-class',
            message: `
            <div class="alert_css">
              <div class="header">
                이 클럽딜 참여를<br>
                취소하시겠어요?
              </div>
              <div class="message">
                입력하신 모든 정보가 삭제되며,<br/>
                다른 클럽딜에 참여하실 수 있게 됩니다.
              </div>
            </div>`,
          })
        return alert.present()
      }
    }
    const ionRouter = useIonRouter()
    function goPrevious() {
      router.go(-1)
    }
    async function closeModal() {
      await modalController.dismiss()
    }

    function printStatus(status: string){
      if( ~['collected', 'completed'].indexOf(status)){
        return '(모집완료)'
      }else if(~['collecting'].indexOf(status)){
        return '(모집중)'
      }
    }

    const clickScreen = 'clubdeal'

    console.log(partnershipInfo.value)

    const onShare = () => {
      const partnershipStatus = partnershipInfo.value.partnershipStatus
      // "collecting"
      // "coming_soon"
      const url = `https://angelleague.io/link/club-deal/${partnershipInfo.value.partnershipId}`
      if (partnershipStatus === 'coming_soon') {
        const title = `${partnershipInfo.value.partnershipName} 확인하기`
        const text = `오픈 예정 ${partnershipInfo.value.partnershipName}(공동구매) 정보를 확인해 보세요. 하트 찜 하면 오픈전 알림을 보내드려요!`
        share({
          text,
          title,
          url,
        })
      } else {
        const title = `${partnershipInfo.value.partnershipName} 참여하기`
        const text = `선착순 종료 임박. ${partnershipInfo.value.partnershipName}(공동구매) 참여하고 주주가 되어보세요.`
        share({
          text,
          title,
          url,
        })
      }
      // bichi share club deal

    }

    return {
      arrowDownOutline,
      boardSummary,
      btnClick: buttonClick,
      btnToggle: buttonToggle,
      clickScreen,
      closeModal,
      doRefresh,
      frame783Png,
      frame783x2Png,
      frame783x3Png,
      fromCompany,
      getDate,
      goPrevious,
      handleScroll,
      hasSession,
      heartToggle,
      isLogin,
      numberToKorean,
      onShare,
      only3,
      openFAQModal,
      openLink,
      openPopover,
      openPopover2,
      openPopoverFee,
      partnershipInfo,
      partnershipLike,
      partnershipRequest,
      postPartnershipLike,
      printStatus,
      recommendPartnership,
      refresh,
      route,
      router,
      scrollTop,
      setPartnershipId,
      showBank,
      showToggle,
      submitButtons,
      useCompanyModal,
      user,
      userAvailablePoint,
      viewKakaoKlipModal,
    }
  },
})
