import {styled} from '@/plugins/emotion'

export const Content = styled('span', {
  name: 'Content',
  stylePortal: 'css',
  target: 'line-center',
})(
  {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontVariant: 'inherit',
    fontWeight: 'inherit',
    height: '100%',
    justifyContent: 'center',
    lineHeight: 'inherit',
    position: 'relative',
    userSelect: 'none',
    width: '100%',
  },
)
