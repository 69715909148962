
import {AgImg, AgTextS, AgBoxS} from '@/components/ag'
import {IonContent, IonText} from '@ionic/vue'
import {defineComponent} from 'vue'
import IntroPng from './assets/Intro.png'
// import Intro2xPng from './assets/Intro2x.png'
// import Intro3xPng from './assets/Intro3x.png'
import messages from './assets/messages.json'

const images = {
  intro: [
    IntroPng,
    // Intro2xPng,
    // Intro3xPng,
  ],
}

export const text = {
  message: '서비스가 오픈되면 바로 사용하실 수 있어요!',
  subtitle: '엔젤리그 서비스의 조합 지분거래 기능 오픈을 위해 잠시 공사중입니다. \uD83D\uDE4F',
  timeEnd: '06.28 12:00',
  timeMessage: '개편 작업 시간',
  title: '엔젤리그 서비스 공사중',
}

export default defineComponent({
  components: {
    AgBoxS,
    AgImg,
    AgTextS,
    IonContent,
    IonText,
  },
  name: 'UnderConstruction',
  setup() {
    return {
      images,
      text,
    }
  },
})
