import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_header = _resolveComponent("ag-header")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_ag_content = _resolveComponent("ag-content")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ag_button = _resolveComponent("ag-button")!
  const _component_ag_box = _resolveComponent("ag-box")!
  const _component_ag_footer = _resolveComponent("ag-footer")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    corner: "20",
    height: "auto",
    "model-value": _ctx.isOpenRef,
    "safe-area": true,
    "swipe-to-close": true,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(false)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_header, { handle: "" }),
      _createVNode(_component_ag_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_header, {
            title: "조합지분 구매 확인",
            onClose: _ctx.goToNext
          }, null, 8, ["onClose"]),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalPage)))
        ]),
        _: 1
      }),
      _createVNode(_component_ag_footer, {
        p: "20px",
        mb: "safeAreaBottom"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_box, {
            dp: "flex",
            gap: "sp6"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonText, (button, index) => {
                return (_openBlock(), _createBlock(_component_ag_button, {
                  key: button.title,
                  disabled: _ctx.isWaitingConfirm,
                  block: "lg",
                  corner: "10px",
                  height: "100%",
                  width: "100%",
                  outline: index === 0 && _ctx.modalPageName === 'FAIL' ? 'primary' : '',
                  onClickCapture: button.func
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_ion_spinner, { name: "crescent" }, null, 512), [
                      [_vShow, _ctx.isWaitingConfirm]
                    ]),
                    _createTextVNode(" " + _toDisplayString(button.title), 1)
                  ]),
                  _: 2
                }, 1032, ["disabled", "outline", "onClickCapture"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}