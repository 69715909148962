const l32 = '32px'
const l29 = '29px'
const l28 = '28px'
const l26 = '26px'
const l24 = '24px'
const l22 = '22px'
const l20 = '20px'
const l18 = '18px'
const l16 = '16px'
const l44 = '44px'

export const lineHeights = {
  l16, l18, l20, l22, l24, l26, l28, l29, l32, l44,
}

export type LineHeights = typeof lineHeights
export type LineHeightsType = keyof LineHeights
