import {styled, systems} from '@/plugins/emotion'

export const StyledText = styled('span', {
  name: 'StyledText',
  props: {
    css: null,
  },
  stylePortal: 'css',
})({
  wordBreak: 'keep-all',
}, systems)
