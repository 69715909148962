import {lastOne} from '@/utils'

export const getMayObjectItem = (value: any, index: number | string, substitute?: number | string) => {
  let result
  if (Array.isArray(value)) {
    result = value[Number(index)]
  } else if (typeof value === 'object') {
    result = value[String(index)]
  } else {
    return value
  }
  if (typeof substitute !== 'undefined' && typeof result === 'undefined') {
    if (Array.isArray(value) && typeof substitute === 'number' && substitute === -1) {
      return lastOne(...value)
    }
    return value[substitute]
  }
  return result
}
