import {systemPipe} from './utils'
import {compose, createVariant} from '@winter-love/style-system'
import {ExtractPropTypes, PropType} from 'vue'
import {animationProps, animationSystems} from './animation'
import {blockProps, blockSystems} from './block'
import {colorProps, colorSystems} from './color'
import {displayProps, displaySystems} from './display'
import {fillOutlineProps, fillOutlineSystems} from './fill-outline'
import {flexItemProps, flexItemSystems, flexProps, flexSystems} from './flex'
import {fontProps, fontSystems} from './fonts'
import {gridProps, gridSystems} from './grid'
import {lineHeightProps, lineHeightSystems} from './line-height'
import {placeProps, placeSystems} from './place'
import {positionProps, positionSystems} from './position'
import {cornerProps, cornerSystems} from './corner'
import {additionalSizeProps, additionalSizeSystems, sizeProps, sizeSystems} from './size'
import {spaceProps, spaceSystems} from './space'
import {textProps, textSystems} from './text'
import {typographyProp, typographySystem} from './typography'
import {borderProps, borderSystems} from './border'
import {cursorSystems, cursorProps} from './cursor'
import {opacityProps, opacitySystems} from './opacity'
import {filterProps, filterSystems} from './filter'
import {etcProps, etcSystems} from './etc'
import {imgSystems, imgProps} from './img'
import {boxShadowSystems, boxShadowProps} from './box-shadow'
import {backgroundSystem, backgroundColorProps} from './background'


export * from './block'
export * from './color'
export * from './display'
export * from './fill-outline'
export * from './flex'
export * from './fonts'
export * from './grid'
export * from './line-height'
export * from './place'
export * from './position'
export * from './corner'
export * from './animation'
export * from './size'
export * from './space'
export * from './text'
export * from './transition'
export * from './safe-area-pad'
export * from './typography'
export * from './outline-color'
export * from './fill-color'
export * from './utils'
export * from './cursor'
export * from './opacity'
export * from './filter'
export * from '@winter-love/style-system'

export const asProp = {type: [String, Function, Object]}
export const themeProp = {type: Object}

const commonSystems = compose(
  cursorSystems,
  borderSystems,
  backgroundSystem,
  textSystems,
  spaceSystems,
  sizeSystems,
  cornerSystems,
  fontSystems,
  boxShadowSystems,
  colorSystems,
  typographySystem,
  displaySystems,
  flexItemSystems,
  additionalSizeSystems,
  flexSystems,
  placeSystems,
  gridSystems,
  positionSystems,
  lineHeightSystems,
  opacitySystems,
  filterSystems,
  animationSystems,
  etcSystems,
  imgSystems,
  )
const _systems = systemPipe(
  // 뒤에 스타일을 앞에 스타일을 덥어 씌웁니다.
  // compose 는 같은 스타일 레밸로 들어갑니다. (+성능 향상)
  compose(
    fillOutlineSystems,
    blockSystems,
  ),
  commonSystems,
)

export const variant = createVariant(commonSystems)

export const systems = (props: any) => {
  return _systems({...props.css, ...props})
}

const _systemProps = {
  ...textProps,
  ...spaceProps,
  ...sizeProps,
  ...colorProps,
  ...cornerProps,
  ...flexProps,
  ...flexItemProps,
  ...displayProps,
  ...placeProps,
  ...gridProps,
  ...positionProps,
  ...additionalSizeProps,
  ...fillOutlineProps,
  ...borderProps,
  ...blockProps,
  ...cursorProps,
  ...filterProps,
  ...fontProps,
  ...lineHeightProps,
  ...opacityProps,
  ...animationProps,
  ...etcProps,
  ...imgProps,
  ...boxShadowProps,
  ...backgroundColorProps,
  theme: themeProp,
  typography: typographyProp,
}

export type _SystemProps = ExtractPropTypes<typeof _systemProps>

export const systemProps = {
  ..._systemProps,
  as: asProp,
  css: {type: Object as PropType<_SystemProps>},
}

export type SystemProps = ExtractPropTypes<typeof systemProps>
