import {styled, systems, systemProps} from '@/plugins/emotion'

export const Container = styled('div', {
  props: {
    ...systemProps,
    // for removing attrs
    failBackSrc: null,
    loading: null,
    showLoading: null,
    src: null,
  },
})(
  {
    height: 'auto',
    label: 'container',
    position: 'relative',
    width: 'auto',
  },
  systems,
)
