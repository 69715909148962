import {CSSObject} from '@emotion/css'
import {ColorsType} from '@/plugins/emotion'
import {colors} from '@/plugins/emotion/theme'
import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'
import style from '@styled-system/css'

const defaultStyle: CSSObject = {
  backgroundColor: colors.white,
  borderStyle: 'solid',
  borderWidth: '1px',
}

export type OutlineColorsType = ColorsType | string

export const outlineColorSystem = system({
  outlineColor: (value: string, _, props) => {
    return style({
      borderColor: value,
      color: value,
      ...defaultStyle,
    })(props)
  },
})

export const outlineColorProp = getResponsiveProp<OutlineColorsType>()
