
import {IonRippleEffect, modalController} from '@ionic/vue'
import {computed, defineComponent, inject, onBeforeMount, watch, ref, onMounted, toRef} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import ViewOptionModal from '@/components/Modal/ViewOptionModal.vue'
import {toFixed} from '@/utils/filters'
import SimpleLoginModal from '@/components/Modal/SimpleLoginModal.vue'
import useModal from '@/composables/useModal'
import {getFormattedDate} from '@/utils/filters'
import {AgBoxS, AgTextS} from '@/components/ag'

export default defineComponent({
  components: {AgBoxS, AgTextS, IonRippleEffect},
  name: 'InvestStatus',
  props: {
    parent: String,
  },
  setup(props) {
    const {state, dispatch, commit, getters} = useStore()
    const router = useRouter()
    const {useCompanyModal} = useModal()
    const token = computed(() => getters['user/getToken'])
    const user = computed(() => getters['user/getUser'])
    const homeClubDeals = computed(() => getters['partnership/getHomeClubDeals'])
    const likeStockItems = computed(() => getters['stockItem/getLikeStockItems'])

    const totalAsset = computed(() => getters['asset/getTotalAsset'])
    const assetPartnerships = computed(() => getters['asset/getAssetPartnerships'])
    const reflectAt = computed(() => getters['asset/getReflectAt'])

    const totalIncomingProfit = computed(() => getters['asset/getTotalIncomingProfit'])
    const totalIncomingProfitRate = computed(() => getters['asset/getTotalIncomingProfitRate'])

    const viewType = ref<any>('수익률순')
    const toggleValue = ref<any>(localStorage.getItem('money_flag') ? localStorage.getItem('money_flag') : 'on')
    const moneyFlag = computed(() => toggleValue.value)

    function buttonEventTrigger() {
      toggleValue.value = toggleValue.value === 'on' ? 'off' : 'on'
      localStorage.setItem('money_flag', toggleValue.value)
    }

    async function buttonEvent(e: any) {
      if (toggleValue.value === 'off') {
        const modal = await modalController
          .create({
            backdropDismiss: true,
            component: SimpleLoginModal,
            componentProps: {
              cb: buttonEventTrigger,
            },
            cssClass: 'simple-login-modal',
            id: 'SimpleLoginModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss()
          .then((data: any) => {
            const chBox: any = document.querySelector('#moneyCheckBox')
            chBox.checked = data.data === 'success'
          })

        return modal.present()
      }
      buttonEventTrigger()

    }

    onBeforeMount(() => {
      dispatch('stockItem/ACT_GET_POPULARITY')
      dispatch('asset/loadData')
      dispatch('asset/ACT_GET_ASSET_PARTNERSHIPS')
    })

    watch(viewType, () => {
      if (viewType.value === '평가금액순') {
        assetPartnerships.value.sort((a: any, b: any) => {
          const aAsset = isNaN(Number.parseInt(a.stockItemAsset)) ? -Number.MAX_VALUE : Number.parseInt(a.stockItemAsset)
          const bAsset = isNaN(Number.parseInt(b.stockItemAsset)) ? -Number.MAX_VALUE : Number.parseInt(b.stockItemAsset)
          const aProfit = isNaN(Number.parseInt(a.stockItemIncomingProfit)) ? 0 : Number.parseInt(a.stockItemIncomingProfit)
          const bProfit = isNaN(Number.parseInt(b.stockItemIncomingProfit)) ? 0 : Number.parseInt(b.stockItemIncomingProfit)
          const aProfitRate = isNaN(Number.parseFloat(a.stockItemIncomingProfitRate)) ? -Number.MAX_VALUE : Number.parseFloat(a.stockItemIncomingProfitRate)
          const bProfitRate = isNaN(Number.parseFloat(b.stockItemIncomingProfitRate)) ? -Number.MAX_VALUE : Number.parseFloat(b.stockItemIncomingProfitRate)

          if ((bAsset + bProfit) < (aAsset + aProfit)) {
            return -1
          } else if ((bAsset + bProfit) > (aAsset + aProfit)) {
            return 1
          }
            return bProfitRate - aProfitRate

        })
      } else {
        assetPartnerships.value.sort((a: any, b: any) => {
          const aAsset = isNaN(Number.parseInt(a.stockItemAsset)) ? -Number.MAX_VALUE : Number.parseInt(a.stockItemAsset)
          const bAsset = isNaN(Number.parseInt(b.stockItemAsset)) ? -Number.MAX_VALUE : Number.parseInt(b.stockItemAsset)
          const aProfit = isNaN(Number.parseInt(a.stockItemIncomingProfit)) ? -Number.MAX_VALUE : Number.parseInt(a.stockItemIncomingProfit)
          const bProfit = isNaN(Number.parseInt(b.stockItemIncomingProfit)) ? -Number.MAX_VALUE : Number.parseInt(b.stockItemIncomingProfit)
          const aProfitRate = isNaN(Number.parseFloat(a.stockItemIncomingProfitRate)) ? -Number.MAX_VALUE : Number.parseFloat(a.stockItemIncomingProfitRate)
          const bProfitRate = isNaN(Number.parseFloat(b.stockItemIncomingProfitRate)) ? -Number.MAX_VALUE : Number.parseFloat(b.stockItemIncomingProfitRate)

          if (bProfitRate < aProfitRate) {
            return -1
          } else if (bProfitRate > aProfitRate) {
            return 1
          }
            return (bAsset + bProfit) - (aAsset + aProfit)

          // (isNaN(parseFloat(b.stockItemIncomingProfitRate))?0:parseFloat(b.stockItemIncomingProfitRate)) - (isNaN(parseFloat(a.stockItemIncomingProfitRate))?0:parseFloat(a.stockItemIncomingProfitRate)));
        })
      }
    })

    const createModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: ViewOptionModal,
          componentProps: {
            requestType: 'invest',
            viewOption: 'none',
            viewType: viewType.value,
          },
          cssClass: 'view-option-modal',
          id: 'ViewOptionModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      modal.onDidDismiss().then(async (data) => {
        if (data.data.viewType) {
          viewType.value = await data.data.viewType
        }
      })

      return modal.present()
    }

    const formattedDate = (updateDate: any) => {
      return getFormattedDate(updateDate, 'MM.DD')
    }

    const parentRef = toRef(props, 'parent')

    return {
      assetPartnerships,
      btnEvent: buttonEvent,

      clickScreen:parentRef.value,

      createModal,

      formattedDate,

      homeClubDeals,

      likeStockItems,

      moneyFlag,

      reflectAt,

      // stockItems,
      router,

      toFixed,

      toggleValue,

      token,

      totalAsset,

      // totalProfitMoney, totalProfitPercent,
      totalIncomingProfit,
      totalIncomingProfitRate,
      useCompanyModal,
      user,
      viewType,
      // , popularList
    }
  },
  // components: { IonToggle },
})
