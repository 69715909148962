import Axios, {AxiosInstance, AxiosRequestConfig, AxiosError} from 'axios'
import {ref, Ref} from 'vue'
import {joinUrlMany} from './join-url'
import {UsePromiseReturnType, usePromise, UsePromiseOptions} from '../promise'
import {toArray} from '@/utils'

export type UseAxiosConfig = Omit<AxiosRequestConfig, 'cancelToken'>

export type RequestOptions = Pick<AxiosRequestConfig, 'data' | 'params' | 'headers'>

export type ExecuteRequestOptions = RequestOptions & {
  urlParams?: string[]
}

let _axios: AxiosInstance = Axios.create()

export const setDefaultAxios = (axios: AxiosInstance) => {
  _axios = axios
}

export const getDefaultAxios = (): AxiosInstance => {
  return _axios
}

export interface UseAxiosReturnType<Data, Execute, Error> extends UsePromiseReturnType<Data, [Execute], Error> {
  cancel: (message?: string) => void
  cancelError: Ref<Data | undefined>
}

export interface UseAxiosOptions<Data, OriginalData, Execute> extends UsePromiseOptions<[Execute]>{
  axiosInstance?: AxiosInstance
  transformRequest?: (request: ExecuteRequestOptions, config: AxiosRequestConfig) => Promise<ExecuteRequestOptions> | ExecuteRequestOptions
  transformResponse?: (data: OriginalData, config: AxiosRequestConfig) => Promise<Data> | Data
}

const _useAxios = <
  Data, Execute extends ExecuteRequestOptions,
  Error extends AxiosError = AxiosError,
  OriginalData = any,
  >(
    config: UseAxiosConfig = {},
    options: UseAxiosOptions<Data, OriginalData, Execute> = {},
  ) => {
  const {axiosInstance, transformRequest, transformResponse, immediate} = options
  const axiosCancel = Axios.CancelToken.source()
  const cancelErrorRef = ref<null | string>(null)

  const {execute, promise, count, data, error, fetching} =
    usePromise<Data, [Execute], Error>(async (options: Execute) => {
      const _axiosInstance = axiosInstance ?? getDefaultAxios()
      if (!_axiosInstance) {
        throw new Error('need the default axios instance or an axiosInstance in the arguments')
      }

      const {data = {}, params = {}, urlParams = []} = options ?? {}

      // get cancel token
      const cancelToken = axiosCancel.token

      const myOptions = {
        data: {...config.data, ...data},
        params: {...config.params, ...params},
        urlParams,
      }

      const newOptions = transformRequest ? await transformRequest(myOptions, config) : myOptions

      const newConfig = {
        ...config,
        cancelToken,
        data: newOptions.data,
        params: newOptions.params,
        url: joinUrlMany(config.url ?? '/', ...toArray<string>(newOptions.urlParams)),
      }

      return _axiosInstance(newConfig).then(async (response) => {
        const data = response.data ?? null

        // save response data
        // set fetching state
        if (transformResponse) {
          return transformResponse(data, newConfig)
        }
        return data
      }).catch((error_) => {

        // save only request error
        if (Axios.isCancel(error_)) {
          cancelErrorRef.value = error_
          return
        }

        throw error_
      })
    }, {
      immediate,
    })

  /**
   * cancel the request
   * @param message
   */
  const cancel = (message: string = 'unknown') => {
    axiosCancel.cancel(message)
  }

  return {
    cancel,
    cancelError: cancelErrorRef,
    count,
    data,
    error,
    execute,
    fetching,
    promise,
  }
}

export const useAxios = Object.assign(_useAxios, {
  get axios() {
    return getDefaultAxios()
  },
  set axios(axios: AxiosInstance) {
    setDefaultAxios(axios)
  },
})

export type UseAxios = typeof useAxios
