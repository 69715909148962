
import {defineComponent, ref, computed} from 'vue'
import {modalController, IonContent} from '@ionic/vue'
import {useStore} from 'vuex'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import AngelLeagueInfo from './Home/AngelLeagueInfo.vue'

export default defineComponent({
  components: {
    AngelLeagueInfo,
    IonContent,
  },
  name: 'FAQModal',
  props: {
    type: null,
  },
  setup(props) {
    const dismiss = async () => {
      await modalController.dismiss()
    }

    const typeValue = computed(() => props.type)

    const {getters, dispatch} = useStore()
    const FAQData = computed(() => getters['common/getFAQ'])
    const IntroData = computed(() => getters['common/getIntro'])
    const ProcessData = computed(() => getters['common/getProcess'])

    type ModalType = 'info' | 'FAQ' | 'process'
    const modalTypeRef = ref<ModalType>(props.type)
    const modalTitle = {'FAQ': '자주 묻는 질문', 'info' : '엔젤리그 소개', 'process':'조합 생성 과정'}

    const gtm = useGtm()
    gtm?.trackEvent({
      event: MODAL_OPEN_EVENT,
      page: 'FAQModal',
      title: `${modalTitle[modalTypeRef.value]} 모달`,
    })

    if (props.type === 'info') {
      dispatch('common/ACT_GET_INTRO_DATA')
    } else if (props.type === 'FAQ') {
      dispatch('common/ACT_GET_FAQ_DATA')
    } else if (props.type === 'process') {
      dispatch('common/ACT_GET_PROCESS_DATA')
    }

    const questionArray = ref(Array.from({length: 100}, () => false))

    const showToggle = (index: any) => {
      questionArray.value[index] = !questionArray.value[index]
    }

    return {FAQData, IntroData, ProcessData, dismiss, questionArr: questionArray, showToggle, typeValue}
  },
})
