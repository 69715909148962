
import {defineComponent, computed, onBeforeMount, onUpdated, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {toFixed, prettyDateToOnemonth, printContent, openLink} from '@/utils/filters'
import StarRatingInline from '@/components/Board/StarRatingInline.vue'
import PrintOgData from '@/components/Board/PrintOgData.vue'
import {IonRippleEffect, IonInfiniteScroll, IonInfiniteScrollContent} from '@ionic/vue'
import useModal from '@/composables/useModal'
import apiStockItem from '@/api/stock-item'
const {
  stockItemBoardLike,
} = apiStockItem()

export default defineComponent({
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRippleEffect,
    PrintOgData,
    StarRatingInline,
  },
  name: 'BoardListAll',
  props: {
    stockItemBoardsProp: null,
  },
  setup(props) {
    const {getters, dispatch, commit} = useStore()
    const router = useRouter()
    const {useCompanyModal} = useModal()

    const user = computed(() => getters['user/getUser'])

    const stockItemBoards = computed(() => props.stockItemBoardsProp)

    async function boardLike(board: any) {
      await stockItemBoardLike(board.stockItemId, board.stockItemBoardId)
      dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS_FOR_HOME', {page: 1})
    }

    function boardClick(data: any, event: any) {
      if (event.target.tagName === 'A') {
        openLink(event.target.dataset.href)
      }
    }

    const clickScreen = 'discussion'

    return {
      boardClick,
      boardLike,
      clickScreen,
      openLink,
      prettyDateToOnemonth,
      printContent,
      router,
      stockItemBoards,
      toFixed,
      useCompanyModal,
      user,
    }
  },
})
