import {Plugins} from '@capacitor/core'
const {Storage} = Plugins

export async function setObject(key: string): Promise<void> {
  await Storage.set({
    key: key,
    value: JSON.stringify([]),
  })
}

export async function get(key: string): Promise<any> {
  const item: any = await Storage.get({key: key})
  return JSON.parse(item.value)
}

export async function remove(key: string): Promise<void> {
  await Storage.remove({
    key: key,
  })
}

export async function deleteItem(key: string) {
  const item: any = await Storage.get({key: key})
  const listsObject = JSON.parse(item.value)
  listsObject.shift()
  await Storage.set({
    key: key,
    value: JSON.stringify(listsObject),
  })
}

export async function set(key: string, value: any): Promise<void> {
  const item: any = await Storage.get({key: key})
  let listsObject = JSON.parse(item.value)
  // 예외처리 1. list가 비어있는 경우
  if (listsObject === null) {
    await setObject(key)
    const item: any = await Storage.get({key: key})
    listsObject = JSON.parse(item.value)
  } else {
    const index = listsObject.indexOf(value)
    if (~index) {
      listsObject.splice(index, 1)
    } else if (listsObject.length >= 5) { // 예외처리 2. list가 5개 이상인 경우
      await deleteItem(key)
      const item: any = await Storage.get({key: key})
      listsObject = JSON.parse(item.value)
    }
  }
  listsObject.push(value)
  await Storage.set({
    key: key,
    value: JSON.stringify(listsObject),
  })
}

// export async function getItem(key: string) {
//   const { value } = await Storage.get({ key: key });
//   // console.log('Got item: ', value);
// }

// export async function deleteItem(key: string) {
//   const item: any = await Storage.get({ key: key });
//   const listsObject = JSON.parse(item.value);
//   listsObject['dealInfo'].shift();
//   await Storage.set({
//     key: key,
//     value: JSON.stringify(listsObject),
//   });
// }
