
import {defineComponent, computed, toRef, ref, watch, PropType} from 'vue'
import {Stock} from 'src/views/search/types'
import SearchStockItem from './SearchStockItem.vue'
import {IonCard, IonCardSubtitle, IonCardContent, IonCardHeader} from '@ionic/vue'

const text = {
  title: '최근 본 회사',
}

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    SearchStockItem,
  },
  emits: ['select'],
  name: 'SearchLatestList',
  props: {
    item: {type: Object as PropType<Stock>},
    maxCount: {default: 5, type: Number},
  },
  setup(props) {
    const maxCount = toRef(props, 'maxCount')
    const history = ref<Stock[]>([])
    const item = toRef(props, 'item')
    const isShow = computed(() => {
      return Object.keys(history.value).length > 0
    })
    const hasHistory = (id: number) => {
      return history.value.some((item) => {
        return item.stockItemId === id
      })
    }

    const onSaveHistory = (item?: Stock) => {
      if (!item) {
        return
      }
      const has = hasHistory(item.stockItemId)
      if (has) {
        return
      }

      if (history.value.length === maxCount.value) {
        history.value.shift()
      }

      history.value.push(item)
    }

    watch(item, onSaveHistory)

    return {
      history,
      isShow,
      text,
    }
  },
})
