import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'
import {Property} from 'csstype'

export const positionSystems = system({
  bottom: true,
  left: true,
  position: true,
  right: true,
  top: true,
  transform: true,
})

export type PositionType = Property.Position | number
export type LeftType = Property.Left | number
export type RightType = Property.Right | number
export type TopType = Property.Top | number
export type BottomType = Property.Bottom | number

export const positionProps = {
  bottom: getResponsiveProp<BottomType>(),
  left: getResponsiveProp<LeftType>(),
  position: getResponsiveProp<PositionType>(),
  right: getResponsiveProp<RightType>(),
  top: getResponsiveProp<TopType>(),
}
