import {SpaceType} from '@/plugins/emotion'
import {getResponsiveProp} from './utils'
import {compose, system} from '@winter-love/style-system'

export const paddingSystems = system({
  p: {
    property: 'padding',
    scale: 'space',
  },
  padding: {
    property: 'padding',
    scale: 'space',
  },
  paddingBottom: {
    property: 'paddingBottom',
    scale: 'space',
  },
  paddingLeft: {
    property: 'paddingLeft',
    scale: 'space',
  },
  paddingRight: {
    property: 'paddingRight',
    scale: 'space',
  },
  paddingTop: {
    property: 'paddingTop',
    scale: 'space',
  },
  paddingX: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
  },
  paddingY: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
  },
  pb: {
    property: 'paddingBottom',
    scale: 'space',
  },
  pl: {
    property: 'paddingLeft',
    scale: 'space',
  },
  pr: {
    property: 'paddingRight',
    scale: 'space',
  },
  pt: {
    property: 'paddingTop',
    scale: 'space',
  },
  px: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
  },
  py: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
  },
})
export const marginSystems = system({
  m: {
    property: 'margin',
    scale: 'space',
  },
  margin: {
    property: 'margin',
    scale: 'space',
  },
  marginBottom: {
    property: 'marginBottom',
    scale: 'space',
  },
  marginLeft: {
    property: 'marginLeft',
    scale: 'space',
  },
  marginRight: {
    property: 'marginRight',
    scale: 'space',
  },
  marginTop: {
    property: 'marginTop',
    scale: 'space',
  },
  marginX: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'space',
  },
  marginY: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'space',
  },
  mb: {
    property: 'marginBottom',
    scale: 'space',
  },
  ml: {
    property: 'marginLeft',
    scale: 'space',
  },
  mr: {
    property: 'marginRight',
    scale: 'space',
  },
  mt: {
    property: 'marginTop',
    scale: 'space',
  },
  mx: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'space',
  },
  my: {
    properties: ['marginBottom', 'marginTop'],
    scale: 'space',
  },
})
export const spaceProp = getResponsiveProp<SpaceType>()
export const paddingProps = {
  p: spaceProp,
  padding: spaceProp,
  paddingBottom: spaceProp,
  paddingLeft: spaceProp,
  paddingRight: spaceProp,
  paddingTop: spaceProp,
  paddingX: spaceProp,
  paddingY: spaceProp,
  pb: spaceProp,
  pl: spaceProp,
  pr: spaceProp,
  pt: spaceProp,
  px: spaceProp,
  py: spaceProp,
}

export const marginProps = {
  m: spaceProp,
  margin: spaceProp,
  marginBottom: spaceProp,
  marginLeft: spaceProp,
  marginRight: spaceProp,
  marginTop: spaceProp,
  marginX: spaceProp,
  marginY: spaceProp,
  mb: spaceProp,
  ml: spaceProp,
  mr: spaceProp,
  mt: spaceProp,
  mx: spaceProp,
  my: spaceProp,
}

export const spaceSystems = compose(paddingSystems, marginSystems)

export const spaceProps = {
  ...paddingProps,
  ...marginProps,
}
