import {sizes} from './sizes'
import {CSSObject} from '@emotion/css'

const xsm: CSSObject = {
  '&.block-content': {
    height: sizes.inXsm,
  },
  '.block-content': {
    height: sizes.inXsm,
  },
}

const sm: CSSObject = {
  '&.block-content': {
    height: sizes.inSm,
  },
  '.block-content': {
    height: sizes.inSm,
  },
}

const md: CSSObject = {
  '&.block-content': {
    height: sizes.inMd,
  },
  '.block-content': {
    height: sizes.inMd,
  },
}

const lg: CSSObject = {
  '&.block-content': {
    height: sizes.inLg,
  },
  '.block-content': {
    height: sizes.inLg,
  },
}

export const innerBlocks = {
  lg, md, sm, xsm,
}

// todo theme 에 연결 안되었습니다.
export type InnerBlocks = typeof innerBlocks
export type InnerBlocksType = keyof InnerBlocks
