
import {AgButton, AgText, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {defineComponent, computed, defineAsyncComponent, toRef} from 'vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {useStore} from 'vuex'

const modalPages = {
  FAIL: defineAsyncComponent(() => import('./component/FailToRegist.vue')),
  SUCCESS: defineAsyncComponent(() => import('./component/RegistedStake.vue')),
}
export type ModalPageName = keyof typeof modalPages

export default defineComponent({
  components: {
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgModal,
    AgText,
    ModalHeader,
  },
  emits: ['close', 'edit', 'reload'],
  name: 'ViewMyStake',
  props: {
    isOpen: {require: true, type: Boolean},
    openType: {type: String},
  },
  setup(props, {emit}) {
    const {getters} = useStore()
    const openTypeRef = toRef(props, 'openType')
    const registerResult = computed(() => getters['stakeDeal/registerResult'])
    const setOpen = () => emit('close')
    const modalPageName = computed(() => openTypeRef.value === 'result' && !registerResult.value.status ? 'FAIL' : 'SUCCESS')
    const modalPage = computed(() => modalPages[modalPageName.value])

    const closeModal = () => emit('close')

    return {closeModal, modalPage, registerResult, setOpen}
  },
})
