import {styled, systemProps, systems} from '@/plugins/emotion'

export const styledBoxProps = {
  ...systemProps,
}

export const StyledBox = styled('div', {
  name: 'StyledBox',
  props: {
    css: {default: () => ({}), type: Object},
  },
  stylePortal: 'css',
  target: 'block-content component-root',
})(
  {
    position: 'relative',
  },
  systems,
)
