import {styled, colorSystems, filterSystems, filterProps, colorProps} from '@/plugins/emotion'

export const progressBarProps = {
  ...colorProps,
  ...filterProps,
}

export const ProgressBar = styled('div', {
  name: 'ProgressBar',
  props: {
    ...progressBarProps,
  },
})(
  {
    backgroundColor: 'inherit',
    height: '100%',
    transition: 'width 0.1s',
  },
  filterSystems,
  colorSystems,
)
