import {defineComponent, h} from 'vue'
import {Refresher, refresherProps} from './components'
import {IonRefresherContent} from '@ionic/vue'
import {syncOutline} from 'ionicons/icons'

export const agRefresher = {
  ...refresherProps,
}

export const AgRefresher = defineComponent({
  emits: ['refresh', 'pull', 'start'],
  name: 'AgRefresher',
  props: {
    ...agRefresher,
  },
  setup(props, {attrs, emit}) {
    const onRefresh = (event: any) => {
      return emit('refresh', event)
    }
    const onPull = () => {
      return emit('pull')
    }
    const onStart = () => {
      return emit('start')
    }
    return () => (
      h(Refresher, {...attrs, ...props, onIonPull: onPull, onIonRefresh: onRefresh, onIonStart: onStart}, () => [
        h(IonRefresherContent, {pullingIcon: syncOutline, refreshingSpinner: 'crescent'}),
      ])
    )
  },
})
