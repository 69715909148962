import Delete from '../assets/Delete.svg'
import {styled, systemProps, systems} from '@/plugins/emotion'

export const deleteIconProps = {
  ...systemProps,
}

export const DeleteIcon = styled(Delete, {
  props: {
    ...deleteIconProps,
  },
})(
  systems,
)
