export type UpdateObjectGetter<Key extends string = string, Data = any> = (record: Record<Key, Data>, key: Key) => Data
const defaultGetter = <Key extends string, Data = any>(record: Record<Key, Data>, key: Key): Data => {
  return record[key]
}

export type UpdateObjectSetter<Key extends string = string, Data = any> = (record: Record<Key, Data>, key: Key, value: Data) => void
const defaultSetter = <Key extends string = string, Data = any>(record: Record<Key, Data>, key: Key, value: Data): void => {
  record[key] = value
}

export interface UpdateObjectOptions {
  getter?: UpdateObjectGetter
  setter?: UpdateObjectSetter
}

export const updateObject = <T extends Record<string, any>>(
  target: T,
  data: Partial<T>,
  options: UpdateObjectOptions = {},
): T => {
  const {
    getter = defaultGetter,
    setter = defaultSetter,
  } = options
  Object.keys(data).forEach((key) => {
    setter(target, key, getter(data, key))
  })
  return target
}
