
import {IonContent} from '@ionic/vue'
import {AgBox, AgText} from '@/components/ag'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {
    AgBox,
    AgText,
    IonContent,
  },
  name: 'ClubDealHelp',
})
