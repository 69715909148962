import LoginModal from '@/components/Modal/LoginModal.vue'
import SimpleLoginModal from '@/components/Modal/SimpleLoginModal.vue'
import {modalController} from '@/plugins/ionic'
import {store} from '@/vuex'
import {Plugin} from 'vue'

export const modal: Plugin = (app) => {
  app.config.globalProperties.$global = {
    async openLoginModal(callback: any) {
      const user = store.getters['user/getUser']
      const uuid = store.getters['user/getUuid']
      if (!user) { // todo login 후 해당 페이지 다시 접근
        if (!uuid) {
          const modal = await modalController
          .create({
            backdropDismiss: true,
            component: LoginModal,
            componentProps: {
              cb: callback,
            },
            cssClass: 'login-modal',
            id: 'LoginModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })
          return modal.present()
        } 
          const modal = await modalController
          .create({
            backdropDismiss: true,
            component: SimpleLoginModal,
            componentProps: {
              cb: callback,
            },
            cssClass: 'simple-login-modal',
            id: 'SimpleLoginModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })
          return modal.present()
        
      }
    },
  }
}

export default modal
