import {Module} from 'vuex'
import {RootState} from '..'
import apiPreApplication from '@/api/pre-application'
const {getPreApplicationData} = apiPreApplication()

// state
interface PreApplication {
  totalFreeDay: number
  userBankAccount: Record<any, any>
  userData: Record<any, any>
  userStockBankAccount: Record<any, any>
}

// action
const PreApplication: Module<PreApplication, RootState> = {
  
actions: {
    async ACT_SET_DATA({commit}) {
      const result = await getPreApplicationData()
      commit('SET_USER_DATA', result)
      commit('SET_USER_BANK_ACCOUNT', result.userBankAccount)
      commit('SET_STOCK_BANK_ACCOUNT', result.userStockBankAccount)
      commit('SET_STOCK_TOTAL_FREE_DAY', result.totalFreeDay)

      return result
    },
  },
  

getters: {
    getTotalFreeDay: state => state.totalFreeDay,
    getUserBankAccount: state => state.userBankAccount,
    getUserData: state => state.userData,
    getUserStockBankAccount: state => state.userStockBankAccount,
  },
  
  // RootState와 똑같이 작성해도 된다.
mutations: {
    SET_STOCK_BANK_ACCOUNT(state, userStockBankAccount: any) {
      state.userStockBankAccount = userStockBankAccount
    },
    SET_STOCK_TOTAL_FREE_DAY(state, totalFreeDay: any) {
      state.totalFreeDay = totalFreeDay
    },
    SET_USER_BANK_ACCOUNT(state, userBankAccount: any) {
      state.userBankAccount = userBankAccount
    },
    SET_USER_DATA(state, result: any) {
      state.userData = {
        address1: result.address1,
        address2: result.address2,
        inviteCode: result.inviteCode,
        zipcode: result.zipcode,
      }
    },
  },
  namespaced: true,
  state: {
    totalFreeDay: 0,
    userBankAccount: {},
    userData: {},
    userStockBankAccount: {},
  },
}

export default PreApplication
