import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'

export type FilterPropType = string

export const filterSystems = system({
  backdropFilter: {
    property: 'backdropFilter',
    scale: 'filters',
  },
  filter: {
    property: 'filter',
    scale: 'filters',
  },
})

export const filterProps = {
  backdropFilter: getResponsiveProp<FilterPropType>(),
  filter: getResponsiveProp<FilterPropType>(),
}
