import {Module} from 'vuex'
import {RootState} from '..'
import apiPartnership from '@/api/partnership'
const {getPartnershipsOpen, requestPartnership, requestPartnershipOpen, getContract, getPartnerships, getPartnershipsRequest, partnershipLike, getHistories, getRecommendPartnerships, getExceptPartnerships, getPartnershipsHomePrepares} = apiPartnership()
// state
interface Partnership {
  anotherNotYet: Record<any, any>
  boardSummary: any[]
  collectingLeadDeals: any[]
  collectingNoLeadDeals: any[]
  contract: Record<any, any>
  depositInfo: Record<any, any>
  entireRecommendPartnership: any[]
  exceptRecommendPartnership: any[]
  exitDeals: any[]
  homeDeals: any[]
  partnershipHistories: any[]
  partnershipInfo: any
  partnershipOpen: Record<any, any>
  partnershipRequest: Record<any, any>
  prepareDeals: any[]
  preparesPartnerships: any[]
  recentCollectedDeals: any[]
  recommendPartnership: any[]
  submitButtons: any[]
  upcomingLeadDeals: any
  upcomingNoLeadDeals: any
}

// const obj = {
//   collecting : ""
// }

// action
const Partnership: Module<Partnership, RootState> = {
  
actions: {
    async ACT_GET_ALL_PARTNERSHIPS({commit}) {
      const result = await getPartnerships()

      commit('SET_CLUB_DEALS', result)
      commit('SET_EXIT_DEALS', result.exitDeals)
      commit('SET_PARTNERSHIP_HISTORIES', result.partnershipHistories)
      // // console.log(result)
    },
    async ACT_GET_CONTRACT({commit}) {
      const result = await getContract()
      commit('SET_CONTRACT', result)
    },
    async ACT_GET_DEPOSIT_BANKINFO({commit}, {partnershipId, usePoint, investMoney}) {
      const result = await requestPartnership(partnershipId, usePoint, investMoney)
      commit('SET_DEPOSIT_INFO', result)
    },
    async ACT_GET_ENTIRE_RECOMMEND_PARTNERSHIPS({commit}) {
      const result = await getRecommendPartnerships()
      commit('SET_ENTIRE_RECOMMEND_PARTNERSHIP', result.recommendList)
    },
    async ACT_GET_EXCEPT_RECOMMEND_PARTNERSHIPS({commit}, {partnershipId}) {
      const result = await getExceptPartnerships(partnershipId)
      commit('SET_EXCEPT_RECOMMEND_PARTNERSHIP', result.recommendList)
    },
    async ACT_GET_HISTORIES({commit}, page) {
      const result = await getHistories(page)
      commit('SET_ADD_PARTNERSHIP_HISTORIES', result.partnershipHistories)
    },
    async ACT_GET_OPEN_DEPOSIT_BANKINFO({commit}, {partnershipId, usePoint, investMoney}) {
      const result = await requestPartnershipOpen(partnershipId, usePoint, investMoney)
      commit('SET_DEPOSIT_INFO', result)
    },
    async ACT_GET_PARTNERSHIPS_OPEN({commit}, partnershipId: number) {
      try {
        const result = await getPartnershipsOpen(partnershipId)
        commit('SET_PARTNERSHIP_OPEN', result)
        return result
      } catch {
        return null
      }
    },
    async ACT_GET_PARTNERSHIPS_REQUEST({commit}, partnershipId: number) {
      const result = await getPartnershipsRequest(partnershipId)
      commit('SET_PARTNERSHIP_INFO', result)
    },
    async ACT_GET_PREPARE_PARTNERSHIPS({commit}) {
      const result = await getPartnershipsHomePrepares()
      commit('SET_PREPARE_PARTNERSHIPS', result)
    },
    async ACT_LIKE_PARTNERSHIP_DETAIL({commit}, {partnershipId}) {
      const result = await partnershipLike(partnershipId)
      commit('SET_CHANGE_LIKE_COUNT', result.likeInfo)
      // commit('SET_REPLACE_LIKE_COUNT', {partnershipStatus, target, likeInfo:result.likeInfo})
    },
    async ACT_LIKE_PARTNERSHIP_TARGET({commit}, {partnershipStatus, target, partnershipId}) {
      // console.log(partnershipStatus)
      // console.log(target)
      // console.log(partnershipId)

      const result = await partnershipLike(partnershipId)
      commit('SET_REPLACE_LIKE_COUNT', {likeInfo: result.likeInfo, partnershipStatus, target})
      return result
    },
  },
  

getters: {
    getAnotherNotYet: (state) => state.anotherNotYet,
    getBoardSummary: (state) => state.boardSummary,
    getClubDealHistories: (state) => state.partnershipHistories,
    getCollectingLeadDeals: (state) => state.collectingLeadDeals,
    getCollectingNoLeadDeals: (state) => state.collectingNoLeadDeals,
    getContract: (state) => state.contract,
    getDepositInfo: (state) => state.depositInfo,
    getEntireRecommendPartnership: (state) => state.entireRecommendPartnership,
    getExceptRecommendPartnership: (state) => state.exceptRecommendPartnership,
    getExitDeals: (state) => state.exitDeals,
    getHomeClubDeals: (state) => state.homeDeals,
    getPartnershipInfo: (state) => state.partnershipInfo,
    getPartnershipOpen: (state) => state.partnershipOpen,
    getPartnershipRequest: (state) => state.partnershipRequest,
    getPrepareDeals: (state) => state.prepareDeals,
    getPreparesPartnerships: (state) => state.preparesPartnerships,
    getRecentCollectedDeals: (state) => state.recentCollectedDeals,
    getRecommendPartnership: (state) => state.recommendPartnership,
    getSubmitButtons: (state) => state.submitButtons,
    getUpcomingLeadDeals: (state) => state.upcomingLeadDeals,
    getUpcomingNoLeadDeals: (state) => state.upcomingNoLeadDeals,
  },
  
  // RootState와 똑같이 작성해도 된다.
mutations: {
    SET_ADD_PARTNERSHIP_HISTORIES(state, partnershipHistories: any) {
      state.partnershipHistories = [...state.partnershipHistories, ...partnershipHistories]
      // console.log(state.partnershipHistories)
    },
    SET_CHANGE_LIKE_COUNT(state, likeInfo) {
      state.partnershipInfo = {
        ...state.partnershipInfo,
        ...likeInfo,
      }
    },
    SET_CLUB_DEALS(state, clubDeals: any) {
      state.upcomingLeadDeals = clubDeals.upcomingLeadDeals
      state.upcomingNoLeadDeals = clubDeals.upcomingNoLeadDeals
      state.collectingNoLeadDeals = clubDeals.collectingNoLeadDeals
      state.collectingLeadDeals = clubDeals.collectingLeadDeals
      state.recentCollectedDeals = clubDeals.recentCollectedDeals
      state.prepareDeals = clubDeals.prepareDeals
      state.homeDeals = clubDeals.homeDeals
    },
    SET_CONTRACT(state, contract) {
      state.contract = contract
    },
    SET_DEPOSIT_INFO(state, depositInfo) {
      state.depositInfo = depositInfo
    },
    SET_ENTIRE_RECOMMEND_PARTNERSHIP(state, list: any) {
      state.entireRecommendPartnership = list
    },
    SET_EXCEPT_RECOMMEND_PARTNERSHIP(state, list: any) {
      state.exceptRecommendPartnership = list
    },
    SET_EXIT_DEALS(state, exitDeals: any) {
      state.exitDeals = exitDeals
    },
    SET_PARTNERSHIP_HISTORIES(state, partnershipHistories: any) {
      state.partnershipHistories = partnershipHistories
    },
    SET_PARTNERSHIP_INFO(state, partnershipInfo: any) {
      state.partnershipInfo = partnershipInfo.partnershipInfo
      state.boardSummary = partnershipInfo.boardSummary
      state.recommendPartnership = partnershipInfo.recommendPartnership
      state.submitButtons = partnershipInfo.submitButtons
      state.anotherNotYet = partnershipInfo.anotherNotYet
      state.partnershipRequest = partnershipInfo.partnershipRequest
    },
    SET_PARTNERSHIP_OPEN(state, partnershipInfo: any) {
      state.partnershipOpen = partnershipInfo
    },
    SET_PREPARE_PARTNERSHIPS(state, preparesPartnerships: any) {
      state.preparesPartnerships = preparesPartnerships
    },
    SET_REPLACE_LIKE_COUNT(state, {partnershipStatus, target, likeInfo}) {
      let filtered = []

      if (partnershipStatus === 'coming_soon') {
        const partnerships: Record<any, any> = (state as any)[target]
        filtered = Object.values(partnerships).reduce((a, b) => [...a, ...b], []).filter((b: any) => b.partnershipId === likeInfo.partnershipId)
      } else {
        const partnerships: any = (state as any)[target]
        filtered = partnerships.filter((b: any) => b.partnershipId === likeInfo.partnershipId)
      }
      if (filtered.length > 0) {
        filtered[0].partnershipLikeId = likeInfo.partnershipLikeId
        filtered[0].likeCount = likeInfo.likeCount
      }
    },
  },
  namespaced: true,
  state: {
    anotherNotYet: {},
    boardSummary: [],
    collectingLeadDeals: [],
    collectingNoLeadDeals: [],
    contract: {},
    depositInfo: {},
    entireRecommendPartnership: [],
    exceptRecommendPartnership: [],
    exitDeals: [],
    homeDeals: [],
    partnershipHistories: [],
    partnershipInfo: {},
    partnershipOpen: {},
    partnershipRequest: {},
    prepareDeals: [],
    preparesPartnerships: [],
    recentCollectedDeals: [],
    recommendPartnership: [],
    submitButtons: [],
    upcomingLeadDeals: {},
    upcomingNoLeadDeals: {},
  },
}

export default Partnership
