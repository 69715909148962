import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client/core'
import {setContext} from '@apollo/client/link/context'
import {DefaultApolloClient} from '@vue/apollo-composable'
import {createApolloProvider} from '@vue/apollo-option'
import {App} from 'vue'

const httpLink = createHttpLink({
  uri: `${process.env.VUE_APP_API_URL}/graphql`,
  useGETForQueries: true,
})

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const apolloClient = new ApolloClient({

  cache: new InMemoryCache({
    addTypename: false,
    resultCaching: false,
  }),
  // connectToDevTools: true,
  link: authLink.concat(httpLink),
})

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

export default (app: App) => {
  apolloProvider.install(app)
  app.provide(DefaultApolloClient, apolloClient)
}
