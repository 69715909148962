
import {modalController, IonRippleEffect} from '@ionic/vue'
import {useStore} from 'vuex'
import {onUpdated, defineComponent, reactive, ref, watch, onBeforeMount} from 'vue'
import {useRouter, onBeforeRouteLeave} from 'vue-router'
import LoginModal from '@/components/Modal/LoginModal.vue'
import {Plugins} from '@capacitor/core'
const {Browser} = Plugins
import {AgImg, AgBoxS, AgAlphabetKeypad} from '@/components/ag'

export default defineComponent({
  components: {
    AgAlphabetKeypad,
    AgBoxS,
    AgImg,
    IonRippleEffect,
  },
  name: 'Login',
  props: {
    cb: null,
  },
  setup(props) {
    const reValue = reactive({password: '', passwordCheck: '', stepToggle: false})

    const resetRevalue = () => {
      reValue.password = ''
      reValue.passwordCheck = ''
      reValue.stepToggle = false
    }

    onBeforeRouteLeave((to: any, from: any) => {
      resetRevalue()
    })

    const {commit, getters, dispatch} = useStore()
    const router = useRouter()

    const processingStatus = ref<string>('init')
    const credentials = ref<{ email: string; password: string }>({
      email: 'es.ko@capbox.kr',
      password: '123',
    })

    const loginTitle = ref('')
    const loginMessage = ref('')
    const wrongPassword = ref(false)

    const uuid = ref('TESTER')

    const loginTitlePool = ref<any>({
      invalidSamePasswordCheck: '비밀번호를\n다시 입력해주세요',
      invalidSimplePassword: '다른 비밀번호를\n입력해주세요',
      loginSimplePassword: '간편로그인\n비밀번호를 입력해주세요',
      message: '간편로그인\n비밀번호를 등록해주세요',
      registSimplePassword: '간편로그인\n비밀번호를 등록해주세요',
      repeatSimplePassword: '비밀번호를\n한번 더 입력해주세요',
    })

    const loginMessagePool = ref<any>({
      default: '숫자 6자리 + 문자 1자리',
      differentPassword: '비밀번호가 다릅니다.',
      differentPasswordAsBefore: '이전 페이지에서 입력한 비밀번호와 같지 않습니다',
      duplicateThreeDigit: '같은 숫자를 3개 연속 사용하면 위험합니다.',
      wrongPassword: '잘못 입력하셨어요.',
    })
    loginMessage.value = loginMessagePool.value.default

    function resetFromStart() {
      resetRevalue()
      loginTitle.value = loginTitlePool.value.registSimplePassword
      loginMessage.value = loginMessagePool.value.default
      wrongPassword.value = false
    }

    //
    // 비밀번호를<br/>
    // 한번 더 입력해주세요

    const initData = () => {
      processingStatus.value = 'simple_login'
      loginTitle.value = loginTitlePool.value.loginSimplePassword
    }
    onBeforeMount(() => {
      // console.log('Component is onBeforeMount!');
      initData()
    })
    // onUpdated(()=>{
    //   // console.log('SimpleLogin Component is onUpdated!');
    //   initData();
    // })

    const checkProcessStatus = (status: string) => {
      return processingStatus.value === status
    }

    const duplicateCount = (text: string) => {
      if (/(\w)\1\1/.test(text)) {
        return true
      }
      return false
    }

    const passwordValidation = (password: string) => {
      let isValid = true
      let message = ''
      const dup = duplicateCount(password)

      if (dup) {
        isValid = false
        message = loginMessagePool.value.duplicateThreeDigit
      }
      return {isValid, message}
    }

    watch(reValue, async (after, previous) => {
      console.log(after, previous)
      console.log(after.passwordCheck.length, '------')
      if (after.password.length === 7) {
        if (checkProcessStatus('simple_login')) {
          // console.log('go login!');
          const account = await dispatch('user/ACT_TESTER_LOGIN', reValue.password)
          if (!account.error) {
            await modalController.dismiss('success')
            if (props.cb && typeof props.cb === 'function') {
              props.cb()
            }
          } else {
            reValue.password = ''
            loginMessage.value = `${loginMessagePool.value.wrongPassword} (${account.error.response.data.message})`
            wrongPassword.value = true
          }
        }
      } else if (after.password.length === 1) { // 1개 입력한 경우 원래 메세지로 되돌려 줌
        if (checkProcessStatus('simple_login')) {
          loginTitle.value = loginTitlePool.value.loginSimplePassword
        }
        loginMessage.value = loginMessagePool.value.default
        wrongPassword.value = false
      }
    })

    function addNumber(_value: any) {
      let value = _value
      // console.log(value);
      value = value.toString()
      let result = ''
      if (reValue.stepToggle) {
        result = reValue.passwordCheck
      } else {
        result = reValue.password
      }
      if (result.length === 6) {
return
}
      result += value

      if (reValue.stepToggle) {
        reValue.passwordCheck = result
      } else {
        reValue.password = result
      }
    }

    function clearNumber(value: any) {
      let result = ''

      if (reValue.stepToggle) {
        result = reValue.passwordCheck
      } else {
        result = reValue.password
      }

      if (value === 'clear') {
        result = ''
      } else if (value === 'del') {
        if (result === '') {
          return
        }
          result = result.slice(0, - 1)

      }

      if (reValue.stepToggle) {
        reValue.passwordCheck = result
      } else {
        reValue.password = result
      }
    }

    function addAlphabet(value: any) {
      if (value === 'del') {
        clearNumber('del')
        return
      }

      if (reValue.stepToggle) {
        reValue.passwordCheck += value
      } else {
        reValue.password += value
      }
    }

    function addDot() {
      if (!reValue.passwordCheck.includes('.')) {
        reValue.passwordCheck += '.'
      }
    }

    return {
      addAlphabet,
      addDot,
      addNumber,
      checkProcessStatus,
      clearNumber,
      credentials,
      loginMessage,
      loginTitle,
      processingStatus,
      reValue,
      resetFromStart,
      resetRevalue,
      wrongPassword,
    }
    // => data return
  },
})
