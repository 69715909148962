import {authHeader} from '../api/auth-header'
import {API_URL} from '../config'
import axios from 'axios'
import {store} from '../vuex'
import router from '../router'
import {modalController} from '@ionic/vue'
import {getCurrentInstance} from 'vue'
const instance = axios.create({
  baseURL: `${API_URL}`,
  timeout: 20000,
  // headers: authHeader(),
})

/*
    1. 요청 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 요청 바로 직전 - 인자값: axios config
    2) 요청 에러 - 인자값: error
*/
instance.interceptors.request.use(
  function(config: any) {
    // 요청 바로 직전
    // axios 설정값에 대해 작성합니다.
    if (!store.getters.apiAvailable) {
      throw new axios.Cancel('Operation canceled by the user.')
    }

    config.headers = {
      ...config.headers,
      ...authHeader(),
    }
    return config
  },
  function(error: any) {
    // 요청 에러 처리를 작성합니다.
    return Promise.reject(error)
  },
)

/*
    2. 응답 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 응답 정성 - 인자값: http response
    2) 응답 에러 - 인자값: http error
*/
instance.interceptors.response.use(
  function(response: any) {
    /*
        http status가 200인 경우
        응답 바로 직전에 대해 작성합니다.
        .then() 으로 이어집니다.
    */

    const resData = response.data

    const auth = resData.auth
    const data = resData.data || true

    // 신규 토큰으로 갱신
    if (auth.valid) {
      store.dispatch('user/ACT_TOKEN_REFRESH', auth.newToken)
    } else { // state, localstorage user 정보 제거, token 제거
      // 'No auth token': 'NO_AUTH_TOKEN',
      // 'jwt expired': 'JWT_EXPIRED',
      // 'invalid signature': 'INVALID_SIGNATURE',

      const uuid = store.getters['user/getUuid']
      if (auth.type === 'JWT_EXPIRED') { // 토큰 만료
        // console.log("토큰 만료됨.");
        store.dispatch('user/ACT_LOGOUT')
      } else if (auth.type === 'INVALID_SIGNATURE') {
        // console.log("비정상 토큰");
        store.dispatch('user/ACT_LOGOUT')
      } else if (auth.type === 'NO_AUTH_TOKEN') {
        // console.log("토큰 없음");
      }
    }

    return data
  },

  function(error: any) {
    /*
        http status가 200이 아닌 경우
        응답 에러 처리를 작성합니다.
        .catch() 으로 이어집니다.
    */
    if (axios.isCancel(error)) {
      console.log(error.message)
      document.location.href = '/error'
      // router.replace({ path: "/error" });
    } else if (!error.response) {
      // network error
      // TODO network 연결이 안된다는 서비스 공지 필요
      console.error(error)
      store.dispatch('ACT_SET_GLOBAL_TOAST', {message: '네트워크 연결이 불안정 합니다. 잠시 후 다시 시도해주세요.', open: true})
      store.dispatch('ACT_SET_API_AVAILABLE', false)
    } else {
      // console.log(error.response.data);
      // console.log(error.response.status);
      if (error.response.status === 400) {
        store.dispatch('ACT_SET_GLOBAL_TOAST', {message: error.response.data.message, open: true})
        console.error(`${error.response.status}:${error.response.data.message}`)
      } else if (error.response.status === 401) {
        store.dispatch('ACT_SET_GLOBAL_TOAST',
          {
            buttons: ['확인'],
            message: '로그인이 필요합니다.',
            open: true,
          })

        localStorage.removeItem('uuid')
        Promise.all([
          store.dispatch('user/ACT_LOGOUT'),
          // store.dispatch('user/ACT_SET_UUID', null), >> 로그인이 풀렸을때도 본인인증을 시키기 때문에 제거함
        ]).then(() => {
          document.location.href = '/tabs/home'
        })

        // document.location.href = '/tabs/home'
        // router.replace({path: '/tabs/home'})
      } else if (error.response.status === 404) {
        store.dispatch('ACT_SET_GLOBAL_TOAST', {message: error.response.data.message, open: true})
      }
    }

    return Promise.reject(error)
  },
)

export default instance

//
//
//
// export default async function(method: string, url: string, body?: any): Promise<any> {
//    try{
//
//
//       const res = await axios({
//         method: method,
//         url: API_URL + url,
//         headers: authHeader(),
//         data: {
//           body
//         }
//       });
//
//       return res;
//     }catch(error){
//       // console.log(error);
//     }
// }
