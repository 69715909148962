
import {modalController, IonContent, IonRippleEffect, IonInfiniteScroll, IonInfiniteScrollContent} from '@ionic/vue'
import stockInfo from '../../data/stock.json'
import {computed, defineComponent, onMounted, ref, watch} from 'vue'
import {chevronBack} from 'ionicons/icons'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRippleEffect,
  },
  name: 'StockItemTop',
  props: {},
  setup() {
    const {getters, dispatch} = useStore()
    const router = useRouter()
    const {useCompanyModal} = useModal()

    const stockSort = ref<any>('popular')
    const stockItems = computed(() => getters['stockItem/getSimpleStockItems'])
    const stockItemsValuation = computed(() => getters['stockItem/getSortedStockItems']('currentValuation'))

    const stockItemsCount = ref(stockItems.value.length)

    const perPage = 10
    const currentPage = ref(1)
    const currentPageValuation = ref(1)
    // const stockItemsPaging = computed(() => (stockItems.value.slice((currentPage.value - 1) * currentPage.value, currentPage.value * perPage)))
    const stockItemsPaging = computed(() => (stockItems.value.slice(0, currentPage.value * perPage)))
    const stockItemsValuationPaging = computed(() => (stockItemsValuation.value.slice(0, currentPageValuation.value * perPage)))

    const hasMore = ref(stockSort.value === 'popular' ? (stockItemsCount.value > stockItemsPaging.value.length) : (stockItemsCount.value > stockItemsValuationPaging.value.length))

    const loadData = async (event_: any) => {
      setTimeout(async () => {
        if (stockSort.value === 'popular') {
          currentPage.value++
        } else {
          currentPageValuation.value++
        }
        event_.target.complete()
      }, 300)
    }

    function radioButtonClick(id: any) {
      stockSort.value = id === 1 ? 'popular' : 'value'
    }

    const user = computed(() => getters['user/getUser'])

    const itemLike = (stockItemId: any) => {
      const result: any = dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
    }

    const itemLikeCompany = async (stockItemId: any) => {
      const result = await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
      const index = stockItems.value.findIndex((stock: any) => stock.stockItemId === result.stockItem.stockItemId)
      stockItems.value[index].likeFlag = result.stockItem.likeFlag
      stockItems.value[index].likeCount = result.stockItem.likeCount
    }

    async function closeModal() {
      await modalController.dismiss()
    }

    return {chevronBack, closeModal, hasMore, itemLike, itemLikeCompany, loadData, radioBtnClick: radioButtonClick, router, stockItemsCount, stockItemsPaging, stockItemsValuationPaging, stockSort, useCompanyModal, user}
  },
})

