const text = '\'Pretendard\', system-ui, -apple-system, \'Roboto\', \'sans-serif\''
const block = '\'Pretendard\', system-ui, -apple-system, \'Roboto\', \'sans-serif\''

export const fonts = {
  block,
  text,
}

export type Fonts = typeof fonts
export type FontsType = keyof Fonts | string
