import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_ag_header = _resolveComponent("ag-header")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    height: "100vh",
    corner: "0",
    "safe-area": true,
    "model-value": _ctx.isOpen,
    "swipe-to-close": true,
    "onUpdate:modelValue": _ctx.setOpen
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_header, {
        "safe-area": true,
        pt: "17px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_modal_header, {
            title: `조합원 자격 팔기`,
            onClose: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$clickTrackEvent({clickScreen: _ctx.clickScreen, 'clickArea':'헤더', 'clickContent':`닫기`});_ctx.setOpen()})
          })
        ]),
        _: 1
      }),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalPage), {
        "open-type": _ctx.openType,
        parent: _ctx.clickScreen,
        onCloseWithResult: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeWithResult'))),
        onClose: _ctx.setOpen,
        onGoTo: _ctx.goToModal
      }, null, 8, ["open-type", "parent", "onClose", "onGoTo"]))
    ]),
    _: 1
  }, 8, ["model-value", "onUpdate:modelValue"]))
}