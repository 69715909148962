import {Plugins} from '@capacitor/core'
import {API_URL} from '../config'
import {getTokenString} from '../api/auth-header'
const {Browser} = Plugins

export async function openMyFile(path: string): Promise<void> {
  await Browser.open({url: `${API_URL}${path}?accessToken=${getTokenString()}`})
}

export async function openPartnershipFile(fileId: string): Promise<void> {
  await Browser.open({url: `${API_URL}/partnerships/files/${fileId}/download?accessToken=${getTokenString()}`})
  console.log('ㅠㅠ')
}
