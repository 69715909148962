import {CSSObject} from '@emotion/css'
import {radii} from './radii'
import {sizes} from './sizes'
import {space} from './space'
import {typography} from './typography'

const xs: CSSObject = {
  '&.block-content': {
    minHeight: sizes.xs,
  },
  '&>.block-content:not(.component-root)': {
    minHeight: sizes.xs,
  },
  borderRadius: radii.sm,
  paddingBottom: space.sp4,
  paddingLeft: space.sp4,
  paddingRight: space.sp4,
  paddingTop: space.sp4,
  ...typography.tit14b,
}

const sm: CSSObject = {
  '&.block-content': {
    minHeight: sizes.sm,
  },
  '&>.block-content:not(.component-root)': {
    minHeight: sizes.sm,
  },
  borderRadius: radii.sm,
  paddingBottom: space.sp6,
  paddingLeft: space.sp8,
  paddingRight: space.sp8,
  paddingTop: space.sp6,
  ...typography.btn12b,
}

const md: CSSObject = {
  '&.block-content': {
    minHeight: sizes.md,
  },
  '&>.block-content:not(.component-root)': {
    minHeight: sizes.md,
  },
  borderRadius: radii.sm,
  paddingBottom: space.sm,
  paddingLeft: space.sp8,
  paddingRight: space.sp8,
  paddingTop: space.sm,
  ...typography.tit16b,
}

const lg: CSSObject = {
  '&.block-content': {
    minHeight: sizes.lg,
  },
  '&>.block-content:not(.component-root)': {
    minHeight: sizes.lg,
  },
  borderRadius: radii.md,
  paddingBottom: space.sp16,
  paddingLeft: space.sp8,
  paddingRight: space.sp8,
  paddingTop: space.sp16,
  ...typography.tab18m,
}

const none: CSSObject = {}

export const blocks = {
  lg, md, none, sm, xs,
}

export type Blocks = typeof blocks
export type BlocksType = keyof Blocks
