
import {defineComponent, toRef} from 'vue'
import {AgImg, AgBox, AgBoxS, AgButton, AgText, AgTextS, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {IonRippleEffect} from '@ionic/vue'
import {numberToKorean} from '@/utils/filters'
import useModal from '@/composables/useModal'
export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgImg,
    AgModal,
    AgText,
    AgTextS,
    IonRippleEffect,
  },
  name: 'PrepareDeals',
  props: {
    prepareDeals: Array,
  },
  setup(props, {emit}) {
    const preparesPartnerships = toRef(props, 'prepareDeals')
    const {useCompanyModal} = useModal()
    return {
      numberToKorean,
      preparesPartnerships,
       useCompanyModal,
    }
  },
})
