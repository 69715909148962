
import IndividualInfo from './components/IndividualInfo.vue'
import {AgBoxS, AgImg, AgRefresher, AgTextS, AgIconS, AgButtonS} from '@/components/ag'
import HomeBoardList from '@/components/Board/HomeBoardList.vue'
import sellRegistModal from '@/components/Home/SellRegistModal.vue'
import CollectClubDeals from '@/components/Modal/Home/CollectClubDeals.vue'
import {isPlatform} from '@/composables'
import homeMain from '@/data/homeMain.json'
import stockInfo from '@/data/stock.json'
import {clubDate, openLink} from '@/utils/filters'
import useModal from '@/composables/useModal'
import RegistSellStakeModal from '@/views/view/regist-sell-stake/RegistSellStakeModal.vue'
import LinkAccountModal from '@/views/view/link-account/LinkAccountModal.vue'
import PopularShareTransactions from '@/components/Home/PopularShareTransactions.vue'
import StockItemTop from '@/views/home/components/StockItemTop.vue'
import StockItemEntire from '@/views/home/components/StockItemEntire.vue'
import PopularShareTransactionModal from '@/views/view/home/popular-share-transaction/PopularShareTransactionModal.vue'
import StakeListsModal from '@/views/view/stake-lists/StakeListsModal.vue'
import PrepareDeals from '@/components/Home/prepare-deals.vue'
import {store} from '@/vuex'
import {toMutRef} from '@/composables'
import {Plugins} from '@capacitor/core'
import {IonContent, IonPage, modalController} from '@ionic/vue'
import {computed, defineComponent, getCurrentInstance, onBeforeMount, onBeforeUpdate, ref, onMounted} from 'vue'
import {onBeforeRouteLeave, useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import bannerART from './assets/img.png'
import bannerART2 from './assets/img@2x.png'
import bannerART3 from './assets/img@3x.png'
import {NftData} from '@/environment'
import NftBanner from './components/NftBanner/NftBanner.vue'
import NftBannerMid from './components/NftBannerMid/NftBannerMid.vue'
import NftList from './components/NftList/NftList.vue'
import NftFactoryList from './components/NftFactoryList/NftFactoryList.vue'
import talSvg from './assets/tal.pure.svg'
import {cookieSoftStorage} from '@/utils/storage'
import ArtBanner from './components/ArtBanner/ArtBanner.vue'
import nftBackGround from './assets/back-ground.pure.svg'
import nftBackGround1 from './assets/back-ground.png'
import nftBackGround2 from './assets/back-ground@2x.png'
import nftBackGround3 from './assets/back-ground@3x.png'
import {NftMintBanner} from '@/components/NftMintBanner'
import {provideTrackPayload} from '@/composables'

const {Browser} = Plugins
const {openSellModal} = useModal()
export default defineComponent({
  components: {
    AgBoxS,
    AgButtonS,
    AgIconS,
    AgImg,
    AgRefresher,
    AgTextS,
    ArtBanner,
    CollectClubDeals,
    HomeBoardList,
    IndividualInfo,
    IonContent,
    IonPage,
    LinkAccountModal,
    NftBanner,
    NftBannerMid,
    NftFactoryList,
    NftList,
    NftMintBanner,
    PopularShareTransactionModal,
    PopularShareTransactions,
    PrepareDeals,
    RegistSellStakeModal,
    StakeListsModal,
    sellRegistModal,
  },
  data() {
    return {
      companyLogo: stockInfo.cLogo,
      homeMain: homeMain.menu,
      stocks: stockInfo.stock,
    }
  },
  name: 'Home',
  props: {
    isOpenClubDealAll: {default: false, type: Boolean},
    isOpenShareTransaction: {default: false, type: Boolean},
  },
  setup(props) {
    provideTrackPayload({
      clickScreen: 'home',
    })
    const app = getCurrentInstance()
    const $global = app && app.appContext.config.globalProperties.$global
    const {
      dispatch,
      getters,
    } = useStore()
    const router = useRouter()
    const {
      useCollectPartnerships,
      viewKakaoKlipModal,
      openFAQModal,
      viewCollectClubdealsModal,
      useCompanyModal,
      useClubDealProgressModal,
    } = useModal()
    const route = useRoute()
    const totalIncomingProfitRate = computed(() => getters['asset/getTotalIncomingProfitRate'])
    const totalIncomingProfit = computed(() => getters['asset/getTotalIncomingProfit'])
    const reflectAt = computed(() => getters['asset/getReflectAt'])
    const totalAsset = computed(() => getters['asset/getTotalAsset'])
    const user = computed(() => getters['user/getUser'])
    const showBanner = computed(() => !isPlatform('hybrid').value && !isPlatform('desktop'))

    const token = computed(() => getters['user/getToken'])
    const homeClubDeals = computed(() => getters['home/getPartnershipsHome'] || [])
    const openClubDeals = computed(() => getters['partnership/getPartnershipsHome'].value || [])

    const stockItems = computed(() => getters['stockItem/getStockItems'] || [])
    const currentPath = computed(() => window.location.pathname)

    // const stockItemTop3 = computed(() => getters['home/getStockItemTop3'] || [])
    // const popularItemTop3 = computed(() => getters['home/getPopularItemTop3'] || [])

    const likeStocks = computed(() => getters['home/getLikeStock'] || [])
    // const leadTop = computed(() => getters['home/getLeadTop'] || [])
    // const activeBoardTop = computed(() => getters['home/getActiveBoardTop'] || [])

    const likeStockItems = computed(() => getters['stockItem/getLikeStockItems'] || [])

    const stockItems10 = computed(() => stockItems.value.slice(0, 10) || [])

    const stockItemBoards = computed(() => getters['stockItem/getStockItemBoardsHome'])
    const preparesPartnerships = computed(() => getters['partnership/getPreparesPartnerships'])

    const toggleValue = ref<any>(false)

    // async function openSellModal() {
    //   const sellModal = await modalController
    //     .create({
    //       backdropDismiss: true,
    //       component: SellItemModal,
    //       cssClass: 'full-screen-modal',
    //       id: 'SellItemModal',
    //       // mode: 'ios',
    //       keyboardClose: true,
    //
    //       swipeToClose: true,
    //     })
    //
    //   return sellModal.present()
    // }

    const onClick = () => {
      router.push('/tabs/search/company')
    }

    const opinionIndex = ref<any>(0)
    const slideToggle = ref<any>(false)

    const loadBoard = () => {
      dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS_FOR_HOME', {page: 1})
    }

    onBeforeMount(() => {
      // console.log('home onBeforeMount!?')
      dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS')
      dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS')
      dispatch('home/loadData')
      dispatch('partnership/ACT_GET_PREPARE_PARTNERSHIPS')
      loadBoard()
      slideToggle.value = false
    })

    onMounted(() => {
      const {login} = route.query
      if (login === 'true' && !token.value) {
        $global.openLoginModal()
      }
    })

    onBeforeUpdate(() => {
      if (currentPath.value === '/tabs/home') {
        slideToggle.value = false
      }
    })

    onBeforeRouteLeave(() => {
      slideToggle.value = true
    })

    // watch(leadTop, () => {
    //   // console.log(leadTop.value);
    // })

    const itemLike = async (stockItemId: any) => {
      await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
    }

    const itemLikeCompany = async (stockItemId: any) => {
      await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
    }

    const doRefresh = async (event: any) => {
      // console.log('Begin async operation');
      await Promise.all(
          [
            store.dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS'),
            store.dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS'),
            store.dispatch('home/loadData'),
            store.dispatch('asset/loadData'),
            loadBoard(),
          ],
      )
      event.target.complete()
    }

    const openItemTopModal = async (type: string) => {
      const modal = await modalController
      .create({
        backdropDismiss: true,
        component: StockItemTop,
        componentProps: {
          type,
        },
        cssClass: 'home-modal',
        id: 'ItemTop',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })

      return modal.present()
    }

    const openItemEntireModal = async () => {
      const modal = await modalController
      .create({
        backdropDismiss: true,
        component: StockItemEntire,
        cssClass: 'home-modal',
        id: 'ItemTop',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })

      return modal.present()
    }

    const sellModalOpen = ref<boolean>(false)
    const stakeListOpen = ref<boolean>(false)
    const viewShareTransactionOpen = toMutRef(props, 'isOpenShareTransaction')
    const clubDealAllOpen = toMutRef(props, 'isOpenClubDealAll')

    function clickHomeBanner(index: number) {
      switch (index) {
        case 0:
          useCollectPartnerships()
          break
        case 1:
          // sellModalOpen.value = true
          user.value === null ? $global.openLoginModal() : openSellModal()
          break
        case 2:
          // stakeListOpen.value = true
          Browser.open({url: 'https://angelleague.notion.site/5dfa09f455914adeb7324397e1d08103'})
          break
      }
    }

    const stakeSellOpen = ref<any>(false)
    const linkAccountModal = ref<any>(false)
    const afterLinked = ref<string>('')
    const SellOpenType = ref<string>('')
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const viewOpenType = ref<string>('')
    const viewMyStakeOpen = ref<any>(false)

    const checkIsLinked = async () => {
      afterLinked.value = 'sell'
      SellOpenType.value = 'create'

      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
        linkAccountModal.value = true
      } else if (currentStep.value === 'COMPLETE') {
        stakeSellOpen.value = true
      }
    }
    const openModalFromPopularShareTransaction = async (target: string) => {
      if(target==='shareSellAll'){
        stakeListOpen.value = true
      }else if(target==='sellMyShareSell'){
        await checkIsLinked()
      }

    }

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }
    const branchResult = () => {
      viewOpenType.value = 'result'

      stakeSellOpen.value = false
      viewMyStakeOpen.value = true
      dispatch('stakeDeal/GET_ALL_STAKELIST')
    }
    const afterLinkAccountAct = () => {
      stakeSellOpen.value = true
      linkAccountModal.value = false
    }


    const goUrl = (url: string) => {
      return Browser.open({url})
    }

    //gtm event screen name
    const clickScreen = 'home'

    const nftData = NftData()

    const saveTokenCookie = () => {
      cookieSoftStorage.setItem('token', {
        value: localStorage.getItem('token'),
      }, {
        domain: 'angelleague.io',
        secure: true,
      })
    }

    const openTal = (url: string) => {
      saveTokenCookie()
      Browser.open({url})
    }

    return {
      SellOpenType,
      afterLinkAccountAct,
      bannerART,
      bannerART2,
      bannerART3,
      branchResult,
      clickHomeBanner,
      clickScreen,
      clubDate,
      clubDealAllOpen,
      doRefresh,
      goUrl,
      homeClubDeals,
      itemLike,
      itemLikeCompany,
      // leadTop,
likeStockItems,
      
likeStocks,
      
linkAccountModal,
      
nftBackGround,

nftBackGround1,

nftBackGround2,

nftBackGround3,

nftData,

onClick,

openChannelTalk,

openClubDeals,



openFAQModal,



openItemEntireModal,


openItemTopModal,

      openLink,
      openModalFromPopularShareTransaction,
      openSellModal,
      openTal,
      opinionIdx: opinionIndex,
      preparesPartnerships,
      reflectAt,
      router,
      sellModalOpen,
      showBanner,
      stakeListOpen,
      stakeSellOpen,


      stockItemBoards,
      // activeBoardTop,
// popularItemTop3,
// stockItemTop3,
stockItems,



stockItems10,






talSvg,






toggleValue,






token,



totalAsset,
      totalIncomingProfit,
      totalIncomingProfitRate,
      useClubDealProgressModal,
      useCollectPartnerships,
      useCompanyModal,
      user,
      viewCollectClubdealsModal,
      viewKakaoKlipModal, viewShareTransactionOpen,
    }
  },

})
