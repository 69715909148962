
import {computed, defineComponent, ref} from 'vue'
import {modalController, toastController, IonRippleEffect} from '@ionic/vue'
import apiSettings from '@/api/settings'

export default defineComponent({
  components: {
    IonRippleEffect,
  },
  name: 'AccountKeypadModal',
  props: {
    accountType: null,
    bankInfo: null,
  },
  setup(props) {
    const selectedBank = computed(() => props.bankInfo)
    const selectedType = computed(() => props.accountType)
    const accountNumber = ref<string>('')
    const {putBankAccount, putStockBankAccount} = apiSettings()

    function addNumber(num: number) {
      const ch = num.toString()
      if (!accountNumber.value) {
        accountNumber.value = ch
      } else if (accountNumber.value.length < 15) {
        accountNumber.value += ch
      }
    }

    const dismiss = (data: string) => {
      modalController.dismiss(data)
    }

    function clearNumber() {
      if (accountNumber.value.length > 0 && accountNumber.value) {
        accountNumber.value = accountNumber.value.slice(0, Math.max(0, accountNumber.value.length - 1))
      }
    }

    async function sendAccount() {
      if (selectedType.value === 'refund') {
        putBankAccount(selectedBank.value.bankId, accountNumber.value)
          .then((data) => {
            if (data) {
              toastController.create({
                duration: 1000,
                message: '은행계좌가 성공적으로 등록되었습니다.',
                position: 'top',
              })
                .then((toast) => {
                  modalController.dismiss()
                  return toast.present()
                })
            } else {
              toastController.create({
                duration: 1000,
                message: '은행불계좌 등록에 실패하였습니다.',
                position: 'top',
              })
                .then((toast) => {
                  return toast.present()
                })
            }
          })
      } else {
        putStockBankAccount(selectedBank.value.stockBankId, accountNumber.value)
          .then((data) => {
            if (data) {
              toastController.create({
                duration: 1000,
                message: '증권계좌가 성공적으로 등록되었습니다.',
                position: 'top',
              })
                .then((toast) => {
                  modalController.dismiss()
                  return toast.present()
                })
            } else {
              toastController.create({
                duration: 1000,
                message: '증권계좌 등록에 실패하였습니다.',
                position: 'top',
              })
                .then((toast) => {
                  return toast.present()
                })
            }
          })
      }
    }
    const clickScreen = 'account-keypad-modal'
    return {accountNumber, addNumber, clearNumber, clickScreen, dismiss, selectedBank, selectedType, sendAccount}
  },
})
