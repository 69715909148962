
import {defineComponent, computed, onBeforeMount, onUpdated, ref, toRef} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {toFixed, openLink} from '@/utils/filters'
import router from '@/router'
import {IonRippleEffect} from '@ionic/vue'
import {useGtm} from '@gtm-support/vue-gtm'
import {CLICK_EVENT, GtmClickEvent} from '@/plugins/gtm-event'
import {AgIcon, AgBoxS} from '@/components/ag'

export default defineComponent({
  components: {AgBoxS, AgIcon, IonRippleEffect},
  name: 'BoardListAll',
  props: {
    parent: String,
    stockItemBoardsProp: null,
  },
  setup(props) {
    const {getters, dispatch, commit} = useStore()
    const router = useRouter()

    const stockItemBoards = computed(() => props.stockItemBoardsProp)
    // const stockItemBoards = computed(()=>getters['stockItem/getStockItemBoardsAll']);

    function boardClick(data: any, event: any) {
      if (event.target.tagName === 'A') {
        openLink(event.target.dataset.href)
      }
    }

    const parentRef = toRef(props, 'parent')

    return {
      boardClick,
      clickScreen: parentRef.value,
      openLink,
      router,
      stockItemBoards,
      toFixed,
    }
  },
})
