
import {IonPage, IonContent, modalController, IonRippleEffect, IonIcon} from '@ionic/vue'
import {AgText, AgBoxS, AgTextS, AgImg} from '@/components/ag'
import {defineComponent, onBeforeMount, computed, watch, ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {numberToKorean, printDate} from '@/utils/filters'
import {openPartnershipFile} from '@/utils/OpenFiles'
import useModal from '@/composables/useModal'
import PartnershipBoardList from '@/components/Board/PartnershipBoardList.vue'
import PartnershipBoardWriteModal from '@/components/Modal/PartnershipBoard/PartnershipBoardWriteModal.vue'
import RegistSellStakeModal from '@/views/view/regist-sell-stake/RegistSellStakeModal.vue'
import ViewMyStake from '@/views/view/view-my-stake/ViewMyStake.vue'
import LinkAccountModal from '@/views/view/link-account/LinkAccountModal.vue'
import {chatbubble} from 'ionicons/icons'
export default defineComponent({
  components: {
    AgBoxS,
    AgImg,
    AgText,
    AgTextS,
    IonContent,
    IonIcon,
    IonPage,
    IonRippleEffect,
    LinkAccountModal,
    PartnershipBoardList,
    PartnershipBoardWriteModal,
    RegistSellStakeModal,
    ViewMyStake,

  },
  name: 'PartnershipDetail',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const {getters, dispatch} = useStore()
    const {useCompanyModal} = useModal()

    const activeTab = ref('info')
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const stockItems = computed(() => getters['stockItem/getStockItems'])
    const selectedPartnership = computed(() => getters['asset/getSelectedPartnership'])
    const partnership = computed(() => selectedPartnership.value.partnership || {})
    const stockItemId = computed(() => partnership.value.stockItemId || '')
    const partnershipAsset = computed(() => selectedPartnership.value.partnershipAsset || {})
    const myPartnershipAsset = computed(() => selectedPartnership.value.myPartnershipAsset || {})
    const remainingAsset = computed(() => selectedPartnership.value.remainingAsset || {})
    const partnershipCompanyFiles = computed(() => selectedPartnership.value.partnershipCompanyFiles || {})
    const partnershipFiles = computed(() => selectedPartnership.value.partnershipFiles || {})
    const partnershipInvestors = computed(() => selectedPartnership.value.partnershipInvestors || {})
    const processStatus = computed(() => selectedPartnership.value.processStatus || {})
    const partnershipStatus = computed(() => partnership.value.processStatus || 'collecting')

    const stockItemInfo = computed(() => selectedPartnership.value.partnership || {})

    const hasSession = () => {
      return getters['user/hasSession']
    }

    const changeTab = (value: string) => {
      activeTab.value = value
    }

    watch(() => route.params, async () => {
      await dispatch('asset/ACT_SELECT_PARTNERSHIP', route.params.partnershipId)
    })

    // watch(selectedPartnership, () => {
    //   stockItemInfo.value = stockItems.value.filter((a: any) => a.stockItems === selectedPartnershipInfo.value.stockItemId)
    // })

    const paramsId = computed(() => route.params.partnershipId)

    onBeforeMount(async () => {
      if (route.params.partnershipId) {
        await dispatch('asset/ACT_SELECT_PARTNERSHIP', route.params.partnershipId, partnership.value.stockItemId)
      }
    })

    const statusArray = [ // statusArr.indexOf(processStatus[partnership.processStatus])
      '',
      'collecting',
      'documenting',
      'signing',
      'partnership',
      'selling',
      'sold',
      'captable',
    ]

    function getCurrentStatusArray() {
      if (partnership.value && processStatus.value) {
        return statusArray.slice(1, statusArray.indexOf(partnershipStatus.value) + 1)
      } 
        return []
      
    }

    function getLeftStatusArray() {
      if (partnership.value && processStatus.value) {
        return statusArray.slice(statusArray.indexOf(partnershipStatus.value) + 1)
      } 
        return []
      
    }

    const openModal = async (partnershipBoard: any = null) => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: PartnershipBoardWriteModal,
          componentProps: {
            partnershipBoard,
            partnershipId: paramsId.value,
          },
          cssClass: 'board-write-modal',
          id: 'PartnershipBoardWriteModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      return modal.present()
    }

    const stakeSellOpen = ref<boolean>(false)
    const linkAccountModal = ref<boolean>(false)
    const sellOpenType = ref<string>('')
    const viewMyStakeOpen = ref<any>(false)
    const viewOpenType = ref<string>('')

    const branchResult = () => {
      viewOpenType.value = 'result'

      stakeSellOpen.value = false
      viewMyStakeOpen.value = true
      dispatch('stakeDeal/GET_ALL_STAKELIST')
    }

    const openSellStakeModal = () => {
      sellOpenType.value = 'stakeSelected'
      stakeSellOpen.value = true
    }

    const selectStakeAction = async () => {
      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
linkAccountModal.value = true
} else if (currentStep.value === 'COMPLETE') {
        await dispatch('stakeDeal/GET_SELECTED_STAKE_INFO', {partnershipId: paramsId.value, stockItemId: stockItemId.value})
        await openSellStakeModal()
        linkAccountModal.value = false
      }
    }

    return {
      activeTab,
      branchResult,
      changeTab,
      chatbubble,
      getCurStatusArr: getCurrentStatusArray,
      getLeftStatusArr: getLeftStatusArray,
      hasSession,
      linkAccountModal,
      myPartnershipAsset,
      numberToKorean,
      openModal,
      openPartnershipFile,
      openSellStakeModal,
      paramsId,
      partnership,
      partnershipAsset,
      partnershipCompanyFiles,
      partnershipFiles,
      partnershipInvestors,
      printDate,
      processStatus,
      remainingAsset,
      route,
      router,
      selectStakeAction,
      selectedPartnership,
      sellOpenType,
      stakeSellOpen,
      statusArr: statusArray,
      stockItemInfo,
      useCompanyModal,
      viewMyStakeOpen,
      viewOpenType,
    }
  },
})
