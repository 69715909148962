
import {defineComponent, onMounted, ref, h, computed, toRef} from 'vue'
import {AgImg, AgBox, AgBoxS, AgButton, AgText, AgTextS, AgModal, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {IonRippleEffect} from '@ionic/vue'
import {useStore} from 'vuex'
import {getFormattedDate} from '@/utils/filters'
import PopularShareTransactionModal from '@/views/view/home/popular-share-transaction/PopularShareTransactionModal.vue'
import {numberToKorean} from '@/utils/filters'
export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgImg,
    AgModal,
    AgText,
    AgTextS,
    IonRippleEffect,
    PopularShareTransactionModal,
  },
  emits: ['btnClick', 'companyClick'],
  name: 'PopularShareTransactions',
  props: {
    parent: String,
  },
  setup(props, {emit}) {
    const {getters, dispatch} = useStore()
    const popularShareTransaction = computed(() => getters['home/getFivePopularShareTransaction'])
    const stakeList = computed(() => popularShareTransaction.value.popularShareTransactions)
    const updateDate = computed(() => popularShareTransaction.value.reflectedAt)
    const viewShareTransactionOpen = ref<boolean>(false)
    const formattedDate = (updateDate: any) => {
      return getFormattedDate(updateDate, 'MM.DD HH:mm')
    }
    const parentRef = toRef(props, 'parent')
    return {
      clickScreen: parentRef.value,
      formattedDate,
      numberToKorean,
      stakeList,
      updateDate,
      viewShareTransactionOpen,
    }
  },
})
