import {Module} from 'vuex'
import {RootState} from '..'
import apiStockItem from '@/api/stock-item'

import {get} from '@/composables/useDealStorage'
const {
  getStockItems, getStockItem, stockItemLike
  , stockItemSearchLog, stockItemsPopularity
  , stockItemBoards, createStockItemBoards
  , deleteStockItemBoard
  , stockItemBoardsPage
  , stockItemBoardLike
  , getPartnershipHistoriesByStockItemId,
} = apiStockItem()

// state
interface StockItem {
  initStockItem: any
  popularStockItem: any[]
  recentViewList: any[]
  selectedStockItem: any
  stockItemBoards: any[]
  stockItemBoardsAll: any[]
  stockItemBoardsAllCount: number
  stockItemBoardsAllHasmore: boolean
  stockItemBoardsCount: number
  stockItemBoardsHasmore: boolean
  stockItemBoardsHome: any[]
  stockItemInitialCall: boolean
  stockItemLogos: any
  stockItemPartnershipHistories: any[]
  stockItems: any[]
}

// action
const StockItem: Module<StockItem, RootState> = {

  actions: {
    async ACT_DELETE_STOCK_ITEM_BOARD({commit, dispatch}, {stockItemId, stockItemBoardId}) {
      const result = await deleteStockItemBoard(stockItemId, stockItemBoardId)
      commit('SET_DELETE_STOCK_ITEM_BOARD', result.stockItemBoard.stockItemBoardId)
      commit('SET_STOCK_ITEM_BOARD_COUNT', result.stockItemBoardsCount)
      dispatch('ACT_GET_STOCK_ITEM_BOARDS_ALL_FOR_REFRESH', {page: 1})
      return result.stockItemBoard
    },
    async ACT_GET_ALL_STOCK_ITEMS({commit, dispatch}) {
      const result = await getStockItems()
      commit('SET_STOCK_ITEMS', result.stockItems)
      // dispatch('home/ACT_STOCK_ITEM_TOP3', result.orderByLike, {root: true})
      // dispatch('home/ACT_POPULAR_ITEM_TOP3', result.orderBySearch, {root: true})
      // dispatch('home/ACT_LEAD_TOP', result.orderByLeadLike, {root: true})
      // dispatch('home/ACT_ACTIVE_BOARD_TOP', result.orderByCount, {root: true})
    },
    async ACT_GET_ALL_STOCK_ITEMS_REPLACE({commit}, id: any) {
      const result = await getStockItems()
      const replace = result.stockItems.filter((a: any) => a.stockItemId.toString() === id.toString())
      commit('SET_STOCK_ITEMS_REPLACE', replace)
    },
    async ACT_GET_COLLECTED_PARTNERSHIP({commit}, {page, stockItemId}) {
      const result = await getPartnershipHistoriesByStockItemId(page, stockItemId)
      commit('SET_ADD_PARTNERSHIP_HISTORIES', result.partnershipHistories)
    },
    async ACT_GET_INIT_STOCK_ITEM({commit}, {stockItemId}) {
      const result = await getStockItem(stockItemId)
      const initStockItem = result
      commit('SET_INIT_STOCK_ITEM', initStockItem)
    },
    async ACT_GET_POPULARITY({commit, state}) {
      if (state.popularStockItem.length > 0) {
return
}
      const result = await stockItemsPopularity()
      const popularStockItemIds = result.popularStockItems.map((stock: any) => stock.stockItemId)
      commit('SET_POPULAR_STOCK_ITEMS', popularStockItemIds)
    },
    async ACT_GET_STOCK_ITEM({commit}, {stockItemId}) {
      const result = await getStockItem(stockItemId)
      const selectedStockItem = result
      commit('SET_STOCK_ITEM', selectedStockItem)
    },
    async ACT_GET_STOCK_ITEM_BOARDS({commit}, {stockItemId, page, type}) {
      // type:'initAndSet'
      const result = await stockItemBoards(stockItemId, page)
      if (type === 'initAndSet') {
        commit('SET_INIT_AND_SET_STOCK_ITEM_BOARDS', result.stockItemBoards)
      } else {
        commit('SET_CONCAT_STOCK_ITEM_BOARDS', result.stockItemBoards)
      }

      commit('SET_STOCK_ITEM_BOARD_COUNT', result.stockItemBoardsCount)
      commit('SET_STOCK_ITEM_BOARDS_HASMORE', result.perPage <= result.stockItemBoards.length)
    },
    async ACT_GET_STOCK_ITEM_BOARDS_ALL({commit}, {page}) {
      const result = await stockItemBoardsPage(page)
      commit('SET_STOCK_ITEM_BOARDS_ALL', result.stockItemBoards)
      commit('SET_STOCK_ITEM_BOARDS_ALL_COUNT', result.stockItemBoardsCount)
      commit('SET_STOCK_ITEM_BOARDS_HASMORE', result.perPage <= result.stockItemBoards.length)
      return result
    },
    async ACT_GET_STOCK_ITEM_BOARDS_ALL_FOR_REFRESH({commit}, {page}) {
      const result = await stockItemBoardsPage(page)
      commit('SET_INIT_AND_SET_STOCK_ITEM_BOARDS_ALL', result.stockItemBoards)
    },

    async ACT_GET_STOCK_ITEM_BOARDS_FOR_HOME({commit}, {page}) {
      const result = await stockItemBoardsPage(page)
      commit('SET_STOCK_ITEM_BOARDS_FOR_HOME', result.stockItemBoards)
    },
    async ACT_LIKE_STOCK_ITEM({commit, dispatch, state}, {stockItemId}) {
      const result = await stockItemLike(stockItemId)
      commit('SET_REPLACE_STOCK_ITEM', result.stockItem)
      // dispatch('home/ACT_STOCK_ITEM_TOP3', result.orderByLike, {root: true})
      // dispatch('home/ACT_POPULAR_ITEM_TOP3', result.orderBySearch, {root: true})
      // dispatch('home/ACT_LEAD_TOP', result.orderByLeadLike, {root: true})
      // dispatch('home/ACT_ACTIVE_BOARD_TOP', result.orderByCount, {root: true})
      return result
    },
    async ACT_LIKE_STOCK_ITEM_BOARD({commit}, {stockItemId, stockItemBoardId, target}) {
      const result = await stockItemBoardLike(stockItemId, stockItemBoardId)
      commit('SET_REPLACE_STOCK_ITEM_BOARD', {stockItemBoard: result.stockItemBoard, target})
    },
    async ACT_LOG_STOCK_ITEM_SEARCH(_, {stockItemId}) {
      await stockItemSearchLog(stockItemId)
    },
    async ACT_SAVE_STOCK_ITEM_BOARDS({commit, dispatch}, {stockItemId, boardData}) {
      const result = await createStockItemBoards(stockItemId, boardData)
      console.log(result)
      if (result.type === 'create') {
        commit('SET_PUSH_FIRST_STOCK_ITEM_BOARD', result.stockItemBoard)
      } else if (result.type === 'update') {
        commit('SET_REPLACE_STOCK_ITEM_BOARD', {stockItemBoard: result.stockItemBoard, target: 'stockItemBoards'})
      }
      commit('SET_STOCK_ITEM_BOARD_COUNT', result.stockItemBoardsCount)
      dispatch('ACT_GET_STOCK_ITEM_BOARDS_ALL_FOR_REFRESH', {page: 1})
      return result.stockItemBoard
    },

  },

  getters: {
    getInitStockItem: (state) => state.initStockItem,
    getLikeStockItems: (state) => state.stockItems.filter(si => si.likeFlag),
    getPopularStockItem: (state) => state.popularStockItem,
    getRecentViewList: (state) => state.recentViewList,
    getSelectedStockItem: (state) => state.selectedStockItem,
    getSimpleStockItems: (state) => {
      return state.stockItems.map((stock: any) => {
        const object = {
          avgPoint: stock.avgPoint,
          companyLogo: stock.companyLogo,
          companyStatus: stock.companyStatus,
          count: stock.count,
          currentValuation: stock.currentValuation ? Number(stock.currentValuation) : 0,
          likeCount: stock.likeCount ? Number(stock.likeCount) : 0,
          likeFlag: stock.likeFlag,
          marketPrice: stock.marketPrice,
          minSellingPrice: stock.minSellingPrice,
          searchCount: stock.searchCount ? Number(stock.searchCount) : 0,
          status: stock.status,
          stockItemId: stock.stockItemId,
          stockItemName: stock.stockItemName,
        }
        return object
      })
    },
    getSortedStockItems: (state) => (attr: string) => {
      return state.stockItems.map((stock: any) => {
        const object = {
          avgPoint: stock.avgPoint,
          companyLogo: stock.companyLogo,
          companyStatus: stock.companyStatus,
          count: stock.count,
          currentValuation: stock.currentValuation ? Number(stock.currentValuation) : 0,
          likeCount: stock.likeCount ? Number(stock.likeCount) : 0,
          likeFlag: stock.likeFlag,
          marketPrice: stock.marketPrice,
          minSellingPrice: stock.minSellingPrice,
          status: stock.status,
          stockItemId: stock.stockItemId,
          stockItemName: stock.stockItemName,
        }
        return object
      }).sort((a: any, b: any) => {
        return b[attr] - a[attr]
      })
    },
    getStandardStockItem: (state) => (stockItemId: any) => {
      return state.stockItems.find((stock: any) => stock.stockItemId === stockItemId)
    },
    getStockItemBoards: (state) => state.stockItemBoards,
    getStockItemBoardsAll: (state) => state.stockItemBoardsAll,
    getStockItemBoardsAllCount: (state) => state.stockItemBoardsAllCount,
    getStockItemBoardsAllHasmore: (state) => state.stockItemBoardsAllHasmore,
    getStockItemBoardsCount: (state) => state.stockItemBoardsCount,
    getStockItemBoardsHasmore: (state) => state.stockItemBoardsHasmore,
    getStockItemBoardsHome: (state) => state.stockItemBoardsHome,
    getStockItemLogos: (state) => state.stockItemLogos,
    getStockItemPartnershipHistories: (state) => state.stockItemPartnershipHistories,
    getStockItems: (state) => {
      return state.stockItems
    },
  },

  // RootState와 똑같이 작성해도 된다.
  mutations: {
    SET_ADD_PARTNERSHIP_HISTORIES(state, partnershipHistories: any) {
      state.stockItemPartnershipHistories = partnershipHistories
    },
    SET_CONCAT_STOCK_ITEM_BOARDS(state, stockItemBoards: any) {
      state.stockItemBoards = state.stockItemBoards.concat(stockItemBoards)
      // concat 후 중복 제거
      state.stockItemBoards = [...state.stockItemBoards.reduce((m, t) => m.set(t.stockItemBoardId, t), new Map()).values()]
    },
    SET_DELETE_STOCK_ITEM_BOARD(state, stockItemBoardId: number) {
      const index = state.stockItemBoards.findIndex((board: any) => Number(board.stockItemBoardId) === Number(stockItemBoardId))
      if (~index) {
        state.stockItemBoards.splice(index, 1)
      }
    },
    SET_INIT_AND_SET_STOCK_ITEM_BOARDS(state, stockItemBoards: any) {
      state.stockItemBoards = stockItemBoards
    },
    SET_INIT_AND_SET_STOCK_ITEM_BOARDS_ALL(state, stockItemBoards: any) {
      state.stockItemBoardsAll = stockItemBoards
    },
    async SET_INIT_RECENT_VIEW_STOCK_ITEM(state) {
      state.recentViewList = await get('deal_storage') || []
    },
    SET_INIT_STOCK_ITEM(state, stockItem: any) {
      state.initStockItem = stockItem
    },
    SET_POPULAR_STOCK_ITEMS(state, popularStockItems: any) {
      state.popularStockItem = popularStockItems
    },
    SET_PUSH_FIRST_STOCK_ITEM_BOARD(state, stockItemBoard: any) {
      state.stockItemBoards = [stockItemBoard].concat(state.stockItemBoards)
    },

    SET_REPLACE_STOCK_ITEM(state, stockItem) {
      state.stockItems = state.stockItems.map(stock => stock.stockItemId === stockItem.stockItemId ? stockItem : stock)

      if (state.selectedStockItem && state.selectedStockItem.stockItemInfo) {
        state.selectedStockItem.stockItemInfo = {
          ...state.selectedStockItem.stockItemInfo,
          ...(state.selectedStockItem.stockItemInfo.stockItemId === stockItem.stockItemId ? stockItem : {}),
        }
      }
    },
    SET_REPLACE_STOCK_ITEM_BOARD(state, {stockItemBoard, target}) {
      (state as any)[target] = (state as any)[target].map((stock: any) => stock.stockItemBoardId === stockItemBoard.stockItemBoardId ? stockItemBoard : stock)
    },
    SET_STOCK_ITEM(state, stockItem: any) {
      state.selectedStockItem = stockItem
    },
    SET_STOCK_ITEMS(state, stockItems: any) {
      state.stockItems = stockItems
      stockItems.forEach((a: any) => (state.stockItemLogos[a.stockItemId] = a.companyLogo))
    },
    SET_STOCK_ITEMS_REPLACE(state, stockItems: any) {
      // console.log(stockItems)
      const index = state.stockItems.find((a: any) => a.stockItemId.toString() === stockItems.stockItemId.toString())
      console.log(index)

      state.stockItems[index] = stockItems
    },
    SET_STOCK_ITEM_BOARDS_ALL(state, stockItemBoards: any) {
      state.stockItemBoardsAll = state.stockItemBoardsAll.concat(stockItemBoards)
    },
    SET_STOCK_ITEM_BOARDS_ALL_COUNT(state, stockItemBoardsCount: any) {
      state.stockItemBoardsAllCount = stockItemBoardsCount
    },
    SET_STOCK_ITEM_BOARDS_ALL_HASMORE(state, hasMore: boolean) {
      state.stockItemBoardsAllHasmore = hasMore
    },
    SET_STOCK_ITEM_BOARDS_FOR_HOME(state, stockItemBoards: any) {
      state.stockItemBoardsHome = stockItemBoards
    },
    SET_STOCK_ITEM_BOARDS_HASMORE(state, hasMore: boolean) {
      state.stockItemBoardsHasmore = hasMore
    },
    SET_STOCK_ITEM_BOARD_COUNT(state, stockItemBoardsCount: any) {
      state.stockItemBoardsCount = stockItemBoardsCount
    },

  },
  namespaced: true,
  state: {
    initStockItem: {},
    popularStockItem: [],
    recentViewList: [],
    selectedStockItem: {},
    stockItemBoards: [],
    stockItemBoardsAll: [],
    stockItemBoardsAllCount: 0,
    stockItemBoardsAllHasmore: true,
    stockItemBoardsCount: 0,
    stockItemBoardsHasmore: true,
    stockItemBoardsHome: [],
    stockItemInitialCall: false,
    stockItemLogos: {},
    stockItemPartnershipHistories: [],
    stockItems: [],
  },
}

export default StockItem
