// import RegistEmailModal from '@/components/Modal/RegistEmailModal.vue'
// import ClubDealJoinModal from '@/components/Modal/ClubDeal/ClubDealJoinModal.vue'
// import ClubDealOpenJoinModal from '@/components/Modal/ClubDeal/ClubDealOpenJoinModal.vue'
// import CollectClubDeals from '@/components/Modal/Home/CollectClubDeals.vue'
// import PreApplication from '@/components/Modal/Home/PreApplication.vue'
// import CollectPartnerships from '@/components/Modal/Home/CollectPartnerships.vue'
import {modalController} from '@ionic/vue'
// import CompanyModal from '@/components/Modal/Company/CompanyModal.vue'
// import CompanyModal from '@/components/Modal/Company/CompanyModal.vue'
// import CompanyModal2 from '@/components/Modal/Company/CompanyModal2.vue'
// import ClubDealProgressModal from '@/components/Modal/ClubDeal/ClubDealProgressModal.vue'
// import ClubDealOpenModal from '@/components/Modal/ClubDeal/ClubDealOpenModal.vue'
// import ClubDealSellChatModal from '@/components/Modal/Chat/ClubDealSellChatModal.vue'
// import ClubDealBuyChatModal from '@/components/Modal/Chat/ClubDealBuyChatModal.vue'
// import SellItemModal from '@/components/Modal/SellItemModal.vue'
// import FAQModal from '@/components/Modal/FAQModal.vue'
// import KakaoKlipModal from '@/components/Modal/KakaoKlipModal.vue'

import {defineAsyncComponent} from 'vue'

export default function useModal() {
  async function useRegistEmailModal() {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/RegistEmailModal.vue')),
        cssClass: 'full-screen-modal',
        id: 'registEmailModal',
        keyboardClose: true,
      })

    return modal.present()
  }

  async function usePreApplicationModal() {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/Home/PreApplication.vue')),
        cssClass: 'full-screen-modal',
        id: 'preApplication',
        keyboardClose: true,
      })

    return modal.present()
  }

  async function useCollectPartnerships() {
    const modal = await modalController
      .create({
        backdropDismiss: true,
        component: defineAsyncComponent(() => import('@/components/Modal/Home/CollectPartnerships.vue')),
        cssClass: 'collect-partnership-modal',
        id: 'collectPartnership',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })

    return modal.present()
  }

  async function usePopularShareTransaction() {
    const modal = await modalController
      .create({
        backdropDismiss: true,
        component: defineAsyncComponent(() => import('@/views/view/home/popular-share-transaction/PopularShareTransactionModal.vue')),
        cssClass: 'collect-partnership-modal',
        id: 'collectPartnership',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })

    return modal.present()
  }

  async function viewCollectClubdealsModal() {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/Home/CollectClubDeals.vue')),
        componentProps: {
          modelValue: true,
        },
        cssClass: 'full-screen-modal',
        id: 'collectClubDeals',
        keyboardClose: true,
        mode: 'ios',
      })

    return modal.present()
  }

  async function useClubDealJoinModal(clubDealId: number) {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/ClubDeal/ClubDealJoinModal.vue')),
        componentProps: {
          clubDealId,
        },
        cssClass: 'full-screen-modal',
        id: 'clubDealJoinModal',
        keyboardClose: true,
      })

    return modal.present()
  }

  async function useClubDealOpenJoinModal(clubdealId: number, channelUrl: string, partnershipOpenChannelId: number) {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/ClubDeal/ClubDealOpenJoinModal.vue')),
        componentProps: {
          channelUrl,
          clubdealId,
          partnershipOpenChannelId,
        },
        cssClass: 'full-screen-modal',
        id: 'clubDealOpenJoinModal',
        keyboardClose: true,
      })
    await modal.present()
    return modal
  }

  async function useCompanyModal(stockItemId: number, type?: string | undefined, tab?: string) {
    const modal = await modalController
      .create({
        component: process.env.VUE_APP_UI_MODE === 'ios-pass' ?
          defineAsyncComponent(() => import('@/components/Modal/Company/CompanyModal2.vue')) :
          defineAsyncComponent(() => import('@/components/Modal/Company/CompanyModal.vue')),
        componentProps: {
          stockItemId,
          tabName: tab,
          type,
        },
        cssClass: 'full-screen-modal',
        id: 'companyModal',
        keyboardClose: true,
      })
    await modal.present()
    return modal
  }

  async function useClubDealProgressModal(partnershipId: number, from?: string) {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/ClubDeal/ClubDealProgressModal.vue')),
        componentProps: {
          from,
          partnershipId,
        },
        cssClass: 'full-screen-modal',
        id: 'clubDealProgressModal',
        keyboardClose: true,
      })
    await modal.present()
    return modal
  }

  async function useClubDealOpenModal(partnershipId: number, from?: string) {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/ClubDeal/ClubDealOpenModal.vue')),
        componentProps: {
          from,
          partnershipId,
        },
        cssClass: 'full-screen-modal',
        id: 'clubDealOpenModal',
        keyboardClose: true,
      })
    await modal.present()
    return modal
  }

  async function useClubDealBuyChatModal(channelUrl: string) {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/Chat/ClubDealBuyChatModal.vue')),
        componentProps: {
          channelUrl,
        },
        cssClass: 'full-screen-modal',
        id: 'clubDealBuyChatModal',
        keyboardClose: true,
      })
    await modal.present()
    return modal
  }

  async function useClubDealSellChatModal(channelUrl: string) {
    const modal = await modalController
      .create({
        component: defineAsyncComponent(() => import('@/components/Modal/Chat/ClubDealSellChatModal.vue')),
        componentProps: {
          channelUrl,
        },
        cssClass: 'full-screen-modal',
        id: 'clubDealSellChatModal',
        keyboardClose: true,
      })
    await modal.present()
    return modal
  }

  async function openSellModal() {
    const modal = await modalController
      .create({
        backdropDismiss: true,
        component: defineAsyncComponent(() => import('@/components/Modal/SellItemModal.vue')),
        componentProps: {},
        cssClass: 'sell-item-modal',
        id: 'SellItemModal',

        // mode: 'ios',
keyboardClose: true,

swipeToClose: true,
      })
    await modal.present()

    // if (disMissTarget) {
      // await modalController.dismiss(null, 'KakaoKlipModal', disMissTarget)
    // }
  }

  async function openFAQModal(modalType: string) {
    const modal = await modalController
      .create({
        backdropDismiss: true,
        component: defineAsyncComponent(() => import('@/components/Modal/FAQModal.vue')),
        componentProps: {
          type: modalType,
        },
        cssClass: 'faq-modal',
        id: 'FAQModal',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })

    return modal.present()
  }

  async function viewKakaoKlipModal() {
    const modal = await modalController
      .create({
        backdropDismiss: true,
        component: defineAsyncComponent(() => import('@/components/Modal/KakaoKlipModal.vue')),
        cssClass: 'kakao-klip-modal',
        id: 'kakaoKlipModal',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })

    return modal.present()
  }

  return {
    openFAQModal,
    openSellModal,
    useClubDealBuyChatModal,
    useClubDealJoinModal,
    useClubDealOpenJoinModal,
    useClubDealOpenModal,
    useClubDealProgressModal,
    useClubDealSellChatModal,
    useCollectPartnerships,
    useCompanyModal,
    usePopularShareTransaction,
    usePreApplicationModal,
    useRegistEmailModal,
    viewCollectClubdealsModal,
    viewKakaoKlipModal,
  }
}
