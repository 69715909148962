import {unref, Ref} from 'vue'

export type UnRef<T> = T extends Ref<infer V> ? V : T

export const unRefs = <Key extends string, T>(refs: Record<Key, T>): Record<Key, UnRef<T>> => {
  const _refs: any = refs
  return Object.keys(_refs).reduce((result, key) => {
    result[key] = unref(_refs[key])
    return result
  }, {} as Record<any, any>)
}
