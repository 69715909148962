import {CSSObject} from '@emotion/css'
import {fontSizes} from './font-sizes'
import {fontWeights} from './font-weights'
import {lineHeights} from './line-heights'

const tit14b: CSSObject = {
  fontSize: fontSizes.f14,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l22,
}

const tit16b: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l24,
}
const tit16r: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l24,
}

const tit18b: CSSObject = {
  fontSize: fontSizes.f18,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l26,
}

const tit18r: CSSObject = {
  fontSize: fontSizes.f18,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l26,
}

const tit20b: CSSObject = {
  fontSize: fontSizes.f20,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l29,
}

const tit20r: CSSObject = {
  fontSize: fontSizes.f20,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l24,
}

const tit22b: CSSObject = {
  fontSize: fontSizes.f22,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l32,
}

const tit22r: CSSObject = {
  fontSize: fontSizes.f22,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l32,
}

const tit24b: CSSObject = {
  fontSize: fontSizes.f24,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l32,
}

const tit24r: CSSObject = {
  fontSize: fontSizes.f24,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l32,
}
const tit26r: CSSObject = {
  fontSize: fontSizes.f26,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l32,
}

const tit26b: CSSObject = {
  fontSize: fontSizes.f26,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l32,
}

const tab16b: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l20,
}

const tab16m: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w5,
  lineHeight: lineHeights.l20,
}

const tab18b: CSSObject = {
  fontSize: fontSizes.f18,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l20,
}

const tab18m: CSSObject = {
  fontSize: fontSizes.f18,
  fontWeight: fontWeights.w5,
  lineHeight: lineHeights.l20,
}

//

const main16r: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l24,
}
const main20r: CSSObject = {
  fontSize: fontSizes.f20,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l29,
}
const mainL16r: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l28,
}
const main14r: CSSObject = {
  fontSize: fontSizes.f14,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l22,
}
const main13r: CSSObject = {
  fontSize: fontSizes.f13,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l20,
}
const main12r: CSSObject = {
  fontSize: fontSizes.f12,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l20,
}

//
const num18bk: CSSObject = {
  fontSize: fontSizes.f18,
  fontWeight: fontWeights.w9,
  lineHeight: lineHeights.l24,
}
const num16bk: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w9,
  lineHeight: lineHeights.l24,
}
const num16eb: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w8,
  lineHeight: lineHeights.l24,
}
const num14bk: CSSObject = {
  fontSize: fontSizes.f14,
  fontWeight: fontWeights.w9,
  lineHeight: lineHeights.l20,
}
const num14eb: CSSObject = {
  fontSize: fontSizes.f14,
  fontWeight: fontWeights.w8,
  lineHeight: lineHeights.l22,
}
const num20eb: CSSObject = {
  fontSize: fontSizes.f20,
  fontWeight: fontWeights.w8,
  lineHeight: lineHeights.l24,
}
const num24eb: CSSObject = {
  fontSize: fontSizes.f24,
  fontWeight: fontWeights.w8,
  lineHeight: lineHeights.l32,
}
const num26eb: CSSObject = {
  fontSize: fontSizes.f26,
  fontWeight: fontWeights.w8,
  lineHeight: lineHeights.l32,
}



const num30eb: CSSObject = {
  fontSize: fontSizes.f30,
  fontWeight: fontWeights.w8,
  lineHeight: lineHeights.l44,
}

//
const list17m: CSSObject = {
  fontSize: fontSizes.f17,
  fontWeight: fontWeights.w5,
  lineHeight: lineHeights.l20,
}
const list16m: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w5,
  lineHeight: lineHeights.l24,
}
const list15m: CSSObject = {
  fontSize: fontSizes.f15,
  fontWeight: fontWeights.w5,
  lineHeight: lineHeights.l28,
}
const list15r: CSSObject = {
  fontSize: fontSizes.f15,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l28,
}

const btn12r: CSSObject = {
  fontSize: fontSizes.f12,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l18,
}

const btn12b: CSSObject = {
  fontSize: fontSizes.f12,
  fontWeight: fontWeights.w7,
  lineHeight: lineHeights.l18,
}

const text16r: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l24,
}

const text12r: CSSObject = {
  fontSize: fontSizes.f12,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l22,
}

const text13r: CSSObject = {
  fontSize: fontSizes.f13,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l22,
}

const text14r: CSSObject = {
  fontSize: fontSizes.f14,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l22,
}
const text16m: CSSObject = {
  fontSize: fontSizes.f16,
  fontWeight: fontWeights.w4,
  lineHeight: lineHeights.l24,
}
const text17m: CSSObject = {
  fontSize: fontSizes.f17,
  fontWeight: fontWeights.w5,
  lineHeight: lineHeights.l24,
}

const h1: CSSObject = {
  fontSize: '36px',
  fontWeight: fontWeights.bold,
  lineHeight: '44px',
}

const h2: CSSObject = {
  fontSize: '30px',
  fontWeight: fontWeights.bold,
  lineHeight: '40px',
}

const h3: CSSObject = {
  fontSize: '24px',
  fontWeight: fontWeights.bold,
  lineHeight: '30px',
}

const h4: CSSObject = {
  fontSize: '20px',
  fontWeight: fontWeights.normal,
  lineHeight: '28px',
}

const h5: CSSObject = {
  fontSize: '18px',
  fontWeight: fontWeights.normal,
  lineHeight: '28px',
}

const h6: CSSObject = {
  fontSize: '17px',
  fontWeight: fontWeights.normal,
  lineHeight: '28px',
}

const h7: CSSObject = {
  fontSize: '16px',
  fontWeight: fontWeights.normal,
  lineHeight: '28px',
}

const h8: CSSObject = {
  fontSize: '15px',
  fontWeight: fontWeights.normal,
  lineHeight: '28px',
}

const h9: CSSObject = {
  fontSize: '14px',
  fontWeight: fontWeights.normal,
  lineHeight: '28px',
}

const h10: CSSObject = {
  fontSize: '13px',
  fontWeight: fontWeights.normal,
  lineHeight: '28px',
}

const h11: CSSObject = {
  fontSize: '12px',
  fontWeight: fontWeights.normal,
  lineHeight: '28px',
}

export const typography = {
  btn12b,
  btn12r,
  h1,
  h10,
  h11,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8,
  h9,
  list15m,
  list15r,
  list16m,
  list17m,
  main12r,
  main13r,
  main14r,
  main16r,
  main20r,
  mainL16r,
  num14bk,
  num14eb,
  num16bk,
  num16eb,
  num18bk,
  num20eb,
  num24eb,
  num26eb,
  num30eb,
  tab16b,
  tab16m,
  tab18b,
  tab18m,
  text12r,
  text13r,
  text14r,
  text16m,
  text16r,
  text17m,
  tit14b,
  tit16b,
  tit16r,
  tit18b,
  tit18r,
  tit20b,
  tit20r,
  tit22b,
  tit22r,
  tit24b,
  tit24r,
  tit26b,
  tit26r,
}

export type Typography = typeof typography
export type TypographyType = keyof Typography
