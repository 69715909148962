import {AgAlert} from '@/components/ag'
import {requestPermission, storageRef, toMutRef} from '@/composables'
import {css, useTheme} from '@/plugins/emotion'
import {systems} from '@/plugins/emotion/systems'
import {isPlatform} from '@ionic/vue'
import {computed, defineComponent, h, reactive} from 'vue'

export const useNotificationAllowMessage = () => {
  const endToShow = storageRef('__end_to_show_notification_allow_message__', false)
  return reactive({
    endToShow,
  })
}

export const NotificationAllowMessage = defineComponent({
  emits: ['update:modelValue'],
  name: 'NotificationAllowMessage',
  props: {
    modelValue: {
      default: false,
      type: Boolean,
    },
  },
  render() {
    const {
      show,
      title,
      message,
      yesButton,
    } = this
    return (
      h(AgAlert, {
        buttons: [yesButton],
        message,
        modelValue: show,
        title,
      })
    )
  },
  setup(props, {emit}) {
    const theme = useTheme()
    const notificationAllowMessage = useNotificationAllowMessage()
    const showRef = toMutRef(props, 'modelValue')
    const show = computed(() => {
      if (isPlatform('hybrid') && !notificationAllowMessage.endToShow) {
        return showRef.value
      }
      return false
    })
    const title = '엔젤리그 알림이 좋은 이유!'
    const messageCss = computed(() => css({
        '& strong': {
          color: '#4360EC',
          fontWeight: 'bold',
        },
      },
      systems({
        color: 'font',
        theme,
        typography: 'h7',
      })))
    const message = computed(() => `<div class="${messageCss.value}"><p>정확하고 안전한 클럽딜 참여를 위해 <strong>입금 확인/판매/협의</strong> 등의 <strong>현황 알림</strong>을 <strong>실시간으로 확인</strong>하셔야 해요</p><P>주요 정보를 놓치실 수 있으니 엔젤리그 알림 받기 동의해주세요🙂</P></div>`)

    const closeAndAllow = () => {
      notificationAllowMessage.endToShow = true
      requestPermission()
      showRef.value = false
      emit('update:modelValue', false)
    }

    const yesButton = computed(() => ({
      handler: () => closeAndAllow(),
      text: '확인',
    }))
    return {
      message,
      show,
      title,
      yesButton,
    }
  },
})

export default NotificationAllowMessage
