import {Module} from 'vuex'
import {RootState} from '..'
import apiCommon from '@/api/common'
const {
  getFAQ,
  getIntro,
  getProcess,
} = apiCommon()
// state
interface Common {
    FAQ: any
    Intro: any
    Process: any
}

// action
const Common: Module<Common, RootState> = {

  actions: {
    ACT_GET_FAQ_DATA({commit}) {
      getFAQ().then((value) => {
        commit('SET_FAQ', value.faq)
      })
    },
    ACT_GET_INTRO_DATA({commit}) {
      getIntro().then((value) => {
        commit('SET_INTRO', value.angelleagueIntroduction)
      })
    },
    ACT_GET_PROCESS_DATA({commit}) {
      getProcess().then((value) => {
        commit('SET_PROCESS', value.partnershipProcess)
      })
    },
  },

  getters: {
    getFAQ: state => state.FAQ,
    getIntro: state => state.Intro,
    getProcess: state => state.Process,
  },

  // RootState와 똑같이 작성해도 된다.
  mutations: {
    SET_FAQ(state, FAQ: any) {
      state.FAQ = FAQ
    },
    SET_INTRO(state, Intro: any) {
      state.Intro = Intro
    },
    SET_PROCESS(state, Process: any) {
      state.Process = Process
    },
  },
  namespaced: true,
  state: {
    FAQ: null,
    Intro: null,
    Process: null,
  },
}

export default Common
