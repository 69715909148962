import {styled} from '@/plugins/emotion'

export const Img = styled('img', {
  props: {
    height: null,
    width: null,
  },
})(
  {
    height: '100%',
    label: 'img',
    position: 'relative',
    width: '100%',
  },
  ({width}) => {
    if (!width || width !== 'auto') {
      return {}
    }
    return {
      width: 'unset',
    }
  },
  ({height}) => {
    if (!height || height !== 'auto') {
      return {}
    }
    return {
      height: 'unset',
    }
  },
)
