
import {
  alertController, IonPage, IonContent, IonFooter, modalController,
  IonRippleEffect,
} from '@ionic/vue'
import {defineComponent, computed, ref, onBeforeMount, getCurrentInstance, onMounted} from 'vue'
import SellAgreeInfo from '@/components/Modal/SellAgreeInfo.vue'
import SuggestModal from '@/components/Modal/SuggestModal.vue'
import SellKeypad from '@/components/Modal/SellKeypad.vue'
import SellMessage from '@/components/Chat/SellMessage.vue'

import {chevronBack} from 'ionicons/icons'
import {useStore} from 'vuex'
import {useRouter, useRoute, onBeforeRouteLeave} from 'vue-router'
// import SendBird from '@/plugins/SendBird.ts'
import {SendBirdChatEvent} from '@/plugins/SendBirdChatEvent'
import {SendBirdAction} from '@/plugins/SendBirdAction'
import channelApi from '@/api/open-channel'
import {getFormatDate} from '@/utils/filters'

export default defineComponent({
  components: {
    IonContent,
    IonFooter,
    IonPage,
    IonRippleEffect,
    SellMessage,
  },
  name: 'SellChat',
  props: {
    channelUrl: null,
  },
  setup(props) {
    const app = getCurrentInstance()
    const $modalOpenEvent = app && app.appContext.config.globalProperties.$modalOpenEvent
    const {getters, commit, dispatch} = useStore()
    const {sendNewMessage, markAsRead} = channelApi()

    const route = useRoute()
    const router = useRouter()

    const user = computed(() => getters['user/getUser'])
    const userId = computed(() => user.value ? user.value.userId.toString() : '')
    const nickname = computed(() => user.value ? user.value.nickname : '')

    // 초기화 필요한 아이들
    const channelMetaData: any = ref(null)
    const messageMap = ref<any>(new Map())
    const unreadList = ref<any>([])
    const channelUrl = ref(props.channelUrl)

    const currentChannel = ref<any>(null)
    const lastMessageId = ref<any>(null)
    const element: any = computed(() => document.querySelector('#ionContent'))

    const partnershipOpenChannelId = computed(() => channelMetaData.value ? channelMetaData.value.partnershipOpenChannelId : '')
    const channelStatus = computed(() => channelMetaData.value ? channelMetaData.value.status : '')
    const partnershipID = computed(() => channelMetaData.value ? channelMetaData.value.partnershipId : '')

    const LeadNickname = computed(() => {
      if (currentChannel.value) {
        return currentChannel.value.members
          .filter((a: any) => a.userId !== 'angelleague' && a.userId !== userId.value)
          .map((a: any) => a.nickname === 'null' ? '리드엔젤' : a.nickname).toString()
      }
      return ''
    })

    // scroll setting
    async function moveToBottom() {
      // console.log('moveToBottom')
      if (channelUrl.value) {
        // console.log(1);
        await markAsRead(channelUrl.value, userId.value)
        await dispatch('message/ACT_UNREAD_COUNT')
        // console.log(2);
        setTimeout(() => {
          // console.log(3);
          element.value.scrollToBottom()
          // console.log(4);
        }, 200)
      }
    }

    // sendbird setting

    const getMessageList = async (groupChannel: any) => {
      const sba: any = await SendBirdAction.getInstance()
      const messages: any = await sba.getMessageList(groupChannel, true)
      messages.forEach((item: any) => {
        const key = getFormatDate(new Date(item.createdAt))

        item.unreadStatus = groupChannel.getUnreadMembers(item).length > 1
        if (item.unreadStatus === true) {
          unreadList.value.push(item.messageId)
        }

        if (messageMap.value.has(key)) {
          messageMap.value.get(key).push(item)
        } else {
          messageMap.value.set(key, [item])
        }
      })

      lastMessageId.value = groupChannel.lastMessage.messageId
      moveToBottom()
      groupChannel.getAllMetaData()
        .then((data: any) => {
          channelMetaData.value = data
        })
    }

    const getNextMessage = async (groupChannel: any, MessageId: number, type?: string) => {
      const sba: any = await SendBirdAction.getInstance()
      const messages: any = await sba.getMessagesByMessageId(groupChannel, MessageId, type)
      let index = 0

      // console.log(messages);

      messages.forEach((message: any) => {
        const key = getFormatDate(new Date(message.createdAt))
        message.unreadStatus = groupChannel.getUnreadMembers(message).length > 1
        if (message.unreadStatus === true) {
          unreadList.value.push(message.messageId)
        }
        if (type === 'update') {
          const array = messageMap.value.get(key)
          array[array.length - 1] = message
        } else if (type === 'reset' && !message.unreadStatus) { // type이 reset이고 unreadStatus가 false이면?
          const arrayIndex = messageMap.value.get(key).map((a: any) => a.messageId).lastIndexOf(unreadList.value[index])
          messageMap.value.get(key)[arrayIndex] = message
          index++
        } else {
          if (messageMap.value.has(key)) {
            messageMap.value.get(key).push(message)
          } else {
            messageMap.value.set(key, [message])
          }
        }
      })

      if (type === 'reset') {
        unreadList.value = []
      }
      lastMessageId.value = groupChannel.lastMessage.messageId
      moveToBottom()

      groupChannel.getAllMetaData()
        .then((data: any) => {
          channelMetaData.value = data
        })
    }

    // const sendBirdChatEvent = SendBirdChatEvent.getInstance()
    // sendBirdChatEvent.onMessageReceived = async (channel: any, message: any) => {
    //   if (channel.url === channelUrl.value) {
    //     await getNextMessage(channel, lastMessageId.value, 'receive')
    //   }
    // }
    //
    // sendBirdChatEvent.onReadReceiptUpdated = async (channel: any) => {
    //   if (channel.url === channelUrl.value && unreadList.value[0]) {
    //     await getNextMessage(channel, unreadList.value[0], 'reset')
    //   }
    // }

    async function init() {
      // if (channelUrl.value) {
      //   const sendbirdAction: any = await SendBirdAction.getInstance()
      //   sendbirdAction
      //     .getChannel(channelUrl.value, false)
      //     .then((channel: any) => {
      //       getMessageList(channel)
      //       currentChannel.value = channel
      //     })
      //     .catch((error: any) => {
      //       console.error(error)
      //     })
      // }
    }

    onBeforeMount(async () => {
      await init()
      moveToBottom()
    })

    const clickScreen = 'negotiation-sell'
    onMounted(()=>{
      $modalOpenEvent({page: clickScreen, title: '클럽딜 열기 판매자 협의창'})
    })

    function resetRefs() {
      channelMetaData.value = null
      messageMap.value = new Map()
      unreadList.value = []
      channelUrl.value = ''
    }

    async function cancelToLeadAngel() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              handler: () => {
                // console.log('Cancel clicked');
              },
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                await sendNewMessage(Number.parseInt(partnershipID.value), partnershipOpenChannelId.value, 'cancelToLeadAngel')
                  .then(async (message) => {
                    // console.log(message);
                    const sba: any = await SendBirdAction.getInstance()
                    const temporaryMessage = sba.sendUserMessage({
                      channel: currentChannel.value,
                      handler: (message: any, error: any) => {
                        // console.log(message, error);
                        getNextMessage(currentChannel.value, lastMessageId.value, 'send')
                      },
                      message: message.message,
                      status: 'cancelToLeadAngel',
                    })
                  })
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups adderss-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              리드엔젤과 협의를<br/>
              취소하시겠어요?
            </div>
            <div class="message">
              리드엔젤에게 정중한 협의 취소 메시지를<br/>
              전송한 후, 협의를 종료합니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    // functions
    async function suggestToLeadAngel(type: string) {
      let modal: any = null
      modal = await modalController
        .create({
          backdropDismiss: true,
          component: SellKeypad,
          componentProps: {
            channel: currentChannel.value,
            channelMetaData: channelMetaData.value,
            channelUrl: channelUrl.value,
            suggestType: type,
          },
          cssClass: 'suggest-keypad-modal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      modal.onDidDismiss().then(async (data: any) => {
        if (data.data) {
          getNextMessage(currentChannel.value, lastMessageId.value, 'send')
        }
      })

      return modal.present()
    }

    async function createModal(name: string) {
      let modal: any = ''
      if (name === 'approveToLeadAngel') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: SellAgreeInfo,
            componentProps: {
              channel: currentChannel.value,
              channelMetaData: channelMetaData.value,
              channelUrl: channelUrl.value,
              userId: userId.value,
            },
            cssClass: 'sell-agree-info-modal',
            id: 'SellAgreeInfo',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(async (data: any) => {
          if (data.data) {
            getNextMessage(currentChannel.value, lastMessageId.value, 'send')
          }
        })
      } else if (name === 'suggestOtherCondition') {
        modal = await modalController
          .create({
            backdropDismiss: true,
            component: SuggestModal,
            componentProps: {
              channelMetaData: channelMetaData.value,
              channelUrl: channelUrl.value,
              userId: userId.value,
            },
            cssClass: 'suggest-modal',
            id: 'SuggestModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(async (data: any) => {
          if (data.data === 'cancelToLeadAngel') {
            getNextMessage(currentChannel.value, lastMessageId.value, 'send')
          } else if (data.data === 'suggestToLeadAngel') {
            await suggestToLeadAngel('suggestToLeadAngel')
          }
        })
      }

      return modal.present()
    }

    function printDate(createdAt: string) {
      const date = new Date(createdAt)
      const days = ['일', '월', '화', '수', '목', '금', '토']

      return getFormatDate(date).slice(2).replace(/-/gi, '. ') + ' ' + days[date.getDay()] + '요일'
    }

    async function closeModal() {
      await modalController.dismiss()
      resetRefs()
    }

    return {
      LeadNickname,
      cancelToLeadAngel,
      channelStatus,
      chevronBack,
      clickScreen,
      closeModal,
      createModal,
      curChannel: currentChannel,
      getFormatDate,
      messageMap,
      moveToBottom,
      printDate,
      route,
      router,
      suggestToLeadAngel,
      userId,
    }
  },
})

