
import {computed, defineComponent, reactive, ref, watch} from 'vue'
import {modalController, IonContent, alertController, IonFooter} from '@ionic/vue'
import {useStore} from 'vuex'
import apiUser from '@/api/user'
const {checkNickNameDup, saveNicknameAPI} = apiUser()
export default defineComponent({
  components: {
    IonContent,
    // IonFooter
  },
  name: 'BoardWriteModal',
  props: {
    stockItemBoard: null,
    stockItemId: null,
  },
  setup(properties) {
    const {state, dispatch, commit, getters} = useStore()

    const focusToggle = ref(false)
    const buttonToggle = ref(false)
    const user = getters['user/getUser']

    // const nickNameFilter = ref<string>(user.nickname);
    const nickName = ref<string>(user.nickname)
    const noDupNickName = ref<string>('')
    const disabled = ref(false)
    const nodup = ref(false)

    // const reValue = reactive("");

    const boardData = ref({
      contents: properties.stockItemBoard.contents || '',
      ogData: properties.stockItemBoard.ogData || null,
      point: properties.stockItemBoard.point || null,
      stockItemBoardId: properties.stockItemBoard.stockItemBoardId || null,
      stockItemId: `${properties.stockItemId}`,
    })

    if (boardData.value.contents) {
      focusToggle.value = true
    }

    const contentsReference = ref(boardData.value.contents)

    const greenScore = ref(null)
    const ratings = [1, 2, 3, 4, 5]
    const ratingComments = {
      1: '성장성이 없어보여요',
      2: '고민되는 종목이에요',
      3: '포트폴리오에 넣을 만 해요',
      4: '투자하기 좋은 성장성!',
      5: '꼭 가져야 할 종목이에요!!',
    }

    const dismiss = () => {
      modalController.dismiss()
    }

    const onFocus = async () => {
      focusToggle.value = await true
      const tag: any = await document.querySelector('#input_content')
      await tag.focus()
    }

    const saveScore = (score: any) => {
      boardData.value.point = score
    }

    const onBlur = () => {
      focusToggle.value = !(boardData.value.contents == null || boardData.value.contents === '')
    }

    const changingNickname = async () => {
      buttonToggle.value = !buttonToggle.value
      const tag: any = await document.querySelector('#nickname_box')
      await tag.focus()
    }

    const OPINION_REGIST_TITLE = '의견 등록하기'
    const NICKNAME_CHANGE_TITLE = '닉네임 변경'

    const openAlert = async (title: string, message: string) => {
      const alert = await alertController
        .create({
          buttons: ['확인'],
          cssClass: 'lead-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              ${title}
            </div>
            <div class="message">
              ${message}
            </div>
          </div>
          `,
        })
      return alert.present()
    }

    const nickNameTyping = (event: Event) => {
      const nickname = (event.target as any).value
      if (noDupNickName.value) {
        nodup.value = nickname === noDupNickName.value
      }
    }

    const checkNicknamePattern = (text: string) => {
      const checkSpace = /\s/g
      const checkEng = /[A-Za-z]/
      const checkKor = /[|ㄱ-ㅣ가-힣]/
      const d = {msg: '', pass: true}

      if (checkSpace.test(text)) {
        d.pass = false
        d.msg = '닉네임에는 공백이 포함될 수 없습니다.'
        return d
      }
      if (!checkEng.test(text) && !checkKor.test(text)) {
        d.pass = false
        d.msg = '한글이나 영어문자를 함께 사용해주세요.'
        return d
      }
      return d
    }
    const saveNickname = async () => {
      if (nickName.value.length > 10) {
        await openAlert(NICKNAME_CHANGE_TITLE, '닉네임은 10자까지 가능합니다.')
        return
      }

      const d = checkNicknamePattern(nickName.value)
      if (!d.pass) {
        await openAlert(NICKNAME_CHANGE_TITLE, d.msg)
        return
      }
      const {msg} = await dispatch('user/ACT_SAVE_NICKNAME', nickName.value)
      await openAlert(NICKNAME_CHANGE_TITLE, msg)
      buttonToggle.value = false
      nodup.value = false
    }

    const checkNicknameDup = async () => {
      if (nickName.value.length > 5) {
        await openAlert(NICKNAME_CHANGE_TITLE, '닉네임은 5자까지 가능합니다.')
        return
      }

      const d = checkNicknamePattern(nickName.value)
      if (!d.pass) {
        await openAlert(NICKNAME_CHANGE_TITLE, d.msg)
        return
      }

      const result = await checkNickNameDup(nickName.value)
      nodup.value = result.result
      noDupNickName.value = result.nickname
      await openAlert(NICKNAME_CHANGE_TITLE, result.msg)

      // vm.modal_simple.title = data.msg;
      // $('#modal-simplealert').modal('show');
      // vm.nodup = data.result;
      // vm.nodup_nickname = data.nickname;
    }

    const checkUrl = (text: string) => {
      const urlRegex = /https?:\/\/(www\.)?[\w#%.:=@~-]{1,256}\.[\d()A-Za-z]{1,6}\b([\w#%&()./:=?@~-]*)?/g
      return text.match(urlRegex)
    }

    const handleContent = (e: any) => {
      if (e.target.value.length >= 400) {
        e.target.value = e.target.value.slice(0, 400)
        boardData.value.contents = e.target.value
      }

      const isthisurl = checkUrl(boardData.value.contents)
      // todo 링크 붙여넣기할때 바로 meta 가져올 것인가.
    }

    const contentLengthCheck = () => {
      return !(boardData.value.contents.length >= 20)
    }

    const saveBoard = async () => {
      if (contentLengthCheck()) {
        await openAlert(OPINION_REGIST_TITLE, '20자 이상 작성해주세요!')
        return
      }
      const ok = await dispatch('stockItem/ACT_SAVE_STOCK_ITEM_BOARDS', {
        boardData: boardData.value,
        stockItemId: properties.stockItemId,
      })
      if (ok) {
        await openAlert(OPINION_REGIST_TITLE, '저장되었습니다.')
        dismiss()
      }
    }

    const clickScreen = 'board-write-modal'
    return {
      boardData,
      btnToggle: buttonToggle,
      changingNickname,
      checkNicknameDup,
      clickScreen,
      disabled,
      dismiss,
      focusToggle,
      handleContent,
      nickName,
      nickNameTyping,
      nodup,
      onBlur,
      onFocus,
      ratingComments,
      ratings,
      saveBoard,
      saveNickname,
      saveScore,
    }
  },
})
