import CallApi from '../utils/CallApi'

export default function() {
  const getWalletInfo = async () => {
    const data = await CallApi({
      method: 'get',
      url: '/wallet',
    })
    return data
  }
  
  const getDepositInfo = async () => {
    const data = await CallApi({
      method: 'get',
      url: '/wallet/deposit',
    })
    return data
  }
  const createDeposit = async (body: any) => {
    const data = await CallApi({
      data: body,
      method: 'post',
      url: '/wallet/deposit',
    })
    return data
  }
  
  const deleteDeposit = async () => {
    const data = await CallApi({
      method: 'delete',
      url: '/wallet/deposit',
    })
    return data
  }
  
  const getWithdrawInfo = async () => {
    const data = await CallApi({
      method: 'get',
      url: '/wallet/withdraw',
    })
    return data
  }
  
  const withdrawRequestCheck = async () => {
    const data = await CallApi({
      method: 'get',
      url: '/wallet/withdraw/check',
    })
    console.log(data)
    return data
  }
  
  const createWithdraw = async (body: any) => {
    const data = await CallApi({
      data: body,
      method: 'post',
      url: '/wallet/withdraw',
    })
    return data
  }
  
  const deleteWithdraw = async () => {
    const data = await CallApi({
      method: 'delete',
      url: '/wallet/withdraw',
    })
    return data
  }
  
  return {
    createDeposit,
    createWithdraw,
    deleteDeposit,
    deleteWithdraw,
    getDepositInfo,
    getWalletInfo,
    getWithdrawInfo,
    withdrawRequestCheck,
  }
}
