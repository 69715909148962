
import {
  IonPage,
  IonContent,
  modalController, toastController,
} from '@ionic/vue'
import {computed, defineComponent, ref, onBeforeMount} from 'vue'
import InputModal from '@/components/Modal/InputModal.vue'
import {useShare} from '@/composables'
import {useStore} from 'vuex'
import router from '../../router'
import apiPoint from '../../api/user-point'
import {convertDate, getDateDiff} from '@/utils/filters'
import useModal from '@/composables/useModal'
const {loadSaveHistory, loadUseHistory} = apiPoint()
import CollectClubDeals from '@/components/Modal/Home/CollectClubDeals.vue'
export default defineComponent({
  components: {
    CollectClubDeals,
    IonContent,
    IonPage,
  },
  setup() {
    const {getters, commit, dispatch, state} = useStore()
    const {viewCollectClubdealsModal} = useModal()
    const share = useShare()

    const point = computed(() => getters['pointInfo/getUserPoint'])
    const code = computed(() => getters['pointInfo/getInviteCode'])
    const registCode = computed(() => getters['pointInfo/getRegistInviteCode'])
    const pointArray = computed(() => getters['pointInfo/getTotalPoint'])
    const saveCount = computed(() => getters['pointInfo/saveCount'])
    const saveHistory = computed(() => getters['pointInfo/getSaveHistory'])
    const useCount = computed(() => getters['pointInfo/useCount'])
    const useHistory = computed(() => getters['pointInfo/getUseHistory'])
    const userName = computed(() => state.user.name)

    onBeforeMount(() => {
      dispatch('pointInfo/getPointLink')
      dispatch('pointInfo/loadData')
    })

    const saveIndex = ref(1)
    const useIndex = ref(1)
    const saveToggle = ref(true)
    const useToggle = ref(true)

    // onMounted(() => {
    //   if(saveCount.value <= 10) saveToggle.value = false
    //   if(useCount.value <= 10) useToggle.value = false
    // })

    const route = router

    const addSaveHistory = async () => {
      saveIndex.value = saveIndex.value + 1
      const history = await loadSaveHistory(saveIndex.value)
      // console.log(history.length)

      if (history.length % 10 !== 0) {
saveToggle.value = false
}
      commit('pointInfo/ADD_SAVE_HISTORY', history)
    }

    const addUseHistory = async () => {
      useIndex.value = useIndex.value + 1
      const history = await loadUseHistory(useIndex.value)
      if (history.length % 10 !== 0) {
useToggle.value = false
}
      commit('pointInfo/ADD_USE_HISTORY', history)
    }

    const onSuccess = async () => {
      // console.log('success')
      const toast = await toastController
        .create({
          duration: 1000,
          message: code.value + '가 클립보드에 복사되었습니다.',
          position: 'middle',
        })
      return toast.present()
    }

    const onError = () => {
      // console.log('error')
    }

    const activeTab = ref('save')

    const changeTab = (value: string) => {
      activeTab.value = value
    }

    const createModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: InputModal,
          componentProps: {
            registCode: registCode.value,
          },
          cssClass: 'input-modal',
          id: 'InputModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      return modal.present()
    }

    const textDiv = ref<any>(null)

    async function copyText(string: string) {
      const createInput = document.createElement('input')
      createInput.setAttribute('type', 'text')
      textDiv.value.append(createInput)
      createInput.value = string
      createInput.select()

      const result = document.execCommand('copy')
      createInput.remove()

      if (result) {
        onSuccess()
      }
    }

    const withIn90Days = (expireDate: string) => {
      const dateFormat = convertDate(expireDate)
      const today = new Date()

      return Math.floor(getDateDiff(today, dateFormat)) < 90
    }

    const clickScreen = 'point'
    const clubDealAllOpen = ref<boolean>(false)
    const link = computed(() => state.pointInfo.sharePointsLink)
    
    const onShare = () => {
      if (!link.value) {
        return
      }
      share({
        text: `엔젤리그 10,000P 적립 초대코드 ${code.value}`,
        title: '초대링크로 가입하기',
        url: link.value,
      })
    }
    
    return {
      activeTab,
      addSaveHistory,
      addUseHistory,
      changeTab,
      clickScreen,
      clubDealAllOpen,
      code,
      copyText,
      createModal,
      link,
      onError,
      onShare,
      onSuccess,
      point,
      pointArr: pointArray,
      registCode,
      route,
      saveCount,
      saveHistory,
      saveToggle,
      textDiv,
      useCount,
      useHistory,
      useToggle,
      viewCollectClubdealsModal,
      withIn90Days,
    }
  },
})
