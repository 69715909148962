import {Module} from 'vuex'
import {RootState} from '..'
import messageHistory from '@/data/message.json'
import userInfo from '@/data/users.json'
import stock from '@/data/stockDetail.json'
import stockInfo from '@/data/stock.json'

// state
interface DealInfo {
  comment: string
  company: any
  dealInfo: any
  message: any[]
  messageStatus: string
  payPrice: number
  point: number
  stockInfo: any
  userMoney: number
  userName: string
}

// action
const DealInfo: Module<DealInfo, RootState> = {
  
actions: {
    ADDMESSAGE({commit}, {message}) {
      commit('SET_MESSAGE', message)
    },
    DEAL_ACTION({commit}, comment: string) {
      commit('setData', comment)
    },
    LOADDEAL({commit}, {dealInfo}) {
      commit('SET_DEALINFO', dealInfo)
    },
    SEND_REQUEST({commit}, {price, percent, total}) {
      // console.log(price)

      const message = {
        create_at: new Date(),
        price: price,
        rate: percent,
        sender: 'buyer',
        total: total,
        type: 'suggest',
      }
      // console.log(message)

      commit('ADD_MESSAGE', message)
    },
  },
  

getters: {
    comment: state => state.comment,
    company(state) {
      return state.company
    },
    dealId(state) {
      // console.log(state.dealInfo)
      return state.dealInfo.clubdeal_id
    },
    dealInfo(state) {
      return state.dealInfo
    },
    message(state) {
      return state.message
    },
    messageStatus(state) {
      return state.messageStatus
    },
    payPrice(state) {
      return state.payPrice
    },
    point(state) {
      return state.point
    },
    stockInfo(state) {
      return state.stockInfo
    },
    userMoney(state) {
      return state.userMoney
    },
  },
  
  // RootState와 똑같이 작성해도 된다.
mutations: {
    async ADD_MESSAGE(state, message) {
      const price = Number(message.price)
      await state.message.push(message)

      if (state.dealInfo.min_price > price) {
        const newMessage = {
          create_at: new Date(),
          sender: 'seller',
          type: 'deny_suggest',
        }
        setTimeout(() => {
          state.message.push(newMessage)
          state.messageStatus = 'deny_suggest'
        }, 3000)
      } else {
        const newMessage = {
          create_at: new Date(),
          sender: 'seller',
          type: 'accept_suggest',
        }
        setTimeout(() => {
          state.message.push(newMessage)
          state.messageStatus = 'accept_suggest'
        }, 3000)
      }
    },
    SET_DEALINFO(state, dealInfo) {
      state.dealInfo = dealInfo
    },
    SET_PRICE(state, payPrice: number) {
      state.payPrice = payPrice
    },
    SET_TOGGLE(state, index_) {
      const index = state.stockInfo.findIndex((item: any) => item.id === index_)
      state.stockInfo[index].star = !state.stockInfo[index].star
      // console.log(index + " " + state.stockInfo[index].star)
    },
    setData(state, comment: string) {
      // console.log(comment)
      state.comment = comment
    },
  },
  namespaced: true,
  state: {
    comment: 'moduleA',
    company: stock,
    dealInfo: null,
    message: messageHistory.message,
    messageStatus: 'initial_price',
    payPrice: 0,
    point: 50000,
    stockInfo: stockInfo.stock,
    userMoney: userInfo.money,
    userName: userInfo.user_name,
  },
}

export default DealInfo

// import { StoreOptions } from 'vuex'

// interface Deal {
//   comment: string
//   date: string
//   name: string
//   price: number
//   percent: number
//   total: number
//   user: string
// }

// export const store: StoreOptions<Deal> = {
//   state: {
//     comment: "",
//     date: "",
//     name: "",
//     price: 0,
//     percent: 0,
//     total: 0,
//     user: "",
//   },
//   mutations: {
//     SET_DEAL(state, comment) {
//       state.comment = comment
//       // console.log(state.comment)
//     }
//   },
//   actions: {
//     DEAL_ACTION({ commit }, { comment }) {
//       commit('SET_DEAL', comment)
//     },
//   },
//   getters : {
//     deal(state) {
//       return state.comment
//     },
//   }
// }

// export default {
//   namespaced: true,
//   ...store
// }
