
import {defineComponent, onBeforeMount, ref} from 'vue'
import {AgBox, AgImg, AgText, AgContent, AgHeader} from '@/components/ag'
import {useRouter, useRoute} from 'vue-router'
import {getSimplePartnerships} from '@/api/negostock'
import dayjs from 'dayjs'

export default defineComponent({
  components: {
    AgBox,
    AgContent,
    AgHeader,
    AgImg,
    AgText,
  },
  name: 'PartnershipDetailInfo',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const partnershipInfo = ref<any>({})

    const formattedDate = (updateDate: any) => {
      return dayjs(updateDate).format('YYYY. MM. DD')
    }

    onBeforeMount(async () => {
      partnershipInfo.value = await getSimplePartnerships(route.params.partnershipId)
      console.log(partnershipInfo.value)
    })

    return {
      formattedDate,
      partnershipInfo,
      router,
    }
  },
})
