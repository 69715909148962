import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'
import {Property} from 'csstype'

export const animationSystems = system({
  animation: true,
  animationDelay: true,
  animationDirection: true,
  animationDuration: true,
  animationFillMode: true,
  animationIterationCount: true,
  animationName: true,
  animationPlayState: true,
  animationTimingFunction: true,
  transition: {
    property: 'transition',
    scale: 'transitions',
  },
})

export const animationProps = {
  animation: getResponsiveProp<Property.Animation>(),
  animationDelay: getResponsiveProp<Property.AnimationDelay>(),
  animationDirection: getResponsiveProp<Property.AnimationDuration>(),
  animationDuration: getResponsiveProp<Property.AnimationDuration>(),
  animationFillMode: getResponsiveProp<Property.AnimationFillMode>(),
  animationIterationCount: getResponsiveProp<Property.AnimationIterationCount>(),
  animationName: getResponsiveProp<Property.AnimationName>(),
  animationPlayState: getResponsiveProp<Property.AnimationPlayState>(),
  animationTimingFunction: getResponsiveProp<Property.AnimationTimingFunction>(),
  transition: getResponsiveProp<Property.Transition>(),
}
