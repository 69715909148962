import airbridge from 'airbridge-web-sdk-loader'
import {getPlatform} from '@/composables/platform'
import {Plugin} from 'vue'

const platform = getPlatform()


if (platform === 'web') {
  airbridge.init({
    app: 'angelleague',
    webToken: 'ea01e782f3194798bb208639e5b10a99',
  })
}

export const airbridgePlugin: Plugin = () => {
  // empty
}

export default airbridgePlugin
