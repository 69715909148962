import {RouteRecordRaw} from 'vue-router'

export const community: RouteRecordRaw[] = [
  {
    component: () => import('@/views/Community.vue'),
    name: 'Community',
    path: 'community',

  },
  {
    component: () => import('@/views/OpenChannel/OpenChannel.vue'),
    name: 'ChannelList',
    path: 'channel-list',
  },
]
