
import {defineComponent, toRef, ref, getCurrentInstance} from 'vue'
import {AgBox, AgToggleIcon, AgText} from '@/components/ag'

export default defineComponent({
  components: {
    AgBox,
    AgText,
    AgToggleIcon,
  },
  emits: ['allCheck', 'notYet'],
  name: 'SecondStep',
  props: {
    parent: {type: String},
    stakeName: {type: String},
  },
  setup(props, {emit}) {
    const parentRef = toRef(props, 'parent')
    const isChecked = ref<boolean>(false)
    const stakeNameRef = toRef(props, 'stakeName')

    const checkAct = () => {
      isChecked.value = !isChecked.value
      if (isChecked.value === true) {
        emit('allCheck', 'DONE')
      } else {
        emit('notYet')
      }
    }

    const clickScreen = parentRef.value

    return {checkAct, clickScreen, isChecked, stakeNameRef}
  },
})
