
import partnership from '@/api/partnership'
import {AgBoxS, AgTextS, AgToggleIcon, AgRefresher, AgIcon, AgButtonS, AgButton, AgIconS} from '@/components/ag'
import {defineComponent, reactive, onBeforeMount, computed, watch, ref, PropType} from 'vue'
import {
  IonPage,
  modalController,
  IonContent,
  popoverController, IonRippleEffect,
} from '@ionic/vue'
import {useShare, toMutRef} from '@/composables'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import OpenClubDeals from './components/OpenClubDeals.vue'
import DetailModal from '@/components/Modal/DetailModal.vue'
import BoardWriteModal from '@/components/Modal/BoardWriteModal.vue'
import {set} from '@/composables/useDealStorage'
import CollectClubDeals from '@/components/Modal/Home/CollectClubDeals.vue'
import GroupBuy from '@/components/Company/GroupBuy.vue'
import {searchOutline, arrowDownOutline} from 'ionicons/icons'
import * as leaf from '../../../../public/assets/icon/leaf.png'
import * as vector from '../../../../public/assets/icon/Vector.png'
import * as help from '../../../../public/assets/icon/help-circle.png'
import * as thun from '../../../../public/assets/icon/zap.png'
import SellItemModal from '@/components/Modal/SellItemModal.vue'
import SellItemViewModal from '@/components/Modal/SellItemViewModal.vue'
import StarRatingInline from '@/components/Board/StarRatingInline.vue'
import {Plugins} from '@capacitor/core'
import BoardList from '@/components/Board/BoardList.vue'
import CompanyInfoValue from '@/components/Popover/CompanyInfoValue.vue'
import useModal from '@/composables/useModal'
import StakeInfoModal from '@/views/view/stake-deals/StakeInfoModal.vue'
import RegistSellStakeModal from '@/views/view/regist-sell-stake/RegistSellStakeModal.vue'
import StakeDealList from '@/components/Company/stake-deal/StakeDealList.vue'
import BuyStakeModal from '@/views/view/buy-stake/BuyStakeModal.vue'
import ViewMyStake from '@/views/view/view-my-stake/ViewMyStake.vue'
import LinkAccountModal from '@/views/view/link-account/LinkAccountModal.vue'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import ViewOptionModal from '@/components/Modal/ViewOptionModal.vue'
import PriceInfo from './components/PriceInfo.vue'
import logoPng from './assets/logo.png'
import logoPng2 from './assets/logo@2x.png'
import logoPng3 from './assets/logo@3x.png'
import ClubDeals from './components/ClubDeals.vue'
import DoneClubDeals from './components/DoneClubDeals.vue'
import {
  currency, openLinkWithAccessToken,
  splitDate, numberToKorean, openLink, prettyDateToOnemonth,
} from '@/utils/filters'
import {provideTrackPayload} from '@/composables'

const {Browser} = Plugins
type Tabs = 'clubdeal' | 'info' | 'board' | 'share-sell'
export default defineComponent({
  components: {
    AgBoxS,
    AgButton,
    AgButtonS,
    AgIcon,
    AgIconS,
    AgRefresher,
    AgTextS,
    AgToggleIcon,
    BoardList,
    BuyStakeModal,
    ClubDeals,
    CollectClubDeals,
    DoneClubDeals,
    GroupBuy,
    IonContent,
    IonPage,
    IonRippleEffect,
    LinkAccountModal,
    OpenClubDeals,
    PriceInfo,
    RegistSellStakeModal,
    StakeDealList,
    StakeInfoModal, StarRatingInline, ViewMyStake,
  },
  name: 'CompanyModal',
  props: {
    move: null,
    stockItemId: null,
    tabName: {default: 'info', type: String as PropType<Tabs>},
    type: null,
  },
  setup(props) {
    provideTrackPayload({
      clickScreen: 'company',
    })
    const {getters, dispatch, commit} = useStore()
    const gtm = useGtm()
    const viewType = ref<any>('등록 최신순')
    const fromCommunity = computed(() => props.move)
    const reValue = reactive<{ mode: boolean; starIcon: string}>({
      mode: false,
      starIcon: '',
    })
    const share = useShare()

    const router = useRouter()

    const {
      useClubDealProgressModal, useClubDealOpenModal, openFAQModal,
    } = useModal()

    const user = computed(() => getters['user/getUser'])

    const isLogin = computed(() => Boolean(user.value))

    const createModal = async () => {
      const modal = await modalController
      .create({
        backdropDismiss: true,
        component: ViewOptionModal,
        componentProps: {
          requestType: 'stake-deal-company',
          viewOption: 'none',
          viewType: viewType.value,
        },
        cssClass: 'view-option-modal',
        id: 'ViewOptionModal',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })

      modal.onDidDismiss().then(async (data) => {
        if (data.data?.viewType) {
          viewType.value = await data.data.viewType
        }
      })

      return modal.present()
    }


    const sortedStake = computed(() => {
      const array = [...stakeList.value]
      if (viewType.value === '등록 최신순') {
        return array.sort((a: any, b: any) => {
          const aDate: any = new Date(a.createAt)
          const bDate: any = new Date(b.createAt)
          return bDate - aDate
        })
      } else if (viewType.value === '주당 가격순') {
        return array.sort((a: any, b: any) => {
          if (b.price > a.price) {
            return -1
          } else if (a.price > b.price) {
            return 1
          }
          return 0
        })
      }else if (viewType.value === '판매 금액순') {
        return array.sort((a: any, b: any) => {
          if (Number(b.totalPrice) > Number(a.totalPrice)) {
            return -1
          } else if (Number(a.totalPrice) > Number(b.totalPrice)) {
            return 1
          }
          return 0
        })
      }
      return array

    })



    watch(user, () => {
      if (user.value) {
        dispatch('stakeDeal/GET_STAKELIST', props.stockItemId)
        dispatch('stakeDeal/GET_MY_STAKELIST', props.stockItemId)
      }
    })

    const loadCompany = async (id: string) => {
      await dispatch('stockItem/ACT_GET_STOCK_ITEM', {stockItemId: id})
      await dispatch('stockItem/ACT_GET_COLLECTED_PARTNERSHIP', {page: 1, stockItemId: id})
      await dispatch('stakeDeal/GET_STAKELIST', id)
      if (user.value) {
        await dispatch('stakeDeal/GET_MY_STAKELIST', id)
      }
    }

    const filteredStakeList = ref([])

    const filterFlag: any = reactive({
      _1tradeAble: true,
      _2withinAMonth: true,
      _3myItem: false,
    })


    function checkTradable(condition: boolean, item: any){
      return !condition || Boolean(item.status === 'onSale')
    }

    function checkWithinAMonth(condition: boolean, item: any) {
      const currentDate = item.createAt
      const date: any = new Date((currentDate || '').replace(/-/g, '/').replace(/[TZ]/g, ' ').split('.')[0])
      const diff = ((Date.now() - date) / 1000)
      const dayDiff = Math.floor(diff / 86400)
      return !condition || Boolean(dayDiff < 30)
    }

    function checkMine(condition: boolean, item: any){
      return !condition || Boolean(item.mine)
    }

    function filterizeByCondition(conditions: any, item: any){
      return checkTradable(conditions[0], item) && checkWithinAMonth(conditions[1], item) && checkMine(conditions[2], item)
    }

    const serializeFlag = () => {
      return Object.values(filterFlag).map((f:any)=>Number(f))
    }

    function doFilter(){
      const flags = serializeFlag()
      const deepCopiedList = JSON.parse(JSON.stringify(stakeListOrigin.value))

      filteredStakeList.value = deepCopiedList.filter((stake: any)=>{
        return filterizeByCondition(flags, stake)
      })
    }

    // const paramsId = ref<any>(route.params.stockItemId);
    const paramsId = computed(() => props.stockItemId)

    const selectedStockItem = computed(() => getters['stockItem/getSelectedStockItem']) // 현재 슬라이드가 선택된 회사

    const stakeListOrigin = computed(() => getters['stakeDeal/stakeList'])

    const perLimit = 10
    const showLimit = ref<number>(perLimit)
    const stakeList = computed(() => filteredStakeList.value.slice(0, showLimit.value))

    watch(filterFlag, ()=>{
      doFilter()
    })
    watch(stakeListOrigin, ()=>{
      doFilter()
    })


    const stakeHasMore = computed(()=>stakeList.value.length<filteredStakeList.value.length)
    const stakeCount = computed(()=>filteredStakeList.value.length)
    const showMoreStake = () => {
      showLimit.value += perLimit
    }
    const stockItemPartnershipHistories = computed(() => getters['stockItem/getStockItemPartnershipHistories'])

    // 회사 정보
    const stockItemInfo = computed(() => selectedStockItem.value.stockItemInfo || {})
    const incomingProfit = computed(() => {
      return Number(stockItemInfo.value.incomingProfit ?? 0)
    })
    const marketPrice = computed(() => {
      const {marketPrice} = stockItemInfo.value
      if (typeof marketPrice === 'undefined') {
        return false
      }
      return currency(Number(marketPrice).toFixed(0))
    })
    const profitKind = computed(() => {
      if (incomingProfit.value > 0) {
        return 'plus'
      }
      if (incomingProfit.value < 0) {
        return 'minus'
      }
      return 'zero'
    })
    const profitKindColor = computed(() => {
      switch (profitKind.value) {
        case 'plus':
          return 'red1'
        case 'minus':
          return 'sky1'
        default:
          return 'gray3'
      }
    })
    const currentValuation = computed(() => {
      return numberToKorean(stockItemInfo.value.currentValuation)
    })
    const incomingProfitRate = computed(() => Number(stockItemInfo.value.incomingProfitRate ?? 0).toFixed(2))

    // 회사의 진행중 클럽딜
    const collectingPartnerships = computed(() => selectedStockItem.value.collectingPartnerships || [])
    // 회사의 진행예정 클럽딜
    const comingSoonPartnerships = computed(() => selectedStockItem.value.comingSoonPartnerships || [])

    const collectingAndComingSoon = computed(() => [...collectingPartnerships.value, ...comingSoonPartnerships.value])

    // 회사의 클럽딜 열기
    const preparePartnerships = computed(() => selectedStockItem.value.preparePartnerships || [])
    // 다른회사 클럽딜
    const anotherCompanyPartnerships = computed(() => selectedStockItem.value.anotherCompanyPartnerships || [])
    // 등록된 주식 매물
    const diplayStockItemSell = computed(() => selectedStockItem.value.displayStockItemSells || [])

    console.log(diplayStockItemSell.value, '--------')

    const stockItemBoards = computed(() => getters['stockItem/getStockItemBoards'])

    const swiper = ref<any>(null)

    const hasSession = () => {
      return getters['user/hasSession']
    }


    async function initPage() {
      await loadCompany(props.stockItemId.toString())
      await set('deal_storage', props.stockItemId)
      await commit('stockItem/SET_INIT_RECENT_VIEW_STOCK_ITEM')
    }

    async function initPageMeta() {
      await dispatch('stockItem/ACT_GET_POPULARITY')
      // await dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS', {stockItemId: props.stockItemId.toString(), page: 1, type: 'initAndSet'})
    }

    const activeTab = toMutRef(props, 'tabName')
    const changeTab = (value: Tabs) => {
      activeTab.value = value
    }

    watch(stockItemInfo, (current, previous) => {
      if(current.stockItemId === previous.stockItemId) {
        return
      }
      const stockItemName = stockItemInfo.value?.stockItemName
      const stockItemId = stockItemInfo.value?.stockItemId
      gtm?.trackEvent({
        event: MODAL_OPEN_EVENT,
        page: `company/${stockItemId}`,
        title: `${stockItemName} 회사 페이지`,
      })
    })

    onBeforeMount(async () => {
      if (props.stockItemId) {
        await initPage()
        await initPageMeta()
      }
      if (props.type === 'share-sell') {
        changeTab('share-sell')
      }else if (props.type === 'community') {
        changeTab('board')
      }else if (props.type === 'clubdeal') {
        changeTab('clubdeal')
      }

    })

    const doRefresh = async (event: any) => {
      // console.log('Begin async operation');
      if (props.stockItemId) {
        await initPage()
      }
      event.target.complete()
    }

    const slideOptions = {
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: -30,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: -38,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: -48,
        },
      },
      centeredSlides: true,
      initialSlide: 0,
      observeParents: true,
      observer: true,
      speed: 400,
    }


    // get icons
    const searchIcon = searchOutline
    const talkIcon = vector
    const budIcon = leaf
    const helpCircle = help
    const thunder = thun

    const itemLike = (stockItemId: any) => {
      dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
    }

    const partnershipLike = async (partnershipStatus: string, target: string, partnershipId: string) => {
      console.log(partnershipStatus, target, partnershipId)
      const result = await dispatch('partnership/ACT_LIKE_PARTNERSHIP_TARGET', {partnershipId, partnershipStatus, target})
      const likeInfo = result.likeInfo
      const filtered = partnershipStatus === 'coming_soon'
          ? comingSoonPartnerships.value.filter((b: any) => b.partnershipId === likeInfo.partnershipId)
          : preparePartnerships.value.filter((b: any) => b.partnershipId === likeInfo.partnershipId)

      if (filtered.length > 0) {
        filtered[0].partnershipLikeId = likeInfo.partnershipLikeId
        filtered[0].likeCount = likeInfo.likeCount
      }
    }

    const openModal = async (stockItemBoard: any = null) => {
      const modal = await modalController
      .create({
        backdropDismiss: true,
        component: BoardWriteModal,
        componentProps: {
          stockItemBoard,
          stockItemId: paramsId.value,
        },
        cssClass: 'board-write-modal',
        id: 'BoardWriteModal',
        keyboardClose: true,
        mode: 'ios',
        swipeToClose: true,
      })
      return modal.present()
    }

    /* functions */
    const clickDeal = async (deal: any) => {
      // 1. getDeal 실행! => vuex에 dealInfo 저장

      const getDeal = () => dispatch('dealInfo/LOADDEAL', {dealInfo: deal})
      await getDeal()
      // 2. modal 페이지 생성!
      const modal = await modalController
      .create({
        backdropDismiss: true,
        component: DetailModal,
        componentProps: {
          stockId: deal.stockItemId,
        },
        cssClass: 'my-custom-class',
        // ios 모드에서만 지원 가능? -> 이따 빌드해서 확인해보기
        id: 'detailModal',

        // backdropDismiss: true,
        keyboardClose: true,

        mode: 'ios',

        swipeToClose: true,
      })

      // console.log(getters.dealInfo);
      return modal.present()
    }

    const openSellModal = async () => {
      const modal = await modalController
      .create({
        backdropDismiss: true,
        component: SellItemModal,
        componentProps: {
          stockItemId: paramsId.value,
        },
        cssClass: 'sell-item-modal',
        id: 'SellItemModal',

        // mode: 'ios',
        keyboardClose: true,

        swipeToClose: true,
      })

      return modal.present()
    }

    async function showMySellItem(sellInfo: any) {
      if (!sellInfo.isMine) {
        return
      }
      const modal = await modalController
      .create({
        backdropDismiss: true,
        component: SellItemViewModal,
        // ios 모드에서만 지원 가능? -> 이따 빌드해서 확인해보기
        componentProps: {
          sellInfoRef: sellInfo,
          updateCompleteRef: false,
        },

        cssClass: 'sell-item-modal complete',

        // backdropDismiss: true,
        keyboardClose: true,

        mode: 'ios',

        swipeToClose: true,
      })
      return modal.present()
    }

    let popover: any = null
    const openPopover = async (event_: Event) => {
      if (popover) {
        return
      }
      popover = await popoverController
      .create({
        component: CompanyInfoValue,
        componentProps: {},
        cssClass: 'company-info-value',
        event: event_,
        translucent: false,
      })

      popover.onDidDismiss().then((result: any) => {
        popover = null
      })
      return popover.present()
    }

    async function closeModal() {
      await modalController.dismiss(null, '', 'companyModal')
    }

    // 조합 지분거래 관련 코드들

    const detailInfoOpen = ref<boolean>(false)
    const buyStakeModal = ref<boolean>(false)
    const viewMyStakeOpen = ref<boolean>(false)
    const linkAccountModal = ref<any>(false)
    const stakeSellOpen = ref<boolean>(false)
    const SellOpenType = ref<string>('')
    const viewOpenType = ref<string>('')
    const afterLinked = ref<string>('')
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const myStakeList = computed(() => getters['stakeDeal/myStakeList'])

    const printDetailModal = async (deal: any) => {
      if (deal.mine) {
        deal.type = await 'view'
        await commit('stakeDeal/SET_REGISTER_RESULT', deal)
        viewOpenType.value = 'view'
        viewMyStakeOpen.value = await true
      } else if (!deal.mine) {
        await dispatch('stakeDeal/SELECT_DEAL', deal)
        detailInfoOpen.value = await true
      }
    }

    const refreshStakeList = () => {
      if (viewOpenType.value === 'result') {
        dispatch('stakeDeal/GET_MY_STAKELIST', stockItemInfo.value.stockItemId)
      }

      viewMyStakeOpen.value = false
    }

    const checkIsLinked = async () => {
      afterLinked.value = 'sell'
      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
        linkAccountModal.value = true
      } else if (currentStep.value === 'COMPLETE') {
        createPartnershipStake()
      }
    }

    const createPartnershipStake = async () => {
      SellOpenType.value = await 'selected'

      await dispatch('stakeDeal/GET_MY_STAKELIST', stockItemInfo.value.stockItemId)
      await commit('stakeDeal/SET_SELECT_COMPANY', stockItemInfo.value)

      stakeSellOpen.value = await true
      viewMyStakeOpen.value = await false
    }

    const editMyStake = async () => {
      SellOpenType.value = await 'edit'
      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
        linkAccountModal.value = true
      } else if (currentStep.value === 'COMPLETE') {
        stakeSellOpen.value = await true
      }

      viewMyStakeOpen.value = await false
    }

    const branchResult = async () => {
      await dispatch('stakeDeal/GET_STAKELIST', props.stockItemId)
      viewOpenType.value = await 'result'

      stakeSellOpen.value = await false
      viewMyStakeOpen.value = await true
    }

    const reloadData = async () => {
      dispatch('stakeDeal/GET_STAKELIST', props.stockItemId)
      viewMyStakeOpen.value = false
    }

    const buyStakeResult = async () => {
      dispatch('stakeDeal/GET_STAKELIST', props.stockItemId)
      buyStakeModal.value = false
    }

    const moveToMyPage = async () => {
      modalController.dismiss(null, undefined, 'companyModal')
      buyStakeModal.value = false
      detailInfoOpen.value = false
    }

    const buyNextProcess = (modal: any) => {
      afterLinked.value = 'buy'
      if (modal === 'linkAccount') {
        linkAccountModal.value = true
      } else if (modal === 'stakeBuy') {
        buyStakeModal.value = true
      }
    }

    const onShare = () => {
      const url = `https://angelleague.io/link/company/${stockItemInfo.value.stockItemId}`
      // bichi share company
      share({
        text: `${stockItemInfo.value.stockItemName} 투자에 관심있다면 최신 소식과 정보를 확인하고, 구매 가능 매물을 살펴 보세요`,
        title: `${stockItemInfo.value.stockItemName} 정보 확인`,
        url,
      })
    }

    const goUrl = (url: string) => {
      return Browser.open({url})
    }

    const isSellAvailable = computed(() => {
      if (!user.value) {
        return 'no-user'
      }
      if (myStakeList.value && myStakeList.value.length > 0) {
        return 'available'
      }
      return 'not-available'
    })

    const sellAvailableMessage = computed(() => {
      switch (isSellAvailable.value) {
        case 'available':
          return '판매 가능한 조합원 자격이 있어요!'
        case 'no-user':
          return '로그인 후 조합원 자격 보유여부를 알 수 있어요'
        default:
          return '판매 가능한 조합원 자격이 없습니다.'
      }
    })
    const clickScreen = 'company'
    const clubDealAllOpen = ref<boolean>(false)

    return {
      SellOpenType,
      activeTab,
      afterLinked,
      anotherCompanyPartnerships,
      arrowDownOutline,
      branchResult,
      budIcon,
      buyNextProcess,
      buyStakeModal,
      buyStakeResult,
      changeTab,
      checkIsLinked,
      clickDeal,
      clickScreen,
      closeModal,
      clubDealAllOpen,
      collectingAndComingSoon,
      collectingPartnerships,
      comingSoonPartnerships,
      createModal,
      createPartnershipStake,
      currency,
      currentValuation,
      detailInfoOpen,
      diplayStockItemSell,
      doRefresh,
      editMyStake,
      filterFlag,
      goUrl,
      hasSession,
      helpCircle,
      incomingProfit,
      incomingProfitRate,
      isLogin,
      isSellAvailable,
      itemLike,
      linkAccountModal,
      logoPng,
      logoPng2,
      logoPng3,
      marketPrice,
      moveToMyPage,
      myStakeList,
      numberToKorean,
      onShare,
      openFAQModal,
      openLink,
      openModal,
      openPopover,
      openSellModal,
      paramsId,
      partnershipLike,
      preparePartnerships,
      prettyDateToOnemonth,
      printDetailModal,
      profitKind,
      profitKindColor,
      reValue,
      refreshStakeList,
      reloadData,
      router,
      searchIcon,
      selectedStockItem,
      sellAvailableMessage,
      showMoreStake,
      showMySellItem,
      slideOptions,
      sortedStake,
      stakeCount,
      stakeHasMore,
      stakeList,
      stakeListOrigin,
      stakeSellOpen,
      stockItemBoards,
      stockItemInfo,
      stockItemPartnershipHistories,
      swiper,
      talkIcon,
      thunder,
      useClubDealOpenModal,
      useClubDealProgressModal,
      user,
      viewMyStakeOpen,
      viewOpenType,
      viewType,
    }
  },
})

