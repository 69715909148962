<template>
  <ion-content
    :translucent="true"
  >
    <div class="tooltip-inner">
      <div class="tooltip-arrow" />
      <div class="tooltip_in">
        * 스톡옵션 행사로 취득한 주식이 근로소득으로 간주되면서 추가로 납부하게 되는 근로소득세
        <br><br>** 매매사례가액이 없는 경우(대부분의 회사는 매매사례가액이 없습니다) 주식의 시가를 액면가로 가정함
        <br><br>*** 본 계산기는 근로 중 스톡옵션 행사 기준으로, 퇴직 후 스톡옵션을 행사하면 기타소득으로 분류됨
        <br><br>**** 조세특례제한법에서 벤처기업을 육성하기 위하여 벤처기업에 재직하는 임원 또는 사용인이 적격 주식매수선택권을 행사함으로서 얻은 이익에 대해서 근로소득이나 기타소득이 아닌 양도소득세로 과세하도록 하는 특례규정이 있으나, 적용 요건이 엄격하여 사전에 특례제도의 적용이 가능한지 검토가 필요함
      </div>
    </div>
  </ion-content>
</template>

<script>
import {IonContent} from '@ionic/vue'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {IonContent},
  name: 'StockCalcHelp',
})
</script>

<style scoped>
ion-content {
--background: #000;
}
.tooltip-inner {
padding: 3px 8px;
color: #fff;
text-align: left;
background-color: #000;
border-radius: 4px;
font-size: 12px;
}

.tooltop_in {
text-align: left;
margin: 12px 5px;
color: #fff;
}

.tooltip {
position: absolute;
z-index: 1070;
top: 1000px;
left: 135px;
font: var(--main_12R);
}

.tooltip.right {
padding: 0 5px;
margin-left: 3px;
}

.tooltip.in {
opacity: .9;
}

.tooltip-arrow {
width: 0;
height: 0;
border-color: transparent;
border-style: solid;
position: absolute;
}

.tooltip.right .tooltip-arrow {
left: 0;
margin-top: -5px;
border-width: 5px 5px 5px 0;
border-right-color: #000;
}
</style>
