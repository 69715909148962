import {AppVersion} from '@ionic-native/app-version'
import {AgAlert} from '@/components/ag'
import {defineComponent, onMounted, h, computed, ref} from 'vue'
import {getPlatform} from '@/composables/platform'
import Axios from 'axios'
import {Plugins} from '@capacitor/core'
import versionCompare, {VersionIs} from 'version-compare'

const {Browser} = Plugins

// https://play.google.com/store/apps/details?id=io.angelleague.angelleague&hl=kr

// https://play.google.com/store/apps/details?id=io.angelleague.angelleague

export const getMinVersion = async (platform: 'web' | 'ios' | 'android'): Promise<string | undefined> => {
  try {
    const baseURl = process.env.VUE_APP_APP_URL ?? 'https://angelleague.io'

    const response = await Axios(process.env.VUE_APP_APP_INFO_URL ?? `${baseURl}/assets/app-info.json`)

    const minVersion = response.data?.minLiveVersion ?? {}

    return minVersion[platform]
  } catch {
    return undefined
  }
}

export const getCurrentVersion = async (platform: 'web' | 'ios' | 'android'): Promise<string> => {
  if (platform === 'web') {
    return '200.0.0'
  }
  return AppVersion.getVersionNumber().then((version) => {
    return version
  }).catch(() => {
    return '0.0.0'
  })
}

export const shouldNativeUpdate = (currentVersion?: string, minVersion?: string): boolean => {
  if (!currentVersion || !minVersion) {
    return false
  }
  
  return versionCompare(currentVersion, minVersion) === VersionIs.LessThan
}

export const NativeUpdate = defineComponent({
  setup() {
    const value = ref(false)

    onMounted(async () => {
      const platform = getPlatform()
      const minVersion = await getMinVersion(platform)
      const currentVersion = await getCurrentVersion(platform)
      const result = shouldNativeUpdate(currentVersion, minVersion)
      if (result) {
        value.value = true
      }
    })
    
    const onAppUpdate = () => {
      const platform = getPlatform()
      const url = platform === 'ios' ?
        'https://itunes.apple.com/app/id1558999691' :
        'https://play.google.com/store/apps/details?id=io.angelleague.angelleague'
      Browser.open({
        url,
      })
    }
    
    const yesButton = computed(() => {
      return {
        handler: () => {
          onAppUpdate()
          return false
        },
        text: '업데이트 하기',
      }
    })
    
    return () => {
      return h(AgAlert as any, {
        backdropDismiss: false,
        buttons: [yesButton.value],
        css: {wordBreak: 'keep-all'},
        message: '사용자 의견이 반영된\n 더 좋아진 엔젤리그,\n 지금 바로 즐겨보세요!',
        modelValue: value.value,
        title: '새로운 버전이\n 업데이트 되었어요!',
      })
    }
  },
})
