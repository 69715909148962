
import {defineComponent, computed, onBeforeMount, onUpdated, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {toFixed, prettyDateToOnemonth, printContent, openLink} from '@/utils/filters'
import StarRatingInline from '@/components/Board/StarRatingInline.vue'
import PrintOgData from '@/components/Board/PrintOgData.vue'
import {IonRippleEffect, IonInfiniteScroll, IonInfiniteScrollContent} from '@ionic/vue'
import useModal from '@/composables/useModal'
export default defineComponent({
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRippleEffect,
    PrintOgData,
    StarRatingInline,
  },
  name: 'BoardListAll',
  props: {
    stockItemBoardsProp: null,
  },
  setup(props) {
    const {getters, dispatch, commit} = useStore()
    const router = useRouter()
    const {useCompanyModal} = useModal()
    const page = ref(0)

    const loadBoard = async () => {
      const result = await dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS_ALL', {page: ++page.value})
    }

    const loadData = async (event_: any) => {
      setTimeout(async () => {
        await loadBoard()
        event_.target.complete()
      }, 300)
    }

    const user = computed(() => getters['user/getUser'])

    // const stockItemBoards = computed(()=>getters['stockItem/getStockItemBoardsAll']);
    const stockItemBoards = computed(() => props.stockItemBoardsProp)
    const stockItemBoardsCount = computed(() => getters['stockItem/getStockItemBoardsAllCount'])

    const hasMore = computed(() => stockItemBoardsCount.value > stockItemBoards.value.length)
    onBeforeMount(async () => {
      await loadBoard()
    })

    function boardLike(board: any) {
      dispatch('stockItem/ACT_LIKE_STOCK_ITEM_BOARD', {
        stockItemBoardId: board.stockItemBoardId, stockItemId: board.stockItemId, target: 'stockItemBoardsAll',
      })
    }

    function boardClick(data: any, event: any) {
      if (event.target.tagName === 'A') {
        openLink(event.target.dataset.href)
      }
    }

    const clickScreen = 'discussion'

    return {
      boardClick,
      boardLike,
      clickScreen,
      hasMore,
      loadData,
      openLink,
      prettyDateToOnemonth,
      printContent,
      router,
      stockItemBoards,
      stockItemBoardsCount,
      toFixed,
      useCompanyModal,
      user,
    }
  },
})
