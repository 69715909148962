import {Module} from 'vuex'
import {RootState} from '..'
import apiWallet from '@/api/wallet'
const {
  getWalletInfo,
} = apiWallet()
// state
interface Wallet {
  transactionHistory: any[]
  walletInfo: Record<any, any>
}

// action
const Wallet: Module<Wallet, RootState> = {
  actions: {
    async ACT_GET_WALLET_INFO({commit}) {
      const data: any = await getWalletInfo()
      console.log(data)
      commit('SET_WALLET_INFO', data)
      commit('SET_TRANSACTION_HISTORY', data.transactionHistory)
    },
  },
  getters: {
    getTransactionHistory: state => {
      return state.transactionHistory
    },
    getWalletInfo: state => state.walletInfo,
  },
  // RootState와 똑같이 작성해도 된다.
  mutations: {
    SET_TRANSACTION_HISTORY(state, data: any) {
      state.transactionHistory = data
    },
    SET_WALLET_INFO(state, data: any) {
      state.walletInfo = {
        currentBalance: data.currentBalance,
        depositRequestAmount: data.depositRequestAmount,
        usableBalance: data.usableBalance,
        useRequestAmount: data.useRequestAmount,
        withdrawRequestAmount: data.withdrawRequestAmount,
      }
    },
  },
  namespaced: true,
  state: {
    transactionHistory: [],
    walletInfo: {},
  },
}

export default Wallet
