import {defineComponent, PropType, h, computed, toRef} from 'vue'
import thumbsUpFill from './assets/thumbs-up-fill.svg'
import thumbsUpOutline from './assets/thumbs-up-outline.svg'
import checkCircleFill from './assets/check-circle-fill.svg'
import checkCircleOutline from './assets/check-circle-outline.svg'
import radioOutline from './assets/radio-outline.svg'
import radioFill from './assets/radio-fill.svg'
import heartOutline from './assets/heart-outline.svg'
import heartFill from './assets/heart-fill.svg'
import starOutline from './assets/star-outline.svg'
import starFill from './assets/star-fill.svg'
import talkOutline from './assets/talk-outline.svg'
import talkFill from './assets/talk-fill.svg'
import {styled, colorSystem} from '@/plugins/emotion'
import {variant} from '@/plugins/emotion'

export type IconsType = 'thumbsUp' | string | 'checkCircle' | 'radio' | 'star' | 'heart' | 'talk'

export type Icons = Record<IconsType, [any, any]>

const icons: Icons = {
  check: [checkCircleOutline, checkCircleFill],
  heart: [heartOutline, heartFill],
  radio: [radioOutline, radioFill],
  star: [starOutline, starFill],
  talk: [talkOutline, talkFill],
  thumbsUp: [thumbsUpOutline, thumbsUpFill],
}

export type InnerIconSizeType = 'md' | 'sm'

export const agToggleProps = {
  block: {default: 'md', type: String as PropType<InnerIconSizeType>},
  color: {default: 'primary', type: String},
  name: {default: 'radio', type: String as PropType<IconsType>},
  value: {default: false, type: Boolean},
}

const sizeVariant = variant({
  prop: 'block',
  variants: {
    full: {
      height: '100%',
      width: '100%',
    },
    md: {
      height: '24px',
      width: '24px',
    },
    sm: {
      height: '14px',
      width: '14px',
    },
  },
})

const Container = styled('div', {
  props: {
    block: agToggleProps.block,
    color: agToggleProps.color,
  },
})(
  colorSystem,
  sizeVariant,
)

export const AgToggleIcon = defineComponent({
  name: 'AgToggleIcon',
  props: {
    ...agToggleProps,
  },
  setup(props) {
    const nameRef = toRef(props, 'name')
    const valueRef = toRef(props, 'value')
    const indexRef = computed(() => valueRef.value ? 1 : 0)
    const srcRef = computed(() => {
      const value = nameRef.value
      return icons[value][indexRef.value]
    })
    return () => {
      return h(Container, {block: props.block, color: props.color}, () => [
        h(srcRef.value),
      ])
    }
  },
})
