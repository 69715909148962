import CallApi from '../utils/CallApi'
import axios from 'axios'

export default function() {
  return {
    
    
    
    
    
    
    
    
    
    
    
    
    

    markAsRead: async (channelUrl: any, userId: string) => {
      const appId = 'E541C962-CE85-41B1-9651-69537658312D'

      // // console.log('읽음');

      let result: any = []

      try {
        result = await axios({
          data: {
            user_id: `${userId}`,
          },
          headers: {
            'Api-Token': 'c724e270936d3cdf35eb44f780c741ba09317f3e',
            'Content-Type': 'application/json; charset=utf8',
          },
          method: 'put',
          url: `https://api-${appId}.sendbird.com/v3/group_channels/${channelUrl}/messages/mark_as_read`,
        })
        if (result) {
          // // console.log(result);
        }
      } catch {
        // console.log(error);
      }

      return result
    },

    // getChannelList : async () => {
//   let result: any = [];
//   try{
//     result = await CallApi({
//       url:`partnerships/{partnershipId}/open-channel`,
//       method:'get',
//     });
//
//   }catch(error){
//     // console.log(error);
//   }
//   return result;
// },
postOpenChannel: async (partnershipId: number) => {
      let result: any = []
      result = await CallApi({
        method: 'post',
        url: `partnerships/${partnershipId}/open-channel`,
      })
      return result
    },

    sendNewMessage: async (partnershipId: number, partnershipOpenChannelId: number, status: string, price?: number) => {
      let result: any = []
      try {
        result = await CallApi({
          data: {
            price: price,
            status: status,
          },
          method: 'put',
          url: `partnerships/${partnershipId}/open-channel/${partnershipOpenChannelId}`,
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    updateChannel: async (channelUrl: string, channelData: string) => {
      const appId = 'E541C962-CE85-41B1-9651-69537658312D'
      let result: any = []

      try {
        result = await axios({
          data: {
            data: `${channelData}`,
          },
          headers: {
            'Api-Token': '398ca6e96d7354c8c365d7c411dd800ef87567fa',
            'Content-Type': 'application/json; charset=utf8',
          },
          method: 'put',
          url: `https://api-${appId}.sendbird.com/v3/group_channels/${channelUrl}`,
        })
      } catch {
        // console.log(error);
      }

      return result
    },
  }
}
