
import {IonButton, IonContent, IonTitle, IonHeader, IonToolbar} from '@ionic/vue'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
  },
  emits: ['close'],
  name: 'StockDetail',
  props: {
    stockId: String,
  },
  setup(props, {emit}) {
    const onClose = () => {
      emit('close')
    }

    return {
      onClose,
    }
  },
})
