import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_stock_item = _resolveComponent("search-stock-item")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, {
    class: "full-content",
    onTouchstart: _ctx.onBlur
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createBlock(_component_search_stock_item, {
          key: item.stockItemId,
          id: item.stockItemId,
          like: item.likeFlag,
          logo: item.companyLogo,
          name: item.stockItemName,
          button: "",
          onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', $event)))
        }, null, 8, ["id", "like", "logo", "name"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["onTouchstart"]))
}