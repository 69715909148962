import {wrapRef, MayRef} from '@winter-love/use'
import {isSSR} from '@/utils'
import {computed} from 'vue'

export const useComputedStyle = <T extends Element>(element: MayRef<T>) => {
  const elementRef = wrapRef(element)

  return computed(() => {
    if (isSSR()) {
      return {}
    }
    return getComputedStyle(elementRef.value)
  })
}
