
import {AgBox, AgButton, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {defineComponent, computed, onBeforeMount} from 'vue'
import PersonalInfo from './PersonalInfo.vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    ModalHeader,
    PersonalInfo,
  },
  emits: ['close', 'nextStep'],
  setup(props, {emit}) {
    const {getters, commit, dispatch} = useStore()
    const setOpen = (value: boolean) => emit('close', value)
    const previousProcess = () => emit('nextStep')
    const nextProcess = async () => {
      await emit('nextStep')
    }

    onBeforeMount(async () => {
      await dispatch('user/ACT_SET_SETTING_INFO')
    })

    const settingInfo = computed(() => getters['user/getSettingInfo'] || {})
    const generateString = (first: any, last: any) => {
      const stringArray = []
      if (first) {
stringArray.push(first)
}
      if (last) {
stringArray.push(last)
}
      return stringArray.length > 0 ? stringArray.join(' ') : null
    }
    const address = computed(() => generateString(settingInfo.value.address1, settingInfo.value.address2))

    const stockBank = computed(() => settingInfo.value.stockBankName || '')
    const stockBankAccount = computed(() => settingInfo.value.stockBankAccount || '')
    const bank = computed(() => settingInfo.value.bankName || '')
    const bankAccount = computed(() => settingInfo.value.bankAccount || '')

    const bankInfo = computed(() => generateString(bank.value.bankName, bankAccount.value))
    const stockBankInfo = computed(() => generateString(stockBank.value.stockBankName, stockBankAccount.value))

    const infoValueList = computed(() => [address.value, bankInfo.value, stockBankInfo.value])
    const nextButton = computed(() => {
      return !(address.value && stockBank.value && stockBankAccount.value && bank.value && bankAccount.value)
    })

    const infoList = ['주소', '은행계좌', '증권계좌']

    return {infoList, infoValueList, nextButton, nextProcess, previousProcess, setOpen, settingInfo}
  },
})
