import {Plugins, PushNotification, PushNotificationActionPerformed, PushNotificationToken} from '@capacitor/core'
import {ref, onBeforeUnmount} from 'vue'

const {PushNotifications} = Plugins

export type ActionPerformedListener = (notification: PushNotificationActionPerformed) => void
export type ReceivedListener = (notification: PushNotification) => void

export const notificationPermissionRef = ref<'none' | 'allow' | 'disallow' | 'waiting'>('none')
export const notificationToken = ref<undefined | string>()
export const notificationError = ref<any>()

let _init = false

export const requestPermission = () => {
  if (_init) {
    return
  }
  _init = true

  if (notificationPermissionRef.value !== 'none') {
    return
  }

  PushNotifications.addListener('registration', (token: PushNotificationToken) => {
    notificationToken.value = token.value
  })

  PushNotifications.addListener('registrationError', (error) => {
    notificationError.value = JSON.stringify(error)
  })

  notificationPermissionRef.value = 'waiting'
  PushNotifications.requestPermission().then((result) => {
    if (result.granted) {
      notificationPermissionRef.value = 'allow'
      return PushNotifications.register()
    }
      notificationPermissionRef.value = 'disallow'

  })
}

export const onNotificationReceived = (received?: ReceivedListener, executePermissions: boolean = false) => {

  if (executePermissions) {
    requestPermission()
  }
  const onReceived = (notification: PushNotification) => received?.(notification)
  const handle = PushNotifications.addListener('pushNotificationReceived', onReceived)

  onBeforeUnmount(() => {
   handle.remove()
  })
}

export const onNotificationActionPerformed = (actionPerformed: ActionPerformedListener, executePermissions: boolean = false) => {

  if (executePermissions) {
    requestPermission()
  }
  const onActionPerformed = (notification: PushNotificationActionPerformed) => actionPerformed?.(notification)
  PushNotifications.addListener('pushNotificationActionPerformed', onActionPerformed)

  onBeforeUnmount(() => {
    PushNotifications.removeAllListeners()
  })
}
