import CallApi from '../utils/CallApi'

export default function() {
  return {
    getPreApplicationData: async () => {
      let result: any = []
      try {
        result = await CallApi({
          method: 'get',
          url: '/share-transaction/pre-application',
        })
      } catch {
        // console.log(error);
      }
      return result
    },

    postPreApplication: async (code: string) => {
      try {
        await CallApi({
          data: {inviteCode: `${code}`},
          method: 'post',
          url: '/share-transaction/pre-application',
        })
      } catch (error) {
        return error
      }

      return 'done'
    },
  }
}
