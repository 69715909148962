
import {computed, defineComponent, toRef} from 'vue'
import {AgBox, AgText, AgImg} from '@/components/ag'
import {IonLabel, IonRadio, popoverController} from '@ionic/vue'
import StakeHelp from '@/components/Popover/StakeHelp.vue'

export default defineComponent({
  components: {
    AgBox,
    AgImg,
    AgText,
  },
  name: 'CalcExpectedPrice',
  props: {
    finalSellInfo: {default: null, type: Object},
    parent: {type: String},
  },
  setup(props) {
    const finalSellInfoRef = toRef(props, 'finalSellInfo')
    const expectedPriceRef = computed(() => finalSellInfoRef.value.expectedPrice || '')
    const expectedTotalPriceRef = computed(() => finalSellInfoRef.value.expectedTotalPrice || '')
    const belowMinPrice = computed(() => expectedTotalPriceRef.value < 10000)
    const expectedQtyRef = computed(() => finalSellInfoRef.value.expectedQty || '')
    const expectedAssetRef = computed(() => finalSellInfoRef.value.expectedAsset || '')
    const parentRef = toRef(props, 'parent')
    const clickScreen = parentRef.value
    let popover: any = null
    const openPopover = async () => {
      if (popover) {
return
}
      popover = await popoverController
        .create({
          component: StakeHelp,
          componentProps: {
            onClick: () => {
              popover.dismiss()
            },
          },
          cssClass: 'stake-help',
          translucent: false,
        })

      popover.onDidDismiss().then((result: any) => {
        popover = null
      })
      return popover.present()
    }

    return {
      belowMinPrice,
      clickScreen,
      expectedAssetRef,
      expectedPriceRef,
      expectedQtyRef,
      expectedTotalPriceRef,
      finalSellInfoRef,
      openPopover,
    }
  },
})
