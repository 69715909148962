
import {useSocketIO} from '@/composables'
import {Plugins} from '@capacitor/core'
import {IonContent, IonFooter, IonPage} from '@ionic/vue'
import {defineComponent, onBeforeMount, reactive, ref} from 'vue'
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import LeftTopLogo from '../components/Common/LeftTopLogo.vue'
import {API_URL} from '../config'

const {Browser} = Plugins

export default defineComponent({
  components: {IonContent, IonFooter, IonPage, LeftTopLogo},
  name: 'Login',
  setup() {
    const {commit, getters, dispatch} = useStore()
    const router = useRouter()
    const route = useRoute()

    const agrees: any = reactive({agreeMarketing: false, agreeTerms: false, agreeThirdparty: false})
    const agreeAll = ref(false)
    const duplicateAccounts = ref<any>([])
    const chooseAccount = ref(null)
    const processingStatus = ref<string>('init')

    // const processingStatus = ref('agree_need');
    const authInfoMessage = ref<{msg: string; title: string}>({
      msg: '',
      title: '',
    })

    const credentials = ref<{email: string; password: string}>({
      email: 'es.ko@capbox.kr',
      password: '123',
    })
    // processingStatus.value = 'under_adult';
    // authInfoMessage.value = {
    //   title:"앗! 아직 성인이 아니십니다.",
    //   msg:"고위험 직접투자 서비스인 엔젤리그는\n미성년자가 사용하실 수 없습니다.\n성인이 된 후 꼭 만나요!"
    // }

    // processingStatus.value = 'duplicate_account';
    // authInfoMessage.value = {
    //   title:"사용하셨던 이메일이 있어요!",
    //   msg:"계속 사용 원하시는 이메일을 1개 선택해주세요"
    // }
    // duplicateAccounts.value = [
    //   {userId:170, email:'es.ko@capbox.kr'},
    //   {userId:239, email:'mt_grace@naver.com'},
    //   {userId:1234, email:'esko10@esko.com'},
    //   {userId:1235, email:'esko1@gmail.com'},
    // ];

    // processingStatus.value = 'agree_need';

    // processingStatus.value = 'regist_success';
    // authInfoMessage.value = {
    //   title:"가입 완료!",
    //   msg:"홍길동 엔젤님,\n환영합니다!"
    // }
    const toggleAgreeAll = () => {
      agreeAll.value = !agreeAll.value

      for (const key in agrees) {
        agrees[key] = agreeAll.value
      }
    }

    const checkProcessStatus = (status: string) => {
      return processingStatus.value === status
    }

    const openIABrowser = async (socketId: string) => {
      await Browser.open({url: `${API_URL}/auth/identity-certification/${socketId}`})
    }

    const setNextStep = async () => {
      const signupStep = await dispatch('user/ACT_GET_SIGNUP_STEP')
      processingStatus.value = signupStep.result
      authInfoMessage.value = signupStep.message

      // 본인인증 후 계정매칭 완료 후 UUID device저장
      if (processingStatus.value === 'regist_success') {
        await dispatch('user/ACT_SAVE_UUID_LOCALSTORAGE')
        await dispatch('user/ACT_SAVE_SIMPLE_PASSWORD_LOCALSTORAGE', signupStep.simple_password_flag)
      }
    }

    const {socket} = useSocketIO()
    socket.on('connect', function() {
      // console.log('SOCKET Connected');
    })

    socket.on('disconnect', function() {
      // console.log('SOCKET Disconnected');
    })

    onBeforeRouteLeave((to: any, from: any) => {
      socket.disconnect()
    })

    // socket init
    socket.on('msgToClient', async function(data: any) {
      // console.log('msgToClient event', data);
      processingStatus.value = 'identityCertifying'
      await openIABrowser(socket.id)
    })

    socket.on('under_adult', function(data: any) {
      processingStatus.value = data.result// under_adult
      authInfoMessage.value = data.message
      Browser.close()
    })

    // 본인인증 후 계정 생성/매칭 성공
    socket.on('auth_success', async function(data: any) {
      // uuid 최초 획득, state에 저장
      const uuid = data.authInfo.uuid
      await dispatch('user/ACT_SET_UUID', uuid)
      await setNextStep()
      await Browser.close()
    })

    // 본인 인증 후 중복 전화번호 계정 중 선택
    socket.on('duplicate_account', async function(data: any) {
      // console.log("::::::::::duplicate_account:::::::::",data);
      processingStatus.value = data.result// duplicate_account
      authInfoMessage.value = data.message
      duplicateAccounts.value = data.authInfo.duplicate_accounts
      const uuid = data.authInfo.uuid
      await dispatch('user/ACT_SET_UUID', uuid)
      await Browser.close()
    })

    const onClickSocket = () => {
      // console.log(socket);
      socket.emit('start-certification', {
        msg: 'hello world',
      })
    }

    const onClickAgree = async () => {
      const data = await dispatch('user/ACT_AGREE_TERMS', agrees)
      await setNextStep()
      // // console.log('onClickAgree', data);
      // processingStatus.value = data.result;
      // authInfoMessage.value = data.message;
    }

    const onClickConfirmEmail = async () => {
      const data = await dispatch('user/ACT_CHOOSE_ACCOUNT', chooseAccount.value)
      await setNextStep()
    }

    const loginResult = reactive<{message: string}>({message: ''})

    const doLogin = async () => {
      const account = await dispatch('user/ACT_LOGIN', credentials.value)
      if (!account.error) {
        router.replace({path: '/tabs/home'})
      } else {
        loginResult.message = account.error.message
      }
    }

    const seeYa = () => {
      // router.go(-1);
      // console.log(router);
      // console.log(route);
      router.replace({path: '/tabs/home'})
    }

    const doStart = async () => {
      // console.log('본인인증 고고');
      onClickSocket()
    }

    const simplePassword = async () => {
      router.replace({path: `/simple-login?${new Date().getMilliseconds()}`})
    }

    onBeforeMount(() => {
      // console.log('Component is onBeforeMount!');
    })

    return {
      agreeAll,
      agrees,
      authInfoMessage,
      checkProcessStatus,
      chooseAccount,
      credentials,
      doLogin,
      doStart,
      duplicateAccounts,
      loginResult,
      onClickAgree,
      onClickConfirmEmail,
      processingStatus,
      seeYa,
      simplePassword,
      toggleAgreeAll,
    }
    // => data return
  },
})

function inAppBrowserCallback() {
  // console.log("inAppBrowserCallback!!!!!");
}

