import {AgBoxS} from '@/components/ag'
import {defineComponent} from 'vue'

export const NotificationMark = defineComponent({
  components: {
    AgBoxS,
  },
  emits: ['update:modelValue'],
  name: 'NotificationMark',
  props: {
    modelValue: {
      default: false,
      type: Boolean,
    },
  },
  template: `
    <ag-box-s :css="{position: 'relative'}">
    <slot />
    <ag-box-s
        v-if="modelValue"
        :css="{
          bg: '#FD6767',
          position: 'absolute',
          width: 10,
          height: 10,
          borderRadius: 20,
          top: 0,
          right: 0,
        }"
    />
    </ag-box-s>
  `,
})
