
import {AgBox, AgButton, AgContent, AgFooter, AgHeader} from '@/components/ag'
import {defineComponent, ref} from 'vue'
import TermsCheckBox from './TermsCheckBox.vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {useStore} from 'vuex'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    ModalHeader,
    TermsCheckBox,
  },
  emits: ['close', 'nextStep'],
  setup(props, {emit}) {
    const {dispatch} = useStore()

    const setOpen = (value: boolean) => emit('close', value)
    const nextButton = ref<any>(true)
    const previousProcess = () => emit('close')
    const nextProcess = async () => {
      await dispatch('stakeDeal/NEXT_STEP_ACTION')
      await emit('nextStep')
    }

    return {nextButton, nextProcess, previousProcess, setOpen}
  },
})
