
import {AlertOptions} from '@ionic/core'
import {computed, defineComponent, ref} from 'vue'
import {onNotificationReceived, onNotificationActionPerformed} from '@/composables'
import {AgAlert} from '@/components/ag'
import {useNotificationAllowMessage} from './NotificationAllowMessage'
import {useRouter} from 'vue-router'
import {v1 as uuid} from 'uuid'
import {modalController} from '@ionic/vue'

export default defineComponent({
  components: {
    AgAlert,
  },
  name: 'Notification',
  props: {
    buttonText: {default: '확인', type: String},
  },
  setup() {
    const notificationRef = ref<AlertOptions | null>(null)
    const notificationAllowMessage = useNotificationAllowMessage()
    const router = useRouter()

    const setNotification = (notification: any | null) => {
      notificationRef.value = notification
    }

    const closeNotification = () => {
      notificationRef.value = null
    }

    const yesButton = computed(() => ({
      handler: () => {
        modalController.dismiss()
        router.push({
          name: 'notification-list',
          query: {flag: uuid()},
        })
        closeNotification()
      },
      text: '확인하러 가기',
    }))

    const noButton = computed(() => ({
      cssClass: 'gray',
      handler: () => closeNotification(),
      text: '닫기',
    }))

    onNotificationReceived((notification) => {
      setNotification({
        message: notification.body,
        subTitle: notification.subtitle,
        title: notification.title,
      })
    }, notificationAllowMessage.endToShow)

    onNotificationActionPerformed((payload) => {
      const {notification} = payload
      const {title, body, subtitle} = notification?.data ?? {}
      setNotification({
        message: body ?? '빈 메세지',
        subTitle: subtitle,
        title: title,
      })
    })

    const showNotification = computed(() => Boolean(notificationRef.value))

    return {
      closeNotification,
      noButton,
      notificationRef,
      setNotification,
      showNotification,
      yesButton,
    }
  },
})
