import {ExtractPropTypes, FunctionalComponent, h} from 'vue'
import {ColumnSection, RowSection, StyledBox, styledBoxProps, Skeleton} from './components'
import {_SystemProps} from '@/plugins/emotion'

export const agBoxSProps = {
  columnAs: null,
  containerAs: null,
  rowAs: null,
  skeleton: {default: false, type: Boolean},
}

export type AgBoxSProps = Partial<ExtractPropTypes<typeof agBoxSProps>> & {css?: _SystemProps}

export const AgBoxS: FunctionalComponent<AgBoxSProps> & {__stylePortal?: string} = (
  props, {slots},
) => {
  const {columnAs, rowAs, containerAs, skeleton} = props
  return (
    h(StyledBox, {as: containerAs}, () => skeleton ? h(Skeleton) : [
      slots.prepend?.(),
      h(ColumnSection, {columnAs}, {
        bottom: slots.bottom,
        default: () => (
          h(RowSection, {rowAs}, {
            default: slots.default,
            left: slots.left,
            right: slots.right,
          })
        ),
        top: slots.top,
      }),
      slots.append?.(),
    ])
  )
}

AgBoxS.props = agBoxSProps
AgBoxS.__stylePortal = 'css'

export const agBoxProps = {
  ...styledBoxProps,
  ...agBoxSProps,
}

export type AgBoxProps = Partial<ExtractPropTypes<typeof agBoxProps>>

export const AgBox: FunctionalComponent<AgBoxProps> = (
  props, {slots},
) => {
  const {columnAs, rowAs, css, containerAs, ...rest} = props
  return (
    h(AgBoxS, {columnAs, containerAs, css: {...rest, ...css}, rowAs}, slots)
  )
}

AgBox.props = agBoxProps
