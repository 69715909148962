
import {onDeepLinks} from '@/composables'
import {useDeepLinkReplace} from '@/composables/deep-link-replace'
import {defineComponent} from 'vue'

export const DeepLink = defineComponent({
  setup: () => {
    
    const runByLink = useDeepLinkReplace()
    
    const getLink = (url: string) => {
      if (/^angelleague:\/\//u.test(url)) {
        return url.split('angelleague://')[1].split('?')[0]
      }
      return url.split('https://angelleague.io/')[1].split('?')[0]
    }
    
    onDeepLinks(async (url) => {
      const link = getLink(url)
      return runByLink(link)
    })
    
    return () => {
      return null
    }
  },
})
