import {LineHeightsType} from '@/plugins/emotion'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'

export const lineHeightSystems = system({
  lineHeight: {
    property: 'lineHeight',
    scale: 'lineHeights',
  },
})
export const lineHeightProps = {
  lineHeight: getResponsiveProp<LineHeightsType>(),
}
