
import ModalHeader from '@/components/Common/ModalHeader.vue'
import {defineComponent, computed, onBeforeMount, ref, PropType, watch, toRef} from 'vue'
import {AgBox, AgBoxS, AgButton, AgIcon, AgText, AgModal, AgContent, AgFooter, AgHeader, AgImg, AgTextS} from '@/components/ag'
import {useStore} from 'vuex'
import {prettyDateToOnemonth, perCurrency, currency, numberToKorean} from '@/utils/filters'
import {alertController, toastController} from '@ionic/vue'
import {cancelFromBuyer, getStakeDepositInfo, getValidatePurchase} from '@/api/stake-deal'
import ViewDeposit from '@/views/view/deposit/Index.vue'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import useAlert from '@/composables/useAlert'
import {useRouter} from 'vue-router'
export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgHeader,
    AgIcon,
    AgImg,
    AgModal,
    AgText,
    AgTextS,
    ModalHeader,
    ViewDeposit,
  },
  emits: ['goTo', 'update:modelValue', 'cancel', 'companyClick', 'closeParent'],
  name: 'StakeInfoModal',
  props: {
    modelValue: {type: Boolean},
    priceCompare: {default: 'show', type: String},
  },
  setup: function(props, {emit}) {
    const gtm = useGtm()
    const {getters, dispatch} = useStore()
    const setOpen = (value: boolean) => emit('update:modelValue', value)
    const user = computed(() => getters['user/getUser'])
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const purchaseInfo = ref<any>({})
    const priceCompareRef = ref<string>(props.priceCompare)
    const modelValueRef = toRef(props, 'modelValue')
    const router = useRouter()

    const clickScreen = 'partnership-share-sell-detail-modal'
    watch(modelValueRef, ()=>{
      if(modelValueRef.value){
        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: `${clickScreen}/${dealInfo.value.partnershipShareSellId}`,
          title: `${dealInfo.value.partnershipName} 조합지분 거래 모달`,
        })
      }
    })


    const remainingSeconds = computed(() => {
      if (purchaseInfo.value.remainingSeconds >= 60) {
        return `${Math.floor(purchaseInfo.value.remainingSeconds / 60)}분 전`
      } 
        return `${purchaseInfo.value.remainingSeconds}초 전`
      
    })

    const deposit = ref<any>({})
    const viewDepositOpen = ref<boolean>(false)

    const openAlert = async (type: any) => {
      if (type === 'intercept') {
        const alert = await alertController
            .create({
              buttons: [
                {
                  text: '확인',
                },
              ],
              cssClass: 'wait-alert-class',
              message: `
            <div class="alert_css">
              <div class="header">
              잠시 기다려주세요!
              </div>
              <div class="message">
                이 매물은 지금<br>
                다른 구매자가 입금 대기중입니다.<br>
                10분내 구매금 입금이 완료되지 않으면<br>
                구매 기회가 생깁니다.
              </div>
            </div>
            `,
            })
        return alert.present()
      } else if (type === 'pay_info') {
        const alert = await alertController
            .create({
              buttons: [
                {
                  text: '확인',
                },
              ],
              cssClass: 'pay-alert-class',
              message: `
            <div class="alert_css">
              <div class="alert-header">
              구매신청 정보
              </div>
              <div class="message purchase-info-alert">
                <div class="space-between">
                  <div class="gray">주당 가격</div>
                  <div><span class="bold">${currency(purchaseInfo.value.price)}</span> 원</div>
                </div>
                <div class="space-between">
                  <div class="gray">구매 금액</div>
                  <div><span class="bold">${currency(purchaseInfo.value.totalPrice)}</span> 원</div>
                </div>
                <div class="space-between">
                  <div class="gray">수량</div>
                  <div>
                    약 <span class="bold">${currency(purchaseInfo.value.qty)}</span> 주
                    (${currency(purchaseInfo.value.asset)}좌)
                  </div>
                </div>
                <div class="space-between">
                  <div class="gray">거래 수수료 1%</div>
                  <div><span class="bold">${currency(purchaseInfo.value.fee)}</span> 원</div>
                </div>
                <hr/>
                <div class="space-between">
                  <div class="gray">예수금 차감 예정 금액</div>
                  <div></div>
                </div>
                <div class="space-between">
                  <div><span class="bold">${currency(purchaseInfo.value.depositAmount)}</span> 원(수수료 포함)</div>
                  <div></div>
                </div>

                <div class="remaining blue bold">(조합원 자격 이전 ${remainingSeconds.value})</div>
              </div>

            </div>
            `,
            })
        return alert.present()
      }
    }

    const cancelBuyAction = async () => {
      const result = await cancelFromBuyer(dealInfo.value.partnershipShareSellId, dealInfo.value.partnershipSharePurchaseId)
      if (result) {
        await dispatch('stakeDeal/GET_ALL_STAKELIST')
        emit('cancel')
      }
    }

    const resetStakeInfo = async () => {
      await dispatch('stakeDeal/GET_ALL_STAKELIST')
      emit('cancel')
    }

    const buyBtnEnable = ref<boolean>(true)
    const cancelBtnEnable = ref<boolean>(true)

    function setBtnDisabled(toggle:boolean){
      buyBtnEnable.value = toggle
      cancelBtnEnable.value = toggle
    }

    async function cancelToBuy() {
      const alert = await alertController
          .create({
            buttons: [
              {
                cssClass: 'btn-gray',
                role: 'cancel',
                text: '아니오',
              },
              {
                handler: async () => {
                  cancelBuyAction()
                },
                text: '네, 취소할게요',
              },
            ],
            cssClass: 'btn-groups adderss-alert-class',
            message: `
          <div class="alert_css">
            <div class="header">
              구매를 취소하시겠어요?
            </div>
            <div class="message">
              구매 요청 내역, 구매금 입금 계좌와<br>
              조합가입 확인서는 모두 삭제됩니다
            </div>
          </div>`,
          })

      return alert.present()
    }

    const checkCurrentTime = () => {
      return true
      // const time = new Date().getHours()
      // return (time > 9 && time <= 14)
    }

    const openUnavailableAlert = async (type: string) => {
      if (type === '구매' || type === '계정연결') {
        const alert = await alertController
            .create({
              buttons: [
                {
                  text: '확인',
                },
              ],
              cssClass: 'time-alert-class',
              message: `
          <div class="alert_css">
            <div class="header">
            ${type} 가능시간 안내
            </div>
            <div class="message">
              ${type === '구매' ? '구매는' : '계정연결은'} 영업일 기준<br>
              <span class="blue_text">
              오전 9시 ~ 오후 5시<br>
              </span>
              사이에 가능합니다.
            </div>
          </div>
          `,
            })
        return alert.present()
      } else if (type === '구매불가') {
        const alert = await alertController
            .create({
              buttons: [
                {
                  text: '확인',
                },
              ],
              cssClass: 'seller-cancel-alert-class',
              message: `
          <div class="alert_css">
            <div class="header">
            이 매물을 구매하실 수 없어요
            </div>
            <div class="message">
              이미 이 조합의 지분을<br>
              아주 많이 보유하셨어요<br>
              한 사람이 조합 지분의 1/3이상을<br>
              보유할 수 없습니다 🙏<br>
              다른 조합의 매물을 찾아보세요!
            </div>
          </div>
          `,
            })
        return alert.present()
      }
    }

    const closeAllThenToMypage = () =>{
      setOpen(false)
      emit('closeParent')
      router.push('/tabs/mypage/wallet')
    }

    const {chargeDepositAlert} = useAlert()


    const nextProcess = async () => {
      setBtnDisabled(false)
      await dispatch('stakeDeal/GET_LINK_STATUS')
      if (currentStep.value === 'COMPLETE') {
        const res = await getValidatePurchase(dealInfo.value.partnershipShareSellId)
        if (res.result) {
          emit('goTo', 'stakeBuy')
          
        } else {
          if (res.status === 406 && res.msg === 'CHARGE_MORE_DEPOSIT') {
            const data: any = res.data
            chargeDepositAlert(data.amount, closeAllThenToMypage )

          }else if (res.status === 406 && res.msg === 'CHECK_ASSET_LIMIT') {
            openUnavailableAlert('구매불가')
            
          }else if(res.status === 406 && res.msg === 'CHECK_PURCHASE_AVAILABLE_TIME'){
            openUnavailableAlert('구매')
          }
        }
      } else {
        emit('goTo', 'linkAccount')
      }
      setBtnDisabled(true)
    }

    const printDepositInfo = async () => {
      purchaseInfo.value = await getStakeDepositInfo(dealInfo.value.partnershipSharePurchaseId)
      console.log(purchaseInfo.value)
      await openAlert('pay_info')
    }

    const printAnotherDepositInfo = async (partnershipSharePurchaseId: number) => {
      deposit.value = await getStakeDepositInfo(partnershipSharePurchaseId)
      console.log(deposit.value)
      viewDepositOpen.value=true
    }

    return {
      buyBtnEnable,
      cancelBtnEnable,
      cancelToBuy,
      clickScreen,
      dealInfo,
      deposit,
      nextProcess,
      numberToKorean,
      openAlert,
      perCurrency,
      prettyDateToOnemonth,
      priceCompareRef,
      printAnotherDepositInfo,
      printDepositInfo,
      purchaseInfo,
      resetStakeInfo,
      setOpen,
      user,
      viewDepositOpen,
    }
  },
})
