
import {defineComponent, computed, getCurrentInstance, onMounted, toRef} from 'vue'
import {AgBox, AgButton, AgText, AgContent, AgFooter} from '@/components/ag'
import {alertController, toastController} from '@ionic/vue'
import {useStore} from 'vuex'
import {currency} from '@/utils/filters'
import {cancelFromSeller, deleteSellInfo, postConfirmSell} from '@/api/stake-deal'

export default defineComponent({
  components: {
    AgBox,
    AgButton,
    AgContent,
    AgFooter,
    AgText,
  },
  emits: ['close', 'edit', 'reload'],
  name: 'RegistedStake',
  setup(props, {emit}) {
    const app = getCurrentInstance()
    const $modalOpenEvent = app && app.appContext.config.globalProperties.$modalOpenEvent
    const clickScreen = 'my-partnership-share-sell-modal'
    onMounted(()=>{
      $modalOpenEvent({page: clickScreen, title: '내가 등록한 매물 확인 모달'})
    })

    const setOpen = () => emit('close')
    const {getters, dispatch} = useStore()
    const registerResult = computed(() => getters['stakeDeal/registerResult'])
    const isAvaliableToEdit = computed(() => registerResult.value.status === 'saleCompleted')
    const viewType = computed(() => registerResult.value.type)

    const deleteSellAction = async () => {
      const result = await deleteSellInfo(registerResult.value.partnershipShareSellId)
      if (result) {
        await dispatch('stakeDeal/GET_ALL_STAKELIST')
        emit('reload')
      }
    }

    const editMyStake = async () => {
      await dispatch('stakeDeal/SELECT_DEAL', registerResult.value)
      await emit('edit')
    }

    const cancelSellAction = async () => {
      console.log(registerResult.value)
      const result = await cancelFromSeller(registerResult.value.partnershipShareSellId, registerResult.value.partnershipSharePurchaseIdByBuyer)
      if (result) {
        await dispatch('stakeDeal/GET_ALL_STAKELIST')
        emit('reload')
      }
    }

    async function cancelToSell() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                cancelSellAction()
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups seller-cancel-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              거래를 취소하시겠어요?
            </div>
            <div class="message">
              구매자에게 정중한 거래 취소 메시지를<br>
              전송한 후, 이번 거래를 종료합니다.<br>
              거래 종료 후<br>
               해당 매물은 판매, 판매취소, 조건 수정이
              가능한 상태가 됩니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    async function deleteSell() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '팔기 유지',
            },
            {
              handler: async () => {
                deleteSellAction()
              },
              text: '팔기 취소',
            },
          ],
          cssClass: 'btn-groups buy-confirm-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              조합원 자격 팔기 취소
            </div>
            <div class="message">
              입력된 판매 조건은 모두 삭제되며<br>
              매물로 등록된 건은<br>
              목록에서 보이지 않게됩니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    const printSuccessMessage = async () => {
      const toast = await toastController
        .create({
          cssClass: 'toast-custom-blue',
          duration: 2000,
          message: '거래 확정이 완료되었습니다.',
          position: 'middle',
        })
      return toast.present()
    }

    const confirmSell = async () => {
      const result = await postConfirmSell(registerResult.value.partnershipShareSellId, registerResult.value.partnershipSharePurchaseIdByBuyer)
      if (result) {
        printSuccessMessage()
        emit('reload')
      }
    }

    async function confirmAlert() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '취소',
            },
            {
              handler: async () => {
                confirmSell()
              },
              text: '확인',
            },
          ],
          cssClass: 'btn-groups buy-confirm-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              거래를 확정합니다.
            </div>
            <div class="message">
              거래를 확정하시면<br>
              즉시 조합원 자격 이전 후<br>
              판매금이 예수금으로 충전됩니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    return {
      cancelSellAction,
      cancelToSell,
      clickScreen,
      confirmAlert,
      confirmSell,
      currency,
      deleteSell,
      editMyStake,
      isAvaliableToEdit,
      registerResult,
      setOpen,
      viewType,
    }
  },
})
