const gray6 = '#D9DEE8'
const gray5 = '#D4D9DF'
const gray4 = '#B6BBC6'
const gray3 = '#8F929B'
const gray2 = '#5E6068'
const gray1 = '#222222'
const white = '#FFFFFF'
const dark = gray1
const font = gray1

// background
const bg1 = '#F6F8FB'
const bg2 = '#EEF1F7'
// sky
const sky1 = '#3A8FF2'
const sky2 = '#C4DFFF'
// purple
const purple1 = '#735CFF'
const skyPurple1 = '#7284DC'
// blue
const blueGray1 = '#7583A1'
const blue1 = '#4360EC'

const mint1 = '#00B7B9'
const mint2 = '#AFECDD'

const red1 = '#F44B4B'
const red2 = '#FD6767'

const green1 = '#3EBA1F'
const orange1 = '#FF8800'
const darkGreen2 = '#007A7B'
const darkGreen1 = '#183136'
const indigo1 = '#101D5C'
const indigo2 = '#293051'

const secondary = mint1
const primary = blue1
const listLine = '#F9F9FB'

const danger = red2
const transparent = 'transparent'

export const invertColors = {
  bg1: gray2,
  bg2: gray2,
  blue1: white,
  blueGray1: white,
  danger: white,
  dark: white,
  darkGreen1: white,
  darkGreen2: white,
  font: white,
  gray1: white,
  gray2: white,
  gray3: gray2,
  gray4: gray2,
  gray5: gray2,
  gray6: gray2,
  green1: white,
  indigo1: white,
  indigo2: white,
  listLine: gray2,
  mint1: white,
  mint2: white,
  orange1: white,
  primary: white,
  purple1: white,
  red1: white,
  red2: white,
  secondary: white,
  sky1: white,
  sky2: gray2,
  skyPurple1: white,
  transparent: gray2,
  white: gray2,
}

export type InvertColors = typeof invertColors
export type InvertColorsType = keyof InvertColors

export const colors = {
  bg1,
  bg2,
  blue1,
  blueGray1,
  danger,
  dark,
  darkGreen1,
  darkGreen2,
  font,
  gray1,
  gray2,
  gray3,
  gray4,
  gray5,
  gray6,
  green1,
  indigo1,
  indigo2,
  listLine,
  mint1,
  mint2,
  orange1,
  primary,
  purple1,
  red1,
  red2,
  secondary,
  sky1,
  sky2,
  skyPurple1,
  transparent,
  white,
}

export type Colors = typeof colors
export type ColorsType = keyof Colors | string
