import {Plugins, ShareOptions} from '@capacitor/core'
import {getPlatform} from '../platform'
import {useLegacyClipboard} from '@winter-love/use'
import {toastController} from '@ionic/vue'

const {Share} = Plugins

const defaultWebClipboardHook = async () => {
  const toast = await toastController.create({
    duration: 4000,
    message: '공유 복사 완료!',
  })
  
  return toast.present()
}

const defaultGetMessage = (options: ShareOptions) => {
  const {url} = options ?? {}
  return url ?? ''
}

export interface UseShareOptions {
  getMessage?: () => string
  onWebClipboardHook?: (message: string) => unknown
}

export const useShare = (options: UseShareOptions = {}) => {
  const {
    onWebClipboardHook = defaultWebClipboardHook,
    getMessage = defaultGetMessage,
  } = options
  const isNative = getPlatform() !== 'web'
  
  const {write} = useLegacyClipboard()

  const webShare = (options: ShareOptions) => {
    const {text, title, url} = options
    if (navigator.share) {
      return navigator.share({
        text,
        title,
        url,
      })
    }
    const message = getMessage({text, title, url})
    onWebClipboardHook(message)
    return write(message)
  }
  
  const nativeShare = (options: ShareOptions) => {
    return Share.share(options)
  }
  
  return (options: ShareOptions) => {
    if (isNative) {
      return nativeShare(options)
    }
    return webShare(options)
  }
}
