export function authHeader() {
  if (localStorage.getItem('user')) {
    const token = localStorage.getItem('token') || ''
    if (token) {
      return {
        Authorization: 'Bearer ' + token,
      }
    }
      return {
      }
    
  } 
    return {}
  
}

export function getTokenString() {
  if (localStorage.getItem('user')) {
    const token = localStorage.getItem('token') || ''
    if (token) {
      return token
    } 
      return null
    
  } 
    return null
  
}
