import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-694d4152"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-container" }
const _hoisted_2 = { class: "title-wrap" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "light-g"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_text = _resolveComponent("ag-text")!
  const _component_ag_img = _resolveComponent("ag-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ag_text, {
        color: 'gray3',
        typography: 'main14r'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.infoTypeRef), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: "space-between",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.infoMap[_ctx.infoTypeRef].func && _ctx.infoMap[_ctx.infoTypeRef].func(...args)))
    }, [
      _createVNode(_component_ag_text, {
        dp: '-webkit-box',
        width: 'calc(100% - 32px)',
        typography: 'tit18r'
      }, {
        default: _withCtx(() => [
          (_ctx.infoValueRef)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.infoValueRef), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.infoMap[_ctx.infoTypeRef].default), 1))
        ]),
        _: 1
      }, 8, ["width"]),
      _createVNode(_component_ag_img, {
        style: {"flex-shrink":"0"},
        width: "30px",
        height: "30px",
        src: '/assets/icon/btn_open_gray.png'
      }, null, 8, ["src"])
    ])
  ]))
}