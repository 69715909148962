
import {modalController} from '@ionic/vue'
import {AgBox, AgBoxS} from '@/components/ag'
import {useStore} from 'vuex'
import {defineComponent, reactive, ref, onUnmounted, onMounted, computed} from 'vue'
import {AUTH_API_URL} from '@/config'
import {Plugins} from '@capacitor/core'
import SimpleLoginModal from '@/components/Modal/SimpleLoginModal.vue'
import Use from '@/views/Agrees/Use.vue'
import {openLink, deviceParam} from '@/utils/filters'
import {useSocketIO} from '@/composables/socket-io'
import {Offline} from '@/components/contents/offline/Offline'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
const {Browser} = Plugins

export default defineComponent({
  components: {
    AgBoxS,
    Offline,
  },
  name: 'Login',
  props: {
    cb: null,
    psswordReset: null,
  },
  setup(props) {
    const gtm = useGtm()
    const {socket} = useSocketIO()

    onUnmounted(() => {
      socket?.disconnect?.()
    })

    onMounted(()=>{
      gtm?.trackEvent({
        event: MODAL_OPEN_EVENT,
        page: clickScreen,
        title: '본인인증 로그인 모달',
      })
    })

    const {dispatch, state} = useStore()

    const agrees: any = reactive({agreeMarketing: false, agreeTerms: false, agreeThirdparty: false})
    const agreeAll = ref(false)
    const duplicateAccounts = ref<any>([])
    const chooseAccount = ref(null)
    const processingStatus = ref<string>(socket ? 'init' : 'internet-error')

    const resetPassword = ref(props.psswordReset)
    // processingStatus.value = 'agree_need';
    const authInfoMessage = ref<{msg: string; title: string}>({
      msg: '',
      title: '',
    })

    // processingStatus.value = 'identityCertifying';

    // processingStatus.value = 'under_adult';
    // authInfoMessage.value = {
    //   title:"앗! 아직 성인이 아니십니다.",
    //   msg:"고위험 직접투자 서비스인 엔젤리그는\n미성년자가 사용하실 수 없습니다.\n성인이 된 후 꼭 만나요!"
    // }

    // processingStatus.value = 'duplicate_account';
    // authInfoMessage.value = {
    //   title:"사용하셨던 이메일이 있어요!",
    //   msg:"계속 사용 원하시는 이메일을 1개 선택해주세요"
    // }
    // duplicateAccounts.value = [
    //   {userId:170, email:'es.ko@capbox.kr'},
    //   {userId:239, email:'mt_grace@naver.com'},
    //   {userId:1234, email:'esko10@esko.com'},
    //   {userId:1235, email:'esko1@gmail.com'},
    // ];

    // processingStatus.value = 'agree_need';

    // processingStatus.value = 'regist_success';
    // authInfoMessage.value = {
    //   title:"가입 완료!",
    //   msg:"홍길동 엔젤님,\n환영합니다!"
    // }

    const toggleAgreeAll = () => {
      agreeAll.value = !agreeAll.value

      for (const key in agrees) {
        agrees[key] = agreeAll.value
      }
    }

    const checkProcessStatus = (status: string) => {
      return processingStatus.value === status
    }

    const openIABrowser = async (socketId: string) => {
      await Browser.open({url: `${AUTH_API_URL}/auth/identity-certification/${socketId}?${deviceParam()}`})
    }

    const temporaryUuid = ref('')

    const setNextStep = async () => {
      const signupStep = await dispatch('user/ACT_GET_SIGNUP_STEP', temporaryUuid.value)
      processingStatus.value = signupStep.result
      authInfoMessage.value = signupStep.message

      // 본인인증 후 계정매칭 완료 후 UUID device저장
      if (processingStatus.value === 'regist_success') {
        if (resetPassword.value === true) {
          authInfoMessage.value.title = '본인인증 완료!'
        }

        await dispatch('user/ACT_SET_UUID', temporaryUuid.value)
        await dispatch('user/ACT_SAVE_UUID_LOCALSTORAGE', temporaryUuid.value)
        await dispatch('user/ACT_SAVE_SIMPLE_PASSWORD_LOCALSTORAGE', signupStep.simple_password_flag)
      }
    }

    if (processingStatus.value !== 'internet-error') {
      socket.on('connect', function() {
        // alert('SOCKET Connected');
        // alert(socket.id);
        // console.log('SOCKET Connected');
      })

      socket.on('disconnect', function() {
        // console.log('SOCKET Disconnected');
      })

      socket.on('connect_failed', () => {
        //
      })

      // socket init
      socket.on('msgToClient', async function(data: any) {
        console.log('msgToClient event', data)

      })

      socket.on('under_adult', function(data: any) {
        processingStatus.value = data.result// under_adult
        authInfoMessage.value = data.message
        Browser.close()
      })

      // 본인인증 후 계정 생성/매칭 성공
      socket.on('auth_success', async function(data: any) {
        // uuid 최초 획득, state에 저장
        const uuid = data.authInfo.uuid
        temporaryUuid.value = uuid
        // await dispatch('user/ACT_SET_UUID', uuid)
        await setNextStep()
        await Browser.close()
      })

      // 본인 인증 후 중복 전화번호 계정 중 선택
      socket.on('no_auth_history', async function(data: any) {
        processingStatus.value = 'identityCertifyFail'
      })
      socket.on('duplicate_account', async function(data: any) {
        // console.log("::::::::::duplicate_account:::::::::",data);
        processingStatus.value = data.result// duplicate_account
        authInfoMessage.value = data.message
        duplicateAccounts.value = data.authInfo.duplicate_accounts
        const uuid = data.authInfo.uuid
        temporaryUuid.value = uuid
        await dispatch('user/ACT_SET_UUID', uuid)
        await Browser.close()
      })
    }

    function doRefresh() {
      const socketId = localStorage.getItem('auth_socket_id')
      socket.emit('refresh-certification', {
        authSocketId: socketId,
      })
      // socketId
    }

    const onClickSocket = async () => {
      // console.log(socket);
      processingStatus.value = 'identityCertifying'
      localStorage.setItem('auth_socket_id', socket.id)

      await openIABrowser(socket.id)
      socket.emit('start-certification', {
        msg: 'hello world',
      })
    }

    const onClickAgree = async () => {
      await dispatch('user/ACT_AGREE_TERMS', {agrees, uuid:temporaryUuid.value})
      await setNextStep()
      // // console.log('onClickAgree', data);
      // processingStatus.value = data.result;
      // authInfoMessage.value = data.message;
    }

    const onClickConfirmEmail = async () => {
      await dispatch('user/ACT_CHOOSE_ACCOUNT', chooseAccount.value)
      await setNextStep()
    }

    const loginResult = reactive<{ message: string }>({message: ''})

    const seeYa = async () => {
      // router.go(-1);
      await modalController.dismiss()
    }

    const doStart = async () => {
      // console.log('본인인증 고고');
      onClickSocket()
    }

    const simplePassword = async () => {
      await modalController.dismiss()
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: SimpleLoginModal,
          componentProps: {
            cb: props.cb,
          },
          cssClass: 'simple-login-modal',
          id: 'SimpleLoginModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      return modal.present()
      // router.replace({ path: `/simple-login?${new Date().getMilliseconds()}` });
    }

    async function openUse() {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: Use,
          componentProps: {},
          cssClass: 'height-100-modal',
          id: 'UseModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      return modal.present()
    }

    const validBrowser = ref(true)
    if (~navigator.userAgent.indexOf('KAKAOTALK')) {
      validBrowser.value = false
    }
    
    const inviteCode = computed(() => {
      return state.pointInfo.waitingPoint?.code
    })

    const clickScreen = computed(() => {
      if (inviteCode.value) {
        return 'login-refferal'
      }
      return 'login'
    })
    return {
      agreeAll,
      agrees,
      authInfoMessage,
      checkProcessStatus,
      chooseAccount,
      clickScreen,
      doRefresh,
      doStart,
      duplicateAccounts,
      inviteCode,
      loginResult,
      onClickAgree,
      onClickConfirmEmail,
      openLink,
      openUse,
      processingStatus,
      resetPassword,
      seeYa,
      simplePassword,
      toggleAgreeAll,
      validBrowser,
    }
    // => data return
  },
})
