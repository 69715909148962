
import {defineComponent, ref, watch, reactive, computed, onBeforeMount} from 'vue'
import {modalController, IonSearchbar, IonContent, IonToolbar,
  IonFooter, IonInput, IonLabel, IonDatetime, pickerController,
  toastController, IonItem, IonRippleEffect} from '@ionic/vue'
import {useStore} from 'vuex'
import {getFormatDate} from '../../utils/filters'
import stockOptionApi from '../../api/stock-option'

const {addStockOptions, editStockOptions} = stockOptionApi()

export default defineComponent({
  components: {
    IonContent,
    IonDatetime,
    IonFooter,
    IonInput,
    IonItem,
    IonLabel,
    IonRippleEffect,
    IonSearchbar,
    IonToolbar,
  },
  name: 'StockAddModal',
  props: {
    mode: String,
    stockOption: null,
  },
  setup(props) {
    const {getters, dispatch} = useStore()

    const stockMode = computed(() => props.mode)
    const stockOptionData = computed(() => props.stockOption)
    const stockOptionId = ref<number>(0)
    const isWaitingConfirm = ref(false)
    const state = reactive({
      companyName: '',
      contractDate: getFormatDate(new Date()),
      exerciseFrom: 1,
      exercisePrice: 0,
      exerciseQty: 0,
      exerciseTo: 1,
      files: '',
      stockItemId: '',
    })

    const editState = reactive({
      originalFileName: '',
      stockOptionFileId: 0,
    })

    const companyInput = ref<string>('')
    const stockItems = computed(()=>getters['stockItem/getStockItems'])
    const searchStockItems = ref<any>([])

    const currentStatus = ref<number>(1)
    const display = ref<boolean>(false)
    const doneButtonToggle = ref<boolean>(stockMode.value === 'edit')
    const keyInitemNameDriectly = ref<boolean>(false)
    const backButtonToggle = ref<boolean>(false)

    onBeforeMount(async () => {
      dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS')
      if (stockMode.value === 'edit' && stockOptionData.value) {
        backButtonToggle.value = true

        console.log(stockOptionData.value)

        state.companyName = stockOptionData.value.companyName
        state.exerciseQty = stockOptionData.value.exerciseQty
        state.exercisePrice = stockOptionData.value.exercisePrice
        state.contractDate = stockOptionData.value.contractDate
        state.exerciseFrom = stockOptionData.value.exerciseFrom
        state.exerciseTo = stockOptionData.value.exerciseTo
        stockOptionId.value = stockOptionData.value.stockOptionId
        editState.originalFileName = stockOptionData.value.originalFileName
        editState.stockOptionFileId = stockOptionData.value.stockOptionFileId

        currentStatus.value = 6
      }
    })

    function uploadFile(event: any) {
      state.files = event.target.files[0]
    }

    async function checkFileValue(event: any) {
      uploadFile(event)
      if (state.files) {
        doneButtonToggle.value = true
      }
    }

    function openFileSelector() {
      const fileElement: any = document.querySelector('#file_for_proof')
      if (fileElement) {
        fileElement.click()
      }
    }

    function removeFile() {
      if (stockMode.value === 'add') {
        const files: any = document.querySelector('#file_for_proof')

        files.value = ''
        state.files = ''

        if (!files.value && !state.files) {
          doneButtonToggle.value = false
        }
      } else if (stockMode.value === 'edit') {
        editState.originalFileName = ''
        doneButtonToggle.value = false
      }
    }

    async function createFormData() {
      const formData = await new FormData()
      console.log(state.files)

      if (stockMode.value === 'add') {
        await formData.append('companyName', state.companyName.toString())
      }

      await formData.append('stockItemId', state.stockItemId.toString())
      await formData.append('exerciseQty', state.exerciseQty.toString())
      await formData.append('exercisePrice', state.exercisePrice.toString())
      await formData.append('contractDate', state.contractDate.toString())
      await formData.append('exerciseFrom', state.exerciseFrom.toString())
      await formData.append('exerciseTo', state.exerciseTo.toString())
      await formData.append('sellAvailableFrom', '')
      await formData.append('sellAvailableTo', '')
      await formData.append('files', state.files)

      return formData
    }

    const sendPost = async () => {
      isWaitingConfirm.value = true
      const formData = await createFormData()

      let result = ''

      if (stockMode.value === 'add') {
        result = await addStockOptions(formData)
        if (result) {
          dispatch('asset/loadData')
          dispatch('asset/ACT_GET_ASSET_PARTNERSHIPS')

          await modalController.dismiss()
          const toast = await toastController
            .create({
              duration: 1000,
              message: '스톡옵션이 성공적으로 추가되었습니다.',
              position: 'middle',
            })
          return toast.present()
        }
      } else if (stockMode.value === 'edit') {
        // editState에서 name이 null이면 한번 지워진 이력이 있는거!
        result = await editStockOptions(stockOptionId.value, formData)
        if (result) {
          // console.log(result);
          dispatch('asset/loadData')
          dispatch('asset/ACT_GET_ASSET_PARTNERSHIPS')

          await modalController.dismiss()
          const toast = await toastController
            .create({
              duration: 1000,
              message: '스톡옵션이 수정되었습니다.',
              position: 'middle',
            })
          return toast.present()
        }
      }
      isWaitingConfirm.value = false
    }

    const dismiss = () => {
      modalController.dismiss()
    }

    const updateDate = (event: any) => {
      if(!event.detail.value) {
return
}
      const day: string = event.detail.value.toString().slice(0, 10)
      state.contractDate = day
    }

    function requestItem() {
      // console.log("회사명 직접 입력");
      keyInitemNameDriectly.value = true
    }

    const checkProcessStatus = (...status: any) => {
      return ~status.indexOf(currentStatus.value)
    }

    const setProcessStatus = (type: string) => {
      currentStatus.value = type === 'previous' ? currentStatus.value - 1 : currentStatus.value + 1
      // console.log(curStatus.value);
    }

    const searchCleared = (event: Event) => {
      // console.log(event)
    }

    const setCompany = (name: string, id: string) => {
      state.companyName = name
      state.stockItemId = id
      setProcessStatus('next')
    }

    function checkLength(string: string, value: string) {
      const checkNum: number = Number(string + value)
      return checkNum > 2100000000
    }

    function addNumber(num: any, status: number) {
      const value: string = num.toString()
      let statusTarget = status === 2 ? state.exerciseQty.toString() : state.exercisePrice.toString()

      if (((value === '0' || value === '00') && statusTarget === '0') ||
          checkLength(statusTarget, value)) {
return
} // 현재 값이 0인데 0이나 00을 누르려고 하는 상황

      statusTarget += value

      if (status === 2) {
        state.exerciseQty = Number(statusTarget)
      } else {
        state.exercisePrice = Number(statusTarget)
      }
    }

    function clearNumber(command: string, status: number) {
      let result = status === 2 ? state.exerciseQty.toString() : state.exercisePrice.toString()

      if (command === 'clear') {
        result = '0'
      } else if (command === 'del') {
        if (result === '') {
          return
        } 
          result = result.slice(0, - 1)
        
      }

      if (status === 2) {
        state.exerciseQty = Number(result)
      } else {
        state.exercisePrice = Number(result)
      }
    }

    async function searchKeyword(event: any) {
      const query = event.target.value
      if (!query) {
        searchStockItems.value = []
      } else {
        companyInput.value = query
        searchStockItems.value = stockItems.value.filter((item: any) => ~item.stockItemName.indexOf(companyInput.value)||(item.codeNumber&&~item.codeNumber.indexOf(query)))
      }
    }

    const multiColumnOptions = [
      ['1년', '2년', '3년', '4년', '5년', '6년', '7년', '8년', '9년', '10년', '11년', '12년', '13년', '14년', '15년'],
      ['1년', '2년', '3년', '4년', '5년', '6년', '7년', '8년', '9년', '10년', '11년', '12년', '13년', '14년', '15년'],
    ]

    function getColumnOptions(columnIndex: number, numOptions: number, columnOptions: any) {
      const options = []
      for (let index = 0; index < numOptions; index++) {
        options.push({
          text: columnOptions[columnIndex][index % numOptions],
          value: index,
        })
      }

      return options
    }

    function getColumns(numColumns: number, numOptions: number, columnOptions: any) {
      const columns = []
      for (let index = 0; index < numColumns; index++) {
        columns.push({
          name: `col${index}`,
          options: getColumnOptions(index, numOptions, columnOptions),
        })
      }

      return columns
    }

    async function openPicker(numColumns: number, numOption: number, columnOptions: any) {
      const picker = await pickerController.create({
        buttons: [
          {
            role: 'cancel',
            text: '취소',
          },
          {
            handler: (value: any) => {
              state.exerciseFrom = Number(value.col0.text.slice(0, 1))
              state.exerciseTo = Number(value.col1.text.slice(0, 1))
            },
            text: '확인',
          },
        ],
        columns: getColumns(numColumns, numOption, columnOptions),
        mode: 'ios',
      })

      picker.columns[0].selectedIndex = state.exerciseFrom - 1
      picker.columns[1].selectedIndex = state.exerciseTo - 1

      await picker.present()
    }

    const itemLikeCompany = async (stockItemId: any) => {
      const result = await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
      const index = searchStockItems.value.findIndex((stock: any) => stock.stockItemId === result.stockItem.stockItemId)
      if (index > -1) {
        searchStockItems.value[index].likeFlag = result.stockItem.likeFlag
      }
    }

    const customDayShortNames = [
      'man',
      'tir',
      'ons',
      'tor',
      'fre',
    ]
    const customPickerOptions = {
      buttons: [
        {
          role: 'cancel',
          text: '취소',
        },
        {
          handler: (event: any) => {
            const {year, month, day} = event
            console.log(year, month, day)
            state.contractDate = `${year.text}-${month.text}-${day.text}`
          },
          text: '확인',
        },
      ],
    }

    return {
      addNumber,
      backBtnToggle: backButtonToggle,
      checkFileValue,
      checkProcessStatus,
      clearNumber,
      companyInput,
      curStatus: currentStatus,
      customDayShortNames,
      customPickerOptions,
      dismiss,
      display,
      doneBtnToggle: doneButtonToggle,
      editState,
      isWaitingConfirm,
      itemLikeCompany,
      keyInitemNameDriectly,
      multiColumnOptions,
      openFileSelector,
      openPicker,
      removeFile,
      requestItem,
      searchCleared,
      searchKeyword,
      searchStockItems,
      sendPost,
      setCompany,
      setProcessStatus,
      state,
      stockItems,
      stockMode,
      updateDate,
      uploadFile,
    }
  },
})
