
import {modalController} from '@ionic/vue'
import {defineComponent, computed} from 'vue'

export default defineComponent({
  name: 'DeleteInfo',
  props: {
    type: null,
  },
  setup(props) {
    const modalType = computed(() => props.type)
    const dismiss = (command: string) => {
      modalController.dismiss(command)
    }

    return {
      dismiss,
      modalType,
    }
  },
})
