import {Module} from 'vuex'
import {RootState} from '..'
import apiAsset from '@/api/asset'
const {getAsset, getShareTransactions, deletePartnershipBoard, createPartnershipBoards, getAssetPartnerships, getSelectedPartnership, getPartnershipBoard, likePartnershipBoard} = apiAsset()
// state
interface Asset {
  assetCollectingPartnerships: Record<any, any>
  assetPartnerships: Record<any, any>
  depositInformation: Record<any, any>
  docusign: Record<any, any>
  earlyBird: Record<any, any>
  exit: Record<any, any>
  partnershipBoardList: any[]
  partnershipCollecting: Record<any, any>
  partnershipComplete: Record<any, any>
  reflectAt: string
  selectedPartnership: Record<any, any>
  shareTransactions: Record<any, any>
  shareTransactionsDepositInfo: Record<any, any>
  shareTransactionsHistory: Record<any, any>
  stockOption: Record<any, any>
  totalAsset: number
  totalIncomingProfit: number
  totalIncomingProfitRate: number
}

// action
const Asset: Module<Asset, RootState> = {

  actions: {
    async ACT_DELETE_PARTNERSHIP_BOARD(_, {partnershipId, partnershipBoardId}) {
      const result = await deletePartnershipBoard(partnershipId, partnershipBoardId)
      return result
    },
    ACT_GET_ASSET_PARTNERSHIPS({commit}) {
      getAssetPartnerships()
        .then((data) => {
          commit('SET_ASSET_PARTNERSHIPS', data.stockItemAssetList)
          commit('SET_TOTAL_INCOMING_PROFIT', data.totalIncomingProfit)
          commit('SET_TOTAL_INCOMING_PROFIT_RATE', data.totalIncomingProfitRate)
          commit('SET_REFLECT_AT', data.reflectAt)
        })
    },
    async ACT_GET_SHARE_TRANSACTIONS_HISTORY({commit}, page = 1) {
      getShareTransactions(page)
        .then((data: any) => {
          commit('SET_SHARE_TRANSACTIONS_HISTORY', data)
        })
    },
    async ACT_LIKE_PARTNERSHIP_BOARD({commit}, {partnershipId, partnershipBoardId}) {
      likePartnershipBoard(partnershipId, partnershipBoardId)
        .then((result) => {
          result.partnershipBoardId = partnershipBoardId
          commit('SET_REPLACE_PARTNERSHIP_BOARD_LIKE_INFO', result)
        })
    },
    async ACT_SAVE_PARTNERSHIP_BOARDS(_, {boardData}) {
      const result = await createPartnershipBoards(boardData)
      return result
      // console.log(result)

      // if(result.type==='create'){
      //   commit('SET_PUSH_FIRST_PARTNERSHIP_BOARD', result)
      // }else if(result.type==='update'){
      //   commit('SET_REPLACE_PARTNERSHIP_BOARD', result)
      // }

      // return result
    },
    ACT_SELECT_PARTNERSHIP({commit}, partnershipId: string) {
      if (typeof (partnershipId) === 'string') {
        getSelectedPartnership(partnershipId)
          .then((data) => {
            console.log(data)
            if (data) {
commit('SET_SELECT_PARTNERSHIP', data)
}
          })
      }
    },
    ACT_SELECT_PARTNERSHIP_BOARD_LIST({commit}, partnershipId: any) {
      getPartnershipBoard(partnershipId)
        .then((data) => {
          if (data) {
commit('SET_PARTNERSHIP_BOARD_LIST', data.reverse())
} // 최근순!
        })
    },
    loadData({commit}) {
      getAsset().then((value) => {
        console.log(value)
        commit('SET_PS_COMPLETE', value.partnershipComplete)
        commit('SET_PS_COLLECTING', value.partnershipCollecting)
        commit('SET_TOTAL_ASSET', value.totalAsset)
        // commit('SET_EARLYBIRD',value.earlyBird)
        commit('SET_EXIT', value.exit)
        commit('SET_STOCK_OPTION', value.stockOption)
        commit('SET_DEPOSIT_INFO', value.depositInformation)
        commit('SET_SHARE_TRANSACTION_DEPOSIT_INFO', value.shareTransactionDepositInfos)
        commit('SET_DOCUSIGN', value.docusign)
        commit('SET_SHARE_TRANSACTIONS', value.shareTransactions)
      })
    },

  },

  getters: {

    getAssetPartnerships: state => state.assetPartnerships,
    getDepositInfo: state => state.depositInformation,

    getDocusign: state => state.docusign,

    // getEarlyBird: state => state.earlyBird,
    getExit: state => state.exit,

    // getSelectedPartnershipInfo: state => state.selectedPartnershipInfo,
    getPartnershipBoardList: state => state.partnershipBoardList,

    getPsCollecting: state => state.partnershipCollecting,

    getPsComplete: state => state.partnershipComplete,
    getReflectAt: state => state.reflectAt,
    getSelectedPartnership: state => state.selectedPartnership,
    getShareTransactions: state => state.shareTransactions,
    getShareTransactionsDepositInfo: state => state.shareTransactionsDepositInfo,
    getShareTransactionsHistory: state => state.shareTransactionsHistory,
    getStockOption: state => state.stockOption,
    getTotalAsset: state => state.totalAsset,
    getTotalIncomingProfit: state => state.totalIncomingProfit,
    getTotalIncomingProfitRate: state => state.totalIncomingProfitRate,
  },

  // RootState와 똑같이 작성해도 된다.
  mutations: {
    DEL_SHARE_TRANSACTION_DEPOSIT_INFO(state, index: number) {
      state.shareTransactionsDepositInfo.splice(index, 1)
    },

    SET_ASSET_PARTNERSHIPS(state, array: any) {
      const result = array.sort((a: any, b: any) => {
        const aAsset = isNaN(Number.parseInt(a.stockItemAsset)) ? -Number.MAX_VALUE : Number.parseInt(a.stockItemAsset)
        const bAsset = isNaN(Number.parseInt(b.stockItemAsset)) ? -Number.MAX_VALUE : Number.parseInt(b.stockItemAsset)
        const aProfit = isNaN(Number.parseInt(a.stockItemIncomingProfit)) ? -Number.MAX_VALUE : Number.parseInt(a.stockItemIncomingProfit)
        const bProfit = isNaN(Number.parseInt(b.stockItemIncomingProfit)) ? -Number.MAX_VALUE : Number.parseInt(b.stockItemIncomingProfit)
        const aProfitRate = isNaN(Number.parseFloat(a.stockItemIncomingProfitRate)) ? -Number.MAX_VALUE : Number.parseFloat(a.stockItemIncomingProfitRate)
        const bProfitRate = isNaN(Number.parseFloat(b.stockItemIncomingProfitRate)) ? -Number.MAX_VALUE : Number.parseFloat(b.stockItemIncomingProfitRate)

        if (bProfitRate < aProfitRate) {
          return -1
        } else if (bProfitRate > aProfitRate) {
          return 1
        } 
          return (bAsset + bProfit) - (aAsset + aProfit)
        
      })

      // console.log(result)

      state.assetPartnerships = result
    },
    SET_DEPOSIT_INFO(state, object: Record<any, any>) {
      state.depositInformation = object
    },
    SET_DOCUSIGN(state, object: Record<any, any>) {
      state.docusign = object
    },

    SET_EARLYBIRD(state, object: Record<any, any>) {
      state.earlyBird = object
    },

    SET_EXIT(state, object: Record<any, any>) {
      state.exit = object
    },

    // SET_SELECT_PARTNERSHIP_INFO(state, selectedPartnershipInfo: any) {
    //   state.selectedPartnershipInfo = selectedPartnershipInfo
    // },
    SET_PARTNERSHIP_BOARD_LIST(state, partnershipBoardList: any) {
      console.log(partnershipBoardList)

      state.partnershipBoardList = partnershipBoardList
    },

    SET_PS_COLLECTING(state, object: Record<any, any>) {
      state.partnershipCollecting = object
    },

    SET_PS_COMPLETE(state, object: Record<any, any>) {
      state.partnershipComplete = object
    },
    SET_PUSH_FIRST_PARTNERSHIP_BOARD(state, partnershipBoard: any) {
      state.partnershipBoardList = [partnershipBoard].concat(state.partnershipBoardList)
    },
    SET_REFLECT_AT(state, reflectAt: string) {
      state.reflectAt = reflectAt
    },
    SET_REPLACE_PARTNERSHIP_BOARD(state, partnershipBoard: any) {
      state.partnershipBoardList = state.partnershipBoardList.map((stock: any) => stock.partnershipBoardId === partnershipBoard.partnershipBoardId ? partnershipBoard : stock)
    },
    SET_REPLACE_PARTNERSHIP_BOARD_LIKE_INFO(state, boardData: any) {
      state.partnershipBoardList.map(
        (a: any) => {
          if (a.partnershipBoardId.toString() === boardData.partnershipBoardId.toString()) {
            a.likeFlag = boardData.likeFlag
            a.likeCount = boardData.likeCount
            return a
          }
          return a
        },
)
    },
    SET_SELECT_PARTNERSHIP(state, selectedPartnership: any) {
      state.selectedPartnership = selectedPartnership
    },
    SET_SHARE_TRANSACTIONS(state, object: Record<any, any>) {
      state.shareTransactions = object
    },
    SET_SHARE_TRANSACTIONS_HISTORY(state, object: Record<any, any>) {
      state.shareTransactionsHistory = object
    },
    SET_SHARE_TRANSACTION_DEPOSIT_INFO(state, object: Record<any, any>) {
      state.shareTransactionsDepositInfo = object
    },
    SET_STOCK_OPTION(state, object: Record<any, any>) {
      state.stockOption = object
    },
    SET_TOTAL_ASSET(state, num: number) {
      state.totalAsset = num
    },
    SET_TOTAL_INCOMING_PROFIT(state, num: number) {
      state.totalIncomingProfit = num
    },
    SET_TOTAL_INCOMING_PROFIT_RATE(state, string: string) {
      state.totalIncomingProfitRate = Number.parseFloat(string)
    },
  },
  namespaced: true,
  state: {

    assetCollectingPartnerships: {},

    assetPartnerships: {},

    depositInformation: {},
    docusign: [],

    earlyBird: {},

    exit: {},

    // selectedPartnershipInfo: {},
    partnershipBoardList: [],

    partnershipCollecting: {},

    partnershipComplete: {},
    reflectAt: '',
    selectedPartnership: {},
    shareTransactions: {},
    shareTransactionsDepositInfo: {},
    shareTransactionsHistory: {},
    stockOption: {},
    totalAsset: 0,
    totalIncomingProfit: 0,
    totalIncomingProfitRate: 0,
  },
}

export default Asset
