
import {AgBox, AgBoxS, AgImg, AgButton, AgText, AgTextS, AgContent, AgFooter} from '@/components/ag'
import {computed, defineComponent, watch, defineAsyncComponent, ref, toRef} from 'vue'
import {alertController, modalController, toastController} from '@ionic/vue'
import {useStore} from 'vuex'
import {requestPurchase} from '@/api/stake-deal'
import {useRouter} from 'vue-router'
import {useLegacyClipboard} from '@/composables'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

export default defineComponent({
  components: {
    AgBox,
    AgBoxS,
    AgButton,
    AgContent,
    AgFooter,
    AgImg,
    AgText,
    AgTextS,
  },
  emits: ['close', 'goToNext', 'buyDone'],
  props: {
    // dealInfo: {type: Object},
    modelValue: {type: Boolean},
  },
  setup(props, {emit}) {

    const gtm = useGtm()
    const {getters, commit} = useStore()
    const expectedPurchaseInfo = computed(() => getters['stakeDeal/expectedPurchaseInfo'])
    const dealInfo = computed(() => getters['stakeDeal/dealInfo'])

    watch(expectedPurchaseInfo, ()=>{
      gtm?.trackEvent({
        event: MODAL_OPEN_EVENT,
        page: `purchase-request/${dealInfo.value.partnershipShareSellId}`,
        title: `${dealInfo.value.partnershipName} 지분거래 완료 후 구매금 입금 대기 모달`,
      })
    })

    async function cancelToBuy() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                emit('close')
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups buy-confirm-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              구매를 취소하시겠어요?
            </div>
            <div class="message">
              구매 요청 내역, 조합가입 확인서 내용을 모두 삭제하고 거래를 종료합니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    async function requestToBuy(){
      try{
        const result = await requestPurchase(expectedPurchaseInfo.value.partnershipShareSellId, expectedPurchaseInfo.value.userSignId )
        console.log(result)

        if(result){
          await commit('stakeDeal/SET_COMPLETE_PURCHASE_INFO', result)
          await emit('goToNext', 'PURCHASE_COMPLETE')
        }
      }catch(e){
        await emit('goToNext', 'PURCHASE_FAIL')
      }

    }

    return {
      cancelToBuy,
      dealInfo,
      expectedPurchaseInfo,
      requestToBuy,
    }
  },
})
