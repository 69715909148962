import {getPlatforms as _getPlatforms} from '@ionic/vue'

export const getPlatform = () => {
  const platforms = _getPlatforms()
  
  const isHybrid = platforms.includes('hybrid')
  
  if (!isHybrid) {
    return 'web'
  }
  
  const isAndroid = platforms.includes('android')
  if (isAndroid) {
    return 'android'
  }

  const isIos = platforms.includes('iphone')
  
  if (isIos) {
    return 'ios'
  }
  
  return 'web'
}
