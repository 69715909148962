import {RouteRecordRaw} from 'vue-router'

export const search: RouteRecordRaw[] = [
  {
    component: process.env.VUE_APP_UI_MODE === 'ios-pass' ? () => import('@/views/Search2.vue') : () => import('@/views/Search.vue'),
    name: 'company',
    path: 'company',
  },
  {
    component: () => import('@/views/Company.vue'),
    name: 'company-detail',
    path: 'company/:stockItemId',
    props: route => ({...route.params, ...route.query}),
  },
  // 새로만드는 페이지 주소
  {
    component: () => import('@/views/search/Index.vue'),
    name: 'search',
    path: 'search/:storkId?',
  },
]
