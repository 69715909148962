import {Plugin} from 'vue'
import {useGtm} from '@gtm-support/vue-gtm'

export const CLICK_EVENT: string = 'linkclick'
export const MODAL_OPEN_EVENT: string = 'modalpage'

export interface GtmClickEvent {
  clickArea: string
  clickContent: string
  clickScreen?: string
  event?: string
}

export interface GtmModalOpenEvent {
  event?: string
  page: string
  title: string
}

export const gtmEvent: Plugin = (app) => {
  const gtm = useGtm()
  const modalOpenEvent = ({page, title}: GtmModalOpenEvent) => {
    gtm?.trackEvent({
      event: MODAL_OPEN_EVENT,
      page,
      title,
    })
  }
  const clickTrackEvent = ({clickScreen, clickArea, clickContent}: GtmClickEvent) => {
    gtm?.trackEvent({
      clickArea: clickArea,
      clickContent: clickContent,
      clickScreen: clickScreen,
      event: CLICK_EVENT,
    })
  }
  app.config.globalProperties.$clickTrackEvent = clickTrackEvent
  app.config.globalProperties.$modalOpenEvent = modalOpenEvent
}

export default gtmEvent
