
import {defineComponent, computed, onBeforeMount, ref, watch, toRef} from 'vue'
import {IonRippleEffect, popoverController, modalController} from '@ionic/vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'
import {toFixed, prettyDateToOnemonth, printContent, openLink} from '@/utils/filters'
import StarRatingInline from '@/components/Board/StarRatingInline.vue'
import PrintOgData from '@/components/Board/PrintOgData.vue'

import BoardWriteModal from '@/components/Modal/BoardWriteModal.vue'
import Popover from './Popover.vue'
export default defineComponent({
  components: {IonRippleEffect, PrintOgData, StarRatingInline},
  name: 'BoardList',
  props: {
    parent: String,
    stockItemId: null,
  },
  setup(props) {
    console.log(props)
    const selectedStockItemId = computed(() => props.stockItemId)
    const {getters, dispatch, commit} = useStore()
    const route = useRoute()
    const user = computed(() => getters['user/getUser'])
    const page = ref(0)
    const loadBoard = () => {
      if (selectedStockItemId.value) {
        page.value = 0
        dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS', {page: ++page.value, stockItemId: props.stockItemId, type: 'initAndSet'})
      }
    }

    const loadBoardMore = () => {
      dispatch('stockItem/ACT_GET_STOCK_ITEM_BOARDS', {page: ++page.value, stockItemId: props.stockItemId})
    }

    const stockItemBoards = computed(() => getters['stockItem/getStockItemBoards'])
    const stockItemBoardsCount = computed(() => getters['stockItem/getStockItemBoardsCount'])

    const hasMore = computed(() => getters['stockItem/getStockItemBoardsHasmore'])
    onBeforeMount(async () => {
      if (props.stockItemId) {
        await loadBoard()
      }
    })

    watch(selectedStockItemId, () => {
      loadBoard()
    })

    function boardLike(board: any) {
      dispatch('stockItem/ACT_LIKE_STOCK_ITEM_BOARD', {
        stockItemBoardId: board.stockItemBoardId, stockItemId: board.stockItemId, target: 'stockItemBoards',
      })
    }

    function boardClick(data: any, event: any) {
      if (event.target.tagName === 'A') {
        openLink(event.target.dataset.href)
      }
    }

    const openModal = async (stockItemBoard: any = null) => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: BoardWriteModal,
          componentProps: {
            stockItemBoard,
            stockItemId: stockItemBoard.stockItemId.toString(),
          },
          cssClass: 'board-write-modal',
          id: 'BoardWriteModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      return modal.present()
    }

    const openPopover = async (stockItemBoard: any = null, event_: Event) => {
      const popover = await popoverController
        .create({
          component: Popover,
          componentProps: {
            stockItemBoard,
            stockItemId: stockItemBoard.stockItemId.toString(),
          },
          
          cssClass: 'more-vertical',
          // cssClass: 'my-custom-class',
event: event_,
          translucent: true,
        })
      return popover.present()
    }

    const parentRef = toRef(props, 'parent')

    return {
      boardClick,
      boardLike,
      clickScreen:parentRef.value,
      hasMore,
      loadBoard,
      loadBoardMore,
      openLink,
      openModal,
      openPopover,
      prettyDateToOnemonth,
      printContent,
      selectedStockItemId,
      stockItemBoards,
      stockItemBoardsCount,
      toFixed,
      user,
    }
  },
})
