
import {
  IonPage,
  IonToolbar,
  IonHeader,
  IonContent,
  IonSearchbar,
  modalController,
  IonRippleEffect,
} from '@ionic/vue'
import {defineComponent, reactive, ref, computed, onBeforeMount, onUpdated, onActivated, getCurrentInstance} from 'vue'
import {searchOutline, arrowDownOutline} from 'ionicons/icons'
import {useStore} from 'vuex'
import GroupBuy from '@/components/Company/GroupBuy.vue'
import * as search from '../../public/assets/icon/search.png'
import * as sorting from '../../public/assets/icon/sorting.png'
import * as selectStar from '../../public/assets/icon/star_select.png'
import * as unselectStar from '../../public/assets/icon/star_unselect.png'
import {useRoute} from 'vue-router'
import SellItemModal from '@/components/Modal/SellItemModal.vue'
import {store} from '@/vuex'
import useModal from '@/composables/useModal'
import {useBlur} from '@/composables'
import dayjs from 'dayjs'
import {AgRefresher, AgButtonS, AgTextS, AgBoxS} from '@/components/ag'
import LinkAccountModal from '@/views/view/link-account/LinkAccountModal.vue'
import RegistSellStakeModal from '@/views/view/regist-sell-stake/RegistSellStakeModal.vue'
import ViewMyStake from '@/views/view/view-my-stake/ViewMyStake.vue'
import PopularShareTransactionModal from '@/views/view/home/popular-share-transaction/PopularShareTransactionModal.vue'
import StakeListsModal from '@/views/view/stake-lists/StakeListsModal.vue'
import {numberToKorean} from '@/utils/filters'
const useUpdatedNow = () => {
  const nowRef = ref(dayjs())

  onUpdated(() => {
    nowRef.value = dayjs()
  })

  return nowRef
}

export default defineComponent({
  components: {
    AgBoxS,
    AgButtonS,
    AgRefresher,
    AgTextS,
    GroupBuy,
    IonContent,
    IonHeader,
    IonPage,
    IonRippleEffect,
    IonSearchbar,
    IonToolbar,
    LinkAccountModal,
    PopularShareTransactionModal,
    RegistSellStakeModal,
    StakeListsModal,
    ViewMyStake,
  },
  setup() {
    const app = getCurrentInstance()
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const {getters, commit, dispatch} = useStore()
    const reValue = reactive({displayToggle: false, popularList: null, recentList: null})
    const {useCompanyModal} = useModal()
    const stockItems = computed(() => getters['stockItem/getStockItems'])
    const popularStockItem = computed(() => getters['stockItem/getPopularStockItem'])
    const recentViewList = computed(() => getters['stockItem/getRecentViewList'])
    const searchStockItems = ref(stockItems.value)
    const user = computed(() => getters['user/getUser'])
    const route = useRoute()
    const now = useUpdatedNow()
    const formattedNow = computed(() => dayjs(now.value).format('MM.DD HH:mm'))
    const viewShareTransactionOpen = ref<boolean>(false)

    async function reloadFromDealStorage() {
      dispatch('stockItem/ACT_GET_POPULARITY')
      dispatch('home/ACT_GET_FIVE_POPULAR_SHARE_TRANSACTION')
      if (user.value) {
dispatch('stakeDeal/GET_MY_ALL_STAKELIST')
}
      commit('stockItem/SET_INIT_RECENT_VIEW_STOCK_ITEM')
      if (route.hash) {
        // scrollToHash()
      }
    }

    const searchInput = ref('')

    async function filterKeyword(query: any) {
      reValue.displayToggle = !((query === null || query === ''))
      searchStockItems.value = reValue.displayToggle ? stockItems.value.filter((item: any) => ~item.stockItemName.indexOf(query) || (item.codeNumber && ~item.codeNumber.indexOf(query))) : []
    }
    async function searchKeyword(event: any) {
      const query = event.target.value
      searchInput.value = query
      if(query){
        $clickTrackEvent({'clickArea':'검색영역', 'clickContent':query, clickScreen})
      }
      await filterKeyword(query)
    }

    function getPopularStockItems(popularStockItem: any) {
      if(!popularStockItem || !stockItems.value) {
return []
}
      let popList: any = []
      popularStockItem.forEach((id: string) => stockItems.value.find((stock: any) => {
        if(stock.stockItemId.toString() === id.toString()){
          popList.push(stock)
        }
      }))
      return popList
    }

    const getRecentStockItems = computed(()=>{
      const temporary = JSON.parse(JSON.stringify(recentViewList.value)).reverse()
      let popList: any = []
      temporary.forEach((id: any) => stockItems.value.find((stock: any) => {
        if(stock.stockItemId.toString() === id.toString()){
          popList.push(stock)
        }
      }))
      return popList
    })

    onActivated(() => {
      console.log('active')
    })

    onBeforeMount(async () => {
      await Promise.all(
          [
            store.dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS'),
            store.dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS'),
            reloadFromDealStorage(),
          ],
      )
    })

    // no use
    const starIcon = selectStar
    // no use
    const unstarIcon = unselectStar
    // no use
    const sortIcon = sorting
    // no use
    const searchIcon = search

    const activeClass = 'deal-toggle'
    const itemLike = async (stockItemId: any) => {
      const item = await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
      const index = searchStockItems.value.findIndex((stock: any) => stock.stockItemId === item.stockItem.stockItemId)
      if (index > -1) {
        searchStockItems.value[index].likeFlag = item.stockItem.likeFlag
      }

      // searchStockItems
    }
    const searchCleared = () => {
      if (searchInput.value === '') {
        onBlur()
      }
    }
    const openSellModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: SellItemModal,
          componentProps: {},
          cssClass: 'sell-item-modal',
          id: 'SellItemModal',

          // mode: 'ios',
          keyboardClose: true,

          swipeToClose: true,
        })
      return modal.present()
    }

    const doRefresh = async (event: any) => {
      await reloadFromDealStorage()
      await Promise.all(
        [
          store.dispatch('partnership/ACT_GET_ALL_PARTNERSHIPS'),
          store.dispatch('stockItem/ACT_GET_ALL_STOCK_ITEMS'),
        ],
      )
      event.target.complete()
    }

    const onBlur = useBlur()

    const onSubmit = () => {
      onBlur()
    }

    /* 조합지분거래 코드 */
    const myAllStakeList = computed(() => getters['stakeDeal/myAllStakeList'])
    const isAvaliable = computed(() => myAllStakeList.value && myAllStakeList.value.length)
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const popularShareTransaction = computed(() => getters['home/getFivePopularShareTransaction'])
    const popularStakeList = computed(() => popularShareTransaction.value.popularShareTransactions)
    const stakeSellOpen = ref<any>(false)
    const viewMyStakeOpen = ref<any>(false)
    const linkAccountModal = ref<any>(false)

    const branchResult = () => {
      stakeSellOpen.value = false
      viewMyStakeOpen.value = true
    }

    const selectSearchCompany = async (stock: any) =>{
      $clickTrackEvent({'clickArea':'검색결과', 'clickContent':stock.stockItemName, clickScreen})
      await dispatch('stockItem/ACT_LOG_STOCK_ITEM_SEARCH', {stockItemId: stock.stockItemId})
      await useCompanyModal(stock.stockItemId)
    }

    const stakeListOpen = ref<boolean>(false)
    const SellOpenType = ref<string>('')
    const afterLinked = ref<string>('')
    const checkIsLinked = async () => {
      afterLinked.value = 'sell'
      SellOpenType.value = 'create'

      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
        linkAccountModal.value = true
      } else if (currentStep.value === 'COMPLETE') {
        stakeSellOpen.value = true
      }
    }
    const openModalFromPopularShareTransaction = async (target: string) => {
      if(target==='shareSellAll'){
        stakeListOpen.value = true
      }else if(target==='sellMyShareSell'){
        await checkIsLinked()
      }

    }

    //gtm event screen name
    const clickScreen = 'search'

    return {
      activeClass,
      arrowDownOutline,
      branchResult,
      checkIsLinked,
      clickScreen,
      doRefresh,
      formattedNow,
      getPopularStockItems,
      getRecentStockItems,
      isAvaliable,
      itemLike,
      linkAccountModal,
      myAllStakeList,
      numberToKorean,
      onBlur,
      onSubmit,
      openModalFromPopularShareTransaction,
      openSellModal,
      popularStakeList,
      popularStockItem,
      reValue,
      recentViewList,
      searchCleared,
      searchIcon,
      searchInput,
      searchKeyword,
      searchOutline,
      searchStockItems,
      selectSearchCompany,
      sortIcon,
      stakeListOpen,
      stakeSellOpen,
      starIcon,
      unstarIcon,
      useCompanyModal,
      user,
      viewMyStakeOpen,
      viewShareTransactionOpen,
    }
  },
})
