import {Module} from 'vuex'
import {RootState} from '..'
import {getLinkStatus, actionLinkStatusApi, getMyStakeList, getSelectedStakeInfo, getMyAllStakeList, getAllStakeList, getCompanyStakeList, getPurchaseInfo} from '@/api/stake-deal'
import {openLinkWithAccessToken} from '@/utils/filters'
import {API_URL} from '@/config'

// state
interface StakeDeal {
  action: any
  allStakeList: any
  buyResult: any
  completePurchaseInfo: any
  currentStep: string
  dealInfo: any
  expectedPurchaseInfo: any
  message: string
  myAllStakeList: any
  myStakeList: any
  negostockUrl: string
  negostockUserInfo: any
  purchaseInfo: any
  registerResult: any
  requestId: any
  selectCompany: any
  stakeList: any
}

// action
const StakeDeal: Module<StakeDeal, RootState> = {
  actions: {
    async GET_ALL_STAKELIST({commit}, page = 1) {
      const list = await getAllStakeList(page)
      commit('SET_ALL_STAKELIST', list)
    },
    async GET_LINK_STATUS({commit}) {
      const result = await getLinkStatus()
      commit('SET_CURRENT_STEP', result.currentStep)
      commit('SET_MESSAGE', result.message)
      commit('SET_ACTION', result.action)
      commit('SET_NEGOSTOCK_USER_INFO', result.negostockUserInfo)
    },
    async GET_MY_ALL_STAKELIST({commit}) {
      const list = await getMyAllStakeList()
      commit('SET_MY_ALL_STAKELIST', list)
    },
    async GET_MY_STAKELIST({commit}, stockItemId) {
      const list = await getMyStakeList(stockItemId)
      commit('SET_MY_STAKELIST', list)

      // if (list.length > 0) {
      //   commit('SET_SELECTED_DEAL', list[0])
      // }
    },
    async GET_PURCHASE_INFO({commit}, partnershipShareSellId) {
      const list = await getPurchaseInfo(partnershipShareSellId)
      commit('SET_PURCHASE_INFO', list.letterOfConfirmationContentsDTO)
    },
    async GET_SELECTED_STAKE_INFO({commit}, item) {
      const result = await getSelectedStakeInfo(item.partnershipId, item.stockItemId)
      if (result.length > 0) {
        commit('SET_SELECTED_DEAL', result[0])
      }
    },
    async GET_STAKELIST({commit}, stockItemId, page=1) {
      const list = await getCompanyStakeList(stockItemId, page, 500, 'saleCompleted')
      commit('SET_STAKELIST', list)

      // if (list.length > 0) {
      //   commit('SET_SELECTED_DEAL', list[0])
      // }
    },
    async NEXT_STEP_ACTION({state}) {
      if (state.action.type === 'API') {
        const result = await actionLinkStatusApi(state.action.endPoint, state.action.method)
      }
    },
    SELECT_DEAL({commit}, dealInfo) {
      commit('SET_SELECTED_DEAL', dealInfo)
    },
  },

  getters: {
    action: state => state.action,
    allStakeList: state => state.allStakeList,
    availableStakeList: state => state.allStakeList.filter((s: any)=>~['onSale', 'inTransaction'].indexOf(s.status)),
    buyResult: state => state.buyResult,
    completePurchaseInfo: state => state.completePurchaseInfo,
    currentStep: state => state.currentStep,
    dealInfo: state => state.dealInfo,
    expectedPurchaseInfo: state => state.expectedPurchaseInfo,
    message: state => state.message,
    myAllStakeList: state => state.myAllStakeList,
    myStakeList: state => state.myStakeList,
    negostockUrl: state => state.negostockUrl,
    negostockUserInfo: state => state.negostockUserInfo,
    purchaseInfo: state => state.purchaseInfo,
    registerResult: state => state.registerResult,
    requestId: state => state.requestId,
    selectCompany: state => state.selectCompany,
    stakeList: state => state.stakeList,
  },

  // RootState와 똑같이 작성해도 된다.
  mutations: {
    SET_ACTION(state, action: any) {
      state.action = action
    },
    SET_ALL_STAKELIST(state, allStakeList: any) {
      state.allStakeList = allStakeList
    },
    SET_BUY_RESULT(state, buyResult: any) {
      state.buyResult = buyResult
    },
    SET_COMPLETE_PURCHASE_INFO(state, completePurchaseInfo: any) {
      state.completePurchaseInfo = completePurchaseInfo
    },
    SET_CURRENT_STEP(state, currentStep: string) {
      state.currentStep = currentStep
    },
    SET_EXPECTED_PURCHASE_INFO(state, expectedPurchaseInfo: any) {
      state.expectedPurchaseInfo = expectedPurchaseInfo
    },
    SET_MESSAGE(state, message: string) {
      state.message = message
    },
    SET_MY_ALL_STAKELIST(state, stakeList: any) {
      state.myAllStakeList = stakeList
    },
    SET_MY_STAKELIST(state, myStakeList: any) {
      state.myStakeList = myStakeList
    },
    SET_NEGOSTOCK_URL(state, negostockUrl: any) {
      state.negostockUrl = negostockUrl
    },
    SET_NEGOSTOCK_USER_INFO(state, negostockUserInfo: any) {
      state.negostockUserInfo = negostockUserInfo
    },
    SET_PURCHASE_INFO(state, purchaseInfo: any) {
      state.purchaseInfo = purchaseInfo
    },
    SET_REGISTER_RESULT(state, registerResult: any) {
      state.registerResult = registerResult
    },
    SET_REQUEST_ID(state, requestId: any) {
      state.requestId = requestId
    },
    SET_SELECTED_DEAL(state, dealInfo: any) {
      state.dealInfo = dealInfo
    },
    SET_SELECT_COMPANY(state, stock: any) {
      state.selectCompany = stock
    },
    SET_STAKELIST(state, stakeList: any) {
      state.stakeList = stakeList
    },
  },
  namespaced: true,
  state: {
    action: {},
    allStakeList: [],
    buyResult: {},
    completePurchaseInfo: {},
    currentStep: 'DealInfo',
    dealInfo: {},
    expectedPurchaseInfo: {},
    message: '',
    myAllStakeList: {},
    myStakeList: {},
    negostockUrl: '',
    negostockUserInfo: {},
    purchaseInfo: {},
    registerResult: {},
    requestId: '',
    selectCompany: {},
    stakeList: [],
  },
}

export default StakeDeal
