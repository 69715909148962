
import {
  IonPage,
  IonContent,
  modalController,
  alertController,
  popoverController,
  IonProgressBar,
  IonFooter,
  IonToolbar,
  IonRefresher, IonRefresherContent,
  toastController, IonRippleEffect,
} from '@ionic/vue'
import {arrowDownOutline} from 'ionicons/icons'
import {defineComponent, computed, onBeforeMount, onUpdated, watch, ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import CompanyValue from '@/components/Popover/CompanyValue.vue'
import Fee from '@/components/Popover/Fee.vue'
import FAQModal from '@/components/Modal/FAQModal.vue'
import {useStore} from 'vuex'
import apiChannel from '@/api/open-channel'
import Channel from '@/vuex/store/open-channel'
import * as filter from '../../utils/filters'
import {set} from '@/composables/useDealStorage'
import router from '@/router'
import ClubDealInfo from '@/components/Modal/ClubDealInfo.vue'
import apiPartnership from '@/api/partnership'
import {SendBirdAction} from '@/plugins/SendBirdAction'
import useModal from '@/composables/useModal'
const {delPartnershipRequests} = apiPartnership()
export default defineComponent({
  components: {
    IonContent,
    IonFooter,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRippleEffect,
    IonToolbar,
  },
  name: 'ClubDealOpen',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const {dispatch, getters, commit} = useStore()
    const {postOpenChannel} = apiChannel()
    const {useClubDealOpenJoinModal, useCompanyModal, useClubDealProgressModal} = useModal()

    let partnershipId = route.params.clubdeal_id.toString()
    // Partnership status = prepare
    // 리드엔젤 아니면 참여 불가능 뜨게

    async function initPage() {
      await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', route.params.clubdeal_id)
    }
    const fromCompany = ref(false)
    watch(
      () => route.params,
      async newParams => {
        if (newParams.clubdeal_id) {
          // console.log("route.params", newParams)
          partnershipId = newParams.clubdeal_id.toString()
          fromCompany.value = (route.query.from === 'company')
          await initPage()
        }
      },
    )

    onBeforeMount(async () => {
      if (route.params.clubdeal_id) {
        fromCompany.value = (route.query.from === 'company')
        await initPage()
      }
    })

    // onUpdated(async() => {
    //   // console.log(":::ClubDealOpen onUpdated:::")
    //   if(route.params.clubdeal_id){
    //     await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', route.params.clubdeal_id);
    //   }
    // });

    const doRefresh = async (event: any) => {
      // console.log('Begin async operation');
      if (route.params.clubdeal_id) {
        await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', route.params.clubdeal_id)
      }
      event.target.complete()
    }

    const hasSession = () => {
      return getters['user/hasSession']
    }
    const partnershipInfo = computed(() => getters['partnership/getPartnershipOpen'])

    const boardTotalInfo = computed(() => partnershipInfo.value.boardTotalInfo || {})
    const recommendPartnership = computed(() => partnershipInfo.value.recommendPartnerships || {})

    const currentValuation = computed(() => filter.numberToKorean(partnershipInfo.value.currentValuation))
    const availableAssetFrom = computed(() => filter.numberToKorean(partnershipInfo.value.availableAssetFrom))
    const availableAssetTo = computed(() => filter.numberToKorean(partnershipInfo.value.availableAssetTo))

    let popover: any = null
    const openPopover = async (event_: Event) => {
      if (popover) {
return
}
      popover = await popoverController
        .create({
          component: CompanyValue,
          componentProps: {
            onClick: () => {
              popover.dismiss()
            },
          },
          cssClass: 'company-value',
          event: event_,
          translucent: false,
        })

      popover.onDidDismiss().then((result: any) => {
        popover = null
      })
      return popover.present()
    }

    let popoverFee: any = null
    const openPopoverFee = async (event_: Event) => {
      if (popoverFee) {
return
}
      popoverFee = await popoverController
        .create({
          component: Fee,
          componentProps: {
            onClick: () => {
              popoverFee.dismiss()
            },
          },
          cssClass: 'company-value',
          event: event_,
          translucent: false,
        })

      popoverFee.onDidDismiss().then((result: any) => {
        popoverFee = null
      })
      return popoverFee.present()
    }

    // onBeforeMount(() => {
    //   if(partnershipInfo.value.sellPriceNegoFlag) {
    //     apiChannel().getChannelList();
    //   }
    // })

    const handleScroll = async (event: any) => {
      const main: any = document.querySelector('#open__main_container')
      const button: any = document.querySelector('#open__scroll_top_btn')
      const scroll = await event.getScrollElement()

      if (scroll.scrollTop > main.clientHeight) {
        button.classList.add('show')
      } else {
        button.classList.remove('show')
      }
    }

    const scrollTop = async () => {
      const content: any = document.querySelector('#open__ion_content')
      content.scrollToPoint(0, 0)
    }

    async function openClubDeal() {
      await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', Number(partnershipId))
      await useClubDealOpenJoinModal(Number(partnershipId), partnershipInfo.value.partnershipOpenChannelUrl, partnershipInfo.value.partnershipOpenChannelId)
      // router.push({ name : 'ClubDealOpenJoin'
      //   , params: { clubdealId : partnershipId, }
      //   , query:{ channelUrl:partnershipInfo.value.partnershipOpenChannelUrl
      //     , partnershipOpenChannelId: partnershipInfo.value.partnershipOpenChannelId

      //   }});
    }

    const notLeadAngelAlert = async () => {
      const alert = await alertController
        .create({
          buttons: ['확인'],
          cssClass: 'lead-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
            리드엔젤만<br>
            클럽딜을 열 수 있습니다
            </div>
            <div class="message">아직 리드엔젤이 되지 못하셨어요 😢</div>
          </div>
          `,
        })
      return alert.present()
    }

    const openAlert = async () => {
      if (partnershipInfo.value.validLeadAngel) {
        await openClubDeal()
      } else {
        await notLeadAngelAlert()
      }
    }

    const createModal = async (e: any) => {
      // console.log(e.target.id);

      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: FAQModal,
          componentProps: {
            type: e.target.id,
          },
          cssClass: 'faq-modal',
          id: 'FAQModal',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })
      return modal.present()
    }

    async function priceNegoFunc() {
      if (!partnershipInfo.value.validLeadAngel) {
        return notLeadAngelAlert()
      }

      if (partnershipInfo.value.partnershipOpenChannelUrl) {
        await router.push({name: 'ClubDealBuyChat', params: {channelUrl: partnershipInfo.value.partnershipOpenChannelUrl}})
      } else {
        try {
          const channel = await postOpenChannel(Number.parseInt(partnershipId))
          if (channel) {
            await router.push({name: 'ClubDealBuyChat', params: {channelUrl: channel.channelUrl}})
          } else {
            // console.log('이미 생성된 채널');

          }
        } catch {
          // console.log(e);
        }
      }
    }

    async function showNotYet() {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: ClubDealInfo,
          componentProps: {
            anotherNotYetProps: partnershipInfo.value.anotherNotYet,
            partnershipName: partnershipInfo.value.partnershipName,
          },
          cssClass: 'clubdeal-info',
          id: 'ClubDealInfo',
          keyboardClose: true,
          mode: 'ios',
          swipeToClose: true,
        })

      modal.onDidDismiss().then(async (data: any) => {
        if (data.data === 'cancelJoin') {
          await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', route.params.clubdeal_id)
        }
      })

      return modal.present()
    }

    async function sendCancelClubDealMessage(message: string) {
      // console.log(message);
      if (!message) {
return
}
      const sendbirdAction: any = await SendBirdAction.getInstance()
      sendbirdAction
        .getChannel(partnershipInfo.value.partnershipOpenChannelUrl, false)
        .then((channel: any) => {
          sendbirdAction.sendUserMessage({
            channel: channel,
            handler: (message: any, error: any) => {
              // console.log(message, error);
            },
            message: message,
            status: 'cancelToSeller',
          })
        })
    }

    async function cancelRequest() {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              handler: () => {
                // console.log('Cancel clicked');
              },
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                // console.log("삭제하기 클릭");
                const message = await delPartnershipRequests(partnershipInfo.value.joinPartnershipRequest.partnershipRequestId, Number(partnershipId))

                await dispatch('partnership/ACT_GET_PARTNERSHIPS_OPEN', route.params.clubdeal_id)
                const toast = await toastController
                  .create({
                    duration: 1000,
                    message: '클럽딜 참여가 취소되었습니다.',
                    position: 'middle',
                  })
                if (partnershipInfo.value.partnershipOpenChannelId) {
                  await sendCancelClubDealMessage(message.channelMessage)
                }
                return toast.present()
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups suggest-alert-class',
          message: `
            <div class="alert_css">
              <div class="header">
                이 클럽딜 참여를<br>
                취소하시겠어요?
              </div>
              <div class="message">
                입력하신 모든 정보가 삭제되며,<br/>
                다른 클럽딜에 참여하실 수 있게 됩니다.
              </div>
            </div>`,
        })
      return alert.present()
    }

    return {
      arrowDownOutline,
      availableAssetFrom,
      availableAssetTo,
      boardTotalInfo,
      cancelRequest,
      createModal,
      currentValuation,
      doRefresh,
      filter,
      fromCompany,
      handleScroll,
      hasSession,
      openAlert,
      openPopover,
      openPopoverFee,
      partnershipInfo,
      priceNegoFunc,
      recommendPartnership,
      route,
      router,
      scrollTop,
      showNotYet,
      useClubDealProgressModal,
      useCompanyModal,
    }
  },
})
