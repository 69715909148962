import {ExtractPropTypes, FunctionalComponent, h} from 'vue'
import {StyledColumnSection} from './StyledColumnSection'

export const columnSectionProps = {
  columnAs: null,
}

export const ColumnSection: FunctionalComponent<ExtractPropTypes<typeof columnSectionProps>> = (
  props, {slots},
) => {
  const {columnAs} = props
  const topSlot = slots.top?.()
  const bottomSlot = slots.bottom?.()
  const hasColumn = Boolean(topSlot || bottomSlot)

  if (hasColumn) {
    return (
      h(StyledColumnSection, {as: columnAs}, () => [
        topSlot,
        slots.default?.(),
        bottomSlot,
      ])
    )
  }
  return slots.default?.()
}

ColumnSection.props = columnSectionProps
