import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'
import {Property} from 'csstype'

export const transitionSystem = system({
  transition: {
    property: 'transition',
    scale: 'transitions',
  },
})

export type TransitionType = Property.Transition

export const transitionProp = getResponsiveProp<TransitionType>()
