import {styled} from '@/plugins/emotion'
import {AlertButton, AlertInput, AnimationBuilder, Mode} from '@ionic/core'
import {IonAlert} from '@ionic/vue'
import {FunctionalComponent, h, PropType} from 'vue'

export const ionAlertProps = {
  animated: {type: Boolean},
  backdropDismiss: {type: Boolean},
  buttons: {type: Array as PropType<AlertButton[]>},
  enterAnimation: {type: Function as PropType<AnimationBuilder>},
  id: {type: String},
  inputs: {type: Array as PropType<AlertInput[]>},
  keyboardClose: {type: Boolean},
  leaveAnimation: {type: Function as PropType<AnimationBuilder>},
  message: {type: String},
  mode: {type: String as PropType<Mode>},
  translucent: {type: Boolean},
}

export const styledAlertProps = {
  ...ionAlertProps,
  cssClass: null,
  header: {type: String},
  isOpen: {type: Boolean},
  subHeader: {type: String},
}

const IonAlertWrapper: FunctionalComponent = (props, {attrs, slots}) => {
  return h(IonAlert, {...attrs, ...props, cssClass: attrs.class}, () => slots.defailt?.())
}

export const StyledAlert = styled(IonAlertWrapper, {
  stylePortal: 'css',
})({
  '& .alert-button-group': {
    display: 'flex',
    flexDirection: 'row',
  },
  '& .alert-head': {
    fontSize: 'f18',
    fontWeight: 700,
    lineHeight: 'l26',
    paddingBottom: 'md',
    paddingTop: 'mld',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    wordBreak: 'keep-all',
  },
  '& .alert-message': {
    color: 'dark',
    fontSize: 'f14',
    fontWeight: 400,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    wordBreak: 'keep-all',
  },
  '& button .alert-button-inner': {
    fontSize: 'f14',
    fontWeight: 700,
    lineHeight: 'l22',
    marginBottom: 0,
    paddingBottom: 'mld',
  },
  '& button.alert-button.sc-ion-alert-md': {
    borderRadius: 0,
    flexGrow: 1,
    width: 'auto',
  },
  '& button.gray': {
    backgroundColor: '#EEF1F7',
  },
  '& button.gray>span': {
    color: '#5E6068',
  },
})
