export const isShowPriceFeature = () => {
  return Boolean(process.env.VUE_APP_PRICE_FEATURE)
}

export const inShowSeFeature = () => {
  return Boolean(process.env.VUE_APP_SE_FEATURE)
}

export const appUniversalInstallUrl = () => {
  return process.env.VUE_APP_APP_UNIVERSAL_INSTALL_URL ?? 'https://abr.ge/ugtegy'
}

export const allowDomain = () => {
  return process.env.VUE_APP_ALLOW_DOMAIN ?? 'angelleague.io'
}

export const nextAppUrl = () => {
  return process.env.VUE_APP_NEXT_APP_URL ?? 'https://go.angelleague.io'
  // return 'https://go.angelleague.io'
}

export const apiUrl = () => {
  return process.env.VUE_APP_API_URL ?? 'https://back.angelleague.io'
}


export const NftData = () => {
  return {
    link: {
      url: 'https://abit.ly/nft',
    },
  }
}
