import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_text_s = _resolveComponent("ag-text-s")!
  const _component_ag_box_s = _resolveComponent("ag-box-s")!
  const _component_ag_img = _resolveComponent("ag-img")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_box_s, { css: {dp: 'flex', alignItems: 'center', direction: 'column', bg: 'purple1', color: 'white', height: '100%'} }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_text_s, { css: {typography: 'tit20b', mb: 16, mt: 35} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.text.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ag_text_s, { css: {typography: 'main16r', mb: 16, textAlign: 'center', mx: 40} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.text.subtitle), 1)
            ]),
            _: 1
          }),
          (_ctx.text.timeMessage)
            ? (_openBlock(), _createBlock(_component_ag_text_s, {
                key: 0,
                css: {typography: 'num16bk'}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.text.timeMessage), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ag_box_s, { css: {mb: 16} }, {
            default: _withCtx(() => [
              (_ctx.text.timeStart)
                ? (_openBlock(), _createBlock(_component_ag_text_s, {
                    key: 0,
                    css: {typography: 'num16bk'}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.text.timeStar), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ag_text_s, { css: {typography: 'num16bk'} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(` ~ ${_ctx.text.timeEnd}`), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ag_text_s, {
            css: {typography: 'main16r', mb: 40},
            color: "light"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.text.message), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ag_img, {
            src: _ctx.images.intro,
            height: "294",
            sizes: "",
            width: "300"
          }, null, 8, ["src"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}