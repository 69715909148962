import {AgModal} from '@/components/ag'
import {storageRef} from '@/composables'
import dayjs from 'dayjs'
import {computed, defineComponent, h, toRef} from 'vue'

export interface ProhibitInfo {
  id?: number
  timestamp?: number
}

export const BannerModal = defineComponent({
  emits: ['update:open'],
  name: 'BannerModal',
  props: {
    id: {default: 0, type: Number},
    maxProhibitDay: {default: 3, type: Number},
    open: {default: false, type: Boolean},
  },
  setup(props, {slots, emit}) {
    const openRef = toRef(props, 'open')
    const idRef = toRef(props, 'id')
    const maxProhibitDayRef = toRef(props, 'maxProhibitDay')
    const prohibitRef = storageRef<undefined | ProhibitInfo>('prohibit-banner')

    const dayOutRef = computed(() => {
      const prohibit = prohibitRef.value
      if (!prohibit) {
        return true
      }

      const {id, timestamp} = prohibit

      if (id !== idRef.value) {
        return true
      }

      const now = dayjs()
      return now.valueOf() > Number(timestamp)
    })

    const realOpenRef = computed(() => {
      return Boolean(openRef.value && dayOutRef.value)
    })

    const prohibitClose = (day?: number) => {
      // bannerIdRef.value = bannerId
      const now = dayjs()
      const maxDay = day ?? maxProhibitDayRef.value
      const next = now.add(maxDay, 'day')
      prohibitRef.value = {
        id: idRef.value,
        timestamp: next.valueOf(),
      }
      emit('update:open', false)
    }

    const close = () => {
      emit('update:open', false)
    }

    const cleanProhibit = () => {
      prohibitRef.value = undefined
    }

    return () => (
      h(AgModal, {modelValue: realOpenRef.value}, () => slots.default?.({cleanProhibit, close, prohibitClose}))
    )
  },
})
