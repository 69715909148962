
import {useBlur} from '@/composables'
import {IonContent} from '@ionic/vue'
import {defineComponent, PropType} from 'vue'
import SearchStockItem from './SearchStockItem.vue'
import {Stock} from '../types'

export default defineComponent({
  components: {
    IonContent,
    SearchStockItem,
  },
  emits: ['select'],
  props: {
    list: Array as PropType<Array<Stock>>,
  },
  setup() {
    const onBlur = useBlur()

    return {
      onBlur,
    }
  },
})
