import {defineComponent, h} from 'vue'
import {Canvas, canvasProps} from './components'

export const AgSignaturePad = defineComponent({
  name: 'AgSignaturePad',
  props: {
    ...canvasProps,
  },
  setup(props, {attrs}) {
    return () => (
      h(Canvas, {...attrs, ...props})
    )
  },
})
