import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_header = _resolveComponent("ag-header")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    height: "auto",
    "model-value": _ctx.isOpen,
    "safe-area": true,
    "swipe-to-close": true,
    "onUpdate:modelValue": _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_header, { handle: true }),
      _createVNode(_component_modal_header, {
        title: "내가 등록한 매물 확인",
        onClose: _ctx.setOpen
      }, null, 8, ["onClose"]),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalPage), {
        onClose: _ctx.setOpen,
        onEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit'))),
        onReload: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reload')))
      }, null, 8, ["onClose"]))
    ]),
    _: 1
  }, 8, ["model-value", "onUpdate:modelValue"]))
}