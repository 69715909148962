import {Property} from 'csstype'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'

export type GridType = Property.Grid
export type ColumnGapType = Property.ColumnGap
export type GridAutoColumnsType = Property.GridAutoColumns
export type GridAutoFlowType = Property.GridAutoFlow
export type GridAutoRowsType = Property.GridAutoRows
export type GridTemplateType = Property.GridTemplate
export type GridTemplateAreasType = Property.GridTemplateAreas
export type GridTemplateColumnsType = Property.GridTemplateColumns
export type GridTemplateRowsType = Property.GridTemplateRows
export type GridColumnEndType = Property.GridColumnEnd
export type GridColumnStartType = Property.GridColumnStart
//
export type GridRowStartType = Property.GridRowStart
export type GridRowEndType = Property.GridRowEnd
export type GridRowType = Property.GridRow
export type GridColumnType = Property.GridColumn
//
export type RowGapType = Property.RowGap

export const gridSystems = system({
  columnGap: true,
  grid: true,
  gridAutoColumns: true,
  gridAutoFlow: true,
  gridAutoRows: true,
  gridColumn: true,
  gridColumnEnd: true,
  gridColumnStart: true,
  gridRow: true,
  gridRowEnd: true,
  gridRowStart: true,
  gridTemplate: true,
  gridTemplateAreas: true,
  gridTemplateColumns: true,
  gridTemplateRows: true,
  rowGap: true,
})

export const gridProps = {
  columnGap: getResponsiveProp<ColumnGapType>(),
  grid: getResponsiveProp<GridType>(),
  gridAutoColumns: getResponsiveProp<GridAutoColumnsType>(),
  gridAutoFlow: getResponsiveProp<GridAutoFlowType>(),
  gridAutoRows: getResponsiveProp<GridAutoRowsType>(),
  gridColumn: getResponsiveProp<GridColumnType>(),
  gridColumnEnd: getResponsiveProp<GridColumnEndType>(),
  gridColumnStart: getResponsiveProp<GridColumnStartType>(),
  gridRow: getResponsiveProp<GridRowType>(),
  gridRowEnd: getResponsiveProp<GridRowEndType>(),
  gridRowStart: getResponsiveProp<GridRowStartType>(),
  gridTemplate: getResponsiveProp<GridTemplateType>(),
  gridTemplateAreas: getResponsiveProp<GridTemplateAreasType>(),
  gridTemplateColumns: getResponsiveProp<GridTemplateColumnsType>(),
  gridTemplateRows: getResponsiveProp<GridTemplateRowsType>(),
  rowGap: getResponsiveProp<RowGapType>(),
}
