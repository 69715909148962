import {useSaltShuffle} from '@/composables'
import {defineComponent, h, toRef, VNode} from 'vue'
import {AgBoxS} from '../ag-box'
import {Button, ButtonSlotProps} from './components'

export interface AgAlphabetKeypadDefaultSlotProps {
  children: VNode
  index: number
  key: string
  onClick: (key: string) => any
}

const DEFAULT_KEY_LENGTH = 26
const KEY_OFFSET = 65
const LAST_FIRST_ITEM_INDEX = 21

/**
 * alphabet keyList need to define once
 */
const keyList = Array(DEFAULT_KEY_LENGTH).fill(null).map((_, index) => {
  return String.fromCharCode(index + KEY_OFFSET)
})

export const agAlphabetKeypadProps = {
  keyList: {
    default: () => keyList,
    type: Array,
  },
  salt: {
    default: '1',
    type: String,
  },
  shuffle: {
    default: true,
    type: Boolean,
  },
}

/**
 * Alphabet keyPad. It can be shuffled
 */
export const AgAlphabetKeypad = defineComponent({
  emits: ['click', 'delete'],
  inheritAttrs: false,
  name: 'AgAlphabetKeypad',
  props: {
    // ...agAlphabetKeypadProps,
    keyList: {
      default: () => keyList,
      type: Array,
    },
    salt: {
      default: '1',
      type: String,
    },
    shuffle: {
      default: true,
      type: Boolean,
    },
  },
  setup(props, {
    emit,
    slots,
    attrs,
  }) {
    const keyListRef = toRef(props, 'keyList')
    const saltRef = toRef(props, 'salt')
    const shuffleRef = toRef(props, 'shuffle')
    const shuffledKeyListRef = useSaltShuffle(keyListRef, saltRef, shuffleRef)

    const onClick = (key: string) => {
      emit('click', key)
    }

    const createOnClick = (key: string) => () => {
      onClick(key)
    }

    const onDelete = () => {
      emit('delete')
    }

    return () => {
      const _props: any = {...props}
      _props.keyList = undefined
      _props.salt = undefined
      _props.shuffle = undefined
      const css: Record<string, any> = attrs.css as any ?? {}

      const newCss = {
        alignContent: 'space-between',
        alignItems: 'center',
        color: 'white',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        justifyItems: 'center',
        ...css,
      }

      return (
        // keypad container
        h(AgBoxS, {
          ...attrs,
          css: newCss,
        }, () => [
          // render keys
          ...shuffledKeyListRef.value.map((key: string, index: number) => {
            const css: Record<string, any> = {}
            if (index === LAST_FIRST_ITEM_INDEX) {
              css.gridColumnStart = 2
            }
            return h(Button, {
              css,
              index,
              key,
              keyName: key,
              onClick: () => onClick(key),
            }, {
              default: slots.default ? (context: ButtonSlotProps) => slots.default?.(context) : undefined,
            })
          }),
          // // render delete key
          // defaultButtonRendering('delete', shuffledKeyListRef.value.length),
          h(Button, {
            index: shuffledKeyListRef.value.length,
            key: 'delete',
            keyName: 'delete',
            onClick: onDelete,
          }, {
            default: slots.default ? (context: ButtonSlotProps) => slots.default?.(context) : undefined,
          }),
        ])
      )
    }
  },
})
