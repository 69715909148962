import {getResponsiveProp} from './utils'
import {TypographyType} from '@/plugins/emotion'
import {system} from '@winter-love/style-system'
import css from '@styled-system/css'

export const typographySystem = system({

  typography: Object.assign((value: any, scale: any, props: any) => {
    const style = scale?.[value]
    return style ? css(style)(props) : {}
  }, {
    scale: 'typography',
  }),
})

export const typographyProp = getResponsiveProp<TypographyType>()
