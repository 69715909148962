import {systemProps} from '@/plugins/emotion'
import {IonSpinner} from '@ionic/vue'
import {computed, defineComponent, h, PropType, toRef} from 'vue'
import {BackDrop, Container, Img, Loading} from './components'
import {useLoading, useSrcset} from './composables'

export type LoadingType = 'eager' | 'lazy'
export type Decoding = 'sync' | 'async' | 'auto'
export type SpinnerNames = 'bubbles' | 'circles' | 'circular' | 'crescent' | 'dots' | 'lines' | 'lines-small'

/**
 * 주의 사항 가능하다면 width 와 height 를 지정해 주세요
 */
export const AgImg = defineComponent({
  emits: ['error', 'end-loading', 'start-load'],
  name: 'AgImg',
  props: {
    ...systemProps,
    // eslint-disable-next-line vue/require-default-prop
    alt: String,
    backdropSrc: String,
    decoding: {type: String as PropType<Decoding>},
    failBackSrc: String,
    loading: {type: String as PropType<LoadingType>},
    showLoading: {default: false, type: Boolean},
    spinnerName: {default: 'dots', type: String as PropType<SpinnerNames>},
    src: {type: [Array, String] as PropType<string | string[]>},
  },
  setup(props, {attrs, slots}) {
    const showLoadingRef = toRef(props, 'showLoading')
    const srcRef = toRef(props, 'src')
    const failBackSrcRef = toRef(props, 'failBackSrc')
    const {src, srcset, isError} = useSrcset(srcRef, failBackSrcRef)

    const onerror = () => {
      isError.value = true
    }

    const {loading, onload, onloadstart} = useLoading(srcRef)
    const isLoading = computed(() => {
      return showLoadingRef.value && loading.value
    })

    return () => {
      const {spinnerName, alt, backdropSrc, width, height, ...restProps} = props
      const {class: className, style, ...restAttrs} = attrs
      return h(Container, {...restProps, class: className, height, style, width}, () => [
        backdropSrc
          ? h(BackDrop, {src: backdropSrc})
          : null,
        h(Img, {
          ...restAttrs,
          alt,
          height,
          onerror,
          onload,
          onloadstart,
          src: src.value,
          srcset: srcset.value,
          width,
        }),
        h(Loading, {show: isLoading.value}, () => [
          slots.default?.() ?? h(IonSpinner, {name: spinnerName}),
        ]),
      ])
    }
  },
})
