import {FontSizesType, FontsType, FontWeightsType} from '@/plugins/emotion'
import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'
import {Property} from 'csstype'

export const fontProps = {
  fontFamily: getResponsiveProp<FontsType>(),
  fontSize: getResponsiveProp<FontSizesType>(),
  fontStyle: getResponsiveProp<Property.FontStyle>(),
  fontWeight: getResponsiveProp<FontWeightsType>(),
}
export const fontSystems = system({
  fontFamily: {
    property: 'fontFamily',
    scale: 'fonts',
  },
  fontSize: {
    property: 'fontSize',
    scale: 'fontSizes',
  },
  fontStyle: true,
  fontWeight: {
    property: 'fontWeight',
    scale: 'fontWeights',
  },
})
