
import {computed, onBeforeMount, reactive, ref} from 'vue'
import {useAndroidBackButtonExit} from '@/composables'
import {IonTabBar, IonTabButton, IonTabs, IonPage} from '@ionic/vue'
import {homeOutline, searchOutline, analyticsOutline, personOutline, reorderThreeOutline} from 'ionicons/icons'
import {SendBirdChatEvent} from '@/plugins/SendBirdChatEvent'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {useGtm} from '@gtm-support/vue-gtm'
import {CLICK_EVENT, GtmClickEvent} from '@/plugins/gtm-event'

const text = {
  backButtonMessage: '"뒤로" 한번 더하시면 종료됩니다',
}

export default {
  components: {IonPage, IonTabBar, IonTabButton, IonTabs},
  name: 'Tabs',
  setup() {
    const router = useRouter()
    const {dispatch, getters} = useStore()
    const noti = ref(false)

    const agrees: any = reactive({community: false, home: false, myProperty: false, nego: false, search: false})

    const getUnReadCount = computed(() => getters['message/getUnReadCount'])

    // async function initChatEvent(){
    //   const sendBirdChatEvent = SendBirdChatEvent.getInstance()
    //   sendBirdChatEvent.onMessageReceived = async (channel: any, message: any) => {
    //     console.log('onMessageReceived', message)
    //     await dispatch('message/ACT_UNREAD_COUNT')
    //     // await dispatch('message/ACT_REFRESH_CHANNEL', {channel, message})
    //   }
    // }
    onBeforeMount(async () => {
      // await dispatch('message/ACT_UNREAD_COUNT')
      // await initChatEvent()
    })

    const goURL = (url: string, event: any) => {
      router.push(url)
    }

    useAndroidBackButtonExit(text.backButtonMessage)

    //gtm event screen name
    const gtmScreen = 'all'

    return {
      agrees,
      analyticsOutline,
      clickScreen: gtmScreen,
      getUnReadCount,
      goURL,
      homeOutline,
      iosPass: process.env.VUE_APP_UI_MODE === 'ios-pass',
      noti,
      personOutline,
      reorderThreeOutline,
      searchOutline,
    }
  },
}
