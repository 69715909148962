
import {IonPage, IonContent, modalController, IonRippleEffect} from '@ionic/vue'
import {computed, defineComponent, onBeforeMount, ref} from 'vue'
import router from '../../router'
import ViewOptionModal from '@/components/Modal/ViewOptionModal.vue'
import DealInfoModal from '@/components/Modal/DealInfoModal.vue'
import DealHistory from '../../data/dealHistory.json'
import PopularShareTransactionModal from '@/views/view/home/popular-share-transaction/PopularShareTransactionModal.vue'
import {useStore} from 'vuex'
import {AgText, AgBoxS} from '@/components/ag'
import useModal from '@/composables/useModal'
import {getFormattedDate} from '@/utils/filters'

export default defineComponent({
  components: {
    AgBoxS,
    AgText,
    IonContent,
    IonPage,
    IonRippleEffect,
    PopularShareTransactionModal,
  },
  name: 'DealHistory',
  setup() {
    const {getters, dispatch} = useStore()
    const {useCompanyModal} = useModal()
    const route = router

    const dealHistory = ref(DealHistory.dealHistory)
    const viewShareTransactionOpen = ref<boolean>(false)
    const totalResult = ref<number>(+1178000)
    const viewOption = ref<string>('전체')
    const viewType = ref<string>('최신순')

    const stakeHistory = computed(() => getters['asset/getShareTransactionsHistory'])

    let printedYear = ref<any>([])
    function checkNewYear(year: string, stake: any){
      if(printedYear.value.indexOf(year)<0){
        printedYear.value.push(year)
        return true
      }
      return false
    }

    function clearPrintedYear(){
      printedYear.value = []
    }

    const sortedStakeHistory = computed(() => {
      let array = [...stakeHistory.value]
      if (viewType.value === '과거순') {
        array = array.sort(function(a: any, b: any) {
          return (a.createdAt < b.createdAt ? 1 : -1)
        })
      }
      array = array.sort(function(a: any, b: any) {
          return (a.createdAt < b.createdAt ? -1 : 1)
        })

      const result = array.reduce((r: any, a: any) => {
        const {createAt} = a
        let monthyear = createAt.substring(0, 4)
        if(!r[monthyear]) {
          r[monthyear] = [a]
        } else {
          r[monthyear].push(a)
        }
        return r
      }, {})

      return result

    })
    const transactionPrice = (stake: any) => {
      return stake.type === '판매' ? Number(stake.totalPrice) - Number(stake.fee) - Number(stake.carriedInterest) : Number(stake.totalPrice) + Number(stake.fee)
    }

    onBeforeMount(() => {
      dispatch('asset/ACT_GET_SHARE_TRANSACTIONS_HISTORY')
    })

    const createModal = async (type: string, deal: any) => {
      if (type === 'filter') {
        const modal = await modalController
          .create({
            backdropDismiss: true,
            component: ViewOptionModal,
            componentProps: {
              requestType: 'stakeDeal',
              viewOption: viewOption.value,
              viewType: viewType.value,
            },
            cssClass: 'input-modal',
            id: 'ViewOptionModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        modal.onDidDismiss().then(async (data) => {
          viewOption.value = await data.data.viewOption
          viewType.value = await data.data.viewType
        })

        return modal.present()
      }
        const modal = await modalController
          .create({
            backdropDismiss: true,
            component: DealInfoModal,
            componentProps: {
              deal: deal,
            },
            cssClass: 'deal-info-modal',
            id: 'DealInfoModal',
            keyboardClose: true,
            mode: 'ios',
            swipeToClose: true,
          })

        return modal.present()

    }

    const formattedDate = (updateDate: any) => {
      return getFormattedDate(updateDate, 'MM.DD HH:mm')
    }

    const clickScreen = 'deal-history'

    return {
      checkNewYear,
      clickScreen,
      createModal,
      dealHistory,
      formattedDate,
      route,
      sortedStakeHistory,
      stakeHistory,
      totalResult,
      transactionPrice,
      useCompanyModal,
      viewOption,
      viewShareTransactionOpen,
      viewType,
    }
  },
})
