import {styled} from '@/plugins/emotion'
import {AgBoxS, agBoxProps} from '../../ag-box'

export const containerProps = {
  ...agBoxProps,
}

export const Container: any = styled(AgBoxS, {
  name: 'Container',
  stylePortal: 'css',
  target: 'ion-activatable ripple-parent',
})(
  {
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
  },
)
