
import GroupBuy from '@/components/Company/GroupBuy.vue'
import {AgModal, AgContent, AgHeader, AgBoxS, AgTextS, AgIcon} from '@/components/ag'
import {useShare} from '@/composables'
import {defineComponent, toRef, watch} from 'vue'
import {IonPage, IonRippleEffect, IonContent, modalController, IonHeader, IonTitle, IonToolbar} from '@ionic/vue'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

export default defineComponent({
  components: {
    AgBoxS,
    AgContent,
    AgHeader,
    AgIcon,
    AgModal,
    AgTextS,
    GroupBuy,
    IonContent,
    IonHeader,
    IonPage,
    IonRippleEffect,
    IonTitle,
    IonToolbar,
  },
  props: {
    modelValue: {type: Boolean},
  },
  setup(props) {
    const gtm = useGtm()
    const isOpenRef = toRef(props, 'modelValue')
    const share = useShare()

    watch(isOpenRef, () => {
      if(isOpenRef.value){
        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: 'clubdeal-all-modal',
          title: '클럽딜 모아보기 모달',
        })
      }
    })

    function dismiss() {
      return modalController.dismiss()
    }
    
    const onShare = () => {
      // bichi club deals
      const url = 'https://angelleague.io/link/club-deals/'
      const title = '참여가능 클럽딜'
      const text = '지금 소액으로 참여 가능한 클럽딜 리스트를 확인하세요'
      share({
        text,
        title,
        url,
      })
    }

    return {dismiss, onShare}
  },
})
