
import {defineComponent, watch, toRef, ref, onMounted, getCurrentInstance} from 'vue'
import {AgBox, AgImg, AgText} from '@/components/ag'
import AgreeInfo from '../component/AgreeInfo.vue'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
import {useGtm} from '@gtm-support/vue-gtm'

export default defineComponent({
  components: {
    AgBox,
    AgImg,
    AgText,
    AgreeInfo,
  },
  emits: ['allCheck', 'notYet'],
  name: 'FirstStep',
  props: {
    parent: {type: String},
    stakeName: {type: String},
  },
  setup(props, {emit}) {
    const app = getCurrentInstance()
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const parentRef = toRef(props, 'parent')
    const agreeArray = ref<any>([{
      content: '스타트업의 주식 보유로 매우 큰 차익실현을 기대할 수 있지만, 비상장회사 주식투자의 특성을 이해하고 투자금 전액 손실 등 매우 큰 위험을 감수합니다.',
      title: '투자금 전액 손실 등 매우 큰 위험을 감수합니다.',
    }, {
      content: '구매 대상은 조합원 자격으로서 투자대상 회사의 주식 그 자체가 아닙니다. 설립되는 조합이 주식을 매수하게 되며, 개인은 조합원 자격을 보유하게 됩니다.',
      title: '투자금액은 조합이 공동 관리해야 합니다.',
    },
    ])

    const checked = ref<any>([false, false, false])
    const stakeNameRef = toRef(props, 'stakeName')

    const checkCount = ref<number>(0)

    watch(checkCount, () => {
      if (checkCount.value === 2) {
        emit('allCheck', 'SECOND')
      } else {
        emit('notYet')
      }
    })

    const print = (index: any) => {

      checked.value[index] = !checked.value[index]

      if (checked.value[index]) {
        checkCount.value += 1
      } else if (!checked.value[index]) {
        checkCount.value -= 1
      }
      $clickTrackEvent({'clickArea':'조합 참여 위험안내', 'clickContent':`${agreeArray.value[index].title}`, clickScreen})

    }
    const clickScreen = parentRef.value

    return {
      agreeArray,
      checked,
      clickScreen,
      print,
      stakeNameRef,
    }
  },
})
