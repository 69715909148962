import {defineComponent, h} from 'vue'

// WIP
export const AgInput = defineComponent({
  setup: () => {
    return () => (
      h('div')
    )
  },
})
