
import {IonPage, IonContent, IonInput, modalController, popoverController, IonRippleEffect} from '@ionic/vue'
import {computed, defineComponent, onBeforeMount, reactive, ref, onMounted, onBeforeUpdate} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import StockAddModal from '../../components/Modal/StockAddModal.vue'
import SellItemModal from '@/components/Modal/SellItemModal.vue'
import {numToHan, stockOptionPeriod} from '../../utils/filters'
import StockCalcHelp from '@/components/Popover/StockCalcHelp.vue'

export default defineComponent({
  components: {
    IonContent,
    IonInput,
    IonPage,
    IonRippleEffect,
  },
  name: 'StockOption',
  props: {
    scroll: null,
  },
  setup() {
    const {getters, dispatch} = useStore()
    const router = useRouter()
    const route = useRoute()
    const topContainer = ref<any>(null)
    const activeTab = ref('mystock')
    const user = computed(() => getters['user/getUser'])
    const scrollLoc = computed(() => route.params.scroll)
    const calcResult = computed(() => getters['stockOption/getCalcInfo'])
    const calcInfo = reactive({
      annualIncome: 50000000,
      companyName: '엔젤리그',
      exercisePrice: 50000,
      exerciseQty: 1000,
      handoverPrice: 100000,
    })

    const calcTabObject = ref<any>(null)
    const changeTab = (value: string) => {
 activeTab.value = value
}
    const stockOption = computed(() => getters['asset/getStockOption'] || '')
    const calcStock = async () => dispatch('stockOption/GET_STOCK_CALCINFO', calcInfo)

    let popover: any = null
    const openPopover = async (event_: Event) => {
      if (popover) {
return
}
      popover = await popoverController
        .create({
          component: StockCalcHelp,
          componentProps: {
            onClick: () => {
              popover.dismiss()
            },
          },
          cssClass: 'stock-calc-help',
          event: event_,
          translucent: false,
        })

      popover.onDidDismiss().then(() => {
        popover = null
      })
      return popover.present()
    }

    const createModal = async (mode: string, data?: any) => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: StockAddModal,
          componentProps: {
            mode: `${mode}`,
            stockOption: data,
          },
          cssClass: 'full-screen-modal',
          id: 'StockAddModal',
          keyboardClose: true,
          swipeToClose: true,
        })

      return modal.present()
    }

    const openSellModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: SellItemModal,
          cssClass: 'sell-item-modal',
          id: 'SellItemModal',

          // mode: 'ios',
          keyboardClose: true,

          swipeToClose: true,
        })

      return modal.present()
    }

    function moveScroll() {
      if (scrollLoc.value) {
        const containerHeight = topContainer.value.clientHeight
        const content: any = document.querySelector('#stock__ionContent')
        content.scrollToPoint(0, containerHeight)
      }
    }

    onBeforeMount(() => {
      if (user.value) {
dispatch('asset/loadData')
}
    })

    onMounted(() => moveScroll())
    onBeforeUpdate(() => moveScroll())

    const isValidInput = (event: any) => {
      if (event.target.value.toString().length > 15) {
        event.target.value = event.target.value.slice(0, 15)
      }

      event.target.value = event.target.value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1')
    }

    const clickScreen = 'stockoption'

    return {
      activeTab,
      calcInfo,
      calcResult,
      calcStock,
      changeTab,
      clickScreen,
      createModal,
      isValidInput,
      numToHan,
      openPopover,
      openSellModal,
      router,
      stockOption,
      stockOptionPeriod,
      topContainer,
      user,
    }
  },
})
