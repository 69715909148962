import {Module} from 'vuex'
import {RootState} from '..'
import messageHistory from '../../data/message.json'
import {SendBirdAction} from '@/plugins/SendBirdAction'

interface CustomChannel {
  channel: any
  channelUrl: any
  createdAt: any
  lastMessage: any
  leadAngelUserId: any
  members: any
  name: any
  sellerUserId: any
  status: any
  stockItemId: any
  unreadMessageCount: any
}

// state
interface Message {
  channelList: any
  initSendBird: boolean
  message: any[]
  unReadCount: any
}

// action
const message: Module<Message, RootState> = {

actions: {
    async ACT_CHANNEL_INIT({commit, rootState}) {
      commit('SET_UNREAD_COUNT', 0)
      commit('SET_CHANNEL_LIST', [])
    },
    // async ACT_REFRESH_CHANNEL({commit}, {channel}) {
    //   const sba: any = await SendBirdAction.getInstance()
    //   const chan: any = await sba.getChannel(channel.url, false)
    //   const metaData = await chan.getAllMetaData((response: any) => {
    //     return response
    //   })
    //   commit('SET_REFRESH_CHANNEL', {channel, metaData})
    // },
    async ACT_UNREAD_COUNT({commit, rootState}) {
      const user = (rootState as any).user.user
      // if (user) {
      //   let unReadCount = 0
      //   const sba: any = await SendBirdAction.getInstance()
      //   const groupChannels: any = await sba.getGroupChannelList(true)
      //
      //   let channelList = groupChannels.map((ch: any) => {
      //     const data: CustomChannel = {
      //       channel: ch,
      //       channelUrl: ch.url,
      //       createdAt: ch.lastMessage.createdAt,
      //       lastMessage: ch.lastMessage.message,
      //       leadAngelUserId: 0,
      //       members: ch.members,
      //       name: ch.name,
      //       sellerUserId: 0,
      //       status: '',
      //       stockItemId: 0,
      //       unreadMessageCount: ch.unreadMessageCount,
      //     }
      //     return data
      //   })
      //
      //   await Promise.all(channelList.map(async (channel: any) => {
      //     const meta = await channel.channel.getAllMetaData()
      //     channel.stockItemId = meta.stockItemId
      //     channel.leadAngelUserId = meta.leadAngelUserId
      //     channel.sellerUserId = meta.sellerUserId
      //     channel.status = meta.status
      //   }))
      //
      //   channelList = channelList.filter((ch: any) => {
      //     return !(ch.status === 'init' && ch.sellerUserId.toString() === user.userId.toString())
      //   })
      //
      //   channelList.forEach((ch: any) => {
      //     unReadCount += ch.unreadMessageCount
      //   })
      //
      //   // const unReadCount = groupChannels.map((ch: any)=>ch.unreadMessageCount).reduce((a: any,b: any) => {
      //   //   return a+b
      //   // },[])
      //   commit('SET_UNREAD_COUNT', unReadCount)
      //   commit('SET_CHANNEL_LIST', channelList)
      // } else {
      //   commit('SET_UNREAD_COUNT', 0)
      //   commit('SET_CHANNEL_LIST', [])
      // }
    },
    SEND_REQUEST({commit}, {price, percent, total}) {
      // console.log(price)

      const message = {
        create_at: new Date(),
        price: price,
        rate: percent,
        sender: 'buyer',
        total: total,
        type: 'suggest',
      }
      // console.log(message)

      commit('ADD_MESSAGE', message)
    },
  },


getters: {
    getChannelList(state) {
      return state.channelList
    },
    getUnReadCount(state) {
      return state.unReadCount
    },
    message(state) {
      return state.message
    },
  },

  // RootState와 똑같이 작성해도 된다.
mutations: {
    async ADD_MESSAGE(state, message) {
      await state.message.push(message)

      const newMessage = {
        create_at: new Date(),
        sender: 'seller',
        type: 'accept_suggest',
      }
      state.message.push(newMessage)
    },
    SET_CHANNEL_LIST(state, channelList) {
      state.channelList = channelList
    },
    SET_REFRESH_CHANNEL(state, {channel, metaData}) {
      state.channelList.forEach((ch: any) => {
        if (ch.channel.url === channel.url) {
          ch.channel = channel
          ch.createdAt = channel.lastMessage.createdAt
          ch.lastMessage = channel.lastMessage.message
          ch.members = channel.members
          ch.name = channel.name
          ch.unreadMessageCount = channel.unreadMessageCount
          state.unReadCount = ch.unreadMessageCount
          ch.stockItemId = metaData.stockItemId
          ch.leadAngelUserId = metaData.leadAngelUserId
          ch.sellerUserId = metaData.sellerUserId
          ch.status = metaData.status
        }
      })
    },
    SET_UNREAD_COUNT(state, unReadCount) {
      state.unReadCount = unReadCount
    },
  },
  namespaced: true,
  state: {
    channelList: [],
    initSendBird: false,
    message: messageHistory.message,
    unReadCount: 0,
  },
}

export default message
