import {styled} from '@/plugins/emotion'

export const StyledRowSection = styled('div', {
  name: 'RowSection',
  stylePortal: 'css',
  target: 'row-section',
})(
  {
    display: 'flex',
    flexDirection: 'row',
  },
)
