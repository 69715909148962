import {injectGlobal} from '@/plugins/emotion'
import {isPlatform} from '@ionic/core'

export const injectGlobalStyle = () => {
  if (process.env.NODE_ENV === 'development') {
    // 트리쉐이킹 유도용
    if (isPlatform('mobileweb')) {
      injectGlobal({
        ':root': {
          '--ion-safe-area-bottom': '35px',
          '--ion-safe-area-top': '15px',
        },
      })
    }
  }

  injectGlobal({
    '*': {
      boxSizing: 'border-box',
    },


    // dark ionic 용
    '@media (prefers-color-scheme: light)': {
      '--ion-background-color': '#FFFFFF',
    },


   // dark ionic 용
    'body': {
      // dark background
      '--ion-background-color': '#FFFFFF',
    },

    'button:disabled': {
      opacity: 0.3,
    },

    svg: {
      userSelect: 'none',
    },

    'svg .fill': {
      fill: 'currentcolor',
    },
    'svg .stroke': {
      stroke: 'currentcolor',
    },
  })
}
