
import {IonPage, IonContent, modalController} from '@ionic/vue'
import {defineComponent, computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import SellItemModal from '@/components/Modal/SellItemModal.vue'

export default defineComponent({
  components: {IonContent, IonPage},
  name: 'SendbirdTest',
  setup() {
    const {getters} = useStore()
    const router = useRouter()

    const user = computed(() => getters['user/getUser'])

    const openSellModal = async () => {
      const modal = await modalController
        .create({
          backdropDismiss: true,
          component: SellItemModal,
          componentProps: {},
          cssClass: 'sell-item-modal',
          id: 'SellItemModal',

          // mode: 'ios',
keyboardClose: true,

swipeToClose: true,
        })

      return modal.present()
    }

    return {openSellModal, router, user}
  },
})
