import {CSSObject} from '@emotion/css'
import {ResponsiveValue} from '@winter-love/style-system'
import {PropType} from 'vue'
import {AnyFunction} from '@/types'

export const getResponsiveProp = <T>() => ({type: [String, Array, Object, Number] as PropType<ResponsiveValue<T>>})

export type SystemFunction = (props: Record<string, any>) => CSSObject

export const systemPipe = (...systems: SystemFunction[]): SystemFunction => {
  return (props) => {
    return systems.reduce((style, system) => {
      return {
        ...style,
        ...system(props),
      }
    }, {} as CSSObject)
  }
}

export type ChangePropsConfig = [string, string | undefined] | [string]

export const swapProps = (props: Record<string, any>, configs: ChangePropsConfig[]) => {
  return configs.reduce((result, [from, to]) => {
    if (typeof to === 'undefined') {
      result[from] = undefined
      return result
    }
    result[to] = props[from]
    return result
  }, {...props})
}

export const swapPropsAndExecute = (props: Record<string, any>, func: AnyFunction, configs: ChangePropsConfig[]) => {
  return func(swapProps(props, configs))
}

export const withSwapProps = (func: AnyFunction, configs: ChangePropsConfig[]) => (props: Record<string, any>) => {
  return func(swapProps(props, configs))
}
