import {styled, systemProps, systems} from '@/plugins/emotion'

export const containerProps = {
  ...systemProps,
}

export const Container = styled('div', {
  props: {
    ...containerProps,
  },
})(
  systems,
)
