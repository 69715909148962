import {styled, systems, systemProps, CSSObject} from '@/plugins/emotion'

export const containerProps = {
  ...systemProps,
}

export const Container = styled('div', {
  props: {
    ...containerProps,
  },
})(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  } as CSSObject,
  systems,
)
