
import {
  IonPage,
  IonContent,
  alertController,
  IonRippleEffect,
  toastController,
} from '@ionic/vue'
import {arrowDownOutline} from 'ionicons/icons'
import {defineComponent, computed, onBeforeMount, ref, watch, getCurrentInstance} from 'vue'
import {useStore} from 'vuex'
import {throttle} from 'lodash'
import {useRoute, useRouter} from 'vue-router'
import apiPartnership from '@/api/partnership'
import {SendBirdAction} from '@/plugins/SendBirdAction'
import useModal from '@/composables/useModal'
import {AgButton, AgRefresher, AgTextS, AgBoxS} from '@/components/ag'
import {cancelFromBuyer, cancelFromSeller, postConfirmSell} from '@/api/stake-deal'
import {openLink} from '@/utils/filters'
import {Browser} from '@capacitor/core'

export default defineComponent({
  components: {
    AgBoxS,
    AgButton,
    AgRefresher,
    AgTextS,
    IonContent,
    IonPage,
    IonRippleEffect,
  },
  name: 'MyPage',
  setup() {
    const app = getCurrentInstance()
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const {getters, dispatch, commit} = useStore()
    const {delPartnershipRequests} = apiPartnership()
    const user = computed(() => getters['user/getUser'])
    const isLead = computed(() => user.value.leadAngelId)
    const printTime = (second: any) => {
      if (second >= 60) {
        return `${Math.floor(second / 60)}분 전`
      }
        return `${second}초 전`

    }

    const router = useRouter()
    const route = useRoute()
    const testObject = {
      shareTransactionsDepositInfo: [{
        buyerClaim: 10000,
        partnershipName: '야놀자',
        payStatus: 'ACCEPT',
        remainingSeconds: 600,
        sellerUserId: 170,
        status: 'SHARE_IN',
        virtualAccount: '1212113',
        virtualAccountOwner: '이솔',
      }],
    }

    const {useClubDealProgressModal, openFAQModal, viewKakaoKlipModal} = useModal()

    const getTokenValidDuration = computed(() => getters['user/getTokenValidDuration'])
    const point = computed(() => getters['pointInfo/getUserPoint'])
    const partnershipComplete = computed(() => getters['asset/getPsComplete'])
    const shareTransactions = computed(() => getters['asset/getShareTransactions'])
    const partnershipCollecting = computed(() => getters['asset/getPsCollecting'])
    // const earlyBird = computed(() => getters[`asset/getEarlyBird`]);
    const exit = computed(() => getters['asset/getExit'])
    const stockOption = computed(() => getters['asset/getStockOption'])
    const depositInfo = computed(() => getters['asset/getDepositInfo'])
    const shareTransactionsDepositInfo = computed(() => getters['asset/getShareTransactionsDepositInfo'])
    const stBuyerDepositInfo = computed(() => {
      const array = [...shareTransactionsDepositInfo.value]
      return array.filter((a) => a.buyerUserId === user.value.userId)
    })
    const stSellerDepositInfo = computed(() => {
      const array = [...shareTransactionsDepositInfo.value]
      return array.filter((a) => a.sellerUserId === user.value.userId)
    })
    const totalAsset = computed(() => getters['asset/getTotalAsset'])
    const docusign = computed(() => getters['asset/getDocusign'])
    const recommendList = computed(() => getters['partnership/getEntireRecommendPartnership'])
    const groupChannels = ref<any>(0)
    const mypageNameHeader = ref<any>(null)
    const mypageTitle = ref<any>(null)
    const mypageJibunId = ref<any>(null)

    async function initThisPage() {
      await dispatch('pointInfo/loadData')
      await dispatch('asset/loadData')
      await dispatch('asset/ACT_GET_ASSET_PARTNERSHIPS')
      await dispatch('partnership/ACT_GET_ENTIRE_RECOMMEND_PARTNERSHIPS')
      await dispatch('message/ACT_UNREAD_COUNT')
      const channelList = computed(() => getters['message/getChannelList'])
      groupChannels.value = channelList.value.length
    }
    onBeforeMount(async () => {
      await initThisPage()
    })
    watch(
      () => route.path,
      async newPath => {
        if (newPath === '/tabs/mypage') {
          await initThisPage()
        }
      },
    )
    const doRefresh = async (event: any) => {
      await initThisPage()
      event.target.complete()
    }

    // onUpdated(()=>{
    //   // console.log('MyPageAuth Component is onUpdated!');
    //   dispatch(`pointInfo/loadData`);
    //   dispatch(`asset/loadData`);
    //   SendBirdAction.getInstance().getGroupChannelList(false)
    //       .then((data: any) => {
    //         groupChannels.value = data.length;
    //       })
    // })

    const pointBox = ref<any>(null)
    const moneyBox = ref<any>(null)

    const handleScroll = throttle(async (event: any) => {
      const scroll = await event.getScrollElement()

      if (scroll.scrollTop > 20) {
        // pointBox.style.cssText = `opacity : (50-${scroll.scrollTop})/100`;
        const opacity = await (1 - (scroll.scrollTop * 0.01))
        pointBox.value.style.cssText = `opacity : ${opacity}`
      } else {
        pointBox.value.style.cssText = 'opacity : 1'
      }

      if (scroll.scrollTop > 72) {
        const opacity = 1 - ((scroll.scrollTop - 72) * 0.015)
        moneyBox.value.style.cssText = `opacity : ${opacity}`
      } else {
        moneyBox.value.style.cssText = 'opacity : 1'
      }

      if (scroll.scrollTop > 120) {
        const opacity = 1 - ((scroll.scrollTop - 120) * 0.02)
        mypageTitle.value.style.cssText = `opacity : ${opacity}`
      } else {
        mypageTitle.value.style.cssText = 'opacity : 1'
      }

      if (scroll.scrollTop > 160) {
        const opacity = 1 - ((scroll.scrollTop - 160) * 0.02)
        mypageJibunId.value.style.cssText = `opacity : ${opacity}`
      } else {
        mypageJibunId.value.style.cssText = 'opacity : 1'
      }

      if (scroll.scrollTop > 205) {
        mypageNameHeader.value.classList.add('shadow')
      } else {
        mypageNameHeader.value.classList.remove('shadow')
      }
    }, 33)

    async function sendCancelClubDealMessage(message: string, channelUrl: string) {
      if (!message) {
return
}
      const sendbirdAction: any = await SendBirdAction.getInstance()
      sendbirdAction
        .getChannel(channelUrl, false)
        .then((channel: any) => {
          sendbirdAction.sendUserMessage({
            channel: channel,
            message: message,
            status: 'cancelToSeller',
          })
        })
    }

    const cancelBuyAction = async (purchaseInfo: any, index: any) => {
      const result = await cancelFromBuyer(purchaseInfo.partnershipShareSellId, purchaseInfo.partnershipSharePurchaseId)
      if (result) {
        commit('asset/DEL_SHARE_TRANSACTION_DEPOSIT_INFO', index)
      }
    }

    async function cancelToBuy(purchaseInfo: any, index: any) {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              handler: async () => {
                $clickTrackEvent({'clickArea':'구매를 취소하시겠어요?', 'clickContent':'아니오', clickScreen})
              },
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                $clickTrackEvent({'clickArea':'구매를 취소하시겠어요?', 'clickContent':'네, 취소할게요', clickScreen})
                cancelBuyAction(purchaseInfo, index)
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups adderss-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              구매를 취소하시겠어요?
            </div>
            <div class="message">
              구매 요청 내역, 구매금 입금 계좌와<br>
              조합가입 확인서는 모두 삭제됩니다
            </div>
          </div>`,
        })

      return alert.present()
    }

    const cancelSellAction = async (sellInfo: any, index: any) => {
      const result = await cancelFromSeller(sellInfo.partnershipShareSellId, sellInfo.partnershipSharePurchaseId)
      if (result) {
        commit('asset/DEL_SHARE_TRANSACTION_DEPOSIT_INFO', index)
      }
    }

    async function cancelToSell(sellInfo: any, index: any) {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '아니오',
            },
            {
              handler: async () => {
                cancelSellAction(sellInfo, index)
              },
              text: '네, 취소할게요',
            },
          ],
          cssClass: 'btn-groups seller-cancel-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              거래를 취소하시겠어요?
            </div>
            <div class="message">
              구매자에게 정중한 거래 취소 메시지를<br>
              전송한 후, 이번 거래를 종료합니다.<br>
              거래 종료 후<br>
               해당 매물은 판매, 판매취소, 조건 수정이
              가능한 상태가 됩니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    const confirmSell = async (sellInfo: any, index: any) => {
      const result = await postConfirmSell(sellInfo.partnershipShareSellId, sellInfo.partnershipSharePurchaseId)
      if (result) {
        commit('asset/DEL_SHARE_TRANSACTION_DEPOSIT_INFO', index)
        initThisPage()

        const toast = await toastController
          .create({
            cssClass: 'toast-custom-blue',
            duration: 2000,
            message: '거래 확정이 성공적으로 완료되었습니다.',
            position: 'middle',
          })
        return toast.present()
      }
    }

    async function confirmAlert(sellInfo: any, index: any) {
      const alert = await alertController
        .create({
          buttons: [
            {
              cssClass: 'btn-gray',
              role: 'cancel',
              text: '취소',
            },
            {
              handler: async () => {
                confirmSell(sellInfo, index)
              },
              text: '확인',
            },
          ],
          cssClass: 'btn-groups buy-confirm-alert-class',
          message: `
          <div class="alert_css">
            <div class="header">
              거래를 확정합니다.
            </div>
            <div class="message">
              거래를 확정하시면<br>
              즉시 조합원 자격 이전 후<br>
              판매금이 예수금으로 충전됩니다.
            </div>
          </div>`,
        })

      return alert.present()
    }

    async function openAlert(type: string, command?: string, partnership?: any) {
      let alert: any = ''
      const typeString = type === 'clubdeal' ? '클럽딜' : '얼리버드'
      if (command === 'cancel') {
        const id = partnership.partnershipId
        const requestId = partnership.partnershipRequestId

        alert = await alertController
          .create({
            buttons: [
              {
                cssClass: 'btn-gray',
                role: 'cancel',
                text: '취소',
              },
              {
                handler: async () => {
                  if (type === 'clubdeal') {
                    const message = await delPartnershipRequests(requestId, id)
                    if (partnership.partnershipOpenChannelId) {
                      await sendCancelClubDealMessage(message.channelMessage, partnership.channelUrl)
                    }
                  }

                  await openAlert(type)
                },
                text: '확인',
              },
            ],
            cssClass: 'btn-groups cancel-alert-class',
            message: `
          <div class="alert_css">
            <div class="header">
            참여 취소하기
            </div>
            <div class="message">
              ${typeString} 참여 취소 하시겠어요?
            </div>
          </div>
          `,
          })
      } else {
        alert = await alertController
          .create({
            buttons: [
              {
                handler: async () => {
                  await dispatch('asset/loadData')
                  await dispatch('pointInfo/loadData')
                },
                text: '확인',
              },
            ],
            cssClass: 'cancel-alert-class',
            message: `
          <div class="alert_css">
            <div class="header">
            참여 취소 완료
            </div>
            <div class="message">
              ${typeString} 참여가 취소되었습니다.
            </div>
          </div>
          `,
          })
      }

      return alert.present()
    }

    const textDiv = ref<any>(null)

    const onSuccess = async () => {
      await initThisPage()

      const toast = await toastController
        .create({
          duration: 1000,
          message: '입금 계좌가 클립보드에 복사되었습니다.',
          position: 'middle',
        })
      return toast.present()
    }

    async function copyText(bankAccountInfo: string) {
      const createInput = document.createElement('input')
      createInput.setAttribute('type', 'text')
      textDiv.value.append(createInput)
      createInput.value = bankAccountInfo
      createInput.select()

      const result = document.execCommand('copy')
      createInput.remove()

      if (result) {
        onSuccess()
      }
    }

    const openChannelTalk = async () => {
      await Browser.open({url: 'https://angelleague.channel.io/'})
    }

    const transactionPrice = (stake: any) => {
      return stake.type === '판매' ? Number(stake.totalPrice) - Number(stake.fee) - Number(stake.carriedInterest) : Number(stake.totalPrice) + Number(stake.fee)
    }

    const clickScreen = 'my'

    return {
      arrowDownOutline,
      cancelToBuy,
      cancelToSell,
      clickScreen,
      confirmAlert,
      copyText,
      depositInfo,
      doRefresh,
      docusign,
      exit,
      getTokenValidDuration,
      groupChannels,
      handleScroll,
      isLead,

      moneyBox,
      mypageJibunId,

      mypageNameHeader,

      mypageTitle,

      openAlert,
      openChannelTalk,

      openFAQModal,

      openLink,
      partnershipCollecting,

      partnershipComplete,

      point,

      pointBox,

      printTime,

      recommendList,

      router,

      shareTransactions,

      shareTransactionsDepositInfo,

      stBuyerDepositInfo,

      stSellerDepositInfo,

      stockOption,

      testObject,
      textDiv,

      totalAsset,

      transactionPrice,

      useClubDealProgressModal,
      // earlyBird,
user,
      viewKakaoKlipModal,
    }
  },
})
