import {ColorsType} from '@/plugins/emotion'
import {getResponsiveProp} from './utils'
import {compose, system} from '@winter-love/style-system'
import {Property} from 'csstype'

export const colorProp = getResponsiveProp<ColorsType>()

export const colorSystem = system({
  color: {
    property: 'color',
    scale: 'colors',
  },
})

// IDE 오류인듯 쓰고있습니다.
// noinspection UnnecessaryLocalVariableJS
export const backgroundColorSystem = system({
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
  },
  backgroundPosition: true,
  backgroundRepeat: true,
  backgroundSize: true,
  bg: {
    property: 'backgroundColor',
    scale: 'colors',
  },

})

export const colorSystems = compose(colorSystem, backgroundColorSystem)

export const backgroundColorProps = {
  backgroundColor: getResponsiveProp<ColorsType>(),
  backgroundPosition: getResponsiveProp<Property.BackgroundPosition>(),
  backgroundRepeat: getResponsiveProp<Property.BackgroundRepeat>(),
  backgroundSize: getResponsiveProp<Property.BackgroundSize>(),
  bg: getResponsiveProp<ColorsType>(),
}

export const colorProps = {
  ...backgroundColorProps,
  color: colorProp,
}
