const sp4 = '4px'
const sp6 = '6px'
const sp8 = '8px'
const sp10 = '10px'
const sp12 = '12px'
const sp14 = '14px'
const sp16 = '16px'
const sp20 = '20px'
const sp24 = '24px'
const sp32 = '32px'
const safeAreaTop = 'var(--ion-safe-area-top)'
const safeAreaBottom = 'var(--ion-safe-area-bottom)'
const safeAreaLeft = 'var(--ion-safe-area-left)'
const safeAreaRight = 'var(--ion-safe-area-right)'
const md = sp24
const msd = sp16
const mld = sp32
const sm = sp12
const slm = sp14
const ssm = sp10

export const space = {
  md,
  mld,
  msd,
  safeAreaBottom,
  safeAreaLeft,
  safeAreaRight,
  safeAreaTop,
  slm,
  sm,
  sp10,
  sp12,
  sp14,
  sp16,
  sp20,
  sp24,
  sp32,
  sp4,
  sp6,
  sp8,
  ssm,
}

export type Space = typeof space
export type SpaceType = keyof Space | string | number
