
import {AgModal, AgContent, AgHeader, AgBoxS, AgTextS, AgButtonS} from '@/components/ag'
import {defineComponent, toRef, ref, computed, watch} from 'vue'
import ModalHeader from '@/components/Common/ModalHeader.vue'
import useModal from '@/composables/useModal'
import {useStore} from 'vuex'
import LinkAccountModal from '@/views/view/link-account/LinkAccountModal.vue'
import RegistSellStakeModal from '@/views/view/regist-sell-stake/RegistSellStakeModal.vue'
import ViewMyStake from '@/views/view/view-my-stake/ViewMyStake.vue'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'
const {openSellModal} = useModal()

export default defineComponent({
  components: {
    AgBoxS,
    AgButtonS,
    AgContent,
    AgHeader,
    AgModal,
    AgTextS,
    // AgText,
    LinkAccountModal,
    ModalHeader,
    RegistSellStakeModal,
    ViewMyStake,
  },
  emits: ['close'],
  name: 'SellRegistModal',
  props: {
    isOpen: {required: true, type: Boolean},
  },
  setup(props, {emit}) {
    const gtm = useGtm()
    const {getters, dispatch} = useStore()
    const isOpenRef = toRef(props, 'isOpen')
    const setOpen = () => emit('close')
    const stakeSellOpen = ref<any>(false)
    const buyStakeModal = ref<any>(false)
    const viewMyStakeOpen = ref<any>(false)
    const linkAccountModal = ref<any>(false)
    const user = computed(() => getters['user/getUser'])
    const currentStep = computed(() => getters['stakeDeal/currentStep'])
    const myAllStakeList = computed(() => getters['stakeDeal/myAllStakeList'])
    const isAvaliable = computed(() => myAllStakeList.value && myAllStakeList.value.length)

    const clickScreen = 'regist-sell-modal'
    watch(isOpenRef, () => {
      if (user.value && isOpenRef) {
        dispatch('stakeDeal/GET_MY_ALL_STAKELIST')
      }
      if(isOpenRef.value){
        gtm?.trackEvent({
          event: MODAL_OPEN_EVENT,
          page: clickScreen,
          title: '팔기 등록 모달',
        })
      }
    })

    const branchResult = () => {
      stakeSellOpen.value = false
      viewMyStakeOpen.value = true
    }

    const checkIsLinked = async () => {
      await dispatch('stakeDeal/GET_LINK_STATUS')

      if (currentStep.value !== 'COMPLETE') {
linkAccountModal.value = true
} else if (currentStep.value === 'COMPLETE') {
stakeSellOpen.value = true
}
    }

    return {
      branchResult,
      buyStakeModal,
      checkIsLinked,
      clickScreen,
      isAvaliable,
      isOpenRef,
      linkAccountModal,
      myAllStakeList,
      openSellModal,
      setOpen,
      stakeSellOpen,
      user,
      viewMyStakeOpen,
    }
  },
})
