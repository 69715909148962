
import {computed, onBeforeMount, ref} from 'vue'
import {getFormatDate} from '@/utils/filters'

export default {
  props: {
    childMessage: Object,
    userId: String,
  },
  setup(props: any) {
    const userId: any = computed(() => props.userId)
    const messageInfo: any = computed(() => props.childMessage)

    const sender = computed(() => messageInfo.value._sender.userId || 'system')
    const message = computed(() => messageInfo.value.message.toString().replace(/\n/gi, '<br>') || 'null')
    const unreadStatus = computed(() => messageInfo.value.unreadStatus)
    const date = computed(() =>
      getFormatDate(new Date(messageInfo.value.createdAt)).toString().slice(2).replace(/-/gi, '. '))

    const data = computed(() => messageInfo.value.data)
    const messageType = ref('')

    if (sender.value === userId.value) {
      messageType.value = 'buyer'
    } else if (sender.value === 'angelleague') {
      messageType.value = 'system'
    } else {
      messageType.value = 'seller'
    }

    return {data, date, message, messageInfo, messageType, unreadStatus}
  },
}
