import {styled, systems, colorSystem, systemProps} from '@/plugins/emotion'
import {IonSpinner} from '@ionic/vue'
import {defineComponent, h} from 'vue'

export type SpinnerNames = 'bubbles' | 'circles' | 'circular' | 'crescent' | 'dots' | 'lines' | 'lines-small'

export const WrappedIonSpinner = defineComponent({
  inheritAttrs: false,
  name: 'WrappedIonSpinner',
  setup: (props, {attrs, slots}) => {
    const {..._attrs} = attrs
    delete _attrs.color
    return () => (
      h(IonSpinner, {..._attrs, ...props}, () => slots.default?.())
    )
  },
})

export const ionicSpinnerProps = {
  duration: {type: Number},
  name: {default: 'bubbles', type: String},
  paused: {default: false, type: Boolean},
}

export const styledSpinnerProps = {
  ...systemProps,

}

const ionColorSystem = (props: Record<string, any>) => {
  const {color} = colorSystem(props)
  return {
    '--color': color,
  }
}

export const StyledSpinner = styled(WrappedIonSpinner, {
  name: 'Spinner',
  props: {
    ...styledSpinnerProps,
  },
  stylePortal: 'css',
})(
  systems,
  ionColorSystem,
)
