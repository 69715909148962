
import {computed, defineComponent, onMounted, ref} from 'vue'
import {modalController, IonInput, toastController, IonLabel} from '@ionic/vue'
import apiPoint from '../../api/user-point'
import {useStore} from 'vuex'
const {sendInvite, sendPromotion} = apiPoint()

export default defineComponent({
  components: {
    IonInput,
    IonLabel,
  },
  name: 'InputModal',
  props: {
    registCode: null,
  },
  setup(props) {
    const {dispatch} = useStore()
    const registInviteCode = ref(props.registCode)
    const dismiss = () => {
      modalController.dismiss()
    }

    const inviteCode = ref('')
    const discountCode = ref('')

    const handleInput = () => {
      // console.log(inviteCode.value);
      // console.log(discountCode.value);
    }

    const sendPost = async () => {
      // console.log(inviteCode.value);
      const res = await sendInvite(inviteCode.value)
      if (res.result) {
        registInviteCode.value = inviteCode.value
        dispatch('pointInfo/loadData')
      }
      const toast = await toastController
        .create({
          cssClass: res.result ? 'toast-custom-blue' : 'toast-custom-red',
          duration: 2000,
          message: `${res.msg}`,
          position: 'middle',
        })
      return toast.present()
    }

    const postSend = async () => {
      const res = await sendPromotion(discountCode.value)
      if (res.result) {
        discountCode.value = ''
        dispatch('pointInfo/loadData')
      }
      const toast = await toastController
        .create({
          cssClass: res.result ? 'toast-custom-blue' : 'toast-custom-red',
          duration: 2000,
          message: `${res.msg}`,
          position: 'middle',
        })
      return toast.present()
    }

    return {discountCode, dismiss, handleInput, inviteCode, postSend, registInviteCode, sendPost}
  },
})
