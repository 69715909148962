import {getResponsiveProp} from './utils'
import {system} from '@winter-love/style-system'
import {useTheme} from '@/plugins/emotion'
import {getMayObjectItem, lastOne} from '@/utils'
import {Property} from 'csstype'

export type FlexType = Property.Flex
export type FlexDirectionType = Property.FlexDirection
export type FlexWrapType = Property.FlexWrap
export type FlexGrowType = Property.FlexGrow | boolean
export type FlexShrinkType = Property.FlexShrink
export type FlexGapType = Property.Gap

export const flexSystems = system({
  alignContent: true,
  alignItems: true,
  direction: {
    property: 'flexDirection',
  },
  flex: true,
  gap: (gap: FlexGapType, scale: any, props: any, index: number) => {
    const displayProp = lastOne(props.dp, props.display)
    const display = getMayObjectItem(displayProp, index, -1)
    const theme: any = useTheme()
    const _gap = theme?.space?.[gap] ?? gap
    if (display === 'flex' || display === 'inline-flex') {
      return {
        // fix emotion bug -> '&' parsing is late then self style
        '&': {
          '--flex-gap': 'var(--flex-gap-container)',
          '--flex-gap-container': `calc(var(--flex-gap-parent, 0px) - ${_gap}) !important`,
          marginRight: 'var(--flex-gap)',
          marginTop: 'var(--flex-gap)',
        },

        '&>*': {
          '--flex-gap': 'var(--flex-gap-item) !important',
          '--flex-gap-item': `${_gap} !important`,
          '--flex-gap-parent': `${_gap} !important`,
          marginRight: 'var(--flex-gap)',
          marginTop: 'var(--flex-gap)',
        },
      }
    }
    return {}
  },
  justifyContent: true,
  justifyItems: true,
  wrap: {
    property: 'flexWrap',
  },
})

export const flexProps = {
  alignContent: getResponsiveProp(),
  alignItems: getResponsiveProp(),
  direction: getResponsiveProp<FlexDirectionType>(),
  flex: getResponsiveProp<FlexType>(),
  gap: getResponsiveProp<FlexGapType>(),
  justifyContent: getResponsiveProp(),
  justifyItems: getResponsiveProp(),
  wrap: getResponsiveProp<FlexWrapType>(),
}

export const flexItemSystems = system({
  alignSelf: true,
  flexBasis: true,
  // wip
// grow: (grow, scale) => {
//   console.log(scale)
//   return {}
// },
grow: {
    property: 'flexGrow',
  },
  justifySelf: true,
  shrink: {
    property: 'flexShrink',
  },
})

export const flexItemProps = {
  alignSelf: getResponsiveProp(),
  flexBasis: getResponsiveProp(),
  grow: getResponsiveProp<FlexGrowType>(),
  justifySelf: getResponsiveProp(),
  shrink: getResponsiveProp<FlexShrinkType>(),
}
