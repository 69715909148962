import {styled} from '@/plugins/emotion'

export const Loading = styled('div', {
  props: {
    show: {type: Boolean},
  },
})(
  {
    alignItems: 'center',
    backdropFilter: 'blur(2px)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    label: 'loading',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  ({show}) => {
    return {
      visibility: show ? 'visible' : 'hidden',
    }
  },
)
