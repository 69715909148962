
import SellItemViewModal from '@/components/Modal/SellItemViewModal.vue'
import {IonContent, IonInput, IonRippleEffect, IonSearchbar, modalController, toastController, IonCheckbox} from '@ionic/vue'
import {computed, defineComponent, getCurrentInstance, onBeforeMount, onMounted, reactive, ref} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import stockitemApi from '../../api/stock-item'
import {printFileNameMaxlength} from '@/utils/filters'

import {openMyFile} from '@/utils/OpenFiles'
import {useGtm} from '@gtm-support/vue-gtm'
import {MODAL_OPEN_EVENT} from '@/plugins/gtm-event'

const {createStockItemSell, updateStockItemSell, getStockItemSell} = stockitemApi()

export default defineComponent({
  components: {
    IonCheckbox,
    IonContent,
    IonInput,
    IonRippleEffect,
    IonSearchbar,
  },
  name: 'SellItemModal',
  props: {
    stockItemId: null,
    stockItemWantSellId: null,
  },
  setup(props) {
    const app = getCurrentInstance()
    const gtm = useGtm()
    const {state, dispatch, commit, getters} = useStore()
    const router = useRouter()
    const $filters = app && app.appContext.config.globalProperties.$filters
    const $clickTrackEvent = app && app.appContext.config.globalProperties.$clickTrackEvent
    const user = computed(() => getters['user/getUser'])
    const companyInput = ref<string>('')
    const currentStatus = ref<number>(1)
    const keyInitemNameDriectly = ref<boolean>(false)
    const display = ref<boolean>(false)

    const stockItems = getters['stockItem/getStockItems']
    // const stockItems = getters['stockItem/getSimpleStockItems']
    const searchStockItems = ref<any>([])

    const selectedStockItemId = computed(() => props.stockItemId)
    const sellInfo: any = ref({
      displayFlag: false,
      email: '',
      fileLoc: '',
      fileName: '',
      filePath: '',
      files: '',
      kakaoSendAt: '',
      phone: '',
      qty: 0,
      question: '',
      sellMinQty: 0,
      sellPrice: 0,
      sellPriceNegoFlag: false,
      sellStatus: '',
      stockItemId: '',
      stockItemName: '',
      stockItemWantSellId: '',
      type: '',
      userId: '',
    })

    const clickScreen = 'sell-item-modal'

    onMounted(()=>{
      gtm?.trackEvent({
        event: MODAL_OPEN_EVENT,
        page: clickScreen,
        title: '보유주식 팔기 모달',
      })
    })

    const dismiss = () => {
      modalController.dismiss()
    }

    const previousDismiss = () => {
      modalController.dismiss(null, '', 'collectPartnership')
    }

    const uploadFile = (event: any) => {
      sellInfo.value.files = event.target.files[0]
    }

    async function setStockItemId(stockItemId: any) {
      // console.log('setStockItemId',stockItemId);
      if (stockItemId) {
        const selectedStockItem = stockItems.find((stock: any) => Number(stock.stockItemId) === Number(stockItemId))

        // console.log(stockItems, stockItemId, selectedStockItem )

        const stockItemWantSellId = props.stockItemWantSellId
        let {stockItemSell} = await getStockItemSell(stockItemId, stockItemWantSellId)
        // console.log(stockItemSell);
        if (!stockItemSell) {
          stockItemSell = {sellPriceNegoFlag: true}
          // console.log(stockItemSell);
        }
        if (selectedStockItem !== undefined) {
          currentStatus.value = 2
          sellInfo.value = {
            ...sellInfo.value,
            ...selectedStockItem,
            ...stockItemSell,
          }

          // console.log(sellInfo.value);
        }
      }
    }

    onBeforeMount(async () => {
      // previousDismiss();
      await setStockItemId(selectedStockItemId.value)
    })

    async function createFormData() {
      const formData = new FormData()
      formData.append('stockItemWantSellId', sellInfo.value.stockItemWantSellId.toString())
      formData.append('stockItemId', sellInfo.value.stockItemId.toString())
      formData.append('stockItemName', sellInfo.value.stockItemName.toString())
      formData.append('fileName', sellInfo.value.fileName.toString())
      formData.append('fileLoc', sellInfo.value.fileLoc.toString())
      formData.append('sellPrice', sellInfo.value.sellPrice.toString())
      formData.append('sellPriceNegoFlag', sellInfo.value.sellPriceNegoFlag.toString())
      formData.append('qty', sellInfo.value.qty.toString())
      formData.append('sellMinQty', sellInfo.value.sellMinQty.toString())
      formData.append('files', sellInfo.value.files)
      return formData
    }

    const sendPost = async () => {
      const formData = await createFormData()
      const result: any = reactive({fileUpload: false, stockItemWantSellId: null})
      if (sellInfo.value.stockItemWantSellId) {
        const r = await updateStockItemSell(sellInfo.value.stockItemWantSellId, formData)
        result.stockItemWantSellId = r.stockItemWantSellId
        result.fileUpload = r.fileUpload
      } else {
        const r = await createStockItemSell(formData)
        result.stockItemWantSellId = r.stockItemWantSellId
        result.fileUpload = r.fileUpload
      }
      if (result.stockItemWantSellId) {
        sellInfo.value.stockItemWantSellId = result.stockItemWantSellId
        if (result.fileUpload) {
          const toast = await toastController
            .create({
              cssClass: 'toast-custom-blue',
              duration: 1000,
              message: '파일 업로드 성공!',
              position: 'middle',
            })
          await toast.present()
        }

        await modalController.dismiss()
        const modal = await modalController
          .create({
            backdropDismiss: true,
            component: SellItemViewModal,
            // ios 모드에서만 지원 가능? -> 이따 빌드해서 확인해보기
            componentProps: {
              sellInfoRef: sellInfo.value,
              updateCompleteRef: true,
            },

            cssClass: 'sell-item-modal complete',

            // backdropDismiss: true,
            keyboardClose: true,

            mode: 'ios',

            swipeToClose: true,
          })
        return modal.present()

        // await modalController.dismiss();
        // const toast = await toastController
        //   .create({
        //     message: '보유주식 팔기 요청이 등록되었습니다.',
        //     position: 'middle',
        //     duration: 1000,
        //   })
        // return toast.present();
      }
    }

    const checkProcessStatus = (...status: any) => {
      return ~status.indexOf(currentStatus.value)
    }

    const setProcessStatus = (type: string) => {
      currentStatus.value = type === 'previous' ? currentStatus.value - 1 : currentStatus.value + 1
    }

    async function filterKeyword(query: any) {
      if (!query) {
        searchStockItems.value = []
      } else {
        searchStockItems.value = stockItems.filter((item: any) => ~item.stockItemName.indexOf(query) || (item.codeNumber && ~item.codeNumber.indexOf(query)))
      }
    }

    async function searchKeyword(event: any) {
      const query = event.target.value
      companyInput.value = query
      if(query){
        $clickTrackEvent({'clickArea':'검색영역', 'clickContent':query, clickScreen})
      }
      await filterKeyword(query)
    }

    const searchCleared = (event: Event) => {
      // console.log(event)
    }

    const setCompany = async (stock: any) => {
      await setStockItemId(stock.stockItemId)
    }

    /**
     * status 1 회사 목록
     * status 2 회사 선택됨, 희망 가격 넣기
     * status 3 희망 수량 넣기
     * status 4 판매가능 최소량 넣기
     * status 5 증명서류 첨부
     * status 6 목록 노출 전
     * status 7 목록 노출 후(조건 수정)
     */

    const statusMap: any = ref({
      1: {
        property: 'stockItemName',
        title: '직접 입력',
        unit: '',
      },
      2: {
        property: 'sellPrice',
        title: '희망 가격',
        unit: '원',
      },
      3: {
        property: 'qty',
        title: '희망 수량',
        unit: '주',
      },
      4: {
        property: 'sellMinQty',
        title: '판매 가능 최소량',
        unit: '주',
        validation: {
          eval: ['qty', '<', 'sellMinQty'], message: '희망 수량보다 클 수 없습니다.',
        },
      },
      5: {
        property: 'files',
        title: '증명서류',
        unit: '',
      },
    })

    function buttonEnable() {
      let enable = false

      // todo 설정으로 하려면 죄다 해보자
      if ((statusMap.value as any)[currentStatus.value]) {
        const {property, validation} = (statusMap.value as any)[currentStatus.value]
        if (property === 'files') {
          if ((sellInfo.value as any)[property].name) {
            enable = true
          } else if ((sellInfo.value as any).fileLoc) {
            enable = true
          }
        } else if (property === 'stockItemName' && (sellInfo.value as any)[property]) {
          enable = true
        } else if ((sellInfo.value as any)[property] > 0) {
          enable = true

          if (validation) {
            const validationString = `${(sellInfo.value as any)[validation.eval[0]]} ${validation.eval[1]} ${(sellInfo.value as any)[validation.eval[2]]}`
            // eslint-disable-next-line no-eval
            enable = !eval(validationString)
          }
        }
      }

      return enable
    }

    const setMiddleContents = computed(() => {
      if ((statusMap.value as any)[currentStatus.value]) {
        const {title, property, unit, validation} = (statusMap.value as any)[currentStatus.value]
        let validFailMessage = ''

        if (validation) {
          const validationString = `${(sellInfo.value as any)[validation.eval[0]]} ${validation.eval[1]} ${(sellInfo.value as any)[validation.eval[2]]}`
          // eslint-disable-next-line no-eval
          if (eval(validationString)) {
            validFailMessage = validation.message
          }
        }

        const description = validFailMessage || (~[3, 4].indexOf(currentStatus.value) ? `총 ${$filters.currency(sellInfo.value.sellPrice * (sellInfo.value as any)[property])} 원` : '')

        return {description, num: $filters.currency((sellInfo.value as any)[property]), title, unit}
      }
      return {}
    })

    function addNumber(num: any, status: number) {
      const value: string = num.toString()
      let result: string = Number.parseInt((sellInfo.value as any)[(statusMap.value as any)[status].property]).toString()
      const {validation} = (statusMap.value as any)[currentStatus.value]

      if (validation) {
        const validationString = `${(sellInfo.value as any)[validation.eval[0]]} ${validation.eval[1]} ${(sellInfo.value as any)[validation.eval[2]]}`
        // eslint-disable-next-line no-eval
        if (eval(validationString)) {
          return
        }
      }

      if (result.length >= 8) {
return
}

      if (((value === '0' || value === '00') && result === '0')) {
return
} // 현재 값이 0인데 0이나 00을 누르려고 하는 상황
      result += value; // 그 외 상황들은 다 += 해서 삽입 (이 때, 맨 앞이 0이여도 형변환하면 짤려서 들어감)

      (sellInfo.value as any)[(statusMap.value as any)[status].property] = Number(result)
    }

    function clearNumber(command: string, status: number) {
      let result = (sellInfo.value as any)[(statusMap.value as any)[status].property].toString()

      if (command === 'clear') {
        result = '0'
      } else if (command === 'del') {
        if (result === '') {
          return
        } 
          result = result.slice(0, -1)
        
      }
      (sellInfo.value as any)[(statusMap.value as any)[status].property] = Number(result)
    }

    function requestItem() {
      // console.log("회사명 직접 입력");
      keyInitemNameDriectly.value = true
      sellInfo.value.sellPriceNegoFlag = true
    }

    function openFileSelector() {
      const fileElement: any = document.querySelector('#file_for_proof')
      if (fileElement) {
        fileElement.click()
      }
    }

    const itemLikeCompany = async (stockItemId: any) => {
      const result = await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
      const index = searchStockItems.value.findIndex((stock: any) => stock.stockItemId === result.stockItem.stockItemId)
      if (index > -1) {
        searchStockItems.value[index].likeFlag = result.stockItem.likeFlag
      }
    }

    return {
      addNumber,
      buttonEnable,
      checkProcessStatus,
      clearNumber,
      clickScreen,
      companyInput,
      curStatus: currentStatus,
      dismiss,
      display,
      itemLikeCompany,
      keyInitemNameDriectly,
      openFileSelector,
      openMyFile,
      printFileNameMaxlength,
      requestItem,
      searchCleared,
      searchKeyword,
      searchStockItems,
      sellInfo,
      sendPost,
      setCompany,
      setMiddleContents,
      setProcessStatus,
      stockItems,
      uploadFile,
      user,
    }
  },
})
