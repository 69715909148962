
import {modalController, IonContent, IonRippleEffect, IonInfiniteScroll, IonInfiniteScrollContent} from '@ionic/vue'
import stockInfo from '../../data/stock.json'
import {computed, defineComponent, onMounted, ref, watch} from 'vue'
import {chevronBack} from 'ionicons/icons'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRippleEffect,
  },
  name: 'StockItemTop',
  props: {
    type: null,
  },
  setup(props) {
    const {getters, dispatch} = useStore()
    const router = useRouter()
    const {useCompanyModal} = useModal()
    const viewType = computed(() => props.type)
    const stockTitleInfo = ref<any>(null)

    const viewTitle = computed(() => {
      if (viewType.value === 'default') {
return '모두의 관심 회사'
}
      if (viewType.value === 'popular') {
return '인기 검색 회사'
}
      return ''
    })
    const stockItems = computed(() => getters['stockItem/getSimpleStockItems'])

    const stockItemTop50 = (items: any) => {
      return items.sort((a: any, b: any) => Number.parseInt(b.likeCount) - Number.parseInt(a.likeCount)).slice(0, 50)
    }

    const popularItemTop50 = (items: any) => {
      return items.sort((a: any, b: any) => Number.parseInt(b.searchCount) - Number.parseInt(a.searchCount)).slice(0, 50)
    }

    const user = computed(() => getters['user/getUser'])

    const itemLike = (stockItemId: any) => {
      const result: any = dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
    }

    const itemLikeCompany = async (stockItemId: any) => {
      const result = await dispatch('stockItem/ACT_LIKE_STOCK_ITEM', {stockItemId})
      const index = stockItems.value.findIndex((stock: any) => stock.stockItemId === result.stockItem.stockItemId)
      stockItems.value[index].likeFlag = result.stockItem.likeFlag
      stockItems.value[index].likeCount = result.stockItem.likeCount
    }

    async function closeModal() {
      await modalController.dismiss()
    }

    return {chevronBack, closeModal, itemLike, itemLikeCompany, popularItemTop50, router, stockItemTop50, stockItems, stockTitleInfo, useCompanyModal, user, viewTitle, viewType}
  },
})

