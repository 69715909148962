import {defineComponent, h} from 'vue'
import {styledAlertProps, StyledAlert} from './components'

export const agAlertProps = {
  ...styledAlertProps,
  modelValue: {default: false, type: Boolean},
  subTitle: {default: '', type: String},
  title: {default: '', type: String},
}

export const AgAlert = defineComponent({
  emits: ['update:modelValue'],
  name: 'AgAlert',
  props: agAlertProps,
  setup(props, {emit}) {
    const onClose = () => {
      emit('update:modelValue', false)
    }
    return () => {
      const _props: any = {...props}
      _props.modelValue = undefined
      return (
        h(StyledAlert, {
          ..._props,
          header: props.title,
          isOpen: props.modelValue,
          'onIonAlertDidDismiss': onClose,
          subHeader: props.subTitle,
        })
      )
    }
  },
})
