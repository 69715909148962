import {defineComponent, h, ref, toRef} from 'vue'
import {Container} from './components'
import {onIntersectionElement} from '@/composables'

export const agIntersectionProps = {
  noHide: {default: true, type: Boolean},
}

export const AgIntersectBox = defineComponent({
  name: 'AgIntersectBox',
  props: {
    ...agIntersectionProps,
  },
  setup(props, {slots}) {
    const elementRef = ref()
    const showRef = ref(false)
    const noHideRef = toRef(props, 'noHide')

    onIntersectionElement(elementRef, (entries) => {
      entries.forEach((entry) => {
        if (!noHideRef.value && entry.intersectionRatio === 0) {
          showRef.value = false
          return
        }
        if (entry.intersectionRatio > 0) {
          showRef.value = true
        }
      })
    }, {
      rootMargin: '10px',
      threshold: 0.15,
    })

    return () => {
      return h(Container, {
        ref: elementRef}, showRef.value ? slots : undefined)
    }
  },
})
