import {styled, sizeProps, sizeSystems, additionalSizeSystems, additionalSizeProps} from '@/plugins/emotion'
import {compose} from '@winter-love/style-system'

export const containerProps = {
  ...sizeProps,
  ...additionalSizeProps,
}

export const Container = styled('div', {
  props: {
    css: null,
  },
  stylePortal: 'css',
})(
  {
    flexShrink: 0,
  },
  compose(
    sizeSystems,
    additionalSizeSystems,
  ),
)
