// inset 0 4px 30px rgb(0 0 0 / 25%)
import {system} from '@winter-love/style-system'
import {getResponsiveProp} from './utils'
import {Property} from 'csstype'

export const boxShadowSystems = system({
  boxShadow: {
    property: 'boxShadow',
    scale: 'boxShadows',
  },
})

export const boxShadowProps = {
  boxShadow: getResponsiveProp<Property.BoxShadow>(),
}
