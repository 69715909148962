import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.image.selectedStar,
        alt: "selected-star"
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("img", {
        key: 1,
        src: _ctx.image.unselectedStar,
        alt: "unselected-star"
      }, null, 8, _hoisted_2))
}