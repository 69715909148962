import CallApi from '../utils/CallApi'
import {useStore} from 'vuex'

export const getLinkStatus = async () => {
  // 지분거래 가능 상태 조회 api
  let linkStatus: any = null
  try {
    linkStatus = await CallApi({
      method: 'get',
      url: '/share-transaction/agree',
    })
  } catch {
    console.log(Error)
  }

  return linkStatus
}

export const getCtrlPage = async (publicId: any, status: any) => {
  let page: any = null

  try {
    page = await CallApi({
      method: 'get',
      url: `/external/negostock/ctrl/${publicId}/${status}`,
    })
  } catch {
    console.log(Error)
  }

  return page
}

export const actionNegoStockApi = async (endPoint: any, apiMethod: any) => {
  let result: any = null

  try {
    result = await CallApi({
      method: apiMethod,
      url: `${endPoint}`,
    })
  } catch (error) {
    console.log(error)
  }

  return result
}

export const getSimplePartnerships = async (partnershipId: any) => {
  let result: any = null

  try {
    result = await CallApi({
      method: 'get',
      url: `/simple-partnerships/${partnershipId}`,
    })
  } catch (error) {
    console.log(error)
  }

  return result
}
