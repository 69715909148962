
import {defineComponent, computed, ref} from 'vue'
import {useStore} from 'vuex'
import router from '../../router'
import {modalController, IonContent} from '@ionic/vue'

export default defineComponent({
  components: {
    IonContent,
  },
  name: 'BuyModal',
  props: {
    stockId: null,
  },
  setup(props) {
    const sildeDown = ref(false)

    const stockItemId = ref(props.stockId)

    const {getters} = useStore()
    const deal = computed(() => getters['dealInfo/dealInfo'])
    const status = 1
    const currentPoint = computed(() => getters['dealInfo/point'])
    const currentMoney = computed(() => getters['dealInfo/userMoney'])

    const usePoint = ref(0)
    const useMoney = ref(0)
    const point = ref(getters['dealInfo/point'])
    const userMoney = ref(getters['dealInfo/userMoney'])
    const totalPrice = ref(deal.value.total)

    const closeView = () => {
      modalController.dismiss(null, undefined, 'detailModal')
      modalController.dismiss(null, undefined, 'buyModal')
    }

    const printView = () => {
      modalController.dismiss()
      modalController.dismiss()
      // modalController.dismiss(null, undefined, 'detailModal');
      // modalController.dismiss(null, undefined, 'buyModal');

      router.push(`/payresult/${status}`)
    }

    const adjustPoint = (num: number) => {
      if (totalPrice.value < 0) {
        return
      }
      if (num > getters['dealInfo/point'] || num < 0) {
        return
      }
      usePoint.value = num
      point.value = getters['dealInfo/point'] - usePoint.value
      totalPrice.value = deal.value.total - usePoint.value - useMoney.value
    }

    const adjustMoney = (_num: number) => {
      let num = _num
      if (totalPrice.value < 0) {
        return
      }
      if (num > getters['dealInfo/userMoney'] || num > totalPrice.value) {
        num = totalPrice.value
      } else if (num < 0) {
        return
      }
      useMoney.value = num
      userMoney.value = getters['dealInfo/userMoney'] - useMoney.value
      totalPrice.value = deal.value.total - usePoint.value - useMoney.value
    }

    return {
      adjustMoney,
      adjustPoint,
      closeView,
      curMoney: currentMoney,
      curPoint: currentPoint,
      deal,
      point,
      printView,
      sildeDown,
      stockItemId,
      totalPrice,
      useMoney,
      usePoint,
      userMoney,
    }
  },
})
