import {App} from '@capacitor/core'
import {onBeforeUnmount} from 'vue'

export type OnAppStateUpdateHook = (value: boolean) => void

/**
 * Please use useAppActive
 * @deprecated
 * @param hook
 */
export const onAppStateUpdate = (hook: OnAppStateUpdateHook) => {
  const handle = App.addListener('appStateChange', ({isActive}) => {
    hook(isActive)
  })

  onBeforeUnmount(() => {
    return handle.remove()
  })
}
