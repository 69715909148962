
import {defineComponent} from 'vue'
import {AgContent, AgRefresher, AgText, AgHeader} from '@/components/ag'
import {arrowDownOutline} from 'ionicons/icons'
import {IonPage, IonSearchbar} from '@ionic/vue'

export default defineComponent({
  components: {
    AgContent,
    AgHeader,
    AgRefresher,
    AgText,
    IonPage,
    IonSearchbar,
  },
  setup() {
    const onRefresh = (event: any) => {
      setTimeout(() => {
        event.target.complete()
      }, 3000)
      
    }
    return {
      arrowDownOutline,
      onRefresh,
    }
  },
})
