
import {defineComponent, ref} from 'vue'
import {IonPage} from '@ionic/vue'
import {AgHeader, AgContent, AgButton, AgText} from '@/components/ag'
import {useInAppBrowser, useInAppBrowserPostMessage} from '@/composables/in-app-browser'
export default defineComponent({
  components: {
    AgButton,
    AgContent,
    AgHeader,
    AgText,
    IonPage,
  },
  name: 'Test4',
  setup() {
    const {isOpen, onMessage, isNative} = useInAppBrowser(
      'http://192.168.0.12:8100/test',
      '_black',
)
    const countRef = ref(0)

    const setOpen = (value: boolean) => {
      isOpen.value = value
      if (value) {
        setTimeout(() => {
          isOpen.value = false
        }, 10000)
      }
    }

    const messagesRef = ref<any[]>([])

    const {postMessage} = useInAppBrowserPostMessage()

    onMessage((message) => {
      messagesRef.value.push(message)
    })

    const sendMessage = () => {
      postMessage({message: 'ddddd' + (countRef.value += 1)})
    }

    return {
      isNative,
      isOpen,
      messagesRef,
      postMessage,
      sendMessage,
      setOpen,
    }
  },
})
