import {styled} from '@/plugins/emotion'

export const BackDrop = styled('img')(
  {
    height: '100%',
    label: 'backdrop',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
)
